import React from 'react';
import { landing } from './landing';

export const how = {
  page_title: 'How does it work?',
  page_sub_title_line1: `Host a playdate,`, // now // 'Give some time (with children)',
  page_sub_title_line2: `and then take some time for yourself!`, // in future

  section1: {
    para1: <div>SitterPoint was created for families to <b>share babysitting and childcare</b> with other like-minded families in your area.</div>,
    para2: <div>You can <b>contact available families</b> in your area from the SitterPoint community that <b>makes childcare easy to organise</b> and takes the awkwardness out of asking around.</div>,
    para3: <div>By helping a parent and <b>hosting a playdate, you will receive points</b> that you can <b>use towards your next sit for your own children.</b></div>,
    para4: <div><b>Your children</b> will get to <b>meet, socialise and play</b> with other children in your area.</div>

  },

  section2: {
    header: `How does our platform work?`,
    bullets: landing.bullets
  }


};


export default how;
