import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Modal,
    Button,
    Header,
    Rating
} from "semantic-ui-react";
import moment from 'moment';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { HeaderbarWrapper } from '../../styles/styles';
import { H6, D4, A6 } from '../../styles/text';
import NavBarContainer from '../../containers/Navigation/navbar-container';
import RegisterProgBarContainer from '../../containers/Navigation/registerProgBar-container';
import history from '../Navigation/history';
import colours from '../../styles/colours';

const StarButton = ({ size, rating, setRating }) => {

    const roundedRating = Math.round(rating);
    const ratingText = !rating ? '' : rating.toFixed(0);
    const starSize = size || 'md';

    const handleRate = (e, { rating, maxRating }) => {
      return setRating(rating);
    };


    return (


      <div style={{ paddingLeft: 0, paddingRight: 0, flexDirection: 'row', alignItems: 'center', paddingBottom: 2, display: 'flex', flex: 0.5, background: 'transparent' }}>
        <Rating icon='star' maxRating={5} onRate={handleRate} />

        {
          // <div style={{ flexDirection: 'row', display: 'flex', height: 50, alignItems: 'center' }}>
          //   <FontAwesomeIcon
          //     onClick={ () => setRating(1) }
          //     icon={faStar}
          //     size={starSize}
          //     style={{ color: roundedRating >= 1 ? colours.stars : colours.lightgray, paddingLeft: 4 }} />
          //   <FontAwesomeIcon
          //     onClick={ () => setRating(2) }
          //     icon={faStar}
          //     size={starSize}
          //     style={{ color: roundedRating >= 2 ? colours.stars : colours.lightgray, paddingLeft: 4 }} />
          //   <FontAwesomeIcon
          //     onClick={ () => setRating(3) }
          //     icon={faStar}
          //     size={starSize}
          //     style={{ color: roundedRating >= 3 ? colours.stars : colours.lightgray, paddingLeft: 4 }} />
          //   <FontAwesomeIcon
          //     onClick={ () => setRating(4) }
          //     icon={faStar}
          //     size={starSize}
          //     style={{ color: roundedRating >= 4 ? colours.stars : colours.lightgray, paddingLeft: 4 }} />
          //   <FontAwesomeIcon
          //     onClick={ () => setRating(5) }
          //     icon={faStar}
          //     size={starSize}
          //     style={{ color: roundedRating >= 5 ? colours.stars : colours.lightgray, paddingLeft: 4 }} />
          // </div>
        }
        <div style={{ paddingLeft: 8, paddingRight: 8 }}>
          <H6 textAlign='left' color={colours.stars}>{`${ratingText}`}</H6>
        </div>


      </div>


    );
};

export default StarButton;
