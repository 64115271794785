import React, { Component } from 'react';
import Cropper from 'react-easy-crop';
import Measure from 'react-measure';
import { Image as ImageComponent } from '../image';
import { getUncroppedImg } from './crop-image';
import colours from '../../../styles/colours';

class ImageCropperRaw extends Component {

  // crops the final image to new blob file, ready for upload

  constructor (props) {
    super(props);
    console.log('ImageCropperRaw constructor this props', this.props);
    this.state = {
      crop: { x: 0, y: 0 },
      zoom: 1, // no zoom as not cropping
      // aspect: 1,
      // aspectRatio: 1.3,
      imageDimensions: {
          height: 0,
          width: 0
      },
      containerDimensions: {
          height: 0,
          width: 0
      }

    };


  }

  componentDidMount () {
    const { inputImg } = this.props;
    this.getImageDimensions(inputImg);
  }

  getImageDimensions (src) {
    // takes Base64 image and gets dimensions after load

    const { inputImg, getBlob } = this.props;

    console.log('getImageDimensions inputImg:', inputImg);
    console.log('getImageDimensions src:', src);

    const i = new Image();


    i.onload = async () => {
      const imageDimensions = {
        width: i.width,
        height: i.height
      };

      console.log('i.onload imageDimensions:', imageDimensions);
      console.log('i.onload aspectRatio:', i.width / i.height);

      this.setState({ imageDimensions });

      const unCroppedAreaPixels = {
       width: imageDimensions.width,
       height: imageDimensions.height,
       x: 0, // ?
       y: 0 //
      };


      console.log('unCroppedAreaPixels:', unCroppedAreaPixels);
      // console.log('video Aspect:', 959 / 738); // 1.3
      // console.log('intrinsic final image:', 468 / 265); // 1.76
      // console.log('rendered image:', 299 / 189); // 1.6

      const croppedImage = await getUncroppedImg(
          inputImg,
          unCroppedAreaPixels
      );
      console.log('croppedImage:', croppedImage);
      getBlob(croppedImage);


      // for testing only

      // const urlBlob = URL.createObjectURL(croppedImage);
      // console.log('urlBlob:', urlBlob);
      // this.setState({ urlBlob: urlBlob });


    };

    i.src = src;

  }

  setCrop = (data) => {
     this.setState({ crop: data });
     console.log('setCrop:', data);
  }

  setZoom = (data) => {
     this.setState({ zoom: data });
  }

  /* onCropComplete() will occur each time the user modifies the cropped area,
  which isn't ideal. A better implementation would be getting the blob
  only when the user hits the submit button, but this works for now  */
  onCropComplete = async (_, croppedAreaPixels) => {

    const { inputImg } = this.props;

    // console.log('inputImg:', inputImg); // base64 image
    console.log('onCropComplete');
    console.log('getImageDimensions start');
    this.getImageDimensions(inputImg);
    console.log('getImageDimensions end');

    console.log('croppedAreaPixels:', croppedAreaPixels);
    // can we get dimensions from inputImg? what are x and y?
    // if uncropped, no croppedAreaPixels provided, so use container dimensions (or video?)


  }

  render () {

    const { inputImg, cropShape, aspect } = this.props;
    const useAspect = aspect || this.state.aspect;
    const { imageDimensions } = this.state;
    console.log('ImageCropperRaw state.imageDimensions:', imageDimensions);
    console.log('ImageCropperRaw render aspect:', aspect);
    console.log('ImageCropperRaw this.state.aspectRatio:', this.state.aspectRatio);
    console.log('ImageCropperRaw render this.state.crop:', this.state.crop);
    console.log('ImageCropperRaw this.state.containerDimensions:', this.state.containerDimensions);
    const containerHeight = this.state.containerDimensions.width / useAspect;
    console.log('ImageCropperRaw containerHeight:', containerHeight);


    return (
       /* need to have a parent with `position: relative`
    to prevent cropper taking up whole page */

      <Measure
        bounds
        onResize={(contentRect) => {
          console.log('ImageCropperRaw contentRect:', contentRect);
          this.setState({ containerDimensions: contentRect.bounds });
        }}
      >
        {({ measureRef }) => (
          <div
            ref={measureRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: containerHeight,
              borderWidth: 0,
              borderStyle: 'solid',
              borderColor: 'green'
            }}
          >

            <Cropper
              image={inputImg}
              crop={this.state.crop}
              zoom={this.state.zoom}
              aspect={useAspect}
              cropShape={cropShape || 'round'}
              showGrid={false}
              style={{
                containerStyle: {
                  display: 'none', // hides the Cropper to just show uncropped image below
                  // background: 'blue'
                  borderColor: 'pink',
                  borderWidth: 0,
                  borderStyle: 'solid'
                },
                cropAreaStyle: {
                  borderColor: colours.white,
                  borderWidth: cropShape === 'none' ? 0 : 3,
                  borderStyle: 'solid'
                },
                mediaStyle: {
                  background: 'red',
                  borderColor: 'red',
                  borderWidth: 0,
                  borderStyle: 'solid'
                }
              }}
              objectFit={aspect > 1 ? 'horiztonal-cover' : 'vertical-cover'}

              onCropChange={this.setCrop}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.setZoom}
            />


            {
              <ImageComponent
                alt='main'
                style={{ background: 'white',
                width: this.state.containerDimensions.width,
                height: containerHeight,
                objectFit: 'cover',
                borderColor: 'purple',
                borderWidth: 0,
                borderStyle: 'solid'
                }}
                src={ inputImg }
                spinner
                noDelay

              />
            }

            {
              // saved image blob for testing
              // <ImageComponent
              //   alt='main'
              //   style={{ background: 'white',
              //   width: this.state.containerDimensions.width,
              //   height: containerHeight,
              //   objectFit: 'cover',
              //   borderColor: 'purple',
              //   borderWidth: 2,
              //   borderStyle: 'solid'
              //   }}
              //   src={ urlBlob }
              //   spinner
              //   noDelay
              //
              // />


            }


          </div>
        )}
      </Measure>

    );
  }


}

export default ImageCropperRaw;
