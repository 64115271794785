import React from 'react';
import moment from 'moment';
import { SetGA, ReportGA } from '../../utils/ga';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Modal,
    Button,
    Header
} from "semantic-ui-react";
import { hideModal } from '../../actions/modal';
import Paragraph from '../../components/general/paragraph';
import ModalHeader from '../../components/general/modal-header';
// import Modal from '../../components/modals/modal';
import { H1, A5, H5, H3, ConfirmButtonText } from '../../styles/text';
import { ConfirmButton, Row } from '../../styles/styles';
import ModalAttentionIndicator from '../general/modal-attention-indicator';
import colours from '../../styles/colours';
import { sit_details } from '../../text';
import { PAYMENTS } from '../../constants/ga-category';

const actions = { hideModal };

const ConfirmCancelModal = ({
  // title
  showClose, afterClose, hideModal, cancelAction, backAction, okAction, thread_stage, user_type, other_user_name, modalCategory, modalName }) => {

    console.log('thread_stage:', thread_stage);

  const modalText = (
    thread_stage === 'request' ? sit_details.modal.cancel.request :
    thread_stage === 'pre_approved' ? sit_details.modal.cancel.pre_approved :
    thread_stage === 'approved' ? sit_details.modal.cancel.approved :
    '');

  console.log('user_type', user_type);
  console.log('modalText', modalText);
  const textType = user_type === 'sitter' ? modalText.sitter.confirm : modalText.requester.confirm;
  const { title, header, para, para2, cancel, ok } = textType;


  const onClose = () => {
    hideModal();

    if (afterClose) {
      afterClose();
    }
  };

  const onOK = () => {
    okAction();
    hideModal();

  };

  ReportGA({
    category: PAYMENTS,
    action: `Modal shown to user ${modalName}`
    // meta: { window_width, afterClose, hideModal, cancelAction, backAction, okAction, modalText, modalCategory, modalName, clashing_bookings }
  });


  return (


    <Modal
      closeIcon={showClose && "close"}
      open={true}
      onClose={showClose && onClose}
      style={{ maxWidth: 500 }}
    >
      { // <Modal.Header>{title}</Modal.Header>
      }
      <Modal.Content>

        <ModalHeader>{header}</ModalHeader>

        <div style={{ paddingBottom: 8, display: 'flex', background: 'transparent', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

          <ModalAttentionIndicator

          />

        </div>


        <div style={{ flexDirection: 'column', paddingBottom: 16, display: 'flex', background: 'transparent', justifyContent: 'center', alignItems: 'center' }}>

          <div style={{ marginBottom: 24, maxWidth: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <Paragraph>

              <A5 textAlign='center'>{`${para} ${other_user_name} ${para2}`}</A5>

            </Paragraph>

          </div>

          <div style={{ display: 'flex', maxWidth: 200, width: '100%', flexDirection: 'row', justifyContent: 'space-around' }}>
            <Button
              // secondary

              // style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
              onClick={cancelAction}
            >
              {cancel}
            </Button>
            <Button
              primary

              style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
              onClick={onOK}
            >
              {ok}
            </Button>
          </div>

        </div>

      </Modal.Content>

    </Modal>

  );
};


ConfirmCancelModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default connect(null, { hideModal })(ConfirmCancelModal);
