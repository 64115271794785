import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colours from '../../styles/colours';
import { calendar } from '../../text';
import { FooterLink, FooterWrapper, CheckBoxInput, FakeCheckBox } from '../../styles/styles';
import { H1, H5, H6, B5, DayH, A6, A7, TodH } from '../../styles/text';
import * as BOOKING_TYPES from '../../constants/booking-types';
import { CONFIRMED, UNCONFIRMED } from '../../constants/booking-status';

// Calendar Text

export const MonthHeaderText = ({ children }) => (
  <H1 style={{ color: colours.orange }}>
    {children}
  </H1>

);

export const WeekdayHeaderText = ({ children }) => (

  <div style={{
    width: '100%', display: 'table', height: 30, position: 'relative',
    // 1:1 aspect ratio
    borderWidth: 0, borderColor: 'blue', borderStyle: 'solid',
    justifyContent: 'center', alignItems: 'center'
  }}>
    <H6 style={{
      position: 'absolute', // prevents headings being different size dependent on text
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      textAlign: 'center',
      margin: 0, padding: 0,
      borderWidth: 0, borderRadius: 2, borderColor: 'red', borderStyle: 'solid',
      lineHeight: '200%'

    }}>
      {children}
    </H6>
  </div>

);


export const TimeRowHeaderText = ({ children }) => (
  <div style={{ display: 'table' }}>
    <H5 style={{ display: 'table-cell', textAlign: 'center', verticalAlign: 'middle', marginTop: 2, marginBottom: 2 }}>
      {children}
    </H5>
  </div>

);


export const DateDiv = ({ children, isToday, isInPast, style }) => {
  const pastTestColor = isInPast ? colours.white : 'transparent';
  const divColor = isToday ? pastTestColor : pastTestColor;

  return (
    <div style={{ ...style, borderColor: 'red', borderWidth: 0, borderStyle: 'solid', paddingLeft: 0, paddingRight: 0, borderRadius: 0, marginBottom: 4, background: divColor }}>
      {children}
    </div>
  );
};

export const TodDiv = ({ checkboxWidth, mode }) => {
   // sizes to content width (around 30% of container)
  console.log('mode:', mode);
  if (mode === 'header') {
    // keep longest line and colour transparent
    console.log('mode === header:', (mode === 'header'));
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', background: colours.white, borderWidth: 0, borderColor: 'orange', borderStyle: 'solid' }}>

        <DateDiv style={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'flex-start', borderWidth: 0, borderColor: 'orange', borderStyle: 'solid', marginLeft: 4, marginRight: 4 }}>

          <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <A6 style={{ color: 'transparent' }}>Afternoon</A6>
          </div>

        </DateDiv>

      </div>
    );
  }

  console.log('checkboxWidth:', checkboxWidth);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', background: colours.white, borderWidth: 0, borderColor: 'orange', borderStyle: 'solid' }}>

      <DateDiv style={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'flex-start', borderWidth: 0, borderColor: 'orange', borderStyle: 'solid', marginLeft: 4, marginRight: 4 }}>
        <DateText textColor='transparent' style={{ borderWidth: 0, borderColor: 'orange', borderStyle: 'solid' }}>{'0'}</DateText>

        <div style={{ width: '100%', position: 'relative', marginTop: 6, marginBottom: 4, display: 'table', height: checkboxWidth, borderWidth: 0, borderColor: 'red', borderStyle: 'solid', alignItems: 'center' }}>
          <A6 style={{ color: colours.mediumlightgray, textAlign: 'right', right: 0, top: 0, marginTop: -16, position: 'absolute' }}>{calendar.tods.am}</A6>
          <A6 style={{ position: 'relative', display: 'table-cell' }}>{calendar.tods.morning}</A6>
        </div>
        <div style={{ width: '100%', position: 'relative', marginBottom: 4, display: 'table', height: checkboxWidth, alignItems: 'center' }}>
          <A6 style={{ color: colours.mediumlightgray, right: 0, top: 0, marginTop: -16, position: 'absolute' }}>{calendar.tods.pm}</A6>
          <A6 style={{ position: 'relative', display: 'table-cell' }}>{calendar.tods.afternoon}</A6>
        </div>
        <div style={{ width: '100%', position: 'relative', marginBottom: 4, display: 'table', height: checkboxWidth, alignItems: 'center' }}>
          <A6 style={{ color: colours.mediumlightgray, right: 0, top: 0, marginTop: -16, position: 'absolute' }}>{calendar.tods.eve}</A6>
          <A6 style={{ position: 'relative', display: 'table-cell' }}>{calendar.tods.evening}</A6>
        </div>
        <div style={{ width: '100%', position: 'relative', display: 'table', marginBottom: 0, height: checkboxWidth, alignItems: 'center' }}>
          <A6 style={{ color: colours.mediumlightgray, right: 0, top: 0, marginTop: -16, position: 'absolute' }}>{calendar.tods.on}</A6>
          <A6 style={{ position: 'relative', display: 'table-cell' }}>{calendar.tods.overnight}</A6>
        </div>
      </DateDiv>

    </div>
  );


};


export const DateSelectButton = styled.button.attrs(props => ({
  opacity: props.opacity || 1
}))`

  background: ${props => props.backgroundColor};
  text-decoration: none;
  outline: none;
  opacity: ${props => props.opacity};
  cursor: pointer;
  border-color: ${props => props.isToday ? colours.orange : props.isSelected ? colours.white : props.isInPast ? colours.white : 'transparent'};
  border-width: 1px;
  border-style: solid;
  padding-left: 2px;
  padding-right: 2px;

  border-radius: 3px;
  border-top-left-radius: ${props => props.isToday ? '0px' : '3px'};
  border-top-right-radius: ${props => props.isToday ? '0px' : '3px'};
  margin: 0px;
  width: 30px;
  height: 30px;
  position: relative;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;


  &:hover {

  }
`;

export const DateText = ({ children, textColor, backgroundColor, borderColor }) => {

  // const backgroundColor = 'transparent';
  // const backgroundColor = isSelected ? colours.blue : 'transparent';
    return (
      <div style={{
        margin: 0, width: '100%', position: 'relative',
        paddingBottom: '0%', // 1:1 aspect ratio
        borderWidth: 0, borderColor: 'green', borderStyle: 'solid',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderWidth: 0, borderRadius: 0, borderColor: 'red', borderStyle: 'solid'

        }}>
          <H6
            color={textColor}
            background={backgroundColor}
            textAlign='center'
          >
            {children}
          </H6>
        </div>
      </div>
    );
};

export const AvailabilityText = ({ children, textColor, backgroundColor, borderColor }) => {

  // const backgroundColor = 'transparent';
  // const backgroundColor = isSelected ? colours.blue : 'transparent';
    return (
      <div style={{
        margin: 0, width: '100%', position: 'relative',
        paddingBottom: '0%', // 1:1 aspect ratio
        borderWidth: 0, borderColor: 'green', borderStyle: 'solid',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderWidth: 0, borderRadius: 0, borderColor: 'red', borderStyle: 'solid'

        }}>
          <A7
            color={textColor}
            background={backgroundColor}
            textAlign='center'
          >
            {children}
          </A7>
        </div>
      </div>
    );
};

export const CheckBox = React.forwardRef((props, ref) => {

  // console.log('XisBooked:', isBooked); // object
  const { width, onClick, isChecked, isInPast, isBooked, readOnly, isBlank } = props;

  const backgroundColor = () => {

    if (isInPast) {
      return colours.past;
    }

    // if not booked
    if (isBooked === undefined || !isBooked) {

      if (isChecked) {
        return colours.available;
      }
      // else return unavailable
      return colours.unavailable;

    }
    // else if booked

    if (isBooked.booking_params.booking_type === BOOKING_TYPES.USING && isBooked.booking_params.booking_status === UNCONFIRMED) {
      return colours.requested;
    }
    if (isBooked.booking_params.booking_type === BOOKING_TYPES.USING && isBooked.booking_params.booking_status === CONFIRMED) {
      return colours.using;
    }
    if (isBooked.booking_params.booking_type === BOOKING_TYPES.SITTING && isBooked.booking_params.booking_status === CONFIRMED) {
      return colours.sitting;
    }


  };

  const opacity = () => {

    if (isInPast) {
      return 1;
    }

    // if not booked
    if (isBooked === undefined || !isBooked) {

      if (isChecked) {
        return 1;
      }
      // else return unavailable
      return 0.3;

    }
    // else if booked

    if (isBooked.booking_params.booking_type === BOOKING_TYPES.USING && isBooked.booking_params.booking_status === UNCONFIRMED) {
      return 0.3;
    }
    if (isBooked.booking_params.booking_type === BOOKING_TYPES.USING && isBooked.booking_params.booking_status === CONFIRMED) {
      return 0.3;
    }
    if (isBooked.booking_params.booking_type === BOOKING_TYPES.SITTING && isBooked.booking_params.booking_status === CONFIRMED) {
      return 0.3;
    }


  };

  const borderColor = () => {
    if (isBlank) {
      return 'transparent';
    }
    if (isInPast) {
      return colours.past;
    }
    return colours.borderColour;
  };


  return (
    <div
      ref={ref}
      style={{
      marginTop: 2,
      marginBottom: 2,
      width: width || 30,
      height: width || 30,
      // width: '90%',
      position: 'relative',
      paddingBottom: '0%', // 1:1 aspect ratio
      borderWidth: 0, borderColor: 'green', borderStyle: 'solid',
      justifyContent: 'center', alignItems: 'center'
    }}>
      <label>
        <CheckBoxInput size={`${width || 30}px`} readOnly={readOnly} checked={false} onChange={onClick} backgroundColor={backgroundColor} opacity={opacity} borderColor={borderColor} type='checkbox' />
      </label>
    </div>
  );
});

export const KidCheckBox = ({ isChecked }) => {
  const backgroundColor = isChecked ? colours.orange : colours.lightgray;
  const borderColor = isChecked ? colours.orange : colours.mediumlightgray;


  return (
    <div>
      <label>
        <FakeCheckBox size='20px' checked={isChecked} borderColor={borderColor} backgroundColor={backgroundColor} style={{ color: 'blue', borderWidth: 0, borderColor: 'blue', borderStyle: 'solid' }} />
      </label>
    </div>
  );
};
