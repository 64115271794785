import React from 'react';
import { H1, H2, H3, H5 } from '../../styles/text';
import colours from '../../styles/colours';
import lockIconPic from '../../images/part_DBS.svg';

const CardHeader = ({ lockIcon, light, title, paragraph, backgroundColor, color, subtitle, image, highlightedSubtitle, alignTitle, alignSubtitle, paddingLeft, paddingTop, paddingBottom, children, largeText }) => {

    const colour = color || (light ? colours.white : colours.black);
    console.log('colour:', colour);
    console.log('title:', title);
    return (
      <div style={{ width: '100%', background: backgroundColor || null, display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: paddingLeft || 0, paddingTop: paddingTop || 8, paddingBottom: paddingBottom || 16 }}>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: alignTitle || 'flex-start' }}>
          <div style={{ flex: 1, paddingTop: 4, paddingBottom: 4 }}>
            { largeText
              ?
                <H1
                  textAlign={alignTitle || 'center'}
                  color={color || (light ? colours.white : colours.black)}
                >
                  {title}{children}
                </H1>
              :
                <H2
                  textAlign={alignTitle || 'center'}
                  color={color || (light ? colours.white : colours.black)}
                >
                  {title}{children}
                </H2>
            }
            {
              paragraph && <div style={{ paddingTop: 4, paddingBottom: paddingBottom || 12, display: 'flex', justifyContent: alignSubtitle || 'flex-start' }}>
                {paragraph}
              </div>
            }
          </div>
          {image && <div style={{ position: 'relative', flex: 1, maxWidth: 210, borderColor: 'blue', borderWidth: 0, borderStyle: 'solid', overflow: 'hidden' }}>
            {
              lockIcon &&
              <div style={{ position: 'absolute', right: 0, top: 0, height: 45, width: 45 }}>
                <img alt='card icon' style={{ width: '100%', objectFit: 'cover' }} src={ lockIconPic } />
              </div>
            }
            <img alt='card icon' style={{ width: '100%', objectFit: 'cover' }} src={ image } />
          </div>}
        </div>

        {
          subtitle && <div style={{ paddingTop: 4, paddingBottom: 12, display: 'flex', justifyContent: alignSubtitle || 'flex-start' }}>
            <H5
              textAlign={alignSubtitle || 'left'}
              color={light ? colours.white : null}
            >
              {subtitle}<div style={{ color: (light ? colours.white : colours.darkpurple) }}>{highlightedSubtitle}</div>
            </H5>
          </div>
        }
      </div>
    );
};

export default CardHeader;
