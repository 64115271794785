import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReportGA } from '../../utils/ga';
import { persistor } from '../../init-store';
import InputField from '../general/inputField';
import Image from '../general/image';
import { ConfirmButton, LinkInput } from '../../styles/styles';
import { A5, InputErrorText } from '../../styles/text';
import LinkIcon from '../../images/icon_share_link.min.svg';
import { INVITE } from '../../constants/ga-category';
import colours from '../../styles/colours';

const COPY_LINK = 'copy_link';

// import { connectAlert } from '../Alert';

// const { Answers } = Fabric;

export function FormTextInput ({
  labelText,
  focussedColor,
  unfocussedColor,
  placeholder,
  isEmail,
  iconName,
  input: { value, onChange, onBlur },
  meta: { touched, error } }) {

  // console.log('touched: ', touched);
  // console.log('error: ', error);

  return (
    <div style={{ alignItems: 'center' }}>

      <InputField
        readOnly
        placeholder={ placeholder }
        autoCorrect={ false }
        autoCapitalize={ isEmail ? 'none' : 'words' }
        onChangeText={ text => onChange(text) }
        onBlur={ text => onBlur(text) }
        value={ value }
        type='text'
        labelType='notPoll'
        label={ labelText }
        iconName={ iconName }
        // inputKey= { inputKey }
        // onChangeText={ text => handleChange(text, inputKey) }
        focussedColor={ focussedColor }
        unfocussedColor={ unfocussedColor }
      />


      {
        touched && error && <InputErrorText>{error}</InputErrorText>
      }
    </div>
  );
}


class ReferLink extends Component {

  constructor (props) {
    super(props);
    // console.log('constructor this props', this.props);
    this.state = {
      copied: false
    };

  }


  copyToClipboard = () => {
    const { pageRef, prevRoute, shareType, onClickCopy } = this.props;

    console.log('onClickCopy', onClickCopy);
    this.setState({ copied: true });
    onClickCopy(); // triggers callback passed from parent component
    // trigger copy link refer action //
    // handleCopyLink(userUniqueLink);

    ReportGA({
      category: INVITE,
      action: `User clicked CopyToClipboard ${shareType || COPY_LINK} button on ${pageRef || `Invite`} page, prevRoute ${prevRoute}`
    });

    setTimeout(() => {
      this.setState({ copied: false });
    }, 5000); // 5 seconds, then reset


  };

  renderButton = () => {

    const { userUniqueLink, buttonText } = this.props;

    return (
      <CopyToClipboard
        text={userUniqueLink}
        onCopy={() => this.copyToClipboard()}
      >

        <ConfirmButton
          style={{ borderWidth: 1, borderStyle: 'solid', borderColor: colours.orange, marginRight: 0 }}
          testID='Copy Link'
          onClick={this.copyToClipboard}
          color={ this.state.copied ? colours.orange : colours.white}
          background={ this.state.copied ? colours.white : colours.orange }
        >
          {this.state.copied ? 'Copied!' : buttonText || 'Copy'}
        </ConfirmButton>


      </CopyToClipboard>


    );
  };

  renderIconOnlyButton = () => {

    const { userUniqueLink, iconSize } = this.props;


    return (
      <CopyToClipboard
        text={userUniqueLink}
        onCopy={() => this.copyToClipboard()}
      >
        <button type='button' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'transparent', padding: 0, borderWidth: 0, width: iconSize, height: iconSize }}>
          {
            this.state.copied
            ? <div style={{ width: iconSize * 2 }}><A5 color={colours.orange}>Copied!</A5></div>
            : <Image noDelay width={iconSize} height={iconSize} src={LinkIcon} />
            || <A5>Copy</A5>
          }
        </button>

      </CopyToClipboard>


    );
  };

  render () {
    const { userUniqueLink, buttonOnly, iconOnly } = this.props;

    if (iconOnly) {
      return (

        <div style={{ borderWidth: 1, borderColor: colours.confirm, borderStyle: buttonOnly ? 'none' : 'dashed', borderTopRightRadius: 3, borderBottomRightRadius: 3, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: 0, paddingBottom: 0 }}>

          { this.renderIconOnlyButton() }

        </div>

      );
    } // else
    return (


      <div style={{ borderWidth: 1, borderColor: colours.confirm, borderStyle: buttonOnly ? 'none' : 'dashed', borderTopRightRadius: 3, borderBottomRightRadius: 3, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: 0, paddingBottom: 0 }}>

        <LinkInput
          // visibility={!buttonOnly ? null : 'hidden'}
          display={!buttonOnly ? null : 'none'}
          accessibilityLabel='link to copy'
          underlineColorAndroid='transparent'
          value={userUniqueLink}
        />

        { this.renderButton() }

      </div>

    );
  }
}


export default ReferLink;
