
// import initSocket from '../socket-router';
import http from '../http-common';
import * as ROUTES from '../../constants/routes';
import { showModal, hideModal } from '../modal';
import { verify } from '../../text';
import history from '../../components/Navigation/history';
import { setUploadProgress, setCurrentFile, setFileID } from '../general/upload';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';
import { MODAL_TYPE_UPLOAD_FILE, MODAL_TYPE_UPLOAD_PROGRESS, MODAL_TYPE_ERROR, MODAL_TYPE_CONFIRM_UPLOAD_TYPE, MODAL_TYPE_UPLOAD_SELFIE, MODAL_TYPE_SPINNER } from '../../components/modals/modalTypes';
import { CONFIRM_UPLOAD_TYPE } from '../../components/modals/modalNames';
import { UPLOAD } from '../../components/modals/modalCategory';
// require('dotenv').config(); // no longer needed in new version of node // can cause bugs
const FormData = require('form-data');

export const SET_SELECTED_ADULT = 'SET_SELECTED_ADULT';
export const RESET_SELECTED_ADULT = 'RESET_SELECTED_ADULT';
export const UPLOAD_DOC_REQUEST = 'UPLOAD_DOC_REQUEST';
export const UPLOAD_DOC_SUCCESS = 'UPLOAD_DOC_SUCCESS';
export const UPLOAD_DOC_FAILURE = 'UPLOAD_DOC_FAILURE';
export const SET_SELECTED_DOC_FILE = 'SET_SELECTED_DOC_FILE';
export const SET_SELFIE_IMAGE = 'SET_SELFIE_IMAGE';
export const ACTION_SUCCESS = 'ACTION_SUCCESS';


export function setSelfieImage (data) {
  return {
    type: SET_SELFIE_IMAGE,
    data
  };
}

export function setSelectedAdult (data) {
  return {
    type: SET_SELECTED_ADULT,
    data
  };
}

export const resetSelectedAdult = () => ({
  type: RESET_SELECTED_ADULT
});

export function setSelectedDocFile (data) {
  return {
    type: SET_SELECTED_DOC_FILE,
    data
  };
}

export const uploadDocRequest = () => ({
  type: UPLOAD_DOC_REQUEST
});

export const uploadDocSuccess = data => ({
  type: UPLOAD_DOC_SUCCESS,
  data
});

export const uploadDocFailure = error => ({
  type: UPLOAD_DOC_FAILURE,
  error
});

export const actionSuccess = () => ({
  type: ACTION_SUCCESS
});

export function handleUploadSelectClick (docStatus, adult_id, adult_name, doc_name) {
  // console.log('handleUploadVerifyClick', `${docStatus}, ${adult_id}, ${adult_name}, ${doc_name}`);
  const cameraAction = (dispatch) => {

      dispatch(showModal(MODAL_TYPE_UPLOAD_SELFIE, {
        showClose: true,
        cancelAction: () => dispatch(hideModal()),
        adult_id,
        adult_name,
        doc_name
      }));
    // } // else
      // return okApproveSit(token, thread_id, user_id, payment_type);
  };

  const fileAction = (dispatch) => {

      dispatch(showModal(MODAL_TYPE_UPLOAD_FILE, {

        cancelAction: () => dispatch(hideModal()),
        adult_id,
        adult_name,
        doc_name
      }));
    // } // else
      // return okApproveSit(token, thread_id, user_id, payment_type);
  };

  // const fileAction = () => {
  //
  //     return history.push({ pathname: ROUTES.SUBSCRIBE_TO_CONTACT });
  //   // } // else
  //     // return okApproveSit(token, thread_id, user_id, payment_type);
  // };

  return (dispatch) => {

    // dispatch(showModal(MODAL_TYPE_CONFIRM_UPLOAD_TYPE, {
    //   cameraAction: () => cameraAction(dispatch),
    //   fileAction: () => fileAction(dispatch),
    //   cancelAction: () => dispatch(hideModal()),
    //   docStatus,
    //   adult_id,
    //   adult_name,
    //   doc_name
    // }));


    const modalText = verify.modal.confirm_upload_type;

    const header =
    doc_name === 'photo' ?
      modalText.header.photo :
    doc_name === 'id' ?
      modalText.header.id :
    doc_name === 'poa' ?
      modalText.header.poa :
    doc_name === 'dbs' ?
      modalText.header.dbs :
    'undefined';

    const titleSuffix =
    doc_name === 'photo' ?
      modalText.photo :
    doc_name === 'id' ?
      modalText.id :
    doc_name === 'poa' ?
      modalText.poa :
    doc_name === 'dbs' ?
      modalText.dbs :
    'undefined';

    const para =
    doc_name === 'photo' ?
      modalText.para_photo :
    doc_name === 'id' ?
      modalText.para_id :
    doc_name === 'poa' ?
      modalText.para_poa :
    doc_name === 'dbs' ?
      modalText.para_dbs :
    'undefined';

    const title = `${modalText.title} ${titleSuffix}`;

    const newModalText = { ...modalText, para, title, header };

    dispatch(showModal(MODAL_TYPE_CONFIRM_UPLOAD_TYPE, {
      showClose: true,
      modalCategory: UPLOAD,
      modalName: CONFIRM_UPLOAD_TYPE,
      modalText: newModalText,
      // okAction: () => dispatch(okDowngradeSubscription(token, user_id, cancel, next_payment_date)),
      backAction: null,

      cameraAction: () => cameraAction(dispatch),
      fileAction: () => fileAction(dispatch),
      cancelAction: () => dispatch(hideModal()),
      docStatus,
      adult_id,
      adult_name,
      doc_name

    }));


  };
}

// handleDocUpload: (source, onUploadProgress, nextRoute, adult_id, adult_name, doc_name) => {


export function uploadDoc (token, file, onUploadProgress, adult_id, adult_name, doc_name) {

  console.log('verify uploadDoc NOT id or id_selfie');

  // from here
  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_UPLOAD_PROGRESS,
      { // modalProps
        message: verify.modal.upload_fetching.message,
        successText: verify.modal.upload_fetching.success,
        fetchingText: verify.modal.upload_fetching.uploading
      }
    ));

    dispatch(uploadDocRequest());
    dispatch(setUploadProgress(0));

    console.log('verify upload file: ', file);


    console.log('doc_name', `${doc_name}`);
    console.log('adult_id', `${adult_id}`);
    console.log('adult_name', `${adult_name}`);
    console.log('file', file.name); // if blob, won't have name
    
    console.log('type', `${file.type}`);
    // const fileType = file.type;
    const formData = new FormData();

    formData.append('doc_name', `${doc_name}`);
    formData.append('adult_id', `${adult_id}`);
    formData.append('adult_name', `${adult_name}`);
    const fileName = file.name === undefined ? 'blob' : file.name;
    console.log('file name', fileName);
    formData.append('file', file, fileName);
    formData.append('type', `${file.type}`);

    console.log('Doc formData: ', formData);


    setTimeout(() => {
      // delay slightly so user sees progress indicator.
      // note - using axios for this one as fetch doesn't allow for callback for progress


      http.post(`/verifyupload`, formData, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          authorization: token,
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress // callback to show progress indicator


      })
      .then(CheckError)
      .then((res) => {
        dispatch(actionSuccess()); // triggers success icon
        const data = res.data;
        console.log('Upload doc response data: ', data);


        // setTimeout before pushing next route to allow success narrowScreenOrientation
        setTimeout(() => {
          dispatch(uploadDocSuccess(data));
          dispatch(setCurrentFile(undefined)); // could this be part of success reducer?

          dispatch(hideModal());

        }, 2500); // 2.5 seconds

      })
      .catch((err) => {

        console.log('caught verifyDocUpload error: ', err);

        dispatch(uploadDocFailure(err));

        // centralise bugsnag reporting
        ReportError(!err.response || !err.response.status ? null : err.response.status, !err.response ? null : err.response.data.error, {});
        dispatch(showModal(
          MODAL_TYPE_ERROR,
          { // modalProps
            title: verify.modal.upload_failure.title,
            header: verify.modal.upload_failure.header,
            message: verify.modal.upload_failure.para,
            message2: verify.modal.upload_failure.para2,
            error_status: err.response && err.response.status && err.response.status,
            error_message: err.response && err.response.data.error
          }
        ));

        dispatch(actionSuccess());

      });


    }, 1000); // 1 second delay to ensure progress bar shows

  };
}

export function submitIDDoc (token, file, onUploadProgress, adult_id, adult_name, doc_name) {

  console.log('verify submitIDDoc for id only');

  // from here
  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        // message: verify.modal.upload_fetching.message,
        // successText: verify.modal.upload_fetching.success,
        // fetchingText: verify.modal.upload_fetching.uploading
      }
    ));

    // dispatch(uploadDocRequest());
    // dispatch(setUploadProgress(0));

    console.log('verify submitIDDoc file: ', file);

    console.log('doc_name', `${doc_name}`);
    console.log('adult_id', `${adult_id}`);
    console.log('adult_name', `${adult_name}`);


    setTimeout(() => {
      // delay slightly so user sees progress indicator.
      // progress indicator won't update because now API request (should we reprogram based on time?)

      // instead of uploading, if ID doc, progress to ID Selfie
      // may need to set fileID in state if haven't already.

      // need to store in new field in state, eg. id_file
      // and clear when id_selfie submitted or when unmounting
      dispatch(setFileID(file));


      dispatch(actionSuccess()); // triggers success icon

      // setTimeout before pushing next route to allow success narrowScreenOrientation
      setTimeout(() => {
        // dispatch(uploadDocSuccess(data)); // nothing uploaded
        dispatch(setCurrentFile(undefined)); // could this be part of success reducer?

        dispatch(hideModal());
        dispatch(showModal(MODAL_TYPE_UPLOAD_SELFIE, {
          showClose: true,
          cancelAction: () => dispatch(hideModal()),
          adult_id,
          adult_name,
          doc_name: 'id_selfie'
        }));


      }, 2500); // 2.5 seconds before moving onto ask for selfie


    }, 1000); // 1 second delay to ensure progress bar shows


  };
}

export function uploadIDDocs (token, fileID, fileSelfie, onUploadProgress, adult_id, adult_name, doc_name) {

  // uploads both ID + selfie as two files.
  console.log('verify uploadIDDocs, only triggered by id_selfie, uploads both ID and selfie');

  // from here
  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_UPLOAD_PROGRESS,
      { // modalProps
        message: verify.modal.upload_fetching.message,
        successText: verify.modal.upload_fetching.success,
        fetchingText: verify.modal.upload_fetching.uploading
      }
    ));

    dispatch(uploadDocRequest());
    dispatch(setUploadProgress(0));

    const formData = new FormData();

    formData.append('adult_id', `${adult_id}`);
    formData.append('adult_name', `${adult_name}`);

    // do below for each file in filesArray


    console.log('verify uploadID file: ', fileID);
    console.log('verify uploadSelfie file: ', fileSelfie);


    console.log('doc_name', `${doc_name}`);
    console.log('adult_id', `${adult_id}`);
    console.log('adult_name', `${adult_name}`);
    console.log('fileID name', fileID.name); // if blob, won't have name
    console.log('fileSelfie name', fileSelfie.name); // if blob, won't have name
    console.log('fileID type', `${fileID.type}`);
    console.log('fileSelfie type', `${fileSelfie.type}`);

    // formData.append('doc_name_id', `${'id'}`); // calling multiple times with same key appends array of names
    // formData.append('doc_name_selfie', `${'id_selfie'}`); // calling multiple times with same key appends array of names
    // formData.append('type_id', `${fileID.type}`); // calling multiple times with same key appends array of types
    // formData.append('type_selfie', `${fileSelfie.type}`); // calling multiple times with same key appends array of types

    formData.append('file_id', fileID, fileID.name); // calling multiple times with same key appends array of files
    formData.append('file_selfie', fileSelfie, fileSelfie.name); // calling multiple times with same key appends array of files


    // formData.append(files[i].name, files[i]); to upload multiple - can put this in a loop to append all to an array

    console.log('Doc formData: ', formData);


    setTimeout(() => {
      // delay slightly so user sees progress indicator.
      // note - using axios for this one as fetch doesn't allow for callback for progress


      http.post(`/verifyuploadID`, formData, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          authorization: token,
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress // callback to show progress indicator


      })
      .then(CheckError)
      .then((res) => {
        dispatch(actionSuccess()); // triggers success icon
        const data = res.data;
        console.log('Upload doc response data: ', data);


        // setTimeout before pushing next route to allow success narrowScreenOrientation
        setTimeout(() => {
          dispatch(uploadDocSuccess(data)); // returns new Adult state
          dispatch(setCurrentFile(undefined)); // could this be part of success reducer?
          dispatch(setFileID(undefined));


          // if (data.doc_name === 'id_selfie') { // think we know this will be id_selfie - can remove conditional
          console.log('is selfie');
          // if uploaded and submitted Selfie then upload push route to go back to upload verification
          history.push({
            pathname: ROUTES.VERIFY_MAIN_ADULT,
            state: { doc_name: undefined }
          }); // TODO need option to route to OTHER_ADULTS/ DBS if not main adult

          // show Confirm Modal with option of adding more docs or going to Calendar to add availability (or close to go back to choose adult)

          dispatch(hideModal());
          // } else {
          //   console.log('is neither');
          //   // if normal doc upload for POA or DBS, go back to upload verification - left for user to navigate
          //   // history.push({
          //   //   pathname: ROUTES.UPLOAD_VERIFICATION,
          //   //   state: { doc_name: undefined }
          //   // });
          //   dispatch(hideModal());
          // }
        }, 2500); // 2.5 seconds staying on success before moving back to verify adult, maybe we want an 'OK' so they have time to read?

      })
      .catch((err) => {

        console.log('caught verifyDocUpload error: ', err);

        dispatch(uploadDocFailure(err));

        // centralise bugsnag reporting
        ReportError(!err.response || !err.response.status ? null : err.response.status, !err.response ? null : err.response.data.error, {});
        dispatch(showModal(
          MODAL_TYPE_ERROR,
          { // modalProps
            title: verify.modal.upload_failure.title,
            header: verify.modal.upload_failure.header,
            message: verify.modal.upload_failure.para,
            message2: verify.modal.upload_failure.para2,
            error_status: err.response && err.response.status && err.response.status,
            error_message: err.response && err.response.data.error
          }
        ));

        dispatch(actionSuccess());

      });


    }, 1000); // 1 second delay to ensure progress bar shows

  };
}
