import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, Icon
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";

// opacity: props.opacity || 1

export const StyledButton = styled(Button)`


  margin: 0px!important;
  color: ${props => props.color ? props.color : colours.white}!important;
  background: ${props => props.backgroundColor ? props.backgroundColor : colours.lightpurple}!important;
  border-radius: 0px!important;
  border-width: 0px!important;
  border-style: solid!important;
  border-color: ${colours.orange}!important;

  cursor: pointer!important;

  &:hover {
    background: ${colours.verylightgray}!important;
    color: ${colours.mediumlightpurple}!important;
  }

`;


const JoinButton = (props) => {
  return (
    <StyledButton
      primary={props.primary}
      icon={props.icon}
      labelPosition={props.icon ? props.labelPosition || 'left' : null}
      fluid={props.fluid}
      compact={props.compact}
      disabled={props.disabled}

      color={props.color}
      backgroundColor={props.backgroundColor}

      onClick={props.onClick}
    >
      {
        props.icon
        && <Icon name={props.icon} />
      }
      {props.children}
    </StyledButton>
  );
};

export default JoinButton;
