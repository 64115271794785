import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { HeaderbarWrapper } from '../../styles/styles';
import NavBarContainer from '../../containers/Navigation/navbar-container';
import * as ROUTES from '../../constants/routes';

class Headerbar extends Component {


  render () {
    console.log('headerbar props:', this.props);
    const { location } = this.props;
    const currentRoute = location.pathname;
    // console.log('history.location:', history.location);
    console.log('currentRoute', currentRoute);

    const showProgbar = (!location.state || !location.state.editMode);

    console.log('showProgbar:', showProgbar);
    if ((currentRoute === ROUTES.LANDING) || (currentRoute === ROUTES.WELCOME) || (currentRoute === ROUTES.INVITE)) {
      return null;
    }

    if (
      showProgbar &&
      ((currentRoute === ROUTES.WELCOME) || (currentRoute === ROUTES.ABOUT_CHILDREN) || (currentRoute === ROUTES.EDIT_CHILD) || (currentRoute === ROUTES.WHERE_YOU_LIVE) || (currentRoute === ROUTES.TELL_US_ABOUT_YOURSELF) || (currentRoute === ROUTES.EDIT_ADULT) || (currentRoute === ROUTES.PROFILE_PHOTO) || (currentRoute === ROUTES.BIO) || (currentRoute === ROUTES.VERIFY_MAIN_ADULT) || (currentRoute === ROUTES.PROFILE_SUMMARY))) {
      return null;
    }

    return (
      <HeaderbarWrapper>

        <NavBarContainer currentRoute={currentRoute} />

      </HeaderbarWrapper>

    );
  }
}

export default withRouter(Headerbar);
// withRouter(
// withRouter ensures history.location is added to props for components outside of Router
