import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colours from './colours';
import default_theme, { fonts } from './default-theme';
import {
  Bullet,
  InputTyp,
  TextAreaTyp,
  SendInput
} from './typo';
import {
  AlertButton,
  BackButton,
  MoreButton,
  MoreButtonSmall,
  ConfirmButton,
  EditButton,
  NavButton,
  JoinButton,
  SignupButton,
  TabButton

} from './buttons';


export { AlertButton, BackButton, MoreButton, MoreButtonSmall, ConfirmButton, EditButton, NavButton, SignupButton, JoinButton, TabButton, SendInput };

const {
  a4
} = default_theme.theme_details.textStyles;


// Main Layout
console.log('InputTyp:', InputTyp);


export const Transition = styled.div`
  .active {
    visibility: visible;
    width: 100%;
    height: null;
    transition: all 200ms ease-in;

  }
  .hidden {
    visibility: hidden;
    height: 0px;
    transition: all 200ms ease-out;
    transform: translate(0, -100%);
  }
`;

export const TransitionDown = styled.div`
  .active {
    visibility: visible;
    height: null;
    transition: all 200ms ease-in;

  }
  .hidden {
    visibility: hidden;
    height: 0px;
    transition: all 200ms ease-out;
    transform: translate(0, 100%);
  }
`;

// ${props => (props.sticky) ? 'absolute' : null};

export const StickyTabBar = styled.div`


  border-width: 0;
  border-color: green;
  border-style: solid;
  width: 100%;
  z-index: 2;
  position: sticky;
  padding-top: 0px;
  top: ${props => (props.sticky) ? (props.top || 0) : null};

`;

export const FixedTabBar = styled.div`


  border-width: 0;
  border-color: green;
  border-style: solid;
  width: 100%;
  z-index: 1000;
  position: fixed;
  padding-top: 0px;
  top: ${props => (props.top || 0)};

`;

// .sticky {
//   /* position: absolute; */
//   /* top: 0; */
//   border-width: 1;
//   border-color: red;
//   border-style: solid;
// }
// .scroll {
//
//   border-width: 1;
//   border-color: blue;
//   border-style: solid;
// }
export const StickyBottomBar = styled.div`
  width: 100%;
  position: ${props => props.position ? props.position : 'fixed'};
  z-index: 1000;
  bottom: 0;

`; // scroll away div - hides on scroll down, shows on scroll up


export const ScrollAwayDiv = styled.div`


`; // scroll away div - hides on scroll down, shows on scroll up


export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  border-width: 0px;
  border-color: blue;
  border-style: solid;
  height: 100%;

`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1;

`;

export const MainRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1;
  justify-content: space-between;

`;
export const PageTitleDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: ${colours.offWhite};
  justify-content: center;

`;

export const Block = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background: ${colours.verylightgray};
  border-width: 2px;
  border-color: ${colours.lightgray};
  border-style: solid;
  min-width: 40px;
  max-width: 80px;



`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  /* background: transparent; */
  border-width: 0px;
  border-color: pink;
  border-style: solid;

`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  background: ${colours.offWhite};
  border-width: 0px;
  border-color: orange;
  border-style: solid;


`;

export const InfoRowDiv = styled.div`
  display: flex;

  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: ${colours.offWhite};
  border-width: 0px;
  border-color: pink;
  border-style: solid;

  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }


`;

export const RowWrapped = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background: ${colours.offWhite};
  border-width: 0px;
  border-color: blue;
  border-style: solid;


`;

export const CellSm = styled.div`
  display: table-cell;
  width: 10%;
  justify-content: center;
  align-items: center;
  background: ${props => (props.background === null) ? colours.verylightgray : props.background};
  border-width: 0px;
  border-color: ${colours.orange};
  border-style: solid;
  min-width: 40px;
  max-width: 80px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;

`;

export const CellLg = styled.div`
  display: flex;
  flex-grow: 1;
  width: 30%;
  justify-content: center;
  align-items: center;
  background: ${colours.verylightgray};
  border-width: 1px;
  border-color: ${colours.offWhite};
  border-style: solid;


`;

// inputs

// CheckBox
// wrap native checkbox input to override for easier styling

export const CheckBoxInput = ({ className, checked, size, backgroundColor, opacity, borderColor, ...props }) => (
  <CheckboxContainer
    className={className}
    style={{

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 0, borderRadius: 0, borderColor: 'red', borderStyle: 'solid'


  }}
    >
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} size={size} backgroundColor={backgroundColor} opacity={opacity} borderColor={borderColor}>
      <CheckboxIcon viewBox='0 0 24 24'>
        <polyline points='20 6 9 17 4 12' />
      </CheckboxIcon>
    </StyledCheckbox>
  </CheckboxContainer>
);

// checkboxIcon
const CheckboxIcon = styled.svg`
  fill: none;
  stroke: ${colours.white};
  stroke-width: 2px;
`;

// container to wrap new CheckBox and HiddenCheckbox
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;


`;

// hide native CheckBox
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

// new checkbox we created
const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${props => props.size};
  height: ${props => props.size};
  background: ${props => props.backgroundColor};
  opacity: ${props => props.opacity};

  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.borderColor};
  transition: all 150ms;

  ${HiddenCheckbox}:active + & {
    box-shadow: 0 0 0 3px ${colours.orange};
  }

  ${CheckboxIcon} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'};
  }

`;

// fakeCheckBox

export const FakeCheckBox = ({ className, checked, size, backgroundColor, borderColor }) => (
  <CheckboxContainer className={className}>
    <StyledCheckbox checked={checked} size={size} backgroundColor={backgroundColor} borderColor={borderColor}>
      <CheckboxIcon viewBox='0 0 24 24'>
        <polyline points='20 6 9 17 4 12' />
      </CheckboxIcon>
    </StyledCheckbox>
  </CheckboxContainer>
);


// other inputs

export const Input = styled(InputTyp)`

  margin-left: 0px;
  margin-right: 1px;
  padding-left: 4px;
  padding-right: 4px;

  height: 25px;
  display: flex;
  flex: 1;
  outline: none;

  border-color: ${props => props.error ? colours.error : colours.borderColour};
  border-width: ${props => props.error ? '2px': '0px'};
  border-style: solid;

`;

export const EditPhotoInput = styled.input`

  font-family: ${fonts.default};
  justify-content: center;
  align-items: center;
  background: ${colours.white};
  border-color: ${colours.confirm};
  border-width: 0px;
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  elevation: 1;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 0px ${colours.shadowColour}; // width, height, radius, colour

  &:hover {
    background: ${colours.verylightgray};
    color: ${colours.lightgray};
  }
`;

export const DateInput = styled.input`
  font-family: ${fonts.default};
  text-align: left;
  margin-left: 0px;
  margin-right: 1px;
  padding-left: 4px;
  padding-right: 4px;
  background: ${colours.white};
  font-size: 12px;
  font-weight: 600;
  border-width: 1px;
  border-color: ${colours.borderColour};
  border-style: solid;
  border-radius: 0px;
  height: 25px;
  width: 100%;
  outline: none;
  width: 100px;
  color: ${colours.gray}
`;


export const LinkInput = styled.input`
  font-family: ${fonts.default};
  text-align: left;
  margin-left: 0px;
  margin-right: 1px;
  padding-left: 4px;
  padding-right: 0px;
  background: ${colours.white};
  font-size: 12px;
  font-weight: 600;
  border-width: 0px;
  border-color: ${colours.orange};
  border-style: solid;
  border-radius: 0px;
  height: 25px;
  width: 100%;
  outline: none;
  visibility: ${props => props.visibility};
  display: ${props => props.display};
`;


export const MapMarker = styled.div.attrs(props => ({
  opacity: props.opacity || 1
}))`
  color: ${colours.white};
  opacity: ${props => props.opacity};
  background-color: ${colours.orange};
  border-radius: 20px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  position: absolute;
  left: -5px;
  top: -5px;
`;

export const VagueMapMarker = styled.div.attrs(props => ({
  opacity: props.opacity || 1,
  size: props.size || '100px'
}))`
  color: ${colours.white};
  opacity: ${props => props.opacity};
  background-color: ${colours.marker_main};
  border-radius: 50px;
  width: ${props => props.size};
  height: ${props => props.size};
  min-width: ${props => props.size};
  min-height: ${props => props.size};
  position: absolute;
  left: -5px;
  top: -5px;
`;

export const TextArea = styled(TextAreaTyp)`


  margin-left: 0px;
  margin-right: 1px;
  padding-left: 0px;
  padding-right: 0px;

  width: 100%;
  outline: none;
  resize: none;

  border-color: ${colours.borderColour};
  border-width: 1px;
  border-style: solid;

`;


export const LogoWrapper = styled.div`
  /* width: 120px; */
  height: 100%;

  /* margin: 8px; */
  padding: 0px;
  position: relative;
  overflow: hidden;
  border-color: red;
  border-width: 0px;
  border-style: solid;
`;

export const LogoPic = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: 54px;
  margin: 0px;

  border-width: 0px;
  border-color: orange;
  border-style: solid;
`;

export const AvatarWrapper = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

`;


export const AvatarPhoto = styled.img`
  display: inline;
  width: auto;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;

`;

export const VideoWrapper = styled.div`
  width: 100%;
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;

`;

export const VideoResponsive = styled.iframe`
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;

`;


export const Video = styled.div`
  display: inline;
  width: auto;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;

`;

export const AvatarPhotoFlipped = styled.img`
  display: inline;
  width: auto;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);

`;



export const DivRainfall = styled.div`
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  background: ${colours.verylightgray};
  border-width: 1px;
  border-color: orange;
  border-style: solid;
  min-width: 70px;

`;

export const Div1 = styled.div`
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  background: ${colours.verylightgray};
  border-width: 1px;
  border-color: verydarkgreen;
  border-style: solid;
  min-width: ${props => props.minWidth}px;
  max-width: ${props => props.maxWidth}px;

`;

export const Div2 = styled.div`
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  background: ${colours.verylightgray};
  border-width: 1px;
  border-color: darkgreen;
  border-style: solid;


`;

export const Div3 = styled.div`
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;
  background: ${colours.verylightgray};
  border-width: 1px;
  border-color: green;
  border-style: solid;


`;

export const Div4 = styled.div`
  display: flex;
  flex: 4;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  background: ${colours.verylightgray};
  border-width: 1px;
  border-color: lightgreen;
  border-style: solid;



`;

export const BarBackground = styled.div`
  height: 20px;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  background: ${colours.lightgray};
  margin-left: 5px;
  margin-right: 10px;
  border-radius: 5px;

`;
export const BlueBar = styled.div`
  height: 100%;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  background: ${props => props.value * 10 > 66 ? colours.lightgray : props.value * 10 > 33 ? colours.blue : colours.lightblue};
  width: ${props => props.value * 10}%;
  border-radius: 5px;
  transition: 'all .2s ease-out';

`;
export const YellowBar = styled.div`
  height: 100%;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  background: ${props => props.value * 10 > 66 ? colours.lightgray : props.value * 10 > 33 ? colours.yellow : colours.lightyellow};
  width: ${props => props.value * 10}%;
  border-radius: 5px;
  transition: 'all .2s ease-out';

`;

// background: linear-gradient( ${props => props.angle}, blue, blue 49%, white 49%, white 51%, red 51% );
// ${props => props.colorA}
// ${props => props.colorB}
// ${props => props.colorC}

export const HalvedDiv = styled.div`

  background: linear-gradient( ${props => props.angle}, ${props => props.colorA}, ${props => props.colorA} 49%, ${props => props.colorC} 49%, ${props => props.colorC} 51%, ${props => props.colorB} 51% );


`;


export const Logo = styled.img`


`;

export const LogoName = styled.img`
  @media (max-width: 800px) {
    display: none;
  }
  @media (max-width: 600px) {
    display: inline;
  }

`;


export const CenteredDiv = styled.div`
  margin: auto;
  padding: 50px;
  width: 230px;

`;

export const ImageDiv = styled.div`
  background: blue;
  align-items: center;
  width: 230px;

`;

export const SocialDiv = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

`;

export const DownloadDiv = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${colours.verylightgray};

`;

export const TeamDiv = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 60%;
  margin: auto;


  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }

`;

export const TeamMemberDiv = styled.div`
  background: ${colours.verylightgray};


`;

export const BulletWrapper = styled.div`
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 600px) {
    margin: 30px;
    width: auto;
  }

`;

export const BulletDiv = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;


`;

export const SocialButton = styled.button`

  padding: 10px;

`;

export const BulletNumberWrapper = styled(Bullet)`
  background-color: ${colours.orange};
  border-color: ${colours.orange};
  border-width: 0px;
  border-style: solid;
  margin-right: ${props => props.marginRight ? props.marginRight : '10px'};
`;

// export const BulletNumber = styled.p.attrs(props => ({
//   opacity: props.opacity || 1
// }))`
//   color: ${colours.white};
//   font-weight: 600;
//   font-size: 11px;
//   line-height: 30px;
//   vertical-align: middle;
//   opacity: ${props => props.opacity};
//   background-color: ${colours.orange};
//   border-radius: 30px;
//   border-color: ${colours.orange};
//   border-width: 1px;
//   border-style: solid;
//   width: 30px;
//   height: 30px;
//   margin-right: 10px;
//   min-width: 30px;
//   min-height: 30px;
// `;

export const ButtonNumber = styled.button.attrs(props => ({
  opacity: props.opacity || 1
}))`
  text-decoration: none;
  outline: none;
  opacity: ${props => props.opacity};
  width: 30px;
  height: 30px;
  margin-right: 10px;
  min-width: 30px;
  min-height: 30px;
  font-weight: 600;
  font-size: 11px;
  color: ${colours.white};
  background-color: ${colours.orange};
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border-color: ${colours.orange};
  border-width: 1px;
  border-style: solid;

  &:hover {
    background: ${colours.verylightgray};
    color: ${colours.orange};
  }
`;


// export const BackButtonButton = styled.button.attrs(props => ({
//   opacity: props.opacity || 1
// }))`
//
//   background: transparent;
//   border-style: none;
//   text-decoration: none;
//   outline: none;
//   opacity: ${props => props.opacity};
//   padding-left: 10px;
//   padding-right: 10px;
//   margin-left: 0px;
//   margin-right: 0px;
//   min-height: 30px;
//   cursor: pointer;
//
//   &:hover ${BackButtonText} {
//     color: ${colours.orange};
//   }
// `;


// export const BackButton = ({ children, ...props }) => {
//
//
//   return (
//     <BackButtonButton
//       {...props}
//     >
//       <BackButtonText>
//         {children}
//       </BackButtonText>
//
//     </BackButtonButton>
//
//   );
//
// };

export const GeneralButton = styled.button`
  font-family: ${fonts.default};
  justify-content: center;
  align-items: center;
  background: ${colours.white};
  border-color: ${colours.confirm};
  border-width: 0px;
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  elevation: 1;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 0px ${colours.shadowColour}; // width, height, radius, colour

  &:hover {
    background: ${colours.verylightgray};
    color: ${colours.lightgray};
  }
`;

export const FileUploadLabel = styled.label`
  font-family: ${fonts.default};
  justify-content: center;
  align-items: center;
  background: transparent;

  border-color: ${colours.confirm};
  border-width: 0px;
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  elevation: 1;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 0px ${colours.shadowColour}; // width, height, radius, colour

  &:hover {
    background: ${colours.verylightgray};
    color: ${colours.lightgray};
  }
`;

export const FileUploadLabelVerify = styled.label`
  font-family: ${fonts.default};
  justify-content: center;
  align-items: center;
  background: ${colours.confirm};
  height: 36px;
  border-radius: 2px;
  border-color: ${colours.confirm};
  border-width: 0px;
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  elevation: 1;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 0px ${colours.shadowColour}; // width, height, radius, colour

  &:hover {
    background: ${colours.verylightgray};
    color: ${colours.lightgray};
  }
`;

export const FileUploadIcon = styled.label`

  justify-content: center;
  align-items: center;
  background: ${colours.white};
  border-color: ${colours.confirm};
  border-width: 1px;
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  elevation: 1;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 0px ${colours.shadowColour}; // width, height, radius, colour

  &:hover {
    background: ${colours.verylightgray};
    color: ${colours.lightgray};
  }
`;


export const ViewMessagesButton = styled.button.attrs(props => ({
  opacity: props.opacity || 1
}))`
  color: ${colours.darkgray};
  background: ${colours.white};
  font-weight: 700;
  font-size: 12px;
  border-style: solid;
  text-decoration: none;
  outline: none;
  opacity: ${props => props.opacity};
  padding: 2px;
  cursor: pointer;
  border-color: ${colours.red};
  border-width: 1.5px;

  &:hover {

    color: ${colours.orange};
  }
`;

// export const MoreButtonButton = styled.button.attrs(props => ({
//   opacity: props.opacity || 1
// }))`
//   background: transparent;
//   border-style: solid;
//   outline: none;
//   opacity: ${props => props.opacity};
//   padding: 2px;
//   cursor: pointer;
//   border-color: ${colours.red};
//   border-width: 0px;
//   text-decoration: none;
//
//   &:hover ${MoreButtonText} {
//       text-decoration: none;
//     }
//
// `;

// export const MoreButton = ({ children, underline, ...props }) => {
//
//     const isUnderline = underline === undefined ? true : underline;
//     const applyUnderline = isUnderline === false ? 'none' : 'underline';
//
//     return (
//       <MoreButtonButton
//         {...props}
//       >
//         <MoreButtonText style={{ textDecoration: applyUnderline }}>
//           {children}
//         </MoreButtonText>
//
//       </MoreButtonButton>
//
//     );
//
// };

// export const MoreButtonSmall = ({ children, underline, ...props }) => {
//
//     const isUnderline = underline === undefined ? true : underline;
//     const applyUnderline = isUnderline === false ? 'none' : 'underline';
//
//     return (
//       <MoreButtonButton
//         {...props}
//       >
//         <MoreButtonTextSmall style={{ textDecoration: applyUnderline }}>
//           {children}
//         </MoreButtonTextSmall>
//
//       </MoreButtonButton>
//
//     );
//
// };


export const HelpButton = styled.button.attrs(props => ({
  opacity: props.opacity || 1
}))`
  text-decoration: none;
  outline: none;
  opacity: ${props => props.opacity};
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  min-width: 20px;
  min-height: 20px;
  font-weight: 500;
  font-size: 11px;
  color: ${colours.gray};
  background-color: ${colours.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border-color: ${colours.gray};
  border-style: solid;
  border-width: 1px;
  text-align: center;

  &:hover {
    background: ${colours.verylightgray};

  }
`;


export const HelpBubble = styled.div.attrs(props => ({
  opacity: props.opacity || 1
}))`
  position: relative;
  background: ${colours.white};
  border: 1px solid ${colours.borderColour};
  padding-left: 10px;
  padding-right: 10px;
  width: 200px;
  height: 60px;
  box-shadow: 1px 1px 1px ${colours.shadow};
  z-index: 999;


  &:after, &:before {
    left: 100%;
  	right: 20px;
    margin-right: 5px;
  	border: solid transparent;
  	content: " ";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
    z-index: 999;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
  	border-left-color: ${colours.white};
  	border-width: 10px;
  	margin-top: 20px;
    margin-left: -1px;
    z-index: 999;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
  	border-left-color: ${colours.borderColour};
  	border-width: 11px;
  	margin-top: 19px;


    z-index: 997;
  }
`;


export const SelectorButton = styled.button`
  margin-bottom: 0px;
  padding: 5px;
  width: 30px;
  font-size: 16px;
  background: ${colours.offWhite};
  color: ${colours.blue};
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: ${colours.lightgray};
  border-style: solid;
  border-radius: 2px;

  &:hover {
    background: ${colours.verylightgray};
    color: ${colours.blue};
  }
`;

export const MenuButton = styled.button`

  text-decoration: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  border-width: 0;
  border-color: ${colours.lightgray};
  border-style: solid;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background: ${colours.white};
  align-items: center;
  justify-content: center;

  /* &:hover {
    background: ${colours.lightpurple};
    color: ${colours.blue};
  } */

  &:focus {
    outline: none;
  }

  &:active {
    background: ${colours.verylightpurple};
    color: ${colours.mediumlightpurple};

  }

`;

export const DropDownButton = styled.button`

  minWidth: 50px;
  padding: 0px;
  margin: 0px;
  border: 0 solid gray;
  border-radius: 0px;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  background: ${colours.white};
  align-items: center;
  justify-content: center;
  height: 100%;


  &:hover {
    background: ${colours.verylightgray};
    color: ${colours.blue};
  }

  &:active {
    background: ${colours.verylightgray};
    color: ${colours.blue};

  }

`;

export const NavbarButton = styled.button`

  minWidth: 50px;
  padding: 0px;
  margin: 0px;
  border: 0 solid gray;
  border-radius: 0px;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  background: ${colours.white};
  align-items: center;
  justify-content: center;
  height: 100%;


`;


export const NavLink = styled(Link)`
  font-family: ${fonts.default};
  font-size: 12px;
  display: table-cell;
  padding: 10px 25px;
  border: 0px none #ddd;
  text-decoration: none;

  background: #f7f7f7;
  color: #939598;

  &:hover {
    background: ${colours.verylightgray};
    color: ${colours.blue};
  }
`;


export const ShowChartButton = styled.button`
  margin: 2px;
  padding: 5px;
  background: ${colours.blue};
  color: ${colours.verylightgray};
  right: 0;
  position: absolute;
  border-radius: 5px;

  &:hover {
    background: ${colours.verylightgray};
    color: ${colours.blue};
  }
`;

// export const EditButton = (props) => {
//   console.log('EditButton.props:', props);
//   return (
//     <Button
//       primary
//       // icon={props.icon}
//       labelPosition={props.icon ? props.labelPosition || 'left' : null}
//       fluid={props.fluid}
//       compact={props.compact}
//       disabled={props.disabled}
//
//       style={{ width: props.width || null, margin: 0, color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
//
//       onClick={props.onClick}
//     >
//       { // <Icon name={props.icon || 'edit'} />
//       }
//       {props.children || other.edit}
//     </Button>
//   );
// };

// export const OldEditButton = styled.button`
//   font-family: ${fonts.default};
//
//   justify-content: center;
//   align-items: center;
//   background: ${colours.white};
//   border-color: ${colours.confirm};
//   border-width: 0px;
//   border-style: solid;
//   padding-left: 10px;
//   padding-right: 10px;
//   margin-left: 10px;
//   margin-right: 10px;
//   elevation: 1;
//   outline: none;
//   cursor: pointer;
//   box-shadow: 0px 0px 0px ${colours.shadowColour}; // width, height, radius, colour
//
//   &:hover {
//     background: ${colours.verylightgray};
//     color: ${colours.lightgray};
//   }
// `; // confirmButton

// export const ConfirmButton = (props) => {
//   return (
//     <Button
//       primary
//       icon={props.icon}
//       labelPosition={props.icon ? props.labelPosition || 'left' : null}
//       fluid={props.fluid}
//       compact={props.compact}
//       disabled={props.disabled}
//
//       style={{ margin: 0, color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
//
//       onClick={props.onClick}
//     >
//       {
//         props.icon
//         && <Icon name={props.icon} />
//       }
//       {props.children}
//     </Button>
//   );
// };

// export const ConfirmButton = styled.button`
//
//   font-family: ${fonts.default};
//   justify-content: center;
//   align-items: center;
//   background: ${colours.confirm};
//   border-radius: 2px;
//   border-color: ${colours.confirm};
//   border-width: 0px;
//   border-style: solid;
//   padding-left: 10px;
//   padding-right: 10px;
//   padding-top: 10px;
//   padding-bottom: 10px;
//   margin-left: 10px;
//   margin-right: 10px;
//   elevation: 1;
//   outline: none;
//   cursor: pointer;
//   box-shadow: 0px 0px 0px ${colours.shadowColour}; // width, height, radius, colour
//
//   &:hover {
//     background: ${colours.lightgray};
//     color: ${colours.confirm};
//   }
//
//   &:disabled {
//     background: ${colours.lightgray};
//     color: ${colours.gray};
//   }
// `; // confirmButton

export const SubmitButton = styled.button`

  font-family: ${fonts.default};
  justify-content: center;
  align-items: center;
  background: ${colours.confirm};
  border-radius: 2px;
  border-color: ${colours.confirm};
  border-width: 0px;
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  elevation: 1;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 0px ${colours.shadowColour}; // width, height, radius, colour

  &:hover {
    background: ${colours.lightgray};
    color: ${colours.confirm};
  }

  &:disabled {
    background: transparent;
    color: transparent;
  }
`; // confirmButton


export const EmailUsButton = styled.a`

  justify-content: center;
  align-items: center;
  background: ${colours.confirm};
  border-radius: 2px;
  border-color: ${colours.confirm};
  border-width: 0px;
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  elevation: 1;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 0px ${colours.shadowColour};

  color: ${colours.offWhite};
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;

  font-family: ${fonts.default};
  font-weight: ${fonts.semibold};
  font-size: ${a4};
  text-decoration: none;

  &:hover {
    background: ${colours.lightgray};
    color: ${colours.confirm};
  }

  &:disabled {
    background: ${colours.lightgray};
    color: ${colours.gray};
  }
`; // confirmButton

export const ChatBubble = styled.div.attrs(props => ({
  borderColor: props.borderColor || colours.borderColour,
  background: props.background || colours.white


}))`

  box-shadow: 1px 1px 2px ${colours.shadow};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;

  margin-left: ${props => props.tail === 'left' && '16px'};
  margin-right: ${props => props.tail === 'right' && '16px'};

  position: relative;
  max-width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.borderColor};
  border-radius: 6px;
  border-top-left-radius: ${props => props.tail === 'left' ? '0px' : '6px'};
  border-top-right-radius: ${props => props.tail === 'right' ? '0px' : '6px'};
  background: ${props => props.background};


  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: ${props => `8px solid ${props.tail === 'right' ? props.borderColor : 'transparent'}`};
    border-right: ${props => `8px solid ${props.tail === 'left' ? props.borderColor : 'transparent'}`};
    border-top: 8px solid ${props => props.borderColor};
    border-bottom: 8px solid transparent;
    left: ${props => props.tail === 'left' && '-16px'};
    right: ${props => props.tail === 'right' && '-16px'};
    top: -1px;
  }

  &:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: ${props => `7px solid ${props.tail === 'right' ? props.background : 'transparent'}`};
    border-right: ${props => `7px solid ${props.tail === 'left' ? props.background : 'transparent'}`};
    border-top: 7px solid ${props => props.background};
    border-bottom: 7px solid transparent;
    left: ${props => props.tail === 'left' && '-14px'};
    right: ${props => props.tail === 'right' && '-14px'};
    top: 0px;
  }

  /* &:after {
      content: '';
      top: 1px;
      right: -29px;
      position: absolute;
      border: 0px solid;
      display: block;
      width: 38px;
      height: 26px;
      border-color: ${props => props.borderColor};
      background: transparent;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      box-shadow: -21px 9px 0px 8px ${props => props.background};

  } */

`;


export const ApproveButton = styled.button.attrs(props => ({
  borderColor: props.borderColor || colours.confirm
}))`

  font-family: ${fonts.default};

  justify-content: center;
  align-items: center;
  background: ${colours.white};
  border-radius: 2px;
  border-color: ${props => props.borderColor};
  border-width: 2px;
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  elevation: 1;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 0px ${colours.shadowColour}; // width, height, radius, colour

  &:hover {
    background: ${colours.lightgray};
    color: ${colours.confirm};
  }

  &:disabled {
    background: ${colours.lightgray};
    color: ${colours.gray};
  }
`;


export const CloseButton = styled.button`

  padding: 0px;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background: ${colours.red};
  color: ${colours.verylightgray};
  border-radius: 30px;
  border-width: 1px;
  border-color: ${colours.verylightgray};
  border-style: solid;
  align-items: center;
  justify-content: center;
  button:focus {outline:0;};
  text-decoration: none;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    background: ${colours.offWhite};
    color: ${colours.red};
  }

`;

export const NavButtonWrapper = styled.div`

  display: flex;
  flex-direction: row;
  background: 'blue';
  justify-content: center;


`;


export const BurgerWrapper = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  outline: none;
  border-width: 1px;
  border-color: ${colours.verylightgray};
  border-style: solid;
  padding: 8px;
  border-radius: 5px;

  &:hover {
    background: ${colours.verylightgray};

  }

`;

export const Title = styled.h1`
  font-family: ${fonts.default};
  font-size: 26px;
  text-align: left;
  color: ${colours.blue};

`;

export const H2 = styled.h2`
  font-family: ${fonts.default};
  text-align: left;
  color: ${colours.blue};
  font-size: 20px;
  line-height: 31px;
  font-weight: 300;

`;

export const H3 = styled.h3`
  font-family: ${fonts.default};
  text-align: center;
  margin: 0px;
  color: ${colours.blue};
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;


`;

export const H4 = styled.h4`
  font-family: ${fonts.default};
  text-align: center;
  margin: 0px;
  color: ${colours.blue};
  font-size: 10px;
  line-height: 22px;
  font-weight: 500;


`;

export const T4 = styled.p`
  font-family: ${fonts.default};
  text-align: center;
  margin: 0px;
  color: ${colours.darkgray};
  font-size: 10px;
  line-height: 22px;
  font-weight: 400;

`;

export const D4 = styled.p`
  font-family: ${fonts.default};
  text-align: center;
  margin: 0px;
  color: ${colours.darkgray};
  font-size: 10px;
  line-height: 22px;
  font-weight: 400;

`;

// export const P = styled.p`
//   font-family: ${fonts.default};
//   text-align: justify;
//   margin: 10px;
//   color: ${colours.heading};
//   font-size: 10px;
//   line-height: 22px;
//   font-weight: 300;
//
//
// `;

export const Pcentered = styled.p`
  font-family: ${fonts.default};
  text-align: center;
  margin: 10px;
  color: ${colours.blue};
  font-size: 10px;
  line-height: 22px;
  font-weight: 300;

`;

// export const CenteredP = styled.p`
//   margin: auto;
//   padding-left: 100px;
//   padding-right: 100px;
//   padding-bottom: 20px;
//   padding-top: 10px;
//
//   @media (max-width: 800px) {
//     padding-left: 5px;
//     padding-right: 5px;
//   }
//
// `;

export const FooterText = styled.p`
  font-family: ${fonts.default};
  text-align: center;
  color: ${colours.white};
  font-size: 12px;
  line-height: 22px;
  font-weight: 300;
  text-decoration: none;


`;

export const FooterLink = styled(Link)`
  font-family: ${fonts.default};
  color: ${colours.white};
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  border: none;
  text-decoration: none;

  &:hover {
    color: ${colours.offWhite};
  }


`;

export const Wrapper = styled.section`

`;


export const NavLabel = styled.label`

`;

export const HeaderbarWrapper = styled.div`

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background: ${colours.white};
  button:focus {outline:0;};
  padding-right: 0px;
  margin-right: 0px;

`;

export const NavbarWrapper = styled.section`

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: ${colours.verylightgray};
  button:focus {outline:0;};


`;


export const BurgerDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${colours.verylightgray};
  height: 100%;

`;

export const BurgerStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '20px',
    height: '18px',
    marginLeft: '5px',
    marginRight: '5px'

  },
  bmBurgerBars: {
    background: colours.blue,
    height: '2px'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: colours.gray
  },
  bmMenu: {
    background: colours.verylightgray,
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: colours.darkpurple
  },
  bmItemList: {
    color: colours.white,
    background: colours.verylightgray,
    padding: '0.8em'
  },
  bmOverlay: {
    background: colours.darkgray
  }
};

export const NavBarStyles = {
  // activeTabButton: { // do not use as will disable hover effects
  //   fontSize: 16,
  //   fontWeight: 'thin',
  //   textAlign: 'center',
  //   color: colours.blue,
  //   background: colours.offWhite
  // },
  // inactiveTabButton: {
  //   fontSize: 16,
  //   fontWeight: 'thin',
  //   textAlign: 'center',
  //   color: colours.gray,
  //   background: colours.verylightgray
  // },
  tabContainer: {
    color: colours.blue,
    background: colours.offWhite,
    flexDirection: 'row'

  }
};

// font-family: ${fonts.default},
// border: none,
// text-decoration: none,
// padding: 14px 16px,
// button:focus {outline:0;}
