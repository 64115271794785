/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import '@trendmicro/react-buttons/dist/react-buttons.css';
// // import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import { ReportGA } from '../../utils/ga';
// // import 'react-dropdown/style.css';
// // import 'react-select/dist/react-select.css';
import history from '../Navigation/history';
import { DropDownButton } from '../../styles/styles';
import colours from '../../styles/colours';
import * as ROUTES from '../../constants/routes';
import NavbarImage from '../general/navbar-image';
import { CALENDAR } from '../../constants/ga-category';


export default class CalendarButton extends Component {

  state = {
    open: false
  }


  handleClick = () => {

      // if click is inside, do something
      const { open } = this.state;
      this.setState({ open: !open });


  }

  handleOutsideClick = () => {

      // if click is inside, do something
      this.setState({ open: false });
      console.log('dropdown-messages handleOutsideClick:');

  }

  handleSelect = (route) => {

    // route harcoded as CALENDAR in this button

    ReportGA({
      category: CALENDAR,
      action: `User clicked on Calendar button from Navbar`
    });

    const { handleDropdownNavClick, dropdown_nav_is_open } = this.props;
    // console.log('this.props:', this.props);
    // console.log('handleSelect Messages Item dropdown_nav_is_open:', dropdown_nav_is_open);

    this.setState({ open: false }); // closes messages dropdown menu


    if (dropdown_nav_is_open) {
      // console.log('closing dropdown nav because open Dropdown Messages');
      handleDropdownNavClick(dropdown_nav_is_open); // closes dropdown nav if open
    }

    // set 'thread_back' in nav state to INBOX to help back button routing logic
    // setThreadBack(ROUTES.INBOX);
    // console.log('setThreadBack');

    history.push({
      pathname: route,
      state: { editMode: false }

    });


  }


  render () {


    const { open } = this.state;


    const { all_threads, isFetchingAllThreads, burgerIsOpen, handleBurgerClick, value, image, icon, iconSize, width, burger, user_id } = this.props;
    // console.log('render burgerIsOpen:', burgerIsOpen);
    // console.log('options', options);
    // console.log('burger:', burger);
    // console.log('width:', width);

    const dropdownValue = value;
    // console.log('options: ', options);


    let threads = [];
    let all = 0;
    let unreplied = 0;
    let finalised = 0;
    let numberOfUnreadMessages = 0;
    let numberOfThreadsRespondedTo = 0; // responded to at least once where were the sitter.
    let numberOfThreadsAsSitter = 0; // where were the sitter.

    if (!all_threads || !Array.isArray(all_threads)) {
      threads = [];

    } else {
      const new_all_threads = [...all_threads];
      all = new_all_threads.length;

      const sortedThreads = new_all_threads.sort((a, b) => (moment(a.message_array[a.message_array.length - 1].date_sent).isSameOrBefore(moment(b.message_array[b.message_array.length - 1].date_sent))) ? 1 : -1);
      console.log('sortedThreads', sortedThreads);

      threads = sortedThreads.map((thread, i) => {
        if (i < 3) {
          // console.log('thread', thread);

          // fix this to show latest message
          const message = thread.message_array[0];

          console.log('last message:', message);

          if (message.sender_id !== user_id) {
            unreplied += 1;
          }
          if (message.status === 'finalised') {
            finalised += 1;
          }
          // if last message in array sender_id is not user_id (add 1)
          if (message.sender_id !== user_id && !message.marked_read) {
            numberOfUnreadMessages += 1;
          }

          // console.log('thread_sitter_id', thread.sitter_id);
          // console.log('user_id', user_id);
          // calc response rate
          // if (user_id === thread.sitter_id) {
          //   console.log('user_id === thread.sitter_id', user_id === thread.sitter_id);
          //   numberOfThreadsAsSitter += 1;
          //   // if (thread.message_array.length > 1) {
          //   //   console.log('thread.message_array.length > 1', thread.message_array.length > 1);
          //   //   numberOfThreadsRespondedTo += 1;
          //   // }
          // }

        }
      });
    }

    return (

      <div
        style={{
          flex: 1,
          display: 'flex',
          position: 'relative',
          cursor: 'pointer',
          borderWidth: 0,
          borderColor: colours.lightgray,
          borderStyle: 'solid',
          borderRadius: 0,
          height: '100%',
          minWidth: 64
        }}
      >
        <DropDownButton

          type='button'
          onClick={() => this.handleSelect(ROUTES.AVAILABILITY)}
          onKeyDown={() => this.handleSelect(ROUTES.AVAILABILITY)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.handleSelect(ROUTES.AVAILABILITY);
            }
          }}

        >

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', borderStyle: 'solid', borderWidth: 0, borderColor: 'red' }}>

            <div style={{ borderStyle: 'solid', width: 36, height: 36, borderWidth: 0, borderColor: 'red' }}>

              {
                image
                ? <NavbarImage
                  alt='welcome'
                  style={{ width: 36,
                  // maxWidth: 200,
                  objectFit: 'contain' }}
                  src={ image }
                  />
                : <FontAwesomeIcon
                  icon={icon}
                  size={iconSize}
                  style={{ margin: 8, color: colours.mediumlightpurple }} />

              }

            </div>

          </div>


          {
            // <div style={{ position: 'absolute', display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
            //
            // </div>
          }


        </DropDownButton>

      </div>

    );
  }
}
