import * as signup from '../actions/auth/signup';
import * as login from '../actions/auth/login';
import * as referral from '../actions/auth/referral';
import * as profile from '../actions/auth/profile';
import * as verify from '../actions/verify';
import * as calendar from '../actions/calendar';
import * as register from '../actions/register';
import * as payments from '../actions/payments';
import * as trans from '../actions/transactions';
import * as submitVerifyEmail from '../actions/auth/submit-verify-email';


export const initialState = {
  isSubmittingForm: false,
  isLoggingIn: false,
  isLoggedIn: false,
  error: undefined,
  firstname: '',
  surname: '',
  email: '',
  email_verified: false,
  family_description: '',
  photo_url: '',
  user_id: '',
  kids: [],
  adults: [],
  home_address: {},
  sp: undefined,
  sp_balance: undefined,
  sp_pending: undefined,
  profile_progress: 0,
  member_since: undefined,
  revs_array: [],
  rating: undefined,
  ratings: [],
  response_rate: undefined,
  user_update_no: undefined,
  user_open_no: undefined,
  isFetching: false,
  isFetchingSuccess: false,
  isFetchingUpload: false,
  errorUpdate: undefined,
  errorUpload: undefined,
  errorUpdateNo: undefined,
  errorOpenNo: undefined,
  isWelcome: false,
  got_it: [],
  is_admin: false,
  subscription_status: 'inactive',
  subscription_id: undefined,
  plan: undefined,
  customer_id: undefined,
  cancel_at_period_end: false,
  cancel_at: undefined,
  cancelled_at: undefined,
  current_period_start: undefined,
  current_period_end: undefined,
  active_referral_code: undefined,
  referred_by: undefined,
  referral_code: undefined,
  referrals: [],
  sit_approved_req_count: 0,
  sit_approved_sit_count: 0,
  sit_finalised_count: 0,
  flexible: undefined,
  isFetchingFlexible: false

};

// set incoming active_referral_code

export default function user (state = initialState, action) {
  switch (action.type) {

    case login.LOGIN_USER_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        error: undefined
      };
    case login.LOGIN_USER_RESET:
      return {
        ...state,
        isLoggingIn: false,
        error: undefined
      };

    case referral.SET_ACTIVE_REFERRAL_CODE:
      return {
        ...state,
        active_referral_code: action.data
      };

    case register.SUBMIT_REGISTRATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSubmittingForm: true
      };
    case register.SUBMIT_REGISTRATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSubmittingForm: false,
        kids: action.data.kids,
        adults: action.data.adults,
        home_address: JSON.parse(action.data.home_address),
        sp: action.data.sp,
        profile_progress: action.data.profile_progress,
        family_description: action.data.family_description,
        member_since: action.data.member_since
      };
    case register.SUBMIT_REGISTRATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSubmittingForm: false,
        errorUpdate: action.error
      };
    case register.SUBMIT_EDIT_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSubmittingForm: true
      };
    case register.SUBMIT_EDIT_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSubmittingForm: false,
        firstname: action.data.firstname,
        surname: action.data.surname,
        email: action.data.email,
        email_verified: action.data.email_verified,
        family_description: action.data.family_description,
        profile_progress: action.data.profile_progress
      };

    case register.SUBMIT_KIDS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSubmittingForm: true
      };
    case register.SUBMIT_KIDS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSubmittingForm: false,
        kids: action.data.kids,
        sp: action.data.sp,
        profile_progress: action.data.profile_progress
      };
    case register.SUBMIT_KIDS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSubmittingForm: false,
        errorUpdate: action.error
      };

    case register.SUBMIT_ADULTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSubmittingForm: true
      };
    case register.SUBMIT_ADULTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSubmittingForm: false,
        adults: action.data.adults,
        sp: action.data.sp,
        profile_progress: action.data.profile_progress
      };
    case register.SUBMIT_ADULTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSubmittingForm: false,
        errorUpdate: action.error
      };


    case register.SUBMIT_HOME_ADDRESS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSubmittingForm: true
      };
    case register.SUBMIT_HOME_ADDRESS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSubmittingForm: false,
        home_address: JSON.parse(action.data.home_address),
        sp: action.data.sp,
        profile_progress: action.data.profile_progress
      };
    case register.SUBMIT_HOME_ADDRESS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSubmittingForm: false,
        errorUpdate: action.error
      };


    case register.SUBMIT_EDIT_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSubmittingForm: false,
        errorUpdate: action.error
      };
    case profile.UPLOAD_PHOTO_REQUEST:
      return {
        ...state,
        isFetchingUpload: true
      };
    case profile.UPLOAD_PHOTO_SUCCESS:

      console.log('action.data:', action.data);
      return {
        ...state,
        photo_url: action.data.photo_url,
        profile_progress: action.data.profile_progress,
        isFetchingUpload: false
      };
    case profile.UPLOAD_PHOTO_FAILURE:
      return {
        ...state,
        isFetchingUpload: false,
        errorUpload: action.error
      };
    case verify.UPLOAD_DOC_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFetchingUpload: true,
        isFetchingSuccess: false
      };

    case verify.UPLOAD_DOC_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchingUpload: false,
        isFetchingSuccess: false,
        adults: action.data.adults
      };

    case verify.UPLOAD_DOC_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetchingUpload: false,
        isFetchingSuccess: false,
        errorUpload: action.error
      };

    case verify.ACTION_SUCCESS:
      return {
        ...state,
        isFetchingSuccess: true
      };

    case profile.GOT_IT_WHATS_NEW_REQUEST:
      return {
        ...state,
        user_update_no: action.data
      };
    case profile.UPDATE_OPEN_NO_REQUEST:
      return {
        ...state,
        user_open_no: action.data
      };

    case profile.CHANGE_NAME:
      return handleChangeName(state, action);

    case signup.SIGNUP_USER_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case signup.SIGNUP_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoggingIn: false,
        isLoggedIn: true,
        firstname: action.data.firstname,
        surname: action.data.surname,
        email: action.data.email,
        family_description: action.data.family_description,
        referral_code: action.data.referral_code,
        referred_by: action.data.referred_by,
        photo_url: action.data.photo_url,
        user_id: action.data.user_id,
        home_address: JSON.parse(action.data.home_address),
        kids: action.data.kids,
        adults: action.data.adults,
        sp: action.data.sp,
        profile_progress: action.data.profile_progress,
        member_since: action.data.member_since,
        sp_balance: action.data.sp_balance,
        sp_pending: action.data.sp_pending,
        revs_array: action.data.revs_array,
        error: undefined,
        isWelcome: true
        // available_dates: JSON.parse(action.data.available_dates) - replaced by new calendar reducer
      }; // do we need to add verified_status?

    case signup.SIGNUP_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case login.LOGIN_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoggingIn: false,
        isLoggedIn: true,
        firstname: action.data.firstname,
        surname: action.data.surname,
        email: action.data.email,
        email_verified: action.data.email_verified,
        family_description: action.data.family_description,
        referral_code: action.data.referral_code,
        referred_by: action.data.referred_by,
        referrals: action.data.referrals,
        photo_url: action.data.photo_url,
        user_id: action.data.user_id,
        is_admin: action.data.is_admin,
        home_address: JSON.parse(action.data.home_address),
        kids: action.data.kids,
        adults: action.data.adults,
        got_it: action.data.got_it,
        sp: action.data.sp,
        profile_progress: action.data.profile_progress,
        member_since: action.data.member_since,
        sp_balance: action.data.sp_balance,
        sp_pending: action.data.sp_pending,
        // available_dates: JSON.parse(action.data.available_dates) - replaced by new calendar reducer
        revs_array: action.data.revs_array,
        subscription_status: action.data.subscription_status,
        subscription_id: action.data.subscription_id,
        plan: action.data.plan,
        customer_id: action.data.customer_id,
        cancel_at_period_end: action.data.cancel_at_period_end,
        cancel_at: action.data.cancel_at,
        cancelled_at: action.data.cancelled_at,
        current_period_start: action.data.current_period_start,
        current_period_end: action.data.current_period_end,
        verified_status: action.data.verified_status,
        rating: action.data.rating,
        ratings: action.data.ratings,
        sit_approved_req_count: action.data.sit_approved_req_count,
        sit_approved_sit_count: action.data.sit_approved_sit_count,
        error: undefined

      };

    case login.LOGIN_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoggingIn: false,
        error: action.error
      };


    case profile.SET_WELCOME_FALSE:
      return {
        ...state,
        isWelcome: false
      };

      case profile.SET_GOT_IT_REQUEST:
        return {
          ...state,
          isFetching: true
        };

      case profile.SET_GOT_IT_SUCCESS:
        return {
          ...state,
          isFetching: false,
          got_it: action.data,
          error: undefined
        };

      case profile.SET_GOT_IT_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: action.error
        };

      case calendar.SET_USER_FLEXIBLE_REQUEST:
        return {
          ...state,
          isFetchingFlexible: true
        };

      case calendar.SET_USER_FLEXIBLE_SUCCESS:
        return {
          ...state,
          isFetchingFlexible: false,
          flexible: action.data,
          error: undefined
        };

      case calendar.SET_USER_FLEXIBLE_FAILURE:
        return {
          ...state,
          isFetchingFlexible: false,
          error: action.error
        };


    case profile.GOT_IT_WHATS_NEW_SUCCESS:
      return {
        ...state
      };
    case profile.UPDATE_OPEN_NO_SUCCESS:
      return {
        ...state
      };


    case profile.GOT_IT_WHATS_NEW_FAILURE:
      return {
        ...state,
        errorUpdateNo: action.error
      };
    case profile.UPDATE_OPEN_NO_FAILURE:
      return {
        ...state,
        errorOpenNo: action.error
      };

    case profile.GET_USERNOS_REQUEST:
      return {
        ...state
      };
    case profile.GET_USERNOS_SUCCESS:
      return {
        ...state,
        user_update_no: action.data.update_no,
        user_open_no: action.data.open_no
      };
    case profile.GET_USERNOS_FAILURE:
      return {
        ...state
      };
    case profile.GET_USER_REQUEST:
      return {
        ...state
      };
    case profile.GET_USER_SUCCESS:
      return {
        ...state,
        firstname: action.data.firstname,
        surname: action.data.surname,
        email: action.data.email,
        email_verified: action.data.email_verified,
        is_admin: action.data.is_admin,
        family_description: action.data.family_description,
        referral_code: action.data.referral_code,
        referred_by: action.data.referred_by,
        referrals: action.data.referrals,
        photo_url: action.data.photo_url,
        home_address: JSON.parse(action.data.home_address),
        kids: action.data.kids,
        adults: action.data.adults,
        got_it: action.data.got_it,
        sp: action.data.sp,
        profile_progress: action.data.profile_progress,
        member_since: action.data.member_since,
        sp_balance: action.data.sp_balance,
        sp_pending: action.data.sp_pending,
        revs_array: action.data.revs_array,
        subscription_status: action.data.subscription_status,
        subscription_id: action.data.subscription_id,
        plan: action.data.plan,
        customer_id: action.data.customer_id,
        cancel_at_period_end: action.data.cancel_at_period_end,
        cancel_at: action.data.cancel_at,
        cancelled_at: action.data.cancelled_at,
        current_period_start: action.data.current_period_start,
        current_period_end: action.data.current_period_end,
        verified_status: action.data.verified_status,
        rating: action.data.rating,
        ratings: action.data.ratings,
        sit_approved_req_count: action.data.sit_approved_req_count,
        sit_approved_sit_count: action.data.sit_approved_sit_count,
        flexible: action.data.flexible
      };
    case profile.GET_USER_FAILURE:
      return {
        ...state
      };

    case payments.SUBMIT_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case payments.SUBMIT_SUBSCRIPTION_SUCCESS:

      return {
        ...state,
        subscription_status: action.data.subscription_status,
        current_period_start: action.data.current_period_start,
        current_period_end: action.data.current_period_end,
        isFetching: false
      };

    case payments.SUBMIT_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false
      };

    case payments.SUBMIT_DOWNGRADE_SUCCESS:

      return {
        ...state,
        cancel_at_period_end: action.data.cancel_at_period_end,
        cancel_at: action.data.cancel_at,
        cancelled_at: action.data.cancelled_at,
        current_period_start: action.data.current_period_start,
        current_period_end: action.data.current_period_end
      };

    case trans.GET_ALL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        sp_balance: action.data.sp_balance,
        sp_pending: action.data.sp_pending

      };

    case submitVerifyEmail.SUBMIT_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        got_it: action.data.got_it

      };


    default:
      return state;
  }
}

function handleChangeName (state, action) {
  if (action.category === 'firstname') {
    return {
      ...state,
      firstname: action.value,
      isFetching: false
    };
  }
  if (action.category === 'surname') {
    return {
      ...state,
      surname: action.value,
      isFetching: false
    };
  }
}
