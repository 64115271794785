import { connect } from 'react-redux';
import { ReportGA } from '../../utils/ga';
import SearchBanner from '../../components/search/search-banner';
import { showModal, hideModal } from '../../actions/modal';
import { showSearchBanner, hideSearchBanner, toggleCollapse } from '../../actions/search';
import { loginUser, loginUserReset } from '../../actions/auth/login';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_SIGNIN, MODAL_TYPE_SEARCH } from '../../components/modals/modalTypes';
import history from '../../components/Navigation/history';
import { getTariffById } from '../../actions/auth/account';
import { PROFILE_SUMMARY } from '../../constants/routes';
import { PROFILE, SEARCH, VERIFY as VERI } from '../../constants/ga-category';

const mapStateToProps = ({ user, messageThread, dashboard, search, editSearch, nav, inbox, tariff, layout }, ownProps) => {

  return {
    // serverError: user.error,
    // isConnected: network.isConnected,
    location: ownProps.location,
    window_width: layout.window_width,
    showOnMount: ownProps.showOnMount,
    user_id: user.user_id,
    home_address: user.home_address,
    isFetchingNearbyUsers: dashboard.isFetchingNearbyUsers,
    isLoggingIn: user.isLoggingIn,
    profile_progress: user.profile_progress,
    verified_status: user.verified_status,
    isSearching: search.isSearching,
    isWelcome: user.isWelcome,
    dropdown_nav_is_open: nav.dropdown_nav_is_open,
    allThreads: Array.isArray(inbox.all_threads) ? inbox.all_threads : [],
    saved_start_date: search.saved_start_date,
    saved_end_date: search.saved_end_date,
    saved_start_hr: search.saved_start_hr,
    saved_end_hr: search.saved_end_hr,
    saved_start_min: search.saved_start_min,
    saved_end_min: search.saved_end_min,
    saved_kids: search.saved_selected_kids,
    sp_balance: user.sp_balance,
    edited_thread_details: editSearch,
    editMode: messageThread.editMode,
    start_date: search.start_date, // this is date when creating NEW search should it be replaced with edited_thread_details?
    end_date: search.end_date, // this is date when creating NEW search should it be replaced with edited_thread_details?
    searchBannerIsOpen: search.banner_is_open,
    submittedSearch: search.submittedSearch,
    tariff_details: tariff.tariff_details,
    collapsed: search.collapsed
  };

};

const mapDispatchToProps = dispatch => ({
  handleSubmitForm: ({ email, password }, props, navigation) => { //eslint-disable-line
    // dispatch(hideModal());
    dispatch(showModal(MODAL_TYPE_SIGNIN, {

    }));
    dispatch(loginUser(email.toLowerCase(), password, navigation.navigation));

  },
  handleResetLogin: () => {
    dispatch(loginUserReset());
  },

  showSearchModal: () => {
    dispatch(showModal(MODAL_TYPE_SEARCH, {

    }));
  },
  handleGetTariff: (tariff_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getTariffById(token, tariff_id));
    }

  },

  showSpinnerModal: () => {
    dispatch(showModal(MODAL_TYPE_SPINNER, {

    }));
  },
  hideModal: () => {
    dispatch(hideModal());
  },
  showSearchBanner: () => {
    dispatch(showSearchBanner());
  },
  hideSearchBanner: () => {
    dispatch(hideSearchBanner());
  }, // redundant?
  toggleCollapse: () => {

    ReportGA({
      category: SEARCH,
      action: "User clicked Edit Search in SearchBanner"
    });

    dispatch(toggleCollapse());
  },
  handleClickProfile: () => {

    ReportGA({
      category: PROFILE,
      action: "User clicked Complete Profile in SearchBanner"
    });

    history.push({
      pathname: PROFILE_SUMMARY
    });
    dispatch(hideModal());

  },
  handleClickGetVerified: () => {

    ReportGA({
      category: VERI,
      action: "User clicked Get Verified in SearchBanner"
    });

    history.push({
      pathname: VERI
    });
    dispatch(hideModal());

  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBanner);
