import React, { useState } from 'react';
import {
  Button, Icon
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';

// opacity: props.opacity || 1

export const StyledButton = styled(Button)`

  display: inline-block!important;

  margin: 0px!important;
  color: ${props => props.color ? props.color : colours.logopurple}!important;
  background: ${props => props.background ? props.background : 'transparent'}!important;
  border-radius: ${props => props.rounded ? '30px' : '0px'}!important;
  border-width: ${props => props.selected ? props.rounded ? '1px' : '0px' : '0px'}!important;
  border-bottom-width: ${props => props.selected ? props.rounded ? '1px' : '2px' : '0px'}!important;
  border-style: solid!important;
  border-color: ${props => props.light ? colours.darkpurple : colours.logopurple}!important;

  padding: 8px!important;
  padding-left: ${props => props.selected ? props.rounded ? '7px' : '16px' : '16px'}!important;
  padding-right: ${props => props.selected ? props.rounded ? '7px' : '16px' : '16px'}!important;
  padding-bottom: ${props => props.selected ? props.rounded ? '7px' : '6px' : '8px'}!important;
  min-width: 60px!important;
  min-height: 50px!important;
  cursor: pointer!important;


`;


const ChatNavButton = (props) => {

  const [hover, setHover] = useState(false);
  const [clicked, setClicked] = useState(false);

  const { onClick } = props;

  const toggleHover = (newHover) => {
      if (hover === undefined) {
        setHover(!hover);
      } else {
        setHover(newHover);
      }
  };

  const handleClick = () => {

      if (onClick) {
        onClick();
      }

      toggleHover(false); // removes any hover effects on click

      // trigger touch feedback
      setClicked(true);
      setTimeout(() => {
        setClicked(false);
      }, 20);

  };


  const isHovered = hover;

  const color = props.color || colours.darkpurple;
  const background = clicked ? colours.darkpurple : props.selected ? props.rounded ? colours.lightgray : colours.white : colours.white;
  const hoveredBackground = colours.verylightpurple;

  return (
    <StyledButton
      primary={props.primary}
      icon={props.icon}
      labelPosition={props.icon ? props.labelPosition || 'left' : null}
      fluid={props.fluid}
      compact={props.compact}
      disabled={props.disabled}
      light={props.light}
      rounded={props.rounded}

      color={isHovered ? background : color}
      background={isHovered ? hoveredBackground : background}

      onClick={() => handleClick()}

      // ishovered={isHovered}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}

      selected={props.selected}
    >
      {
        props.icon
        && <Icon name={props.icon} />
      }
      {props.children}

    </StyledButton>
  );
};

export default ChatNavButton;
