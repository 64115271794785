
// import initSocket from '../socket-router';
import history from '../../components/Navigation/history';
import * as ROUTES from '../../constants/routes';

// require('dotenv').config();


export const SET_SELECTED_PHOTO_FILE = 'SET_SELECTED_PHOTO_FILE';
export const UPLOAD_PHOTO_REQUEST = 'UPLOAD_PHOTO_REQUEST';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAILURE = 'UPLOAD_PHOTO_FAILURE';
export const GET_VIEWED_FAMILY_REQUEST = 'GET_VIEWED_FAMILY_REQUEST';
export const GET_VIEWED_FAMILY_SUCCESS = 'GET_VIEWED_FAMILY_SUCCESS';
export const GET_VIEWED_FAMILY_FAILURE = 'GET_VIEWED_FAMILY_FAILURE';
export const SET_VIEWED_FAMILY = 'SET_VIEWED_FAMILY';


export function setSelectedPhotoFile (data) {
  return {
    type: SET_SELECTED_PHOTO_FILE,
    data
  };
}

export const getViewedFamilyRequest = () => ({
  type: GET_VIEWED_FAMILY_REQUEST
});

export const getViewedFamilySuccess = data => ({
  type: GET_VIEWED_FAMILY_SUCCESS,
  data
});

export const getViewedFamilyFailure = error => ({
  type: GET_VIEWED_FAMILY_FAILURE,
  error
});

export function getViewedFamily (token, user_id, home_address) {
  // ajax req getUser and then setViewedFamily
  console.log('getViewedFamily action data user_id', user_id);
  console.log('getViewedFamily home_address', home_address);
  return (dispatch) => {

    dispatch(getViewedFamilyRequest());


    fetch(`${process.env.REACT_APP_API_URI}/viewed_user/${user_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      },
      body: JSON.stringify({ home_address })
    })
    .then((res) => {
      console.log('getViewedUser res:', res);
      res.json()
      .then((data) => {
        console.log('getViewedUser response data:', data);

        dispatch(getViewedFamilySuccess(data));
        history.push({
          pathname: ROUTES.REQUEST_PROFILE,
          state: { editMode: false }
        });

      })
      .catch((err) => {
        console.log('caught getViewedUser error: ', err);
        dispatch(getViewedFamilyFailure(err.message));
      });
    })
    .catch((err) => {
      console.log('caught getViewedUser error: ', err);
      dispatch(getViewedFamilyFailure(err.message));
    });
  };

}


export function setViewedFamily (data) {
  console.log('setViewedFamily action data', data);
  history.push({
    pathname: ROUTES.REQUEST_PROFILE,
    state: { editMode: false }
  });

  return {
    type: SET_VIEWED_FAMILY,
    data
  };
}


export const uploadPhotoRequest = () => ({
  type: UPLOAD_PHOTO_REQUEST
});

export const uploadPhotoSuccess = data => ({
  type: UPLOAD_PHOTO_SUCCESS,
  data
});

export const uploadPhotoFailure = error => ({
  type: UPLOAD_PHOTO_FAILURE,
  error
});


export function uploadPhoto (token, formData) {
  console.log('Photo formData: ', formData);
  console.log('UploadPhotoURL: ', `${process.env.REACT_APP_API_URI}/upload`);
  console.log('token: ', token);
  return (dispatch) => {
    dispatch(uploadPhotoRequest());
    fetch(`${process.env.REACT_APP_API_URI}/upload`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',

        authorization: token
      },
      body: formData
    })
    .then((res) => {
      console.log('uploadPhoto res', res);
      res.json()
      .then((data) => {
        console.log('Photo response data: ', data);
        dispatch(uploadPhotoSuccess(data));
      })
      .catch((e) => {
        console.log('Photo response error: ', e);
        dispatch(uploadPhotoFailure('Something went wrong'));
      });
    })
    .catch((e) => {
      console.log('Photo response error: ', JSON.stringify(e));
      dispatch(uploadPhotoFailure('Something went wrong'));
    });
  };
}
