
export const GET_NEARBY_USERS_REQUEST = 'GET_NEARBY_USERS_REQUEST';
export const GET_NEARBY_USERS_SUCCESS = 'GET_NEARBY_USERS_SUCCESS';
export const GET_NEARBY_USERS_FAILURE = 'GET_NEARBY_USERS_FAILURE';


// export const getNearbyUsersRequest = () => ({
//   type: GET_NEARBY_USERS_REQUEST
// });
//
// export const getNearbyUsersSuccess = data => ({
//   type: GET_NEARBY_USERS_SUCCESS,
//   data
// });
//
// export const getNearbyUsersFailure = error => ({
//   type: GET_NEARBY_USERS_FAILURE,
//   error
// });

// do we need getNearbyUsers? replaced by submitSearch?
// export function getNearbyUsers (token, home_address) {
//   return (dispatch) => {
//
//     dispatch(getNearbyUsersRequest());
//     // console.log('getNearbyUsers action for home_address: ', home_address);
//
//     // TODO - need to add start and end timestamps to search and db query
//
//
//     fetch(`${process.env.REACT_APP_API_URI}/users/nearby_users`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//         authorization: token,
//         'Cache-Control': 'no-cache',
//         Pragma: 'no-cache'
//       },
//       body: JSON.stringify({ home_address })
//     })
//     .then((res) => {
//       // console.log('nearbyUsers res:', res);
//       res.json()
//       .then((data) => {
//         // console.log('getNearbyUsers response data:', data);
//
//         dispatch(getNearbyUsersSuccess(data));
//
//       })
//       .catch((err) => {
//         console.log('caught getNearbyUsers error: ', err);
//         dispatch(getNearbyUsersFailure(err.message));
//       });
//     })
//     .catch((err) => {
//       console.log('caught getNearbyUsers error: ', err);
//       dispatch(getNearbyUsersFailure(err.message));
//     });
//   };
// }
