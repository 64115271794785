import { connect } from 'react-redux';
import RegisterProgBar from '../../components/Navigation/registerProgBar';
import { persistor, store } from '../../init-store';
import { logout } from '../../actions/auth/signup';
import { showModal, hideModal } from '../../actions/modal';
import { openBurger, closeBurger } from '../../actions/navbar';
// import { setNoOfChildren } from '../../actions/register/aboutChildren';
import * as ROUTES from '../../constants/routes';
import history from '../../components/Navigation/history';
import { MODAL_TYPE_SIGNIN, MODAL_TYPE_SIGNUP } from '../../components/modals/modalTypes';

const mapStateToProps = ({ user, nav, register }) => {
  // console.log('this.state:', this.state);
  console.log('localStorage: ', localStorage);
  return {
    isLoggedIn: user.isLoggedIn,
    isLoggingIn: user.isLoggingIn,
    firstname: user.firstname,
    burgerIsOpen: nav.burgerIsOpen,
    progressStep: register.progressStep,
    noOfChildren: register.noOfChildren
  };
};

const mapDispatchToProps = dispatch => ({
  handleLogOut: () => {
    console.log('localStorage: ', localStorage);
    localStorage.removeItem('sitterpoint_token');

    localStorage.removeItem('sitterpoint_user_id');

    history.push(ROUTES.LANDING);

    // const rootNavigator = nav.getNavigator('root');
    // rootNavigator.replace('auth');
    // clean up persisted state
    persistor.purge();
    // store.getState().network.socket.disconnect();
    dispatch(logout());
    // redirect

  },

  // setNoOfChildren: (value) => {
  //   console.log('setNoOfChildren value: ', value);
  //
  //   dispatch(setNoOfChildren(value));
  //
  // },

  handleBurgerClick: (burgerIsOpen) => {
    if (burgerIsOpen) {
      dispatch(closeBurger());
    } else {
      console.log('registerProgBar openBurger');
      dispatch(openBurger());
    }
  },
  handleCloseBurger: () => {
    dispatch(closeBurger());
  },
  
  hideModal: () => {
    dispatch(hideModal());
  }
});

const RegisterProgBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterProgBar);

export default RegisterProgBarContainer;
