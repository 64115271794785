import React from 'react';
import {
    Image
} from "semantic-ui-react";
import { H1, H2, H3, H5 } from '../../styles/text';
import colours from '../../styles/colours';


const CardBanner = ({ title, alignTitle, alignSubtitle, children, largeText, color, paragraph, paddingBottom }) => {

    return (

      <div style={{ height: 'auto', width: '100%', padding: 16, marginTop: 0, background: colours.mediumlightpurple, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderWidth: 0, borderStyle: 'solid', borderColor: 'red' }}>

        { largeText
          ?
            <H1
              textAlign={alignTitle || 'center'}
              color={color || (colours.white)}
            >
              {title}{children}
            </H1>
          :
            <H2
              textAlign={alignTitle || 'center'}
              color={color || (colours.white)}
            >
              {title}{children}
            </H2>
        }
        {
          paragraph && <div style={{ paddingTop: 4, paddingBottom: paddingBottom || 12, display: 'flex', justifyContent: alignSubtitle || 'flex-start' }}>
            {paragraph}
          </div>
        }


      </div>
    );
};

export default CardBanner;
