import * as signup from '../actions/auth/signup';
import * as login from '../actions/auth/login';
import * as profile from '../actions/auth/profile';
import * as payments from '../actions/payments';

// change plan to to match Stripe on go-live
// const initial_plan = process.env.REACT_APP_APP_NODE_ENV === 'production'
//   ? 'price_1MNyydHtSJiPIGEeNpUTiAwu' // live monthly
//   : 'price_1LhVunHtSJiPIGEeHyV8TNNF'; // dev daily


export const initialState = {
  selected_plan: undefined, // should fetch this and current pricing from database on app load.
  selected_plan_details: undefined,
  coupon: '',
  error: undefined,
  errorCoupon: undefined,
  validatedCoupon: '',
  isCheckingCoupon: false,
  isSendingPayment: false,
  errorPayment: undefined,
  // payments: [],
  cardElementsComplete: false,
  products_plans: [],
  default_plan: undefined,
  isFetchingPlans: false,
  customer: undefined,
  payment_methods: undefined,
  show_downgrade: false,
  show_subscribe_payment: false

};

export default function paymentsReducer (state = initialState, action) {
  switch (action.type) {

    // case signup.SIGNUP_USER_SUCCESS:
    //   return {
    //     ...state,
    //     payments: action.data.payments
    //   };
    // case login.LOGIN_USER_SUCCESS:
    //   return {
    //     ...state,
    //     payments: action.data.payments
    //   };
    // case profile.GET_USER_SUCCESS:
    //   return {
    //     ...state,
    //     payments: action.data.payments
    //   };

    case payments.SET_CURRENT_PLAN:
      return {
        ...state,
        selected_plan: action.data,
        selected_plan_details: state.products_plans.filter(item => item.name === 'Sitterpoint Membership')[0].plans.filter(plan => plan.id === action.data)[0]
      };

    case payments.SET_COUPON:
      return {
        ...state,
        coupon: action.data
      };

    case payments.SUBMIT_COUPON_REQUEST:
      return {
        ...state,
        isCheckingCoupon: true,
        errorCoupon: undefined
      };
    case payments.SUBMIT_COUPON_SUCCESS:
      return {
        ...state,
        isCheckingCoupon: false,
        validatedCoupon: action.data

      };
    case payments.SUBMIT_COUPON_FAILURE:
      return {
        ...state,
        isCheckingCoupon: false,
        errorCoupon: action.error
      };

    case payments.SUBMIT_PAYMENT_REQUEST:
      return {
        ...state,
        isSendingPayment: true
      };
    case payments.SUBMIT_PAYMENT_SUCCESS:
      return {
        ...state,
        isSendingPayment: false
        // payments: action.data.payments

      };
    case payments.SUBMIT_PAYMENT_FAILURE:
      return {
        ...state,
        isSendingPayment: false,
        errorPayment: action.error
      };

    case payments.SET_CARD_ELEMENTS_COMPLETE:
      return {
        ...state,
        cardElementsComplete: action.data
      };

    case payments.RESET_PAYMENT_FORM:
      return {
        ...state,
        coupon: '',
        error: undefined,
        errorCoupon: undefined,
        validatedCoupon: '',
        isCheckingCoupon: false,
        isSendingPayment: false,
        errorPayment: undefined,
        cardElementsComplete: false
      };

      case payments.GET_PLANS_REQUEST:
        return {
          ...state,
          isFetchingPlans: true
        };

      case payments.GET_PLANS_SUCCESS: {
        
        const PLAN_FOR_TESTING = 'daily'; // or 'monthly'
         // if dev then use daily plan (to test subs and renewals), else use default plan action.data.filter(item => item.name === 'Sitterpoint Membership')[0].default_price
      

        const newSelectedPlanId = process.env.NODE_ENV === 'development'
          ? PLAN_FOR_TESTING === 'daily'
            ? action.data.filter((item) => item.name === 'Sitterpoint Membership')[0].plans.filter((item) => item.nickname === 'Daily')[0].id
            : action.data.filter((item) => item.name === 'Sitterpoint Membership')[0].default_price
          : action.data.filter((item) => item.name === 'Sitterpoint Membership')[0].default_price;
  
        const newSelectedPlanDetails = action.data.filter((item) => item.name === 'Sitterpoint Membership')[0]
        .plans.filter((plan) => plan.id === newSelectedPlanId)[0];

        return {
          ...state,
          products_plans: action.data,
          default_plan: action.data.filter((item) => item.name === 'Sitterpoint Membership')[0].default_price,
          selected_plan: newSelectedPlanId,
          selected_plan_details: newSelectedPlanDetails,
          isFetchingPlans: false
        };
      }

      case payments.GET_PLANS_FAILURE:
        return {
          ...state,
          error: action.error,
          isFetchingPlans: false
        };

      case payments.GET_PAYMENT_METHOD_REQUEST:
        return {
          ...state,
          isFetching: true
        };
      case payments.GET_PAYMENT_METHOD_SUCCESS:
        return {
          ...state,
          customer: action.data.customer,
          payment_methods: action.data.payment_methods,
          isFetching: false
        };
      case payments.GET_PAYMENT_METHOD_FAILURE:
        return {
          ...state,
          error: action.error,
          isFetching: false
        };

      case payments.ATTACH_PAYMENT_METHOD_REQUEST:
        return {
          ...state,
          isSendingPayment: true
        };
      case payments.ATTACH_PAYMENT_METHOD_SUCCESS:
        return {
          ...state,
          isSendingPayment: false,
          customer: action.data.customer,
          payment_methods: action.data.payment_methods,
          // show_downgrade: false,
          show_subscribe_payment: false
        };
      case payments.ATTACH_PAYMENT_METHOD_FAILURE:
        return {
          ...state,
          isSendingPayment: false,
          errorPayment: action.error
        };

      case payments.SET_SHOW_SUBSCRIBE_PAYMENT:
        return {
          ...state,
          // show_downgrade: false,
          show_subscribe_payment: action.data
        };


    default:
      return state;
  }
}
