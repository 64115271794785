import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";

export const StyledP = styled.p`


  font-weight: ${(props) => props.fontWeight ? props.fontWeight : fonts.normal}!important;
  color: ${(props) => props.color ? props.color : colours.gray}!important;
  text-align: ${(props) => props.textAlign ? props.textAlign : 'left'}!important;
  font-size: ${(props) => props.fontSize ? props.fontSize : '16px'}!important;
  text-overflow: ${(props) => props.textOverflow}!important;
  white-space: ${(props) => props.whiteSpace ? props.whiteSpace : 'pre-line'}!important;
  overflow: ${(props) => props.overflow}!important;

  display: ${(props) => props.maxLines ? '-webkit-box' : null};
   -webkit-line-clamp: ${(props) => props.maxLines ? props.maxLines : null}; /* number of lines to show */
   -webkit-box-orient: ${(props) => props.maxLines ? 'vertical' : null};

  /* font-weight: ${fonts.semibold}; */

`;

const mapStateToProps = (layout, ownProps) => {

    const { color, textAlign, maxLines, ...rest } = ownProps;

    return {
      screensize: layout.screensize,
      color: ownProps.color,
      textAlign: ownProps.textAlign,
      maxLines: ownProps.maxLines,
      ...rest
    };

};

class P extends Component {

  render () {

    const { screensize, color, textAlign, fontWeight, fontSize, textOverflow, whiteSpace, overflow, maxLines } = this.props;
    console.log('P props:', this.props);

    return (
      <StyledP
        screensize={screensize}
        fontWeight={fontWeight || null}
        fontSize={fontSize || null}
        textOverflow={textOverflow || null}
        whiteSpace={whiteSpace || null}
        overflow={overflow || null}
        maxLines={maxLines || null}
        color={color || null}
        textAlign={textAlign || null}

      >
        {this.props.children}
      </StyledP>
    );

  }
}

export default connect(
    mapStateToProps
)(P);
