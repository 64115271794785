import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";


export const StyledP = styled.p`

  margin: 0px!important;
  font-weight: ${props => props.fontWeight ? props.fontWeight : fonts.normal}!important;
  color: ${props => props.color ? props.color : colours.gray}!important;
  text-align: ${props => props.textAlign ? props.textAlign : 'left'}!important;
  font-size: ${props => props.screensize = 'small' ? '0.8em' : '3em'}!important;

`;

const mapStateToProps = (layout, ownProps) => {

    return {
      screensize: layout.screensize,
      color: ownProps.color,
      textAlign: ownProps.textAlign,
      ...ownProps
    };

};


class A7 extends Component {

  render () {

    const { screensize, color, textAlign, fontWeight } = this.props;
    // console.log('A6 props:', this.props);

    return (
      <StyledP

        screensize={screensize}

        color={color || null}
        textAlign={textAlign || null}
        fontWeight={fontWeight}

      >
        {this.props.children}
      </StyledP>
    );

  }
}

export default connect(
    mapStateToProps
)(A7);
