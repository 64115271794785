/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import {
    Menu
} from "semantic-ui-react";
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
// import '@trendmicro/react-buttons/dist/react-buttons.css';
// import '@trendmicro/react-dropdown/dist/react-dropdown.css';
// import 'react-dropdown/style.css';
// import 'react-select/dist/react-select.css';
import { FormValueText } from '../../../styles/text';
import colours from '../../../styles/colours';
import SearchDatePickerContainer from '../../../containers/calendar/search-datepicker';


const defaultStyle = (fullWidth, width) => {

  console.log('fullWidth: ', fullWidth);
  console.log('width: ', width);
  return ({
    opacity: 1,
    position: 'absolute', // fullWidth ? 'fixed' : 'absolute'
    top: 28, // same as your nav height    fullWidth ? 178 : 28
    right: fullWidth ? null : 0,
    // left: fullWidth ? '10%' : null,
    left: fullWidth ? -28 : 0,
    margin: 0,
    // marginLeft: fullWidth ? (width) * -0.6 : 0,
    marginLeft: fullWidth ? 0 : 0,
    background: 'transparent',
    padding: 0,
    paddingLeft: fullWidth ? 10 : 0,
    paddingRight: fullWidth ? 10 : 0,
    textAlign: 'center',
    // transition: 'all 1000 ease',
    zIndex: 2000,
    width: fullWidth ? (width * 1) - 0 : 375 + 8
  });

};


// const slideDownStyles = {
//
//   entering: { transform: 'translateY(-100%)', opacity: 0, height: 0, transition: 'all .9s cubic-bezier(0, 1, 0.5, 1)' }, // transition: .3s cubic-bezier(0, 1, 0.5, 1);
//   entered: { transform: 'translateY(0%)', opacity: 1, height: 200, transition: 'all .9s cubic-bezier(0, 1, 0.5, 1)' },
//   exiting: { transform: 'translateY(0%)', transition: '.9s ease-in-out' },
//   exited: { transform: 'translateY(-100%)' }
// };

const fadeInStyles = {

  entering: { opacity: 0 },
  entered: { transition: 'opacity 200ms linear', opacity: 1 },
  exiting: { transition: 'opacity 100ms linear', opacity: 0 },
  exited: { opacity: 0 }
};


export default class DropdownDateValue extends Component {

  constructor (props) {
    super(props);

    this.state = {
      // open: false, // may be redundant if use redux state
      datePickerTop: 0,
      datePickerLeft: 0,
      datePickerRight: 0,
      datePickerWidth: 0

    };

    this.dateDropdownDivRef = React.createRef();
    // this.dateDropdownItemsDivRef = React.createRef();
    console.log('this.dateDropdownDivRef:', this.dateDropdownDivRef);

  }


  componentDidMount () {

    // Assign click handler to listen the click to close the dropdown when clicked outside
    document.addEventListener("click", this.handleClickOutside);
    // document.addEventListener("mousedown", this.handleClickOutside);

    // console.log('this.dateDropdownDivRef: ', this.dateDropdownDivRef);
    // console.log('this.dateDropdownDivRef.current: ', this.dateDropdownDivRef.current);
    // console.log('this.dateDropdownDivRef.current.getBoundingClientRect(): ', this.dateDropdownDivRef.current.getBoundingClientRect());
    const newdatePickerTop = this.dateDropdownDivRef.current.getBoundingClientRect().bottom;
    const newdatePickerLeft = this.dateDropdownDivRef.current.getBoundingClientRect().left;
    const newdatePickerRight = this.dateDropdownDivRef.current.getBoundingClientRect().right;
    const newdatePickerWidth = this.dateDropdownDivRef.current.getBoundingClientRect().width;

    // console.log('newdatePickerRight', newdatePickerRight);

    this.setState({ datePickerTop: newdatePickerTop, datePickerLeft: newdatePickerLeft, datePickerRight: newdatePickerRight, datePickerWidth: newdatePickerWidth });

  }


  componentWillReceiveProps (nextProps) {

    // closes dropdown when search banner is closed
    if (!nextProps.searchBannerIsOpen) {
      console.log('dateValue WillReceive:', nextProps);
      // this.setState({ open: false }); removed as causing dropdown to close on click date
    }
  }

  componentWillUnmount () {
    // Remove the listener
    document.removeEventListener("click", this.handleClickOutside);
    // document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {

    // https://stackoverflow.com/questions/32553158/detect-click-outside-react-component

    // console.log('dateValue handleOutsideClick event:', event);
    // console.log('dateValue handleOutsideClick this.dateDropdownDivRef.current:', this.dateDropdownDivRef.current);
    // const path = !event ? null : (event.path || (event.composedPath && event.composedPath()));
    // if path is null, then its coming from props passed down through SearchDatePicker (eg. back button)

    // console.log('dateValue handleOutsideClick this.dateDropdownDivRef. && !this.dateDropdownDivRef.current.contains(event.target)', this.dateDropdownDivRef && !this.dateDropdownDivRef.current.contains(event.target));
    if (this.dateDropdownDivRef && this.dateDropdownDivRef.current && !this.dateDropdownDivRef.current.contains(event.target)) {

      const { setDateValueOpen } = this.props;
      setDateValueOpen(false);

    }

  };

  // OLD handleClickOutside = (event) => {
  //
  //   // console.log('dateValue handleOutsideClick event:', event);
  //   const path = !event ? null : (event.path || (event.composedPath && event.composedPath()));
  //   // if path is null, then its coming from props passed down through SearchDatePicker (eg. back button)
  //   if (path.includes(this.dateDropdownItemsDivRef.current)) {
  //
  //     // console.log('dateValue handleOutsideClick');
  //
  //     // this.setState({ open: false });
  //
  //
  //     const { setDateValueOpen } = this.props;
  //     setDateValueOpen(false);
  //
  //   }
  // };

  // handleScroll = (e) => {
  //   // if this element scrolls out of view, close dropwdown to maintain scroll behaviour
  //   const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //   // console.log('e.target.scrollHeight', e.target.scrollHeight);
  //   // console.log('e.target.clientHeight', e.target.clientHeight);
  //   if (bottom) {
  //     const { setDateValueOpen } = this.props;
  //     setDateValueOpen(false);
  //   }
  //
  // }


  handleClickBackButton = (event) => {
    const path = !event ? null : (event.path || (event.composedPath && event.composedPath()));
    // if path is null, then its coming from props passed down through SearchDatePicker (eg. back button)
    if (!path || !path.includes(this.dateDropdownDivRef.current)) {

      console.log('dateValue handleClickBackButton');

      // this.setState({ open: false });

      const { setDateValueOpen } = this.props;
      setDateValueOpen(false);

    }
  };


  handleWindowSizeChange = () => {

    // console.log('this.dateDropdownDivRef: ', this.dateDropdownDivRef);
    const newdatePickerTop = this.dateDropdownDivRef.current.getBoundingClientRect().bottom;
    const newdatePickerLeft = this.dateDropdownDivRef.current.getBoundingClientRect().left;
    const newdatePickerRight = this.dateDropdownDivRef.current.getBoundingClientRect().right;
    const newdatePickerWidth = this.dateDropdownDivRef.current.getBoundingClientRect().width;

    // console.log('newdatePickerRight', newdatePickerRight);

    this.setState({ datePickerTop: newdatePickerTop, datePickerLeft: newdatePickerLeft, datePickerRight: newdatePickerRight, datePickerWidth: newdatePickerWidth });

  };

  handleClick = () => {
      console.log('dateValue handleClick');
      // if click is inside, do something

      // const { open } = this.state;
      // this.setState({ open: !open });
      console.log('dateValue handleClick this.props:', this.props);
      const { setDateValueOpen, date_value_is_open } = this.props;
      setDateValueOpen(!date_value_is_open);


  }


  render () {


    // const { open } = this.state;
    // console.log('dropdown render');

    const { window_width, value, dataType, viewOnly, inputKey, options, date_value_is_open } = this.props;
    // console.log('date dropdown value', value);
    // // console.log('window_width:', window_width);
    // console.log('dateValue this.state:', this.state);
    // console.log('dateValue date_value_is_open:', date_value_is_open);


    const dropdownValue = value;
    // console.log('options: ', options);

    // const defaultOption = options[this.props.value - 1];

    const menuItems = options.map((option, i) => {


        // console.log('option', option);

        return (

          <button
            type='button'
            key='myMenuKey Datepicker'
            eventkey={1}
            index={1}
            // onClick={() => this.handleSelect(currentValue, inputKey, dataType)}
            // onKeyPress={(e) => {
            //   if (e.key === 'Enter') {
            //     this.handleSelect(currentValue, inputKey, dataType);
            //   }
            // }}
            style={{
              width: '100%',
              height: '100%',
              padding: 2,
              borderWidth: 0,
              borderTopWidth: 1,
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderBottomWidth: 1,
              borderColor: colours.mediumlightgray,
              borderStyle: 'solid',
              boxShadow: `1px 1px 1px ${colours.shadow}`,
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              background: colours.white,
              alignItems: 'center',
              justifyContent: 'center'
            }}


          >

            <div style={{ borderWidth: 0,

            borderColor: colours.orange,
            borderStyle: 'solid',
            width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <SearchDatePickerContainer
                handleClickBackButton={() => this.handleClickBackButton()}
                handleDropdownClick={() => this.handleClick()}
              />
            </div>


          </button>

        );
    });

    // const { datePickerTop, datePickerLeft, datePickerRight, datePickerWidth } = this.state;
    // console.log('datePickerTop:', datePickerTop);
    // console.log('datePickerLeft:', datePickerLeft);
    // console.log('datePickerRight:', datePickerRight);
    // console.log('datePickerWidth:', datePickerWidth);

    // const calendar_width = (
    //   window_width < 340 ? window_width - 20
    //   : window_width < 400 ? window_width - 40
    //   : window_width < 460 ? window_width - 80
    //   : window_width < 600 ? 380
    //   : window_width < 760 ? 300
    //   : 340 // once screen beyond 700 only half screen
    // );

    const width = window_width;
    const fullWidth = window_width < 440;
    // console.log('burger:', burger);
    console.log('width:', width);


    return (

      <div
        // add ref here
        ref={this.dateDropdownDivRef} // any click outside of this triggers handleOutsideClick

        style={{
          display: 'flex',
          flex: 4,
          padding: 0,
          position: 'relative',
          cursor: 'pointer',
          // borderTopWidth: 0.5,
          // borderBottomWidth: 0.5,
          // borderLeftWidth: 0.5,
          // borderRightWidth: 0.5,
          // borderColor: colours.darkpurple,
          borderStyle: 'none'
          // borderRadius: 0,
          // background: colours.white,

        }}
      >
        <button

          type='button'
          onClick={this.handleClick}
          onKeyDown={this.handleClick}
          style={{
            minHeight: 28,
            minWidth: 0,
            padding: 0,
            margin: 0,
            // border: 'none',
            borderColor: colours.orange,
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: 8,
            textAlign: 'center',
            position: 'relative',
            cursor: 'pointer',
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            background: date_value_is_open ? colours.orange : colours.white,
            alignItems: 'center',
            justifyContent: 'center',
            textDecoration: 'none',
            overflow: 'hidden'
          }}

        >

          <div style={{ flex: 1, borderWidth: 0, borderStyle: 'solid', borderColor: 'red',
            minWidth: 0, // needed to ensure ellipsis is applied and text tag doesn't break out of flex container
            display: 'flex', justifyContent: 'flex-start',
            overflow: 'hidden',
            paddingLeft: 4, paddingRight: 2,
            paddingBottom: 2, paddingTop: 2
          }}>
            <FormValueText marginRight='0px' color={date_value_is_open ? colours.white : null} whiteSpace='nowrap' textOverflow='ellipsis'>{dropdownValue}</FormValueText>
          </div>


          <div style={{ borderWidth: 0, borderStyle: 'solid', borderColor: 'red', width: 30, height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            {!viewOnly
              && <FontAwesomeIcon
                icon={faCaretDown}
                size='sm'
                style={{ height: '100%', color: date_value_is_open ? colours.white : colours.caretColor }} />
            }
          </div>


        </button>


        <Transition
          in={date_value_is_open}
          appear={true}
          exit={true}
          timeout={{
            appear: 50,
            enter: 50,
            exit: 200
          }}

          unmountOnExit
          // classNames='fade'
        >
          { state => (

            <div

              style={{
                ...defaultStyle(fullWidth, width),
                ...fadeInStyles[state]

              }}

            >

              <div
                // ref={this.dateDropdownItemsDivRef}
                role='presentation'
                // onClick={this.handleOutsideClick}
                // onKeyDown={this.handleOutsideClick}
                // onScroll={this.handleScroll}
                style={{
                  // position: 'fixed', // no longer require this with new handleOutsideClick method, - previously, without this, stops scroll locking, with it enables click behind outside to work, to close window.
                  opacity: 0.2,
                  background: 'colours.lightgray',
                  top: 0,
                  left: 0,
                  // right: 0,
                  width: '100%',
                  // height: '100%', // no longer required for handleClickOutside to be picked up, using new method
                  zIndex: -1,
                  // borderWidth: 1, borderStyle: 'solid', borderColor: 'blue'
              }} />


              <Menu style={{
                margin: '0px',
                width: '100%',

                // width: '100%',
                // display: 'flex',
                // flexDirection: 'column',
                // position: 'relative',
                zIndex: 3000,
                // borderColor: 'red',
                // boxShadow: `1px 1px 1px ${colours.shadow}`
              }} vertical>

                {
                  menuItems
                }
              </Menu>


            </div>
          )}

        </Transition>

      </div>

    );
  }
}
