import React from 'react';
import Card from '../general/card';
import CardSection from '../general/card-section';
import CardHeader from '../general/card-header';
import Paragraph from '../general/paragraph';
import Spinner from '../general/spinner';
import { dashboard } from '../../text';
import AvailabilityWeek from '../../containers/calendar/availability-week';

const LocalAvailability = ({ upcomingAvailability }) => {

    // return loading state if noUpcomingAvailability
    if (!upcomingAvailability) {
      return (
        <Card>
          <CardSection>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}><Spinner /></div>
          </CardSection>
        </Card>
      );
    }

    return (

      <Card noBottomPadding>
        <CardHeader alignTitle='left' title={dashboard.local_availability_title} />

        <CardSection noBottomPadding>

          <Paragraph>
            {dashboard.local_availability_para}
          </Paragraph>

          <div style={{ display: 'flex', width: '100%', overflow: 'hidden', flexDirection: 'row', justifyContent: 'center', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', alignItems: 'center', paddingBottom: 16, paddingLeft: 0, paddingRight: 0 }}>
            <AvailabilityWeek />
          </div>

        </CardSection>

      </Card>

    );
};

export default LocalAvailability;
