import React from 'react';
import colours from '../../styles/colours';
import { H6 } from '../../styles/text';
import { weekdaysShort } from '../../lib/timeHelpers';

// ellipsis button to perform actions on week

export const WeekdaysHeader = () => {

  const weekdayHeaders = weekdaysShort.map((day) => {
    return (
      <div key={day} style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: 36, background: colours.white, borderWidth: 0, borderColor: 'transparent', borderStyle: 'solid' }}>

        <H6

        >{day}
        </H6>

      </div>
    );
  });

  return weekdayHeaders;

};

export default WeekdaysHeader;
