import React, { Component, createRef } from 'react';
import colours from '../../styles/colours';
import { FormLabelText } from '../../styles/text';
import { SendInput } from '../../styles/styles';

// maxHeight of message input textarea
const limitHeight = 300;

// input field is set to width flex 1, do to choose specific width, wrap in div
class SendInputField extends Component {

  constructor (props) {
    super(props);

    this.state = {
      // isFocused: false,
      renderCount: 0
    };
    this.inputReference = createRef();

  }

  componentDidMount () {

      console.log('DID_MOUNT');
      console.log('DID_MOUNT inputReference:', this.inputReference);
      // rendering before this is called.

      if (this.inputReference && this.inputReference.current !== null) {
        // this selects and highlights text inside the input
        this.inputReference.current.focus();
        this.inputReference.current.select();

      }

      this.incrementRender(); // adds 1 to renderCount;

  }


  componentDidUpdate () {

      console.log('NEW_PROPS');
      console.log('NEW_PROPS inputReference:', this.inputReference);

      if (this.inputReference && this.inputReference.current !== null) {

        if (this.state.renderCount < 2) {
          // 1st render puts up the input - yet to receive message as default
          // 2nd render message is now included in the input - so want to now focus and resize the input

          // focus the input
          this.inputReference.current.focus();
          // select the text in case the user wants to delete it
          this.inputReference.current.select();
          // resize the input on initial render

          this.inputReference.current.style.height = 'inherit';
          // e.target.style.height = `${e.target.scrollHeight}px`;
          // In case you have a limitation
          console.log('this.inputReference.current.scrollHeight:', this.inputReference.current.scrollHeight);
          console.log('this.inputReference.current.style.height:', this.inputReference.current.style.height);

          this.inputReference.current.style.height = `${Math.min(this.inputReference.current.scrollHeight + 1, limitHeight)}px`;
          this.incrementRender(); // adds 1 to renderCount;
        }

      }


  }

  handleKeyDown = (e) => {
    const { onChangeText } = this.props;
    console.log('HANDLE_KEYDOWN');
    // trigger onChangeText to trigger rerender and force update to below code
    onChangeText(e);

    // enables textbox growing and scrolling
    e.target.style.height = 'inherit';
    // e.target.style.height = `${e.target.scrollHeight}px`;
    // In case you have a limitation
    // console.log('e.target.scrollHeight:', e.target.scrollHeight);
    // console.log('e.target.style.height:', e.target.style.height);
    
    e.target.style.height = `${Math.min(e.target.scrollHeight + 1, limitHeight)}px`;

  }

  handleFocus = (e) => {
    console.log('HANDLE_FOCUS');
    // this triggers resize based on new value - want to trigger after value has changed/rendered
    // triggers handleKeyDown to ensure box resizes to fit text

    this.handleKeyDown(e);
    e.currentTarget.select(); // focuses input
    // problem is this is called before DOM element has loaded.  Need to use UseEffect somehow
  }

  incrementRender () {
    this.setState(state => ({ renderCount: state.renderCount + 1 }));
  }


  render () {
    const {
      // multiline,
      numberOfLines,
      // labelType,
      value, placeholder,
      // defaultValue,
      // onChangeText, // used in method handleKeyDown above
      testDescription, label, inputKey, optional,
      autoCapitalize, secureTextEntry, type } = this.props;

    const labelText = label;

    console.log('RETURNING: value:', value);
    console.log('RETURNING: renderCount:', this.state.renderCount);
    // force update to resize once input value text is received on initial render


    return (
      <div
        style={{
          background: 'transparent',
          display: 'flex',
          flex: 1,
          height: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 0,
          borderColor: 'blue',
          borderWidth: 0,
          borderStyle: 'solid'

      }}>
        <div style={{
          background: 'transparent',
          display: 'flex',
          flex: 1,
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          borderColor: 'green',
          borderWidth: 0,
          borderStyle: 'solid'
        }}>
          { (label !== undefined)
            && <div style={{ flexDirection: 'row' }}>
              {
                <FormLabelText>
                  {labelText}
                </FormLabelText>
              }
              { optional
                && <FormLabelText style={{ color: colours.gray }}>
                  {' - Optional'}
                </FormLabelText>
              }
            </div>
          }
          <div style={{ background: colours.white, display: 'flex', width: '100%', height: '100%', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', flexDirection: 'row' }}>
            <div style={{ background: 'transparent', height: '100%', paddingLeft: 0, paddingRight: 0, flex: 1, borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>

              <SendInput
                // ref={(e) => {
                //   inputElement.current = e;
                // }}
                // innerRef={inputElement}
                innerRef={this.inputReference}
                // innerRef={callbackRef}
                key={inputKey}
                onFocus={e => this.handleFocus(e)}
                autoFocus
                // onBlur={this.handleBlur}
                accessibilityLabel={testDescription}
                underlineColorAndroid='transparent'
                placeholder={ placeholder }
                onChange={e => this.handleKeyDown(e)}

                value={ value }
                onInput={e => this.handleKeyDown(e)}
                autoCapitalize={autoCapitalize}
                type={type}
                multiline
                numberOfLines={numberOfLines} // check if does anything on android, else delete
                secureTextEntry={secureTextEntry}
                // style={{  }}
                onKeyDown={e => this.handleKeyDown(e)}

              />


            </div>

          </div>
        </div>

      </div>


    );

  }

}

export default SendInputField;
