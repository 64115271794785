import React from 'react';
import { H1, H3, H5 } from '../../styles/text';
import colours from '../../styles/colours';

const SectionHeader = ({ borderRadiusTop, background, light, title, subtitle, highlightedSubtitle, alignTitle, alignSubtitle, paddingTop, paddingBottom, children }) => {

    return (
      <div style={{ borderTopLeftRadius: borderRadiusTop || null, borderTopRightRadius: borderRadiusTop || null, background: background || null, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: paddingTop || 8, paddingBottom: paddingBottom || 8 }}>
        <H1
          textAlign={alignTitle || 'center'}
          color={light ? colours.white : colours.black}
          style={{ width: '100%', margin: 0, paddingTop: 4, paddingBottom: 4, paddingLeft: 0, paddingRight: 0 }}
        >
          {title}{children}
        </H1>
        {
          (subtitle || highlightedSubtitle)
          && <H5
            color={light ? colours.white : colours.gray}
            textAlign={alignSubtitle || 'center'}
            style={{ margin: 0, paddingTop: 4, paddingBottom: 4, paddingLeft: 0, paddingRight: 0 }}
          >{subtitle}{highlightedSubtitle && <div style={{ color: light ? colours.white : colours.darkpurple }}>&nbsp;{highlightedSubtitle}</div>}
          </H5>
        }
      </div>
    );
};

export default SectionHeader;
