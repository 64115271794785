import { MODAL_TYPE_SPINNER } from '../../components/modals/modalTypes';
import { getUserById } from '../auth/profile';

export const GET_ALL_TRANSACTIONS_REQUEST = 'GET_ALL_TRANSACTIONS_REQUEST';
export const GET_ALL_TRANSACTIONS_SUCCESS = 'GET_ALL_TRANSACTIONS_SUCCESS';
export const GET_ALL_TRANSACTIONS_FAILURE = 'GET_ALL_TRANSACTIONS_FAILURE';


export const getAllTransactionsRequest = () => ({
  type: GET_ALL_TRANSACTIONS_REQUEST
});

export const getAllTransactionsSuccess = data => ({
  type: GET_ALL_TRANSACTIONS_SUCCESS,
  data
});

export const getAllTransactionsFailure = error => ({
  type: GET_ALL_TRANSACTIONS_FAILURE,
  error
});

export function getAllTransactionsById (token, user_id) {
  return (dispatch) => {

    dispatch(getAllTransactionsRequest());
    console.log('getAllTransactions action for user_id: ', user_id);

    fetch(`${process.env.REACT_APP_API_URI}/users/all_transactions/${user_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    })
    .then((res) => {
      console.log('getAllTransactions res:', res);
      res.json()
      .then((data) => {
        console.log('getAllTransactions response data:', data);

        if (data && data.length > 0) {

          const transactionData = { transactions: data };

          dispatch(getAllTransactionsSuccess(transactionData));
          dispatch(getUserById(token, user_id)); // to fetch updated balances

        } else {
          // no transactions yet for this user
          const transactionData = { transactions: data };
          dispatch(getAllTransactionsSuccess(transactionData));
          dispatch(getUserById(token, user_id)); // to fetch updated balances
        }

      })
      .catch((err) => {
        console.log('caught getAllTransactions error: ', err);
        dispatch(getAllTransactionsFailure(err));
      });
    })
    .catch((err) => {
      console.log('caught getAllTransactions error: ', err);
      dispatch(getAllTransactionsFailure(err));
    });
  };
}
