import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import colours from '../../styles/colours';

const CloseButton = ({ onClick }) => {


    return (

      <button
        type='button'
        title='Close'
        onClick={ onClick }
        onKeyDown={ onClick }
        style={{
          zIndex: 3000,
          textDecoration: 'none',
          outline: 'none',
          borderStyle: 'none',
          background: 'transparent',
          position: 'absolute', right: 0, padding: 2, paddingLeft: 8, paddingRight: 10 }}
      >
        <FontAwesomeIcon
          icon={faTimes}
          size='lg'
          style={{ color: colours.darkpurple }} />
      </button>


    );
};

export default CloseButton;
