import moment from 'moment';
// import initSocket from '../socket-router';
import history from '../../components/Navigation/history';
import { showModal, hideModal } from '../../actions/modal';
import { getMessageThread } from '../messages';
import { actionSuccess } from '../verify';
import { resetActionBar } from '../layout';
import { plan as planText, sit_details } from '../../text';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_SUCCESS, MODAL_TYPE_ERROR, MODAL_TYPE_CONFIRM } from '../../components/modals/modalTypes';
import { CONFIRM_CHANGEPLAN, SUCCESS_APPROVE, SUCCESS_COUPON, SUCCESS_CHANGEPLAN } from '../../components/modals/modalNames';
import { PLAN, BOOKING, COUPON } from '../../components/modals/modalCategory';
import * as ROUTES from '../../constants/routes';
import { handleCheckout } from './checkout';
import { createCustomer, SUBMIT_SUBSCRIPTION_REQUEST, SUBMIT_SUBSCRIPTION_SUCCESS, SUBMIT_SUBSCRIPTION_FAILURE } from './create-customer';
import { getPlans, GET_PLANS_REQUEST, GET_PLANS_SUCCESS, GET_PLANS_FAILURE } from './plans';
import { getDefaultPaymentMethod, GET_PAYMENT_METHOD_REQUEST, GET_PAYMENT_METHOD_SUCCESS, GET_PAYMENT_METHOD_FAILURE } from './payment-method';
import { attachPaymentMethod, ATTACH_PAYMENT_METHOD_REQUEST, ATTACH_PAYMENT_METHOD_SUCCESS, ATTACH_PAYMENT_METHOD_FAILURE } from './attach-payment-method';

export const SET_SHOW_SUBSCRIBE_PAYMENT = 'SET_SHOW_SUBSCRIBE_PAYMENT';
export const SUBMIT_PAYMENT_REQUEST = 'SUBMIT_PAYMENT_REQUEST';
export const SUBMIT_PAYMENT_SUCCESS = 'SUBMIT_PAYMENT_SUCCESS';
export const SUBMIT_PAYMENT_FAILURE = 'SUBMIT_PAYMENT_FAILURE';
export const SUBMIT_DOWNGRADE_REQUEST = 'SUBMIT_DOWNGRADE_REQUEST';
export const SUBMIT_DOWNGRADE_SUCCESS = 'SUBMIT_DOWNGRADE_SUCCESS';
export const SUBMIT_DOWNGRADE_FAILURE = 'SUBMIT_DOWNGRADE_FAILURE';
export const SUBMIT_COUPON_REQUEST = 'SUBMIT_COUPON_REQUEST';
export const SUBMIT_COUPON_SUCCESS = 'SUBMIT_COUPON_SUCCESS';
export const SUBMIT_COUPON_FAILURE = 'SUBMIT_COUPON_FAILURE';
export const SET_CURRENT_PLAN = 'SET_CURRENT_PLAN';
export const SET_COUPON = 'SET_COUPON';
export const SET_CARD_ELEMENTS_COMPLETE = 'SET_CARD_ELEMENTS_COMPLETE';
export const RESET_PAYMENT_FORM = 'RESET_PAYMENT_FORM';
export { createCustomer, handleCheckout, getPlans, getDefaultPaymentMethod, attachPaymentMethod, ATTACH_PAYMENT_METHOD_REQUEST, ATTACH_PAYMENT_METHOD_SUCCESS, ATTACH_PAYMENT_METHOD_FAILURE, GET_PLANS_REQUEST, GET_PLANS_SUCCESS, GET_PLANS_FAILURE, GET_PAYMENT_METHOD_REQUEST, GET_PAYMENT_METHOD_SUCCESS, GET_PAYMENT_METHOD_FAILURE, SUBMIT_SUBSCRIPTION_REQUEST, SUBMIT_SUBSCRIPTION_SUCCESS, SUBMIT_SUBSCRIPTION_FAILURE };

export const setShowSubscribePayment = data => ({
  type: SET_SHOW_SUBSCRIBE_PAYMENT,
  data
});

export const setCardElementsComplete = data => ({
  type: SET_CARD_ELEMENTS_COMPLETE,
  data
});

export const submitPaymentRequest = () => ({
  type: SUBMIT_PAYMENT_REQUEST
});

export const submitPaymentSuccess = data => ({
  type: SUBMIT_PAYMENT_SUCCESS,
  data
});

export const submitPaymentFailure = error => ({
  type: SUBMIT_PAYMENT_FAILURE,
  error
});


export const submitDowngradeRequest = () => ({
  type: SUBMIT_DOWNGRADE_REQUEST
});

export const submitDowngradeSuccess = data => ({
  type: SUBMIT_DOWNGRADE_SUCCESS,
  data
});

export const submitDowngradeFailure = error => ({
  type: SUBMIT_DOWNGRADE_FAILURE,
  error
});

export const submitCouponRequest = () => ({
  type: SUBMIT_COUPON_REQUEST
});

export const submitCouponSuccess = data => ({
  type: SUBMIT_COUPON_SUCCESS,
  data
});

export const submitCouponFailure = error => ({
  type: SUBMIT_COUPON_FAILURE,
  error
});

export const resetPaymentForm = () => ({
    type: RESET_PAYMENT_FORM
});


export function confirmPAYGPayment (token, messageParams, user_id, thread_id, payment_id, promo_code) {

  console.log('CONFIRM PAYG PAYMENT:');
  console.log('params:', { token, messageParams, user_id, thread_id, payment_id, promo_code });
  // card verified - now to collect payment and confirm booking
  return (dispatch) => {
    console.log('in dispatch:');


    const bodyText = JSON.stringify({
      payment_id,
      user_id,
      thread_id,
      promo_code,
      message_params: messageParams
    });

    fetch(`${process.env.REACT_APP_API_URI}/payment/payg`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      },
      body: bodyText
    })
    .then(CheckError)
    .then((data) => {
      console.log('paygPayment and Approve response data:', data);

      if (data) { // not sure if we need?
        // dispatch(approveSitSuccess(data.thread_id)); // do we need?

        dispatch(submitPaymentSuccess(data)); // adds users' payments to state (but is it used?)
        const { user_data } = data;

        console.log('user_data:', user_data); // is this useful?

        const message_thread_id = data.thread_id;
        dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));

        dispatch(hideModal());
        dispatch(resetActionBar()); // resets MessageBar and ActionBar

        const modalText = planText.modal.payg_payment.success;


        dispatch(showModal(

          MODAL_TYPE_SUCCESS,
          { // modalProps
            // other_user_name?
            modalCategory: BOOKING,
            modalName: SUCCESS_APPROVE,
            modalText: modalText,
            okAction: () => dispatch(hideModal()),
            backAction: null,

            payment_type: 'sit_payg'

          }
        ));
        dispatch(actionSuccess()); // triggers success icon

        console.log('second thread route push');
        history.push({
          pathname: ROUTES.THREAD,
          state: { editMode: false }
        });
      } else {

        // throw error
        throw new CustomException(200, 'Your payment was not authorised by your card issuer or the sit could not be confirmed');

      }

    })
    .catch((err) => {
      console.log('caught submitPaygPayment error: ', err);
      // alert('Payment Error');
      dispatch(submitPaymentFailure(err.message));

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: planText.modal.payg_payment.failure.title,
          header: planText.modal.payg_payment.failure.header,
          message: planText.modal.payg_payment.failure.para,
          message2: planText.modal.payg_payment.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });
  };

}


export function setCurrentPlan (data) {
  return {
    type: SET_CURRENT_PLAN,
    data
  };
}

export function setCoupon (data) {
  return {
    type: SET_COUPON,
    data
  };
}
// check couponIsValid

export function checkCoupon (token, coupon, user_id) {
  console.log('checkCoupon:', coupon);
console.log('checkCoupon user_id:', user_id);
  // "id": "BETA22",
  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: planText.modal.coupon.fetching
      }
    ));

    dispatch(submitCouponRequest());

    console.log('checkCoupon /payment/customer/coupon:', coupon);

    fetch(`${process.env.REACT_APP_API_URI}/payment/customer/coupon/${coupon}/${user_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    })
    .then(CheckError)
    .then((data) => {
        console.log('checkCoupon response:', data);
        dispatch(submitCouponSuccess(data));

        dispatch(hideModal());


        const modalText = planText.modal.coupon.success;

        dispatch(showModal(

          MODAL_TYPE_SUCCESS,
          { // modalProps
            // other_user_name?
            modalCategory: COUPON,
            modalName: SUCCESS_COUPON,
            modalText: modalText,
            okAction: () => dispatch(hideModal()),
            backAction: null,

            // promo_applied: 'Promo applied'

          }
        ));


        dispatch(actionSuccess()); // triggers success icon

    })
    .catch((err) => {
      console.log('checkCoupon error: ', err);

      dispatch(submitCouponFailure(err.message));
      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});

      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: planText.modal.coupon.failure.title,
          header: planText.modal.coupon.failure.header,
          message: planText.modal.coupon.failure.para,
          message2: planText.modal.coupon.failure.para2,
          error_status: err.code && err.code,
          // error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });
  };

}

// launches confirm modal
export function downgradeSubscription (token, user_id, cancel, current_period_end) {

  return (dispatch) => {


    const modalText =
      cancel ? planText.modal.cancel_downgrade.confirm
      : planText.modal.downgrade.confirm;

    console.log('current_period_end:', current_period_end);

    const formattedNextPaymentDate = moment.unix(current_period_end).format("DD/MM/YYYY");
    console.log('formattedNextPaymentDate:', formattedNextPaymentDate);

    const newModalText = { ...modalText, para2: `${modalText.para2} ${formattedNextPaymentDate}` }

    console.log('newModalText:', newModalText);

    dispatch(showModal(MODAL_TYPE_CONFIRM, {
      modalCategory: PLAN,
      modalName: CONFIRM_CHANGEPLAN,
      modalText: newModalText,
      okAction: () => dispatch(okDowngradeSubscription(token, user_id, cancel, current_period_end)),
      backAction: null,
      cancelAction: () => dispatch(hideModal()),

    }));


  };
}

// launched after confirmation
export function okDowngradeSubscription (token, user_id, cancel) {

  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: !cancel ? planText.modal.downgrade.fetching : planText.modal.cancel_downgrade.fetching
      }
    ));
    dispatch(submitDowngradeRequest());

    fetch(`${process.env.REACT_APP_API_URI}/payment/downgrade`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      },
      body: JSON.stringify({
        user_id,
        cancel
      })

    })
    .then(CheckError)
    .then((data) => {
      console.log('Downgrade response:', data);
      dispatch(submitDowngradeSuccess(data));


      // cancel_at: 1614706168
      // cancel_at_period_end: true
      // cancelled_at: null

      // data.cancel_at
      // data.cancel_at_period_end
      // data.cancelled_at

      // success message, get user's updated subscription status and back to plan?

      dispatch(hideModal());
      // show modal showing downgrade success and subscription end date.
      // dispatch(showModal(
      //   MODAL_TYPE_SUCCESS_CHANGEPLAN,
      //   { // modalProps
      //     cancel_at_period_end: data.cancel_at_period_end, // check what want toshow in payment history and notification windows
      //     payment_type: data.cancel_at_period_end ? 'downgrade' : 'cancel_downgrade', // check
      //     cancel_at: data.cancel_at,
      //     current_period_end: data.current_period_end
      //   }
      // ));

      // const modalText =
      //   cancel ? planText.modal.cancel_downgrade.confirm
      //   : planText.modal.downgrade.confirm;


      const cancel_at_period_end = data.cancel_at_period_end; // check what want toshow in payment history and notification windows

      const modalText = cancel_at_period_end ? planText.modal.downgrade.success : planText.modal.cancel_downgrade.success;

      const payment_type = data.cancel_at_period_end ? 'downgrade' : 'cancel_downgrade'; // check
      const cancel_at = data.cancel_at;
      const current_period_end = data.current_period_end;

      const formattedCancelAt = moment.unix(cancel_at).format("DD/MM/YYYY");
      const formattedNextPaymentDate = moment.unix(current_period_end).format("DD/MM/YYYY");

      // const formattedNextPaymentDate = moment.unix(next_payment_date).format("DD/MM/YYYY");

      const para2 =
        payment_type === 'downgrade' && cancel_at_period_end ? `${planText.modal.downgrade.success.para2} ${formattedCancelAt}.`
      :
        payment_type === 'cancel_downgrade' && !cancel_at_period_end ? `${planText.modal.cancel_downgrade.success.para2} ${formattedNextPaymentDate}.`
      : '';

      const newModalText = { ...modalText, para2: para2 };

      console.log('newModalText:', newModalText);

      dispatch(showModal(MODAL_TYPE_SUCCESS, {
        modalCategory: PLAN,
        modalName: SUCCESS_CHANGEPLAN,
        modalText: newModalText,
        okAction: () => dispatch(hideModal()),
        backAction: null,
        cancelAction: () => dispatch(hideModal()),

      }));

      history.push({
        pathname: ROUTES.PLAN,
        state: { editMode: false }
      });


    })
    .catch((err) => {


      console.log('caught downgrade plan error: ', err);

      dispatch(submitDowngradeFailure(err.message));
      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: !cancel ? planText.modal.downgrade.failure.title : planText.modal.cancel_downgrade.failure.title,
          header: !cancel ? planText.modal.downgrade.failure.header : planText.modal.cancel_downgrade.failure.header,
          message: !cancel ? planText.modal.downgrade.failure.para : planText.modal.cancel_downgrade.failure.para,
          message2: !cancel ? planText.modal.downgrade.failure.para2 : planText.modal.cancel_downgrade.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };

}
