import React, { useState } from 'react';
import {
  Button, Icon
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
// import { fonts } from "../../theme";

// opacity: props.opacity || 1

export const StyledButton = styled(Button)`

  flex: ${props => props.flex ? props.flex : null}!important;
  margin: 0px!important;
  color: ${props => props.color ? props.color : colours.white}!important;
  background-color: ${props => props.background ? props.background : colours.orange}!important;
  border-color: ${colours.orange}!important;

  cursor: pointer!important;

`;


const ConfirmButton = (props) => {

  const [hover, setHover] = useState(false);
  const [clicked, setClicked] = useState(false);

  const { onClick } = props;

  const toggleHover = (newHover) => {
      if (hover === undefined) {
        setHover(!hover);
      } else {
        setHover(newHover);
      }
  };

  const handleClick = () => {

      if (onClick) {
        onClick();
      }

      toggleHover(false); // removes any hover effects on click

      // trigger touch feedback
      setClicked(true);
      setTimeout(() => {
        setClicked(false);
      }, 20);

  };


  const isHovered = hover;

  console.log('props:', props);


  const color = props.color || colours.white;
  const background = clicked ? colours.darkpurple : props.background || colours.orange;
  const hoveredBackground = colours.verylightpurple;

  return (
    <StyledButton
      primary
      icon={props.icon}
      labelPosition={props.icon ? props.labelPosition || 'left' : null}
      fluid={props.fluid}
      compact={props.compact}
      disabled={props.disabled}
      color={isHovered ? background : color}
      background={isHovered ? hoveredBackground : background}

      flex={props.flex}
      // style={{ margin: 0, color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}

      // onClick={props.onClick}
      onClick={() => handleClick()}

      // ishovered={isHovered}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}

    >
      {
        props.icon
        && <Icon name={props.icon} />
      }
      {props.children}
    </StyledButton>
  );
};

export default ConfirmButton;
