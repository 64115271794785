import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Header
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";


export const StyledHeader = styled(Header)`

  margin-top: 0px!important;
  margin-bottom: 0px!important;
  padding: 0;
  font-weight: ${fonts.bold}!important;
  color: ${props => props.textcolor ? props.textcolor : colours.darkpurple}!important;
  text-align: ${props => props.textAlign ? props.textAlign : 'left'}!important;
  font-size: ${props => props.screensize = 'small' ? '1em' : '4em'}!important;

`;

const mapStateToProps = (layout, ownProps) => {

    return {
      screensize: layout.screensize,
      color: ownProps.color,
      textAlign: ownProps.textAlign,
      ...ownProps
    };

};


class H4 extends Component {

  render () {

    const { screensize, color, textAlign } = this.props;
    console.log('H4 props:', this.props);

    return (
      <StyledHeader
        as='h4'
        content={this.props.children}
        // inverted
        screensize={screensize}

        textcolor={color || null}
        textAlign={textAlign || null}

      />
    );

  }
}

export default connect(
    mapStateToProps
)(H4);
