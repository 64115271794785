import { connect } from 'react-redux';
import KidsPicker from '../../components/general/kids-picker';
import { toggleDropdownKids } from '../../actions/general/dropdown-hourValue';
import { setSelectedKids, removeSelectedKids, saveSelectedKids, saveEditSelectedKids } from '../../actions/calendar';
import { getUserById } from '../../actions/auth/profile';

const mapStateToProps = ({ user, dropdownHourValue, search }) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  user_id: user.user_id,
  open: dropdownHourValue.isKidsOpen,
  selected_kids: search.selected_kids,
  all_kids: user.kids

});

const mapDispatchToProps = (dispatch, ownProps) => ({


  handleGetUser: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getUserById(token, user_id));

    }

  },
  toggleDropdownKids: (open) => {
    console.log('toggleDropdown');
    dispatch(toggleDropdownKids(open));


  },
  handleSelect: () => {

    // const token = localStorage.sitterpoint_token;
    //
    // if (token) {
    //   dispatch(getUserById(token, user_id));
    //
    // }

  },
  setSelectedKids: (kidObject) => {
    console.log('setting Selected Kids:', kidObject);
      dispatch(setSelectedKids(kidObject));

  },
  removeSelectedKids: (kidObject) => {
    console.log('removing Selected Kids:', kidObject);
      dispatch(removeSelectedKids(kidObject));

  },
  // saveSelectedKids: (kidObject) => {
  //
  //     dispatch(saveSelectedKids(kidObject));
  //
  // },
  handleSubmit: (selected_kids) => {
      const { handleDropdownClick, editMode } = ownProps;
      handleDropdownClick();
      console.log('kids handleSubmit editMode', editMode);
      if (editMode) {
        console.log('kids handleSubmit editMode Saveing kids');
        dispatch(saveEditSelectedKids(selected_kids));
      } else {
        console.log('kids handleSubmit Not editMode Saveing kids:', selected_kids);

        dispatch(saveSelectedKids(selected_kids));
      }

  },
  handleDropdownClick: () => {
    const { handleDropdownClick } = ownProps;
    handleDropdownClick();
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(KidsPicker);
