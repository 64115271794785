import React from 'react';
import { L4 } from '../styles/text';
import { verification_doc_points, profile_completed_points, total_verification_points } from '../rules/points';

const dbs_info_link = 'https://dbscheckonline.org.uk/individuals';
const dbs_fees_link = 'https://www.gov.uk/government/news/fee-changes-for-dbs-checks';


export const landing = {


  page_title: 'SitterPoint',
  headline: <div>The community of <strong>free <br />babysitting and childcare</strong></div>,
  intro: 'SitterPoint is a community of local parents and carers who share babysitting and childcare.',
  joinButtonText: 'Sign up',
  joinBetaButtonText: 'Sign up',
  joinButtonTrialText: 'Try for free',

  // feeApplies: '* Small booking fee applies',

  strapline: `An afforable alternative to childcare`,

  howDoIStart: { title: 'Why not try it out?', subtitle: `Sign up now and get 3 months completely free`, highlightedSubtitle: '' },

  howDoIStartBeta: { title: 'Would you like to try our new platform?', subtitle: `You can join our beta programme to help us improve the experience`, highlightedSubtitle: '' },

  save: { title: 'Save over £1,000 a year!', subtitle: `by using SitterPoint for `, highlightedSubtitle: 'as little as 2 hours a week' },

  wantToLearn: { title: 'Want to learn more?', subtitle: `Watch our video`, highlightedSubtitle: '' },


  bullets: [

      {
        text: `Sign up and complete your profile`
      },
      {
        text: `Get verified`
      },
      {
        text: `Go and search for families`
      }


  ],

  // ourPromise: {
  //   title: `Affordable babysitting and childcare`,
  //   subtitle: `Connecting you with local families who can sit for you`,
  //   highlightedSubtitle: ``
  // },

  // howProcess: { title: 'Because every parent deserves a break without breaking the bank on childcare', subtitle: `Here's how it works`, highlightedSubtitle: '' },
  howProcess: { title: `Here's how it works`, subtitle: ``, highlightedSubtitle: '' },


  process: [

      {
        text: `You need a break!`
      },
      {
        text: `Search for local families`
      },
      {
        text: `Contact and confirm the sit`
      },
      {
        text: `Enjoy your time, while your kids have fun`
      },
      {
        text: `Give points to the host family`
      },
      {
        text: `Help others and earn points`
      }

  ],

  // learn how points work
  // read our top tips for a good sit


  testimonials: {
    title: `They Trust us`,
    subtitle: `The stories of`,
    highlightedSubtitle: `our community`,
    content: [
      {
        id: 1,
        imgUrl: '',
        name: `Anna`,
        familyText: 'Family with 4 children',
        memberSince: `Member since Jun 2019`,
        quoteText: `What I love about SitterPoint us that my kids get to go on a fun playdate while I have to nip into the office, and I don't feel like I'm dumping them on someone.`
      },
      {
        id: 2,
        imgUrl: '',
        name: `Susan`,
        familyText: 'Family with 3 children',
        memberSince: `Member since Jun 2019`,
        quoteText: `SitterPoint is the best way to find a local sitter and meet new parents in your area. My daughters loved it too.`
      },
      {
        id: 3,
        imgUrl: '',
        name: `Asha`,
        familyText: 'Family with 2 children',
        memberSince: `Member since Jun 2019`,
        quoteText: `SitterPoint is the best way to find a local sitter and meet new parents in your area. My daughters loved it too.`
      },
      {
        id: 4,
        imgUrl: '',
        name: `Dawn`,
        familyText: 'Family with 1 child',
        memberSince: `Member since Jun 2019`,
        quoteText: `SitterPoint is the best way to find a local sitter and meet new parents in your area. My daughters loved it too.`
      },
      {
        id: 5,
        imgUrl: '',
        name: `Tracy`,
        familyText: 'Family with 3 children',
        memberSince: `Member since Jun 2019`,
        quoteText: `SitterPoint is the best way to find a local sitter and meet new parents in your area. My daughters loved it too.`
      },
      {
        id: 6,
        imgUrl: '',
        name: `Jill`,
        familyText: 'Family with 2 children',
        memberSince: `Member since Jun 2019`,
        quoteText: `SitterPoint is the best way to find a local sitter and meet new parents in your area. My daughters loved it too.`
      }
    ]
  },


  ourPromise: {
    title: `An afforable alternative to childcare`,
    subtitle: `Connecting you with local families who can sit for you`
    // highlightedSubtitle: `* Small booking fee applies`
  },


  footer: [
    {
      text: 'SitterPoint is registered in England and Wales number DT3467890'
    }
  ]


};


export default landing;
