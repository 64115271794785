import React, { useState } from 'react';
import { connect } from 'react-redux';
import colours from '../../styles/colours';
import { showModal, hideModal } from '../../actions/modal';
import { MODAL_TYPE_EDIT_DAY } from '../modals/modalTypes';
import { calendar } from '../../text';
import { DateText, AvailabilityText } from './calendar-styles';


export const CellColumnButton = ({ noMargin, overflow, availability_score, children, date, onClick, handleCellClick, isInSelectedRange, isToday, isInPast, isPrevMonth, isNextMonth, isAvailable, isSitBooked, isSitPending, isReqBooked, isReqPending, hasClashes, cellWidth, cellHeight, style, textColor, backgroundColor }) => {

  const [hover, setHover] = useState(false);

  // console.log('isInPast: ', isInPast);

  // isfirst row
  // islast row
  // is first cell
  // is last cell

  const toggleHover = (newHover) => {
    if (!isInPast && !isNextMonth && !isPrevMonth) {
      if (hover === undefined) {
        setHover(!hover);
      } else {
        setHover(newHover);
      }
    }
  };

  // const handleClick = () => {
  //
  //
  //   // console.log('date: ', date);
  //   if (!isInPast && !isNextMonth && !isPrevMonth) {
  //
  //     if (onClick) {
  //       onClick();
  //     } else {
  //       handleCellClick(date);
  //     }
  //
  //   }
  //   toggleHover(false);
  // };

  const available = !isInPast && isAvailable;
  const sitBooked = !isInPast && isSitBooked;
  const sitPending = !isInPast && isSitPending;
  const reqBooked = !isInPast && isReqBooked;
  const reqPending = !isInPast && isReqPending;
  const isHovered = hover;

  // console.log('available:', available);

  const pastTestColor = isInPast ? colours.white : 'transparent';
  const divColor = isToday ? pastTestColor : pastTestColor;
  const reqBookedColor = colours.reqBooked;
  const sitBookedColor = colours.sitBooked;
  const availableColor = colours.available;

  const availableTextColor = colours.white;

  const usedTextColor = available ? availableTextColor : textColor;

  const hoveredColor = colours.lightpurple;
  const outlineWidth = isHovered ? 3 : 0;
  const outlineColor = isHovered ? hoveredColor : 'transparent';

  const borderColor = isHovered ? hoveredColor : hasClashes && !isInPast ? colours.red : colours.mediumlightgray;

  const borderWidth = 1;

  const mainbackgroundColor = isInSelectedRange ? colours.blue : hasClashes && !isInPast ? colours.verylightred : backgroundColor || divColor;

  const showInnerCircle = available;

  const dotsMargin = cellWidth < 60 ? 1 : 2;
  const dotSize = 8;
  const dotBorderWidth = 1;

  const innerCircleMargin = noMargin? 0 : 4;

  const innerCircleSize = cellWidth - ((dotSize + dotsMargin + innerCircleMargin) * 2); // multiply by two ensure account for spacing both sides;


  const cellColor = availability_score === 2 ? colours.goodGreen : availability_score === 1 ? colours.someGreen : 'transparent';


  return (
    <div
      // type='button'
      // onMouseEnter={() => toggleHover(true)}
      // onMouseLeave={() => toggleHover(false)}
      // onClick={() => handleClick(children)}
      style={{ ...style, zIndex: isHovered ? 999 : 1, position: 'relative', outlineWidth, outlineColor, outlineStyle: 'solid', display: 'flex', width: cellWidth, height: cellHeight || null, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderWidth: 0, borderLeftWidth: borderWidth, borderRightWidth: 0, borderTopWidth: borderWidth, borderBottomWidth: 0, borderColor, borderStyle: 'solid', paddingLeft: 0, paddingRight: 0, borderRadius: 0, background: cellColor }}>


      <div style={{ overflow: overflow || null, width: noMargin ? '100%' : innerCircleSize, height: 'auto', background: 'transparent', margin: innerCircleMargin, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

        <AvailabilityText textColor={usedTextColor}>{children}</AvailabilityText>

      </div>

    </div>
  );
};


const mapDispatchToProps = (dispatch, ownProps) => ({

  // handleCellClick: (date) => {
  //
  //   dispatch(showModal(MODAL_TYPE_EDIT_DAY, {
  //
  //     date
  //
  //   }));
  //
  // },
  onClick: ownProps.onClick


  // handleGetUser: (user_id) => {
  //
  //   const token = localStorage.sitterpoint_token;
  //
  //   if (token) {
  //     console.log('availability get User');
  //     dispatch(getUserById(token, user_id));
  //
  //   }
  //
  // },
  //
  // addAvailability: (availabilityObject) => {
  //
  //     dispatch(addAvailability(availabilityObject));
  //
  // },
  // removeAvailability: (availabilityObject) => {
  //
  //     dispatch(removeAvailability(availabilityObject));
  //
  // },
  // handleSubmitAvailability: (user_id, available_dates) => {
  //
  //     console.log('submitAvailability:', available_dates);
  //
  //     const token = localStorage.sitterpoint_token;
  //
  //     if (token) {
  //       dispatch(submitAvailability(token, user_id, available_dates));
  //     }
  //
  // },
  // handleGetAllThreads: (user_id) => {
  //
  //   const token = localStorage.sitterpoint_token;
  //
  //   if (token) {
  //     dispatch(getAllThreadsById(token, user_id));
  //
  //   }
  //
  // },
  // setBookedDates: (usersBookedDates) => {
  //   dispatch(setBookedDates(usersBookedDates));
  // }


});

export default connect(null, mapDispatchToProps)(CellColumnButton);
