/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReportGA } from '../../utils/ga';
// import '@trendmicro/react-buttons/dist/react-buttons.css';
// import '@trendmicro/react-dropdown/dist/react-dropdown.css';
// import 'react-dropdown/style.css';
// import 'react-select/dist/react-select.css';
import history from '../Navigation/history';
import { DropDownButton } from '../../styles/styles';
import Notif from '../general/notif';
import colours from '../../styles/colours';
import * as ROUTES from '../../constants/routes';
import NavbarImage from '../general/navbar-image';
import { MESSAGES } from '../../constants/ga-category';


export default class MessagesInboxButton extends Component {


  componentDidMount () {
    const { user_id } = this.props;

    console.log('this.props', this.props);

    if (user_id) {
      // this.props.handleGetUser(user_id);
      this.props.handleGetAllThreads(user_id);

      // has this already been called by Navbar by this point?

    }

  }


  handleSelect = (route) => {

    ReportGA({
      category: MESSAGES,
      action: `User clicked on Inbox button from Navbar`
    });

    // route harcoded as INBOX in this button

    const { setThreadBack, handleDropdownNavClick, dropdown_nav_is_open } = this.props;
    // console.log('this.props:', this.props);
    // console.log('handleSelect Messages Item dropdown_nav_is_open:', dropdown_nav_is_open);

    // this.setState({ open: false }); // closes messages dropdown menu


    if (dropdown_nav_is_open) {
      // console.log('closing dropdown nav because open Dropdown Messages');
      handleDropdownNavClick(dropdown_nav_is_open); // closes dropdown nav if open
    }

    // set 'thread_back' in nav state to INBOX to help back button routing logic
    setThreadBack(ROUTES.INBOX);
    console.log('setThreadBack');

    history.push({
      pathname: route,
      state: { editMode: false }

    });


  }


  render () {


    const { image, icon, iconSize, numberOfUnreadMessages } = this.props;

    console.log('inbox button numberOfUnreadMessages:', numberOfUnreadMessages);
    return (

      <div
        style={{
          flex: 1,
          display: 'flex',
          position: 'relative',
          cursor: 'pointer',
          borderWidth: 0,
          borderColor: colours.lightgray,
          borderStyle: 'solid',
          borderRadius: 0,
          height: '100%',
          minWidth: 64
        }}
      >
        <DropDownButton

          type='button'
          onClick={() => this.handleSelect(ROUTES.INBOX)}
          onKeyDown={() => this.handleSelect(ROUTES.INBOX)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.handleSelect(ROUTES.INBOX);
            }
          }}

        >

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', borderStyle: 'solid', borderWidth: 0, borderColor: 'red' }}>

            <div style={{ position: 'relative', borderStyle: 'solid', width: 36, height: 36, borderWidth: 0, borderColor: 'red' }}>

              {
                image
                ? <NavbarImage
                  alt='welcome'
                  style={{ width: 36,
                  // maxWidth: 200,
                  objectFit: 'contain' }}
                  src={ image }
                  />
                : <FontAwesomeIcon
                  icon={icon}
                  size={iconSize}
                  style={{ margin: 8, color: colours.mediumlightpurple }} />
              }
              <div style={{ position: 'absolute', bottom: -8, right: -8 }}>
                {(numberOfUnreadMessages !== 0) && <Notif>{numberOfUnreadMessages}</Notif>}
              </div>

            </div>

          </div>

        </DropDownButton>

      </div>

    );
  }
}
