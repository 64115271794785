import React from 'react';
import { ConfirmButton } from '../../styles/styles';
import Card from '../general/card';
import CardHeader from '../general/card-header';
import Paragraph from '../general/paragraph';
import { dashboard, other } from '../../text';
// import landingBackgroundPic from '../../images/landingBackgroundPic.jpg';
import verified50sp from '../../images/points_50.min.svg';

// import { connectAlert } from '../Alert';

// const { Answers } = Fabric;

const DbsSP = (props) => {
    // console.log('f item user:', user);
    const { handleClickGotIt, window_width, profileProgress } = props;


    if (profileProgress === 100) {
      return (

        <Card>
          <CardHeader
            paddingBottom='0px'
            alignTitle='left'
            title={<div>{dashboard.dbs_sp_title1}{' '}{dashboard.dbs_sp_title2}</div>}
            image={verified50sp}
            paragraph={(window_width >= 490)
              && <Paragraph>
                <br />
                {dashboard.dbs_sp_para1}
                <br />
                {dashboard.dbs_sp_para2}
              </Paragraph>
            }

          />
          {
            (window_width < 490) &&
            <Paragraph>
              {dashboard.dbs_sp_para1}
              <br />
              {dashboard.dbs_sp_para2}
            </Paragraph>
          }


          <div style={{ flex: 1, width: '100%', display: 'flex', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', justifyContent: 'flex-end', alignItems: 'flex-end', paddingTop: 4, paddingBottom: 4 }}>
            <ConfirmButton
              onClick={ () => handleClickGotIt() }
              // style={{ alignItems: 'center', alignSelf: 'flex-end', borderColor: 'green', borderWidth: 0, borderStyle: 'solid', minHeight: 30, marginRight: 0 }}
            >
              {other.got_it}
            </ConfirmButton>
          </div>
        </Card>

      );
    }
    // else
    return null;


};

export default DbsSP;
