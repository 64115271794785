export const CHART = 'CHART_MODAL';
export const OTHER = 'OTHER_MODAL';
export const SIGNIN = 'SIGNIN_MODAL';
export const SIGNUP = 'SIGNUP_MODAL';

export const SUCCESS_SIGNUP = 'SUCCESS_SIGNUP';

export const SEARCH = 'SEARCH_MODAL';
export const EDIT_SIT_DETAILS = 'EDIT_SIT_DETAILS';

export const FORGOTPASSWORD = 'FORGOTPASSWORD_MODAL';
export const SUCCESS_RESET_PASSWORD = 'SUCCESS_RESET_PASSWORD';
// export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const SUCCESS_SUBMIT_VERIFY_EMAIL = 'SUCCESS_SUBMIT_VERIFY_EMAIL';
export const SUCCESS_RESEND_VERIFY_EMAIL = 'SUCCESS_RESEND_VERIFY_EMAIL';

export const SUCCESS_EDIT_DETAILS = 'SUCCESS_EDIT_DETAILS';
export const SUCCESS_EDIT_BIO = 'SUCCESS_EDIT_BIO';
export const SUCCESS_UPDATE_KIDS = 'SUCCESS_UPDATE_KIDS';
export const SUCCESS_DELETE_KIDS = 'SUCCESS_DELETE_KIDS';
export const SUCCESS_UPDATE_ADULTS = 'SUCCESS_UPDATE_ADULTS';
export const SUCCESS_DELETE_ADULTS = 'SUCCESS_DELETE_ADULTS';
export const CONFIRM_DELETE = 'CONFIRM_DELETE';


export const REVIEW_SIT = 'REVIEW_SIT';

export const SPINNER = 'SPINNER_MODAL';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const CONFIRM_CHANGEPLAN = 'CONFIRM_CHANGEPLAN';
export const SUCCESS_CHANGEPLAN = 'SUCCESS_CHANGEPLAN';
export const SUCCESS_COUPON = 'SUCCESS_COUPON';
export const SUCCESS_ATTACH_PAYMENT = 'SUCCESS_ATTACH_PAYMENT';

export const CONFIRM_CANCEL = 'CONFIRM_CANCEL';
export const SUCCESS_CANCEL = 'SUCCESS_CANCEL';

export const CONFIRM_PREAPPROVE = 'CONFIRM_PREAPPROVE';
export const SUCCESS_PREAPPROVE = 'SUCCESS_PREAPPROVE';
export const CONFIRM_APPROVE = 'CONFIRM_APPROVE';
export const SUCCESS_APPROVE = 'SUCCESS_APPROVE';

export const SUCCESS_SENT = 'SUCCESS_SENT';

export const CONFIRM_UPLOAD_TYPE = 'CONFIRM_UPLOAD_TYPE';
export const UPLOAD_SELFIE = 'UPLOAD_SELFIE';
export const UPLOAD_FILE = 'UPLOAD_FILE';

export const EDIT_DAY = 'EDIT_DAY';
export const CONFIRM_ADD_AVAILABILITY = 'CONFIRM_ADD_AVAILABILITY';
export const SUCCESS_ADD_AVAILABILITY = 'SUCCESS_ADD_AVAILABILITY';
export const CONFIRM_EDIT_ALL_AVAILABILITY = 'CONFIRM_EDIT_ALL_AVAILABILITY';
export const EDIT_BOOKING = 'EDIT_BOOKING';
export const CONFIRM_EDIT_AVAILABILITY = 'CONFIRM_EDIT_AVAILABILITY';
export const SUCCESS_EDIT_AVAILABILITY = 'SUCCESS_EDIT_AVAILABILITY';
export const CONFIRM_DELETE_ALL_AVAILABILITY = 'CONFIRM_DELETE_ALL_AVAILABILITY';
export const SUCCESS_DELETE_AVAILABILITY = 'SUCCESS_DELETE_AVAILABILITY';

export const SIGNUP_VERIFY_EMAIL_SENT = 'SIGNUP_VERIFY_EMAIL_SENT';
