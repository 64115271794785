import { connect } from 'react-redux';
import InviteFriends from '../../components/dashboard/invite-friends';


const mapStateToProps = ({ layout, user, nav }, ownProps) => {


  return {
    // serverError: user.error,
    // isConnected: network.isConnected,
    userIsLoggedIn: user.isLoggedIn,
    window_width: layout.window_width,
    window_height: layout.window_height,
    show_navbar: layout.show_navbar,
    user_id: user.user_id,
    firstname: user.firstname,
    surname: user.surname,
    referral_code: user.referral_code,
    dropdown_nav_is_open: nav.dropdown_nav_is_open,
    nextRoute: ownProps.nextRoute,
    location: ownProps.location

  };

};

const mapDispatchToProps = (
  // dispatch
) => ({


});

export default connect(mapStateToProps, mapDispatchToProps)(InviteFriends);
