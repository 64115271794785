import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DynamicInputComponent } from '../../dynamic-component';
import history from '../../../components/Navigation/history';
import { default_theme } from '../../default-theme';

const { input } = default_theme.theme_details.textStyles;

const mapStateToProps = ({ style }, ownProps) => {
    // console.log('style:', style);

    const getTagPropsToUse = (nav) => {
      let result = {};
      if (nav === '/typo') {
        result = style.editing_theme.theme_details.textStyles.input;
      } else if (!style.available_themes) {
        result = input;
      } else {
        result = style.available_themes.reduce((acc, item) => {
          if (item.selected) {
            return item.theme_details.textStyles.input;
          }
          return acc;
        }, []);
      }
      return result;

    };
    const nav = history.location.pathname;
    const tagPropsToUse = getTagPropsToUse(nav);

    return {
      tagProps: tagPropsToUse,
      ownProps
    };

};


class InputTyp extends Component {

  render () {

    const { tagProps, ownProps } = this.props;
    console.log('InputTyp ownProps:', ownProps);

    return (
      <DynamicInputComponent {...tagProps} {...ownProps}>
        {this.props.children}
      </DynamicInputComponent>
    );

  }
}

export default connect(
    mapStateToProps
)(InputTyp);
