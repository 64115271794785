import moment from 'moment';
import * as calendar from '../actions/calendar';
import * as profile from '../actions/auth/profile';

export const initialState = {
  available_dates: [],
  booked_dates: [],
  isFetchingCalendar: false,
  isFetching: false,
  error: undefined,
  upcoming_availability: undefined

};

export default function calendarReducer (state = initialState, action) {
  switch (action.type) {

    case profile.GET_USER_REQUEST:
      return {
        ...state,
        isFetchingCalendar: true
      };
    case profile.GET_USER_SUCCESS: {
      // console.log('GET USER SUCCESS:', action.data);
      // console.log('available_dates_array:', action.data.available_dates_array);

      // append available_hours to each object in array
      const newAvailable_dates = action.data.available_dates_array.map((item) => {
        const parsedAvailableDates = JSON.parse(item.available_dates);
        const startTime = moment(parsedAvailableDates[0], 'YYYY-MM-DD HH:mm:ss');
        const endTime = moment(parsedAvailableDates[1], 'YYYY-MM-DD HH:mm:ss');
        const available_hours = endTime.diff(startTime, 'hours');
        
        return { ...item, available_hours };
      });

      return {
        ...state,
        isFetchingCalendar: false,
        available_dates: newAvailable_dates
      };
    }

    case profile.GET_USER_FAILURE:
      return {
        ...state,
        isFetchingCalendar: false,
        error: action.error
      };

    case calendar.SET_BOOKED_DATES: {

      const newBookedDates = action.bookedDates;

      const todsArray = ['on', 'am', 'pm', 'ev'];

      const parsedDates = newBookedDates;
      const sortedDates = parsedDates.sort((a, b) => {

        const aMoment = moment(`${a.day}/${a.month}/${a.year}`, 'DD/MMMM/YYYY');
        const bMoment = moment(`${b.day}/${b.month}/${b.year}`, 'DD/MMMM/YYYY');

        if (moment(aMoment).isBefore(moment(bMoment))) {
          return -1;
        }
        if (moment(aMoment).isSameOrBefore(moment(bMoment))) {
          const aTimeIndex = todsArray.indexOf(a.time);
          const bTimeIndex = todsArray.indexOf(b.time);
          if (aTimeIndex < bTimeIndex) {
            return -1;
          }
          return 1;
        }
        return 1;
      });


      return {
        ...state,
        booked_dates: sortedDates
      };

    }

    case calendar.ADD_AVAILABILITY_REQUEST:

      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case calendar.ADD_AVAILABILITY_SUCCESS: {

      // parse and sorts available_dates - can we do on SQL side?

      return {
        ...state,
        isFetching: false,
        available_dates: action.data
      };

    }

    case calendar.ADD_AVAILABILITY_FAILURE:

      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case calendar.EDIT_AVAILABILITY_REQUEST:

      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case calendar.EDIT_AVAILABILITY_SUCCESS: {

      // parse and sorts available_dates - can we do on SQL side?

      return {
        ...state,
        isFetching: false,
        available_dates: action.data
      };

    }

    case calendar.EDIT_AVAILABILITY_FAILURE:

      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case calendar.DELETE_AVAILABILITY_REQUEST:

      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case calendar.DELETE_AVAILABILITY_SUCCESS: {

      // parse and sorts available_dates - can we do on SQL side?

      return {
        ...state,
        isFetching: false,
        available_dates: action.data
      };

    }

    case calendar.DELETE_AVAILABILITY_FAILURE:

      return {
        ...state,
        isFetching: false,
        error: action.error
      };


      case calendar.GET_UPCOMING_AVAILABILITY_REQUEST:

        return {
          ...state,
          isFetching: true,
          error: undefined
        };

      case calendar.GET_UPCOMING_AVAILABILITY_SUCCESS: {

        // parse and sorts available_dates - can we do on SQL side?

        return {
          ...state,
          isFetching: false,
          upcoming_availability: action.data
        };

      }

      case calendar.GET_UPCOMING_AVAILABILITY_FAILURE:

        return {
          ...state,
          isFetching: false,
          error: action.error
        };


    default:
      return state;
  }
}
