import { CheckError, ReportError } from '../../utils/check-error';

// import initSocket from '../socket-router';


export const SUBMIT_FLEXIBLE_USERS_REQUEST = 'SUBMIT_FLEXIBLE_USERS_REQUEST';
export const SUBMIT_FLEXIBLE_USERS_SUCCESS = 'SUBMIT_FLEXIBLE_USERS_SUCCESS';
export const SUBMIT_FLEXIBLE_USERS_FAILURE = 'SUBMIT_FLEXIBLE_USERS_FAILURE';


export const submitFlexibleUsersRequest = () => ({
    type: SUBMIT_FLEXIBLE_USERS_REQUEST
});

export const submitFlexibleUsersSuccess = data => ({
  type: SUBMIT_FLEXIBLE_USERS_SUCCESS,
  data
});

export const submitFlexibleUsersFailure = error => ({
  type: SUBMIT_FLEXIBLE_USERS_FAILURE,
  error
});


export function getFlexibleUsers (token, user_id, home_address) {

  console.log('submitFlexibleUsers (home_address):', home_address);

  return (dispatch) => {

    dispatch(submitFlexibleUsersRequest());

    fetch(`${process.env.REACT_APP_API_URI}/users/flexible_users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      },
      body: JSON.stringify({ user_id, home_address })
    })
    .then(CheckError)
    .then((data) => {
      console.log('submittedFlexibleUsers response data:', data);
      // success
        // length = 0
          // push results and display message

        // length > 0
          // push results and show list

      dispatch(submitFlexibleUsersSuccess(data));

    })
    .catch((err) => {
      console.log('caught submitFlexibleUsers error: ', err);
      dispatch(submitFlexibleUsersFailure(err.message));

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});


    });


  };
}
