import * as ROUTES from '../constants/routes';

const link_url = 'https://www.allaboutcookies.org';
const link_text = 'www.allaboutcookies.org';


export const cookies = {

  banner: {
    intro: `This website uses cookies to optimise site functionality and give you the best possible experience. By using our website you agree to our use of cookies.`,
    // old_intro: `We use cookies to optimise site functionality and give you the best possible experience. If you continue without changing your preferences, we'll assume that you are happy to receive all cookies.`,
    ok: `Accept cookies`,
    settings: `Learn more`
    // old_settings: `Customise`,
    // learn_more: `Learn More`
  },

  cookie_settings: {


// new simple version
    page_title: 'Cookies Policy',
    items: [
      {
        header: `What are Cookies?`,
        para: `Cookies are small files that websites place on your hard drive or browser to enable additional features of the website to work. Use of cookies is common practice and many websites use them and most internet browsers automatically accept cookies.
        We are providing this information to you as part of our policy to comply with recent changes to the law relating to the use of cookies and other information storage technology operating on websites.`
      },
      {
        header: `Use of Cookies by SitterPoint`,
        para: `We use cookies on the SitterPoint website to collect information about how visitors use the website and to collect standard analytics to gather data to enhance the performance of our website. This is important to us as we want to improve the visitor journey when you browse our website and ensure that it is as user friendly as possible.
        However, our cookies do not gather any personally identifying information such as a person’s name or e-mail address. The details below provide further information about the cookies which are currently in use on our website and a description of the purpose of each of these cookies.`
      },
      {
        header: `Analytical Cookies`,
        para: `This cookie collects information in an anonymous form, data including the number of visitors to the SitterPoint website, where visitors have come from and the pages they have visited during their session and time spent on the website.
        These cookies are used to collect information (non-personal information) about how visitors use the SitterPoint website. We use the information to compile reports on usability for internal company use only, and so we can improve the website for our customers.
        If you want to, you can disable cookies from your browser and delete all cookies that are stored on your computer. You can do this as often as you want to.
        To find out how to do this for your particular browser you can go to “help” on your browser’s menu or you can visit`,
        link_text: `${link_text}`,
        link_url: `${link_url}`
      },
      {
        header: `What happens if I do disable Cookies?`,
        para: `You need to be aware that if you choose to disable cookies from your browser it may prevent you from taking full advantage of the SitterPoint website, as some or all of the areas of the website may fail to work for you.
        You will see from the table above that certain cookies which we use on our website are considered to be strictly necessary for the operation of certain aspects of the website. Therefore, please be aware that if these cookies are disabled we may be unable to provide certain services which you have requested.`
      },
      {
        header: `Enquiries`,
        para: `If you have any queries about our Cookies Policy, please`,
        link_url: `${ROUTES.CONTACT}`,
        link_text: `Contact us.`
      },
      {

        para: `To learn more about the how we handle the data we collect please read our`,
        link_url: `${ROUTES.PRIVACY}`,
        link_text: `Privacy policy.`
      }
    ]


// old version


    // page_title: 'Cookie Settings',
    // intro: `Cookies are widely used to make websites work, or work more efficiently, as well as to provide information to the website owner or others. Session cookies are temporary cookies that remain in the cookie file of your browser only until your browser is closed. They allow websites to link your actions during a browser session. Persistent cookies stay in the cookie file of your browser for longer (how long will depend on the lifetime of the specific cookie). For further information on cookies, including how to use your browser to block them and how to delete cookies already stored on your device, visit: `,
    // link_text: `${link_text}`,
    // link_url: `${link_url}`,
    //
    // enable_all_button: `Enable All Cookies (Recommended)`,
    // enabled: `Enabled`,
    // disabled: `Disabled`,
    // required: `Required`,
    //
    // items: [
    //   {
    //     header: `Required Cookies`,
    //     status: `required`,
    //     description: `These cookies are required in order for Sitterpoint.co.uk to function correctly and cannot be disabled.`
    //   },
    //   // {
    //   //   header: `Functional Cookies`,
    //   //   status: 'enabled',
    //   //   description: `These cookies make the site more efficient for you and remember some of your preferences, but are not essential for using the site.`
    //   // },
    //   {
    //     header: `Analytical Cookies`,
    //     status: `required`,
    //     // status: 'enabled',
    //     description: `Analytical cookies help us to improve our website by collecting and reporting information on its usage.`
    //   }
    //   // {
    //   //   // header: `Facebook Cookies`,
    //   //   // description: `These cookies allow you to login to Sitterpoint.co.uk using your Facebook account. If you choose to disable them but then try and login via Facebook, we'll enable them for you.`,
    //   //   //
    //   // },
    //   // {
    //   //   // header: `Targetting Cookies`,
    //   //   // description: `Our partners set cookies to help show you more relevant advertising on other sites.`
    //   // }


    // ]


  }


};


export default cookies;
