import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Modal,
    Header
} from "semantic-ui-react";
import { ReportGA } from '../../utils/ga';
import { hideModal } from '../../actions/modal';
import buildThreadDetails, { getApproveButtonDetails } from '../../lib/threadHelpers';
import { setEditMode, setNewMessage } from '../../actions/messages';
import { resetActionBar, showApproveActionBar } from '../../actions/layout';
import { clearSearchQuery as clearSearchAction, clearEditSearchQuery } from '../../actions/search';
import { H4, H5, A5, A6 } from '../../styles/text';
import colours from '../../styles/colours';
import { sit_details } from '../../text';
import { isDateTimeInPast } from '../../lib/timeHelpers';

import { EDIT_SIT_DETAILS } from '../../components/modals/modalNames';
import { SEARCH, MESSAGES } from '../../constants/ga-category';
import EditSearchContainer from '../messages/edit-search-container';
import MessageBarContainer from '../messages/message-bar';
// need to add validation before save

// const actions = { hideModal };
const cellBorderColor = colours.mediumlightgray;


class EditSitDetailsModal extends Component {

  componentDidMount () {
    // clear Search to reset times
    const { clearSearchQuery } = this.props;
    // clearSearchQuery(); / this clears previous saved search and stops modal loading at pre-first message

    ReportGA({
      category: MESSAGES,
      action: `Modal shown to user ${EDIT_SIT_DETAILS}`
      // meta: { window_width, afterClose, hideModal, cancelAction, backAction, okAction, modalText, modalCategory, modalName, clashing_bookings, cameraAction, docStatus, adult_id, adult_name, doc_name, thread_stage, payment_type, user_type }
    });

  }

  handleMessageInput = (event) => {
    const { handleChange } = this.props; // to hook
    handleChange(event.target.value);
  }

  // renderMessageBar (action) {
  //
  //   // sending saves changes to sit details and sends message to other user, re-statusing sit if required
  //   const {
  //     isInPast,
  //     sitter_id,
  //     requester_id,
  //     status, sp_actual, sp_quoted, messagesLength, newMessageContent, isSendingMessage, resetActionBar } = this.props;
  //
  //   console.log('sp_quoted: ', sp_quoted);
  //   const approvePlaceholder = 'approve_placeholder';
  //
  //   return (
  //
  //     <MessageBarContainer
  //       reset={resetActionBar}
  //       approve
  //       action={action}
  //       approvePlaceholder={approvePlaceholder}
  //
  //       newMessageContent={newMessageContent}
  //       isSendingMessage={isSendingMessage}
  //
  //       isInPast={isInPast}
  //       sitter_id={sitter_id}
  //       requester_id={requester_id}
  //       status={status}
  //       sp_actual={sp_actual}
  //       sp_quoted={sp_quoted}
  //
  //       messagesLength={messagesLength}
  //
  //     />
  //   );
  // }


  render () {

    const {
      // title
    window_width,
    afterClose,
    hideModal,
    user_id,

    // to hook
    newMessageThreadParams,
    thread_details,
    tariff_details,
    saved_search_details,
    edited_thread_details,
    // editMode, // fixed to true as we are always editing, if not then we should use state above and setReduxstate
    isSendingMessage,
    clickUpdate,
    closeModal

    } = this.props;


    const { title, para, update } = sit_details.modal.edit_sit_details.edit;

    console.log('EditSitDetailsModal this.props:', this.props);

    const onClose = () => {

      closeModal();


      if (afterClose) {
        afterClose();
      }
    };


    // const newMessageThreadParams = null;
    const editMode = true; // fixed to true as we are always editing, if not then we should use state above and setReduxstate
    console.log('thread_details.status:', thread_details.status);
    const latestEditedThreadDetails = edited_thread_details.saved_start_date === null ? saved_search_details : edited_thread_details;
    console.log('latestEditedThreadDetails', latestEditedThreadDetails);
    const threadDetails = buildThreadDetails(editMode, newMessageThreadParams, thread_details, tariff_details, latestEditedThreadDetails);
    console.log('threadDetails: ', threadDetails);
    const {
      start_date,
      end_date,
      start_time,
      end_time,
      kids,
      status,
      sitter_id,
      requester_id,
      sp_quoted,
      sp_actual
    } = threadDetails;

    const sitter_rating = thread_details !== {} ? thread_details.sitter_rating : undefined;
    const requester_rating = thread_details !== {} ? thread_details.requester_rating : undefined;


    const isInPast = isDateTimeInPast(end_date, end_time);
    const isStarted = isDateTimeInPast(start_date, start_time);

    const showPreAuthorise = (user_id === sitter_id) && ((isInPast && ((status !== 'finalised'))));
    const finaliseMode = (isInPast && (status !== 'finalised'));
    const preliminaryThreadDetails = { start_date, end_date, start_time, end_time, kids };
    console.log('preliminaryThreadDetails:', preliminaryThreadDetails);
    // determines action for submission

    console.log('EditSitDetails STATUS:', status);

    const showReviewModal = null;
    const subscription_status = null; // don't think this is relevant for editing (will go to pre-approve afterward anyway)
    const messagesLength = null; // not used in this function
    const approveButtonDetails = getApproveButtonDetails(editMode, setEditMode, showReviewModal, subscription_status, messagesLength, user_id, sitter_id, requester_id, isInPast, isStarted, status, thread_details, sp_actual, requester_rating, sitter_rating, edited_thread_details);

    const action = !approveButtonDetails ? '' : approveButtonDetails.action;
    console.log('SET_NEW_MESSAGE action result:', action);
    const approvePlaceholder = 'approve_placeholder';

    // test whether detail have changed to conditionally show message bar
    const editParameters = (!edited_thread_details.saved_start_date || !edited_thread_details.saved_start_hr || !edited_thread_details.saved_start_min || !edited_thread_details.saved_end_hr || !edited_thread_details.saved_end_min || !edited_thread_details.saved_selected_kids) ? null : { start_date: edited_thread_details.saved_start_date, end_date: edited_thread_details.saved_end_date, start_time: `${edited_thread_details.saved_start_hr}:${edited_thread_details.saved_start_min}`, end_time: `${edited_thread_details.saved_end_hr}:${edited_thread_details.saved_end_min}`, kids: edited_thread_details.saved_selected_kids };

    const threadParameters = (!thread_details.start_date || !thread_details.start_time || !thread_details.end_time || !thread_details.kids) ? null : { start_date: thread_details.start_date, end_date: thread_details.end_date, start_time: thread_details.start_time, end_time: thread_details.end_time, kids: thread_details.kids };

    const savedSearchParameters = (!saved_search_details.saved_start_date || !saved_search_details.saved_start_hr || !saved_search_details.saved_start_min || !saved_search_details.saved_end_hr || !saved_search_details.saved_end_min || !saved_search_details.saved_selected_kids) ? null : { start_date: saved_search_details.saved_start_date, end_date: saved_search_details.saved_end_date, start_time: `${saved_search_details.saved_start_hr}:${saved_search_details.saved_start_min}`, end_time: `${saved_search_details.saved_end_hr}:${saved_search_details.saved_end_min}`, kids: saved_search_details.saved_selected_kids };
    const previousParameters = !threadParameters ? savedSearchParameters : threadParameters; // pre-first contact, thread params will be null, so use previous search params as comparison.

    const areEditParamsSame = editParameters === null || JSON.stringify(previousParameters) === JSON.stringify(editParameters);
    console.log('NEW DEETS editParameters: ', editParameters);
    console.log('NEW DEETS threadParameters: ', threadParameters);
    console.log('NEW DEETS savedSearchParameters: ', savedSearchParameters);
    console.log('NEW DEETS previousParameters: ', previousParameters);
    console.log('NEW DEETS areEditParamsSame: ', areEditParamsSame);

    return (

      <Modal
        centered={false} // top align
        closeIcon="close"
        open={true}
        onClose={onClose}
        style={{ maxWidth: 500 }}

      >

        <Modal.Content>


          <Header>

            <div style={{ marginTop: window_width > 991 ? 8 : 32, padding: 8, background: colours.mediumlightpurple, display: 'flex', justifyContent: 'center' }}>
              <H4 color={colours.white} textAlign='center'>{title}</H4>
            </div>

          </Header>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <p>
              <A5>{para}</A5>
            </p>
          </div>

          {
            // body
          }

          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

            <EditSearchContainer
              noTitle
              showPreAuthorise={showPreAuthorise}
              finaliseMode={finaliseMode}
              preliminaryThreadDetails={preliminaryThreadDetails}
            />


          </div>

          <div style={{ display: 'flex', width: '100%', paddingTop: 4, flexDirection: 'row', justifyContent: 'flex-end' }}>

            {
              // if edited
              !areEditParamsSame &&
              <MessageBarContainer
                // reset={resetActionBar}
                approve
                action={action}
                approvePlaceholder={approvePlaceholder}

                // newMessageContent={newMessageContent}
                isSendingMessage={isSendingMessage}

                isInPast={isInPast}
                sitter_id={sitter_id}
                requester_id={requester_id}
                status={status}
                sp_actual={sp_actual}
                sp_quoted={sp_quoted}

                messagesLength={messagesLength}

              />
            }

            {
              // <Button
              //   primary
              //
              //   style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
              //   onClick={() => clickUpdate()}
              // >
              //
              //   {update}
              //
              // </Button>
          }


          </div>


        </Modal.Content>


      </Modal>

    );
  }
}


EditSitDetailsModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

const mapStateToProps = ({ user, layout, messageThread, editSearch, search, tariff }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  window_width: layout.window_width,
  user_id: user.user_id,
  thread_details: (messageThread.thread_messages.length === 0) ? {} : messageThread.thread_details,
  edited_thread_details: editSearch,
  saved_search_details: search,
  tariff_details: tariff.tariff_details,
  editMode: messageThread.editMode,
  isSendingMessage: messageThread.isSendingMessage,
  newMessageThreadParams: messageThread.newMessageThreadParams,

  isFetchingUpload: user.isFetchingUpload,
  isFetchingSuccess: user.isFetchingSuccess,
  successText: ownProps.successText,
  fetchingText: ownProps.fetchingText,
  afterClose: ownProps.afterClose,
  hideModal: ownProps.hideModal,
  title: ownProps.title,
  message: ownProps.message


});

const mapDispatchToProps = dispatch => ({

  clearSearchQuery: () => {
    dispatch(clearSearchAction());
  },

  hideModal: () => {
    dispatch(hideModal());
  },

  clickUpdate: () => {

    dispatch(hideModal());
    dispatch(showApproveActionBar());

    ReportGA({
      category: MESSAGES,
      action: `User clicked clickUpdateSitDetails on EditSitDetails modal`
    });

  },

  closeModal: () => {
    // also resets Action Bar if clicking close on modal
    ReportGA({
      category: MESSAGES,
      action: `User clicked Close from EditSitDetailsModal`
    });

    dispatch(resetActionBar());
    dispatch(setEditMode(false));
    dispatch(clearEditSearchQuery());
    dispatch(hideModal()); // check this doesn't stuff up any other actions

  },

  // handleChange: (text) => {
  //   console.log('typed messageText', text);
  //   dispatch(setNewMessage(text));
  // },

  // resetActionBar: () => {
  //   dispatch(resetActionBar());
  // }

});

export default connect(mapStateToProps, mapDispatchToProps)(EditSitDetailsModal);
