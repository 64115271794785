import React from 'react';

export const philosophy = {
  page_title: 'Our philosophy',
  page_sub_title_line1: '',
  page_sub_title_line2: '',

  section1: {
    para1: `We created this platform because we believe that every parent deserves a break from time to time and should enjoy some child-free time to do whatever they need to do without having to worry about expensive childcare or the awkwardness to ask around for help.`,
    para2: ``,
    para3: ``
  },

  section2: {
    header_line1: `The benefits of being part `,
    header_line2: `of the SitterPoint community`,
    para1: <div>As well as <b>saving money</b> on childcare and babysitting, we believe this concept is a <b>win-win</b> situation for <b>parents as well as for children</b>.</div>,

    bullets: [
      {
        sub_title: `Go out without spending a fortune on babysitting`,
        paras: [
          `Many parents think twice before going out without their children as babysitting cost can impact considerably the overall budget.`
        ]
      },
      {
        sub_title: `Have a network of free babysitters in your area`,
        paras: [
          `Many parents find it awkward to ask around for some help for a specific time and date. They also feel they have to return the favour quickly for the same amount of time.`
        ]
      },
      {
        sub_title: `Your children socialise and have more playdates`,
        paras: [
          `Children love playdates, whether at home, at a friend’s place, in the park or at an activity centre. They love playing with new toys and meeting new friends. This allows them to socialise, stimulates them and gives them to have access to different cultural environments.`
        ]
      },
      {
        sub_title: `Build a stronger community`,
        paras: [
          `By helping each other with babysitting and childcare, we believe it builds positive rewards and reinforcement for an enjoyable sense of togetherness.`
        ]
      }
    ],

    para2: `It may feel overwhelming to have to sit other children.`,
    para3: <div>The reality is that most of the time it is <b>easier to have a few children to manage</b>, as they will play together and can easily entertain themselves without you having to do too much. Also, <b>children often behave and listen better in other households</b>, than at home.</div>,
    para4: `To help families, we created this page:`,
    tips_link: `Tips for a successful sit.`

  }


};


export default philosophy;
