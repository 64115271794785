import React from 'react';
import colours from '../../styles/colours';
import { fonts } from '../../styles/theme';

const HighlightedText = ({ children, color }) => {

    return (
      <span
        style={{ color: color || colours.mediumlightpurple, fontWeight: fonts.semibold }}
      >
        {children}
      </span>
    );
};

export default HighlightedText;
