/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { SetGA, ReportGA } from '../../../utils/ga';
import {
    Icon,
    Dropdown,
    Menu
} from "semantic-ui-react";
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import '@trendmicro/react-buttons/dist/react-buttons.css';
// import '@trendmicro/react-dropdown/dist/react-dropdown.css';
// import 'react-dropdown/style.css';
// import 'react-select/dist/react-select.css';
import history from '../../Navigation/history';
import Avatar from '../../general/avatar';
import ProgressBar from '../../general/progress-bar';
import { Row, DropDownButton } from '../../../styles/styles';
import { T4, A5 } from '../../../styles/text';
import { DropdownItem } from '../../../styles/buttons';
import colours from '../../../styles/colours';
import Notif from '../../general/notif';
import { PROFILE } from '../../../constants/ga-category';


const defaultStyle = (burger, width) => {
  return ({
    opacity: 1,
    position: burger ? 'fixed' : 'absolute',
    top: burger ? 118 : 60, // same as your nav height
    right: burger ? null : 0,
    // left: burger ? '10%' : null,
    left: burger ? 0 : null,
    margin: 0,
    // marginLeft: burger ? (width) * -0.6 : 0,
    marginLeft: burger ? 0 : 0,
    background: 'transparent',
    padding: 0,
    paddingLeft: burger ? 10 : 0,
    paddingRight: burger ? 10 : 0,
    textAlign: 'center',
    // transition: 'all 1000 ease',
    zIndex: 2000,
    width: burger ? (width * 1) - 0 : 350
  });

};

const fadeInStyles = {

  entering: { opacity: 0 },
  entered: { transition: 'opacity 200ms linear', opacity: 1 },
  exiting: { transition: 'opacity 100ms linear', opacity: 0 },
  exited: { opacity: 0 }
};


export default class DropdownMenu extends Component {

  state = {
    open: false
  }


  handleClick = () => {

      // if click is inside, do something
      const { open } = this.state;
      this.setState({ open: !open });

      ReportGA({
        category: PROFILE,
        action: `Profile dropdown button clicked on navbar `
      });

  }

  handleOutsideClick = () => {

      // if click is inside, do something
      this.setState({ open: false });

  }

  handleSelect = (route, editMode) => {

    const { onBurgerClick, burgerIsOpen, handleDropdownNavClick, dropdown_nav_is_open } = this.props;

    ReportGA({
      category: PROFILE,
      action: `User clicked ${route} on Profile dropdown`
    });

    this.setState({ open: false }); // closes dropdown menu
    history.push({
      pathname: route,
      state: { editMode: editMode || false }

    });
    // console.log('aboutToClick:', this.props);
    if (burgerIsOpen) {
      onBurgerClick(burgerIsOpen); // closes burgermenu - is still needed?
    }

    // console.log('HANDLE SELECT:', dropdown_nav_is_open);

    if (dropdown_nav_is_open) {
      console.log('closing dropdown nav because open Dropdown Messages');
      handleDropdownNavClick(dropdown_nav_is_open); // closes dropdown nav if open
    }


  }


  render () {


    const { open } = this.state;


    const { photo_url, showValue, value, options, icon, iconSize, width, burger } = this.props;
    // console.log('options', options);


    const dropdownValue = value;
    console.log('options: ', options);

    // const defaultOption = options[this.props.value - 1];


    const menuItems = options.map((option, i) => {

        const currentValue = option.value;


        console.log('option', option);
        if (!option.value) {
          return option;
        }


        return (


          <DropdownItem
            // type='button'
            key={`myDropdownKey ${option.value}`}
            eventkey={currentValue}
            index={i}
            onClick={() => this.handleSelect(option.route, option.editMode)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.handleSelect(option.route);
              }
            }}
            style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            // description='2 new'
            // text='Important'

          >

            {
              option.value
              && <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

                <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 4, alignSelf: 'flex-end', height: '100%', background: 'transparent' }}>
                  {
                    option.value
                    && <A5 textAlign='left'>{option.value}</A5>
                  }

                  {
                    option.blurb
                    && <A5
                      textAlign='left'
                      color={colours.red}
                    >{option.blurb}</A5>
                  }
                  {
                    option.text
                    && <A5>{option.text}</A5>
                  }
                </div>


                  {
                    option.points
                    && <div style={{ paddingBottom: 2, alignSelf: 'flex-end', display: 'flex', flex: 0.5, background: 'transparent' }}>
                      <A5>{option.points}</A5>
                    </div>
                  }
                  {
                      option.unit
                      && <div style={{ paddingBottom: 2, alignSelf: 'flex-end', display: 'flex', flex: 0.5, background: 'transparent' }}>
                        <A5>&nbsp;{option.unit}</A5>
                      </div>
                  }
                  {
                    option.progress !== undefined
                    ? <div style={{ paddingLeft: 8, flexDirection: 'row', alignItems: 'center', paddingBottom: 2, display: 'flex', flex: 1, background: 'transparent' }}>
                      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', borderColor: 'blue', borderWidth: 0, borderStyle: 'solid' }}>
                        <ProgressBar progress={option.progress} />
                      </div>
                    </div>
                    : null


                  }

              </div>

            }
          </DropdownItem>

        );
    });

    return (

      <div

        style={{
          flex: 1,
          display: 'flex',
          position: 'relative',
          cursor: 'pointer',
          borderWidth: 0,
          borderColor: colours.lightgray,
          borderStyle: 'solid',
          borderRadius: 0,
          height: '100%',
          minWidth: 68
        }}
      >
        <DropDownButton

          type='button'
          onClick={this.handleClick}
          onKeyDown={this.handleClick}
          style={{ display: 'flex', justifyContent: 'center', borderWidth: 0,
          borderColor: colours.lightgray,
          borderStyle: 'solid' }}

        >

          <div style={{ position: 'relative', display: 'table' }}>
            <div style={{ margin: 8 }}>
              {
                photo_url &&

                  <Avatar
                    size={36}
                    src={ photo_url } />


              }
              { !photo_url && icon
                && <FontAwesomeIcon
                  icon={icon}
                  size={iconSize}
                  style={{ color: colours.gray }} />
              }
            </div>
          </div>


          <div style={{ position: 'absolute', display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Icon name='caret down' size='small' />
          </div>


        </DropDownButton>
        <Transition
          in={open}
          appear={true}
          exit={true}
          timeout={{
            appear: 50,
            enter: 50,
            exit: 200
          }}

          unmountOnExit
          // classNames='fade'
        >
          { state => (

            <div

              style={{
                ...defaultStyle(burger, width),
                ...fadeInStyles[state]
              }}

            >
              <div
                role='presentation'
                onClick={this.handleOutsideClick}
                onKeyDown={this.handleOutsideClick}
                style={{
                  position: 'fixed',
                  opacity: 0.2,
                  background: colours.darkgray,
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: -1
              }} />

              <Menu style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: `1px 1px 1px ${colours.shadow}`

              }}
                vertical
              >

                {menuItems}
              </Menu>

            </div>
          )}

        </Transition>


      </div>

    );
  }
}
