import { connect } from 'react-redux';
import AvailabilityWeek from '../../components/calendar/availability-week';

import { getUserById } from '../../actions/auth/profile';
import { setCheckboxWidth } from '../../actions/layout';
import { setBookedDates } from '../../actions/calendar';
import { getAllThreadsById } from '../../actions/messages';

const mapStateToProps = ({ user, layout, calendar, nav, inbox }) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  window_width: layout.window_width,
  window_height: layout.window_height,
  user_id: user.user_id,
  home_address: user.home_address,
  // user_messages: messages.user_messages,
  isLoggingIn: user.isLoggingIn,
  available_dates: calendar.available_dates,
  booked_dates: calendar.booked_dates,
  combinedBookingsArray: [...calendar.available_dates, ...calendar.booked_dates],
  dropdown_nav_is_open: nav.dropdown_nav_is_open,
  isFetchingCalendar: calendar.isFetchingCalendar,
  isSavingAvailability: calendar.isSavingAvailability,
  allThreads: Array.isArray(inbox.all_threads) ? inbox.all_threads : [],
  upcomingAvailability: calendar.upcoming_availability

});

const mapDispatchToProps = (dispatch) => ({

  handleGetUser: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      console.log('availability get User');
      dispatch(getUserById(token, user_id));

    }

  },

  handleGetAllThreads: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getAllThreadsById(token, user_id));

    }

  },
  setBookedDates: (usersBookedDates) => {
    dispatch(setBookedDates(usersBookedDates));
  },
  setCheckboxWidth: (checkboxWidth) => {
    dispatch(setCheckboxWidth(checkboxWidth));
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityWeek);
