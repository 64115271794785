import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Header
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";


export const StyledP = styled.p`

  padding: ${props => props.padding ? props.padding : null}!important;
  font-weight: ${fonts.bold}!important;
  color: ${props => props.color ? props.color : colours.black}!important;
  background: ${props => props.backgroundColor ? props.backgroundColor : null}!important;
  text-align: ${props => props.textAlign ? props.textAlign : 'left'}!important;
  font-size: ${props => props.screensize = 'small' ? '1em' : '4em'}!important;

  &:hover {
    background: ${props => props.hoverBackground ? props.hoverBackground : null}!important;
    color: ${props => props.hoverColor ? props.hoverColor : null}!important;
  }

`;

const mapStateToProps = (layout, ownProps) => {

    return {
      screensize: layout.screensize,
      color: ownProps.color,
      textAlign: ownProps.textAlign,
      ...ownProps
    };

};


class B4 extends Component {

  render () {

    const { screensize, color, backgroundColor, textAlign, padding, hoverBackground, hoverColor } = this.props;
    // console.log('B4 props:', this.props);

    return (
      <StyledP

        screensize={screensize}

        color={color || null}
        backgroundColor={backgroundColor || null}
        padding={padding}
        hoverBackground={hoverBackground}
        hoverColor={hoverColor}

        textAlign={textAlign || null}

      >
        {this.props.children}
      </StyledP>
    );

  }
}

export default connect(
    mapStateToProps
)(B4);
