import { connect } from 'react-redux';
import SearchbarTop from '../../components/Navigation/searchbar-top';
import { persistor, store } from '../../init-store';
import { logout } from '../../actions/auth/signup';
import { showModal, hideModal } from '../../actions/modal';
import { openBurger, closeBurger } from '../../actions/navbar';
import * as ROUTES from '../../constants/routes';
import { showSearchBanner, scrolledToSearchBanner, hideSearchBanner } from '../../actions/search';
import history from '../../components/Navigation/history';
import { MODAL_TYPE_SIGNIN, MODAL_TYPE_SIGNUP } from '../../components/modals/modalTypes';

const mapStateToProps = ({ user, nav, search, layout }, ownProps) => {
  // console.log('this.state:', this.state);
  // console.log('localStorage: ', localStorage);
  return {
    isLoggedIn: user.isLoggedIn,
    isLoggingIn: user.isLoggingIn,
    burgerIsOpen: nav.burgerIsOpen,
    window_width: layout.window_width,
    searchBannerIsOpen: search.banner_is_open,
    dropdown_nav_is_open: nav.dropdown_nav_is_open,
    scroll_to_banner: search.scroll_to_banner
    // forceSkip: ownProps.forceSkip,
    // pristine, goBack, skip, location
  };

};

const mapDispatchToProps = dispatch => ({
  showSearchBanner: () => {
    dispatch(showSearchBanner());
  },
  scrolledToSearchBanner: () => {
    dispatch(scrolledToSearchBanner());
  },

  hideSearchBanner: () => {
    dispatch(hideSearchBanner());
  }
});

const SearchbarTopContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchbarTop);

export default SearchbarTopContainer;
