import history from '../../components/Navigation/history';
import { showModal, hideModal } from '../../actions/modal';
import { getMessageThread } from '../messages';
import { actionSuccess } from '../verify';
import { setShowSubscribePayment } from './index';
import { resetActionBar } from '../layout';
import { plan as planText, sit_details } from '../../text';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_SUCCESS, MODAL_TYPE_ERROR } from '../../components/modals/modalTypes';
import { SUCCESS_ATTACH_PAYMENT } from '../../components/modals/modalNames';
import { PLAN, BOOKING } from '../../components/modals/modalCategory';
import * as ROUTES from '../../constants/routes';


export const ATTACH_PAYMENT_METHOD_REQUEST = 'ATTACH_PAYMENT_METHOD_REQUEST';
export const ATTACH_PAYMENT_METHOD_SUCCESS = 'ATTACH_PAYMENT_METHOD_SUCCESS';
export const ATTACH_PAYMENT_METHOD_FAILURE = 'ATTACH_PAYMENT_METHOD_FAILURE';


export const attachPaymentMethodRequest = () => ({
  type: ATTACH_PAYMENT_METHOD_REQUEST
});

export const attachPaymentMethodSuccess = data => ({
  type: ATTACH_PAYMENT_METHOD_SUCCESS,
  data
});

export const attachPaymentMethodFailure = error => ({
  type: ATTACH_PAYMENT_METHOD_FAILURE,
  error
});


// need similar for subscription creation (without sit)
export function attachPaymentMethod (token, messageParams, user_id, email, payment_method, plan, promo_id) {
  // console.log('payment_method:', payment_method);
  // console.log('plan:', plan);

  console.log('ATTACH PAYMENT METHOD');
  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: planText.modal.subscribe.fetching
      }
    ));
    dispatch(attachPaymentMethodRequest());

    // determin if subscription or booking with subscription (will have thread_id)

    // approve Sit if came via booking
    console.log('!messageParams:', !messageParams);

    if (!messageParams) {
      // subscription only

      fetch(`${process.env.REACT_APP_API_URI}/payment/customer/update_payment_method`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: token,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        },
        body: JSON.stringify({
          user_id,
          email,
          payment_method
          // plan,
          // promo_id,
          // message_params: messageParams
        })

      })
      .then(CheckError)
      .then((data) => {
        console.log('attach Payment Method response customer:', data);
        dispatch(attachPaymentMethodSuccess(data));

        console.log('no thread = subscription, so goto myPlan:');
        // success message, get user's updated subscription status and back to plan?

        // const datapayments = data.payments;

        // const lastpaymentlength = datapayments.length;
        // console.log('lastpaymentlength:', lastpaymentlength);

        // const lastpayment = JSON.parse(datapayments[datapayments.length - 1]);
        // const amount = lastpayment.amount;
        // const currency_amount = amount;

        dispatch(hideModal());
        dispatch(resetActionBar()); // resets MessageBar and ActionBar

        const modalText = planText.modal.update_payment_method.success; // ?

        dispatch(showModal(

          MODAL_TYPE_SUCCESS,
          { // modalProps
            // other_user_name?
            modalCategory: PLAN,
            modalName: SUCCESS_ATTACH_PAYMENT,
            modalText: modalText,
            okAction: () => {
              window.location.reload(true); // ensures myPlan updates and scrolls to top
              // dispatch(hideModal());
            },
            backAction: null,

            // ? payment_type: 'subscription'

          }
        ));

        dispatch(actionSuccess()); // triggers success icon

        dispatch(setShowSubscribePayment(false));

        history.push({
          pathname: ROUTES.PLAN,
          state: { editMode: false }
        });


      })
      .catch((err) => {
        console.log('caught attach Payment Method error: ', err);

        dispatch(attachPaymentMethodFailure(err.message));
        // centralise bugsnag reporting
        ReportError(!err.code ? null : err.code, err.message, {});
        dispatch(showModal(
          MODAL_TYPE_ERROR,
          { // modalProps
            title: planText.modal.update_payment_method.failure.title,
            header: planText.modal.update_payment_method.failure.header,
            message: planText.modal.update_payment_method.failure.para,
            message2: planText.modal.update_payment_method.failure.para2,
            error_status: err.code && err.code,
            error_message: err.message
          }
        ));

        dispatch(actionSuccess());

      });

    } else {

      // sit and subscription
      // don't think this can happen because if only updating details, there won't be a sit (will be from My Plan)

    }

  };

}
