import React from 'react';
import colours from '../../styles/colours';

const Notif = ({ children }) => (
  <div style={{ zIndex: 1000, position: 'absolute', height: '100%', width: '100%' }}>
    <div style={{ position: 'absolute', display: 'table', verticalAlign: 'middle', bottom: 0, right: 0, background: colours.notification, height: 19, width: 19, borderRadius: '50%' }}>
      <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
        <p style={{ fontSize: 12, fontWeight: 600, textAlign: 'center', color: colours.white }}>{children}</p>
      </div>
    </div>
  </div>
);

export default Notif;
