const colours = {
  white: '#ffffff',
  verylightgray: '#f7f7f8',
  offWhite: '#f7f7f7',
  lightgray: '#f2f2f1',
  past: '#f2f2f1',
  bordergray: '#a0a3a1',
  mediumlightgray: '#a0a3a1',
  oldmediumlightgray: '#e3e3e3',
  gray: '#727272',
  darkgray: '#404040',
  red: '#C96363',
  verylightred: '#f0c2c2',

  black: '#2B2B2B',
  blue: '#316AB3', // blue of tabbar
  darkblue: '#316AB3', // blue of dots on landing
  error: '#ED2024',
  lightblue: '#316AB3',
  verylightblue: '#efefef',
  blueWhat: '#424FA1', // blue of what
  borderblue: '#c2dfdb',

  verylightgreen: '#E9F2F0',


  verylightpurple: '#F3F2F9',
  lightpurplegray: '#CCC1E0',
  borderColour: '#CCC1E0',
  lightpurple: '#A891C4',
  main: '#A891C4', // tabbar, card borders, etc - purple
  mediumlightpurple: '#8C75ab',
  faviconpurple: '#8b83b9',
  darkpurple: '#675a9a',
  logopurple: '#a58bc0', // new one
  inputborder: '#9d9d9c',

  orange: '#F5A824',
  magenta: '#e30b67',
  lightyellow: '#F58220',
  yellow: 'gold',
  green: '#39ab49',
  goodGreen: '#87be3d', // was previously '#8DC744',
  someGreen: '#bad58f', // some availability
  tickGreen: '#4AAC33',

  what: '#424FA1',
  where: '#7D3E98',
  when: '#F58220',
  confirm: '#F5A824',
  cta: `#ed6e1c`,
  next: '#8DC744',

  transparent: 'transparent',
  background: '#f7f7f8',
  modalBackground: 'rgba(52, 52, 52, 0.8)',
  headerButtonColor: '#939598',
  headerTitleColor: '#404040',
  heading: '#316AB3',
  subheading: '#2B2B2B',

  shadow: 'rgba(50,50,50,0.1)',

  // map
  marker_main: '#e84526',

  // upcoming_dates
  unavailable: '#ffffff',
  using: '#8C75ab', // colours.mediumlightpurple
  pending_booked_sitter: '#F5A824', // colours.orange
  needFinalise: '#1ee7c2',
  sitting: '#8C75ab',
  pending_am_booked: '#F5A824',
  awaitingFinalise: '#F5A824',
  requested: '#a18ac1',

  // calendar
  reqBooked: '#8DC744', // colours.green;
  sitBooked: '#F5A824', // colours.orange;
  available: '#A891C4', // colours.darkpurple; // old available: '#62bd5f',
  clashing: '#f0c2c2', // colours.verylightred

  offered: '#CCC1E0',

  // inbox

  notification: `#fa3e3e`,

  ownMessage: '#ffffff',
  ownMessageBorder: '#F5A824',
  otherMessage: '#f7f7f8',
  otherMessageBorder: '#A891C4',
  stars: 'gold'
};


export default colours;
