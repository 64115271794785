import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, Icon
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";
import { B4 } from '../../text';

// opacity: props.opacity || 1

export const StyledButton = styled(Button)`

  padding: 0px!important;
  padding-left: 0px!important;
  padding-right: 0px!important;
  margin: 0px!important;
  color: ${props => props.color ? props.color : colours.black}!important;
  background: ${colours.white}!important;
  border-radius: 0px!important;
  border-width: 0px!important;
  border-style: solid!important;
  border-color: ${colours.orange}!important;

  cursor: pointer!important;

  /* &:hover {
    background: ${colours.verylightgray}!important;
    color: ${colours.mediumlightpurple}!important;
  } */

`;


const SignupButton = (props) => {
  console.log('props.backgroundColor', props.backgroundColor);
  return (
    <StyledButton
      primary={props.primary}
      icon={props.icon}
      labelPosition={props.icon ? props.labelPosition || 'left' : null}
      fluid={props.fluid}
      compact={props.compact}
      disabled={props.disabled}

      color={props.color}
      backgroundColor={props.backgroundColor}

      onClick={props.onClick}
    >
      {
        props.icon
        && <Icon name={props.icon} />
      }
      {
        <B4
          color={colours.white}
          backgroundColor={colours.mediumlightpurple}
          padding='4px'
          hoverColor={colours.mediumlightpurple}
          hoverBackground={colours.verylightgray}

        >{props.children}</B4>
      }
    </StyledButton>
  );
};

export default SignupButton;
