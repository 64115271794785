
export const search = {

  section_search_title: 'Find a local sitter',
  complete_profile_para: 'You must complete your profile to search for sitters',
  complete_profile_button: 'Complete your profile!',
  get_verified_para: 'You must be a verified user to search for sitters.',
  get_verified_button: 'Get Verified!',
  title: 'Search',
  select_date_time: 'Date(s)',
  date_time_placeholder: 'When',
  date_time_edit: 'Date(s)',
  date: 'Date',
  dropoff: 'Dropoff time',
  pickup: 'Pickup time',
  for: 'For',
  kids_placeholder: 'Children',
  edit_search: 'Edit search',
  select_children: 'Select children',
  for_children_long: 'Children',
  for_children_short: 'Children',
  for_children_placeholder: 'For who',
  sp: 'SP',
  invalid_dates: 'Please enter valid dates and times',
  select_dates_message: 'Choose date(s)',
  select_times_message: 'Choose dropoff and pickup times',
  select_kids_message: 'Please select kid(s)',
  not_enough_points_message: 'You do not have enough points, please reduce the time or earn more points.',
  click_search_message: 'Click search to find nearby sitters',
  sit_time_label: 'You are requesting a sitter for',
  points_used_label: 'This will use ',
  membersince_label: 'Member since:',
  distance_label: 'Distance:',
  miles: 'miles',
  children_label: 'Children:',
  rating_label: 'Rating:',
  view_full_profile: 'View full profile',
  no_available_users: 'Sorry, there are no available sitters for your selected times.',
  only_flexible_users: `Sorry, there are no available sitters for your selected times.  Click the button below to show families who've marked themselves as flexible.`,
  results_header: 'Local families available for the date and time you searched',
  add_location: 'Add your location to your profile to see local families',
  filter_results: `Filter results`,
  sort_by: `Sort by`,
  contact_sitter: `Contact Sitter`,
  go_to_chat: `Go to Chat`,
  already_contacted: `Already contacted`,
  back_to_results: `Back to search results`,

  modal: {
    fetching: 'searching...',
    failure: {
      title: 'Search Failed',
      header: 'Oops',
      para: 'Your search could not be completed.  This could be due to a network error, please try again.',
      para2: 'Please contact us if this problem persists.',
      ok: 'OK'
    }
  }
};


export default search;
