export const REFERRAL_SIGNUP = 50;
export const REFERRAL_FIRSTSIT = 50;
export const PROFILE_COMPLETE = 450; // need to change graphic on dashboard if change
export const VERIFY_USER = 150;
export const DBS_PART = 50; // just do it once for 1st adult, no additional bonuse for next adult.
export const DBS_FULL = 150; // remove DBS_FULL

export const REFERRALS_SIGNUP_LIMIT = 15;
export const REFERRALS_FIRSTSIT_LIMIT = 15;

export const MAX_SIGNUP_BONUS = (REFERRALS_SIGNUP_LIMIT * REFERRAL_SIGNUP) + (REFERRALS_FIRSTSIT_LIMIT * REFERRAL_FIRSTSIT);

export const GOLD_MEMBER_ANNUAL = 500; // do we want to limit to only once per year, or one-off?
export const GOLD_MEMBER_MONTH = 100; // do we want to limit to once per month, after full 12 months, or one-off?
// must mirror API
