// import initSocket from '../socket-router';
// require('dotenv').config();

export const OPEN_BURGER = 'OPEN_BURGER';
export const CLOSE_BURGER = 'CLOSE_BURGER';
export const OPEN_DROPDOWN_NAV = 'OPEN_DROPDOWN_NAV';
export const CLOSE_DROPDOWN_NAV = 'CLOSE_DROPDOWN_NAV';
export const SET_INBOX_FILTER = 'SET_INBOX_FILTER';
export const SET_THREAD_BACK = 'SET_THREAD_BACK';


export const openBurger = () => ({
  type: OPEN_BURGER
});

export const closeBurger = () => ({
  type: CLOSE_BURGER
});

export const openDropdownNav = () => ({
  type: OPEN_DROPDOWN_NAV
});

export const closeDropdownNav = () => ({
  type: CLOSE_DROPDOWN_NAV
});

export const setInboxFilter = data => ({
  type: SET_INBOX_FILTER,
  data
});

export const setThreadBack = data => ({
  type: SET_THREAD_BACK,
  data
});
