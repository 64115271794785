import update from 'immutability-helper';
import * as messages from '../actions/messages';

export const initialState = {
  newMessageContent: '',
  newMessagePlaceholder: '',
  isSendingMessage: false,
  isEditingDetails: false,
  isFetchingThread: false,
  isFetching: false,
  newMessageThreadParams: {},
  message_thread: [],
  thread_id: '',
  thread_details: {},
  thread_messages: [],
  editMode: false,
  error: undefined,
  other_user_details: {},
  last_thread_id: undefined

};

export default function messageThread (state = initialState, action) {
  switch (action.type) {


    case messages.SET_EDIT_MODE:
      return {
        ...state,
        editMode: action.data
      };

    case messages.SET_NEW_MESSAGE:
      return update(state, {
        newMessageContent: { $set: action.data }
      });

    case messages.SET_NEW_PLACEHOLDER:
      return update(state, {
        newMessagePlaceholder: { $set: action.data }
      });

    case messages.SET_NEW_LOCAL_THREAD:
      return update(state, {
        newMessageThreadParams: { $set: action.data },
        thread_id: { $set: '' },
        thread_details: { $set: {} },
        thread_messages: { $set: [] },
        other_user_details: { $set: action.data.other_user_details }

      });

    case messages.GET_MESSAGE_THREAD_REQUEST:
      return {
        ...state,
        isFetchingThread: true,
        newMessageContent: ''
      };
    case messages.GET_MESSAGE_THREAD_SUCCESS:
      return {
        ...state,
        isFetchingThread: false,
        thread_details: action.data.thread_details,
        thread_messages: action.data.thread_messages,
        other_user_details: action.data.other_user_details,
        newMessageThreadParams: {},
        last_thread_id: action.data.thread_details.thread_id

      };

    // case messages.SEND_EDITED_DETAILS_REQUEST:
    //   return {
    //     ...state,
    //     isEditingDetails: true
    //   };
    // case messages.SEND_EDITED_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     isEditingDetails: false
    //   };
    // case messages.SEND_EDITED_DETAILS_FAILURE:
    //   return {
    //     ...state,
    //     isEditingDetails: false,
    //     error: action.error
    //   }; // replaced by generic sitActionReq


    case messages.SEND_NEW_MESSAGE_REQUEST:
      return {
        ...state,
        isSendingMessage: true
      };
    case messages.SEND_NEW_MESSAGE_SUCCESS:
      return {
        ...state,
        isSendingMessage: false,
        newMessageContent: ''

      };
    case messages.SEND_NEW_MESSAGE_FAILURE:
      return {
        ...state,
        isSendingMessage: false,
        error: action.error
      };

    case messages.SIT_ACTION_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case messages.SIT_ACTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        newMessageContent: ''

      };
    case messages.SIT_ACTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case messages.CANCEL_ACTION_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case messages.CANCEL_ACTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        newMessageContent: ''

      };
    case messages.CANCEL_ACTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };


    case messages.PRE_APPROVE_SIT_REQUEST:
      return {
        ...state,
        isPreApproving: true
      };
    case messages.PRE_APPROVE_SIT_SUCCESS:
      return {
        ...state,
        isPreApproving: false,
        newMessageContent: ''

      };
    case messages.PRE_APPROVE_SIT_FAILURE:
      return {
        ...state,
        isPreApproving: false,
        error: action.error
      };

    // case messages.APPROVE_SIT_REQUEST:
    //   return {
    //     ...state,
    //     isPreApproving: true
    //   };
    // case messages.APPROVE_SIT_SUCCESS:
    //   return {
    //     ...state,
    //     isPreApproving: false,
    //     newMessageContent: ''
    //
    //   };
    // case messages.APPROVE_SIT_FAILURE:
    //   return {
    //     ...state,
    //     isPreApproving: false,
    //     error: action.error
    //   };
    case messages.CANCEL_APPROVE_SIT_REQUEST:
      return {
        ...state,
        isPreApproving: true
      };
    case messages.CANCEL_APPROVE_SIT_SUCCESS:
      return {
        ...state,
        isPreApproving: false,
        newMessageContent: ''

      };
    case messages.CANCEL_APPROVE_SIT_FAILURE:
      return {
        ...state,
        isPreApproving: false,
        error: action.error
      };
    case messages.FINALISE_SIT_REQUEST:
      return {
        ...state,
        isPreApproving: true
      };
    case messages.FINALISE_SIT_SUCCESS:
      return {
        ...state,
        isPreApproving: false,
        newMessageContent: ''

      };
    case messages.FINALISE_SIT_FAILURE:
      return {
        ...state,
        isPreApproving: false,
        error: action.error
      };

    case messages.CLEAR_THREAD:
      return {
        ...state,
        thread_details: {},
        thread_messages: []

      };

    default:
      return state;
  }
}
