import React from 'react';
import { REFERRALS_SIGNUP_LIMIT, REFERRAL_SIGNUP, REFERRAL_FIRSTSIT } from '../constants/bonuses';
import { verification_doc_points, profile_completed_points, total_verification_points } from '../rules/points';
import * as ROUTES from '../constants/routes';
import { ExternalLink } from '../styles/buttons';


const dbs_info_link = 'https://dbscheckonline.org.uk/individuals';
const dbs_fees_link = 'https://www.gov.uk/government/news/fee-changes-for-dbs-checks';


// bigger font in Q&A


export const faq = {
  page_title: 'FAQ',
  intro: 'Here are the most frequently asked questions and useful information you should know about SitterPoint:',
  end1: 'If you can’t find the answer to your question, please get in touch by using our ',
  end2: '',
  items: [

      // {
      //   title: 'Why does SitterPoint exist?',
      //   text: 'As parents, we are very likely to use some sort of childcare for our children from time to time. We also know that it is sometimes difficult to find a sitter (you can often spend hours asking around friends, or choose a paid babysitter, which can be very expensive).  We created SitterPoint to solve this problem, making sits easy to organise and affordable for everyone.  We also believe kids can have more fun and benefit when they can play with other children, and not just be put in front of a TV.'
      // },
      // {
      //   title: 'Why should I sign up with SitterPoint?',
      //   text: 'SitterPoint is based on the philosophy that every parent should have easy access to good quality, free childcare for their children. This can be achieved through a community of parents who share the same idea. Therefore, if you think this can be possible, you should join our community and sign up for free!'
      // },
      // {
      //   title: 'How does it work?',
      //   text: 'If you are a parent or carer, you can create a family profile for free. The family profile is about you as a parent and how many children you have. Then, get yourself verified by uploading a copy of an ID, a proof of address and optionally a DBS check. Finally, add your availability in your calendar as a “sitter”. Local families can contact other available parents for the dates and times they need. It is up to the HostFamily to accept and confirm the sit. By sitting for other families and therefore organising a playdate, you will earn SittingPoints that you can then use with any local parents to sit for your own children.'
      // },
      // {
      //   title: 'How are SittingPoints calculated?',
      //   text: `It is easy: sitting for one child earns you 100 SP per hour. Each additional child earns you 60 SP per hour. For more details, visit our page: `,
      //   link_text: `What are SittingPoints`,
      //   link_url: ROUTES.SITTER_POINTS
      // },
      // {
      //   title: 'Do I have to use the points I earn with the family I sit for?',
      //   text: 'No, you don’t have to and this is why we created SitterPoint. It is sometimes very difficult to find someone with an inverse schedule to yours, making reciprocal sits difficult. You can use those points with any other local families available when you need a sitter.'
      // },
      // {
      //   title: 'Is SitterPoint safe?',
      //   text: [
      //     'As parents, safety is your number one priority when it comes to our children. This is why we ask parents to complete a full and accurate family profile. All families who want to use this platform need to get verified first. This includes: a copy of an ID or a passport and a proof of address (water/phone/statement bill) for every parent living under the same address. You can also upload a copy of your CRB/DBS check if one or both parents have one. Any other person who is 16 or above and staying at the same address also needs to get verified (with at least a copy of an ID document).',
      //     `You can also check the reviews left previously from users or organise to meet your sitter beforehand.`
      //   ]
      // },
      // {
      //   title: 'What are the criteria to register with SitterPoint?',
      //   text: 'You need to have at least one child, living in the same address as you.  All parents must then get verified for free in order to arrange a sit.'
      // },
      // {
      //   title: 'What do I need to be verified?',
      //   text: [
      //     `We are asking a minimum of 2 documents to verify you. You must upload a copy of:`,
      //     <br />,
      //     `- a valid passport or ID card`,
      //     <br />,
      //     `- a proof of address (bank statement, utility bill, payslip)`,
      //     <br />,
      //     <br />,
      //     `Optional (but recommended):`,
      //     <br />,
      //     `- a DBS check issued less than 5 years ago.`,
      //     <br />,
      //     <br />,
      //     `You don’t need to have a DBS check. However, it may increase your chances of being asked to sit and therefore earn more points.`,
      //     <br />,
      //     <br />,
      //     `If you are interested in obtaining one go to:`,
      //     <br />,
      //     <ExternalLink href={dbs_info_link}>{dbs_info_link}</ExternalLink>
      //
      //   ]
      // },
      // {
      //   title: 'What is a DBS check?',
      //   text: `Previously known as a CRB (Criminal Records Bureau) check, a DBS (Disclosure and Barring Service) check is a record of an individual’s Convictions, Cautions, Reprimands and Warnings and can also include intelligence held by the police and/or the DBS that relates to that individual and their suitability for working with children or vulnerable people.`
      // },
      // // {
      // //   title: 'How much does it cost to run a DBS check?',
      // //   text: [
      // //     `An Enhanced DBS check costs £40.`,
      // //     <br />,
      // //     `Please note that there are different prices for DBS depending on the depth of information. In the UK, if you are working with children or vulnerable people, you will need the Enhanced DBS check.`,
      // //     <br />,
      // //     `For more information, visit: `,
      // //     <ExternalLink href={dbs_fees_link}>{dbs_fees_link}</ExternalLink>
      // //   ]
      // // },
      // {
      //   title: 'How can I start gaining some points?',
      //   text: [
      //     `Once you have filled out 100% of your profile, you will get ${profile_completed_points} SP.`,
      //     <br />,
      //     `You will also gain up to ${total_verification_points} extra SP by getting verified (${verification_doc_points} SP per document uploaded).`,
      //     <br />,
      //     `And finally, we have a referral system. If you refer a parent or friend who completes a full profile, you will get an extra ${REFERRAL_SIGNUP} SP, plus ${REFERRAL_FIRSTSIT} SP after they complete their first sit (up to maximum of ${REFERRALS_SIGNUP_LIMIT} parents or carers.)`,
      //     <br />,
      //     `After completing your profile and availability, any local parents can contact you to sit their children. When you confirm and sit, you earn points.`
      //   ]
      // },
      // {
      //   title: 'Do I need to sit in my own home?',
      //   text: `Not necessarily. You can specify that you will be sitting the children in the local park, playground, library, museum or elsewhere and how you are planning to travel there (e.g. by foot, bus, tube). If the sit is likely to take place somewhere other than your own home, make sure you tell the GuestFamily where you would like to take the children before confirming the sit.`
      // },
      // {
      //   title: 'I know some parents from the same school as my children. If they are available in the afternoon, can I ask them to pick up my children from school and meet them to pickup my children?',
      //   text: `Yes, you can. This needs to be agreed between the parents before confirming the sit. However, make sure that you tell your child and school teacher in advance so that everyone knows what is happening.`
      // },
      // {
      //   title: 'How many children can I sit on top of my own children?',
      //   text: `It is up to you. We recommend that you feel comfortable with the number of extra children you can welcome. It might depend on the age of the children you have or if you already know the children you are hosting. If you look after two different children from two different families, it is recommended to tell the other parent that you are sitting other children as well as theirs.`
      // },
      // {
      //   title: 'How can I make some changes to My Profile?',
      //   text: [
      //     `Click on your profile picture on the navigation bar and go to your Family Profile and edit whatever you need to change.`,
      //     <br />,
      //     `Don’t forget to save those changes by clicking the “Save” button.`
      //   ]
      // }

      // {
      //   title: 'What if things don't go as planned?',
      //   text: `what to do in an emergency? who to contact? Who to contact in an emergency? (cover our ass)`
      // },

      {
        title: `Is SitterPoint safe?`,
        text: [
          `As parents, safety is your number one priority when it comes to our children. This is why we ask parents to complete a full and accurate family profile. All families who want to use this platform need to get verified first.`,
          `You can also check the reviews left previously from users or organise to meet your sitter beforehand, like on other babysitting websites.`
        ]
      },

      {
        title: `Do I need to pay to register with SitterPoint?`,
        text: [`No. Creating a profile, getting verified and viewing families' profiles is free. You will only pay a small monthly fee if you want to contact families and book playdates.`]
      },

      // {
      //   title: `What are the criteria to register with SitterPoint?`,
      //   text: [`You need to have at least one child living at the same address as you.`]
      // },

      {
        title: `Do I need to sit first to use the service?`,
        text: [`No, by completing your profile and getting verified, you will earn points to get you started. You can also refer your friends to earn additional SittingPoints.`]
      },

      {
        title: `I have a child with special needs, can I use this service?`,
        text: [
          `Of course. As parents, we think this service should be inclusive for all children whatever their abilities or needs. When completing your profile, you will be asked if your children have special needs.`,
          `However, it is important to remind and share with the HostFamily some useful information and tips to make the sit safe and enjoyable for everyone (e.g. avoid doing or saying certain things, list of things they love doing).`
        ]
      },

      {
        title: `I have a child with food allergies, can I still use this service?`,
        text: [
          `Of course. As parents, we think this service should be inclusive for all children regardless of health issues. When completing your profile, you will be asked if your children have food allergies or other allergies.`,
          `However, it is important to remind the HostFamily that they can’t eat certain things. If you are worried about food allergies, you can ask for no food to be given in order to make the sit safe and enjoyable for everyone. Ask if a lunchbox can be brought in, and check whether it is safe for the HostFamily, who may have other allergies.`
        ]
      },


      {
        title: 'What do I need to be verified?',
        text: [
          `We are asking for a minimum of 2 documents to verify you. You must upload a copy of:`,
          <>
            <div>- a valid passport or ID card</div>
            <div>- a proof of address (bank statement, utility bill, payslip)</div>
            <br />
            <div>Optional (but recommended):</div>
            <div>- a DBS check issued less than 5 years ago</div>
          </>,

          `You don’t need to have a DBS check. However, it may increase your chances of being asked to sit and therefore earn more points.`,

          <div>If you are interested in obtaining one go to: <ExternalLink href={dbs_info_link}>{dbs_info_link}</ExternalLink></div>


        ]
      },

      {
        title: `How many children can I sit on top of my own children?`,
        text: [`It is up to you. We recommend that you feel comfortable with the number of extra children you can welcome. It might depend on the age of the children you have or if you already know the children you are hosting.`]
      },

      {
        title: `My child is unwell, can I still use the service?`,
        text: [`It depends. If your child is vomiting and/or has a high temperature, we recommend you not use this service for at least 48 hours. If your child has contracted chicken pox, for example, you can ask some parents if their children have already had chicken pox, and if they feel comfortable sitting your children. It is in everyone’s best interest to keep both children and adults healthy.`]
      },

      {
        title: `Can I drop off my children and have my partner pick them up?`,
        text: [`Yes, but you need to let the HostFamily who sits your child know about it beforehand. They can ask for a picture of your partner and can ask them for an ID on collection if they never met.`]
      },

      {
        title: `My friends might need this service occasionally, can they use my account?`,
        text: [`No, you can only use this service under your own profile, for your own children. However, you could refer your friend for them to sign up for free, and there is no minimum or maximum times they can use this service. If you refer a family and they complete their full profile, you will get 50 SP, plus an extra 50 SP after their first sit.`]
      },

      {
        title: `I can’t find any families with children the same age as mine, is it a problem?`,
        text: [
          `Absolutely not. Children tend to learn by playing with older children while older children get a sense of responsibility by teaching younger children.`,
          `There is plenty of research on the subject from psychologists, anthropologists and educators (like Maria Montessori) who wrote that it is important for a child to be in an environment with other age-group children.`
        ]
      },

      {
        title: `Can I amend my booking?`,
        text: [
          `Yes, you can. Make sure you do it via the existing booking and give as much notice as possible and message the GuestFamily about the changes.`,
          `The HostFamily has to re-confirm the changes.`
        ]
      },

      {
        title: `Can I cancel my booking?`,
        text: [
          `Yes, but points up to the first hour of the booking will be retained by the hosting family.  Any remaining points after the first hour will be refunded to you.`,
          `We have this policy to discourage cancellations which can inconvenience hosts.  Please ensure you are committed to the playdate at the time of confirming the booking.`
        ]
      },

      {
        title: `What happens if the hosting family cancels my booking?`,
        text: [`All points will be refunded to you.  We recommend checking a hosting family's profile to review feedback on their reliability before booking with them.`]
      }

  ]
};

export default faq;
