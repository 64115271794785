import * as themes from '../actions/style/themes';
import { default_theme } from '../styles/default-theme';

export const initialState = {

  available_themes: [ default_theme ],
  used_theme_id: 0,
  default_theme,
  editing_theme_id: 0,
  editing_theme: default_theme,
  new_theme_name: '',
  editing_tag: undefined

};

export default function style_props (state = initialState, action) {
  switch (action.type) {


    case themes.GET_ALL_THEMES_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case themes.GET_ALL_THEMES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        available_themes: action.data
      };

    case themes.GET_ALL_THEMES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case themes.ADD_NEW_THEME_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case themes.ADD_NEW_THEME_SUCCESS:
      return {
        ...state,
        isFetching: false
        // available_themes: action.data
      };

    case themes.ADD_NEW_THEME_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case themes.SAVE_THEME_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case themes.SAVE_THEME_SUCCESS:
      return {
        ...state,
        isFetching: false
        // available_themes: JSON.parse(action.data)
      };

    case themes.SAVE_THEME_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case themes.SET_USE_THEME_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case themes.SET_USE_THEME_SUCCESS:
      return {
        ...state,
        isFetching: false
        // available_themes: JSON.parse(action.data)
      };

    case themes.SET_USE_THEME_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case themes.SET_NEW_THEME_NAME:
      return {
        ...state,
        new_theme_name: action.data.new_theme_name
      };

    case themes.SET_EDITING_THEME:
      return {
        ...state,
        editing_theme_id: action.data.editing_theme.theme_id,
        editing_theme: action.data.editing_theme
      };

    case themes.SET_EDITING_TAG:
      return {
        ...state,
        editing_tag: action.data
      };

    case themes.UPDATE_EDIT_THEME: {

      const new_tag = action.data.tag;

      let main_tag = '';
      let extended_tag = false;

      if (action.data.style_section === 'textStyles') {
        main_tag = `a${new_tag.slice(1)}`;
        if (action.data.tag[0] === 'h') {
          extended_tag = true;
        }

      }
      // console.log('extended_tag:', extended_tag);
      // console.log('action.data.screensize_index:', action.data.screensize_index);
      //
      // console.log('state.editing_theme:', state.editing_theme);
      const existingSection = { ...state.editing_theme.theme_details[action.data.style_section] };
      // console.log('existingSection:', existingSection);
      const existingMainTag = { ...existingSection[main_tag] };
      // console.log('existingMainTag:', existingMainTag);


      if (extended_tag) {
        const existingExtendedTags = existingMainTag.extended_tags;
        // console.log('existingExtendedTags:', existingExtendedTags);
        const existingExtendedTagProperties = { ...existingExtendedTags[action.data.tag] };
        // console.log('existingExtendedTagProperties:', existingExtendedTagProperties);
        const existingExtendedProperty = [...existingExtendedTagProperties[action.data.property]];
        // console.log('existingExtendedProperty:', existingExtendedProperty);

        const newArray = [...existingExtendedProperty];
        // console.log('newArray[action.data.screensize_index]:', newArray[action.data.screensize_index]);
        newArray[action.data.screensize_index] = action.data.value;
        // console.log('newArray:', newArray);


        return {
          ...state,
          editing_theme: {
            ...state.editing_theme,
            theme_details: {
              ...state.editing_theme.theme_details,
              [action.data.style_section]: {
                ...existingSection,
                [main_tag]: {
                  ...existingMainTag,
                  extended_tags: {
                    ...existingExtendedTags,
                    [action.data.tag]: {
                      ...existingExtendedTagProperties,
                      [action.data.property]: action.data.property === 'color' ? action.data.value : newArray
                    }
                  }
                }
              }

            }

          }
        };
      }
      // else if not extended, i.e. main tag

      const existingMainProperty = [...existingMainTag[action.data.property]];
      // console.log('existingMainProperty:', existingMainProperty);

      const newArray = [...existingMainProperty];
      // console.log('newArray[action.data.screensize_index]:', newArray[action.data.screensize_index]);
      newArray[action.data.screensize_index] = action.data.value;

      // console.log('newArray:', newArray);


      return {
        ...state,
        editing_theme: {
          ...state.editing_theme,
          theme_details: {
            ...state.editing_theme.theme_details,
            [action.data.style_section]: {
              ...existingSection,
              [action.data.tag]: {
                ...existingMainTag,
                [action.data.property]: action.data.property === 'color' ? action.data.value : newArray
              }
            }
          }
        }

      };
    }


    default:
      return state;
  }
}
