import { thread_chat } from '../text';

export const getDefaultMessage = (action, messagesLength, receiver_name, receiver_id, sitter_id, isInPast, status) => {

  // ready to test by bookings!!!!

  console.log('getDefaultMessage action:', action);

  console.log('sitter_id:', sitter_id);


  const { default_message } = thread_chat;
  console.log('action:', action);
  console.log('receiver_name:', receiver_name);
  if (!action || action === undefined) {
    console.log('messagesLength:', messagesLength);
    if (messagesLength === 0) {
      // first contact
      console.log('first contact');
      const { request } = default_message;
      const { hi, para1, para2 } = request;
      return `${hi} ${receiver_name}. ${para1} ${para2}`;
    }


    // default message, e.g. type new message here
    return default_message.default;

  }

  if (action === 'message') {
    console.log('messagesLength:', messagesLength);
    if (messagesLength === 0 || !messagesLength) {
      // first contact
      console.log('first contact');
      const { request } = default_message;
      const { hi, para1, para2 } = request;
      return `${hi} ${receiver_name}. ${para1} ${para2}`;
    }
    // default message, e.g. type new message here
    return default_message.default;
  }

  if (action === 'edit_new') {
    console.log('messagesLength:', messagesLength);
    if (messagesLength === 0 || !messagesLength) {
      // first contact
      console.log('first contact');
      const { request } = default_message;
      const { hi, para1, para2 } = request;
      return `${hi} ${receiver_name}. ${para1} ${para2}`;
    }
    // default message, e.g. type new message here
    return default_message.default;
  }

  if (action === 'pre_approve') {
    // pre_approve
    console.log('pre-approve');
    const { pre_approve } = default_message;
    const { hi, para1, para2 } = pre_approve;
    return `${hi} ${receiver_name}. ${para1} ${para2}`;
  }

  if (action === 'approve') {
    // approve
    console.log('approve');
    const { approve } = default_message;
    const { hi, para1, para2 } = approve;
    return `${hi} ${receiver_name}. ${para1} ${para2}`;
  }

  if (action === 'pre_authorise') {
    // approve
    console.log('pre_authorise');
    const { pre_authorise } = default_message;
    const { hi, para1, para2 } = pre_authorise;
    return `${hi} ${receiver_name}. ${para1} ${para2}`;
  }

  if (action === 'updated_pre_authorised') {
    // approve
    console.log('updated_pre_authorised');
    const { updated_pre_authorised } = default_message;
    const { hi, para1, para2 } = updated_pre_authorised;
    return `${hi} ${receiver_name}. ${para1} ${para2}`;
  }

  if (action === 'finalise') {
    // approve
    console.log('finalise');
    const { finalise } = default_message;
    const { hi, para1, para2 } = finalise;
    return `${hi} ${receiver_name}. ${para1} ${para2}`;
  }

  if (action === 'cancel') {

    if (isInPast) {
      if (receiver_id === sitter_id) {
        // cancel requester
        console.log('cancel_after_requester');
        const { cancel_after_requester } = default_message;
        const { hi, para1, para2 } = cancel_after_requester;
        return `${hi} ${receiver_name}. ${para1} ${para2}`;
      }
      if (receiver_id !== sitter_id) {
        // cancel sitter
        console.log('cancel_after_sitter');
        const { cancel_after_sitter } = default_message;
        const { hi, para1, para2 } = cancel_after_sitter;
        return `${hi} ${receiver_name}. ${para1} ${para2}`;
      }
    } else {
      // not in past
      if (receiver_id === sitter_id) {
        // cancel requester
        console.log('cancel_requester');
        const { cancel_requester } = default_message;
        const { hi, para1, para2 } = cancel_requester;
        return `${hi} ${receiver_name}. ${para1} ${para2}`;
      }
      if (receiver_id !== sitter_id) {
        if (status === 'request') {
          // decline by sitter
          console.log('decline_sitter');
          const { decline_sitter } = default_message;
          const { hi, para1, para2 } = decline_sitter;
          return `${hi} ${receiver_name}. ${para1} ${para2}`;
        }
        // cancel sitter
        console.log('cancel_sitter');
        const { cancel_sitter } = default_message;
        const { hi, para1, para2 } = cancel_sitter;
        return `${hi} ${receiver_name}. ${para1} ${para2}`;
      }
    }


  }


  if (action === 'edit') {
    // edit
    console.log('edit');
    const { edit } = default_message;
    const { hi, para1, para2 } = edit;
    return `${hi} ${receiver_name}. ${para1} ${para2}`;
  }


  // else
  return 'unhandled defaultMessageText';

};

export default getDefaultMessage;
