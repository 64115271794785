import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";

// opacity: props.opacity || 1
export const StyledP = styled(Button).attrs(props => ({
  opacity: props.opacity || 1
}))`

  background: transparent!important;
  border-style: solid!important;
  outline: none!important;
  opacity: ${props => props.opacity}!important;
  padding: 2px!important;
  cursor: pointer!important;
  border-color: ${colours.red}!important;
  border-width: 0px!important;


  text-decoration: none!important;

  font-weight: ${fonts.bold}!important;
  color: ${props => props.color ? props.color : colours.red}!important;
  text-align: left!important;
  font-size: ${props => props.screensize = 'small' ? '1em' : '4em'}!important;

`; // underline based on hover removed in MoreButtonButton


const mapStateToProps = (layout, ownProps) => {

    return {
      screensize: layout.screensize,
      color: ownProps.color,
      textAlign: ownProps.textAlign,
      ...ownProps
    };

};


class MoreButtonSmall extends Component {

  render () {

    const { screensize, color, textAlign, onClick } = this.props;
    // console.log('MoreButtonTextSmall props:', this.props);
    // A6 size

    return (
      <StyledP
        onClick={onClick}
        screensize={screensize}

        color={color || null}
        textAlign={textAlign || null}

      >
        {this.props.children}
      </StyledP>
    );

  }
}

export default connect(
    mapStateToProps
)(MoreButtonSmall);
