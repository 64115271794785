// import initSocket from '../socket-router';
import React from 'react';

export const SET_FILE_FETCHING = 'SET_FILE_FETCHING';
export const SET_SELECTED_FILES = 'SET_SELECTED_FILES';
export const SET_CURRENT_FILE = 'SET_CURRENT_FILE';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SET_BLOB = 'SET_BLOB';
export const SET_FILEID = 'SET_FILEID';

export function setFileFetching () {

  return {
    type: SET_FILE_FETCHING
  };
}

export function setSelectedFiles (data) {

  return {
    type: SET_SELECTED_FILES,
    data
  };
}

export function setCurrentFile (data) {
  return {
    type: SET_CURRENT_FILE,
    data
  };
}

export function setUploadProgress (data) {
  return {
    type: SET_UPLOAD_PROGRESS,
    data
  };
}

export function setBlob (data) {


  return {
    type: SET_BLOB,
    data
  };
}

export function setFileID (data) {


  return {
    type: SET_FILEID,
    data
  };
}
