import React from 'react';
import { ExternalLink, Link } from '../styles/buttons';
import * as ROUTES from '../constants/routes';

export const tips = {
  page_title: 'Our tips for a successful sit',
  page_sub_title_line1: '',
  page_sub_title_line2: '',

  section1: {
    // header_line1: ``,
    paras: [`When you use SitterPoint, you use it both as a HostFamily ('sitter') and as a GuestFamily ('requester'). We want to make sure all children and adults (whether sitting, or using other parents as sitters) have a positive and enjoyable experience from sits organised through the platform. To help you get started, we have put together these tips for a great sit, an easy-to-follow guide to help you ensure a successful, fun and safe sit for all. We hope you enjoy using SitterPoint.`
    ]

  },

  section2: {
    header_line1: `Tips for when you are the GuestFamily`,
    // header_line2: `of the SitterPoint community`,
    paras: [

      `Before booking a parent sitter, it is important to tell
  your child:`,
      `- What your plans are
  - When this is going to happen
  - Roughly how long it will take
  - And that you will find a playdate for that time.`,
  //     `Here's an example of what you might say:
  // “I am planning to go to the gym on Tuesday afternoon. There is a yoga class I would like to attend and it lasts an hour. Then I might go for a short swim. So, I am planning to book a playdate for you to have some fun while I’m out.” Depending on the age of your child, you might adapt how you will communicate this information, but whatever the age of your child, even for a toddler, it is important to tell them what will come next and how things are going to work.`,
      `By doing this, you will create trust and excitement for your children at the idea they will have a play date, rather than fear that you will not be staying with them, or not knowing where they are going to go.`

    ]

  },

  section3: {
    header_line1: `Booking the right sitter for you and your children.`,
    // header_line2: `of the SitterPoint community`,
    paras: [
      `If you are new to an area or don’t know the parents you see as available, remember that all of our parents have agreed to and adopt our philosophy, and all families have been checked and verified in order to appear in search results.
However, feel free to spend a bit of time to look at:`,
      `- Their family profile,
- Their reviews,
- Verifications`,
      `The family profile is there to help you understand if a family is right for your child. Also, you can see how many children the family has and their ages.`,
      `The reviews will help you too, as these show you how other parents found the experience.  Look for things such as:`,
      {
        sub_title: `- Communication:`,
        para: `How good was the communication with the other parent before, during and after the sit?`
      },
      {
        sub_title: `- Fun:`,
        para: `Did the children have fun during the sit?`
      },
      {
        sub_title: `- Trust:`,
        para: `Did the parent follow specific instructions if this was required by parents (e.g. about allergies, changing nappies, etc.)`
      },

      `The verification checks will help you to see who has been verified among the adults and other members of the family (age 16+), as well as who has a valid DBS check, if you choose parents who have one. You can ask the HostFamily to view their original document before dropping off your child.`,
      `When writing to a parent sitter, make it friendly, with a short description of your family and why you need time for a sitter. You may also say a few words about your child and why you think they may be a good fit with their children. You can contact more than one family to maximise your chances of finding a sitter (so long as you only confirm the sit with one of them). If you are still not sure if a family is right for you, bear in mind that all the family profiles you will be seeing are local to you, so why don’t you meet the other parents and children in the park to see if they are a good match and if there is chemistry with your children too!`

]
},

section4: {
  header_line1: `After you have booked a sitter:`,

paras: [

      `Great! All booked, you are going to be able to have some “me time”!`,
      `Now you just need to let your children know you have sorted out a playdate and who they are going to meet that day.`

]
},

section5: {
  header_line1: `Before you drop them off:`,

  paras: [
  `If your child is young or shy, you can tell them to bring something familiar or comforting (e.g. a book, toy, etc.).`,
  `Please remind them of any house rules (if any mentioned on the HostFamily's profile) that they are expected to follow.`
  // `For example: “You could bring your book to show Lucy and John, they might want to read it with you”. `,
  // `Or: “You can bring Bobby Rabbit with you, he will love to meet new friends”.`

  ]


  },

  section6: {
    header_line1: `When picking up your child.`,

    paras: [

      `Be on time to pickup your child, unless you specified to the HostFamily that it may take longer than you think before booking the sitter (e.g. hospital appointment, hairdresser, etc) and they agreed to be flexible. You may be late due to an unforeseen circumstances (such as tube delays, or road closures). If so, please let the HostFamily know as soon as possible what the estimated time of arrival will be if you can. In some cases, your plans may change (like a cancelled appointment). Please let the HostFamily know if you are planning to pick up your child earlier than expected for two reasons:`,
      `- You don’t want the children to have just started a painting activity or a board game 5 minutes before you turn up.`,
      `- You want your child to know that your plans have changed, to minimise the frustration of a shorter stay (because chances are they will be having lots of fun and they’ll want to stay and play for longer).`,
      ``,
      `Don't forget to thank the HostFamily!`

    ]

  },

  // section7: {
  //   header_line1: `Stop the “points clock”`,
  //
  //   paras: [
  //
  //     `The “points clock” will stop as soon as you are at the front door to pick up your child. Therefore, you will agree on the final time (especially if you are late or very early) as you will both be asked to confirm the final times after the sit (so that any necessary additional points can be reflected).`
  //
  //   ]
  //
  // },

  section7: {
    header_line1: `Tips for when you are the HostFamily:`,

    paras: [

      <div>First of all, no parents will contact you to sit if you do not <Link to={ROUTES.AVAILABILITY}>update your calendar</Link> and specify when you are available to sit.</div>,

      // `If you update your calendar and have a minimum of 8 slots per month of availability, you will earn extra points even if nobody`,
      // `contact you for those time slots. (Learn more about Calendar update to earn extra points.)`,

      `Then, before accepting a GuestFamily request, it is important to:`,
      `- Double check if you can make it from and until the time requested. If you have any other plans after, make sure you tell the GuestFamily to pick up their children on time.`,
      `- View the GuestFamily profile. This will help you to know more about the parents (description and reviews) and the children (names, ages, allergies).`,
      `- Ask the GuestFamily to pack anything that the child may need (change of clothes, sleeping bag, blouse for painting, snacks and medication (especially if the children have allergies).`,
      `- Ask the GuestFamily if they are happy with the house rules, if you have any.`,
      `As for the user parents, if you are not sure, you can also ask to meet the other parent and children in the park to see if they are a good match and there is chemistry with your children too.`
    ]

  },


  section8: {
    header_line1: `After confirming a sit request with the GuestFamily`,

    paras: [

      `This is an exciting time as your children are going to have some friends over.`,
      `Now let your children know that some new friends are coming over for a playdate on that day.`,
      `You could ask your children what they are planning to do.`,
      `If the children who are coming have allergies or special needs, talk about it to your children for them to understand not to go to the kitchen or eat something without them asking you first, and discuss what they can do to make the guest children welcome, happy and safe.`

    ]

  },

  section9: {
    header_line1: `Make the children feel welcome and comfortable`,

    paras: [


      `Introduce yourself and the other children and any other members of your family (including pets!) present at the time.`,
      `Before the children go and play, you can tell/remind them of your house rules and see if they understand:`,
      `e.g. “Before going to John and Lucy’s bedroom and play with them, please remove your shoes and wash your hands.`,
      `Or: “I know you like trains and so Lucy drew a train for you. John also would like to show you his favourite train book.”`
    ]

  },

  section10: {
    header_line1: `Make it fun`,

    paras: [


      `If you're hosting the playdate and your children as well as the guest children are shy, why don’t you lay out small activities? A drawing session, puzzles, board games or Lego - all great chances to join in with them and break the ice! They will soon make up their own games.`
    ]

  },

  section11: {
    header_line1: `During the sit`,

    paras: [


      `Let the GuestFamily know that their children are having fun.`,
      `Feel free to send a text to the parents at some point to let them know how their children are doing. You may as well take a couple of pictures of the children playing during the sit to send to the parent user to let them know that everything is going well (please check if parents are comfortable with this). Do not share on social media or elsewhere.`
    ]

  },

  section12: {
    header_line1: `Stop the “points clock”`,

    paras: [


      `The “points clock” will stop as soon as the parent user is at your front door. Therefore, you will agree on the final time as you will both be asked to confirm the final times after the sit (so that any necessary additional points can be reflected).`,
      `We believe a good communication between both families using the service is the key to a safe and enjoyable sit.`
    ]

  }

};


export default tips;
