import * as index from '../actions/register/index';
import update from 'immutability-helper';
import * as aboutChildren from '../actions/register/aboutChildren';
import * as aboutYourself from '../actions/register/aboutYourself';

export const initialState = {
  progressStep: 1,
  noOfChildren: 0,
  child_details: [],
  selected_child: undefined,
  noOfAdults: 0,
  adult_details: [],
  selected_adult: undefined,
  selectedPhotoFile: undefined,
  isChildToAdd: false,
  isAdultToAdd: false

};

export default function register (state = initialState, action) {
  switch (action.type) {

    case index.SET_SELECTED_PHOTO_FILE:

      return {
        ...state,
        selectedPhotoFile: action.data
      };

    case register.SET_PROGRESS_STEP:

      return {
        ...state,
        progressStep: action.data
      };

    case register.INCREMENT_PROGRESS_STEP:

      return {
        ...state,
        progressStep: state.progressStep + 1
      };


    case index.INCREMENT_PROGRESS_STEP:

      return {
        ...state,
        progressStep: state.progressStep + 1
      };

    case index.DECREMENT_PROGRESS_STEP:
      return {
        ...state,
        progressStep: state.progressStep - 1
      };

    case aboutChildren.SET_NO_OF_CHILDREN:
      return {
        ...state,
        noOfChildren: action.data
      };

    case aboutChildren.CREATE_CHILD:

      const newChild = { child_id: action.data };

      return {
        ...state,
        child_details: [
          ...state.child_details,
          newChild
        ]
      };

    case aboutChildren.SELECT_CHILD:

      return {
        ...state,
        selected_child: action.data
      };

    case aboutChildren.SET_CHILD_TO_ADD:
      return {
        ...state,
        isChildToAdd: action.data
      };

    case aboutYourself.SET_NO_OF_ADULTS:
      return {
        ...state,
        noOfAdults: action.data
      };

    case aboutYourself.CREATE_ADULT:

      const newAdult = { adult_id: action.data };

      return {
        ...state,
        adult_details: [
          ...state.adult_details,
          newAdult
        ]
      };

    case aboutYourself.SELECT_ADULT:

      return {
        ...state,
        selected_adult: action.data
      };

    case aboutYourself.SET_ADULT_TO_ADD:
      return {
        ...state,
        isAdultToAdd: action.data
      };

    default:
      return state;
  }
}
