import React from 'react';
// import * as ROLES from '../../constants/roles';
import {
    Image
} from "semantic-ui-react";
import history from '../Navigation/history';
import { Column, Row } from '../../styles/styles';
import colours from '../../styles/colours';
import { H5 } from '../../styles/text';
import LogoSitterpoint from '../../images/logo_sitterpoint.min.svg';
import LogoSitterpointLight from '../../images/logo_sitterpoint_white.min.svg';
//  default styles - edit in CSS to preserve hover:
import '../../styles/navigation.css';
import * as ROUTES from '../../constants/routes';

const LogoMain = ({ center, light }) => {

    return (

      <div
        onClick={() => history.push({
            pathname: ROUTES.DASHBOARD,
            state: { editMode: true }
          })
        }
        style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '100%', marginRight: 'auto', background: 'transparent', cursor: 'pointer', borderWidth: 0, borderStyle: 'solid', borderColor: 'blue' }}>

        <div style={{ display: 'flex', flexDirection: 'row', height: 60, alignItems: 'center', position: 'relative' }}>
          <Image width='auto' height={100} src={light ? LogoSitterpointLight : LogoSitterpoint} alt='Sitterpoint' />
          {
            // <div style={{ position: 'absolute', right: 0, bottom: 0, paddingRight: 8, paddingBottom: 4 }}><H5 color={light ? colours.black : colours.white}>Beta</H5></div>
          }
        </div>


      </div>


    );
};

export default LogoMain;
