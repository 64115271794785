import React, { Component } from 'react';
import { ReportGA } from '../../utils/ga';
import history from './history';
import * as ROUTES from '../../constants/routes';
import { Transition, ScrollAwayDiv, Row } from '../../styles/styles';
import colours from '../../styles/colours';
import OpenSearchButtonContainer from '../../containers/search/open-search';
import NavMenu from './nav-menu';
import DropdownNavContainer from '../../containers/Navigation/dropdown-nav-container';
// import LogoBar from '../general/logo-bar';
import LogoMain from '../general/logo-main';
import NavBarLoggedOutContainer from '../../containers/Navigation/navbar-logged-out';
//  default styles - edit in CSS to preserve hover:
import '../../styles/navigation.css';
// import { infoBarHeight } from '../../styles/layout';
// import { versionNo } from '../../constants/env';
import { NAV, SEARCH } from '../../constants/ga-category';

class NavBar extends Component {


  // constructor () {
  //   super();
  //
  //   // this.showSignInModal = this.showSignInModal.bind(this);
  //   // this.showSignUpModal = this.showSignUpModal.bind(this);
  //   // this.goToLanding = this.goToLanding.bind(this);
  //
  // }

  componentDidMount () {
    const { user_id } = this.props;

    if (user_id) {

      this.props.handleGetAllTransactions(user_id);
    }

  }


  goToLanding = () => {

    ReportGA({
      category: NAV,
      action: 'User pressed Go To Landing button on navbar/navmenu'
      // current location?
    });

    history.push({
        pathname: ROUTES.LANDING,
        state: { editMode: true }
    });

  };


  showSearchModal () {
    const { showSearchModal } = this.props;

    ReportGA({
      category: SEARCH,
      action: 'User pressed Search button on navbar/navmenu'
      // current location?
    });

    showSearchModal();

  }


  render () {
    const { currentRoute, show_navbar, window_width, user_id, handleLogOut, isLoggedIn, firstname, sp, user_is_admin, photo_url, profile_progress, profile_complete_count, verified_count, email_verified, handleBurgerClick, burgerIsOpen, saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, saved_kids, tariff_details, all_transactions, sp_balance, sp_pending } = this.props;
    // console.log('navbar this.props:', this.props);
    // console.log('navbar location:', location);
    // const currentRoute = location.pathname; // location is not updating - need to use WithRouter HOC
      // console.log('transactions:', all_transactions);
    return (
      isLoggedIn
      ? <NavigationAuth
        user_id={user_id}
        all_transactions={all_transactions}
        sp_balance={sp_balance}
        sp_pending={sp_pending}
        currentRoute={currentRoute}
        show_navbar={show_navbar}
        sp={sp}
        user_is_admin={user_is_admin}
        photo_url={photo_url}
        profile_progress={profile_progress}
        profile_complete_count={profile_complete_count}
        verified_count={verified_count}
        email_verified={email_verified}
        handleLogOut={handleLogOut}
        isLoggedIn={isLoggedIn}
        firstname={firstname}
        window_width={window_width}
        onBurgerClick={handleBurgerClick}
        burgerIsOpen={burgerIsOpen}
        saved_start_date={saved_start_date}
        saved_end_date={saved_end_date}
        saved_start_hr={saved_start_hr}
        saved_end_hr={saved_end_hr}
        saved_start_min={saved_start_min}
        saved_end_min={saved_end_min}
        saved_kids={saved_kids}
        tariff_details={tariff_details}
        />
      : <NavigationNonAuth currentRoute={currentRoute} handleLogOut={handleLogOut} screenWidth={window_width} showSignInModal={this.showSignInModal} showSignUpModal={this.showSignUpModal} goToLanding={this.goToLanding} />
    );
  }

}


const SearchTabs = () => {

  return (
    <Row style={{ display: 'flex', flex: 1, alignItems: 'flex-end' }}>

      <OpenSearchButtonContainer />

    </Row>

  );

};

const breakpoint = 730;

const NavigationAuth = ({ currentRoute, user_id, all_transactions, sp_balance, sp_pending, show_navbar, window_width, firstname, sp, user_is_admin, photo_url, profile_progress, profile_complete_count, verified_count, email_verified, handleLogOut, onBurgerClick, burgerIsOpen, saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, saved_kids, tariff_details }) => (
  <Transition style={{ width: '100%' }}>
    <ScrollAwayDiv
      className={window_width > 730 ? 'active' : show_navbar ? 'active' : 'hidden'}
      style={{ flexDirection: 'row', alignItems: 'center', flex: 1, display: 'flex' }}
    >

      <div style={{ flexDirection: 'column', flex: 1, display: 'flex' }}>
        {
          // <div style={{ height: infoBarHeight }}>
          //   <div style={{ display: 'flex', height: '100%', flexDirection: 'row', paddingRight: 8, justifyContent: 'flex-end', alignItems: 'center', flex: 1, borderWidth: 0, borderStyle: 'solid', borderColor: colours.red }}>
          //     <A6>{`v${versionNo}`}&nbsp;</A6>
          //     <A6>Logged in as</A6>
          //     <A6 color={colours.red}>&nbsp;{`${user_id}`}</A6>
          //     <A6 color={colours.red}>&nbsp;{firstname}&nbsp;</A6>
          //   </div>
          // </div>
        }

        <div style={{ background: window_width >= breakpoint ? colours.white : colours.logopurple, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderWidth: 0, borderTopWidth: 0, borderBottomWidth: 1, borderStyle: 'solid', borderColor: colours.lightgray }}>

          <LogoMain />

          <Row style={{ flex: 1, paddingLeft: 0, paddingRight: 8, borderWidth: 0, borderStyle: 'solid', borderColor: 'green', alignItems: 'center' }}>
            {
              console.log('rendering currentRoute:', currentRoute)
            }
            {
              (
                currentRoute !== ROUTES.THREAD
                && currentRoute !== ROUTES.INVITE
                && currentRoute !== ROUTES.WELCOME
                && currentRoute !== ROUTES.WHERE_YOU_LIVE
                && currentRoute !== ROUTES.ABOUT_CHILDREN
                && currentRoute !== ROUTES.EDIT_CHILD
                && currentRoute !== ROUTES.TELL_US_ABOUT_YOURSELF
                && currentRoute !== ROUTES.EDIT_ADULT
                && currentRoute !== ROUTES.EDIT_DETAILS
                && currentRoute !== ROUTES.PROFILE_PHOTO
                && currentRoute !== ROUTES.BIO
                && currentRoute !== ROUTES.CHOOSE_ADULT
                && currentRoute !== ROUTES.VERIFY_MAIN_ADULT
                && currentRoute !== ROUTES.VERIFY_OTHER_ADULTS
                && currentRoute !== ROUTES.VERIFY_DBS_MAIN_ADULT
                && currentRoute !== ROUTES.VERIFY_POA
                && currentRoute !== ROUTES.VERIFY_SELFIE
                && currentRoute !== ROUTES.ADMIN
              )
              && <SearchTabs
                window_width={window_width}
                burgerIsOpen={burgerIsOpen}
                onBurgerClick={() => onBurgerClick()}
                saved_start_date={saved_start_date}
                saved_end_date={saved_end_date}
                saved_start_hr={saved_start_hr}
                saved_end_hr={saved_end_hr}
                saved_start_min={saved_start_min}
                saved_end_min={saved_end_min}
                saved_kids={saved_kids}
                tariff_details={tariff_details}
                profile_progress={profile_progress}

              />
            }

          </Row>

          {
            (window_width < breakpoint)
            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 60, paddingRight: 0, width: 50, borderWidth: 0, borderStyle: 'solid', borderColor: 'green', cursor: 'pointer' }}>
              <DropdownNavContainer
                orientation='burger'
                sp={sp}
                user_is_admin={user_is_admin}
                photo_url={photo_url}
                profile_progress={profile_progress}
                profile_complete_count={profile_complete_count}
                verified_count={verified_count}
                email_verified={email_verified}
                handleLogOut={handleLogOut}
              />

            </div>

            : <NavMenu
              width={window_width}
              orientation='row'
              sp={sp}
              user_id={user_id}
              photo_url={photo_url}
              transactions={all_transactions}
              sp_balance={sp_balance}
              sp_pending={sp_pending}
              profile_progress={profile_progress}
              profile_complete_count={profile_complete_count}
              verified_count={verified_count}
              email_verified={email_verified}
              user_is_admin={user_is_admin}
              burgerIsOpen={burgerIsOpen}
              onBurgerClick={() => onBurgerClick()}
              handleLogOut={handleLogOut}
            />
          }


        </div>

      </div>


    </ScrollAwayDiv>
  </Transition>

);

const NavigationNonAuth = ({ currentRoute, screenWidth, goToLanding }) => (

  <div style={{ flexDirection: 'row', alignItems: 'center', flex: 1, display: 'flex' }}>
    <div style={{ flexDirection: 'column', flex: 1, display: 'flex' }}>
      {
        // <button
        //   type='button'
        //   onClick={() => goToLanding()}
        //   onKeyDown={() => goToLanding()}
        //   style={{ padding: 0, cursor: 'pointer', display: 'flex', background: colours.borderColour, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderWidth: 0, borderStyle: 'solid', borderColor: colours.lightgray
        //   }}
        // >
        // {
        //     <LogoBar strapline window_width={screenWidth} />
        // }
        //
        //
        // </button>
      }

      <NavBarLoggedOutContainer currentRoute={currentRoute} />


    </div>
  </div>


);

export default NavBar;
