import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Progress } from 'semantic-ui-react';
import Spinner from '../../components/general/spinner';

class UploadProgressIndicator extends Component {

render () {
  const { isFetchingUpload, spinner, progress, successText, fetchingText } = this.props;

    // if (!isFetchingUpload) {
    //   return null;
    // }
    // if (isFetchingUpload) {
      return (

        <div style={{ paddingBottom: 10, paddingTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderColor: 'blue', borderWidth: 0, borderStyle: 'solid' }}>

          <div style={{ display: 'flex', width: '100%', flexDirection: 'row', paddingBottom: 10, justifyContent: 'center', borderColor: 'green', borderWidth: 0, borderStyle: 'solid' }}>

            <Progress style={{ width: 200 }} percent={progress} indicating active success={progress === 100}>
              {progress === 100 ? successText : fetchingText}
            </Progress>

          </div>

          {
            spinner && progress !== 100 &&
            <Spinner />
          }


        </div>

      )


  }

}


const mapStateToProps = ({ upload }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  isFetchingUpload: upload.isFetchingUpload,
  progress: upload.progress,
  successText: ownProps.successText,
  fetchingText: ownProps.fetchingText

});

const mapDispatchToProps = (dispatch, props) => ({



});

export default connect(mapStateToProps, mapDispatchToProps)(UploadProgressIndicator);
