import * as confirmEmail from '../actions/auth/confirm-email';


export const initialState = {

  // email: undefined,
  success_message: undefined,
  error: undefined,
  isFetching: false
};

// set incoming active_referral_code

export default function forgotPassword (state = initialState, action) {
  switch (action.type) {


    case confirmEmail.CONFIRM_EMAIL_RESET:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        successMessage: undefined
      };

    case confirmEmail.CONFIRM_EMAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
        successMessage: undefined
      };

    case confirmEmail.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success_message: action.data.message

      };
    case confirmEmail.CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };


    default:
      return state;
  }
}
