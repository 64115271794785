import { connect } from 'react-redux';
import NavBar from '../../components/Navigation/navbar';
import { persistor, store } from '../../init-store';
import { logout } from '../../actions/auth/signup';
import { showModal, hideModal } from '../../actions/modal';
import { openBurger, closeBurger } from '../../actions/navbar';
import { getAllTransactionsById } from '../../actions/transactions';
import * as ROUTES from '../../constants/routes';
import history from '../../components/Navigation/history';
import { MODAL_TYPE_SIGNIN, MODAL_TYPE_SIGNUP } from '../../components/modals/modalTypes';

const mapStateToProps = ({ user, nav, search, tariff, layout, transactions }, ownProps) => {
  // console.log('this.state:', this.state);
  // console.log('localStorage: ', localStorage);
  return {
    isLoggedIn: user.isLoggedIn,
    isLoggingIn: user.isLoggingIn,
    user_id: user.user_id,
    firstname: user.firstname,
    sp: user.sp,
    user_is_admin: user.is_admin,
    photo_url: user.photo_url,
    profile_progress: user.profile_progress,
    profile_complete_count: user.profile_complete_count,
    verified_count: user.verified_count,
    email_verified: user.email_verified,
    burgerIsOpen: nav.burgerIsOpen,
    saved_start_date: search.saved_start_date,
    saved_end_date: search.saved_end_date,
    saved_start_hr: search.saved_start_hr,
    saved_end_hr: search.saved_end_hr,
    saved_start_min: search.saved_start_min,
    saved_end_min: search.saved_end_min,
    saved_kids: search.saved_selected_kids,
    tariff_details: tariff.tariff_details,
    window_width: layout.window_width,
    show_navbar: layout.show_navbar,
    currentRoute: ownProps.currentRoute,
    all_transactions: transactions.all_transactions,
    sp_balance: user.sp_balance,
    sp_pending: user.sp_pending
  };

};

const mapDispatchToProps = dispatch => ({

  handleGetAllTransactions: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getAllTransactionsById(token, user_id));

    }

  },
  handleLogOut: () => {
    // console.log('localStorage: ', localStorage);
    localStorage.removeItem('sitterpoint_token');
    localStorage.removeItem('sitterpoint_user_id');

    history.push(ROUTES.LANDING);

    // const rootNavigator = nav.getNavigator('root');
    // rootNavigator.replace('auth');
    // clean up persisted state
    persistor.purge();
    // store.getState().network.socket.disconnect();
    dispatch(logout());
    // redirect

  },
  // setSelectedValue: (value, inputKey, dataType) => {
  //   console.log('value: ', value);
  //   console.log('inputKey: ', inputKey);
  //   console.log('dataType: ', dataType);
  //
  //   dispatch(setValue(value, inputKey, dataType));
  //
  // },
  handleBurgerClick: (burgerIsOpen) => {
    if (burgerIsOpen) {
      dispatch(closeBurger());
    } else {
      console.log('navbar-container openBurger');
      dispatch(openBurger());
    }
  },
  handleCloseBurger: () => {
    dispatch(closeBurger());
  },
  showSignInModal: () => {
    dispatch(showModal(MODAL_TYPE_SIGNIN, {

    }));
  },
  showSignUpModal: () => {
    dispatch(showModal(MODAL_TYPE_SIGNUP, {

    }));
  },
  hideModal: () => {
    dispatch(hideModal());
  }
});

const NavBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBar);

export default NavBarContainer;
