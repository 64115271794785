import history from '../../components/Navigation/history';
import { store } from '../../init-store';
import { showModal, hideModal } from '../modal';
import { actionSuccess } from '../verify';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS, MODAL_TYPE_CONFIRM } from '../../components/modals/modalTypes';
import * as ROUTES from '../../constants/routes';
import { adults as adultsText } from '../../text/register';
import { CheckError, ReportError } from '../../utils/check-error';
import { SUCCESS_UPDATE_ADULTS, CONFIRM_DELETE, SUCCESS_DELETE_ADULTS } from '../../components/modals/modalNames';
import { PROFILE } from '../../components/modals/modalCategory';
import { selectAdult, setNoOfAdults, setAdultToAdd } from './aboutYourself';
import { SUBMIT_REGISTRATION_REQUEST, SUBMIT_REGISTRATION_SUCCESS, SUBMIT_REGISTRATION_FAILURE } from './registration';

export const submitUpdateAdultsRequest = () => ({
  type: SUBMIT_REGISTRATION_REQUEST
});

export const submitUpdateAdultsSuccess = data => ({
  type: SUBMIT_REGISTRATION_SUCCESS,
  data
});

export const submitUpdateAdultsFailure = error => ({
  type: SUBMIT_REGISTRATION_FAILURE,
  error
});

export const onOKClick = (editMode) => {

  return (dispatch) => {
    dispatch(hideModal());

    if (editMode) {
      // go back to AboutAdults
      history.push({
        pathname: ROUTES.TELL_US_ABOUT_YOURSELF,
        state: { editMode: true }
      });
    } else {
      // add another adult

      // addAdult first
      // dispatch(createAdult());
      const noOfAdults = store.getState().register.noOfAdults;
      dispatch(setNoOfAdults(noOfAdults + 1));

      // then selectAdult
      dispatch(selectAdult(noOfAdults));

      dispatch(setAdultToAdd(true)); // ensures newAdult added to Array when EditAdult form re-initialises


      history.push({
        pathname: ROUTES.EDIT_ADULT,
        state: { editMode: false }
      });
    }

  };


};

export const pushNextRoute = () => {

  return (dispatch) => {
    dispatch(hideModal());

    // is not editMode, by default or there'd be no button
      history.push({
        pathname: ROUTES.PROFILE_PHOTO,
        state: { editMode: false }
      });
    // }


  };
};

export const okDeleteAdult = (token, user_id, fields, adult_index, noOfAdults, editMode) => {

  // const adults = store.getState().user.adults;


  return (dispatch) => {

    dispatch(showModal(MODAL_TYPE_SPINNER, {

    }));

    console.log('adult_index', adult_index);
    fields.remove(adult_index);

    dispatch(setNoOfAdults(noOfAdults - 1));
    // change number of adults to -1

    // get new Adults from form values
    console.log('store.getState().form:', store.getState().form);
    const adults = store.getState().form.adults.values.adults;
    console.log('adults:', adults);


    dispatch(submitUpdateAdultsRequest());
    // console.log('submitted Action');

    fetch(`${process.env.REACT_APP_API_URI}/users/registration/${user_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ user: { adults } })
    })
    .then(CheckError)
    .then((data) => {

      console.log('registration response data:', data);
      dispatch(submitUpdateAdultsSuccess(data));

      dispatch(hideModal());

      const modalText = adultsText.modal.delete_adults.success;

      dispatch(showModal(
        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: PROFILE,
          modalName: SUCCESS_DELETE_ADULTS,
          modalText,
          okAction: () => dispatch(hideModal()),
          backAction: null

        }
        // need to add confirm modal to ask if they want to add another adult

      ));
      dispatch(actionSuccess()); // triggers success icon

      // console.log('nextRouteToPush:', nextRouteToPush);

      history.push({
        pathname: ROUTES.TELL_US_ABOUT_YOURSELF,
        state: { editMode }
      });

      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.

    })
    .catch((err) => {
      dispatch(submitUpdateAdultsFailure(err.message));
      dispatch(hideModal());

      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: adultsText.modal.update_adults.failure.title,
          header: adultsText.modal.update_adults.failure.header,
          message: adultsText.modal.update_adults.failure.para,
          message2: adultsText.modal.update_adults.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };

};


export function submitDeleteAdult (token, user_id, fields, adult_index, noOfAdults, editMode) {

  return (dispatch) => {


    // we want an are you sure prompt, so need to move this to actions
    const okAction = () => {
      console.log('confirmDeleteAdult okAction:');
      dispatch(okDeleteAdult(token, user_id, fields, adult_index, noOfAdults, editMode));
    };

    // dispatch(showModal(MODAL_TYPE_CONFIRM_PREAPPROVE, {
    //   okAction: () => okAction(),
    //   backAction: null,
    //   cancelAction: () => dispatch(hideModal()),
    //   thread_stage: 'pre_approve'
    // }));


    dispatch(showModal(MODAL_TYPE_CONFIRM, {
      modalCategory: PROFILE,
      modalName: CONFIRM_DELETE,
      modalText: adultsText.modal.delete_adults.confirm,
      okAction: () => okAction(),
      backAction: null,
      cancelAction: () => dispatch(hideModal())
    }));

  };
}


export function submitUpdateAdults (token, user_id, adults, nextRoute, editMode) {

  console.log('submit reg');
  console.log('nextRoute:', nextRoute);
  const nextRouteToPush = !nextRoute ? ROUTES.TELL_US_ABOUT_YOURSELF : nextRoute;
  console.log('nextRouteToPush:', nextRouteToPush);

  if (!adults) {
    history.push({
      pathname: nextRouteToPush,
      state: { editMode }
    });
  }


  return (dispatch) => {
    dispatch(showModal(MODAL_TYPE_SPINNER, {

    }));

    dispatch(submitUpdateAdultsRequest());
    // console.log('submitted Action');

    // console.log('testUserSave', JSON.stringify({ user: { kids, adults, home_address } }));
    fetch(`${process.env.REACT_APP_API_URI}/users/registration/${user_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ user: { adults } })
    })
    .then(CheckError)
    .then((data) => {

      console.log('registration response data:', data);
      dispatch(submitUpdateAdultsSuccess(data));

      dispatch(hideModal());

      const modalText = editMode ? adultsText.modal.update_adults.success : adultsText.modal.update_adults.success_add_another;

      dispatch(showModal(
        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: PROFILE,
          modalName: SUCCESS_UPDATE_ADULTS,
          modalText,
          okAction: () => dispatch(onOKClick(editMode)), // add another adult and navigate to editAdult
          cancelAction: editMode ? null : () => dispatch(pushNextRoute()), // hideModal and return to nextRoute
          backAction: null

        }
        // need to add confirm modal to ask if they want to add another adult

      ));
      dispatch(actionSuccess()); // triggers success icon

      console.log('nextRouteToPush:', nextRouteToPush);

      // history.push({
      //   pathname: nextRouteToPush,
      //   state: { editMode }
      // });

      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.

    })
    .catch((err) => {
      dispatch(submitUpdateAdultsFailure(err.message));
      dispatch(hideModal());

      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: adultsText.modal.update_adults.failure.title,
          header: adultsText.modal.update_adults.failure.header,
          message: adultsText.modal.update_adults.failure.para,
          message2: adultsText.modal.update_adults.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });
  };
}
