import React, { Component } from 'react';
import { ConfirmButton } from '../../styles/styles';


export default class SearchButton extends Component {

  handleClick () {

    const { saveSearchQuery, spQuoted, sendEditedDetails, updatePreAuthorisedDetails, search_parameters, toggleDropdown, home_address, thread_id, editMode, finaliseMode, thread_details, edited_thread_details, user_id } = this.props;
    // console.log('SearchButton search_parameters', search_parameters);
    console.log('SearchButton handleClick this.props', this.props);
    const { saved_start_date, saved_end_date, saved_start_hr, saved_start_min, saved_end_hr, saved_end_min, saved_selected_kids } = search_parameters;
    const useEndDate = !saved_end_date ? saved_start_date : saved_end_date;
    const searchParameters = (!saved_start_date || !useEndDate || !saved_start_hr || !saved_start_min || !saved_end_hr || !saved_end_min || !saved_selected_kids) ? null : { saved_start_date, saved_end_date, saved_start_hr, saved_start_min, saved_end_hr, saved_end_min, saved_selected_kids };

    console.log('SearchButton handleClick searchParameters:', searchParameters);

    const { saved_start_date: edit_start_date, saved_end_date: edit_end_date, saved_start_hr: edit_start_hr, saved_start_min: edit_start_min, saved_end_hr: edit_end_hr, saved_end_min: edit_end_min, saved_selected_kids: edit_kids } = edited_thread_details;
    const useEditEndDate = !edit_end_date ? edit_start_date : edit_end_date;
    const start_time = `${edit_start_hr}:${edit_start_min}`;
    const end_time = `${edit_end_hr}:${edit_end_min}`;
    const editParameters = (!edit_start_date || !useEditEndDate || !edit_start_hr || !edit_start_min || !edit_end_hr || !edit_end_min || !edit_kids) ? null : { start_date: edit_start_date, end_date: useEditEndDate, start_time, end_time, kids: edit_kids, sp_quoted: spQuoted };
    const threadParameters = (!thread_details.start_date || !thread_details.end_date || !thread_details.start_time || !thread_details.end_time || !thread_details.kids) ? null : { start_date: thread_details.start_date, end_date: thread_details.end_date, start_time: thread_details.start_time, end_time: thread_details.end_time, kids: thread_details.kids, sp_quoted: thread_details.sp_quoted };

    const areEditParamsSame = JSON.stringify(threadParameters) === JSON.stringify(editParameters);

    console.log('SearchButton handleClick thread params:', threadParameters);
    console.log('SearchButton handleClick edit params:', editParameters);

    if (!editMode && !searchParameters) {
      console.log('search params not complete');
    }

    if (editMode && areEditParamsSame) {
      console.log('edit params not changed');
    }
    if (editMode && !areEditParamsSame && !finaliseMode) {
      console.log('edit params edited - not finalise mode');
      const messageParams = null;

      sendEditedDetails(messageParams, thread_id, editParameters, user_id);

    } else if (editMode && !areEditParamsSame && finaliseMode) {
      console.log('edit params edited - finalise mode');
      updatePreAuthorisedDetails(thread_id, editParameters, user_id);

    } else {
      console.log('saving');
      saveSearchQuery(user_id, home_address, searchParameters);
    }


  }

  render () {
    const isSearching = false;
    const { search_parameters, validDates, userHasEnoughPoints, editMode, thread_details, edited_thread_details, spQuoted, finaliseMode } = this.props;

    console.log('SearchButton render search_parameters', search_parameters);
    console.log('SearchButton render this.props', this.props);

    const { saved_start_date, saved_end_date, saved_start_hr, saved_start_min, saved_end_hr, saved_end_min, saved_selected_kids } = search_parameters;
    const useEndDate = !saved_end_date ? saved_start_date : saved_end_date;
    const searchParameters = (!saved_start_date || !useEndDate || !saved_start_hr || !saved_start_min || !saved_end_hr || !saved_end_min || !saved_selected_kids || (saved_selected_kids.length === 0)) ? null : { saved_start_date, saved_end_date, saved_start_hr, saved_start_min, saved_end_hr, saved_end_min, saved_selected_kids };

    const { saved_start_date: edit_start_date, saved_end_date: edit_end_date, saved_start_hr: edit_start_hr, saved_start_min: edit_start_min, saved_end_hr: edit_end_hr, saved_end_min: edit_end_min, saved_selected_kids: edit_kids } = edited_thread_details;
    const useEditEndDate = !edit_end_date ? edit_start_date : edit_end_date;
    const start_time = `${edit_start_hr}:${edit_start_min}`;
    const end_time = `${edit_end_hr}:${edit_end_min}`;
    const editParameters = (!edit_start_date || !useEditEndDate || !edit_start_hr || !edit_start_min || !edit_end_hr || !edit_end_min || !edit_kids || (edit_kids.length === 0)) ? null : { start_date: edit_start_date, end_date: useEditEndDate, start_time, end_time, kids: edit_kids, sp_quoted: spQuoted };
    const threadParameters = (!thread_details.start_date || !thread_details.end_date || !thread_details.start_time || !thread_details.end_time || !thread_details.kids || (thread_details.kids.length === 0)) ? null : { start_date: thread_details.start_date, end_date: thread_details.end_date, start_time: thread_details.start_time, end_time: thread_details.end_time, kids: thread_details.kids, sp_quoted: thread_details.sp_quoted };

    const areEditParamsSame = JSON.stringify(threadParameters) === JSON.stringify(editParameters);

    console.log('thread params:', threadParameters);

    console.log('edit params:', editParameters);
    if (!editMode && !searchParameters) {
      console.log('search params not complete');
    }
    if (editMode && areEditParamsSame) {
      console.log('edit params not changed');
    }


    console.log('userHasEnoughPoints:', userHasEnoughPoints);

    // const validDates = (editMode && editParameters ? validateDates(editParameters) : searchParameters && validateDates(searchParameters));
    // console.log('searchParameters:', searchParameters);
    const isButtonDisabled = (!editMode && (!searchParameters || isSearching)) || (editMode && areEditParamsSame) || (editMode && !editParameters) || (!validDates) || (!userHasEnoughPoints);
    const buttonText = finaliseMode ? 'Finalise' : (editMode) ? 'Update' : 'Search';

    // const helpText = !validDates ? search.invalid_dates : helpMessage;


    return (
      <ConfirmButton
        testID='SEARCH Confirm'
        disabled={isButtonDisabled}
        onClick={() => this.handleClick()}
      >
        {buttonText}
      </ConfirmButton>

    );
  }

}
