import React from 'react';
import { H1, H3, H5 } from '../../styles/text';
import colours from '../../styles/colours';

const ModalHeader = ({ title, line2, subtitle, highlightedSubtitle, alignTitle, alignSubtitle, paddingTop, paddingBottom, children }) => {

    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: paddingTop || 16, paddingBottom: paddingBottom || 8 }}>
        <H1
          textAlign={alignTitle || 'center'}
          color={colours.mediumlightpurple}
          style={{ width: '100%', margin: 0, paddingTop: 4, paddingBottom: 4, paddingLeft: 0, paddingRight: 0 }}
        >
          {title}{children}
          {
            line2 && <br />

          }
          {
            line2 && line2
          }
        </H1>
        {
          (subtitle || highlightedSubtitle)
          && <H5
            color={colours.gray}
            textAlign={alignSubtitle || 'center'}
            style={{ margin: 0, paddingTop: 4, paddingBottom: 4, paddingLeft: 0, paddingRight: 0 }}
          >{subtitle}<div style={{ color: colours.darkpurple }}>&nbsp;{highlightedSubtitle}</div>
          </H5>
        }
      </div>
    );
};

export default ModalHeader;
