import { connect } from 'react-redux';
import ProfileSP from '../../components/dashboard/profile-sp';
import { showSearchBanner, hideSearchBanner, clearSearchQuery } from '../../actions/search';
// import { MODAL_TYPE_SEARCH } from '../../components/modals/modalTypes';


const mapStateToProps = ({ search }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  searchBannerIsOpen: search.banner_is_open,
  isSearchSubmitted: search.submittedSearch !== null,
  handleClickGotIt: ownProps.handleClickGotIt,
  window_width: ownProps.window_width,
  profileProgress: ownProps.profileProgress

});

const mapDispatchToProps = dispatch => ({

  showSearchBanner: () => {
    dispatch(showSearchBanner());
  },

  hideSearchBanner: () => {
    dispatch(hideSearchBanner());
  },
  clearSearchQuery: () => {
    dispatch(clearSearchQuery());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSP);
