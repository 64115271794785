import { connect } from 'react-redux';
import DropdownKidsValue from '../../components/layout/dropdowns/kidsValue';
import { toggleDropdown, setKidsValueOpen } from '../../actions/search';
import { getUserById } from '../../actions/auth/profile';

const mapStateToProps = ({ user, search }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  user_id: user.user_id,
  // open: dropdownHourValue.isKidsOpen,
  searchBannerIsOpen: search.banner_is_open,
  kids_value_is_open: search.kids_value_is_open,
  selected_kids: search.saved_selected_kids,
  all_kids: user.kids,
  value: ownProps.value


});

const mapDispatchToProps = dispatch => ({


  handleGetUser: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getUserById(token, user_id));

    }

  },
  setKidsValueOpen: (value) => {
    console.log('setKidsValueOpen:', value);
    dispatch(setKidsValueOpen(value));
  },
  // toggleDropdown: (open) => {
  //   console.log('toggleDropdown');
  //   dispatch(toggleDropdown(open));
  //
  //
  // },
  handleSelect: () => {

    // const token = localStorage.sitterpoint_token;
    //
    // if (token) {
    //   dispatch(getUserById(token, user_id));
    //
    // }

  }

});

export default connect(mapStateToProps, mapDispatchToProps)(DropdownKidsValue);
