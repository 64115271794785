import React from 'react';
import { ReportGA } from '../../utils/ga';
import { ConfirmButton, MoreButton } from '../../styles/styles';
import Card from '../general/card';
import CardHeader from '../general/card-header';
import Paragraph from '../general/paragraph';
// import HighlightedText from '../general/highlighted-text';
import history from '../Navigation/history';
import { dashboard } from '../../text';
import * as ROUTES from '../../constants/routes';
import verifiedInPro from '../../images/verified_in_pro.min.svg';
import { DASHBOARD } from '../../constants/ga-category';


const DocsPending = ({ window_width }) => {
    // console.log('f item user:', user);
    // const { handleMore, narrowScreenOrientation, window_width, profileProgress } = props;

    const handleClickUploadMore = () => {

        history.push({
          pathname: ROUTES.CHOOSE_ADULT
        });

        ReportGA({
          category: DASHBOARD,
          action: `User pressed Upload More button on DocsPending card on Dashboard page`
        });

      };

    const handleClickCal = () => {

        history.push({
          pathname: ROUTES.AVAILABILITY
        });

        ReportGA({
          category: DASHBOARD,
          action: `User pressed Go to Calendar button on DocsPending card on Dashboard page`
        });

      };


    return (


      <Card>
        <CardHeader
          alignTitle='left'
          title={dashboard.docs_pending_title}
          image={verifiedInPro}
        />
        {
          // <CardHeader
          //   paddingBottom='0px'
          //   alignTitle='left'
          //   title={<div>{dashboard.verified_sp_title1}{' '}{dashboard.verified_sp_title2}</div>}
          //   image={verified150sp}
          //   paragraph={(window_width >= 490)
          //     && <Paragraph>
          //       <br />
          //       {dashboard.verified_sp_para1}
          //       <br />
          //       {dashboard.verified_sp_para2}
          //     </Paragraph>
          //   }
          //
          // />
        }
        <Paragraph>
          {dashboard.docs_pending_para1.a}
        </Paragraph>
        <Paragraph>

          {dashboard.docs_pending_para1.b}


          {
            // <HighlightedText>{dashboard.docs_pending_para1.h1}&nbsp;</HighlightedText>

          }

        </Paragraph>
        {
          // <Paragraph>
          //   {dashboard.docs_pending_para1.c}
          // </Paragraph>
        }


        {
          // <Paragraph>
          //   {dashboard.docs_pending_para1.d}
          // </Paragraph>
        }

        {
          // <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 8, paddingBottom: 8 }}>
          //
          //   <MoreButton onClick={() => handleClickUploadMore()}>
          //     {dashboard.docs_pending_upload_more_button}
          //   </MoreButton>
          //
          // </div>
        }


        <div style={{ flex: 1, width: '100%', display: 'flex', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', justifyContent: 'flex-start', alignItems: 'flex-end', paddingTop: 4, paddingBottom: 4 }}>

        </div>

        <div style={{ flex: 1, width: '100%', display: 'flex', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', justifyContent: window_width > 400 ? 'flex-end' : 'space-between', alignItems: 'flex-end', paddingTop: 4, paddingBottom: 4 }}>
          <ConfirmButton
            onClick={() => handleClickUploadMore()}
          >
            {dashboard.docs_pending_upload_more_button}
          </ConfirmButton>
          <div style={{ width: 8 }} />
          <ConfirmButton
            onClick={ () => handleClickCal() }
          >
            {dashboard.docs_pending_cal_button}
          </ConfirmButton>
        </div>


      </Card>

    );
};

export default DocsPending;
