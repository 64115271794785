/* eslint-disable react/function-component-definition */
import React from 'react';
import {
  Button, Icon
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';


export const StyledButton = styled(Button)`

  padding-left: 4px!important;
  padding-right: 4px!important;
  margin: 0px!important;
  color: ${(props) => props.selected ? props.light ? colours.darkpurple : colours.white : props.color ? props.color : props.light ? colours.darkpurple : colours.white}!important;
  background: transparent!important;
  border-radius: 4px!important;
  border-width: 0px!important;
  border-style: solid!important;
  border-color: ${(props) => props.light ? colours.darkpurple : colours.white}!important;

  padding-bottom: ${(props) => props.selected ? '4px' : '11px'}!important;
  border-bottom-width: ${(props) => props.selected ? '2px' : '0px'}!important;
  margin-bottom: ${(props) => props.selected ? '5px' : '0px'}!important;

  cursor: pointer!important;

  &:hover {
    background: ${colours.verylightgray}!important;
    color: ${colours.mediumlightpurple}!important;
  }

`;

const NavButton = (props) => {

  return (
    <StyledButton
      primary={props.primary}
      icon={props.icon}
      labelPosition={props.icon ? props.labelPosition || 'left' : null}
      fluid={props.fluid}
      compact={props.compact}
      disabled={props.disabled}
      light={props.light}
      onClick={props.onClick}
      selected={props.selected}
    >
      {
        props.icon
        && <Icon name={props.icon} />
      }
      {props.children}

    </StyledButton>
  );
};

export default NavButton;
