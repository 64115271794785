// require('dotenv').config();
const appVersion = require('../../package.json').version;

console.log('appVersion:', appVersion);

export const versionNo = appVersion;

export const getAPIURI = () => {

  if (process.env.REACT_APP_APP_NODE_ENV === 'production') {
    console.log('REACT_APP_API_URI:', process.env.REACT_APP_API_URI);
    return process.env.REACT_APP_API_URI;

  }

  if (process.env.REACT_APP_APP_NODE_ENV === 'development') {
    console.log('REACT_APP_API_URI:', process.env.REACT_APP_API_URI);
    return process.env.REACT_APP_API_URI;

  }

  console.log('REACT_APP_API_URI:', process.env.REACT_APP_API_URI);
  return process.env.REACT_APP_API_URI;

};

export const getAPPURI = () => {

  if (process.env.REACT_APP_APP_NODE_ENV === 'production') {

    return process.env.REACT_APP_URL;

  }

  if (process.env.REACT_APP_APP_NODE_ENV === 'development') {

    return process.env.REACT_APP_URL;

  }
  return process.env.REACT_APP_URL;

};

export default getAPIURI;
