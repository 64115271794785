import { connect } from 'react-redux';
import App from '../App';
import { setWindowHeight, setWindowWidth } from '../actions/layout';
import { clearSearchQuery } from '../actions/search';

import { setEditMode } from '../actions/messages';
import { getPlans } from '../actions/payments';
// helper isAvailable 'fully' 'partially' args(available_dates, search_dates)

const mapStateToProps = ({ user, layout }) => {

  return {
    // serverError: user.error,
    // isConnected: network.isConnected,
    user_id: user.user_id,
    firstname: user.firstname,
    surname: user.surname,
    email: user.email,
    window_height: layout.window_height

  };

};

const mapDispatchToProps = dispatch => ({

  setWindowWidth: (width) => {
      dispatch(setWindowWidth(width));
  },
  setWindowHeight: (height) => {
      dispatch(setWindowHeight(height));
  },
  clearSearchQuery: () => {
    dispatch(clearSearchQuery());
  },
  setEditMode: (editMode) => {
      // resets EditMode for search and message thread to false to clear any previous persisted state
      dispatch(setEditMode(editMode));

  },
  handleGetPlans: () => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getPlans(token));
    }

  }


});

export default connect(mapStateToProps, mapDispatchToProps)(App);
