import history from '../../components/Navigation/history';
import { showModal, hideModal } from '../modal';
import { getTimestamp } from '../../lib/timeHelpers';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR } from '../../components/modals/modalTypes';
import { search } from '../../text';
import { actionSuccess } from '../../actions/verify';
import { CheckError, ReportError } from '../../utils/check-error';
import { getFlexibleUsers, SUBMIT_FLEXIBLE_USERS_REQUEST, SUBMIT_FLEXIBLE_USERS_SUCCESS, SUBMIT_FLEXIBLE_USERS_FAILURE } from './get-flexible-users';
import * as ROUTES from '../../constants/routes';
// import initSocket from '../socket-router';

export { getFlexibleUsers, SUBMIT_FLEXIBLE_USERS_REQUEST, SUBMIT_FLEXIBLE_USERS_SUCCESS, SUBMIT_FLEXIBLE_USERS_FAILURE };
export const SUBMIT_SEARCH_REQUEST = 'SUBMIT_SEARCH_REQUEST';
export const SUBMIT_SEARCH_SUCCESS = 'SUBMIT_SEARCH_SUCCESS';
export const SUBMIT_SEARCH_FAILURE = 'SUBMIT_SEARCH_FAILURE';
export const CLEAR_SEARCH_QUERY = 'CLEAR_SEARCH_QUERY';
export const CLEAR_EDIT_SEARCH_QUERY = 'CLEAR_EDIT_SEARCH_QUERY';
export const SHOW_SEARCH_BANNER = 'SHOW_SEARCH_BANNER';
export const SCROLLED_TO_BANNER = 'SCROLLED_TO_BANNER';
export const HIDE_SEARCH_BANNER = 'HIDE_SEARCH_BANNER';
export const TOGGLE_FILTER_RESULTS = 'TOGGLE_FILTER_RESULTS';
export const CLEAR_FILTER_RESULTS = 'CLEAR_FILTER_RESULTS';
export const SET_SORT_RESULTS = 'SET_SORT_RESULTS';
export const TOGGLE_COLLAPSE = 'TOGGLE_COLLAPSE';
export const SET_CONTACTED_USERS = 'SET_CONTACTED_USERS';
export const SET_DATE_VALUE_OPEN = 'SET_DATE_VALUE_OPEN';
export const SET_KIDS_VALUE_OPEN = 'SET_KIDS_VALUE_OPEN';
export const SET_HR_VALUE_OPEN = 'SET_HR_VALUE_OPEN';
export const SET_MIN_VALUE_OPEN = 'SET_MIN_VALUE_OPEN';

// setSortResults(value)

export function toggleFilterResults (checked, filterItem) {
  return {
    type: TOGGLE_FILTER_RESULTS,
    data: {
      checked,
      filterItem
    }
  };
}

export function clearFilterResults () {
  return {
    type: CLEAR_FILTER_RESULTS
  };
}

export function toggleCollapse () {
  return {
    type: TOGGLE_COLLAPSE
  };
}


export function setSortResults (value) {
  return {
    type: SET_SORT_RESULTS,
    data: value
  };
}

export function setDateValueOpen (value) {
  return {
    type: SET_DATE_VALUE_OPEN,
    data: value
  };
}

export function setKidsValueOpen (value) {
  return {
    type: SET_KIDS_VALUE_OPEN,
    data: value
  };
}

export function setHrValueOpen (value) {
  return {
    type: SET_HR_VALUE_OPEN,
    data: value
  };
}

export function setMinValueOpen (value) {
  return {
    type: SET_MIN_VALUE_OPEN,
    data: value
  };
}


export function showSearchBanner () {

  console.log('redux showSearchBanner');
  return {
    type: SHOW_SEARCH_BANNER
  };
}

export function scrolledToSearchBanner () {

  console.log('redux scrolledToSearchBanner');
  return {
    type: SCROLLED_TO_BANNER
  };
}

export function hideSearchBanner () {
  return {
    type: HIDE_SEARCH_BANNER
  };
}

export const submitSearchRequest = data => ({
    type: SUBMIT_SEARCH_REQUEST,
    data
});

export const submitSearchSuccess = data => ({
  type: SUBMIT_SEARCH_SUCCESS,
  data
});

export const submitSearchFailure = error => ({
  type: SUBMIT_SEARCH_FAILURE,
  error
});

export function clearSearchQuery () {
  return {
    type: CLEAR_SEARCH_QUERY
  };
}

export function setContactedUsers (data) {
  return {
    type: SET_CONTACTED_USERS,
    data
  };
}


export function clearEditSearchQuery () {
  return {
    type: CLEAR_EDIT_SEARCH_QUERY
  };
}


export function submitSearch (token, user_id, home_address, search_parameters) {

  console.log('submitSearch (home_address):', home_address);
  console.log('submitSearch (search_parameters):', search_parameters);

  // logic to for search searchParameters
  const { saved_start_date, saved_start_hr, saved_start_min, saved_end_date, saved_end_hr, saved_end_min } = search_parameters;

  // make start and end timestamps
  const start_timestamp = getTimestamp(saved_start_date, saved_start_hr, saved_start_min);
  const end_date = !saved_end_date ? saved_start_date : saved_end_date; // in case of same day
  const end_timestamp = getTimestamp(end_date, saved_end_hr, saved_end_min);

  const submittedSearch = { search_parameters };


  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: search.modal.fetching
      }
    ));

    dispatch(submitSearchRequest(submittedSearch));

    fetch(`${process.env.REACT_APP_API_URI}/users/nearby_users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      },
      body: JSON.stringify({ user_id, home_address, start_timestamp, end_timestamp })
    })
    .then(CheckError)
    .then((data) => {
      console.log('submittedSearch response data:', data);
      // success
        // length = 0
          // push results and display message

        // length > 0
          // push results and show list

      dispatch(submitSearchSuccess(data));

      history.push({
          pathname: ROUTES.RESULTS,
          state: { editMode: false } });
      dispatch(hideModal());

      // setTimeout(() => {
      //   dispatch(submitSearchSuccess(data)); // do we need if submittedSearch does same?
      // }, 3000);


    })
    .catch((err) => {
      console.log('caught submitSearch error: ', err);
      dispatch(submitSearchFailure(err.message));

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: search.modal.failure.title,
          header: search.modal.failure.header,
          message: search.modal.failure.para,
          // message2: loginText.modal.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());


    });


  };
}
