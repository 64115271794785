import React from 'react';

export const thread_chat = {
  inbox_title: 'Inbox',
  all: 'All messages',
  no_messages: 'You have no messages, sit requests and replies to sit requests will appear here',
  messages: 'Messages',
  messages_to: 'Messages to',
  back_to_inbox: `back to 'My Inbox'`,
  back_to_request_profile: `Go back`,
  back_to_results: `Back to results`,
  you: 'You',
  drop: 'Drop off',
  pick: 'Pick up',
  sit_details: 'Sitting Details',
  family_profile: `Family's profile`,
  type_a_message: `Type a message below and click send to request the sit`,
  cancel_pre_approve: 'Cancel Pre-approval',

  status_text: {
    sitter: 'sitter',
    you: 'you',
    You: 'You',
    waiting_for: 'Waiting for',
    to_finalise: 'to finalise times',
    sit_expired: `Sit Expired - this sit wasn't confirmed`,
    error: 'An error has occurred',
    new_request: 'are making a new request to',
    needs_pre_approval: 'This sit needs to be pre-approved by',


  },

  default_message: {
    default: `Type new message here`,

    request: {
      hi: `Hi`,
      para1: `I was wondering if you could sit for me`,
      para2: `on this day and time? (see Sit Details) I would be very grateful. If you have any questions, please let me know. Thanks in advance.`
    },

    pre_approve: {
      hi: `Hi`,
      para1: `Yes, I'm fine to sit for you at this time.`,
      para2: `If you have any questions before the sit, please let me know.`
    },

    approve: {
      hi: `Hi`,
      para1: `That's great news.  I can confirm the sit now.  You should receive an email confirmation with all the details. Could you please send me your exact address or/and contact details?`,
      para2: `I look forward to it.  Thanks again.`
    },

    pre_authorise: {
      hi: `Hi`,
      para1: `It was great to sit for you.  I have entered final details.  Please review they are correct.`,
      para2: `Best Regards.`
    },

    updated_pre_authorised: {
      hi: `Hi`,
      para1: `I have amended the final details.  Please review they are correct.`,
      para2: `Best Regards.`
    },

    finalise: {
      hi: `Hi`,
      para1: `I can confirm these details are correct.  Thank you for sitting for me.`,
      para2: `Best Regards.`
    },

    cancel_requester: {
      hi: `Hi`,
      para1: `Sorry, I'm cancelling this sit as I no longer need a sitter at this time.`,
      para2: `Thank you for understanding.`
    },

    cancel_sitter: {
      hi: `Hi`,
      para1: `Sorry, I'm cancelling this sit as I'm no longer able to sit for you.`,
      para2: `Thank you for understanding.`
    },

    decline_sitter: {
      hi: `Hi`,
      para1: `I am sorry, I am unable to help you this time.`,
      para2: `I wish you luck in your search and hopefully will be able to help you out another day.`
    },

    cancel_after_requester: {
      hi: `Hi`,
      // para1: `Sorry, I'm cancelling this sit as I no longer need a sitter at this time.`,
      // para2: `Thank you for understanding.`
    },

    cancel_after_sitter: {
      hi: `Hi`,
      // para1: `Sorry, I'm cancelling this sit as I'm no longer able to sit for you.`,
      // para2: `Thank you for understanding.`
    },


    edit: {
      hi: `Hi`,
      para1: `I have edited some details regarding the sit.  Please can you check the new details and confirm if you are okay with these.`,
      para2: `Best Regards.`
    }

  }

};


export default thread_chat;
