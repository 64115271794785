import React, { Component } from 'react';

// import { Input } from 'semantic-ui-react';
import colours from '../../styles/colours';
import { ms14, FormLabelText } from '../../styles/text';
import { Input } from '../../styles/styles';
import { iconScale } from '../../styles/scaling';


// old Styled Component Version

// input field is set to width flex 1, do to choose specific width, wrap in div
class InputField extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isFocused: false
    };
  }

  handleFocus = () => {
    this.setState({ isFocused: true });
  }

  handleBlur = () => {
    this.setState({ isFocused: false });
  }

  render () {

    const {
      multiline, numberOfLines, labelType, value, placeholder, error,
      onChangeText, testDescription, label, inputKey, optional,
      autoCapitalize, secureTextEntry, type, maxWidth, name, readOnly
    } = this.props;
    // const focusedColor = !focussedColor ? colours.main : focussedColor;
    // const unfocusedColor = !unfocussedColor ? colours.lightgray : unfocussedColor;
    // console.log('labelType', labelType);
    // console.log('input this.props', this.props);

    const labelText = label;

    // console.log('Input value:', value);

    return (
      <div
        style={{
          background: 'transparent',
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 0,
          borderColor: 'blue',
          borderWidth: 0,
          borderStyle: 'solid'


      }}>
        <div style={{
          background: 'transparent',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          borderColor: 'green',
          borderWidth: 0,
          borderStyle: 'solid'
        }}>
          { (label !== undefined)
            && <div style={{ flexDirection: 'row' }}>
              {
                <FormLabelText>
                  {labelText}
                </FormLabelText>
              }
              { optional
                && <FormLabelText style={{ color: colours.gray }}>
                  {' - Optional'}
                </FormLabelText>
              }
            </div>
          }
          <div style={{ background: colours.white, display: 'flex', width: '100%', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', flexDirection: 'row', alignItems: 'flex-end' }}>
            <div style={{ display: 'flex', maxWidth, flexDirection: 'row', background: 'transparent', paddingLeft: 0, paddingRight: 0, flex: 1, borderColor: colours.borderColour, borderWidth: 1, borderStyle: 'solid' }}>
              <Input
                key={inputKey}
                name={name}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                accessibilityLabel={testDescription}
                underlineColorAndroid='transparent'
                placeholder={ placeholder }
                onChange={onChangeText}
                value={ value }
                error={error}
                onInput={onChangeText}
                autoCapitalize={autoCapitalize}
                type={type}
                multiline={multiline}
                numberOfLines={numberOfLines} // check if does anything on android, else delete
                secureTextEntry={secureTextEntry}
                readOnly={readOnly}
              />
            </div>

          </div>
        </div>

      </div>


    );
  }
}

export default InputField;
