import { showModal, hideModal } from '../modal';
import { actionSuccess } from '../verify';
import { verify_email } from '../../text';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS } from '../../components/modals/modalTypes';
import { SUCCESS_RESEND_VERIFY_EMAIL } from '../../components/modals/modalNames';
import { VERIFY } from '../../components/modals/modalCategory';

export const RESEND_VERIFY_EMAIL_REQUEST = 'RESEND_VERIFY_EMAIL_REQUEST';
export const RESEND_VERIFY_EMAIL_SUCCESS = 'RESEND_VERIFY_EMAIL_SUCCESS';
export const RESEND_VERIFY_EMAIL_FAILURE = 'RESEND_VERIFY_EMAIL_FAILURE';
export const RESEND_VERIFY_EMAIL_RESET = 'RESEND_VERIFY_EMAIL_RESET';

export const verifyEmailRequest = () => ({
  type: RESEND_VERIFY_EMAIL_REQUEST
});

export const verifyEmailReset = () => ({
  type: RESEND_VERIFY_EMAIL_RESET
});

export const verifyEmailSuccess = data => ({
  type: RESEND_VERIFY_EMAIL_SUCCESS,
  data
});

export const verifyEmailFailure = error => ({
  type: RESEND_VERIFY_EMAIL_FAILURE,
  error
});

export function resendVerifyEmail (token, user_id) {

  console.log('resend email:', user_id);
  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: verify_email.modal.resend_email.fetching
      }
    ));

    dispatch(verifyEmailRequest());
    fetch(`${process.env.REACT_APP_API_URI}/send-verify-email`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },

      body: JSON.stringify({ user_id })
    })
    .then((response) => {
      response.json()
        .then((data) => {
          if (data.error) {

            // throw error
            throw new CustomException(null, data.error);

          } else {
            console.log('data', data);
            dispatch(verifyEmailSuccess(data));

            dispatch(hideModal());


            const modalText = verify_email.modal.resend_email.success;

            dispatch(showModal(

              MODAL_TYPE_SUCCESS,
              { // modalProps
                // other_user_name?
                modalCategory: VERIFY,
                modalName: SUCCESS_RESEND_VERIFY_EMAIL,
                modalText: modalText,
                okAction: () => dispatch(hideModal()),
                backAction: null,

              }
            ));

            dispatch(actionSuccess()); // triggers success icon
          }
        })
        .catch((err) => {
          dispatch(verifyEmailFailure(err.message));
          // dispatch(hideModal());

          // centralise bugsnag reporting
          ReportError(!err.code ? null : err.code, err.message, {});
          dispatch(showModal(
            MODAL_TYPE_ERROR,
            { // modalProps
              title: verify_email.modal.resend_email.failure.title,
              header: verify_email.modal.resend_email.failure.header,
              message: verify_email.modal.resend_email.failure.para,
              message2: verify_email.modal.resend_email.failure.para2,
              error_status: err.code && err.code,
              error_message: err.message
            }
          ));

          dispatch(actionSuccess());

        });
    })
    .catch((err) => {
      dispatch(verifyEmailFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: verify_email.modal.resend_email.failure.title,
          header: verify_email.modal.resend_email.failure.header,
          message: verify_email.modal.resend_email.failure.para,
          message2: verify_email.modal.resend_email.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}
