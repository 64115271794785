import React from 'react';
import { SetGA, ReportGA } from '../../utils/ga';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    Button,
    Icon
} from "semantic-ui-react";
import colours from '../../styles/colours';
import { A5 } from '../../styles/text';
import { calendar, sit_details } from '../../text';
import { confirmEditAllAvailability, confirmDeleteAllAvailability } from '../../actions/calendar';
import { showModal, hideModal } from '../../actions/modal';
import { MODAL_TYPE_SPINNER } from '../modals/modalTypes';
import * as BOOKING_TYPES from '../../constants/booking-types';
import { CONFIRMED, UNCONFIRMED } from '../../constants/booking-status';
import { getMessageThread } from '../../actions/messages';
import { store } from '../../init-store';
import * as ROUTES from '../../constants/routes';
import { CALENDAR } from '../../constants/ga-category';

const { start, end } = calendar.edit_event;

const defaultCellBorderColor = colours.mediumlightgray;
const clashingCellBorderColor = colours.red;
const clashingTextColor = colours.red;


export const BookingItem = ({ window_width, viewed_date, thread_id, user_id, booked_dates, booking_id, recurrance_id, recurring_count, start_timestamp, end_timestamp, booking_type, booking_status, handleEditAvailability, handleDeleteAvailability, recurrances, clashing }) => {

  const start_time = moment(start_timestamp, "YYYY-MM-DD HH:mm:ss").format('HH:mm');
  const end_time = moment(end_timestamp, "YYYY-MM-DD HH:mm:ss").format('HH:mm');

  // console.log('start_timestamp:', start_timestamp);
  // console.log('end_timestamp:', end_timestamp);
  // console.log('start_time:', start_time);
  // console.log('end_time:', end_time);


  // const handleReviewThread = () => {
  //   console.log('handleReviewThread clicked, thread_id:', thread_id);
  // };

  const handleReviewThread = () => {


    store.dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: sit_details.modal.fetching_thread.fetching
      }
    ));

    ReportGA({
      category: CALENDAR,
      action: `User clicked on Thread from BookingItem in Edit Day modal`,
    });


    // console.log('this.props:', this.props);
    // console.log('handleSelect Messages Item dropdown_nav_is_open:', dropdown_nav_is_open);

    console.log('message_thread_id:', thread_id);

    const token = localStorage.sitterpoint_token;

    if (token) {
      // load message thread into redux state

      store.dispatch(getMessageThread(token, thread_id, ROUTES.THREAD));

      // push thread route


    }

    store.dispatch(hideModal()); // once thread loaded, hide modal for spinner and calendar

  };

  const cellBorderColor = clashing ? clashingCellBorderColor : defaultCellBorderColor;

  const reqBookedColor = colours.reqBooked;
  const sitBookedColor = colours.sitBooked;
  const availableColor = colours.available;

  const typeMarkerColor = (
    booking_type === BOOKING_TYPES.SITTING && booking_status === CONFIRMED ? sitBookedColor
    : booking_type === BOOKING_TYPES.SITTING && booking_status === UNCONFIRMED ? sitBookedColor
    : booking_type === BOOKING_TYPES.USING && booking_status === CONFIRMED ? reqBookedColor
    : booking_type === BOOKING_TYPES.USING && booking_status === UNCONFIRMED ? reqBookedColor
    : availableColor);

  const typeMarkerOpacity = (
    booking_type === BOOKING_TYPES.SITTING && booking_status === CONFIRMED ? 1
    : booking_type === BOOKING_TYPES.SITTING && booking_status === UNCONFIRMED ? 0.5
    : booking_type === BOOKING_TYPES.USING && booking_status === CONFIRMED ? 1
    : booking_type === BOOKING_TYPES.USING && booking_status === UNCONFIRMED ? 0.5
    : 1);

  const typeText = (
    booking_type === BOOKING_TYPES.SITTING && booking_status === CONFIRMED ? calendar.edit_event.sit_booked
    : booking_type === BOOKING_TYPES.SITTING && booking_status === UNCONFIRMED ? calendar.edit_event.sit_pending
    : booking_type === BOOKING_TYPES.USING && booking_status === CONFIRMED ? calendar.edit_event.req_booked
    : booking_type === BOOKING_TYPES.USING && booking_status === UNCONFIRMED ? calendar.edit_event.req_pending
    : calendar.edit_event.available);

  return (
    <div style={{ width: '100%', overflow: 'hidden', display: 'flex', marginTop: 4, marginBottom: 4, flexDirection: 'row', borderColor: cellBorderColor, borderWidth: 1, borderStyle: 'solid', borderRadius: 8 }}>
      <div style={{ background: typeMarkerColor, width: 8, opacity: typeMarkerOpacity }} />
      <div style={{ display: 'flex', flexDirection: 'column', padding: 4, flex: 1 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <A5 style={{ color: typeMarkerColor, opacity: typeMarkerOpacity }}>{typeText}</A5>
          {
            clashing
            && <A5 style={{ color: clashingTextColor, opacity: typeMarkerOpacity }}>{calendar.clashing}</A5>
          }


          { recurring_count > 1
            && <div style={{ display: 'flex', paddingTop: 2 }}>
              <Icon size='small' color='grey' name='repeat' />
            </div>
          }
        </div>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingBottom: 0 }}>

          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', paddingLeft: window_width < 340 ? 0 : 8, paddingRight: window_width < 340 ? 0 : 8 }}>
            <div style={{ display: 'flex', maxWidth: 100, justifyContent: 'center', alignItems: 'center', flex: 1, borderWidth: 1, borderStyle: 'solid', borderColor: cellBorderColor, opacity: typeMarkerOpacity }}>
              <A5 style={{ opacity: typeMarkerOpacity }}>{`${start}:`}&nbsp;</A5><A5 style={{ opacity: typeMarkerOpacity }}>{start_time}</A5>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                icon
                style={{ display: 'flex', padding: 0, flexDirection: 'row', margin: 0, width: '100%', justifyContent: 'center', alignItems: 'center', background: 'transparent', color: colours.mediumlightgray, opacity: typeMarkerOpacity }}
              >
                <Icon name='long arrow alternate right' />
              </Button>
            </div>
            <div style={{ display: 'flex', maxWidth: 100, justifyContent: 'center', alignItems: 'center', flex: 1, borderWidth: 1, borderStyle: 'solid', borderColor: cellBorderColor, opacity: typeMarkerOpacity }}>
              <A5 style={{ opacity: typeMarkerOpacity }}>{`${end}:`}&nbsp;</A5><A5 style={{ opacity: typeMarkerOpacity }}>{end_time}</A5>
            </div>
          </div>

          {
            booking_type === BOOKING_TYPES.AVAILABLE
            && <div style={{ display: 'flex', flexDirection: 'row', width: 100, alignItems: 'center', paddingLeft: 4, paddingTop: 4, paddingBottom: 4, borderWidth: 0 }}>
              <Button
                primary
                icon

                style={{ display: 'flex', flex: 1, height: 36, justifyContent: 'center', alignItems: 'center', color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}

                onClick={() => handleEditAvailability(booked_dates, booking_id, recurrance_id, user_id, viewed_date, start_time, end_time, recurrances)}

              >
                <Icon name='pencil' // 'edit outline' 'edit'
                />
              </Button>
              <Button
                primary
                icon

                style={{ display: 'flex', flex: 1, height: 36, justifyContent: 'center', alignItems: 'center', color: colours.white, backgroundColor: colours.red, borderColor: colours.orange }}

                onClick={() => handleDeleteAvailability(booking_id, recurrance_id, user_id, recurrances)}
              >
                <Icon name='trash alternate' // 'trash'
                />
              </Button>
            </div>
          }

          {
            booking_type !== 'available'
            && <div style={{ display: 'flex', flexDirection: 'row', width: 100, alignItems: 'center', paddingLeft: 4, paddingTop: 4, paddingBottom: 4, borderWidth: 0 }}>
              <Button
                primary
                icon

                style={{ display: 'flex', flex: 1, height: 36, justifyContent: 'center', alignItems: 'center', color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}

                onClick={() => handleReviewThread()}
              >
                {calendar.edit_event.full_details}
              </Button>

            </div>
          }


        </div>
      </div>

    </div>
  );
};


const mapDispatchToProps = dispatch => ({

  handleEditAvailability: (booked_dates, booking_id, recurrance_id, user_id, viewed_date, start_time, end_time, recurrances) => {
    const token = localStorage.sitterpoint_token;

        if (token) {

          dispatch(confirmEditAllAvailability(token, booked_dates, booking_id, recurrance_id, user_id, viewed_date, start_time, end_time, recurrances));
        }
    // 1st modal prompt - do you wish to edit all? or just 1?  IF > 1 recurrance
    // 2nd modal - edit times
    // 3rd modal prompt - is there a clash?

    // what if already available for all / part? what if event when recurring or available?
  },

  handleDeleteAvailability: (booking_id, recurrance_id, user_id, recurrances) => {
    const token = localStorage.sitterpoint_token;

        if (token) {
          dispatch(confirmDeleteAllAvailability(token, booking_id, recurrance_id, user_id, recurrances));
        }
    // modal prompt - do you wish to delete all? or just 1?

  }

});

export default connect(null, mapDispatchToProps)(BookingItem);
