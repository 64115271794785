import { connect } from 'react-redux';
import { openBurger, closeBurger } from '../actions/navbar';
import { persistor } from '../init-store';
import { logout } from '../actions/auth/signup';
import history from '../components/Navigation/history';
import * as ROUTES from '../constants/routes';
import Routes from '../components/routes';

const mapStateToProps = ({ nav, user, layout }) => {
    // console.log('state: ', state);

    return {
      burgerIsOpen: nav.burgerIsOpen,
      userIsLoggedIn: user.isLoggedIn,
      window_width: layout.window_width,
      window_height: layout.window_height
    };
};

const mapDispatchToProps = dispatch => ({

  handleBurgerClick: (burgerIsOpen) => {
    console.log('routecontainer click:', burgerIsOpen);
    if (burgerIsOpen) {
      dispatch(closeBurger());
    } else {
      dispatch(openBurger());
    }
  },
  handleCloseBurger: () => {
    dispatch(closeBurger());
  },
  handleLogOut: () => {
    // console.log('localStorage: ', localStorage);
    localStorage.removeItem('sitterpoint_token');

    localStorage.removeItem('sitterpoint_user_id');

    history.push(ROUTES.LANDING);

    // const rootNavigator = nav.getNavigator('root');
    // rootNavigator.replace('auth');
    // clean up persisted state
    persistor.purge();
    // store.getState().network.socket.disconnect();
    dispatch(logout());
    // redirect

  },
});

const RoutesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Routes);

export default RoutesContainer;
