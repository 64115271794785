import * as verifyAdmin from '../actions/admin/verify';

export const initialState = {
  isSigningUp: false,
  isSubmittingForm: false,
  isLoggingIn: false,
  isLoggedIn: false,
  error: undefined,
  firstname: '',
  surname: '',
  email: '',
  family_description: '',
  photo_url: '',
  user_id: '',
  kids: [],
  adults: [],
  home_address: {},
  sp: undefined,
  profile_progress: 0,
  member_since: undefined,
  revs_array: [],
  subscription_status: 'inactive',
  sp_balance: undefined,
  verified_status: undefined,
  rating: undefined,
  ratings: [],
  response_rate: undefined,
  user_update_no: undefined,
  user_open_no: undefined,
  isFetching: false,
  isFetchingUpload: false,
  errorUpdate: undefined,
  errorUpload: undefined,
  errorUpdateNo: undefined,
  errorOpenNo: undefined,
  isWelcome: false,
  admin_user_id: undefined,
  user_id_input: '',
  selected_doc: undefined,
  user_doc: undefined,
  errorUserDoc: undefined,
  user_doc_selfie: undefined,
  errorUserDocSelfie: undefined
};

export default function admin (state = initialState, action) {
  switch (action.type) {

    case verifyAdmin.SET_USER_INPUT:

      return {
        ...state,
        user_id_input: action.data
      };


    case verifyAdmin.SET_SELECTED_DOC:

      return {
        ...state,
        selected_doc: action.data
      };

    case verifyAdmin.RESET_SELECTED_DOC:

      return {
        ...state,
        selected_doc: undefined,
        user_doc: undefined
      };

    case verifyAdmin.GET_USER_DOCUMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        user_doc: undefined,
        errorUserDoc: undefined
      };

    case verifyAdmin.GET_USER_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user_doc: action.data
      };

    case verifyAdmin.GET_USER_DOCUMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorUserDoc: action.error
      };

    case verifyAdmin.GET_USER_DOCUMENT_SELFIE_REQUEST:
      return {
        ...state,
        isFetching: true,
        user_doc_selfie: undefined,
        errorUserDocSelfie: undefined
      };

    case verifyAdmin.GET_USER_DOCUMENT_SELFIE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user_doc_selfie: action.data
      };

    case verifyAdmin.GET_USER_DOCUMENT_SELFIE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorUserDocSelfie: action.error
      };

      case verifyAdmin.VERIFY_DOCUMENT_REQUEST:
        return {
          ...state,
          isFetching: true,
          user_doc: undefined,
          errorUserDoc: undefined
        };

      case verifyAdmin.VERIFY_DOCUMENT_SUCCESS:
        return {
          ...state,
          isFetching: false,
          user_doc: action.data
        };

      case verifyAdmin.VERIFY_DOCUMENT_FAILURE:
        return {
          ...state,
          isFetching: false,
          errorUserDoc: action.error
        };

    case verifyAdmin.SUBMIT_ADULTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSubmittingForm: true
      };
    case verifyAdmin.SUBMIT_ADULTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSubmittingForm: false,
        kids: action.data.kids,
        adults: action.data.adults,
        home_address: JSON.parse(action.data.home_address),
        sp: action.data.sp,
        profile_progress: action.data.profile_progress,
        family_description: action.data.family_description,
        member_since: action.data.member_since
      };
    case verifyAdmin.SUBMIT_ADULTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSubmittingForm: false,
        errorUpdate: action.error
      };

    case verifyAdmin.GET_USER_AS_ADMIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        error: undefined
      };


    case verifyAdmin.GET_USER_AS_ADMIN_SUCCESS:
      return {
        ...state,
        isSigningUp: false,
        isLoggingIn: false,
        isLoggedIn: true,
        firstname: action.data.firstname,
        surname: action.data.surname,
        email: action.data.email,
        family_description: action.data.family_description,
        photo_url: action.data.photo_url,
        user_id: action.data.user_id,
        home_address: JSON.parse(action.data.home_address),
        kids: action.data.kids,
        adults: action.data.adults,
        sp: action.data.sp,
        profile_progress: action.data.profile_progress,
        member_since: action.data.member_since,
        sp_balance: action.data.sp_balance,
        revs_array: action.data.revs_array,
        subscription_status: action.data.subscription_status,
        verified_status: action.data.verified_status,
        error: undefined
      };

    case verifyAdmin.GET_USER_AS_ADMIN_FAILURE:
      return {
        ...state,
        isSigningUp: false,
        isLoggingIn: false,
        error: action.error
      };


    default:
      return state;
  }
}
