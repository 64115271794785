import React, { useState } from 'react';
import {
  Button, Icon
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';

// opacity: props.opacity || 1
export const StyledButton = styled(Button)`

  margin: 0px!important;
  padding: 0px!important;
  padding-left: ${(props) => props.paddingLeft ? props.paddingLeft : '8px'}!important;
  padding-right: ${(props) => props.paddingRight ? props.paddingRight : '8px'}!important;
  cursor: pointer!important;

  background-color: ${(props) => props.isHovered ? colours.verylightgray : props.backgroundColor ? props.backgroundColor : colours.white}!important;
  color: ${(props) => props.isHovered ? colours.orange : colours.darkgray}!important;


`; // color based on hover fixed in BackButtonButton

export const BackButton = (props) => {

  const [hover, setHover] = useState(false);

  const { children, screensize, backgroundColor, color, textAlign, onClick, noIcon, rightIcon, paddingLeft, paddingRight } = props;

  const toggleHover = (newHover) => {
      if (hover === undefined) {
        setHover(!hover);
      } else {
        setHover(newHover);
      }
  };

  const handleClick = () => {

      onClick();

      toggleHover(false); // removes any hover effects on click

  };

  const isHovered = hover;

  return (
    <StyledButton
      title='Back'
      screensize={screensize}
      backgroundColor={backgroundColor}
      color={color || null}
      textAlign={textAlign || null}
      rightIcon={rightIcon}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      active
      isHovered={isHovered}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
      // onClick={props.onClick}
      onClick={() => handleClick()}

    >
      {
       !noIcon
        && <Icon
          name='chevron left'
          size='normal'
          style={{ color: isHovered ? colours.orange : colours.black }}
        />
      }
      {children}
      {
        rightIcon
        && <Icon
          name={rightIcon}
          size='normal'
          style={{ color: isHovered ? colours.orange : colours.black }}
        />
      }
    </StyledButton>
  );

};

export default BackButton;
