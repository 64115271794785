import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SetGA, ReportGA } from '../../utils/ga';
import {
    Modal,
    Button,

    Header
} from "semantic-ui-react";
import { hideModal } from '../../actions/modal';
// import Modal from '../../components/modals/modal';
import ModalHeader from '../../components/general/modal-header';
import ConfirmEmailContainer from '../auth/confirm-email';
import { forgot_password } from '../../text';
import { FORGOT_PASSWORD } from '../../components/modals/modalNames';
import { SIGNUP } from '../../components/modals/modalCategory';


const ForgotPasswordModal = ({
  // title,
  afterClose, hideModal }) => {

  const { header } = forgot_password;

  const onClose = () => {
    hideModal();

    if (afterClose) {
      afterClose();
    }
  };

  return (
    <Modal
      closeIcon={"close"}
      open={true}
      onClose={onClose}
      style={{ maxWidth: 500 }}
    >

      <Modal.Content>

        <ModalHeader>{header}</ModalHeader>

        <div style={{ flexDirection: 'column', paddingBottom: 16, display: 'flex', background: 'transparent', justifyContent: 'center', alignItems: 'center' }}>

          <div style={{ marginBottom: 24, maxWidth: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <ConfirmEmailContainer />

          </div>

        </div>

      </Modal.Content>

    </Modal>
  );
};

ForgotPasswordModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default connect(null, { hideModal })(ForgotPasswordModal);
