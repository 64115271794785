import React from 'react';
import { PAYG_SIT_PRICE } from '../constants/pricing';

export const user_currency = '£';
export const subscription_bonus = 500;
export const sit_price = (PAYG_SIT_PRICE / 100).toFixed(2);
export const subscription_name = 'Gold member';
// may want to add insurance cover in future, e.g. Your children are covered everytime in case of any damage they may do. It covers the parent or carer too with a liability insurance.

export const pricing = {

  page_title: 'How much does it cost?',
  section1: {
    paras: [
      `Creating a profile, getting verified and host playdates to earn points for free.`,
      `You only pay a small monthly fee to be a member, which allows you to book as many playdates as you like, so long as you have enough points.`,
      `We charge a fee to cover the costs of maintaining the platform, including verifying parents and carers, keeping your personal data safe and offering support where required.`,
      `Your first 3 months of membership are free!`
    ]
  },
  // plans: [
  //   // {
  //   //   name: `Pay as you go:`,
  //   //   text: () => `- ${user_currency}${sit_price} for every sitter you book`
  //   // },
  //   // {
  //   //   name: `Membership`,
  //   //   text: selected_plan_details => `- ${user_currency}${!selected_plan_details ? null : (selected_plan_details.amount / 100).toFixed(2)} per month, unlimited bookings with no fees`
  //   // }

  // ],
  // plans_intro: `The two options are summarised below:`,
  default_plan: `Default plan`,
  recommended_plan: `Recommended`,
  trial_offer_plan: `TRIAL OFFER !`

};

export const plan = {
  page_title: `My Account`,
  page_intro: `All you need to know about your membership plan and statements relating to your account.`,
  payg_subtitle: 'As you go',
  para_payg: `Enjoy the benefts of SitterPoint for ${user_currency}${sit_price} each time you finalise a sitting with a parent or carer. Whether you finalise a sit for 15 mins or 6 hours, only pay £5.99.`,
  or: 'or',
  memb_subtitle: subscription_name,
  // para_memb: selected_plan_details => `Get sitters as often as you like for just ${user_currency}${!selected_plan_details ? null : (selected_plan_details.amount / 100).toFixed(2)} per month, with an extra ${subscription_bonus} SittingPoints as a welcome bonus.`,
  intro_title: 'We offer you two options to choose from:',
  current_plan: 'Your current plan is:',
  change_payment_method: 'Change payment method',
  change_plan: `Become a ${subscription_name}`,

  my_membership: {
    title: 'My membership',
    current_member_price_sub: `/month`,
    current_member_next_date: `Next payment taken on `,
    not_a_member: `You are not a member.`,
    subscribe_to: `Subscribe to contact families and book playdates.`

  },
  my_points_history: {
    title: 'My points history',

  },
  my_payment_history: {
    title: 'My payment history',

  },
  contact_us: {
    title: 'Contact us',

  },
  cancel_membership_para: `By clicking “Cancel Membership”, your next payment will be cancelled and your membership will expire at the next renewal date.  From this point you will no longer be able to contact or book sitters.
You can go back to being a Member at any time by visiting the “My Account” page.`,

  // plan_para: `At the moment, your plan is set as `,
  // sub: 'Monthly membership',
  downgrade_title: 'Downgrade your plan to Pay as you go',
  downgrade_para: `If you no longer wish to be a Monthly Member and want to downgrade to “Pay as you go", please click the button below.`,
  downgrade_para2: `By clicking “Downgrade to “Pay as you go”, your next payment will be cancelled and your membership will expire at the next renewal date.  From this point you will automatically switch to Pay as you go when booking your next sitter after this date.
You can go back to being a Monthly Member at any time by visiting the “My Plan” page.`,
  downgrade_on: `Your plan is set to switch to pay as you go from `,
  trial_period_ends_on: `Your trial period ends on `,
  next_payment_on: `Your next payment will be debited on `,
  payment_was_due_on: `Your payment was due on `,
  past_due: `Payment overdue`,

  cancel_downgrade_title: 'Cancel Downgrade',
  cancel_downgrade_para: `If you no longer wish to cancel your membership and want to stay a member, please click the button below.`,
  cancel_downgrade_para2: `Click "Remain a member" below to continue your subscription.  Otherwise your next payment will be cancelled and your membership will expire at the next renewal date.  From this point you will automatically switch to Pay as you go when booking your next sitter after this date.
You can go back to being a Monthly Member at any time by visiting the “My Plan” page.`,

  buttons: {
    downgrade: 'Cancel Membership',
    cancel_downgrade: 'Remain a member'
  },
  months: 'per month',

  your_current_plan: 'Your membership',
  trial_offer: <>3 months<br />FREE TRIAL</>,
  not_a_member: `Not a member`,
  three_months_free: `3 Months Free`,
  then: `then`,
  per_month: 'per month',

  payg_short: 'PAYG',
  payg: 'Pay as you go',
  payg_price: `${user_currency}${sit_price}`,
  // sub_price: selected_plan_details => `${user_currency}${!selected_plan_details ? null : (selected_plan_details.amount / 100).toFixed(2)}`,

  per_sit: 'per sit',
  monthly_membership: 'Membership',

  // new plan features
  view_families: `View available families`,
  contact_families: `Contact families`,
  book_playdates: `Book unlimited playdates`,
  earn_points_by_hosting: `Earn points by hosting a playdate`,

  // old plan features
  // assistance: 'Assistance and support',
  // unlimited: 'No booking fees',
  // free_cancellation: 'Free sitter cancellation',
  // extra_points: 'Extra points (100 SP*)',

  modal: {
    coupon: {
      fetching: 'validating...',
      success: {
        title: 'Success',
        header: 'Coupon validated',
        para: '',
        para2: ``,
        ok: 'OK'
      },
      failure: {
        title: 'Invalid Promo',
        header: 'Oops',
        para: 'This promo code could not be validated.  Please check code and try again.',
        para2: 'Please contact us if this problem persists.',
        ok: 'OK'
      }
    },
    payg_payment: {
      fetching: 'processing...',
      success: {
        title: 'Success',
        header: 'Payment Successful',
        para: 'Your sit is confirmed',
        para2: ``,
        ok: 'OK'
      },
      failure: {
        title: 'Booking Failed',
        header: 'Oops',
        para: 'Payment failed.  Please check your payment details and try again.',
        para2: 'Please contact us if this problem persists.',
        ok: 'OK'
      }
    },
    subscribe: {
      fetching: 'processing...',
      success: {
        title: 'Success',
        header: 'Payment Successful',
        para: '',
        para2: ``,
        ok: 'OK'
      },
      failure: {
        title: 'Subscription Failed',
        header: 'Oops',
        para: 'Payment failed.  Please check your payment details and try again.',
        para2: 'Please contact us if this problem persists.',
        ok: 'OK'
      }
    },
    update_payment_method: {
      fetching: 'processing...',
      success: {
        title: 'Success',
        header: 'Card Updated',
        para: '',
        para2: ``,
        ok: 'OK'
      },
      failure: {
        title: 'Update Failed',
        header: 'Oops',
        para: 'Authorisation failed.  Please check your payment details and try again.',
        para2: 'Please contact us if this problem persists.',
        ok: 'OK'
      }
    },
    downgrade: {
      fetching: 'processing...',
      confirm: {
        title: 'Confirm',
        header: 'Downgrade plan',
        para: `Are you sure you want to downgrade your plan to pay as you go?`,
        para2: `Your plan will end on`,
        cancel: 'Cancel',
        ok: 'Downgrade'
      },
      success: {
        title: 'Change Plan',
        header: 'Plan Downgraded',
        para: 'Your plan has been successfully downgraded.',
        para2: `Your plan will switch to pay as you go from `,
        ok: 'OK'
      },
      failure: {
        title: 'Change Plan',
        header: 'Downgrade Error',
        para: 'Your plan could not be downgraded. Please try again or contact us if this problem persists.',
        para2: '',
        ok: 'OK'
      }
    },
    cancel_downgrade: {
      fetching: 'processing...',
      confirm: {
        title: 'Confirm',
        header: 'Cancel downgrade?',
        para: `Are you sure you want to remain a member?`,
        para2: `Your next payment will be on`,
        cancel: 'Cancel',
        ok: 'OK'
      },
      success: {
        title: 'Change Plan',
        header: 'Downgrade Cancelled',
        para: 'Your downgrade has been successfully cancelled.',
        para2: `Your plan will remain as monthly membership.  Your next payment will be collected on `,
        ok: 'OK'
      },
      failure: {
        title: 'Change Plan',
        header: 'Cancel Downgrade Failed',
        para: 'Your downgrade could not be cancelled. Please try again or contact us if this problem persists.',
        para2: '',
        ok: 'OK'
      }
    }

  }

};

export const choosePayment = {
  // page_title: 'Choose your plan',

  payg_title: `You are on "Pay As You Go"`,
  payg_subtitle: 'As you go',
  payg_para: `At the moment, your plan is set as "Pay As You Go".`,

  sub_title: `Start your free trial now to contact families and book playdates!`,
  // sub_para: `No booking fees with a monthly membership!`,
  sub_para2: selected_plan_details => `First 3 months free, then ${user_currency}${!selected_plan_details ? null : (selected_plan_details.amount / 100).toFixed(2)} a month. Cancel at any time.`,

  sub_terms1: '* Please note that if you chose a Monthly Membership, you can cancel it whenever you want and you will automatically downgrade to Pay as you go.',
  sub_terms2: 'Your monthly membership is valid for a month, so if you pay your monthly membership for example on the 3rd of September, you can book any sitter until the 2nd October even if the sit happen after this date.',

  promo_title: 'Have a promo code?',
  enter_promo_code: 'Enter promo code',
  promo_applied: 'Promo applied:',
  percent_off_promo_applied: `% OFF promo applied`,
  invalid_promo_code: 'Invalid promo code',

  buttons: {
    // pay: 'Pay',
    // to_book: 'to book the sit',
    // to_subscribe: 'to become a member',
    confirm_new_card: 'Save new card',
    // upgrade: 'Upgrade Now',
    start_trial: `Start my free trial`,
    subscribe: `Subscribe`,
    
    submit_card_trial: `Start my free trial`,
    submit_card: `Start my membership`,

    add_new_payment_method: `Add New Card`,
    validate_code: 'Validate code',
    submit_code: 'Submit code'

  },
  processing: 'Processing…',
  payment_method: 'Payment method',
  current_payment_methods: `Saved Payment method`,
  add_payment_method: 'Add a payment method',

  card_number: 'Card number',
  expiry_date: 'Expiry date',
  cvc_code: 'Security code',

  para_payg: plan.para_payg,
  or: plan.or,
  memb_subtitle: plan.memb_subtitle,
  // para_memb: selected_plan_details => `Get sitters as often as you like for just ${user_currency}${!selected_plan_details ? null : (selected_plan_details.amount / 100).toFixed(2)} per month, with an extra ${subscription_bonus} SittingPoints as a welcome bonus.`,
  intro_title: 'Make your payment to confirm the sit',
  alt_intro: `or become a ${subscription_name}`,
  current_plan: 'Your current plan is:',
  make_payment: 'Make payment',
  become_member: `Become a ${subscription_name}`,
  sits: 'sits',
  per_month: 'per month'
};

export const subscribe = {
  page_title: `Become a ${subscription_name}`,
  plan_subtitle: 'Subscribe',
  plan_para: 'Make your payment to subscribe',
  or: plan.or,
  memb_subtitle: plan.memb_subtitle,
  // para_memb: plan.para_memb,
  intro_title: 'Make your payment to subscribe',
  alt_intro: `or become a ${subscription_name}`,
  current_plan: 'Your current plan is:',
  make_payment: 'Make payment',
  become_member: `Become a ${subscription_name}`,
  sits: 'sits',
  per_month: 'per month',
  payment_amount_text: 'You are setting up a monthly payment of '
};


export default plan;
