import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";


// opacity: props.opacity || 1
export const StyledP = styled(Button).attrs(props => ({
  opacity: props.opacity || 1
}))`


  background: transparent!important;
  border-style: solid!important;
  outline: none!important;
  opacity: ${props => props.opacity}!important;
  padding: ${props => props.padding ? props.padding : '2px'}!important;
  padding-right: ${props => props.paddingRight ? props.paddingRight : '2px'}!important;
  cursor: pointer!important;
  border-color: ${colours.red}!important;
  border-width: 0px!important;


  text-decoration: ${props => props.underline ? 'underline' : 'none'}!important;

  font-weight: ${fonts.bold}!important;
  color: ${props => props.color ? props.color : colours.red}!important;
  text-align: left!important;
  font-size: ${props => props.screensize = 'small' ? '1em' : '4em'}!important;

  &:hover {
    text-decoration: none;
  }

`; // underline based on hover removed in MoreButtonButton


const mapStateToProps = (layout, ownProps) => {

    return {
      screensize: layout.screensize,
      color: ownProps.color,

      underline: ownProps.underline,
      ...ownProps
    };

};


class AlertButton extends Component {

  render () {

    const { screensize, color, underline, onClick } = this.props;
    // console.log('MoreButtonText props:', this.props);
    // B5 size

    return (
      <StyledP
        onClick={onClick}
        screensize={screensize}

        color={color || null}

        underline={true}
        {...this.props}

      >
        {this.props.children}
      </StyledP>
    );

  }
}

export default connect(
    mapStateToProps
)(AlertButton);
