import { landing } from './landing';
import { dashboard } from './dashboard';
import { profile } from './profile';
import { calendar } from './calendar';
import { verify } from './verify';
import { thread_chat } from './thread-chat';
import { sit_details } from './sit-details';
import { plan, choosePayment, pricing } from './plan';
import { modal } from './modal';
import { reviews } from './reviews';
import { search } from './search';
import { contact, contact_beta } from './contact';
import { how } from './how';
import { philosophy } from './philosophy';
import { tips } from './tips';
import { points } from './points';
import { refer } from './refer';
import { offerDetails } from './offer-details';
import { faq } from './faq';
import { terms } from './terms';
import { privacy } from './privacy';
import { data_deletion } from './data-deletion';
import { cookies } from './cookies';
import { popups } from './pop-ups';
import { welcome, children, adults, where_live, edit_details, upload_photo } from './register';

export { faq, landing, dashboard, profile, calendar, verify, thread_chat, sit_details, plan, choosePayment, reviews, modal, search, contact, contact_beta, how, philosophy, tips, points, refer, offerDetails, pricing, welcome, children, adults, where_live, popups, edit_details, upload_photo, terms, privacy, data_deletion, cookies };

export const header = {
  welcome_to: 'Welcome to',
  app_name: `SitterPoint`,
  t: 't',
  the_community_of: `he community of`,
  free: 'free*',
  babysitting: 'babysitting'
};


export const help = {

    title1: 'How does SitterPoint work?',
    text1: 'Learn how to book a sit and how to sit for others',
    title2: 'What are SittingPoints?',
    text2: 'SittingPoints have been created to facilitate the sitting between parents available in your area. In fact, it is very expensive and difficult to find a sitter to use for a specific date or for just a couple of hours during the week. You can choose the parents’ profile and availablility to sit your child/children agains points and vis versa.',
    title3: 'Our Philosophy',
    text3: 'Read about the values of our community',
    title4: 'FAQ',
    text4: 'Search our frequently asked questions',
    title5: 'Tips for a good sit',
    text5: '',
    title6: 'Contact Us',
    text6: ''

};


export const login = {
  title: 'Sign in',
  signin: 'Sign in',
  email_label: 'Email',
  password_label: 'Password',
  no_account_yet: 'No account yet?',
  forgot_password: 'Forgot password?',
  mandatory_fields: '* Mandatory fields',
  modal: {
    fetching: 'logging in...',
    success: {
      // title: 'Success',
      // header: 'Payment Successful',
      // para: '',
      // para2: ``,
      // ok: 'OK'
    },
    failure: {
      title: 'Login Failed',
      header: 'Oops',
      para: 'Unable to sign in.  Please check your password and try again.',
      // para2: 'Please contact us if this problem persists.',
      ok: 'OK'
    }
  },
  invalid: 'Invalid username or Password'

};

export const signup = {
  title: 'Sign up',
  signup: 'Sign up',
  header: 'Sign up',

  firstname_label: 'Name',
  surname_label: 'Surname',
  email_label: 'Email',
  password_label: 'Password',
  confirm_password_label: 'Confirm password',

  modal: {
    fetching: 'signing up...',
    success: {
      title: 'Success',
      header: 'Signup Successful',
      para: '',
      para2: ``,
      ok: 'OK'
    },
    failure: {
      title: 'Signup Failed',
      header: 'Oops',
      para: 'Unable to sign up.',
      para2: 'Please contact us if this problem persists.',
      ok: 'OK'
    }
  }
};

export const forgot_password = {
  title: 'Forgot password',
  header: 'Reset password',
  reset_password: 'Reset password',
  placeholder_email: 'Email address',
  enter_email: 'Please enter the email address you used to register and we will send an email link to reset your password',
  close: 'Close',

  modal: {
    fetching: 'sending request...',
    success: {
      title: 'Success',
      header: 'Reset Password',
      para: 'An email has been sent to',
      para2: `with further instructions.`,
      ok: 'OK'
    },
    failure: {
      title: 'Request Failed',
      header: 'Oops',
      para: 'Unable to reset password.',
      para2: 'Please contact us if this problem persists.',
      ok: 'OK'
    }
  }
};

export const verify_email = {

  // verify_email: 'Verify Email',
  resend_email: 'Resend Email',
  title: 'Verify your Account',
  header: 'Email Verification',
  send_email: 'Send Email',
  placeholder_email: 'Email address',
  check_email: 'An email will be sent to the email address in your user profile with a link to confirm your account.  Please check the below email address is correct.',
  can_change: 'You can change your email address in your profile if its no longer correct.',
  close: 'Close',

  check_your_email: 'Please check your inbox to confirm your email.',

  modal: {
    resend_email: {
      fetching: 'sending...',
      success: {
        title: 'Confirm email',
        header: 'Verify your email!',
        para: 'Please check your inbox (and spam folders if necessary) and click the link inside to confirm your email address.',
        // para2: `An email has been sent to your email address`,
        ok: 'I understand'
      },
      failure: {
        title: 'Error',
        header: 'Oops',
        para: 'An email could not be sent.  This could be due to a network error, please try again.',
        para2: 'Please contact us if this problem persists.'
      }

    }
  }


};


export const submit_verify_email = {

  // title: 'Verify your Account',
  // header: 'Email Verification',
  // close: 'Close',

  // check_your_email: 'Please check your inbox to confirm your email.',
  go_to_dashboard: 'Go to Dashboard',
  login: 'Sign in',
  resend_email_button: 'Resend Email',

  modal: {
    submit_verify_email: {
      fetching: 'verifying...',
      success: {
        title: 'Success',
        header: 'Email verified!',
        para: 'Your email has been confirmed.',
        // para2: `An email has been sent to your email address`,
        ok: 'OK'
      },
      failure: {
        title: 'Error',
        header: 'Oops',
        para: 'Your email could not be verified.  Please log in and resend a verification email from your profile',
        para2: 'Please contact us if this problem persists.'
      }

    }
  }


};


export const register = { title: 'Submitting...' };
export const finalised = { title: 'Sign up' };
export const approved = { title: 'Sign up' };

export const other = {


    other1: '',
    skip: 'Skip',
    skip_for_now: 'Skip for now',
    back: 'Back',
    continue: 'Continue',
    next: 'Next',
    save_continue: 'Save & Continue',
    save_changes: 'Save',
    go_to_dashboard: `Go to dashboard`,
    ok: 'OK',
    edit: 'Edit',
    got_it: 'Got it',
    read_more: 'Read More...',
    learn_more: 'Learn more...',
    view_more: 'View more...',
    view_less: 'View less...',
    view_details: 'View details...',
    uploading: 'uploading...',
    success: 'successful!',
    sitter_points: 'SittingPoints',

    // maybe duplicated in modal
    error_title: 'Ooops',
    error: 'Error',
    an_error_occurred: 'An error occurred',
    error_details: 'Error Details: ',
    please_pass: 'Please forward this error to the SitterPoint team for investigation so we can continue to improve the platform.'


};

export const camera = {
  take_photo: 'Take a photo',
  take_another: 'Take another photo'
};

export const nav = {

    how: 'How does it work?',
    points: 'About points',
    philosophy: 'Our philosophy',
    plans: `How much does it cost?`,
    faq: 'FAQ',
    sign_in: 'Sign in',
    sign_up: 'Create an account',
    join_beta: 'Join Beta',
    become_beta: 'Become a Beta Tester',
    sign_out: 'Sign out',
    search: `Book a Sit`,
    new_search: `New Search`,
    edit_search: `Edit Search`,

    nav_menus: {
      profile: {
        family_profile: 'Family profile',
        verify_your_email: 'Verify your email',
        complete_your_profile: `Complete your profile`,
        and_earn: `and get`,
        sittingpoints: `SittingPoints`
      },


    }

};

export const footer = {

    how: 'How does it work?',
    points: 'About points',
    philosophy: 'Our philosophy',
    faq: 'FAQ',
    plans: 'How much does it cost?',
    contact: 'Contact us',

    legal1: 'SitterPoint. All rights reserved.',
    read_our: `Read our`, // t&cs
    tc: 'Terms and conditions',
    and: `and`, // privacy policy
    pp: 'Privacy Policy'


};

export default other;
