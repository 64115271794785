import React from 'react';
import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/es/integration/react';
// import bugsnag from '@bugsnag/js';
// import bugsnagReact from '@bugsnag/plugin-react';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import App from './containers/app-container';
import Root from './Root';
// import Loading from './components/general/loading';
// import ErrorScreen from './components/general/error-screen';
// import bugsnagClient from './lib/bugsnag';
// import { store, persistor } from './init-store';
// import * as serviceWorker from './service-worker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { Config } from './service-worker';
// import {  } from '../../styles/styles';

// replace console.* for disable log on production
if (process.env.REACT_APP_APP_NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

// require('dotenv').config();

// const bugsnagClient = bugsnag(`${process.env.REACT_APP_BUGSNAG_API_KEY}`);

// bugsnagClient.use(bugsnagReact, React);
// const ErrorBoundary = bugsnagClient.getPlugin('react');
//
// const beforeSend = (report) => {
//   // You can also provide a beforeSend callback to run just on errors caught by
//   // the error boundary. Maybe you want to attach some of the current state from
//   // whatever model/store you're using (e.g redux)
//   console.log('about to send this report', { report });
// };
//
// const onBeforeLift = () => {
//   // take some action before the gate lifts - PersistGate delays Render until store has rehydrated
// };

ReactDOM.render(
  <Root>
    <App />
  </Root>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();

serviceWorkerRegistration.register({
  onUpdate: (e) => {
    const { waiting: { postMessage = null } = {}, update } = e || {};
    if (postMessage) {
      postMessage({ type: 'SKIP_WAITING' });
      console.log('service-worker posted skip waiting');
    }
    update().then(() => {
      console.log('service-worker SW updated window reloading');
      window.location.reload();
    });
  }
});
