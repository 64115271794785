import { connect } from 'react-redux';
import UpcomingWidget from '../../components/calendar/upcoming-widget';


const mapStateToProps = ({ user, nav, calendar }, ownProps) => {


  return {
    // serverError: user.error,
    // isConnected: network.isConnected,
    user_id: user.user_id,
    widget_type: ownProps.type,
    dropdown_nav_is_open: nav.dropdown_nav_is_open,
    available_dates: calendar.available_dates,
    booked_dates: calendar.booked_dates

  };

};

const mapDispatchToProps = dispatch => ({


});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingWidget);
