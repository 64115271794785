/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import SimpleBar from 'simplebar-react';
import '../../../styles/scrollbars/simplebar.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
// import '@trendmicro/react-buttons/dist/react-buttons.css';
// import '@trendmicro/react-dropdown/dist/react-dropdown.css';
// import 'react-dropdown/style.css';
// import 'react-select/dist/react-select.css';
import { Column, Row } from '../../../styles/styles';
import { ItemButton } from '../../../styles/buttons';
import { FormValueText } from '../../../styles/text';
import colours from '../../../styles/colours';
import { Container } from './common';


export default class DropdownHourValue extends Component {

  constructor (props) {
    super(props);

    this.state = {
      open: false

    };

    this.hourDropdownDivRef = React.createRef();
    console.log('this.hourDropdownDivRef:', this.hourDropdownDivRef);
  }


  componentDidMount () {

    // Assign click handler to listen the click to close the dropdown when clicked outside
    document.addEventListener("click", this.handleClickOutside);

  }

  componentWillReceiveProps (nextProps) {

    // closes dropdown when search banner is closed
    if (!nextProps.searchBannerIsOpen) {
      this.setState({ open: false });
    }
  }


  componentWillUnmount () {
    // Remove the listener
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClick = () => {

      // if click is inside, do something
      const { open } = this.state;
      this.setState({ open: !open });

  }


  handleClickOutside = (event) => {
    // console.log('hourValue handleOutsideClick:', event);
    // const path = event.path || (event.composedPath && event.composedPath());

    // if (path.includes(this.hourDropdownDivRef.current)) {

    if (this.hourDropdownDivRef && this.hourDropdownDivRef.current && !this.hourDropdownDivRef.current.contains(event.target)) {

      // console.log('hourValue includes kids dropdown:');
      if (this.state.open) {
        // console.log('hourValue handleOutsideClick is open, so closing');
        this.setState({ open: false });
      }

    }
  };

  handleSelect = (currentValue, inputKey, dataType) => {
    // console.log('handleSelect currentValue', currentValue);
    // console.log('handleSelect inputKey', inputKey);
    // console.log('handleSelect dataType', dataType);

    this.setState({ open: false });
     const { saveStartHour, saveEndHour, saveStartMinute, saveEndMinute } = this.props;
      // console.log('handleSelect this.props', this.props);
      if (dataType === 'start_hr') {
        saveStartHour(currentValue);
      } else if (dataType === 'end_hr') {
        saveEndHour(currentValue);
      } else if (dataType === 'start_min') {
        saveStartMinute(currentValue);
      } else if (dataType === 'end_min') {
        saveEndMinute(currentValue);
      }


  }


  render () {

    const { value, dataType, inputKey, viewOnly, narrow } = this.props;

    const menuOptions = () => {
      if ((dataType === 'start_hr') || (dataType === 'end_hr')) {
        return [{ value: '00' }, { value: '01' }, { value: '02' }, { value: '03' }, { value: '04' }, { value: '05' }, { value: '06' }, { value: '07' }, { value: '08' }, { value: '09' }, { value: '10' }, { value: '11' }, { value: '12' }, { value: '13' }, { value: '14' }, { value: '15' }, { value: '16' }, { value: '17' }, { value: '18' }, { value: '19' }, { value: '20' }, { value: '21' }, { value: '22' }, { value: '23' }];
      }

      if ((dataType === 'start_min') || (dataType === 'end_min')) {
        return [{ value: '00' }, { value: '15' }, { value: '30' }, { value: '45' }];
      }

    };

    const options = menuOptions();

    const { open } = this.state;


    // console.log('options', options);
    // console.log('render this.props', this.props);

    const dropdownValue = value;
    // console.log('options: ', options);

    // const defaultOption = options[this.props.value - 1];

    const menuItems = options.map((option, i) => {

        const currentValue = option.value;
        // console.log('option', option);

        return (

          <ItemButton
            type='button'
            key={`myMenuKey Sun ${option.value}`}
            eventkey={currentValue}
            index={i}
            onClick={() => this.handleSelect(currentValue, inputKey, dataType)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.handleSelect(currentValue, inputKey, dataType);
              }
            }}
            borderTopWidth={i === 0 ? '1px' : null}
          >
            {
              option.value
              && option.value
            }
            {
              !option.value
              && option
            }
          </ItemButton>

        );
    });

    return (

      <div

        // add ref here
        ref={this.hourDropdownDivRef}

        style={{
          display: 'flex',
          flex: 1,
          padding: 0,
          position: 'relative',
          cursor: 'pointer',
          // borderTopWidth: 0.5,
          // borderBottomWidth: 0.5,
          // borderLeftWidth: 0.5,
          // borderRightWidth: 0.5,
          // borderColor: colours.darkpurple,
          borderStyle: 'none'
          // background: 'transparent'
        }}
      >
        <button
          type='button'
          onClick={this.handleClick}
          onKeyDown={this.handleClick}
          style={{
            minWidth: 0,
            padding: 0,
            margin: 0,
            borderColor: colours.orange,
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: 8,
            textAlign: 'center',
            position: 'relative',
            cursor: 'pointer',
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            background: open ? colours.orange : colours.white,
            alignItems: 'center',
            justifyContent: 'center',
            textDecoration: 'none',
            overflow: 'hidden'
          }}

        >

          <Row style={{ height: 24, flex: 1, alignItems: 'center' }}>

            <div style={{ width: '0%', height: '100%' }} />

            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', paddingLeft: 4, paddingRight: 2, paddingBottom: 2, paddingTop: 2 }}>
              <FormValueText textAlign='center' color={open ? colours.white : null} marginRight='0px'>{dropdownValue}</FormValueText>
            </div>

            <Column style={{ background: 'transparent' }}>
              <div style={{ width: narrow ? 20 : 30, height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                {!viewOnly
                  && <FontAwesomeIcon
                    icon={faCaretDown}
                    size='sm'
                    style={{ height: '100%', color: open ? colours.white : colours.caretColor }} />
                }
              </div>
            </Column>

          </Row>

        </button>
        {(!viewOnly
          && open
          && <Container>
            <div
              // ref={this.hourDropdownDivRef}
              role='presentation'
              // onClick={this.handleOutsideClick}
              // onKeyDown={this.handleOutsideClick}
              style={{
                // position: 'fixed', // no longer require this with new handleOutsideClick method, - previously, without this, stops scroll locking, with it enables click behind outside to work, to close window.
                opacity: 0.2,
                background: colours.lightgray,
                top: 0,
                left: 0,
                width: '100%',
                // height: '100%', // no longer required for handleClickOutside to be picked up, using new method
                zIndex: -1,
                overflow: 'hidden'
            }} />

            <SimpleBar
              forceVisible='y'
              autoHide={false}
              // onScroll={}
              style={{
                // height: 120,
                maxHeight: 140,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: colours.mediumlightgray,
                boxShadow: `1px 1px 1px ${colours.shadow}`
            }}>

              {menuItems}
            </SimpleBar>

          </Container>
        )}

      </div>

    );
  }
}
