import { connect } from 'react-redux';
import NavBarLoggedOut from '../../components/Navigation/navbar-logged-out';
import { persistor, store } from '../../init-store';
import { logout } from '../../actions/auth/signup';
import { showModal, hideModal } from '../../actions/modal';
import { openBurger, closeBurger } from '../../actions/navbar';
import * as ROUTES from '../../constants/routes';
import history from '../../components/Navigation/history';
import { MODAL_TYPE_SIGNIN, MODAL_TYPE_SIGNUP } from '../../components/modals/modalTypes';

const mapStateToProps = ({ user, nav, layout }, ownProps) => {
  // console.log('this.state:', this.state);
  // console.log('localStorage: ', localStorage);
  return {
    isLoggedIn: user.isLoggedIn,
    isLoggingIn: user.isLoggingIn,
    burgerIsOpen: nav.burgerIsOpen,
    window_width: layout.window_width,
    currentRoute: ownProps.currentRoute
  };

};

const mapDispatchToProps = dispatch => ({
  handleLogOut: () => {
    // console.log('localStorage: ', localStorage);
    localStorage.removeItem('sitterpoint_token');

    localStorage.removeItem('sitterpoint_user_id');

    history.push(ROUTES.LANDING);

    // const rootNavigator = nav.getNavigator('root');
    // rootNavigator.replace('auth');
    // clean up persisted state
    persistor.purge();
    // store.getState().network.socket.disconnect();
    dispatch(logout());
    // redirect

  },
  // setSelectedValue: (value, inputKey, dataType) => {
  //   console.log('value: ', value);
  //   console.log('inputKey: ', inputKey);
  //   console.log('dataType: ', dataType);
  //
  //   dispatch(setValue(value, inputKey, dataType));
  //
  // },
  handleBurgerClick: (burgerIsOpen) => {
    if (burgerIsOpen) {
      dispatch(closeBurger());
    } else {
      console.log('navbar-container openBurger');
      dispatch(openBurger());
    }
  },
  handleCloseBurger: () => {
    dispatch(closeBurger());
  },
  showSignInModal: () => {
    dispatch(showModal(MODAL_TYPE_SIGNIN, {

    }));
  },
  showSignUpModal: () => {
    dispatch(showModal(MODAL_TYPE_SIGNUP, {

    }));
  },
  hideModal: () => {
    dispatch(hideModal());
  }
});

const NavBarLoggedOutContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBarLoggedOut);

export default NavBarLoggedOutContainer;
