import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Header
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";


export const StyledP = styled.a`
  /* vertical-align: ${props => props.verticalAlign}; */
  margin: 0px!important;
  padding-right: ${props => props.paddingRight ? props.paddingRight : '4px'}!important;
  padding-left: ${props => props.paddingLeft}!important;
  display: block!important;
  width: ${props => props.width}!important;

  text-overflow: ${props => props.textOverflow ? props.textOverflow : null}!important;
  white-space: ${props => props.whiteSpace}!important;
  overflow: ${props => props.overflow ? props.overflow : 'hidden'}!important;

  font-weight: ${props => props.fontWeight ? props.fontWeight : fonts.normal}!important;
  color: ${props => props.color ? props.color : colours.gray}!important;
  text-align: ${props => props.textAlign ? props.textAlign : 'left'}!important;
  font-size: ${props => props.screensize = 'small' ? '1em' : '4em'}!important;
  line-height: 18px!important;
`;

const mapStateToProps = (layout, ownProps) => {

    return {
      screensize: layout.screensize,
      color: ownProps.color,
      textAlign: ownProps.textAlign,
      width: ownProps.width,
      whiteSpace: ownProps.whiteSpace,
      textOverflow: ownProps.textOverflow,
      ...ownProps
    };

};


class A5 extends Component {

  render () {

    const { screensize, color, textAlign, fontWeight, textOverflow, whiteSpace, overflow, paddingLeft, paddingRight, width } = this.props;
    // console.log('A5 props:', this.props);

    return (
      <StyledP

        screensize={screensize}

        color={color || null}
        textAlign={textAlign || null}
        fontWeight={fontWeight}
        textOverflow={textOverflow}
        whiteSpace={whiteSpace}
        overflow={overflow}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        width={width}

      >
        {this.props.children}
      </StyledP>
    );

  }
}

export default connect(
    mapStateToProps
)(A5);
