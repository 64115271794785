// import React from 'react';
import { total_verification_points, hours_per_verification } from '../rules/points';

export const verify = {
  page_title: 'Become verifed!',
  sections: [
    {
      subtitle: 'Safety first',
      paras: [`In order to contact other like-minded families to sit your children or vice versa and to be able to make the full use of the platform, we are asking every family to get verified.`,
      `This includes every adult and child age 16 and above living in the same household.`]
    },
    {
      subtitle: 'Earn SittingPoints!',
      paras: [`When you become a verified member, you will earn ${total_verification_points} SittingPoints! These points will allow you to get an extra ${hours_per_verification > 1 ? hours_per_verification : ''} hour${hours_per_verification > 1 ? 's' : ''} of sitting.`]
    },
    {
      subtitle: 'Creating a trustworthy community',
      paras: [
        `Our vision is is to build a strong network and community between parents who can sit for each other.`,
        `The verification process shows that you are a real person living at a real address in your local area when a family searches for a family to sit their children.  Families can only be viewed or contacted once they have been verified by SitterPoint.`,
        `We want to offer you the best quality information and peace of mind for you to choose a safe and reliable sitter for you and your children.`
      ]
    }

  ],
  send_subtitle: 'Send us verification documents by uploading:',
  bullets: [
      '- ID documents of adults living under the same roof',
      '- Proof of address (eg: phone, water bill, bank statement...)',
      '- CRB/DBS if you have one (less than 3 years old)'
  ],
  allow: '(please allow 7 days for our team to verify the documents)',
  confirm_text: 'Get Adults verified',

  verify_main_adult: {
    page_title: `Your Verification`,
    subtitle: `Upload documents to verify the main adult below:`,
    intro: ``
  },
  verify_other_adults: {
    page_title: `Verify Other Adults`,
    subtitle: `Upload documents for other adults in your household (optional but recommended).`,
    intro: ``
  },
  verify_dbs_main_adult: {
    page_title: `Your DBS`,
    subtitle: `Upload DBS certificate (optional but recommended if you have one).`,
    intro: ``
  },

  choose_adult: {

    // page_title: 'Upload verification documents',
    page_intro: 'We need the following documents for each adult and 16+:',
    tbc: 'To be completed',
    bullets: [
        '- ID documents of adults living under the same roof',
        '- Proof of address (eg: phone, water bill, bank statement...) (Not needed for 16+)',
        '- CRB/DBS if you have one (less than 3 years old)'
    ],
    allow: '(Please allow 7 days for our team to verify the documents)',
    // verify_main_adult_below: 'Please verify the main adult below:',
    // verify_other_adults_below: 'Please verify other adults in your household to increase the chances of parents choosing you to sit for them:',
    // verify_dbs_main_adult_below: 'Please add DBS documents below if you have them.',
    // verify_below: 'Please verify adults below:',
    docs: {
      id: `ID (e.g. passport, Driver's Licence)`,
      poa: 'Proof of Address',
      dbs: 'DBS certificate'
    },
    not_uploaded: 'Not uploaded and awaiting upload',
    pending_verification: 'Pending verification',
    verified: 'Document verified'

  },
  upload_doc: {
    page_title: '',
    complete_profile: 'Please complete your profile first',
    upload: 'Upload document',
    verify: 'Verify',
    poa: {
      header: `Proof of Address`,
      subheader: 'What to upload?',
      explanation: 'To verify your proof of address, please upload one of:',
      supporting_docs: ['utility bill', 'bank statement'],
      upload_button: 'Upload Proof of Address'
    },
    id: {
      header: `ID (e.g. passport, Driver's Licence)`,
      subheader: 'What to upload?',
      explanation: '',
      upload_button: 'Upload ID'
    },
    // id_selfie: {
    //   // header: `Your Selfie`,
    //   // subheader: 'Confirm your identity',
    //   // explanation: 'We need a selfie of the adult so we can confirm their verify their ID.',
    //   // take_another: ' Take another',
    //   // submit: ' Submit'
    // },
    dbs: {
      header: `DBS certificate`,
      subheader: 'What to upload?',
      explanation: '',
      upload_button: 'Upload DBS'
    },
    profile_photo: {
      header: `Your Photo`,
      subheader: 'What to upload?',
      explanation: 'Upload a selfie.'
    },
    general: {
      verify_time: 'documents are usually verified within 48 hours but can take up to 7 days',
      your_docs: 'Your documents',
      verified: 'verified',
      pending: 'pending',
      not_uploaded: 'not uploaded',
      upload: 'Upload',
      upload_again: 'Upload again'
    }
  },
  modal: {
    confirm_upload_type: {
      title: 'Upload',
      photo: 'Photo',
      id: `ID`,
      poa: `Proof of Address`,
      dbs: `DBS documents`,

      allow_camera: `Allow camera`,
      must_allow_camera: `You must allow camera in your browser so that you can upload a selfie.`,
      or_upload_file: `Alternatively, you can upload an image file`,
      we_need_to_verify: `We need this so we can verify your ID.`,

      contact_us_help: `Please contact us if you need help.`,

      header: {
        photo: 'Upload Photo',
        id: `Upload ID + take a selfie`,
        poa: `Upload Proof of Address`,
        dbs: `Upload DBS`
      },
      icon_caption: {
        // photo: ``,
        id: `Enable your camera to take a selfie so we can verify the ID is yours`,
        poa: `Upload a bank statement or utility bill with your current address`,
        dbs: `Upload a valid DBS certificate`
      },
      // header: 'Choose method',
      para_photo: `Please upload a profile photo.`,
      para_id: `Choose how you want to submit your document.`,
      // para_id: `Please upload an ID document (e.g. passport, driver's license).`,
      para_poa: `Choose how you want to submit your document.`,
      para_dbs: `Choose how you want to submit your document.`,

      para2_photo: `Choose how to submit your photo`,
      // para2: `Choose how you want to submit your document`,
      // para2: `Points will not be refunded if the sit is subsequently cancelled by you.`,
      cancel: 'Cancel',
      ok: 'OK',
      camera: 'Camera',
      file: 'File'
    },
    upload_failure: {
      title: 'Upload Failed',
      header: 'Oops',
      para: 'Upload failed.  Please check your connection and try again.',
      para2: 'Please contact us if this problem persists.',
      ok: 'OK'
    },
    upload_invalid_file: {
      title: 'Upload Failed',
      header: 'Oops',
      // para: 'Upload failed.  Please check your connection and try again.',
      // para2: 'Please contact us if this problem persists.',
      ok: 'OK'
    },
    upload_fetching: {
      message: 'Uploading your document',
      success: 'Document uploaded successfully',
      uploading: 'Uploading document'
    },

    // take selfie
    take_id_intro: `We need a photo of your passport or driver's license to verify your ID.`,
    take_poa_intro: `We need a photo of a recent bill/statement to verify your address.`,
    take_dbs_intro: `Take a photo of your DBS certificate from the last 3 year's if you have one.`,
    take_selfie_intro: `We need a selfie so we can verify the ID.`,
    take_photo_intro: `Take a photo of your family for your profile picture.`,

    // slefie upload crop/preview and confirmation
    upload_selfie_id_selfie: {
      title: 'Your Selfie',
      header: `Does it look okay?`,
      para: `Make sure that your face is clearly visible.`,
      cancel: 'Cancel',
      or: 'or',
      take_another: 'Take another',
      submit: 'Upload',
      preview: 'Preview'
    },
    upload_selfie_id: {
      title: 'Your ID',
      header: `Upload this file?`,
      para: `Make sure the text is not blurry or too dark.`,
      cancel: 'Cancel',
      or: 'or',
      take_another: 'Take another',
      submit: 'Upload'
    },
    upload_selfie_poa: {
      title: 'Your Proof of Address',
      header: `Upload this file?`,
      para: `Make sure the text is not blurry or too dark.`,
      cancel: 'Cancel',
      or: 'or',
      take_another: 'Take another',
      submit: 'Upload'
    },
    upload_selfie_dbs: {
      title: 'Your DBS',
      header: `Upload this file?`,
      para: `Make sure the text is not blurry or too dark.`,
      cancel: 'Cancel',
      or: 'or',
      take_another: 'Take another',
      submit: 'Upload'
    },

    // file upload confirmation
    upload_file_id: {
      title: 'Your ID',
      header: `Upload your ID`,
      para: `Click Submit below to upload your file.`,
      cancel: 'Cancel',
      or: 'or',
      take_another: 'Take another',
      submit: 'Submit'
    },
    upload_file_poa: {
      title: 'Your Proof of Address',
      header: `Upload your POA`,
      para: 'Click Submit below to upload your file.',
      cancel: 'Cancel',
      or: 'or',
      take_another: 'Take another',
      submit: 'Submit'
    },
    upload_file_dbs: {
      title: 'Your DBS',
      header: `Upload your DBS`,
      para: `Click Submit below to upload your file.`,
      cancel: 'Cancel',
      or: 'or',
      take_another: 'Take another',
      submit: 'Submit'
    }

  }

};

export default verify;
