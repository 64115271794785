export const MODAL_TYPE_CHART = 'CHART_MODAL';
export const MODAL_TYPE_OTHER = 'OTHER_MODAL';
export const MODAL_TYPE_SIGNIN = 'SIGNIN_MODAL';
export const MODAL_TYPE_SIGNUP = 'SIGNUP_MODAL';

export const MODAL_TYPE_SEARCH = 'SEARCH_MODAL';
export const MODAL_TYPE_EDIT_SIT_DETAILS = 'MODAL_TYPE_EDIT_SIT_DETAILS';
export const MODAL_TYPE_FORGOTPASSWORD = 'FORGOTPASSWORD_MODAL';
// export const MODAL_TYPE_VERIFY_EMAIL = 'MODAL_TYPE_VERIFY_EMAIL';
export const MODAL_TYPE_SUCCESS_RESEND_VERIFY_EMAIL = 'MODAL_TYPE_SUCCESS_RESEND_VERIFY_EMAIL';
export const MODAL_TYPE_SPINNER = 'MODAL_TYPE_SPINNER';

export const MODAL_TYPE_REVIEW = 'MODAL_TYPE_REVIEW';

export const MODAL_TYPE_ERROR = 'MODAL_TYPE_ERROR';
export const MODAL_TYPE_UPLOAD_PROGRESS = 'MODAL_TYPE_UPLOAD_PROGRESS';
export const MODAL_TYPE_CONFIRM_CHANGEPLAN = 'MODAL_TYPE_CONFIRM_CHANGEPLAN';
export const MODAL_TYPE_SUCCESS_CHANGEPLAN = 'MODAL_TYPE_SUCCESS_CHANGEPLAN';
// export const MODAL_TYPE_SUCCESS_COUPON = 'MODAL_TYPE_SUCCESS_COUPON';

export const MODAL_TYPE_CONFIRM_CANCEL = 'MODAL_TYPE_CONFIRM_CANCEL';
export const MODAL_TYPE_SUCCESS_CANCEL = 'MODAL_TYPE_SUCCESS_CANCEL';

export const MODAL_TYPE_CONFIRM_PREAPPROVE = 'MODAL_TYPE_CONFIRM_PREAPPROVE';
// export const MODAL_TYPE_SUCCESS_PREAPPROVE = 'MODAL_TYPE_SUCCESS_PREAPPROVE';
export const MODAL_TYPE_CONFIRM_APPROVE = 'MODAL_TYPE_CONFIRM_APPROVE';
// export const MODAL_TYPE_SUCCESS_APPROVE = 'MODAL_TYPE_SUCCESS_APPROVE';

export const MODAL_TYPE_SUCCESS_SENT = 'MODAL_TYPE_SUCCESS_SENT';

export const MODAL_TYPE_CONFIRM_UPLOAD_TYPE = 'MODAL_TYPE_CONFIRM_UPLOAD_TYPE';
export const MODAL_TYPE_UPLOAD_SELFIE = 'MODAL_TYPE_UPLOAD_SELFIE';
export const MODAL_TYPE_UPLOAD_FILE = 'MODAL_TYPE_UPLOAD_FILE';

export const MODAL_TYPE_EDIT_DAY = 'MODAL_TYPE_EDIT_DAY';
export const MODAL_TYPE_CONFIRM_ADD_AVAILABILITY = 'MODAL_TYPE_CONFIRM_ADD_AVAILABILITY';
export const MODAL_TYPE_SUCCESS_ADD_AVAILABILITY = 'MODAL_TYPE_SUCCESS_ADD_AVAILABILITY';
// export const MODAL_TYPE_CONFIRM_EDIT_ALL_AVAILABILITY = 'MODAL_TYPE_CONFIRM_EDIT_ALL_AVAILABILITY';
export const MODAL_TYPE_EDIT_BOOKING = 'MODAL_TYPE_EDIT_BOOKING';
// export const MODAL_TYPE_CONFIRM_EDIT_AVAILABILITY = 'MODAL_TYPE_CONFIRM_EDIT_AVAILABILITY';
export const MODAL_TYPE_SUCCESS_EDIT_AVAILABILITY = 'MODAL_TYPE_SUCCESS_EDIT_AVAILABILITY';
// export const MODAL_TYPE_CONFIRM_DELETE_ALL_AVAILABILITY = 'MODAL_TYPE_CONFIRM_DELETE_ALL_AVAILABILITY';
export const MODAL_TYPE_SUCCESS_DELETE_AVAILABILITY = 'MODAL_TYPE_SUCCESS_DELETE_AVAILABILITY';

export const MODAL_TYPE_CONFIRM = 'MODAL_TYPE_CONFIRM';
export const MODAL_TYPE_SUCCESS = 'MODAL_TYPE_SUCCESS';
