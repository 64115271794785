/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
// import '@trendmicro/react-buttons/dist/react-buttons.css';
// import '@trendmicro/react-dropdown/dist/react-dropdown.css';
// import 'react-dropdown/style.css';
// import 'react-select/dist/react-select.css';
import { Column, Row } from '../../../styles/styles';
import { FormValueText } from '../../../styles/text';
import colours from '../../../styles/colours';
import Container from './common';
import KidsPickerContainer from '../../../containers/general/kids-picker';


export default class DropdownKidsValue extends Component {


  constructor (props) {
    super(props);

    // this.state = {
    //   open: false
    //
    // };

    this.kidsDropdownDivRef = React.createRef();
    // console.log('this.kidsDropdownDivRef:', this.kidsDropdownDivRef);
  }


  componentDidMount () {
    // Assign click handler to listen the click to close the dropdown when clicked outside
    document.addEventListener("click", this.handleClickOutside);

  }

  componentWillReceiveProps (nextProps) {

    // closes dropdown when search banner is closed
    if (!nextProps.searchBannerIsOpen) {
      // this.setState({ open: false });
      const { setKidsValueOpen } = this.props;
      setKidsValueOpen(false);
    }
  }


  componentWillUnmount () {
    // Remove the listener
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClick = () => {
      // console.log('kidsValue handleClick this.state.open:', this.state.open);
      // if click is inside, do something
      const { setKidsValueOpen, kids_value_is_open } = this.props;
      // this.setState({ open: !open });
      setKidsValueOpen(!kids_value_is_open);

  }

  handleClickOutside = (event) => {

    // https://stackoverflow.com/questions/32553158/detect-click-outside-react-component

    // console.log('dateValue handleOutsideClick event:', event);
    // console.log('dateValue handleOutsideClick this.dateDropdownDivRef.current:', this.dateDropdownDivRef.current);
    // const path = !event ? null : (event.path || (event.composedPath && event.composedPath()));
    // if path is null, then its coming from props passed down through SearchDatePicker (eg. back button)

    // console.log('dateValue handleOutsideClick this.dateDropdownDivRef. && !this.dateDropdownDivRef.current.contains(event.target)', this.dateDropdownDivRef && !this.dateDropdownDivRef.current.contains(event.target));
    if (this.kidsDropdownDivRef && this.kidsDropdownDivRef.current && !this.kidsDropdownDivRef.current.contains(event.target)) {

      const { setKidsValueOpen, kids_value_is_open } = this.props;

      if (kids_value_is_open) {
        // console.log('kidsValue handleOutsideClick is open, so closing');
        // this.setState({ open: false });
        setKidsValueOpen(false);
      }


    }

  };

  // handleClickOutside = (event) => {
  //   // console.log('kidsValue handleOutsideClick:', event);
  //   // console.log('kidsValue this.state.open:', this.state.open);
  //   const path = event.path || (event.composedPath && event.composedPath());
  //   // console.log('kidsValue path:', path);
  //   // console.log('kidsValue this.kidsDropdownItemsDivRef.current:', this.kidsDropdownItemsDivRef.current);
  //   // console.log('kidsValue path.includes(this.kidsDropdownItemsDivRef.current):', path.includes(this.kidsDropdownItemsDivRef.current));
  //
  //   if (path.includes(this.kidsDropdownDivRef.current)) {
  //
  //     console.log('kidsValue includes kids dropdown:');
  //
  //     if (this.state.open) {
  //       // console.log('kidsValue handleOutsideClick is open, so closing');
  //       this.setState({ open: false });
  //     }
  //
  //   }
  //
  // };


  render () {


    // const { open } = this.state;
    // console.log('dropdown render');

    const { window_width, value, all_kids, selected_kids, editMode, viewOnly, kids_value_is_open } = this.props;
    // console.log('all_kids', all_kids);
    // console.log('selected_kids', selected_kids);
    console.log('value: ', value);


    const dropdownValue = value;
    // console.log('options: ', options);

    // const defaultOption = options[this.props.value - 1];


    return (

      <div
        ref={this.kidsDropdownDivRef}

        style={{
          display: 'flex',
          flex: 4,
          padding: 0,
          position: 'relative',
          cursor: 'pointer',
          // borderTopWidth: 0.5,
          // borderBottomWidth: 0.5,
          // borderLeftWidth: 0.5,
          // borderRightWidth: 0.5,
          // borderColor: colours.orange,
          borderStyle: 'none'
          // borderRadius: 4,
          // background: colours.white
        }}
      >
        <button

          type='button'
          onClick={this.handleClick}
          onKeyDown={this.handleClick}
          style={{
            minHeight: 28,
            minWidth: 0,
            padding: 0,
            margin: 0,
            // border: 'none',
            borderColor: colours.orange,
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: 8,
            textAlign: 'center',
            position: 'relative',
            cursor: 'pointer',
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            background: kids_value_is_open ? colours.orange : colours.white,
            alignItems: 'center',
            justifyContent: 'center',
            textDecoration: 'none',
            overflow: 'hidden',
            zIndex: 1300
          }}

        >

          <div style={{ flex: 1, borderWidth: 0, borderStyle: 'solid', borderColor: 'red',
            minWidth: 0, // needed to ensure ellipsis is applied and text tag doesn't break out of flex container
            display: 'flex', justifyContent: 'flex-start',
            overflow: 'hidden',
            paddingLeft: 4, paddingRight: 2,
            paddingBottom: 2, paddingTop: 2
          }}>

            <FormValueText marginRight='0px' color={kids_value_is_open ? colours.white : null} whiteSpace='nowrap' textOverflow='ellipsis'>{dropdownValue}</FormValueText>

          </div>


          <div style={{ borderWidth: 0, borderStyle: 'solid', borderColor: 'red', width: 30, height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            {!viewOnly
              && <FontAwesomeIcon
                icon={faCaretDown}
                size='sm'
                style={{ height: '100%', color: kids_value_is_open ? colours.white : colours.caretColor }} />
            }
          </div>


        </button>
        {(!viewOnly
          && kids_value_is_open
          && <Container window_width={window_width}>
            <div
              // ref={this.kidsDropdownDivRef}
              role='presentation'
              // onClick={this.handleOutsideClick}
              // onKeyDown={this.handleOutsideClick}
              style={{
                // position: 'fixed', // no longer require this with new handleOutsideClick method, - previously, without this, stops scroll locking, with it enables click behind outside to work, to close window.
                opacity: 0.2,
                background: colours.lightgray,
                top: 0,
                left: 0,
                width: '100%',
                // height: '100%', // no longer required for handleClickOutside to be picked up, using new method

                zIndex: -1
            }} />
            <div style={{ borderWidth: 1, borderRadius: 0, borderStyle: 'solid', borderColor: colours.mediumlightgray, boxShadow: `1px 1px 1px ${colours.shadow}` }}>
              <KidsPickerContainer editMode={editMode} handleDropdownClick={() => this.handleClick()} />
            </div>
          </Container>
        )}

      </div>

    );
  }
}
