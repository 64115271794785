import React from 'react';
import {
  Checkbox
} from "semantic-ui-react";
import InputField from '../general/input-field';
import { H4 } from '../../styles/text';
import colours from '../../styles/colours';


export function FormTextFixed ({
  labelText,
  focussedColor,
  unfocussedColor,
  placeholder,
  isEmail,
  isName,
  iconName,
  input: { value, onChange, onBlur },
  meta: { touched, error } }) {

  console.log('touched: ', touched);
  console.log('error: ', error);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

      <InputField
        // placeholder={ placeholder }
        autoCorrect={ false }
        autoCapitalize={ isEmail ? 'none' : isName ? 'words' : 'sentences' }
        // onChangeText={ text => onChange(text) }
        // onBlur={ text => onBlur(text) }
        value={ placeholder }
        error={touched && error}
        type='text'
        labelType='notPoll'
        label={ labelText }
        iconName={ iconName }
        // inputKey= { inputKey }
        // onChangeText={ text => handleChange(text, inputKey) }
        focussedColor={ focussedColor }
        unfocussedColor={ unfocussedColor }
      />

      <div style={{ display: 'flex', alignSelf: 'flex-end', paddingTop: 4 }}>
        {
          touched && error && <H4 color={colours.error}>{error}</H4>
        }
      </div>
    </div>
  );
}

export function FormTextInput ({
  labelText,
  focussedColor,
  unfocussedColor,
  placeholder,
  isEmail,
  isName,
  iconName,
  input: { value, onChange, onBlur },
  meta: { touched, error } }) {

  console.log('touched: ', touched);
  console.log('error: ', error);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

      <InputField
        placeholder={ placeholder }
        autoCorrect={ false }
        autoCapitalize={ isEmail ? 'none' : isName ? 'words' : 'sentences' }
        onChangeText={ text => onChange(text) }
        onBlur={ text => onBlur(text) }
        value={ value }
        error={touched && error}
        type='text'
        labelType='notPoll'
        label={ labelText }
        iconName={ iconName }
        // inputKey= { inputKey }
        // onChangeText={ text => handleChange(text, inputKey) }
        focussedColor={ focussedColor }
        unfocussedColor={ unfocussedColor }
      />

      <div style={{ display: 'flex', alignSelf: 'flex-end', paddingTop: 4 }}>
        {
          touched && error && <H4 color={colours.error}>{error}</H4>
        }
      </div>
    </div>
  );
}

export function FormPasswordInput ({ labelText, focussedColor, unfocussedColor, iconName, placeholder, input: { value, onChange, onBlur }, meta: { touched, error } }) { // eslint-disable-line max-len
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <InputField
        // testDescription={testDescription}
        underlineColorAndroid='transparent'
        placeholder={ placeholder }
        autoCorrect={ false }
        autoCapitalize='none'
        onChangeText={ text => onChange(text) }
        onBlur={ text => onBlur(text) }
        value={ value }
        error={touched && error}
        type='password'
        labelType='notPoll'
        label={ labelText }
        iconName={ iconName }
        // inputKey= { inputKey }
        // onChangeText={ text => handleChange(text, inputKey) }
        focussedColor={ focussedColor }
        unfocussedColor={ unfocussedColor }
        secureTextEntry
      />

      <div style={{ display: 'flex', alignSelf: 'flex-end', paddingTop: 4 }}>
        {
          touched && error && <H4 color={colours.error}>{error}</H4>
        }
      </div>
    </div>
  );
}

export function FormCheckInput ({
  labelComponent,
  input,
  checked,
  meta: { touched, error } }) {

  console.log('touched: ', touched);
  console.log('error: ', error);

  return (
    <div style={{ width: '100%', borderWidth: 0, borderColor: 'red', borderStyle: 'solid', display: 'flex', flexDirection: 'column' }}>
      <div style={{ borderWidth: 0, borderColor: 'red', borderStyle: 'solid', display: 'flex', flexDirection: 'row', paddingTop: 4, paddingBottom: 4 }}>
        <div style={{ marginRight: 8, borderWidth: 0, borderColor: 'red', borderStyle: 'solid' }}>
          <input
            type='checkbox'
            checked={checked} // default value
            {...input}


          />
        </div>
        <div style={{ flex: 1, display: 'flex', alignItems: 'flex-start', borderWidth: 0, borderColor: 'red', borderStyle: 'solid' }}>
          { labelComponent }
        </div>

      </div>
      <div style={{ display: 'flex', alignSelf: 'flex-end', paddingTop: 4 }}>
        {
          touched && error && <H4 color={colours.error}>{error}</H4>
        }
      </div>
    </div>
  );
}
