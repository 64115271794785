import React from 'react';
import { L4 } from '../styles/text';
import { verification_doc_points, profile_completed_points, total_verification_points } from '../rules/points';

const dbs_info_link = 'https://dbscheckonline.org.uk/individuals';
const dbs_fees_link = 'https://www.gov.uk/government/news/fee-changes-for-dbs-checks';


export const privacy = {
  page_title: 'Privacy Policy',
  // intro: 'Here are the most frequently asked questions and useful information you should know about SitterPoint:',
  end1: 'If you can’t find the answer to your question, please contact us at ',
  end2: 'or by using our request form',
  items: [


{ link_url: ``, link_text: ``, text_type: "section_sub_title", text: `Effective Date: 21 June 2022` },

{ link_url: ``, link_text: ``, text_type: "section_title", text: `1. Who are we?` },
{ link_url: ``, link_text: ``, text_type: "para", text: `1.1 - We are SitterPoint Ltd trading as SitterPoint. For privacy queries, you can contact us at sitterpoint.co.uk/contact.` },

{ link_url: ``, link_text: ``, text_type: "section_title", text: `2. What’s the point of this policy?` },
{ link_url: ``, link_text: ``, text_type: "para", text: `2.1 - It tells you what to expect when we collect your personal information. Please only use our service if you are completely happy with this policy.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `2.2 - Generally, the policy covers only information provided to us. If you give personal information to other people, such as payment providers or other websites to which we link, such as online training courses, insurance services, payroll services and discount schemes, please check their privacy policies.` },

{ link_url: ``, link_text: ``, text_type: "section_title", text: `3. Might the policy change?` },
{ link_url: ``, link_text: ``, text_type: "para", text: `3.1 - Yes. We will post any new version on our website (please check whenever you visit the site) and also notify you by email, SMS, in-app message or otherwise. We will assume you agree to the new version of the policy if you use our site or app after its effective date.` },

{ link_url: ``, link_text: ``, text_type: "section_title", text: `4. What do we collect?` },
{ link_url: ``, link_text: ``, text_type: "para", text: `4.1 - Information which you upload to our service or otherwise give us such as:` },

{ link_url: ``, link_text: ``, text_type: "para", text: `your name, gender, date of birth, contact details, home address and any other information shared by you on your profile;` },
{ link_url: ``, link_text: ``, text_type: "para", text: `other information you provide when you register with us or later such as (if you are a provider) personal information included in documents that you upload to our service, e.g. registration / criminal record / training certs and ID documents;` },
{ link_url: ``, link_text: ``, text_type: "para", text: `details about your transactions on our service;` },
{ link_url: ``, link_text: ``, text_type: "para", text: `information that you include in your username, profile, posts, reviews or which you otherwise display on our service or which you include in messages sent via our service and/or in communications with us; and` },
{ link_url: ``, link_text: ``, text_type: "para", text: `contact or other information which you give or allow us to use for newsletters or other marketing.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `4.2 - Automated information such as the internet protocol (IP) address used to connect your device to the internet, connection information such as browser type and version, information about your device including device-type and device identifier, operating system and platform, mobile network data, a unique reference number linked to the data you enter on our system, login details, the site from which you arrived at our service, details of your activity with date / time stamps including pages you visited and your searches / transactions.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `4.3 - If you use our app’s location-related services information about your location based on your postcode.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `4.4 - The following information about you may be provided to us by other people:` },

{ link_url: ``, link_text: ``, text_type: "para", text: `4.5 - Limited billing information sent to us by our payment provider or the relevant app store for verification purposes e.g., your name, email address and billing address.` },
{ link_url: ``, link_text: ``, text_type: "para", text: `4.6 - If you log in via Facebook: Information provided by Facebook including your name, email address, date of birth and general location. If you grant permission for us to see your Facebook friends, we will use this information to show relevant content to your friends that are also members of our service. For example, your facebook friends may be able to see that you are a member of our service and see any childcare provider reviews you have written.` },
{ link_url: ``, link_text: ``, text_type: "section_title", text: `5. What’s our reason / legal basis for collecting the information?` },
{ link_url: ``, link_text: ``, text_type: "para", text: `5.1 - Because it’s necessary to take steps at your request to enter into a contract with you and/or to perform such a contract. This applies to initial enquiries and to contact and transaction details as well as to profiles, reviews and other information which you display on, or send via, our service.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `5.2 - Because it’s in our “legitimate interests”, e.g., to manage and improve our service including tracking usage patterns and preventing or detecting fraud or abuse. This applies to automated usage data. We may also use your contact information to carry out customer satisfaction surveys.` },
{ link_url: ``, link_text: ``, text_type: "para", text: `5.3 - ` },
{ link_url: ``, link_text: ``, text_type: "para", text: `5.4 - Because you’ve specifically agreed on our service (e.g, by ticking a consent box). This applies to contact or other information which you give or allow us to use for newsletters or other marketing. You can withdraw permission at any time as explained on our service or by contacting us via sitterpoint.co.uk/contact.` },

{ link_url: ``, link_text: ``, text_type: "section_title", text: `6. What about cookies?` },
{ link_url: ``, link_text: ``, text_type: "para", text: `6.1 - We and/or third parties use cookies and other tracking technologies on our website. A cookie is an identifier (a small file of letters and numbers) that is sent to your computer. Our website’s functionality will be limited if you configure your browser to reject cookies.` },

{ link_url: `www.allaboutcookies.org`, link_text: `www.allaboutcookies.org`, text_type: "para", text: `6.2 - Cookies are widely used to make websites work, or work more efficiently, as well as to provide information to the website owner or others. Session cookies are temporary cookies that remain in the cookie file of your browser only until your browser is closed. They allow websites to link your actions during a browser session. Persistent cookies stay in the cookie file of your browser for longer (how long will depend on the lifetime of the specific cookie). For further information on cookies, including how to use your browser to block them and how to delete cookies already stored on your device, visit:` },

{ link_url: ``, link_text: ``, text_type: "para", text: `6.3 - We ourselves place the following types of cookies:` },

{ link_url: ``, link_text: ``, text_type: "para", text: `6.4 - Type of cookies` },
{ link_url: ``, link_text: ``, text_type: "para", text: `6.5 - Logged-in status` },
{ link_url: ``, link_text: ``, text_type: "para", text: `6.6 - Remember me"` },
{ link_url: ``, link_text: ``, text_type: "para", text: `6.7 - Preferences` },
{ link_url: ``, link_text: ``, text_type: "para", text: `6.8 - Affiliate` },
{ link_url: ``, link_text: ``, text_type: "para", text: `6.9 - Security` },
{ link_url: ``, link_text: ``, text_type: "para", text: `6.10 - Cookie notice` },
{ link_url: ``, link_text: ``, text_type: "para", text: `6.11 - Companies which provide us with a service also place cookies. Some of these cookies (e.g. from Google) may involve certain information, such as your IP address and web address of the page you’re visiting, being sent to the company concerned. Below is a summary of the kinds of cookies used together with details about who places them and where you can go to get more information and to opt out (where possible):` },

{ link_url: ``, link_text: ``, text_type: "para", text: `6.12 - Analytics cookies: These kinds of cookies recognise and count the number of website visitors as well as providing other information about the visit such as duration, route through the website and where the visitor came from. This information helps us to improve the way our website works, for example by making sure users find what they need easily. These cookies are provided by:` },
{ link_url: ``, link_text: ``, text_type: "para", text: `6.13 - Google Analytics (“GA”). More information:` },
{ link_url: `https://support.google.com/analytics/answer/6004245`, link_text: `Google’s overview of GA data practices and safeguarding`, text_type: "para", text: `` },
{ link_url: `https://tools.google.com/dlpage/gaoptout/`, link_text: `how to specifically opt out from GA cookies`, text_type: "para", text: `6.14 - ` },
{ link_url: `https://policies.google.com/technologies/partner-sites`, link_text: `Google’s use of its partner’s data`, text_type: "para", text: `6.15 - ` },


{ link_url: ``, link_text: ``, text_type: "para", text: `6.16 - Note that there are various places where you can go to opt out of many companies’ advertising cookies in one go including some or all of the above:` },
{ link_url: `http://www.networkadvertising.org/choices`, link_text: `http://www.networkadvertising.org/choices`, text_type: "para", text: `6.17 - ` },
{ link_url: `http://www.youronlinechoices.com/`, link_text: `http://www.youronlinechoices.com/`, text_type: "para", text: `6.18 - ` },
{ link_url: `http://optout.aboutads.info`, link_text: `http://optout.aboutads.info`, text_type: "para", text: `6.19 - ` },
{ link_url: `http://www.facebook.com/help/cookies`, link_text: `Login cookies: Facebook places third party persistent cookies (up to a year) to authenticate you if you log in via Facebook. Click here for more information. If you register or log into our service via Facebook, you will be automatically logged into our service as long as you remain logged in to Facebook. If you specifically log out of our service, we place a persistent cookie to prevent the automatic login happening on future visits. Apple may also place cookies to enable log in via its service.`, text_type: "para", text: `6.20 - ` },
{ link_url: ``, link_text: ``, text_type: "section_title", text: `7. What do we do with personal information?` },
{ link_url: ``, link_text: ``, text_type: "para", text: `7.1 - Provide our service, e.g. send service messages, provide local search results, process payments, fulfil orders.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `7.2 - Send you marketing messages, if you have given permission. You can withdraw permission at any time as explained on our service or by contacting us via sitterpoint.co.uk/contact.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `7.3 - Use it to recognise you when you visit or return to our service to track anonymised traffic and usage patterns, prevent or detect fraud or abuses and help us improve our service.` },

{ link_url: ``, link_text: ``, text_type: "section_title", text: `8. How long do we keep it?` },
{ link_url: ``, link_text: ``, text_type: "para", text: `8.1 - Until our contract with you has ended or your account has been inactive for at least seven years or you contact us via our web contact form and ask us to delete your personal information.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `8.2 - We may hold on to some of your information for longer (typically up to six years) if reasonably needed for legal, regulatory or tax reasons, deal with disputes, prevent fraud or abuse and/or enforce our terms and conditions.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `8.3 - We will keep your information which we use only for newsletters or other marketing until you tell us to stop sending you such messages.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `8.4 - We will generally keep automated browsing information for up to 18 months.` },

{ link_url: ``, link_text: ``, text_type: "section_title", text: `9. To whom do we send or make available your personal information?` },
{ link_url: ``, link_text: ``, text_type: "para", text: `9.1 - To other people who supply us with a service, e.g. website hosts, content delivery networks and businesses which help us send communications, gather feedback or monitor our website and which provide us with e-commerce, online advertising, analytics, payment, review management and other IT services.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `9.2 - To the relevant insurance provider, if you opt in to the insurance scheme offered on our service. This information will include your name, contact details, childminder status and employer reference number, if applicable.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `9.3 - To other users of our service, if you enter personal information into a public profile or otherwise include it in a review or other public action on our service. Or if you give other users access to your documents which include personal information (e.g. regulatory certifications or criminal record checks). You can control your privacy settings, at sitterpoint.co.uk/settings. Your profile and reviews will be automatically removed from our site if you close your account.` },


{ link_url: ``, link_text: ``, text_type: "para", text: `9.4 - To regulators, the police and other law enforcement authorities to help deal with fraud and abuse and/or comply with legal requirements.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `9.5 - To insurers and professional advisers in connection with our insurance cover or to deal with legal claims.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `9.6 - To potential buyers so far as reasonably necessary, in the case of an actual or proposed (including negotiations for a) sale or merger or business combination involving all or the relevant part of our business.` },

{ link_url: ``, link_text: ``, text_type: "section_title", text: `10. What happens to your payment details?` },
{ link_url: ``, link_text: ``, text_type: "para", text: `10.1 - These go direct to our payment partners. We do not receive such information except as stated above. To ensure your details are not being used without consent, our payment partners may send your personal information to relevant third parties including credit reference and fraud prevention agencies, who may keep a record of that information.` },

{ link_url: ``, link_text: ``, text_type: "section_title", text: `11. Do we send your information outside the European Union?` },
{ link_url: ``, link_text: ``, text_type: "para", text: `11.1 - Your personal information which we collect is stored within the EU and is not transferred to any third countries except for the following.` },

{ link_url: ``, link_text: ``, text_type: "para", text: `11.2 - Your personal information (e.g., name, address, email address, IP address, browsing information) may be transferred to the US by the following companies: Apple and Facebook (login), Google (analytics and “Crashlytics”), Bugsnag (error reporting), Mailgun (email delivery), Stripe (payment), Slack (online tools), Trustpilot (reviews). Transfer is generally on the basis of contracts with EU-mandated standard contractual clauses designed to provide adequate safeguards for your personal information. Some of these companies were certified under the EU-US Privacy Shield Framework which is no longer applicable due to a court decision; we understand that the authorities are working on a replacement and we aim to update our policy to reflect this in due course.` },

{ link_url: ``, link_text: ``, text_type: "section_title", text: `12. What rights do you have?` },
{ link_url: ``, link_text: ``, text_type: "para", text: `12.1 - If the legal requirements are met: To ask us for access to your personal information, to rectify it if there are mistakes, to delete it or restrict it or object to its use in certain circumstances or to “data portability” or to withdraw any consent you’ve given (e.g. marketing).` },

{ link_url: ``, link_text: ``, text_type: "para", text: `12.2 - If you have a complaint about how we are dealing with your personal information, please contact us via the email address above. If you are not happy with our response or think we are not handling your personal information in accordance with the law, you can complain to the Information Commissioner’s Office (ICO).` },

{ link_url: `www.ico.org.uk`, link_text: `www.ico.org.uk`, text_type: "para", text: `12.3 - For more information about your rights, visit the ICO’s website:` }

  ]
};


export default privacy;
