import React from 'react';
import { SetGA, ReportGA } from '../../utils/ga';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Modal
} from "semantic-ui-react";
import { hideModal } from '../../actions/modal';
import Paragraph from '../../components/general/paragraph';
import ModalHeader from '../../components/general/modal-header';
import { signup } from '../../text';
import SignupContainer from '../auth/signup';


const SignupModal = ({
  // title,
  afterClose,
  hideModal }) => {

  const title = signup.title;

  const onClose = () => {
    hideModal();

    if (afterClose) {
      afterClose();
    }
  };


  return (


    <Modal
      closeIcon='close'
      open={true}
      onClose={onClose}
      style={{ maxWidth: 500 }}
      // dimmer='inverted'
      // dimmer='blurring'
      // size='tiny'
      centered={false} // puts at top of page
    >

      <Modal.Content>

        <ModalHeader>{title}</ModalHeader>

        <SignupContainer />

      </Modal.Content>

    </Modal>
  );
};

SignupModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default connect(null, { hideModal})(SignupModal);
