
// import initSocket from '../socket-router';
import { showModal, hideModal } from '../modal';
import { getMessageThread } from '../messages';
import { actionSuccess } from '../verify';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR, MODAL_TYPE_REVIEW, MODAL_TYPE_SUCCESS } from '../../components/modals/modalTypes';
import { SUCCESS_PREAPPROVE, REVIEW_SIT } from '../../components/modals/modalNames';
import { REVIEW } from '../../components/modals/modalCategory';
import * as ROUTES from '../../constants/routes';
import { reviews } from '../../text';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';

// require('dotenv').config();

export const SET_RATING = 'SET_RATING';
export const SET_REVIEW = 'SET_REVIEW';
export const SUBMIT_REVIEW_REQUEST = 'SUBMIT_REVIEW_REQUEST';
export const SUBMIT_REVIEW_SUCCESS = 'SUBMIT_REVIEW_SUCCESS';
export const SUBMIT_REVIEW_FAILURE = 'SUBMIT_REVIEW_FAILURE';

export function setRating (data) {
  return {
    type: SET_RATING,
    data
  };
}

export function setReview (data) {
  return {
    type: SET_REVIEW,
    data
  };
}


export const submitRatingRequest = () => ({
  type: SUBMIT_REVIEW_REQUEST
});

export const submitRatingSuccess = data => ({
  type: SUBMIT_REVIEW_SUCCESS,
  data
});

export const submitRatingFailure = error => ({
  type: SUBMIT_REVIEW_FAILURE,
  error
});


// launches review modal
export function reviewSitting () {

  return (dispatch) => {

    // const okAction = () => {
    //     console.log('reviewSitting okAction:');
    //   dispatch(submitRating(token, thread_id, user_id));
    // };


    dispatch(showModal(MODAL_TYPE_REVIEW, {
      // okAction: () => okAction(),
      modalCategory: REVIEW,
      modalName: REVIEW_SIT,
      backAction: null,
      cancelAction: () => dispatch(hideModal())
    }));
  };
}


export function submitRating (token, rating, review, user_id, thread_id, sitter_id, requester_id) {

  return (dispatch) => {
    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: reviews.fetching
      }
    ));
    dispatch(submitRatingRequest());
    fetch(`${process.env.REACT_APP_API_URI}/review`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({
        rating,
        review,
        user_id,
        thread_id,
        sitter_id,
        requester_id })
    })
    .then(CheckError)
    .then((data) => {

      // dispatch(sitActionSuccess(data.thread_id)); // check
      dispatch(submitRatingSuccess(data));

      const message_thread_id = data.thread_id;
      dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));

      dispatch(hideModal());
      // dispatch(showModal(
      //   MODAL_TYPE_SUCCESS_PREAPPROVE,
      //   { // modalProps
      //     // other_user_name?
      //     // other_user_name: messageParams.thread_details.requester_name,
      //     thread_stage: 'review'
      //   }
      // ));

      const modalText = reviews.modal.success;

      dispatch(showModal(
        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: REVIEW,
          modalName: SUCCESS_PREAPPROVE,
          modalText: modalText,
          okAction: () => dispatch(hideModal()),
          backAction: null,

          thread_stage: 'review'

        }

      ));


      dispatch(actionSuccess()); // triggers success icon

    })
    .catch((err) => {
      dispatch(submitRatingFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: reviews.modal.failure.title,
          header: reviews.modal.failure.header,
          message: reviews.modal.failure.para,
          message2: reviews.modal.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}
