import history from '../../components/Navigation/history';
import { showModal, hideModal } from '../modal';
import { actionSuccess } from '../verify';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS } from '../../components/modals/modalTypes';
import * as ROUTES from '../../constants/routes';
import { edit_details } from '../../text/register';
import { CheckError, ReportError } from '../../utils/check-error';
import { verify_email } from '../../text';
import { SUCCESS_RESEND_VERIFY_EMAIL, SUCCESS_EDIT_DETAILS } from '../../components/modals/modalNames';
import { PROFILE } from '../../components/modals/modalCategory';


export const SUBMIT_EDIT_DETAILS_REQUEST = 'SUBMIT_EDIT_DETAILS_REQUEST';
export const SUBMIT_EDIT_DETAILS_SUCCESS = 'SUBMIT_EDIT_DETAILS_SUCCESS';
export const SUBMIT_EDIT_DETAILS_FAILURE = 'SUBMIT_EDIT_DETAILS_FAILURE';


export const submitEditDetailsRequest = () => ({
  type: SUBMIT_EDIT_DETAILS_REQUEST
});

export const submitEditDetailsSuccess = data => ({
  type: SUBMIT_EDIT_DETAILS_SUCCESS,
  data
});

export const submitEditDetailsFailure = error => ({
  type: SUBMIT_EDIT_DETAILS_FAILURE,
  error
});


export function submitEditDetails (token, user_id, firstname, surname, email, nextRoute, editMode) {


  const nextRouteToPush = !nextRoute ? ROUTES.PROFILE_SUMMARY : nextRoute;
  console.log('nextRouteToPush:', nextRouteToPush);

  return (dispatch) => {
    dispatch(showModal(MODAL_TYPE_SPINNER, {

    }));

    dispatch(submitEditDetailsRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/users/${user_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ user: { firstname, surname, email } })
    })
    .then(CheckError)
    .then((data) => {
      dispatch(submitEditDetailsSuccess(data));

      // success modal?

      const email_changed = data.changeToEmail;

      if (email_changed) {

        // If email address changed then show resend verify email modal

        const modalText = verify_email.modal.resend_email.success;

        dispatch(showModal(

          MODAL_TYPE_SUCCESS,
          { // modalProps
            // other_user_name?
            modalCategory: PROFILE,
            modalName: SUCCESS_RESEND_VERIFY_EMAIL, // SUCCESS_EDIT_DETAILS?
            modalText: modalText,
            okAction: () => dispatch(hideModal()),
            backAction: null,

          }
        ));
      } else {

        // else if email NOT changed changed then show details saved message
        const modalText = edit_details.modal.success;

        dispatch(showModal(

          MODAL_TYPE_SUCCESS,
          { // modalProps
            // other_user_name?
            modalCategory: PROFILE,
            modalName: SUCCESS_EDIT_DETAILS, // SUCCESS_EDIT_DETAILS?
            modalText: modalText,
            okAction: () => dispatch(hideModal()),
            backAction: null,

          }
        ));

      }


      dispatch(actionSuccess()); // triggers success icon


      // dispatch(hideModal());

      history.push({
        pathname: nextRouteToPush,
        state: { editMode: editMode }
      });


      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
    })
    .catch((err) => {
      dispatch(submitEditDetailsFailure(err.message));
      dispatch(hideModal());
      console.log('DETAILS err:', err.message);
      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: edit_details.modal.failure.title,
          header: edit_details.modal.failure.header,
          message: edit_details.modal.failure.para,
          message2: edit_details.modal.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}
