
import { persistor } from '../../init-store';
import { storeToken, storeUserId } from '../../lib/credentials';
import history from '../../components/Navigation/history';
import { showModal, hideModal } from '../../actions/modal';
import { actionSuccess } from '../../actions/verify';
// import { getAPIURI } from '../../constants/env';
import { signup as signupText } from '../../text';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS } from '../../components/modals/modalTypes';
import { SUCCESS_SIGNUP } from '../../components/modals/modalNames';
import { SIGNUP } from '../../components/modals/modalCategory';
import * as ROUTES from '../../constants/routes';
// import initSocket from '../socket-router';
// require('dotenv').config();

// const API_ENDPOINT = getAPIURI();

export const SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const LOGOUT = 'LOGOUT';

export const signupUserRequest = () => ({
  type: SIGNUP_USER_REQUEST
});

export const signupUserSuccess = data => ({
  type: SIGNUP_USER_SUCCESS,
  data
});

export const signupUserFailure = error => ({
  type: SIGNUP_USER_FAILURE,
  error
});

export const logout = () => ({
  type: LOGOUT
});

export function signupUser (firstname, surname, email, password, referred_by) {

  // console.log('API_ENDPOINT:', API_ENDPOINT);
  console.log('signupUser referred_by:', referred_by);

  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: signupText.modal.fetching
      }
    ));

    dispatch(signupUserRequest());
    fetch(`${process.env.REACT_APP_API_URI}/signup`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ firstname, surname, email, password, referred_by })
    })
    .then(CheckError)
    .then((data) => {
      if (data.token && data.user_id) {
        persistor.persist();
        console.log('data:', data);
        console.log('data.available_dates:', data.available_dates);


        const modalText = signupText.modal.success;

        dispatch(showModal(

          MODAL_TYPE_SUCCESS,
          { // modalProps
            // other_user_name?
            modalCategory: SIGNUP,
            modalName: SUCCESS_SIGNUP,
            modalText,
            // okAction: () => dispatch(hideModal()),
            backAction: null

          }
        ));
        dispatch(actionSuccess()); // triggers success icon

        // add delay to not have success too fast, could make conditional on time elapsed < 2 secs
        setTimeout(() => {

          dispatch(signupUserSuccess({
            firstname: data.firstname,
            surname: data.surname,
            email: data.email,
            email_verified: data.email_verified,
            photo_url: data.photo_url,
            user_id: data.user_id,
            home_address: null,
            kids: null,
            adults: null,
            sp: data.sp,
            profile_progress: data.profile_progress,
            profile_complete_count: data.profile_complete_count,
            member_since: data.member_since,
            sp_balance: data.sp_balance,
            sp_pending: data.sp_pending,
            payments: data.payments,
            family_description: data.family_description,
            subscription_status: data.subscription_status,
            verified_status: data.verified_status,
            referral_code: data.referral_code,
            referred_by: data.referred_by
            // available_dates: data.available_dates, replaced in calendar reducer
          }));
          storeUserId(data.user_id);
          storeToken(data.token);


          // initSocket();
          history.push({
              pathname: ROUTES.INVITE,
              state: { nextRoute: ROUTES.WELCOME, editMode: false } });


          dispatch(hideModal());


        }, 2000);

      } else {
        // throw error
        console.log('data.error:', data.error);
        throw new CustomException(null, data.error);


      }
    })
    .catch((err) => {

      console.log('caught signup err.message: ', err.message);

      dispatch(signupUserFailure(err.message));

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: signupText.modal.failure.title,
          header: signupText.modal.failure.header,
          message: signupText.modal.failure.para,
          // message2: signupText.modal.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());


    });
  };
}
