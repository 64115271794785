import React from 'react';
import { ReportGA } from '../../utils/ga';
import history from './history';
import * as ROUTES from '../../constants/routes';
// import * as ROLES from '../../constants/roles';
import { NavButton, SignupButton } from '../../styles/styles';
import colours from '../../styles/colours';
import { nav } from '../../text';
//  default styles - edit in CSS to preserve hover:
import '../../styles/navigation.css';
import { NAV, BETA, SIGN_IN } from '../../constants/ga-category';

const NavMenuLoggedOut = ({ currentRoute, outsideDropdownNavClick, handleDropdownNavClick, dropdown_nav_is_open, showSignInModal, showSignUpModal }) => {

  const handleClick = (route) => {

    ReportGA({
      category: NAV,
      action: `User clicked ${route} on logged out Nav Menu`,
    });

    if (dropdown_nav_is_open) {
      console.log('closing dropdown nav because nav menu is open');
      handleDropdownNavClick(dropdown_nav_is_open); // closes dropdown nav if open
    }

    history.push(route);

  };


  const handleShowSignIn = () => {

    ReportGA({
      category: SIGN_IN,
      action: `User clicked Sign In on logged out Nav Menu`
    });

    if (dropdown_nav_is_open) {
      console.log('closing dropdown nav because nav menu is open');
      handleDropdownNavClick(dropdown_nav_is_open); // closes dropdown nav if open
    }

    showSignInModal();

  };

  const handleShowContactBeta = () => {

    ReportGA({
      category: BETA,
      action: `User clicked ContactBeta on logged out Nav Menu`
    });

    if (dropdown_nav_is_open) {
      console.log('closing dropdown nav because nav menu is open');
      handleDropdownNavClick(dropdown_nav_is_open); // closes dropdown nav if open
    }

    history.push(ROUTES.CONTACT_BETA);

  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderWidth: 0, borderStyle: 'solid', borderColor: colours.gray }}>
      <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 24, paddingBottom: 24, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', borderWidth: 0, borderStyle: 'solid', borderColor: colours.gray }}>

        <NavButton
          light
          onClick={() => handleClick(ROUTES.HOWITWORKS)}
        >
          {nav.how}
        </NavButton>

        <div style={{ height: 1, width: '100%', background: colours.borderColour, marginTop: 8, marginBottom: 8 }} />
        <NavButton
          light
          onClick={() => handleClick(ROUTES.SITTER_POINTS)}
        >
          {nav.points}
        </NavButton>
        <div style={{ height: 1, width: '100%', background: colours.borderColour, marginTop: 8, marginBottom: 8 }} />
        <NavButton
          light
          onClick={() => handleClick(ROUTES.PHILOSOPHY)}
        >
          {nav.philosophy}
        </NavButton>
        <div style={{ height: 1, width: '100%', background: colours.borderColour, marginTop: 8, marginBottom: 8 }} />
        <NavButton
          light
          onClick={() => handleClick(ROUTES.PRICING)}
        >
          {nav.plans}
        </NavButton>
        <div style={{ height: 1, width: '100%', background: colours.borderColour, marginTop: 8, marginBottom: 8 }} />
        <NavButton
          light
          onClick={() => handleClick(ROUTES.FAQ)}
        >
          {nav.faq}
        </NavButton>
        <div style={{ height: 1, width: '100%', background: colours.borderColour, marginTop: 8, marginBottom: 8 }} />

        <NavButton
          light
          onClick={handleShowSignIn}
        >
          {nav.sign_in}
        </NavButton>
        <div style={{ height: 1, width: '100%', background: colours.borderColour, marginTop: 8, marginBottom: 8 }} />

        {
          // currentRoute !== ROUTES.CONTACT_BETA &&
          // <SignupButton
          //   onClick={handleShowContactBeta}
          // >
          //   {nav.become_beta}
          // </SignupButton>
        }
        {
          <SignupButton
            onClick={showSignUpModal}
          >
            {nav.sign_up}
          </SignupButton>
        }

      </div>
    </div>

  );
};

export default NavMenuLoggedOut;
