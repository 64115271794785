import React from 'react';
import { P } from '../../styles/text';
// import colours from '../../styles/colours';

const Paragraph = ({ paddingSide, children, noBottomPadding, textAlign, color }) => {

    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>
        <div style={{ paddingTop: 4, paddingBottom: noBottomPadding ? 0 : 12, paddingLeft: paddingSide || 0, paddingRight: paddingSide || 0 }}>

          <P
            color={color}
            textAlign={textAlign || 'left'}
          >
            {children}
          </P>
        </div>
      </div>
    );
};

export default Paragraph;
