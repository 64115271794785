import React, { lazy, Suspense, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { CompatRouter, CompatRoute } from "react-router-dom-v5-compat";
import { getCookieConsentValue } from "react-cookie-consent";
import { SendGA } from '../utils/ga';
// import Cookies from './general/cookie-consent';
import PageWrapper from './general/page-wrapper';
import Spinner from './general/spinner';
import { navBarHeight, infoBarHeight } from '../styles/layout';
import * as ROUTES from '../constants/routes';
import history from './Navigation/history';
import PrivateRoute from './private-route';
import CompatPrivateRoute from './private-route-compat';
import RedirectRoute from './redirect-route';
import colours from '../styles/colours';
import Headerbar from './general/headerbar';
import ModalRoot from '../containers/modalRoot';

const LandingContainer = lazy(() => import('../containers/auth/landing'));
const DashboardContainer = lazy(() => import('../containers/dashboard/dashboard'));
const ResultsContainer = lazy(() => import('../containers/search/results'));
const HowItWorksContainer = lazy(() => import('../containers/help/how-it-works'));
const OurPhilosophyContainer = lazy(() => import('../containers/help/our-philosophy'));
const SittingPointsContainer = lazy(() => import('../containers/help/sitter-points'));
const TipsContainer = lazy(() => import('../containers/help/tips'));
const TermsContainer = lazy(() => import('../containers/help/terms'));
const PrivacyContainer = lazy(() => import('../containers/help/privacy'));
const DataDeletionContainer = lazy(() => import('../containers/help/data-deletion'));
const CookieSettingsContainer = lazy(() => import('../containers/help/cookie-settings'));
const LoginContainer = lazy(() => import('../containers/auth/login'));
const SignupContainer = lazy(() => import('../containers/auth/signup'));

const InviteContainer = lazy(() => import('../containers/auth/invite'));
const OfferDetailsContainer = lazy(() => import('../containers/help/offer-details'));

const VerifyEmailContainer = lazy(() => import('../containers/auth/verify-email'));

const ConfirmEmailContainer = lazy(() => import('../containers/auth/confirm-email'));
const WelcomeContainer = lazy(() => import('../containers/auth/welcome'));
const AboutChildrenContainer = lazy(() => import('../containers/register/aboutChildren'));
const EditChildContainer = lazy(() => import('../containers/register/edit-child'));
const EditAdultContainer = lazy(() => import('../containers/register/edit-adult'));

const EditDetailsContainer = lazy(() => import('../containers/register/editDetails'));
const BioContainer = lazy(() => import('../containers/register/bio'));
const ProfilePhotoContainer = lazy(() => import('../containers/register/profilePhoto'));
const WhereYouLiveContainer = lazy(() => import('../containers/register/whereYouLive'));
const TellUsAboutYourselfContainer = lazy(() => import('../containers/register/tellUsAboutYourself'));
const MyProfileSummaryContainer = lazy(() => import('../containers/register/my-profile-summary'));
const InboxContainer = lazy(() => import('../containers/messages/inbox'));
const RequestProfileContainer = lazy(() => import('../containers/requests/requestProfile'));
const ThreadContainer = lazy(() => import('../containers/messages/thread'));
const AvailabilityContainer = lazy(() => import('../containers/calendar/availability'));
const FaqContainer = lazy(() => import('../containers/help/faq'));
const ContactContainer = lazy(() => import('../containers/help/contact'));
const ContactBetaContainer = lazy(() => import('../containers/help/contact-beta'));
const AccountHistoryContainer = lazy(() => import('../containers/account/account-history'));
const PlanContainer = lazy(() => import('../containers/account/plan'));
const PlansPricingContainer = lazy(() => import('../containers/account/plans-pricing'));
const VerifyContainer = lazy(() => import('../containers/account/verify'));

const ChooseAdultContainer = lazy(() => import('../containers/verify/choose-adult'));
const VerifyMainAdultContainer = lazy(() => import('../containers/verify/verify-main-adult'));
const VerifyOtherAdultsContainer = lazy(() => import('../containers/verify/verify-other-adults'));
const VerifyDBSMainAdultContainer = lazy(() => import('../containers/verify/verify-dbs-main-adult'));

const ReferContainer = lazy(() => import('../containers/account/refer'));
const SubscribeToContactContainer = lazy(() => import('../containers/payments/subscribe-to-contact'));
const SubscribeContainer = lazy(() => import('../containers/payments/subscribe'));
const SubscribePayContainer = lazy(() => import('../containers/payments/subscribe-pay'));
const UserReviewsContainer = lazy(() => import('../containers/requests/user-reviews'));
const AdminVerificationContainer = lazy(() => import('../containers/admin/verify/verification-summary'));
const VerifyViewDocContainer = lazy(() => import('../containers/admin/verify/view-doc'));
// const TypographyContainer = lazy(() => import('../containers/typography-container'));
const ModalTestContainer = lazy(() => import('../containers/modal-test-container'));

const debugMode = process.env.REACT_APP_APP_NODE_ENV !== 'production';


const Routes = ({ window_width, window_height, userIsLoggedIn }) => {

  const trackPageView = (call) => {
    console.log('trackPageView call:', call);
    console.log('trackPageView window.location.pathname:', window.location.pathname);
    // SetGA({
    //   // page: window.location.pathname
    // });
    // Update the user's current page
    // any data that is relevant to the user session
    // that you would like to track with google analytics

    SendGA({
      hitType: 'pageview',
      page: window.location.pathname
    });
    // Record a pageview for the given page - TO CHECK

  };

  useEffect(() => {

    trackPageView(); // To track the first pageview upon load
    history.listen(trackPageView); // To track the subsequent pageviews

    // checks for new serviceWorker to update app to new version and reloads page when changing route
    
    history.listen(() => {
      console.log('service-worker route changed checking for new SW');
      if (window.swUpdateReady) {
        console.log('service-worker route changed found new SW');
        window.swUpdateReady = false;
        window.stop();
        window.location.reload();
        console.log('service-worker route changed window reloaded');
      }
    });

  }, [history]);


  // console.log('router render, userIsLoggedIn:', userIsLoggedIn);

  const userIsAdmin = true; // should come from props

  const cookiesAccepted = getCookieConsentValue();
  console.log('cookiesAccepted: ', cookiesAccepted);
  // try removiing different elements to see cause of height > 100%

  return (


    <Router history={history}>
      <CompatRouter>

        <div style={{ width: '100%', height: '100%', overflow: 'hidden', marginLeft: 'auto', marginRight: 'auto', maxWidth: 1440, borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>
          {
            (window_width >= 550
            || userIsLoggedIn)
            && <Headerbar />
          }

          <Suspense fallback={
            <PageWrapper window_height={window_height - (navBarHeight + infoBarHeight)}>
              <div style={{ borderWidth: 1, borderColor: 'red', borderStyle: 'solid', display: 'flex', backgroundColor: colours.verylightgray, height: '100%', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}><Spinner /></div>
            </PageWrapper>
          }>

            <Switch>
              <PrivateRoute exact userIsLoggedIn={userIsLoggedIn} path='/' component={DashboardContainer} />
              {
                // <Route path={ROUTES.TYPO} component={TypographyContainer} />
              }
              <RedirectRoute exact userIsLoggedIn={userIsLoggedIn} path={ROUTES.LANDING} component={LandingContainer} redirect_path={ROUTES.DASHBOARD} />

              <Route path={ROUTES.CONTACT_BETA} component={ContactBetaContainer} />
              <Route path={ROUTES.SIGN_UP} component={SignupContainer} />
              <Route path={ROUTES.SIGN_IN} component={LoginContainer} />
              <Route
                path={ROUTES.PASSWORD_FORGET}
                component={ConfirmEmailContainer}
              />

              <Route path={ROUTES.VERIFY_EMAIL} component={VerifyEmailContainer} />

              <CompatRoute path={ROUTES.FAQ} component={FaqContainer} />
              <Route path={ROUTES.HOWITWORKS} component={ HowItWorksContainer } />
              <Route path={ROUTES.PHILOSOPHY} component={ OurPhilosophyContainer } />
              <Route path={ROUTES.SITTER_POINTS} component={ SittingPointsContainer } />
              <Route path={ROUTES.CONTACT} component={ContactContainer} />
              <Route path={ROUTES.TIPS} component={ TipsContainer } />
              <Route path={ROUTES.TERMS} component={ TermsContainer } />

              <Route path={ROUTES.PRIVACY} component={ PrivacyContainer } />
              <Route path={ROUTES.DATA_DELETION} component={ DataDeletionContainer } />
              <Route path={ROUTES.COOKIES} component={ CookieSettingsContainer } />

              <Route path={ROUTES.REFER} component={ReferContainer} />

              <CompatPrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.DASHBOARD} component={DashboardContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.POINTS_HISTORY} component={AccountHistoryContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.PAYMENT_HISTORY} component={AccountHistoryContainer} />
              <CompatRoute path={ROUTES.PLAN} component={PlanContainer} />
              <Route path={ROUTES.PRICING} component={PlansPricingContainer} />

              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.INVITE} component={InviteContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.OFFER_DETAILS} component={OfferDetailsContainer} />

              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.WELCOME} component={WelcomeContainer} />

              <PrivateRoute userIsLoggedIn={userIsLoggedIn} condition='editMode set' path={ROUTES.ABOUT_CHILDREN} component={AboutChildrenContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} condition='editMode set' path={ROUTES.EDIT_CHILD} component={EditChildContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} condition='editMode set' path={ROUTES.WHERE_YOU_LIVE} component={WhereYouLiveContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} condition='editMode set' path={ROUTES.TELL_US_ABOUT_YOURSELF} component={TellUsAboutYourselfContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} condition='editMode set' path={ROUTES.EDIT_ADULT} component={EditAdultContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} condition='editMode set' path={ROUTES.EDIT_DETAILS} component={EditDetailsContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} condition='editMode set' path={ROUTES.BIO} component={BioContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} condition='editMode set' path={ROUTES.PROFILE_PHOTO} component={ProfilePhotoContainer} />


              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.PROFILE_SUMMARY} component={MyProfileSummaryContainer} />

              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.AVAILABILITY} component={AvailabilityContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.RESULTS} component={ResultsContainer} />


              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.INBOX} component={InboxContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.REQUEST_PROFILE} component={RequestProfileContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.USER_REVIEWS} component={UserReviewsContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.THREAD} component={ThreadContainer} />


              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.VERIFY} component={VerifyContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.CHOOSE_ADULT} component={ChooseAdultContainer} />

              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.VERIFY_MAIN_ADULT} component={VerifyMainAdultContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.VERIFY_DBS_MAIN_ADULT} component={VerifyDBSMainAdultContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.VERIFY_OTHER_ADULTS} component={VerifyOtherAdultsContainer} />


              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.SUBSCRIBE_TO_CONTACT} component={SubscribeToContactContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.SUBSCRIBE} component={SubscribeContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} path={ROUTES.SUBSCRIBE_PAY} component={SubscribePayContainer} />


              <PrivateRoute userIsLoggedIn={userIsLoggedIn} userIsAdmin={userIsAdmin} path={ROUTES.ADMIN_VERIFICATION} component={AdminVerificationContainer} />
              <PrivateRoute userIsLoggedIn={userIsLoggedIn} userIsAdmin={userIsAdmin} path={ROUTES.ADMIN_VIEW_VERIFY_DOC} component={VerifyViewDocContainer} />
              {
                debugMode &&
                <Route path='/modalTest' component={ModalTestContainer} />
              }

            </Switch>

          </Suspense>

          <ModalRoot />

          {
            // !cookiesAccepted && <div style={{ paddingBottom: 40 }} />
          }

          {
            // <Cookies />
          }


        </div>
      </CompatRouter>
    </Router>

  );


};


export default Routes;
