import React, { Component } from 'react';
import moment from 'moment';
import colours from '../../styles/colours';
import { persistor } from '../../init-store';
import { ConfirmButton, Row, Column } from '../../styles/styles';
import { BackButton } from '../../styles/buttons';
import history from '../Navigation/history';
import { A5, H2, FormLabelSmallText } from '../../styles/text';
import { search, other } from '../../text';
import Card from '../general/card';
// import CardHeader from '../general/card-header';
import Paragraph from '../general/paragraph';
import CloseButton from '../general/close-button';
// import { feedVertPaddingScale, moderateScale } from '../../styles/scaling';
import Spinner from '../general/spinner';
import ExpandButton from '../general/expand-button';
import SearchItem from './search-item';
import SearchButtonContainer from '../../containers/search/search-button';
import DropdownHourFromValueContainer from '../../containers/general/dropdown-hourFromValue';
import DropdownKidsValueContainer from '../../containers/general/dropdown-kidsValue';
import DropdownDateValueContainer from '../../containers/general/dropdown-dateValue';
import getTimeAndPoints from '../../lib/getTimeAndPoints';
import { validateDates } from '../../lib/timeHelpers';
import * as ROUTES from '../../constants/routes';

const getHelpMessage = (searchDates, searchTimes, validDates, saved_kids, userHasEnoughPoints) => {
  // console.log('searchDates:', searchDates);
  if (!searchDates) {
    return search.select_dates_message;
  }
  // console.log('searchTimes:', searchTimes);
  if (!searchTimes) {
    return search.select_times_message;
  }
  // console.log('validDates:', validDates);
  if (!validDates) {
    return search.invalid_dates;
  }
  if (!saved_kids || saved_kids.length === 0) {
    return search.select_kids_message;
  }
  if (!userHasEnoughPoints) {
    return search.not_enough_points_message;
  }
  return search.click_search_message;
};

const MenuTabs = ({ collapsed, window_width, saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, saved_kids, spQuote }) => {
  // console.log('saved_start_date:', saved_start_date);
  // console.log('saved_end_date:', saved_end_date);
  // console.log('collapsed:', collapsed);


  const startDate = (
    !saved_start_date
    ? null
    : collapsed && window_width < 700
      ? moment(saved_start_date, 'DD-MM-YYYY').format('DD MMM') // collapsed & medium or small screen
      : window_width < 360
        ? moment(saved_start_date, 'DD-MM-YYYY').format('DD/MM') // uncollapsed & small screen
        : moment(saved_start_date, 'DD-MM-YYYY').format('DD MMM') // uncollapsed & medium or large screen
  );
  const endDate = (
    !saved_end_date ? ''
    : collapsed && window_width < 700
      ? ` - ${moment(saved_end_date, 'DD-MM-YYYY').format('DD MMM')}` // collapsed & medium or small screen
      : window_width < 360
        ? ` - ${moment(saved_end_date, 'DD-MM-YYYY').format('DD/MM')}` // uncollapsed & small screen
        : ` - ${moment(saved_end_date, 'DD-MM-YYYY').format('DD MMM')}` // uncollapsed & medium or large screen
  );

  // console.log('startDate:', startDate);
  // console.log('endDate:', endDate);

  const startHr = saved_start_hr;
  const endHr = saved_end_hr;
  const startMin = saved_start_min;
  const endMin = saved_end_min;
  const savedKids = !saved_kids ? null : saved_kids.map(kid => ` ${kid.Name}`);
  console.log('savedKids:', savedKids);
  console.log('Results debug collapsed:', collapsed);
  console.log('date drop saved_start_date:', saved_start_date);

  return (
    <Row style={{ display: 'flex', flex: 1, alignItems: 'flex-end' }}>
      <Column style={{ flex: 1 }}>

        <div style={{ flex: 1, display: 'flex', flexDirection: collapsed && window_width > 500 ? 'row' : 'column', background: colours.verylightgray, paddingTop: 4, paddingLeft: 4, paddingRight: 4 }}>

          {
            // if window_width is wide, put Row to include Children after Date
            // window_width >= 700
            // &&
            <Row style={{ flex: collapsed ? 2 : null, display: 'flex', alignItems: 'flex-start', paddingBottom: 8, paddingLeft: 2, paddingRight: 2 }}>

              { // select date / time - wide screen only
              }
              <div style={{ display: 'flex', minWidth: 0, flex: 1, paddingLeft: 2, paddingRight: 4, flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0, paddingBottom: 4 }}>
                  <FormLabelSmallText>{collapsed ? search.date : search.select_date_time}</FormLabelSmallText>
                </div>
                {
                  collapsed &&
                  <SearchItem>{`${startDate}${endDate}`}</SearchItem>
                }
                {
                  !collapsed &&
                  <DropdownDateValueContainer
                    window_width={window_width}
                    value={saved_start_date ? `${startDate}${endDate}` : search.date_time_placeholder}
                    dataType='menu'
                    options={[{ value: 1 }]}
                  />
                }
              </div>

              { // select kids - wide screen only
              }
              <div style={{ display: 'flex',
              minWidth: 0, // needed to ensure ellipsis in child text is applied and container below doesn't break out of flex container
              flex: 1, paddingLeft: 4, paddingRight: 2, flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0, paddingBottom: 4 }}>
                  <FormLabelSmallText>{collapsed || window_width < 360 ? search.for_children_short : search.for_children_long}</FormLabelSmallText>
                </div>

                {
                  collapsed &&
                  <SearchItem>{`${savedKids}`}</SearchItem>
                }
                {
                  !collapsed &&
                  <DropdownKidsValueContainer
                    value={!saved_kids || saved_kids.length < 1 ? search.for_children_placeholder : `${savedKids}` }
                    dataType='menu'
                  />
                }

              </div>


            </Row>
          }

          { // select date / time - narrow screen only
            // window_width < 700
            // && <div style={{ display: 'flex', paddingLeft: 4, paddingRight: 4, flexDirection: 'column' }}>
            //   <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 2, paddingBottom: 2 }}>
            //     <FormLabelSmallText>{collapsed ? search.date : search.select_date_time}</FormLabelSmallText>
            //   </div>
            //   <DropdownDateValue
            //     window_width={window_width}
            //     value={saved_start_date ? `${startDate}${endDate}` : search.date_time_placeholder}
            //     dataType='menu'
            //     options={[{ value: 1 }]}
            //     viewOnly={collapsed}
            //   />
            // </div>
          }


          { // dropoff pickup times
          }
          <Row style={{ flex: collapsed ? 2 : null, display: 'flex', alignItems: 'center', paddingBottom: 8, paddingLeft: 2, paddingRight: 2 }}>

            <div style={{ display: 'flex', flex: 1, paddingLeft: 2, paddingRight: 4, flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0, paddingBottom: 4 }}>
                <FormLabelSmallText>{search.dropoff}</FormLabelSmallText>
              </div>
              <Row style={{ alignItems: 'center' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', paddingRight: 2 }}>
                  { collapsed &&
                    <SearchItem>{`${startHr}`}</SearchItem>
                  }
                  { !collapsed &&
                    <DropdownHourFromValueContainer
                      value={saved_start_hr ? `${startHr}` : 'Hr'}
                      dataType='start_hr'
                      viewOnly={collapsed}
                    />
                  }
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', paddingLeft: 2 }}>
                  { collapsed &&
                    <SearchItem>{`${startMin}`}</SearchItem>
                  }
                  { !collapsed &&
                    <DropdownHourFromValueContainer
                      value={saved_start_min ? `${startMin}` : 'Min'}
                      dataType='start_min'
                      viewOnly={collapsed}
                    />
                  }
                </div>
              </Row>
            </div>
            <div style={{ display: 'flex', flex: 1, paddingLeft: 4, paddingRight: 2, flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0, paddingBottom: 4 }}>
                <FormLabelSmallText>{search.pickup}</FormLabelSmallText>
              </div>
              <Row style={{ alignItems: 'center' }}>
                <div style={{ flex: 1.1, display: 'flex', justifyContent: 'center', paddingRight: 2 }}>
                  { collapsed &&
                    <SearchItem>{`${endHr}`}</SearchItem>
                  }
                  { !collapsed &&
                    <DropdownHourFromValueContainer
                      value={saved_end_hr ? `${endHr}` : 'Hr'}
                      dataType='end_hr'
                      viewOnly={collapsed}
                    />
                  }
                </div>
                <div style={{ flex: 1.1, display: 'flex', justifyContent: 'center', paddingLeft: 2 }}>
                  { collapsed &&
                    <SearchItem>{`${endMin}`}</SearchItem>
                  }
                  { !collapsed &&
                    <DropdownHourFromValueContainer
                      value={saved_end_min ? `${endMin}` : 'Min'}
                      dataType='end_min'
                      viewOnly={collapsed}
                    />
                  }
                </div>
              </Row>
            </div>
            { // SP - collapsed only
              collapsed && spQuote
              && <div style={{ display: 'flex', flex: 0.8, paddingLeft: 4, paddingRight: 2, flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0, paddingBottom: 4 }}>
                  <FormLabelSmallText>{search.sp}</FormLabelSmallText>
                </div>

                <SearchItem>{`${spQuote.spQuote}`}</SearchItem>


              </div>
            }


          </Row>

          { // select kids - narrow screen only & not collapsed
            // !collapsed
            // && window_width < 700
            // && <Row style={{ display: 'flex', alignItems: 'center', paddingBottom: 8, paddingLeft: 2, paddingRight: 2 }}>
            //   <div style={{ display: 'flex', flex: 1, paddingLeft: 2, paddingRight: 2, flexDirection: collapsed ? 'row' : 'column' }}>
            //     <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 2, paddingBottom: 2 }}>
            //       <FormLabelSmallText>{collapsed ? search.for_children_short : search.for_children_long}</FormLabelSmallText>
            //     </div>
            //     <Row style={{ alignItems: 'center' }}>
            //       <DropdownKidsValueContainer
            //         value={saved_kids ? `${savedKids}` : 'Select children'}
            //         dataType='menu'
            //         viewOnly={collapsed}
            //       />
            //     </Row>
            //   </div>
            // </Row>
          }


        </div>

        { // select kids - narrow screen only & collapsed
          // collapsed && window_width < 700
          // && <Row style={{ display: 'flex', alignItems: 'center', paddingBottom: 8, paddingLeft: 2, paddingRight: 2 }}>
          //   <div style={{ display: 'flex', flex: 1, paddingLeft: 2, paddingRight: 2, flexDirection: collapsed ? 'row' : 'column' }}>
          //     <FormLabelSmallText>{collapsed ? search.for_children_short : search.for_children_long}</FormLabelSmallText>
          //     <Row style={{ alignItems: 'center' }}>
          //       <DropdownKidsValueContainer
          //         value={saved_kids ? `${savedKids}` : 'Select children'}
          //         dataType='menu'
          //         viewOnly={collapsed}
          //       />
          //     </Row>
          //   </div>
          // </Row>
        }

      </Column>
    </Row>
  );

};

class SearchBanner extends Component {

  constructor (props) {
    super(props);

    this.state = {
      showCaret: false,
      validDates: false,
      helpMessage: '',
      showQuote: false,
      spQuote: undefined,
      userHasEnoughPoints: false,
      newSelectedSearchDetails: {
        saved_start_date: undefined,
        saved_end_date: undefined,
        saved_start_hr: undefined,
        saved_end_hr: undefined,
        saved_kids: []
      }
    };

  }


  // toggleCollapse = () => {
  //   const { collapsed } = this.state;
  //   this.setState({ collapsed: !collapsed });
  // }

  componentDidMount () {
    persistor.persist();

    console.log('banner did mount: ', this.props);

    const { user_id, handleGetTariff } = this.props;

    if (user_id) {
      const tariff_id = 1; // could replace with user.tariff_id in future based on currency/deals
      handleGetTariff(tariff_id);
    }


  }


  componentWillReceiveProps (nextProps) {

    const { editMode, edited_thread_details, submittedSearch, tariff_details, saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, saved_kids, sp_balance } = nextProps;
    console.log('getT nextProps:', nextProps);
    console.log('submittedSearch:', submittedSearch);
    const showCaret = submittedSearch !== null;

    const { saved_start_date: edit_start_date, saved_end_date: edit_end_date, saved_start_hr: edit_start_hr, saved_start_min: edit_start_min, saved_end_hr: edit_end_hr, saved_end_min: edit_end_min, saved_selected_kids: edit_kids } = edited_thread_details;


    const newSelectedSearchDetails = {
      saved_start_date: edit_start_date || saved_start_date,
      saved_end_date: edit_end_date || saved_end_date,
      saved_start_hr: edit_start_hr || saved_start_hr,
      saved_start_min: edit_start_min || saved_start_min,
      saved_end_hr: edit_end_hr || saved_end_hr,
      saved_end_min: edit_end_min || saved_end_min,
      saved_kids: edit_kids.length === 0 ? saved_kids : edit_kids
    }; // update with most recent selections used for Quote. use for quote and other items.

    const newSearchDates = (!newSelectedSearchDetails.saved_start_date) ? null : { saved_start_date: newSelectedSearchDetails.saved_start_date, saved_end_date: newSelectedSearchDetails.saved_end_date };
    const newSearchTimes = (!newSelectedSearchDetails.saved_start_hr || !newSelectedSearchDetails.saved_start_min || !newSelectedSearchDetails.saved_end_hr || !newSelectedSearchDetails.saved_end_min) ? null : { saved_start_hr: newSelectedSearchDetails.saved_start_hr, saved_start_min: newSelectedSearchDetails.saved_start_min, saved_end_hr: newSelectedSearchDetails.saved_end_hr, saved_end_min: newSelectedSearchDetails.saved_end_min };

    const searchParameters = (!newSelectedSearchDetails.saved_start_date || !newSelectedSearchDetails.saved_start_hr || !newSelectedSearchDetails.saved_start_min || !newSelectedSearchDetails.saved_end_hr || !newSelectedSearchDetails.saved_end_min) ? null : { saved_start_date: newSelectedSearchDetails.saved_start_date, saved_end_date: newSelectedSearchDetails.saved_end_date, saved_start_hr: newSelectedSearchDetails.saved_start_hr, saved_start_min: newSelectedSearchDetails.saved_start_min, saved_end_hr: newSelectedSearchDetails.saved_end_hr, saved_end_min: newSelectedSearchDetails.saved_end_min, saved_kids: newSelectedSearchDetails.saved_kids }; // only returns object if ALL fields are complete

    // const searchDates = (!saved_start_date) ? null : { saved_start_date, saved_end_date };
    // const searchTimes = (!saved_start_hr || !saved_start_min || !saved_end_hr || !saved_end_min) ? null : { saved_start_hr, saved_start_min, saved_end_hr, saved_end_min };

    console.log('sqQuote searchParameters:', searchParameters);


    // const quoteParams = (!newSelectedSearchDetails.saved_start_date || !newSelectedSearchDetails.saved_start_hr || !newSelectedSearchDetails.saved_start_min || !newSelectedSearchDetails.saved_end_hr || !newSelectedSearchDetails.saved_end_min)
    //   ? (!edited_thread_details)
    //     ? null
    //     : { saved_start_date: edit_start_date, saved_end_date: edit_end_date, saved_start_hr: edit_start_hr, saved_start_min: edit_start_min, saved_end_hr: edit_end_hr, saved_end_min: edit_end_min, saved_kids: edit_kids }
    //   : { saved_start_date, saved_end_date, saved_start_hr, saved_start_min, saved_end_hr, saved_end_min, saved_kids };

    const spQuote = !searchParameters ? null : getTimeAndPoints(tariff_details, searchParameters.saved_start_date, searchParameters.saved_end_date, searchParameters.saved_start_hr, searchParameters.saved_end_hr, searchParameters.saved_start_min, searchParameters.saved_end_min, searchParameters.saved_kids);
    console.log('getT sqQuote:', spQuote);

    // const { saved_start_date: edit_start_date, saved_end_date: edit_end_date, saved_start_hr: edit_start_hr, saved_start_min: edit_start_min, saved_end_hr: edit_end_hr, saved_end_min: edit_end_min, saved_selected_kids: edit_kids } = edited_thread_details;

    const useEditEndDate = !edit_end_date ? edit_start_date : edit_end_date;
    const start_time = `${edit_start_hr}:${edit_start_min}`;
    const end_time = `${edit_end_hr}:${edit_end_min}`;
    const editParameters = (!edit_start_date || !useEditEndDate || !edit_start_hr || !edit_start_min || !edit_end_hr || !edit_end_min || !edit_kids || (edit_kids.length === 0)) ? null : { start_date: edit_start_date, end_date: useEditEndDate, start_time, end_time, kids: edit_kids, sp_quoted: spQuote.spQuote };

    console.log('editMode:', editMode); // why is true when refreshing? set EditMode fals when Dashboard did mount?
    console.log('editParameters:', editParameters);
    console.log('searchParameters:', searchParameters);
    console.log('newSelectedSearchDetails:', newSelectedSearchDetails);
    const areDatesValid = () => {

      if (editMode) {
        if (editParameters) {
          const status = validateDates(editParameters); // do we need this or can edit be same as newSelected?
          return status;
        }
      } else if (searchParameters) {
          const status = validateDates(searchParameters);
          return status;
      } // else
      return false;
    };

    const validDates = areDatesValid();

    // ADD logic to check if user has enough points for this sit?
    const userPoints = sp_balance;
    console.log('userPoints:', userPoints);
    console.log('spQuoted:', spQuote && spQuote.spQuote);

    const userHasEnoughPoints = spQuote && (userPoints >= spQuote.spQuote);
    console.log('userHasEnoughPoints:', userHasEnoughPoints);

    console.log('kids edit_kids:', edit_kids);
    console.log('kids saved_kids:', saved_kids);


    const helpMessage = getHelpMessage(newSearchDates, newSearchTimes, validDates, saved_kids, userHasEnoughPoints);
    const showQuote = (!searchParameters || !tariff_details || !saved_kids || saved_kids.length === 0 || !validDates) ? false : true;

    this.setState({ showCaret, validDates, helpMessage, showQuote, spQuote, userHasEnoughPoints, newSelectedSearchDetails });

  }

  componentWillUpdate () {
    persistor.persist();

    console.log('banner will update: ', this.props);


  }

  previousPage = () => {
    // history.goBack();
    history.push({
      pathname: ROUTES.RESULTS,
      state: { editMode: false }
    });
  }

  render () {

    const { window_width, toggleCollapse, collapsed, submittedSearch, handleClickProfile, handleClickGetVerified, isFetching, profile_progress, verified_status, tariff_details, showSpinnerModal, hideSearchBanner } = this.props;
    // timeout maybe?
    // ifSearching...
    console.log('searchbanner this.props:', this.props);
    console.log('tariff_details:', tariff_details);
    if (isFetching) {
      showSpinnerModal();
    }

    if (!tariff_details || tariff_details === {}) {
      return <div style={{ flex: 1, width: '100%', height: '100%' }}><Spinner /></div>
    }
    console.log('submittedSearch:', submittedSearch);
    console.log('searchBanner.state:', this.state);
    const showCaret = this.state.showCaret;
    const helpMessage = this.state.helpMessage;
    const showQuote = this.state.showQuote;
    const spQuote = this.state.spQuote;
    const validDates = this.state.validDates;
    const userHasEnoughPoints = this.state.userHasEnoughPoints;

    const showBackToResults = !history.location || history.location.pathname !== ROUTES.RESULTS;

    const { saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, saved_kids } = this.state.newSelectedSearchDetails;

    console.log('spQuote:', spQuote);
    console.log('validDates:', validDates);
    console.log('userHasEnoughPoints:', userHasEnoughPoints);

    return (
      <div
        style={{
          width: '100%',
          flex: 1,
          display: 'flex',
          borderColor: colours.darkpurple, borderStyle: 'solid', borderWidth: collapsed ? 0 : 0,
          backgroundColor: colours.verylightgray,
          // borderBottomWidth: 0,
          // borderBottomColor: colours.lightgray,
          justifyContent: 'center',
          zIndex: 1

        }}
      >


        <Card
          title='search banner'
          onClick={ showCaret && collapsed ? () => toggleCollapse() : null }
          maxWidth={!collapsed ? 500 : null}

          borderColor={colours.borderColour}
          borderWidth={1}
          shadow

          noBottomMargin
          noBottomPadding
          // onClick= do we want collapse/expand to apply to whole card click?
          backButton={
            // if location is search results, then show nothing, else show back to search results - may not work if search query has been cleared
            showBackToResults && showCaret && <BackButton onClick={ () => this.previousPage() }>{search.back_to_results}</BackButton>
          }
          closeButton={
            showCaret ? <ExpandButton collapsed={collapsed} onClick={ !collapsed ? () => toggleCollapse() : null } />
            : <CloseButton onClick={ hideSearchBanner } /> // replace with condense, not close (only close if search state cleared)
          }
        >
          { (showBackToResults || !collapsed) &&
            <div style={{
              minHeight: 30,
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%',
              paddingTop: !collapsed && !showCaret && 4,
              paddingBottom: !collapsed && !showCaret && 4
            }}>
              {
                !collapsed && !showCaret &&
                <H2
                  color={colours.darkpurple}
                  alignTitle='center'
                >
                  {search.section_search_title}
                </H2>
              }
            </div>
          }
          { (!showBackToResults && collapsed) &&
            <div style={{ height: 8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>

            </div>
          }

          {
            // profile not complete
            (profile_progress !== 100) &&
            <>
              <Paragraph>
                {search.complete_profile_para}
              </Paragraph>
              <div style={{ flex: 1, width: '100%', display: 'flex', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', justifyContent: 'flex-end', alignItems: 'flex-end', paddingTop: 8, paddingBottom: 4 }}>
                <ConfirmButton onClick={ handleClickProfile }
                  style={{ alignSelf: 'flex-end' }}
                >
                  {search.complete_profile_button}
                </ConfirmButton>
              </div>
            </>
          }


          {
            // profile complete but not verified
            (profile_progress === 100) && (verified_status === 'not_verified') &&
            <>
              <Paragraph>
                {search.get_verified_para}
              </Paragraph>
              <div style={{ flex: 1, width: '100%', display: 'flex', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', justifyContent: 'flex-end', alignItems: 'flex-end', paddingTop: 8, paddingBottom: 4 }}>
                <ConfirmButton onClick={ handleClickGetVerified }
                  style={{ alignSelf: 'flex-end' }}
                >
                  {search.get_verified_button}
                </ConfirmButton>
              </div>
            </>
          }


          {
            (profile_progress === 100) && (verified_status !== 'not_verified') && !collapsed &&
            <div style={{ marginRight: 'auto', marginLeft: 'auto', width: '100%', // maxWidth: 400, // limit search banner width to 650
              display: 'flex', flexDirection: 'column',
              borderWidth: 0, borderTopWidth: 0, backgroundColor: colours.white, borderColor: colours.borderColour, borderStyle: 'solid', paddingTop: 0
            }}>

              <MenuTabs window_width={window_width} hideSearchBanner={hideSearchBanner} saved_start_date={saved_start_date} saved_end_date={saved_end_date} saved_start_hr={saved_start_hr} saved_end_hr={saved_end_hr} saved_start_min={saved_start_min} saved_end_min={saved_end_min} saved_kids={saved_kids} />

                { showQuote && // hours and points message
                  <div style={{ minHeight: 40 }}>
                    <div style={{ background: colours.white, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderWidth: 0, borderTopWidth: 0, borderStyle: 'solid', borderColor: colours.lightgray }}>
                      <A5>{showQuote && search.sit_time_label}</A5>
                      <A5 color={colours.darkpurple }>&nbsp;{showQuote ? `${spQuote.timeQuote}` : `${helpMessage}`}&nbsp;</A5>
                    </div>

                    <div style={{ background: colours.white, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderWidth: 0, borderTopWidth: 0, borderStyle: 'solid', borderColor: colours.lightgray }}>
                      <A5>{showQuote && search.points_used_label}</A5>
                      <A5 color={colours.darkpurple}>{showQuote && `${spQuote.spQuote}`}&nbsp;</A5>
                      <A5 color={colours.darkpurple}>{showQuote && other.sitter_points}</A5>

                    </div>
                  </div>
                }
                { // help message
                  (!showQuote || !userHasEnoughPoints) &&
                  <div style={{ minHeight: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ background: colours.white, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderWidth: 0, borderTopWidth: 0, borderStyle: 'solid', borderColor: colours.lightgray }}>
                      <A5 textAlign='center' color={validDates && userHasEnoughPoints && saved_kids && saved_kids.length !== 0 ? colours.darkpurple : colours.red}>&nbsp;{`${helpMessage}`}&nbsp;</A5>
                    </div>
                  </div>

                }


              <div style={{ background: colours.white, padding: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', borderWidth: 0, borderTopWidth: 0, borderStyle: 'solid', borderColor: colours.borderColour }}>

                <SearchButtonContainer userHasEnoughPoints={userHasEnoughPoints} validDates={validDates} spQuoted={spQuote ? spQuote.spQuote : null} />

              </div>

            </div>
          }

          {
            (profile_progress === 100) && (verified_status !== 'not_verified') && collapsed &&
            <div style={{ marginRight: 'auto', marginLeft: 'auto', width: '100%', // maxWidth: 400, // limit search banner width to 650
              display: 'flex', flexDirection: 'column',
              borderWidth: 0, backgroundColor: colours.white, borderColor: colours.red, borderStyle: 'solid', paddingTop: 0, paddingBottom: 8
            }}>
              <MenuTabs collapsed window_width={window_width} spQuote={spQuote} saved_start_date={saved_start_date} saved_end_date={saved_end_date} saved_start_hr={saved_start_hr} saved_end_hr={saved_end_hr} saved_start_min={saved_start_min} saved_end_min={saved_end_min} saved_kids={saved_kids} />
            </div>
          }


        </Card>


      </div>
    );
  }

}

export default SearchBanner;
