import * as layout from '../actions/layout';
import { getThemeFromScreensize } from '../styles/default-theme';

const default_theme = getThemeFromScreensize(1140);
console.log('default_theme:', default_theme);

export const SET_CHECKBOX_WIDTH = 'SET_CHECKBOX_WIDTH';


export const initialState = {
  checkboxWidth: 20,
  window_width: 1140,
  window_height: undefined,
  show_navbar: true,
  screensize: 'small',
  layout_theme: default_theme,
  cameraAspectRatio: null,

  showMainBar: true,
  showMessage: true,
  showMessageBar: false,
  showCancelPrompt: false,
  showApproveBar: false
};
// showMainBar onwards are for thread layout

export default function nav (state = initialState, action) {
  switch (action.type) {

    case layout.SET_CHECKBOX_WIDTH:
      return {
        ...state,
        checkboxWidth: action.data
      };

    case layout.SET_WINDOW_WIDTH:
      return {
        ...state,
        window_width: action.data.width,
        screensize: action.data.screensize,
        layout_theme: action.data.layout_theme
      };

    case layout.SET_WINDOW_HEIGHT:
      return {
        ...state,
        window_height: action.data.height
      };

    case layout.SET_SHOW_NAVBAR:
      return {
        ...state,
        show_navbar: action.data
      };

    case layout.SET_CAMERA_ASPECT_RATIO:
      return {
        ...state,
        cameraAspectRatio: action.data
      };

    case layout.RESET_ACTION_BAR:
      return {
        ...state,
        showMainBar: true,
        showCancelPrompt: false,
        showMessageBar: false,
        showApproveBar: false
      };

    case layout.SHOW_CANCEL_ACTION_BAR:
      return {
        ...state,
        showCancelPrompt: true,
        showMainBar: false
      };

    case layout.SHOW_MESSAGE_ACTION_BAR:
      return {
        ...state,
        showMessageBar: true,
        showMainBar: false
      };

    case layout.SHOW_APPROVE_ACTION_BAR:
      return {
        ...state,
        showApproveBar: true,
        showMainBar: false
      };


    default:
      return state;
  }
}
