import * as verifyEmail from '../actions/auth/resend-verify-email';
import * as submitVerifyEmail from '../actions/auth/submit-verify-email';

export const initialState = {

  // email: undefined,
  success_message: undefined,
  error: undefined,
  isFetching: false,

};


export default function verificationEmail (state = initialState, action) {
  switch (action.type) {


    case verifyEmail.RESEND_VERIFY_EMAIL_RESET:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        success_message: undefined
      };

    case verifyEmail.RESEND_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
        success_message: undefined
      };

    case verifyEmail.RESEND_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success_message: action.data.message

      };
    case verifyEmail.RESEND_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case submitVerifyEmail.SUBMIT_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
        success_message: undefined
      };

    case submitVerifyEmail.SUBMIT_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success_message: action.data.message

      };
    case submitVerifyEmail.SUBMIT_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };


    default:
      return state;
  }
}
