import update from 'immutability-helper';
import * as calendar from '../actions/calendar';
import * as search from '../actions/search';


export const initialState = {
  selected_dates: [],
  start_date: null,
  end_date: null,
  start_hr: null,
  end_hr: null,
  start_min: null,
  end_min: null,
  saved_start_date: null,
  saved_end_date: null,
  saved_start_hr: null,
  saved_end_hr: null,
  saved_start_min: null,
  saved_end_min: null,
  selected_recurrance: 1,
  selected_kids: [],
  saved_selected_kids: null,
  booked_dates: [{ day: 19, month: "July", time: "pm", year: "2019" }],
  isFetchingCalendar: false,
  submittedSearch: null,
  banner_is_open: false,
  date_value_is_open: false,
  kids_value_is_open: false,
  hr_value_is_open: false,
  min_value_is_open: false,
  scroll_to_banner: false,
  isSearching: false,
  selected_sort_results: 'Distance',
  results_filter: [
    'available'
  ],
  collapsed: false,
  contacted_users: [],
  nearby_users: [],
  flexible_users: []
};

const isSameKid = (first, second) => {
  console.log('kid:', first);
  console.log('comparison kid:', second);
  return (
    // first.child_id === second.child_id // better to use unique id - but need to add one to kids first
    first.Name === second.Name
  );
};

export default function searchReducer (state = initialState, action) {
  switch (action.type) {

    case search.TOGGLE_FILTER_RESULTS: {

      if (action.data.checked) {
        // add to array
        return {
          ...state,
          results_filter: [
            ...state.results_filter,
            action.data.filterItem
          ]
        };
      }
      // remove from array
      const newResultsFilter = state.results_filter.filter((item) => {

        if (item === action.data.filterItem) {
          return false;
        }

        // Every other item stays
        return true;
      });

      return {
        ...state,
        results_filter: newResultsFilter
      };
    }


    case search.CLEAR_FILTER_RESULTS:

      return {
        ...state,
        results_filter: [
          ...initialState.results_filter
        ]
      };

    case search.SET_SORT_RESULTS:

      return {
        ...state,
        selected_sort_results: action.data
      };

    case calendar.SET_START_DATE:

      return {
        ...state,
        start_date: action.dateObject
      };

    case calendar.SET_END_DATE:

      return {
        ...state,
        end_date: action.dateObject
      };

    case calendar.SAVE_START_DATE:

      return {
        ...state,
        saved_start_date: action.dateObject
      };

    case calendar.SAVE_END_DATE:

      return {
        ...state,
        saved_end_date: action.dateObject
      };

    case calendar.SET_START_HOUR:

      return {
        ...state,
        start_hr: action.dateObject
      };

    case calendar.SET_END_HOUR:

      return {
        ...state,
        end_hr: action.dateObject
      };

    case calendar.SAVE_START_HOUR:

      return {
        ...state,
        saved_start_hr: action.dateObject
      };

    case calendar.SAVE_END_HOUR:

      return {
        ...state,
        saved_end_hr: action.dateObject
      };

    case calendar.SET_START_MINUTE:

      return {
        ...state,
        start_min: action.dateObject
      };

    case calendar.SET_END_MINUTE:

      return {
        ...state,
        end_min: action.dateObject
      };

    case calendar.SAVE_START_MINUTE:

      return {
        ...state,
        saved_start_min: action.dateObject
      };

    case calendar.SAVE_END_MINUTE:

      return {
        ...state,
        saved_end_min: action.dateObject
      };

    case calendar.SAVE_SELECTED_RECURRANCE:

      return {
        ...state,
        selected_recurrance: action.data
      };

    case calendar.SET_SELECTED_KIDS:

      return {
        ...state,
        selected_kids: [
          ...state.selected_kids,
          action.kidObject
        ]
      };

    case calendar.REMOVE_SELECTED_KIDS:

      console.log('state.selected_kids:', state.selected_kids);
      const filteredKids = state.selected_kids.filter((item) => {
        console.log('item', item);
        // console.log('action', action);
        console.log('action.kidObject', action.kidObject);
        // Remove item "X"
        // alternatively: you could look for a specific index
        if (isSameKid(item, action.kidObject)) {
          console.log('false-exclude: ', action.kidObject.Name);
          return false;
        }

        // Every other item stays
        console.log('true-include: : ', action.kidObject.Name);
        return true;
      });
      console.log('filteredKids', filteredKids);

      return {
        ...state,
        selected_kids: filteredKids
      };


    case calendar.SET_ALL_SELECTED_KIDS:

      return {
        ...state,
        selected_kids: action.selected_kids
      };


    case calendar.SAVE_SELECTED_KIDS:

      return {
        ...state,
        saved_selected_kids: action.selected_kids
      };

    case search.SUBMIT_SEARCH_REQUEST:

      return {
        ...state,
        submittedSearch: action.data,
        isSearching: true,
        error: undefined,
        nearby_users: [],
        contacted_users: [] // resets so users can be re-contacted after changing search params

      };


    case search.SUBMIT_SEARCH_SUCCESS:

      return {
        ...state,
        isSearching: false,
        collapsed: true,
        nearby_users: action.data
      };

    case search.SUBMIT_SEARCH_FAILURE:

      return {
        ...state,
        isSearching: false,
        error: action.error,
        collapsed: true
      };


    case search.SUBMIT_FLEXIBLE_USERS_REQUEST:

      return {
        ...state,
        error: undefined,
        flexible_users: []
      };


    case search.SUBMIT_FLEXIBLE_USERS_SUCCESS:

      return {
        ...state,
        flexible_users: action.data
      };

    case search.SUBMIT_FLEXIBLE_USERS_FAILURE:

      return {
        ...state,
        error: action.error
      };


    case search.SHOW_SEARCH_BANNER:

      return {
        ...state,
        banner_is_open: true,
        scroll_to_banner: true
      };

    case search.SCROLLED_TO_BANNER:

      return {
        ...state,
        scroll_to_banner: false
      };

    case search.HIDE_SEARCH_BANNER:

      return {
        ...state,
        banner_is_open: false,
        date_value_is_open: false
      };

    case search.SET_DATE_VALUE_OPEN:

      return {
        ...state,
        date_value_is_open: action.data
      };

    case search.SET_KIDS_VALUE_OPEN:

      return {
        ...state,
        kids_value_is_open: action.data
      };

    case search.SET_HR_VALUE_OPEN:

      return {
        ...state,
        hr_value_is_open: action.data
      };

    case search.SET_MIN_VALUE_OPEN:

      return {
        ...state,
        min_value_is_open: action.data
      };

    case search.TOGGLE_COLLAPSE:

      return {
        ...state,
        collapsed: !state.collapsed
      };

    case search.SET_CONTACTED_USERS:

      return {
        ...state,
        contacted_users: state.contacted_users.some(user_item => user_item.user_id === action.data.user_id)
        ? [...state.contacted_users]
        : [
          ...state.contacted_users,
          action.data
        ]
      };

    case search.CLEAR_SEARCH_QUERY:

      return initialState;


    default:
      return state;
  }
}
