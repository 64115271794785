import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { SetGA, ReportGA } from '../../utils/ga';
import hoistNonReactStatic from 'hoist-non-react-statics';
import {
    Modal,
    Button
} from "semantic-ui-react";

import { FormTextInput, FormPasswordInput } from './form-components';
// import HeaderBack from '../common/CreateHeaderBackground';
import { loginValidator as validate } from './form-validation';
import colours from '../../styles/colours';
import { login, signup } from '../../text';
import { Row } from '../../styles/styles';
import { InputErrorText, H4, A5 } from '../../styles/text';
// import { feedVertPaddingScale, moderateScale } from '../../styles/scaling';
import Spinner from '../general/spinner';
import { SIGN_UP, SIGN_IN } from '../../constants/ga-category';

// const debugMode = process.env.REACT_APP_APP_NODE_ENV !== 'production';


class Login extends Component {

  constructor (props) {
    super(props);
    // console.log('constructor this props', this.props);

    this.showSignUpModal = this.showSignUpModal.bind(this);
    this.showForgotPasswordModal = this.showForgotPasswordModal.bind(this);
  }


  componentDidMount () {

    ReportGA({
      category: SIGN_IN,
      action: "Sign In modal shown to user"
    });


  }

  showSignUpModal () {
    const { showSignUpModal } = this.props;

    ReportGA({
      category: SIGN_UP,
      action: "User pressed Sign up button on Sign In modal",
    });

    showSignUpModal();
  }

  // showSpinnerModal () {
  //   const { showSpinnerModal } = this.props;
  //
  //   showSpinnerModal();
  // }

  showForgotPasswordModal () {
    const { showForgotPasswordModal } = this.props;

    ReportGA({
      category: SIGN_IN,
      action: "User pressed Forgot Password button on Sign In modal",
    });

    showForgotPasswordModal();
  }

  renderButton = () => {
    const { handleSubmit, handleSubmitForm, isLoggingIn } = this.props;
    if (isLoggingIn) {
      return <div style={{ justifyContent: 'center', alignItems: 'center' }}><Spinner size={20} /></div>;
    }
    return (
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: 8 }}>


        <Button
          primary
          style={{ width: '100%', color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
          disabled={isLoggingIn}
          onClick={handleSubmit(handleSubmitForm)}
        >
          {login.signin}
        </Button>


      </div>

    );
  };


  render () {

    const { isLoggingIn, serverError } = this.props;
    // timeout maybe?

    if (isLoggingIn) {
      return <div style={{ flex: 1, width: '100%', height: '100%' }}><Spinner /></div>
    }

    return (
      <>

        <Modal.Description>

          {serverError
            && <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 4, paddingBottom: 4 }}>
              <InputErrorText>{serverError}</InputErrorText>
            </div>
          }


          <div style={{ display: 'flex', alignItems: 'center', paddingTop: 4, paddingBottom: 4 }}>


            <div style={{ flex: 1 }}>
              <Field
                name='email'
                labelText={`${login.email_label}*`}
                component={ FormTextInput }
                isEmail
                isLogindiv
                placeholder='* Email'
                iconName='envelope-o'
                focussedColor={ colours.main }
                unfocussedColor={ colours.lightgray }
              />
            </div>


          </div>

          <div style={{ display: 'flex', alignItems: 'center', paddingTop: 4, paddingBottom: 4 }}>


            <div style={{ flex: 1 }}>
              <Field
                name='password'
                labelText={`${login.password_label}*`}
                component={ FormPasswordInput }
                isLogindiv
                placeholder='* Password'
                iconName='lock'
                focussedColor={ colours.main }
                unfocussedColor={ colours.lightgray }
              />
            </div>


          </div>

          <Row style={{ justifyContent: 'flex-end' }}>
            <A5>{login.mandatory_fields}</A5>
          </Row>

          <Row style={{ width: '100%', justifyContent: 'flex-start', paddingBottom: 12 }}>


            <button
              type='button'
              style={{ justifyContent: 'flex-start', backgroundColor: 'transparent', borderWidth: 0 }}

              onClick={ this.showForgotPasswordModal }
            >

              <H4 color={colours.red}>{login.forgot_password}</H4>

            </button>


          </Row>

          { this.renderButton() }

          <div style={{ width: '100%', height: 1, backgroundColor: colours.borderColour, marginTop: 16 }} />


          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingTop: 4, paddingBottom: 4 }}>

            {
              // debugMode &&
              <button
                type='button'
                style={{ backgroundColor: 'transparent', borderWidth: 0 }}

                onClick={ this.showSignUpModal }
              >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  <H4>{login.no_account_yet}&nbsp;</H4>
                  <H4 color={colours.red}>{signup.signup}</H4>
                </div>
              </button>
            }


          </div>


        </Modal.Description>


      </>
    );
  }
}

const decoratedComponent = reduxForm({ form: 'login', validate })(Login);
export default hoistNonReactStatic(decoratedComponent, Login);
// export default connectAlert(hoistNonReactStatic(decoratedComponent, Login));
