import React, { useState } from 'react';
import {
  Button, Dropdown
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { other } from '../../../text';

// opacity: props.opacity || 1

export const StyledButton = styled(Dropdown.Item).attrs(props => ({

}))`


  color: ${props => props.active ? colours.logopurple : props.isHovered ? colours.white : colours.mediumlightgray}!important;
  background: ${props => props.active ? colours.white : props.isHovered ? colours.verylightpurple : colours.white}!important;

  display: flex!important;
  flexDirection: row!important;
  width: 100%!important;



`;


export const DropdownItem = (props) => {
  // console.log('EditButton.props:', props);

  const [hover, setHover] = useState(false);

  const toggleHover = (newHover) => {
      if (hover === undefined) {
        setHover(!hover);
      } else {
        setHover(newHover);
      }
  };

  const handleClick = () => {
    if (!props.active) {
      props.onClick();

      toggleHover(false); // removes any hover effects on click
    }
  };


  const isHovered = hover;

  return (
    <StyledButton
      primary
      // icon={props.icon}
      labelPosition={props.icon ? props.labelPosition || 'left' : null}
      fluid={props.fluid}
      compact={props.compact}
      disabled={props.disabled}
      width={props.width}

      active={props.active}

      isHovered={isHovered}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}

      // onClick={props.onClick}
      onClick={() => handleClick()}

    >
      { // <Icon name={props.icon || 'edit'} />
      }
      {props.children || other.edit}
    </StyledButton>
  );
};

export default DropdownItem;
