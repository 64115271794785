
export const modal = {
  spinner: {
    title: 'Please wait',
    message: 'loading...'

  },
  error: {
    title: 'Error',
    header: 'Ooops',
    message: 'An error occurred',
    please_pass: 'Please forward this error to the SitterPoint team for investigation so we can continue to improve the platform.',
    error_details: 'Error Details: ',
    offline: 'You are offline.',
    error_connection: 'Please check your internet connection and try again.  If this error persists, please contact the SitterPoint team for help.',
    ok: 'OK'
  },
  confirm: {
    title: 'Confirm'
  },
  success: {
    title: 'Success'
  }


};


export default modal;
