import { connect } from 'react-redux';
import SearchDatePicker from '../../components/calendar/search-datepicker';

import { getUserById } from '../../actions/auth/profile';
import { setBookedDates, setStartDate, setEndDate, saveStartDate, saveEndDate, saveEditStartDate, saveEditEndDate, getAvailability, submitAvailability } from '../../actions/calendar';
import { getAllThreadsById } from '../../actions/messages';


const mapStateToProps = ({ layout, user, search, editSearch, messageThread, calendar }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  window_width: layout.window_width,
  user_id: user.user_id,
  // user_messages: messages.user_messages,
  isLoggingIn: user.isLoggingIn,
  available_dates: calendar.available_dates,
  booked_dates: calendar.booked_dates,
  combinedBookingsArray: [...calendar.available_dates, ...calendar.booked_dates],
  start_date: search.start_date,
  end_date: search.end_date,
  saved_start_date: messageThread.editMode ? editSearch.start_date : search.start_date,
  saved_end_date: messageThread.editMode ? editSearch.end_date : search.end_date,
  saved_start_hr: messageThread.editMode ? editSearch.start_hr : search.start_hr,
  saved_end_hr: messageThread.editMode ? editSearch.end_hr : search.end_hr,
  saved_start_min: messageThread.editMode ? editSearch.start_min : search.start_min,
  saved_end_min: messageThread.editMode ? editSearch.end_min : search.end_min,
  editMode: messageThread.editMode,
  thread_status: messageThread.thread_details.status,
  // handleOutsideClick: ownProps.handleOutsideClick,
  // handleClickBackButton: ownProps.handleClickBackButton

});

const mapDispatchToProps = (dispatch, props) => ({


  handleGetUser: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      console.log('search datePicker get User');
      dispatch(getUserById(token, user_id));

    }

  },
  // getAvailability: () => {
  //
  //     dispatch(getAvailability());
  //
  // },
  setStartDate: (dateObject) => {
      console.log('setting Start Date:', dateObject);
      dispatch(setStartDate(dateObject));

  },
  setEndDate: (dateObject) => {
      console.log('setting End Date:', dateObject);
      dispatch(setEndDate(dateObject));

  },
  // saveStartDate: (dateObject) => {
  //
  //     dispatch(saveStartDate(dateObject));
  //
  // },
  // saveEndDate: (dateObject) => {
  //
  //     dispatch(saveEndDate(dateObject));
  //
  // },
  handleGetAllThreads: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getAllThreadsById(token, user_id));

    }

  },
  setBookedDates: (usersBookedDates) => {
    dispatch(setBookedDates(usersBookedDates));
  },
  handleSubmit: (editMode, start_date, end_date) => {
      console.log('submitStartEndDate:', `${start_date}-${end_date}`);
      // dispatch(submitAvailability(available_dates));
      const { handleDropdownClick } = props;
      handleDropdownClick();
      console.log('submit Date editMode:', editMode);
      if (editMode) {
        dispatch(saveEditStartDate(start_date));
        dispatch(saveEditEndDate(end_date));
      } else {
        dispatch(saveStartDate(start_date));
        dispatch(saveEndDate(end_date));
      }

  },
  handleDropdownClick: () => {
    const { handleDropdownClick } = props;
    handleDropdownClick();
  },
  handleClickBackButton: () => {
    const { handleClickBackButton } = props;
    handleClickBackButton();
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(SearchDatePicker);
