
import { store } from '../../init-store';
import history from '../../components/Navigation/history';
import { showModal, hideModal } from '../modal';
import { actionSuccess } from '../verify';
import { clearSearchQuery, setContactedUsers } from '../search';
import { closeDropdownNav, setThreadBack } from '../navbar';
import { createBookedDatesFromThreads } from '../calendar';
import { calcNoOfUnrepliedMessages } from '../../lib/inboxHelpers';
import { resetActionBar } from '../layout';
import { plan, sit_details } from '../../text';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR, MODAL_TYPE_CONFIRM_CANCEL, MODAL_TYPE_SUCCESS, MODAL_TYPE_CONFIRM } from '../../components/modals/modalTypes';
import { CONFIRM_APPROVE, CONFIRM_CANCEL, CONFIRM_PREAPPROVE, SUCCESS_PREAPPROVE, SUCCESS_APPROVE, SUCCESS_CANCEL, SUCCESS_SENT } from '../../components/modals/modalNames';
import { BOOKING } from '../../components/modals/modalCategory';
import * as ROUTES from '../../constants/routes';


export const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE';
export const SET_NEW_PLACEHOLDER = 'SET_NEW_PLACEHOLDER';
export const SET_NEW_LOCAL_THREAD = 'SET_NEW_LOCAL_THREAD';
export const CLEAR_THREAD = 'CLEAR_THREAD';
export const SEND_NEW_MESSAGE_REQUEST = 'SEND_NEW_MESSAGE_REQUEST';
export const SEND_NEW_MESSAGE_SUCCESS = 'SEND_NEW_MESSAGE_SUCCESS';
export const SEND_NEW_MESSAGE_FAILURE = 'SEND_NEW_MESSAGE_FAILURE';
export const GET_MESSAGE_THREAD_REQUEST = 'GET_MESSAGE_THREAD_REQUEST';
export const GET_MESSAGE_THREAD_SUCCESS = 'GET_MESSAGE_THREAD_SUCCESS';
export const GET_ALL_THREADS_REQUEST = 'GET_ALL_THREADS_REQUEST';
export const GET_ALL_THREADS_SUCCESS = 'GET_ALL_THREADS_SUCCESS';
export const GET_ALL_THREADS_FAILURE = 'GET_ALL_THREADS_FAILURE';
export const SIT_ACTION_REQUEST = 'SIT_ACTION_REQUEST';
export const SIT_ACTION_SUCCESS = 'SIT_ACTION_SUCCESS';
export const SIT_ACTION_FAILURE = 'SIT_ACTION_FAILURE';
export const CANCEL_ACTION_REQUEST = 'CANCEL_ACTION_REQUEST';
export const CANCEL_ACTION_SUCCESS = 'CANCEL_ACTION_SUCCESS';
export const CANCEL_ACTION_FAILURE = 'CANCEL_ACTION_FAILURE';

export const CANCEL_REQUEST_SIT_REQUEST = 'CANCEL_REQUEST_SIT_REQUEST';
export const CANCEL_REQUEST_SIT_SUCCESS = 'CANCEL_REQUEST_SIT_SUCCESS';
export const CANCEL_REQUEST_SIT_FAILURE = 'CANCEL_REQUEST_SIT_FAILURE';
export const PRE_APPROVE_SIT_REQUEST = 'PRE_APPROVE_SIT_REQUEST';
export const PRE_APPROVE_SIT_SUCCESS = 'PRE_APPROVE_SIT_SUCCESS';
export const PRE_APPROVE_SIT_FAILURE = 'PRE_APPROVE_SIT_FAILURE';

export const APPROVE_SIT_REQUEST = 'APPROVE_SIT_REQUEST';
export const APPROVE_SIT_SUCCESS = 'APPROVE_SIT_SUCCESS';
export const APPROVE_SIT_FAILURE = 'APPROVE_SIT_FAILURE';
export const CANCEL_APPROVE_SIT_REQUEST = 'CANCEL_APPROVE_SIT_REQUEST';
export const CANCEL_APPROVE_SIT_SUCCESS = 'CANCEL_APPROVE_SIT_SUCCESS';
export const CANCEL_APPROVE_SIT_FAILURE = 'CANCEL_APPROVE_SIT_FAILURE';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SEND_EDITED_DETAILS_REQUEST = 'SEND_EDITED_DETAILS_REQUEST';
export const SEND_EDITED_DETAILS_SUCCESS = 'SEND_EDITED_DETAILS_SUCCESS';
export const SEND_EDITED_DETAILS_FAILURE = 'SEND_EDITED_DETAILS_FAILURE';
export const UPDATE_PRE_AUTHORISE_REQUEST = 'UPDATE_PRE_AUTHORISE_REQUEST';
export const UPDATE_PRE_AUTHORISE_SUCCESS = 'UPDATE_PRE_AUTHORISE_SUCCESS';
export const UPDATE_PRE_AUTHORISE_FAILURE = 'UPDATE_PRE_AUTHORISE_FAILURE';
export const PRE_AUTHORISE_REQUEST = 'PRE_AUTHORISE_REQUEST';
export const PRE_AUTHORISE_SUCCESS = 'PRE_AUTHORISE_SUCCESS';
export const PRE_AUTHORISE_FAILURE = 'PRE_AUTHORISE_FAILURE';
export const FINALISE_SIT_REQUEST = 'FINALISE_SIT_REQUEST';
export const FINALISE_SIT_SUCCESS = 'FINALISE_SIT_SUCCESS';
export const FINALISE_SIT_FAILURE = 'FINALISE_SIT_FAILURE';
export const ADD_TRANSACTION_REQUEST = 'ADD_TRANSACTION_DETAILS_REQUEST';
export const ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_DETAILS_SUCCESS';
export const ADD_TRANSACTION_FAILURE = 'ADD_TRANSACTION_DETAILS_FAILURE';
export const UPDATE_MARK_READ_REQUEST = 'UPDATE_MARK_READ_REQUEST';
export const UPDATE_MARK_READ_SUCCESS = 'UPDATE_MARK_READ_SUCCESS';
export const UPDATE_MARK_READ_FAILURE = 'UPDATE_MARK_READ_FAILURE';
export const SET_ACTIVE_THREAD_ID = 'SET_ACTIVE_THREAD_ID';
export const SET_NO_OF_UNREAD_MESSAGES = 'SET_NO_OF_UNREAD_MESSAGES';

export const setNoOfUnreadMessages = data => ({
  type: SET_NO_OF_UNREAD_MESSAGES,
  data
});

export const setActiveThreadId = data => ({
  type: SET_ACTIVE_THREAD_ID,
  data
});

export function setEditMode (data) {
  return {
    type: SET_EDIT_MODE,
    data
  };
}

export function setNewLocalThread (data) {
  return {
    type: SET_NEW_LOCAL_THREAD,
    data
  };
}

export const clearThread = () => ({
  type: CLEAR_THREAD
});

export function createNewLocalThread (data) {

  return (dispatch) => {
    // sets up Thread Details ready for new request locally before submitting and sending to Server.

    // set thread_details back to initialState.
    dispatch(closeDropdownNav()); // ensures navbar is hidden and doesn't overlap thread
    dispatch(clearThread());
    dispatch(setNewLocalThread(data)); // need to do this first so that THREAD has props
    dispatch(setThreadBack(ROUTES.RESULTS)); // ensures backButton in Thread goes to search results or user profile and not to inbox

    history.push({
      pathname: ROUTES.THREAD
    });

  };
}


export function setNewMessage (data) {
  return {
    type: SET_NEW_MESSAGE,
    data
  };
}

export function setNewPlaceholder (data) {
  return {
    type: SET_NEW_PLACEHOLDER,
    data
  };
}

// export const submitSendEditedDetailsRequest = () => ({
//   type: SEND_EDITED_DETAILS_REQUEST
// });
//
// export const submitSendEditedDetailsSuccess = data => ({
//   type: SEND_EDITED_DETAILS_SUCCESS,
//   data
// });
//
// export const submitSendEditedDetailsFailure = error => ({
//   type: SEND_EDITED_DETAILS_FAILURE,
//   error
// });

export const submitSendNewMessageRequest = () => ({
  type: SEND_NEW_MESSAGE_REQUEST
});

export const submitSendNewMessageSuccess = data => ({
  type: SEND_NEW_MESSAGE_SUCCESS,
  data
});

export const submitSendNewMessageFailure = error => ({
  type: SEND_NEW_MESSAGE_FAILURE,
  error
});

export const markMessageReadRequest = () => ({
  type: UPDATE_MARK_READ_REQUEST
});

export const markMessageReadSuccess = data => ({
  type: UPDATE_MARK_READ_SUCCESS,
  data // not wired to redux (unless see a need)
});

export const markMessageReadFailure = error => ({
  type: UPDATE_MARK_READ_FAILURE,
  error
});

export const getMessageThreadRequest = () => ({
  type: GET_MESSAGE_THREAD_REQUEST
});

export const getMessageThreadSuccess = data => ({
  type: GET_MESSAGE_THREAD_SUCCESS,
  data
});

export const getAllThreadsRequest = () => ({
  type: GET_ALL_THREADS_REQUEST
});

export const getAllThreadsSuccess = data => ({
  type: GET_ALL_THREADS_SUCCESS,
  data
});

export const getAllThreadsFailure = error => ({
  type: GET_ALL_THREADS_FAILURE,
  error
});

export const sitActionRequest = () => ({
  type: SIT_ACTION_REQUEST
});

export const sitActionSuccess = data => ({
  type: SIT_ACTION_SUCCESS,
  data
});

export const sitActionFailure = error => ({
  type: SIT_ACTION_FAILURE,
  error
});

export const cancelActionRequest = () => ({
  type: CANCEL_ACTION_REQUEST
});

export const cancelActionSuccess = data => ({
  type: CANCEL_ACTION_SUCCESS,
  data
});

export const cancelActionFailure = error => ({
  type: CANCEL_ACTION_FAILURE,
  error
});

export const cancelRequestSitRequest = () => ({
  type: CANCEL_REQUEST_SIT_REQUEST
});

export const cancelRequestSitSuccess = data => ({
  type: CANCEL_REQUEST_SIT_SUCCESS,
  data
});

export const cancelRequestSitFailure = error => ({
  type: CANCEL_REQUEST_SIT_FAILURE,
  error
});

export const preApproveSitRequest = () => ({
  type: PRE_APPROVE_SIT_REQUEST
});

export const preApproveSitSuccess = data => ({
  type: PRE_APPROVE_SIT_SUCCESS,
  data
});

export const preApproveSitFailure = error => ({
  type: PRE_APPROVE_SIT_FAILURE,
  error
});


export const cancelApproveSitRequest = () => ({
  type: CANCEL_APPROVE_SIT_REQUEST
});

export const cancelApproveSitSuccess = data => ({
  type: CANCEL_APPROVE_SIT_SUCCESS,
  data
});

export const cancelApproveSitFailure = error => ({
  type: CANCEL_APPROVE_SIT_FAILURE,
  error
});

export const preAuthoriseRequest = () => ({
  type: UPDATE_PRE_AUTHORISE_REQUEST
});

export const preAuthoriseSuccess = data => ({
  type: UPDATE_PRE_AUTHORISE_SUCCESS,
  data
});

export const preAuthoriseFailure = error => ({
  type: UPDATE_PRE_AUTHORISE_FAILURE,
  error
});


export const finaliseSitRequest = () => ({
  type: FINALISE_SIT_REQUEST
});

export const finaliseSitSuccess = data => ({
  type: FINALISE_SIT_SUCCESS,
  data
});

export const finaliseSitFailure = error => ({
  type: FINALISE_SIT_FAILURE,
  error
});

export const addTransactionRequest = () => ({
  type: ADD_TRANSACTION_REQUEST
});

export const addTransactionSuccess = data => ({
  type: ADD_TRANSACTION_SUCCESS,
  data
});

export const addTransactionFailure = error => ({
  type: ADD_TRANSACTION_FAILURE,
  error
});

export function getAllThreadsById (token, user_id) {
  return (dispatch) => {

    dispatch(getAllThreadsRequest());
    console.log('getAllThreads action for user_id: ', user_id);

    fetch(`${process.env.REACT_APP_API_URI}/users/all_threads/${user_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    })
    .then(CheckError)
    .then((data) => {
      console.log('getAllThreads response data:', data);

      dispatch(getAllThreadsSuccess(data));

      dispatch(createBookedDatesFromThreads(user_id, data)); // populates users' bookedDates in state based on Threads

      const numberOfUnreadMessages = calcNoOfUnrepliedMessages(data, user_id).numberOfUnreadMessages;
      dispatch(setNoOfUnreadMessages(numberOfUnreadMessages));

    })
    .catch((err) => {
      console.log('caught getAllThreads error: ', err);
      dispatch(getAllThreadsFailure(err));

      // centralise bugsnag reporting
      const error = CustomException(!err.code ? null : err.code, err.message, {}, dispatch);
      if (error.code !== null) {
        dispatch(showModal(
          MODAL_TYPE_ERROR,
          { // modalProps
            // title: sit_details.modal.fetching_thread.failure.title,
            // header: sit_details.modal.fetching_thread.failure.header,
            // message: sit_details.modal.fetching_thread.failure.para,
            // message2: sit_details.modal.fetching_thread.failure.para2,
            error_status: err.code && err.code,
            error_message: err.message
          }
        ));
      }


      dispatch(actionSuccess());
    });
  };
}


export function getMessageThread (token, message_thread_id, nextRoute) {


  console.log('getMessageThread state:', store.getState().user);
  const user_id = store.getState().user.user_id;
  const home_address = store.getState().user.home_address;

  return (dispatch) => {

    // dispatch(showModal(  // don't show Spinner as closes send message success modal before the user has a chance to read it.
    //   MODAL_TYPE_SPINNER,
    //   { // modalProps
    //     message: sit_details.modal.fetching_thread.fetching
    //   }
    // ));

    dispatch(closeDropdownNav());

    dispatch(getMessageThreadRequest());

    // delay a few seconds to let dropdown close

    setTimeout(() => {
      console.log('getMessageThread action for message_thread_id: ', message_thread_id);

      fetch(`${process.env.REACT_APP_API_URI}/users/message_thread/${message_thread_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: token,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        },
        body: JSON.stringify({ user_id, home_address })
      })
      .then(CheckError)
      .then((data) => {
        console.log('getMessageThread response data:', data);

        dispatch(getMessageThreadSuccess(data));
        // dispatch(hideModal()); // don't hideModal as makes send message success modal disappear before the user has chance to read it.

        if (nextRoute) {
          history.push({
            pathname: nextRoute
          });
        }

      })
      .catch((err) => {
        console.log('caught getMessageThread error: ', err);
        dispatch(sitActionFailure(err.message));

        // centralise bugsnag reporting
        ReportError(!err.code ? null : err.code, err.message, {});
        dispatch(showModal(
          MODAL_TYPE_ERROR,
          { // modalProps
            title: sit_details.modal.fetching_thread.failure.title,
            header: sit_details.modal.fetching_thread.failure.header,
            message: sit_details.modal.fetching_thread.failure.para,
            message2: sit_details.modal.fetching_thread.failure.para2,
            error_status: err.code && err.code,
            error_message: err.message
          }
        ));

        dispatch(actionSuccess());

        // if error fetching thread, go to Inbox
        // check if want to feed in through nextRoute
        history.push({
          pathname: ROUTES.INBOX
        });


      });

    }, 500);


  };
}

export function sendEditedDetails (token, messageParams, thread_id, thread_details, user_id) {

  console.log('sendEditedDetails action:');
  console.log(`thread_id: ${thread_id}, thread_details: ${thread_details}`);

  return (dispatch) => {
    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: sit_details.modal.send_edited.fetching
      }
    ));
    dispatch(sitActionRequest()); // check
    // dispatch(submitSendEditedDetailsRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/message/edit`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        thread_id,
        thread_details,
        user_id,
        message_params: messageParams
      })
    })
    .then(CheckError)
    .then((data) => {

      // dispatch(preApproveSitSuccess(data.thread_id));
      // dispatch(submitSendEditedDetailsSuccess(data));
      dispatch(sitActionSuccess(data.thread_id)); // check

      const message_thread_id = data.thread_id;
      dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));

      // dispatch(clearSearchQuery());
      dispatch(setEditMode(false));
      dispatch(resetActionBar()); // resets MessageBar and ActionBar

      dispatch(hideModal());
      // show modal showing pre-approval success/confirmed booking.

      const modalText = sit_details.modal.send_edited.success;
        // // thread_stage === 'request' ? sit_details.modal.request.success :
        // thread_stage === 'pre_approve' ? sit_details.modal.pre_approve.success :
        // thread_stage === 'pre_authorise' ? sit_details.modal.pre_authorise.success :
        // thread_stage === 'finalise' ? sit_details.modal.finalise.success :
        // thread_stage === 'review' ? reviews.modal.success :

        // '';


      const other_user_name = messageParams.thread_details.sitter_name; // to check when finalising that this is still sitter?
      const points_adjustment = false;
      const para = `${modalText.para}${other_user_name ?
        `${other_user_name}${modalText.para2 && modalText.para2 !== undefined ? modalText.para2 : ''}`
        : points_adjustment ?
          `${points_adjustment}${modalText.para2 && modalText.para2 !== undefined ? modalText.para2 : ''}`
          : ''}`;
      const para2 = !points_adjustment && !other_user_name && modalText.para2 && modalText.para2 !== undefined && modalText.para2;

      const newModalText = { ...modalText, para: para, para2: para2 };


      dispatch(showModal(
        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: BOOKING,
          modalName: SUCCESS_PREAPPROVE,
          modalText: newModalText,
          okAction: () => dispatch(hideModal()),
          backAction: null,

          thread_stage: 'edited_details',
          // user_type,
          // other_user_name: messageParams.thread_details.sitter_name // to check when finalising that this is still sitter?
        }

      ));
      dispatch(actionSuccess()); // triggers success icon

      console.log('second thread route push');
      history.push({
        pathname: ROUTES.THREAD,
        state: { editMode: false }
      });


      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
    })
    .catch((err) => {
      // dispatch(submitSendEditedDetailsFailure(err.message));
      dispatch(sitActionFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: sit_details.modal.send_edited.failure.title,
          header: sit_details.modal.send_edited.failure.header,
          message: sit_details.modal.send_edited.failure.para,
          message2: sit_details.modal.send_edited.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}


export function sendNewSitTransaction (token, user_id, thread_details, sitter_name, requester_name) {


  const { thread_id, sitter_id, requester_id, start_date, end_date, start_time, end_time, kids, quoted_duration, actual_duration, tariff_id, sp_quoted, sp_actual } = thread_details;

  const transaction_type = 'Sit';

  const description = ''; // not used

  // to create for bonsues and top-ups/payments;
  const bonus_id = 1;
  const bonus_name = 'Complete profile';
  const currency_amount = 0;
  const transaction_currency = '';


  console.log('sendNewTransaction action:');

  const transaction = {
    transaction_type,
    description,
    bonus_id,
    bonus_name,
    thread_id,
    sitter_id,
    requester_id,
    sitter_name,
    requester_name,
    start_date,
    end_date,
    start_time,
    end_time,
    kids,
    quoted_duration,
    actual_duration,
    tariff_id,
    sp_quoted,
    sp_actual,
    currency_amount,
    transaction_currency
  };

  return (dispatch) => {
    // dispatch(showModal(MODAL_TYPE_SPINNER, {
    //
    // }));

    dispatch(addTransactionRequest());

    // dispatch(closeDropdownNav()); is this needed?

    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/transaction/${user_id}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({ transaction })
    })
    .then((response) => {
      console.log('addTransaction response:', response);
      response.json()
      .then((data) => {
        dispatch(addTransactionSuccess(data)); // to create

        dispatch(getMessageThread(token, user_id, ROUTES.THREAD)); // replace with get transaction history

        dispatch(hideModal());

        // history.push({
        //   pathname: ROUTES.PROFILE_SUMMARY,
        //   state: { editMode: false }
        // });


        // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
      })
      .catch((err) => {
        dispatch(addTransactionFailure(err.message));
        dispatch(hideModal());
      });
    })
    .catch((error) => {
      dispatch(addTransactionFailure(error.message));
      dispatch(hideModal());
    });
  };
}


export function sendNewMessage (token, messageParams, thread_id, user_id, isFirstMessage) {

  console.log('sendNewMessage action:');
  console.log(`thread_id: ${thread_id}`);


  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        // message: sit_details.modal.cancel.fetching
      }
    ));

    // dispatch(closeDropdownNav()); is this needed?

    dispatch(submitSendNewMessageRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/message`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        thread_id,
        user_id,
        message_params: messageParams
      })
    })
    .then(CheckError)
    .then((data) => {

      if (isFirstMessage) {
        // need to isolate to only do for first message in new thread
        const user_item = {
          user_id: messageParams.thread_details.sitter_id,
          thread_id: data.thread_id
        };
        dispatch(setContactedUsers(user_item)); // adds to array of contacted users for search, clears on new search.
      }

      dispatch(submitSendNewMessageSuccess(data.message_id));
      dispatch(resetActionBar()); // resets MessageBar and ActionBar
      dispatch(setEditMode(false));

      const message_thread_id = data.thread_id;
      if (isFirstMessage) {
        // first message, so go back to search results to contact other sitters
        history.push({
          pathname: ROUTES.RESULTS,
          state: { editMode: false }
        });
      } else {
        // not first message, so go back to thread
        dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));

      }


      // dispatch(hideModal());  // don't want this as modal disappears too quickly after sending message
      // show modal showing message sent success.

      const modalText = sit_details.modal.message.success;

      const other_user_name = user_id === messageParams.thread_details.sitter_id ? messageParams.thread_details.requester_name : messageParams.thread_details.sitter_name;

      const para = `${modalText.para}${other_user_name
        ?
        ` ${modalText.para2 && modalText.para2 !== undefined ? modalText.para2 : ''} ${other_user_name}.`
        : ''}`;
      const para2 = !other_user_name && modalText.para2 && modalText.para2 !== undefined && `${modalText.para2}.`;

      const newModalText = { ...modalText, para: para, para2: para2 };
      console.log('newModalText:', newModalText);

      dispatch(showModal(

        MODAL_TYPE_SUCCESS,
        {
          modalCategory: BOOKING,
          modalName: SUCCESS_SENT,
          modalText: newModalText,
          okAction: () => dispatch(hideModal()),
          backAction: null

          // thread_stage: '',
          // user_type,
          // other_user_name: messageParams.thread_details.requester_name
        }
      ));
      dispatch(actionSuccess()); // triggers success icon

    })
    .catch((err) => {
      dispatch(submitSendNewMessageFailure(err.message));

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: sit_details.modal.message.failure.title,
          header: sit_details.modal.message.failure.header,
          message: sit_details.modal.message.failure.para,
          message2: sit_details.modal.message.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });
  };
}

export function markMessageRead (token, message_id, user_id) {

  console.log('markMessageRead action:');


  return (dispatch) => {

    dispatch(markMessageReadRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/message/mark_read`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({ message_id, user_id })
    })
    .then((response) => {
      console.log('markMessageRead response:', response);
      response.json()
      .then((data) => {
        dispatch(markMessageReadSuccess(data.message_id));
        dispatch(getAllThreadsById(token, user_id));

      })
      .catch((err) => {
        dispatch(markMessageReadFailure(err.message));
      });
    })
    .catch((error) => {
      dispatch(markMessageReadFailure(error.message));
    });
  };
}


export function cancelRequestSit (token, thread_id, user_id) {

  console.log('cancelRequestSit action:');
  console.log(`thread_id: ${thread_id}`);


  return (dispatch) => {
    // dispatch(showModal(MODAL_TYPE_SPINNER, {
    //
    // }));

    dispatch(cancelRequestSitRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/message/cancel_request`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({ thread_id, user_id })
    })
    .then((response) => {
      console.log('cancelRequestSit response:', response);
      response.json()
      .then((data) => {
        dispatch(cancelRequestSitSuccess(data.thread_id));
        const message_thread_id = data.thread_id;
        dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));

        dispatch(hideModal());

        // history.push({
        //   pathname: ROUTES.PROFILE_SUMMARY,
        //   state: { editMode: false }
        // });


        // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
      })
      .catch((err) => {
        dispatch(cancelRequestSitFailure(err.message));
        dispatch(hideModal());
      });
    })
    .catch((error) => {
      dispatch(cancelRequestSitFailure(error.message));
      dispatch(hideModal());
    });
  };
}

// launches confirm modal
export function confirmPreApproveSit (token, messageParams, thread_id, user_id) {

  return (dispatch) => {

    const okAction = () => {
        console.log('confirmPreApproveSit okAction:');
      dispatch(okPreApproveSit(token, messageParams, thread_id, user_id));
    };

    // dispatch(showModal(MODAL_TYPE_CONFIRM_PREAPPROVE, {
    //   okAction: () => okAction(),
    //   backAction: null,
    //   cancelAction: () => dispatch(hideModal()),
    //   thread_stage: 'pre_approve'
    // }));


    dispatch(showModal(MODAL_TYPE_CONFIRM, {
      modalCategory: BOOKING,
      modalName: CONFIRM_PREAPPROVE,
      modalText: sit_details.modal.pre_approve.confirm,
      okAction: () => okAction(),
      backAction: null,
      cancelAction: () => dispatch(hideModal()),
      thread_stage: 'pre_approve'
    }));


  };
}


export function okPreApproveSit (token, messageParams, thread_id, user_id) {

  console.log('okPreApproveSit action:');
  // console.log(`thread_id: ${thread_id}`);

  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: sit_details.modal.pre_approve.fetching
      }
    ));

    dispatch(preApproveSitRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/message/pre_approve`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        thread_id,
        user_id,
        message_params: messageParams
      })
    })
    .then(CheckError)
    .then((data) => {

      dispatch(preApproveSitSuccess(data.thread_id));

      const message_thread_id = data.thread_id;
      dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));
      dispatch(resetActionBar()); // resets MessageBar and ActionBar
      dispatch(hideModal());
      // show modal showing pre-approval success/confirmed booking.

      const modalText = sit_details.modal.pre_approve.success;

      const other_user_name = messageParams.thread_details.requester_name; // to check when finalising that this is still sitter?
      const points_adjustment = false;
      const para = `${modalText.para}${other_user_name ?
        `${other_user_name}${modalText.para2 && modalText.para2 !== undefined ? modalText.para2 : ''}`
        : points_adjustment ?
          `${points_adjustment}${modalText.para2 && modalText.para2 !== undefined ? modalText.para2 : ''}`
          : ''}`;
      const para2 = !points_adjustment && !other_user_name && modalText.para2 && modalText.para2 !== undefined && modalText.para2;

      const newModalText = { ...modalText, para: para, para2: para2 };


      dispatch(showModal(

        MODAL_TYPE_SUCCESS,
        {
          modalCategory: BOOKING,
          modalName: SUCCESS_PREAPPROVE,
          modalText: newModalText,
          okAction: () => dispatch(hideModal()),
          backAction: null,

          thread_stage: 'pre_approve',
          // user_type,
          // other_user_name: messageParams.thread_details.requester_name
        }
      ));
      dispatch(actionSuccess()); // triggers success icon

      console.log('second thread route push');
      history.push({
        pathname: ROUTES.THREAD,
        state: { editMode: false }
      });


      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
    })
    .catch((err) => {
      dispatch(preApproveSitFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: sit_details.modal.pre_approve.failure.title,
          header: sit_details.modal.pre_approve.failure.header,
          message: sit_details.modal.pre_approve.failure.para,
          message2: sit_details.modal.pre_approve.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}

// launches confirm modal
export function confirmCancelAction (token, messageParams, thread_id, user_id, thread_stage, user_type) {
  // need to add review if cancelled after pre-approval
  return (dispatch) => {

    const okAction = () => {
        console.log('confirmCancelAction okAction:');
      dispatch(okCancelAction(token, messageParams, thread_id, user_id, thread_stage, user_type));
    };

    // dispatch(showModal(MODAL_TYPE_CONFIRM_CANCEL, {
    //   okAction: () => okAction(),
    //   backAction: null,
    //   cancelAction: () => dispatch(hideModal()),
    //   thread_stage,
    //   user_type,
    //   other_user_name: user_type === 'sitter' ? messageParams.thread_details.requester_name : messageParams.thread_details.sitter_name
    // }));

    dispatch(showModal(MODAL_TYPE_CONFIRM_CANCEL, {
      // showClose: true,
      modalCategory: BOOKING,
      modalName: CONFIRM_CANCEL,
      // modalText: ,
      okAction: () => okAction(),
      backAction: null,
      cancelAction: () => dispatch(hideModal()),
      thread_stage,
      user_type,
      other_user_name: user_type === 'sitter' ? messageParams.thread_details.requester_name : messageParams.thread_details.sitter_name
    }));

  };
}

export function okCancelAction (token, messageParams, thread_id, user_id, thread_stage, user_type) {

  console.log('cancelAction action:');
  console.log(`thread_id: ${thread_id}`);
  console.log(`thread_stage: ${thread_stage}`);
  console.log(`user_type: ${user_type}`);


  return (dispatch) => {
    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: sit_details.modal.cancel.fetching
      }
    ));

    dispatch(cancelActionRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/message/cancel`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        thread_id,
        user_id,
        message_params: messageParams,
        thread_stage
      })
    })
    .then(CheckError)
    .then((data) => {
      dispatch(cancelActionSuccess(data.thread_id));


      const message_thread_id = data.thread_id;
      dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));

      dispatch(hideModal());
      dispatch(resetActionBar()); // resets MessageBar and ActionBar


      // show modal showing Payment success/confirmed booking.


      // dispatch(showModal(
      //   MODAL_TYPE_SUCCESS_CANCEL,
      //   { // modalProps
      //     thread_stage,
      //     user_type,
      //     other_user_name: user_type === 'sitter' ? messageParams.thread_details.requester_name : messageParams.thread_details.sitter_name
      //   }
      // ));

      const modalText = (
        thread_stage === 'request' ? sit_details.modal.cancel.request :
        thread_stage === 'pre_approved' ? sit_details.modal.cancel.pre_approved :
        thread_stage === 'approved' ? sit_details.modal.cancel.approved :
        '');

      // console.log('user_type', user_type);
      // console.log('modalText', modalText);
      const textType = user_type === 'sitter' ? modalText.sitter.success : modalText.requester.success;

      const other_user_name = user_type === 'sitter' ? messageParams.thread_details.requester_name : messageParams.thread_details.sitter_name;
      const newPara = `${textType.para}${other_user_name}`;

      const newModalText = { ...textType, para: newPara };

      dispatch(showModal(
        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: BOOKING,
          modalName: SUCCESS_CANCEL,
          modalText: newModalText,
          okAction: () => dispatch(hideModal()),
          backAction: null,

          thread_stage,
          user_type

        }

      ));

      dispatch(actionSuccess()); // triggers success icon

      console.log('second thread route push');
      history.push({
        pathname: ROUTES.THREAD,
        state: { editMode: false }
      });


      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
    })

    .catch((err) => {
      dispatch(cancelActionFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      const modalText = (
        thread_stage === 'request' ? sit_details.modal.cancel.request :
        thread_stage === 'pre_approved' ? sit_details.modal.cancel.pre_approve :
        thread_stage === 'approved' ? sit_details.modal.cancel.approve :

        '');
      const textType = user_type === 'sitter' ? modalText.sitter.failure : modalText.requester.failure;
      const { title, header, para, para2 } = textType;

      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title,
          header,
          message: para,
          message2: para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}

// launches confirm modal
export function confirmApproveSit (token, messageParams, thread_id, user_id, payment_type, paid) {

  return (dispatch) => {

    const okAction = () => {
      if (!paid) {
        console.log('okAction');
        // if not subscriber or paid already, then go to collect payment
        return history.push({
          pathname: ROUTES.SUBSCRIBE_TO_CONTACT,
          state: {
            messageParams
          }
        });
      } // else
        dispatch(okApproveSit(token, messageParams, thread_id, user_id, payment_type));
    };

    // dispatch(showModal(MODAL_TYPE_CONFIRM_APPROVE, {
    //   okAction: () => okAction(),
    //   backAction: null,
    //   cancelAction: () => dispatch(hideModal())
    // }));
    dispatch(showModal(MODAL_TYPE_CONFIRM, {
      modalCategory: BOOKING,
      modalName: CONFIRM_APPROVE,
      modalText: sit_details.modal.approve.confirm,
      okAction: () => okAction(),
      backAction: null,
      cancelAction: () => dispatch(hideModal())
    }));


  };


}

// approval now launced from server after successful payment.
// this is only for existing member approvals - to rework

export function okApproveSit (token, messageParams, thread_id, user_id, payment_type) {

  console.log('okApproveSit action:');
  console.log(`thread_id: ${thread_id}`);


  return (dispatch) => {
    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        // message: planText.modal.payg_payment.fetching
      }
    ));

    dispatch(sitActionRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/message/approve`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params  // bonus_id, bonus_name unused
      body: JSON.stringify({
          thread_id,
          user_id,
          message_params: messageParams
      })
    })
    .then(CheckError)
    .then((data) => {
      // dispatch(approveSitSuccess(data.thread_id));
      dispatch(sitActionSuccess(data.thread_id)); // check


      const message_thread_id = data.thread_id;
      dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));

      dispatch(hideModal());
      dispatch(resetActionBar()); // resets MessageBar and ActionBar
      // show modal showing Payment success/confirmed booking.

      const modalText =
        payment_type === 'sit_payg' ? plan.modal.payg_payment.success
      : payment_type === 'sit_subscription' ? sit_details.modal.approve.success.sub
      : payment_type === 'sit_existing_member' ? sit_details.modal.approve.success.member
      : payment_type === 'subscription' ? plan.modal.subscribe.success
      : '';


      dispatch(showModal(

        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: BOOKING,
          modalName: SUCCESS_APPROVE,
          modalText: modalText,
          okAction: () => dispatch(hideModal()),
          backAction: null,

          payment_type

        }
      ));
      dispatch(actionSuccess()); // triggers success icon

      console.log('second thread route push');
      history.push({
        pathname: ROUTES.THREAD,
        state: { editMode: false }
      });


      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
    })

    .catch((err) => {
      dispatch(sitActionFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: payment_type === 'payg' ? sit_details.modal.approve.failure.payg.title : payment_type === 'sit_existing_member' ? sit_details.modal.approve.failure.sub.title : sit_details.modal.approve.failure.member.title,
          header: payment_type === 'payg' ? sit_details.modal.approve.failure.payg.header : payment_type === 'sit_existing_member' ? sit_details.modal.approve.failure.sub.header : sit_details.modal.approve.failure.member.header,
          message: payment_type === 'payg' ? sit_details.modal.approve.failure.payg.para : payment_type === 'sit_existing_member' ? sit_details.modal.approve.failure.sub.para : sit_details.modal.approve.failure.member.para,
          message2: payment_type === 'payg' ? sit_details.modal.approve.failure.payg.para2 : payment_type === 'sit_existing_member' ? sit_details.modal.approve.failure.sub.para2 : sit_details.modal.approve.failure.member.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });
  };
}

export function cancelApproveSit (token, thread_id, user_id) {

  console.log('cancelApproveSit action:');
  console.log(`thread_id: ${thread_id}`);


  return (dispatch) => {
    // dispatch(showModal(MODAL_TYPE_SPINNER, {
    //
    // }));

    dispatch(cancelApproveSitRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/message/cancel_approve`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({ thread_id, user_id })
    })
    .then((response) => {
      console.log('cancelApprove response:', response);
      response.json()
      .then((data) => {
        dispatch(cancelApproveSitSuccess(data.thread_id));
        const message_thread_id = data.thread_id;
        dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));

        dispatch(hideModal());
        dispatch(resetActionBar()); // resets MessageBar and ActionBar

        // history.push({
        //   pathname: ROUTES.PROFILE_SUMMARY,
        //   state: { editMode: false }
        // });


        // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
      })
      .catch((err) => {
        dispatch(cancelApproveSitFailure(err.message));
        dispatch(hideModal());
      });
    })
    .catch((error) => {
      dispatch(cancelApproveSitFailure(error.message));
      dispatch(hideModal());
    });
  };
}

// launches confirm modal
export function confirmPreAuthorise (token, messageParams, thread_id, user_id, thread_details) {

  return (dispatch) => {

    const okAction = () => {
        console.log('confirmPreAuthorise okAction:');
      dispatch(okPreAuthorise(token, messageParams, thread_id, user_id, thread_details));
    };


    const modalText = sit_details.modal.pre_authorise.confirm;
    // const modalText = sit_details.modal.finalise.confirm;

    const other_user_name = messageParams.thread_details.requester_name;
    const newPara = `${modalText.para} ${other_user_name} ${modalText.para1a}`;

    const newModalText = { ...modalText, para: newPara };

    dispatch(showModal(MODAL_TYPE_CONFIRM, {
      modalCategory: BOOKING,
      modalName: CONFIRM_PREAPPROVE,
      modalText: newModalText,
      okAction: () => okAction(),
      backAction: null,
      cancelAction: () => dispatch(hideModal()),
      thread_stage: 'pre_authorise'
    }));
  };
}


export function okPreAuthorise (token, messageParams, thread_id, user_id, thread_details) {

  console.log('okPreApproveSit action:');
  // console.log(`thread_id: ${thread_id}`);

  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: sit_details.modal.pre_authorise.fetching
      }
    ));

    dispatch(sitActionRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/message/update_pre_authorised`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        thread_id,
        user_id,
        message_params: messageParams,
        thread_details
      })
    })
    .then(CheckError)
    .then((data) => {

      dispatch(sitActionSuccess(data.thread_id)); // check

      const message_thread_id = data.thread_id;
      dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));

      dispatch(hideModal());

      // show modal showing pre-approval success/confirmed booking.


      const modalText = sit_details.modal.pre_authorise.success;

      const other_user_name = messageParams.thread_details.requester_name;
      const points_adjustment = false;
      const para = `${modalText.para}${other_user_name ?
        `${other_user_name}${modalText.para2 && modalText.para2 !== undefined ? modalText.para2 : ''}`
        : points_adjustment ?
          `${points_adjustment}${modalText.para2 && modalText.para2 !== undefined ? modalText.para2 : ''}`
          : ''}`;
      const para2 = !points_adjustment && !other_user_name && modalText.para2 && modalText.para2 !== undefined && modalText.para2;

      const newModalText = { ...modalText, para: para, para2: para2 };


      dispatch(showModal(
        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: BOOKING,
          modalName: SUCCESS_PREAPPROVE,
          modalText: newModalText,
          okAction: () => dispatch(hideModal()),
          backAction: null,

          thread_stage: 'pre_authorise',
          // user_type,
          // other_user_name: messageParams.thread_details.requester_name
        }
      ));


      dispatch(actionSuccess()); // triggers success icon
      dispatch(clearSearchQuery());
      dispatch(resetActionBar()); // resets MessageBar and ActionBar
      dispatch(setEditMode(false));

      console.log('second thread route push');
      history.push({
        pathname: ROUTES.THREAD,
        state: { editMode: false }
      });


      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
    })
    .catch((err) => {
      dispatch(sitActionFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: sit_details.modal.pre_authorise.failure.title,
          header: sit_details.modal.pre_authorise.failure.header,
          message: sit_details.modal.pre_authorise.failure.para,
          message2: sit_details.modal.pre_authorise.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}

// export function finaliseSit (token, thread_id, user_id, sp_actual) {
//
//   console.log('finaliseSit action:');
//   console.log(`thread_id: ${thread_id}`);
//
//
//   return (dispatch) => {
//     // dispatch(showModal(MODAL_TYPE_SPINNER, {
//     //
//     // }));
//
//     dispatch(finaliseSitRequest());
//     console.log('submitted Action');
//     fetch(`${process.env.REACT_APP_API_URI}/message/finalise/${thread_id}`, {
//       method: 'PATCH',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         authorization: token
//       },
//       // replace with new params
//       body: JSON.stringify({ Approval: { thread_id, user_id, sp_actual } })
//     })
//     .then((response) => {
//       console.log('Finalise response:', response);
//       response.json()
//       .then((data) => {
//         dispatch(finaliseSitSuccess(data.thread_id));
//         const message_thread_id = data.thread_id;
//         dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));
//
//         dispatch(hideModal());
//         // show modal showing Payment success/confirmed booking.
//         const pointVariance = -data.sp_adj;
//         console.log('data:', data);
//         console.log('data.sp_adj:', data.sp_adj);
//         console.log('data.sp:', data.sp);
//         console.log('pointVariance:', pointVariance);
//         dispatch(showModal(MODAL_TYPE_FINALISED, {
//           points_adjustment: pointVariance
//         }));
//
//         history.push({
//           pathname: ROUTES.THREAD,
//           state: { editMode: false }
//         });
//
//
//         // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
//       })
//       .catch((err) => {
//         dispatch(finaliseSitFailure(err.message));
//         dispatch(hideModal());
//       });
//     })
//     .catch((error) => {
//       dispatch(finaliseSitFailure(error.message));
//       dispatch(hideModal());
//     });
//   };
// }

// launches confirm modal
export function confirmFinalise (token, messageParams, thread_id, user_id, thread_details) {

  return (dispatch) => {

    const okAction = () => {
        console.log('confirmFinalise okAction:');
      dispatch(okFinalise(token, messageParams, thread_id, user_id, thread_details));
    };


    const modalText = sit_details.modal.finalise.confirm;

    // const other_user_name = messageParams.thread_details.requester_name;
    // const newPara = `${modalText.para} ${other_user_name}`;

    // const newModalText = { ...modalText, para: newPara };

    dispatch(showModal(MODAL_TYPE_CONFIRM, {
      modalCategory: BOOKING,
      modalName: CONFIRM_PREAPPROVE,
      modalText: modalText,
      okAction: () => okAction(),
      backAction: null,
      cancelAction: () => dispatch(hideModal()),
      thread_stage: 'finalise'
    }));


  };
}


export function okFinalise (token, messageParams, thread_id, user_id, thread_details) {

  console.log('okFinalise action:');
  console.log('thread_details:', thread_details);
  // console.log(`thread_id: ${thread_id}`);

  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: sit_details.modal.finalise.fetching
      }
    ));

    dispatch(sitActionRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/message/finalise`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        thread_id,
        user_id,
        message_params: messageParams,
        thread_details
      })
    })
    .then(CheckError)
    .then((data) => {

      dispatch(sitActionSuccess(data.thread_id)); // check

      const message_thread_id = data.thread_id;
      dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));

      dispatch(hideModal());
      // show modal showing pre-approval success/confirmed booking.

      const pointVariance = -data.sp_adj;
      // console.log('data:', data);
      // console.log('data.sp_adj:', data.sp_adj);
      // console.log('data.sp:', data.sp);
      // console.log('pointVariance:', pointVariance);

      const modalText = sit_details.modal.finalise.success;

      // const other_user_name = messageParams.thread_details.sitter_name; // to check when finalising that this is still sitter?
      const points_adjustment = pointVariance;
      const para = `${modalText.para}${
        points_adjustment ?
          `${modalText.an_additional}${points_adjustment}${modalText.para2 && modalText.para2 !== undefined ? modalText.para2 : ''}`
          : ''}`;
      const para2 = !points_adjustment && modalText.para2 && modalText.para2 !== undefined && modalText.para2;

      const newModalText = { ...modalText, para: para, para2: para2 };


      dispatch(showModal(
        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: BOOKING,
          modalName: SUCCESS_PREAPPROVE,
          modalText: newModalText,
          okAction: () => dispatch(hideModal()),
          backAction: null,

          thread_stage: 'finalise',
          // points_adjustment: pointVariance,
          // user_type,
          // other_user_name: messageParams.thread_details.sitter_name // to check when finalising that this is still sitter?
        }

      ));

      dispatch(actionSuccess()); // triggers success icon
      dispatch(clearSearchQuery());
      dispatch(resetActionBar()); // resets MessageBar and ActionBar
      dispatch(setEditMode(false));

      console.log('second thread route push');
      history.push({
        pathname: ROUTES.THREAD,
        state: { editMode: false }
      });


      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
    })
    .catch((err) => {
      dispatch(sitActionFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: sit_details.modal.finalise.failure.title,
          header: sit_details.modal.finalise.failure.header,
          message: sit_details.modal.finalise.failure.para,
          message2: sit_details.modal.finalise.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}
