/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch, faSearchPlus, faMagic, faHandshake } from '@fortawesome/free-solid-svg-icons';
// import '@trendmicro/react-buttons/dist/react-buttons.css';
// import '@trendmicro/react-dropdown/dist/react-dropdown.css';
// import 'react-dropdown/style.css';
// import 'react-select/dist/react-select.css';
import history from '../Navigation/history';
import { NavbarButton, ConfirmButton, ViewMessagesButton, Row, MenuButton, Column, NavButton, BurgerWrapper } from '../../styles/styles';
import { D4, H3, T4, H5, B5, ConfirmButtonText } from '../../styles/text';
import colours from '../../styles/colours';
import { nav } from '../../text';
import * as ROUTES from '../../constants/routes';


export default class OpenSearchButton extends Component {


  toggleSearchBanner (bannerIsOpen) {

    const { showSearchBanner, hideSearchBanner, clearSearchQuery, isSearchSubmitted } = this.props;

    console.log('isSearchSubmitted:', isSearchSubmitted);

    if (isSearchSubmitted) {
      clearSearchQuery(); // resets search bar and clears search results
      // ONLY do here if don't want to edit search, and do when search is over (navigate away from results? to dashboard etc?)
      // add option of New Search to Search banner.
    }

    if (!bannerIsOpen) {
      console.log('OpenSearch banner not open');
      // is a New Search, so clear previous search

      showSearchBanner();
    } else {
      console.log('OpenSearch banner open');
      hideSearchBanner();


      showSearchBanner();
    }

  }

  render () {


    const { window_width, showSearchModal, searchBannerIsOpen, isSearchSubmitted } = this.props;
    // console.log('searchButton props:', this.props);
    // console.log('showSearchModal:', showSearchModal);
    const iconSize = 'lg';

    console.log('searchButton history.location:', history.location.pathname);
    const showBannerNotModal = true;
    // const showBannerNotModal = (history.location.pathname === ROUTES.DASHBOARD) || (history.location.pathname === '/') || (history.location.pathname === ROUTES.INBOX);

    console.log('searchButton showBannerNotModal:', showBannerNotModal);

    console.log('isSearchSubmitted:', isSearchSubmitted);
    const isSearchEditable = isSearchSubmitted;

    return (

      <div
        style={{
          flex: 1,
          display: 'flex',
          position: 'relative',
          // cursor: 'pointer',

          marginLeft: 4,
          marginRight: 4,
          borderRadius: 2,
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          zIndex: 2
        }}
      >
        <ConfirmButton
          // background={colours.mediumlightpurple}
          type='button'
          onClick={ showBannerNotModal ? () => this.toggleSearchBanner(searchBannerIsOpen) : showSearchModal }
          onKeyDown={ showBannerNotModal ? () => this.toggleSearchBanner(searchBannerIsOpen) : showSearchModal }

        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          {
            // window_width > 500 &&

            <div style={{ paddingRight: 4 }}>
              { isSearchEditable ? nav.new_search : nav.search }
            </div>

          }

          {window_width > 500 &&
            <div style={{ padding: 0, marginLeft: 4, marginRight: 0 }}>
              <FontAwesomeIcon
                icon={faSearchPlus}
                size={iconSize}
                style={{ color: colours.white }} />
            </div>
          }
          </div>


        </ConfirmButton>


      </div>

    );
  }
}
