import React from 'react';
import { ReportGA } from '../../utils/ga';
import { A6 } from '../../styles/text';
import { ConfirmButton } from '../../styles/styles';
import colours from '../../styles/colours';
import Card from '../general/card';
import CardHeader from '../general/card-header';
import Paragraph from '../general/paragraph';
import ProgressBar from '../general/progress-bar';
import history from '../Navigation/history';
import { dashboard, other } from '../../text';
import welcomeBuntingPic from '../../images/welcome_bunting.min.svg';
import * as ROUTES from '../../constants/routes';
import { DASHBOARD } from '../../constants/ga-category';
import youHave450spPic from '../../images/you_have_450_sp.min.svg';
import pointsFade450spPic from '../../images/points_fade_450_sp.min.svg';
import youHave500spPic from '../../images/you_have_500_sp.min.svg';

const Welcome = (props) => {
    // console.log('f item user:', user);
    const { window_width, handleClickGotIt, profileProgress, onlyPendingDocs } = props;

    // const narrowScreenOrientationDirection = narrowScreenOrientation === 'row' ? 'column' : 'row';

    const handleClickProfile = () => {

      history.push({
        pathname: ROUTES.PROFILE_SUMMARY,
        state: { editMode: true }
      });

      ReportGA({
        category: DASHBOARD,
        action: `User pressed Go to Profile button on Dashboard Welcome card`
      });

    };
    console.log('Welcome profile progress:', profileProgress);
    console.log('Welcome onlyPendingDocs:', onlyPendingDocs);


    return (


      <Card>
        <CardHeader
          paddingBottom='0px'
          alignTitle='left'
          title={<div>{dashboard.welcome_title}</div>}
          image={welcomeBuntingPic}
          paragraph={(window_width >= 490) &&
            <Paragraph noBottomPadding>
              <br />
              {dashboard.welcome_para1}
              <br />
              {(profileProgress !== 100) ? `${dashboard.welcome_para2_incomplete}` : dashboard.welcome_para2}
            </Paragraph>
          }
        />

        {
          (window_width < 490)
          && <Paragraph noBottomPadding>
            {dashboard.welcome_para1}
            <br />
              {(profileProgress !== 100) ? `${dashboard.welcome_para2_incomplete}` : dashboard.welcome_para2}
          </Paragraph>
        }

        {(profileProgress !== 100)
          && <div style={{ marginTop: 16, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ marginRight: 8, paddingBottom: 4, borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>
              <A6>{dashboard.welcome_profile_prog}</A6>
            </div>
            <div style={{ position: 'relative', width: '50%', maxWidth: 200, borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>
              <div style={{ flex: 2, maxWidth: 180, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', borderColor: 'blue', borderWidth: 0, borderStyle: 'solid' }}>

                <ProgressBar progress={profileProgress} />

              </div>
            </div>
          </div>
        }

        {
          (profileProgress !== 100) && !onlyPendingDocs &&
          <CardHeader
            paddingBottom='0px'
            alignTitle='left'
            title={<div>{dashboard.welcome_points_title}</div>}
            image={pointsFade450spPic}
            // lockIcon
            // paragraph={(window_width >= 490)
            //   && <Paragraph>
            //     <br />
            //     {dashboard.dbs_sp_para1}
            //     <br />
            //     {dashboard.dbs_sp_para2}
            //   </Paragraph>
            // }

          />
        }

        <div style={{ flex: 1, width: '100%', display: 'flex', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', justifyContent: 'center', alignItems: 'flex-end', paddingTop: 4, paddingBottom: 16 }}>

          {
            (profileProgress !== 100) && !onlyPendingDocs
            && <ConfirmButton
              background={colours.magenta}
              onClick={ () => handleClickProfile() }
            >
              {dashboard.welcome_button}
            </ConfirmButton>
          }

          {
            (profileProgress === 100)
            && <ConfirmButton

              onClick={ () => handleClickGotIt() }
            >
              {other.got_it}
            </ConfirmButton>
          }

          {
            (profileProgress !== 100) && onlyPendingDocs
            && <Paragraph noBottomPadding>
              {dashboard.welcome_only_pending}
            </Paragraph>
          }

        </div>

      </Card>

    );


};

export default Welcome;
