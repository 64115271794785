import React from 'react';
import colours from '../../../../styles/colours';

export const Container = ({ window_width, children }) => {

    return (
      <div style={{
              opacity: 1,
              position: 'absolute',
              top: 28, // same as your nav height,

              left: window_width < 310 ? (264 - window_width) / 2
                : window_width < 400 ? -25
                : 0,

              width: window_width < 400 ? window_width : '100%',

              textAlign: 'center',
              transition: 'all 1000 ease',
              zIndex: 1200, // previouly 999, but changed to sit in front of messagebar on Edit Details modal

              background: colours.white,
              borderWidth: 0,
              borderStyle: 'solid',
              borderColor: 'blue'

            }}
      >
        { children }
      </div>
    );
};

export default Container;
