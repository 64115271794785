import moment from 'moment';
import { getAlertStatus } from './statusHelpers';
import isDateTimeInPast from './timeHelpers';
import { ACTION_REQUIRED } from '../constants/thread-status';
import { ALL, ACTION, HOSTING, GUEST, UNREPLIED } from '../constants/inbox-filters';


export const filterThreads = (threads, selectedInboxFilter, user_id) => {

  let unreplied = 0;
  let actionRequiredNo = 0;

  const filteredThreads = threads.filter((thread) => {
    // console.log('thread', thread);


    // fix this to show latest message
    const message = thread.message_array[0];
    const { status, sitter_id, end_date, end_time } = thread;

    if ((message.sender_id !== user_id) && (message.status !== 'finalised')) {
      unreplied += 1;
    }

    // calc if action required and add to count
    const isInPast = isDateTimeInPast(end_date, end_time);
    const alertStatus = getAlertStatus(status, user_id, sitter_id, isInPast);

    if (alertStatus === ACTION_REQUIRED) {
      actionRequiredNo += 1;
    } else if (message.sender_id !== user_id) {
      // also return unreplied messages (action is to reply)
      //
      // if (message.status === 'finalised') {
      //   // if finalised don't show as unreplied
      // } else {
      //   actionRequiredNo += 1; // if not finalised and not last sender, show unreplied
      // }


    }

    console.log('last message:', message);

    if (selectedInboxFilter === UNREPLIED) {
      // console.log('unreplied');
      // if last message in array sender_id is not user_id
      if (message.sender_id !== user_id) {
        // console.log('not sender');
        if (message.status === 'finalised') {
          // console.log('message.status:', message.status);
          return false; // if finalised don't show as unreplied
        }
        // else
        // console.log('not finalised');
        return true; // if not finalised and not last sender, show unreplied
      }
      return false; // user was last sender

    }

    if (selectedInboxFilter === ACTION) {

      if (alertStatus === ACTION_REQUIRED) {
        return true; // if action required return true
      }
      // also return unreplied messages (action is to reply)
      // if (message.sender_id !== user_id) {
      //   if (message.status === 'finalised') {
      //     return false; // if finalised don't show as unreplied
      //   }
      //   // else
      //   return true; // if not finalised and not last sender, show unreplied
      // }

      return false;
    }

    if (selectedInboxFilter === HOSTING) {
      if (sitter_id === user_id) {
        return true;
      }
      return false;
    }

    if (selectedInboxFilter === GUEST) {
      if (sitter_id !== user_id) {
        return true;
      }
      return false;
    }

    // else return everything
    return true;
  });


  return { filteredThreads, unreplied, actionRequiredNo };

};

export const sortThreads = (threads) => {
  const new_all_threads = [...threads];
  const sortedThreads = new_all_threads.sort((a, b) => (moment(a.message_array[a.message_array.length - 1].date_sent).isSameOrBefore(moment(b.message_array[b.message_array.length - 1].date_sent))) ? 1 : -1);
  return sortedThreads;
};


export default filterThreads;


export const calcNoOfUnrepliedMessages = (all_threads, user_id) => {
// returns an object with stats about all_threads

  let all = 0;
  let unreplied = 0;
  let finalised = 0;
  let numberOfUnreadMessages = 0;
  let numberOfThreadsRespondedTo = 0; // responded to at least once where were the sitter.
  let numberOfThreadsAsSitter = 0; // where were the sitter.


  const new_all_threads = !all_threads || !Array.isArray(all_threads) ? [] : [...all_threads];
  // all = new_all_threads.length;

  const sortedThreads = new_all_threads.sort((a, b) => (moment(a.message_array[a.message_array.length - 1].date_sent).isSameOrBefore(moment(b.message_array[b.message_array.length - 1].date_sent))) ? 1 : -1);
  console.log('sortedThreads', sortedThreads);

  sortedThreads.map((thread, i) => {
    if (i < 3) {
      console.log('thread', thread);

      // fix this to show latest message
      const message = thread.message_array[0];

      console.log('last message:', message);

      if (message.sender_id !== user_id) {
        unreplied += 1;
      }
      if (message.status === 'finalised') {
        finalised += 1;
      }
      // if last message in array sender_id is not user_id (add 1)
      if (message.sender_id !== user_id && !message.marked_read) {
        numberOfUnreadMessages += 1;
      }

      console.log('numberOfUnreadMessages:', numberOfUnreadMessages);

      // console.log('thread_sitter_id', thread.sitter_id);
      // console.log('user_id', user_id);
      // calc response rate
      // if (user_id === thread.sitter_id) {
      //   console.log('user_id === thread.sitter_id', user_id === thread.sitter_id);
      //   numberOfThreadsAsSitter += 1;
      //   // if (thread.message_array.length > 1) {
      //   //   console.log('thread.message_array.length > 1', thread.message_array.length > 1);
      //   //   numberOfThreadsRespondedTo += 1;
      //   // }
      // }

    }
  });

  return {
    numberOfUnreadMessages,
    all,
    unreplied,
    finalised,
    numberOfThreadsRespondedTo, // responded to at least once where were the sitter.
    numberOfThreadsAsSitter // where were the sitter.
  };

};
