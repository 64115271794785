import React, { Component } from 'react';
import {
    Button,
    Icon
} from "semantic-ui-react";
import colours from '../../styles/colours';
import { BACK_CAPTURE_OPTIONS, FRONT_CAPTURE_OPTIONS } from '../general/camera';
import Spinner from '../general/spinner';
import { useUserMedia } from '../general/camera/hooks/use-user-media';


console.log('process.env.REACT_APP_APP_NODE_ENV: ', process.env.REACT_APP_APP_NODE_ENV);
console.log('process.env.REACT_APP_APP_ENV: ', process.env.REACT_APP_APP_ENV);
const debugMode = process.env.REACT_APP_APP_NODE_ENV !== 'production' && process.env.REACT_APP_APP_ENV === 'local';
// console.log('debugMode: ', debugMode);

const CameraButton = ({ doc_name, cameraAction, modalText }) => {
  // only return this if modal requires camera
  // camera setup

  const frontMediaStream = useUserMedia(FRONT_CAPTURE_OPTIONS);
  const backMediaStream = useUserMedia(BACK_CAPTURE_OPTIONS);
  console.info('CameraButton debugMode:', debugMode);
  console.info('CameraButton frontMediaStream:', frontMediaStream);
  console.info('CameraButton backMediaStream:', backMediaStream);
  const backCameraAvailable = (debugMode ? true : !backMediaStream ? false : true);
  const frontCameraAvailable = (debugMode ? true : !frontMediaStream ? false : true);
  console.info('CameraButton backCameraAvailable:', backCameraAvailable);
  console.info('CameraButton frontCameraAvailable:', frontCameraAvailable);
  const anyCameraAvailable = backCameraAvailable || frontCameraAvailable;
  console.info('CameraButton anyCameraAvailable:', anyCameraAvailable);

  // Camera Test complete - set isFetching false; possibly add timeout?


  return (

      // (isMobile || doc_name === 'id_selfie')
      (backCameraAvailable || doc_name === 'id_selfie')
      && <Button
        primary
        icon
        labelPosition='left'

        style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
        onClick={cameraAction}
      >
        <Icon name='camera' />

        {modalText.camera}
      </Button>

  );
};


class VerifyCameraUploadButton extends Component {


  render () {
    const { isFetching, doc_name, cameraAction, modalText } = this.props;


    return (
      <>
        {
          (!isFetching)
          && <CameraButton docName={doc_name} cameraAction={cameraAction} modalText={modalText} />
        }
        {
          (isFetching)
          && <Spinner />
        }
      </>

    );

  }
}

export default VerifyCameraUploadButton;
// export default connectAlert(hoistNonReactStatic(decoratedComponent, Signup));
