
export const reviews = {
  page_title: 'Reviews',
  review_sit: 'Review the sit',

  header: 'Review the sit',

  how_did_you_rate: 'How did you rate the sit?',
  type_a_review: 'Leave a review:',
  review_text_placeholder: 'Type your review here',
  enter_rating: 'Please enter a rating',
  enter_review: 'Please enter a review',
  reviews: 'reviews',
  overall: 'Overall rating',
  sitter_rating: 'Rating as host',
  requester_rating: 'Rating as guest',

  submit: 'Submit rating',

  // ok: 'OK'
  fetching: 'submitting rating',

  modal: {
    success: {
        title: 'Success',
        header: 'Rating Submitted',
        para: 'Your rating has been submitted, Thank you.',
        // para2: ``,
        ok: 'OK'
    },
    failure: {
        title: 'Rating Failed',
        header: 'Oops',
        para: 'Your rating could not be submitted.  This could be due to a network error, please try again.',
        para2: 'Please contact us if this problem persists.',
        ok: 'OK'
    }
  }

};


export default reviews;
