import React from 'react';
import { A5 } from '../../styles/text';

import colours from '../../styles/colours';

const ProgressBar = ({ progress, color, height }) => {

    return (


      <div style={{ paddingLeft: 8, paddingRight: 8, flexDirection: 'row', alignItems: 'center', paddingBottom: 2, display: 'flex', flex: 1, background: 'transparent' }}>
        <div style={{ paddingRight: 8 }}>
          <A5 style={{ textAlign: 'left' }}>{`${progress}%`}</A5>
        </div>
        <div style={{ flex: 1.2, display: 'flex', height: height || 8, background: colours.lightgray, borderRadius: 4 }}>
          <div style={{ width: `${progress}%`, height: height || 8, background: color || colours.green, borderRadius: 4 }} />
        </div>


      </div>


    );
};

export default ProgressBar;
