import { connect } from 'react-redux';
import OpenSearchButton from '../../components/search/open-search';
import { showModal, hideModal } from '../../actions/modal';
import { showSearchBanner, hideSearchBanner, clearSearchQuery } from '../../actions/search';
// import { MODAL_TYPE_SEARCH } from '../../components/modals/modalTypes';


const mapStateToProps = ({ user, search, layout }) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  window_width: layout.window_width,
  isLoggingIn: user.isLoggingIn,
  userIsLoggedIn: user.isLoggedIn,
  searchBannerIsOpen: search.banner_is_open,
  isSearchSubmitted: search.submittedSearch !== null


});

const mapDispatchToProps = dispatch => ({

  // showSearchModal: () => {
  //   dispatch(showModal(MODAL_TYPE_SEARCH, {
  //
  //   }));
  // },

  showSearchBanner: () => {
    dispatch(showSearchBanner());
  },

  hideSearchBanner: () => {
    dispatch(hideSearchBanner());
  },
  clearSearchQuery: () => {
    dispatch(clearSearchQuery());
  },
  hideModal: () => {
    dispatch(hideModal());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenSearchButton);
