import React, { Component } from 'react';
import { H5, H6, A6 } from '../../styles/text';
import { CheckBox, DateText } from './calendar-styles';
import Paragraph from '../general/paragraph';
import colours from '../../styles/colours';
import { dashboard } from '../../text';


class AvailabilityKey extends Component {


  render () {

    const { cellWidth, cellHeight } = this.props; // get from redux

    const bookingObjectSitting = { booking_params: { booking_status: 'confirmed', booking_type: 'sitting' } };
    const bookingObjectUsing = { booking_params: { booking_status: 'confirmed', booking_type: 'using' } };
    const bookingObjectRequesting = { booking_params: { booking_status: 'unconfirmed', booking_type: 'using' } };

    const maxCheckboxWidth = 40;
    const minKeyItemWidth = 150;

    const reqBookedColor = colours.reqBooked;
    const sitBookedColor = colours.sitBooked;
    const availableColor = colours.available;
    const clashingColor = colours.clashing;


    const dotsMargin = cellWidth < 60 ? 1 : 2;
    const dotSize = 10;
    const dotBorderWidth = 2;

    const innerCircleMargin = 4;

    const goodCellColor = colours.goodGreen;
    const someCellColor = colours.someGreen;
    const noneCellColor = colours.white;

    // const innerCircleSize = cellWidth - ((dotSize + dotsMargin + innerCircleMargin) * 2); // multiply by two ensure account for spacing both sides;
    const innerCircleSize = cellWidth - ((0 + dotsMargin + innerCircleMargin) * 2);
    // checkBoxWidth needs to be responsive when window size changes - add listener

    return (

      <div style={{ display: 'flex', flexDirection: 'column', background: colours.white, borderStyle: 'solid', borderWidth: 0, borderColor: 'red', paddingTop: 4, paddingBottom: 8, justifyContent: 'center' }}>


        <div style={{ display: 'flex', flexDirection: 'column', background: colours.white, borderStyle: 'solid', borderWidth: 0, borderColor: 'red', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', background: colours.white, flexWrap: 'wrap', borderStyle: 'solid', borderWidth: 0, borderColor: 'blue', justifyContent: 'center', alignItems: 'flex-start' }}>


            {
              <div style={{ paddingTop: 8, paddingBottom: 4, borderStyle: 'solid', borderWidth: 0, borderColor: 'red', minWidth: minKeyItemWidth, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: null }}>
                <div style={{ width: cellWidth, height: cellHeight, display: 'flex', justifyContent: 'center', borderStyle: 'solid', borderWidth: 1, borderColor: colours.mediumlightgray, background: goodCellColor }}>
                  <div style={{ width: innerCircleSize, height: innerCircleSize, borderRadius: '50%', background: 'transparent', margin: innerCircleMargin, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <DateText
                      textColor={colours.white}
                    >{''}</DateText>

                  </div>
                </div>
                <div style={{ paddingLeft: 8, paddingRight: 16 }}>
                  <A6>
                    {dashboard.local_availability_key.good}
                  </A6>
                </div>
              </div>
            }

            {
              <div style={{ paddingBottom: 4, borderStyle: 'solid', borderWidth: 0, borderColor: 'red', minWidth: minKeyItemWidth, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: null }}>
                <div style={{ width: cellWidth, display: 'flex', justifyContent: 'center', borderStyle: 'solid', borderWidth: 1, borderColor: colours.mediumlightgray, background: someCellColor }}>
                  <div style={{ width: innerCircleSize, height: innerCircleSize, borderRadius: '50%', background: 'transparent', margin: innerCircleMargin, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <DateText
                      textColor={colours.white}
                    >{''}</DateText>

                  </div>
                </div>
                <div style={{ paddingLeft: 8, paddingRight: 16 }}>
                  <A6>
                    {dashboard.local_availability_key.some}
                  </A6>
                </div>
              </div>
            }

            {
              <div style={{ paddingBottom: 4, borderStyle: 'solid', borderWidth: 0, borderColor: 'red', minWidth: minKeyItemWidth, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: null }}>
                <div style={{ width: cellWidth, display: 'flex', justifyContent: 'center', borderStyle: 'solid', borderWidth: 1, borderColor: colours.mediumlightgray, background: noneCellColor }}>
                  <div style={{ width: innerCircleSize, height: innerCircleSize, borderRadius: '50%', background: 'transparent', margin: innerCircleMargin, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <DateText
                      textColor={colours.white}
                    >{''}</DateText>

                  </div>
                </div>
                <div style={{ paddingLeft: 8, paddingRight: 16 }}>
                  <A6>
                    {dashboard.local_availability_key.none}
                  </A6>
                </div>
              </div>
            }


          </div>
        </div>

      </div>


    );
  }
}

export default AvailabilityKey;
