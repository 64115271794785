import React from 'react';
import { L4 } from '../styles/text';
import { verification_doc_points, profile_completed_points, total_verification_points } from '../rules/points';

const dbs_info_link = 'https://dbscheckonline.org.uk/individuals';
const dbs_fees_link = 'https://www.gov.uk/government/news/fee-changes-for-dbs-checks';
export const HOST_FAMILIES = `HostFamilies`; // Sitting Member, Sitter, Sitters, Sitting Member
export const GUEST_FAMILIES = `GuestFamilies`; // Parent Member, Parent, Parents, Using Member
export const HOST_FAMILY = `HostFamily`; // Sitting Member, Sitter, Sitters, Sitting Member
export const GUEST_FAMILY = `GuestFamily`; // Parent Member, Parent, Parents, Using Member
export const PARENT = `Parent`; // Parent, Parents


export const terms = {
  page_title: 'Terms and Conditions',
  intro: 'This page sets out the terms of use for SitterPoint:',
  end1: 'If you can’t find the answer to your question, please contact us at ',
  end2: 'or by using our request form',
  last_update: `Last Updated: 26 January 2023`,
  items: [

    { text_type: "para", text: `` },


    { text_type: "section_title", text: `1. INTRODUCTION` },

    { text_type: "para", text: `1.1 - Welcome to SitterPoint.co.uk! These Terms of Use (“Terms”) govern the respective rights and obligations between SitterPoint Ltd, a UK corporation (“SitterPoint”), and you, and constitute a legally binding agreement (“Agreement”) between us. This Agreement governs your use of Sitterpoint.co.uk and any affiliated websites (together, the “Website”), your use of any SitterPoint mobile, tablet, and other smart device applications, (together, the “Application”), and your use of any Services (as defined below). Together, the Website, Application, and Services shall be collectively defined as the “Platform”.` },
    { text_type: "para", text: `1.2 - We are SitterPoint Ltd trading as "SitterPoint". Our company information is at the end of this document.` },

    { text_type: "section_sub_title", text: `Applicability of terms and conditions` },
    { text_type: "para", text: `1.3 - Please note that your use of the SitterPoint Platform is also governed by other documents and policies incorporated into these Terms. In case of any conflict among these Terms and the other terms or policies, these Terms shall govern unless expressly stated herein` },
    { text_type: "para", text: `1.4 - BY CONTINUING TO ACCESS OR USE THE PLATFORM AS A VISITOR OR MEMBER, YOU SIGNIFY YOUR ACCEPTANCE OF THESE TERMS.` },
    { text_type: "para", text: `1.5 - If you do not agree with these Terms, you do not have the right to access the Platform.` },
    { text_type: "para", text: `1.6 - Members are solely responsible for determining any laws, rules or regulations that apply to them and ensuring that they understand and are in compliance with those rules regarding offering their Residence for an Exchange, including whether any licenses, permits, or other registrations are required before entering into an Exchange. SitterPoint does not offer legal advice to Members, so please seek independent legal guidance if you have questions about compliance.` },
    { text_type: "para", text: `1.7 - Sitting Members are also solely responsible for providing and communicating to Using Member any and all information that is necessary or desired for the proper sitting of the Using Member's children, including details about their household, property, family members, etc).` },
    { text_type: "para", text: `1.8 - Please read these terms and conditions carefully. They replace any previous versions. By registering on or using our Service you you enter a contract with us on the basis of these these terms and conditions. Alternatively, if you first download our App, you enter into a legal contract with us at that point. Please print or save these terms for future use as we will not keep a file copy specifically for the transaction with you and we cannot guarantee that they will remain accessible on our Service in future. These terms and conditions are available in English only.` },
    { text_type: "para", text: `1.9 - These terms and conditions apply to all Users.` },
    { text_type: "para", text: `1.10 - For App users: These terms and conditions are an “end user licence agreement” between you and us (not the Appstore) in relation to our App (i.e. setting out how we allow you to use our App) and, in addition, you agree to be legally bound by the Appstore Rules.` },

    { text_type: "section_sub_title", text: `Changes to the terms and conditions` },
    { text_type: "para", text: `1.11 - We may change these terms and conditions by giving you at least 15 days' notice unless a more urgent change is needed to comply with laws or regulations or to deal with an unforeseen and imminent danger. We will notify you by posting the new version on our website and by giving notice by email, SMS and/or in-app message.` },
    { text_type: "para", text: `1.12 - If you don’t agree to the new terms, you can end this contract with effect from 15 days after our notice by emailing us within that period.` },

    { text_type: "section_sub_title", text: `Right to cancel (“cooling off”)` },
    { text_type: "para", text: `1.13 - If you are a Consumer, you have the right to cancel this contract subject to the provisions set out below.` },
    { text_type: "para", text: `1.14 - If you do have the right to cancel, the following apply:` },

    { text_type: "section_sub_title", text: `Right to cancel` },
    { text_type: "para", text: `1.15 - You have the right to cancel this contract within 14 days without giving any reason.` },
    { text_type: "para", text: `1.16 - The cancellation period will expire after 14 days from the day of the conclusion of the contract.` },

    { text_type: "section_sub_title", text: `Effects of cancellation` },
    { text_type: "para", text: `1.17 - To exercise the right to cancel, you must inform us via sitterpoint.co.uk/contact of your decision to cancel this contract by a clear statement via our contact form.` },
    { text_type: "para", text: `1.18 - To meet the cancellation deadline, it is sufficient for you to send your communication concerning your exercise of the right to cancel before the cancellation period has expired.` },
    { text_type: "para", text: `1.19 - If you cancel this contract, we will reimburse to you all payments received from you.` },
    { text_type: "para", text: `1.20 - We will make the reimbursement without undue delay, and not later than 14 days after the day on which we are informed about your decision to cancel this contract.` },
    { text_type: "para", text: `1.21 - We will make the reimbursement using the same means of payment as you used for the initial transaction, unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result of the reimbursement.` },
    { text_type: "para", text: `1.22 - If you requested us to begin the performance of services during the cancellation period, you shall pay us an amount which is in proportion to what has been performed until you have communicated to us your cancellation of this contract in comparison with the full coverage of the contract.` },

    { text_type: "section_sub_title", text: `Your right to use our Service` },
    { text_type: "para", text: `1.23 - We grant you a limited personal non-transferable right to use our Service on any applicable device owned or controlled by you (only Apple-branded where Apple Inc is the App Store) subject to these terms and conditions and, where applicable, in accordance with the Appstore Rules.` },

    { text_type: "section_sub_title", text: `Who can use our Service?` },
    { text_type: "para", text: `1.24 - You must not use, or attempt to register on, our Service if:` },
    { text_type: "para", text: `a)you are resident or if a company, incorporated, outside the UK;` },
    { text_type: "para", text: `b)you are below 18 years of age; or` },
    { text_type: "para", text: `c)you have been convicted of any offence, or subject to any court order, relating to assault, violence, sexual misconduct, harassment or dishonesty.` },
    { text_type: "para", text: `1.25 - Our Service is designed for, and may only be used by, persons who genuinely intend to use the Service to provide or avail of Childcare Services.` },
    { text_type: "para", text: `1.26 - You must not use, or attempt to register on, our Service if you act as an agency or other intermediary for any Childcare Services.` },


    { text_type: "section_title", text: `3. DEFINITION OF TERMS` },
    { text_type: "para", text: `3.1 - Capitalised terms have the following meanings in these terms and conditions:` },
    { text_type: "para", text: `3.2 - "App” – the SitterPoint mobile application.` },
    { text_type: "para", text: `3.3 - "Appstore” – the app distributor from which you download the App.` },
    { text_type: "para", text: `3.4 - "Appstore Rules” – any applicable rules, policies or terms of the relevant Appstore.` },
    { text_type: "para", text: `3.5 - "Childcare Contract” – a contract for the supply of Childcare Services between a Childcare Provider and a Parent.` },
    { text_type: "para", text: `3.6 - "Childcare Provider” – a User who uses our Service for the purpose of providing childcare services to Parents.` },
    { text_type: "para", text: `3.7 - "Childcare Services” – childcare services such as babysitting, childminding, nannying, nurseries, or private tutoring.` },
    { text_type: "para", text: `3.8 - "Content” – all information of whatever kind (including profiles, reviews, posts, comments, documents, audio, video, advertisements, messages) uploaded to our Service (including messages sent via our Service).` },

    { text_type: "para", text: `3.9 - "Account” means the account that must be created to allow an individual to become a Member and access the Services offered by the Platform.` },
    { text_type: "para", text: `3.10 - "Exchange” means a Childcare Services exchange entered into between a Sitting Member and a Using Member. Exchanges may be: (i) a deferred (non-simultaneous) symmetrical Childcare Services exchange entered into between the two Members, or (ii) independent of any other Childcare Services exchange (unilateral Exchange).` },
    { text_type: "para", text: `3.11 - "SittingPoints (also referred to throughout the Platform as “SP”) means points accumulated by Members.` },
    { text_type: "para", text: `3.12 - "Listing” means any Family Profile described on the Platform published by a Member, including any User Content used to describe such Listing. Each Listing has a unique identification number on the Platform.` },

    { text_type: "para", text: `3.13 - "Consumer” – an individual acting for purposes which are wholly or mainly outside that person’s trade, business, craft or profession.` },
    { text_type: "para", text: `3.14 - "User” – persons or organisations using our Service (whether or not registered with us).` },
    { text_type: "para", text: `3.15 - "Member” means any person who registers on the Platform and by doing so, accepts these Terms.` },
    { text_type: "para", text: `3.16 - "${HOST_FAMILY}” means a Member who offers his or her Childcare Services for Exchange.` },
    { text_type: "para", text: `3.17 - "${GUEST_FAMILY}” means a Member who enters into an Exchange with a Sitting Member.` },
    { text_type: "para", text: `3.18 - "Parent” – a parent or guardian who uses our Service for the purpose of availing of Childcare Services.` },
    { text_type: "para", text: `3.19 - "Subscriber Member” refers to a Member who has subscribed to the SitterPoint Service on an annual basis and benefits from accessing an unlimited number of Exchanges without paying booking fees over the monthly period of the subscription's validity dates or validity dates of their SitterPoint Gift Card, subject to the Member’s available SittingPoints.` },
    { text_type: "para", text: `3.20 - "Verified Member” means a Member whose address and identity (first and last name) has been confirmed by SitterPoint through its Verification service.` },

    { text_type: "para", text: `3.21 - "Service” or “Services” means our website, our Platform, our App, and any related services.` },
    { text_type: "para", text: `3.22 - SitterPoint Service” means the subscription-based Services offered by SitterPoint on the Platform, allowing Members to enter into an Exchange, and includes additional Services more fully described here.` },
    { text_type: "para", text: `3.23 - "Verification” means the Service proposed by SitterPoint of (i) verifying Members’ identities of with copies of forms of identification provided, and (ii) verifying Members’ listings with the copies of the proof of Residence provided.` },
    { text_type: "para", text: `3.24 - "We”, “us”, and “our,” means SitterPoint.` },
    { text_type: "para", text: `3.25 - "I”, “you”, “your”, “yourself” means you (a Member who uses the Platform in any manner, either through accessing, the Websites, Application or Services).` },

    { text_type: "section_title", text: `4. GENERAL USE` },

    { text_type: "section_sub_title", text: `Acceptable use of our Service` },
    { text_type: "para", text: `4.1 - Your use of the Platform is conditioned upon your compliance with these Terms.` },
    { text_type: "para", text: `4.2 - You agree that you will not in connection with the Service:` },
    { text_type: "para", text: `a)breach any applicable law, regulation or code of conduct;` },
    { text_type: "para", text: `b)upload any Content (including links or references to other content), or otherwise behave in a manner, which:` },
    { text_type: "para", text: `c)upload any Content which includes someone else’s personal information unless that person is 18 years or over and you have obtained that person’s explicit written consent or you are the parent/guardian of such person;` },
    { text_type: "para", text: `is defamatory, threatening, harassing, invasive of privacy, offensive, vulgar, racist, hateful, discriminatory, obscene, pornographic, sexually suggestive, promoting of self-harm, misleading, abusive or deceptive;` },
    { text_type: "para", text: `infringes any intellectual property or other rights of others;` },
    { text_type: "para", text: `d)make recordings or screenshots of audio / video interactions without the written consent of all other participants;` },
    { text_type: "para", text: `e)impersonate any person or entity in order to mislead others;` },
    { text_type: "para", text: `f)upload any Content which links to any third party websites which are unlawful or contain inappropriate Content;` },
    { text_type: "para", text: `g)sell access to the Service;` },
    { text_type: "para", text: `h)use the Service to provide a similar service to third parties or otherwise with a view to competing with us;` },
    { text_type: "para", text: `i)sell advertising, sponsorship or promotions on or in connection with Content except where explicitly authorised by us;` },
    { text_type: "para", text: `j)use the Service for junk mail, spam, pyramid or similar or fraudulent schemes;` },
    { text_type: "para", text: `k)do anything which may have the effect of disrupting the Service including worms, viruses, software bombs or mass mailings;` },
    { text_type: "para", text: `l)do anything which may negatively affect other Users’ enjoyment of the Service;` },
    { text_type: "para", text: `m)gain unauthorised access to any part of the Service or equipment used to provide the Service;` },
    { text_type: "para", text: `n)intercept or modify communications to or from the Service;` },
    { text_type: "para", text: `o)deliberately exploit any bugs found with the Service;` },
    { text_type: "para", text: `p)circumvent any security or other features of the Service including features that restrict use or copying of Content;` },
    { text_type: "para", text: `q)use any automated means to interact with our systems excluding public search engines; or` },
    { text_type: "para", text: `r)attempt, encourage or assist any of the above.` },

    { text_type: "para", text: `4.3 - When using the Platform, you will:` },
    { text_type: "para", text: `4.4 - Not provide false or misleading information as upon Account registration or to any Using, Member, or SitterPoint, create multiple accounts, or create fake accounts.` },
    { text_type: "para", text: `4.5 - Not accept payment from a third party in exchange for performing any commercial activity on the Platform in violation of these Terms without SitterPoint’s express permission.` },
    { text_type: "para", text: `involves phishing or scamming or similar; or` },
    { text_type: "para", text: `4.6 - Not interfere with another Member’s Account, circumvent measures intended to prevent or restrict access to the Platform, or use robots, spiders, or scrapers to collect Platform or Member content,` },
    { text_type: "para", text: `we otherwise reasonably consider to be inappropriate;` },
    { text_type: "para", text: `4.7 - Not collect or otherwise obtain or use any information about others, including e-mail addresses, without consent.` },
    { text_type: "para", text: `4.8 - Not exchange with another Member outside the Platform when that Member is listed on the Platform.` },
    { text_type: "para", text: `4.9 - Comply with other rules and prohibitions set forth throughout these Terms.` },
    { text_type: "para", text: `4.10 - You further agree that:` },
    { text_type: "para", text: `4.11 - You assume all liability for your use of any information appearing on any of the Platform.` },
    { text_type: "para", text: `4.12 - You have the authority to enter into this Agreement, to post your Listing and to offer it for Exchange.` },
    { text_type: "para", text: `4.13 - You are solely responsible for your communications and relationship with other Members.` },
    { text_type: "para", text: `4.14 - SitterPoint has the right but not the obligation to monitor use or access to the Platform in order to ensure the security of Members generally and/or the Platform, or to ensure compliance with these Terms or compliance with any applicable laws, rules, regulations, or order from any legal authority. Members agree to cooperate with and assist SitterPoint in good faith, and provide any related information and take any other actions reasonably requested by SitterPoint regarding any investigation by SitterPoint or any legal authority regarding the misuse of the Platform.` },
    { text_type: "para", text: `4.15 - If you believe in good faith that a Member is violating these Terms or has otherwise acted inappropriately or illegally, you should immediately report such Member to SitterPoint and, as may be the case, to the relevant legal authorities. Any report made to SitterPoint and/or legal authorities however shall not obligate SitterPoint to respond in any particular way except as may be required by law.` },
    { text_type: "para", text: `4.16 - Any web address or URL links must be approved by SitterPoint. Email addresses and other contact information must reside only in the authorized locations within the Listing. All other references to specific web addresses, websites, email addresses or phone numbers may be removed without notice.` },
    { text_type: "para", text: `4.17 - Note that any translations provided by SitterPoint cannot be guaranteed as accurate and Members are responsible for reviewing any translations provided regarding their Listings.` },
    { text_type: "para", text: `4.18 - You must comply with any rules or requirements on our website.` },
    { text_type: "para", text: `4.19 - You must promptly comply with any reasonable request or instruction by us in connection with the Service.` },
    { text_type: "para", text: `4.20 - You must ensure that any contact or other information which you supply to us is accurate and not misleading and you will update it so that it remains so.` },


    { text_type: "section_title", text: `5. MEMBERSHIP RULES` },
    { text_type: "para", text: `5.1 - You may not authorize others to use your Account, and you may not use your Account for the benefit of others (for example, by using your account to register an Exchange for a friend or other third party outside of your immediate household). Except as otherwise expressly provided for herein, you may not assign or otherwise transfer your account to any other person or entity.` },


    { text_type: "section_title", text: `6. PLATFORM SERVICES` },

    { text_type: "para", text: `6.1 - SitterPoint is a platform that connects Parent Users with Sitter Users, enabling them to interact with one another. We allow Sitter Users to create profiles on the Platform which can be viewed by Parent Users. We allow Parent Users to create profiles on the Platform which can be viewed by Sitter Users. Through the Platform, we enable Sitter Users and Parent Users to arrange appointments for babysitting.` },
    { text_type: "para", text: `6.2 - We do not provide employment services. We do not employ Sitters. We do not seek to find employment for sitters. We do not recommend Sitters. We do not interview sitters except where clearly stated on the platform by SitterPoint. We do not edit profiles.` },
    { text_type: "para", text: `6.3 - Registering and creating an account with SitterPoint is free. There is no charge for a parent to post sit requests or to review content, or for Sitters to review posted sit requests.` },
    { text_type: "para", text: `6.4 - SitterPoint uses third party providers to:` },
    { text_type: "para", text: `(a) cross-check the validity of the identity information supplied by the User against multiple public and privately-held data sources;` },
    { text_type: "para", text: `(b) perform a non-exhaustive background check of the information supplied by the User against multiple public and privately-held data sources.` },
    { text_type: "para", text: `6.5 - When creating and editing their profile, Sitters are able to credit themselves as being DBS certified. We do not undertake verification of this certification nor ongoing monitoring to ensure its continued validity, unless where expressly stated on the platform (here is our DBS Checking Policy). In all other instances, the onus for verifying the validity of this accreditation falls entirely on the Parent.` },
    { text_type: "para", text: `6.6 - Clauses 6.4 to 6.5 above explain the extent of the checks that we make in relation to Sitters and Parents. We do not carry out further verification of Sitters, Parents or their profiles; we have no obligation to do so; and make no representations that we do so. You should make your own assessment of the people you decide to interact with through the Platform, whether you are a Parent or a Sitter. You must rely on your own judgment and common sense. The checks that we have carried out and make available through the Platform are only part of this process. SitterPoint accepts no responsibility for the accuracy or completeness of any profile on the Platform as we do not check them. We do not accept responsibility for the accuracy or completeness of any of the checks made in clauses 2.4 to 2.7 nor the validity of Sitter profile information outlined in these clauses to the extent that we have been deliberately misled by the individual in question, and/or to the extent that the source of 3rd party information used was inaccurate.` },
    { text_type: "para", text: `6.7 - The Platform connects Sitters with Parents. When a babysitting appointment is booked, a binding agreement is created between the Sitter and the Parent. SitterPoint is not a party to that agreement. SitterPoint does not and cannot control either the Sitter or the Parent, the services provided (or not provided) or the quality or timing of those services and disclaims all liability for such.` },
    { text_type: "para", text: `6.8 - At its absolute discretion, SitterPoint may refuse to allow any person to register or create an account with SitterPoint or cancel or suspend any existing account if it reasonably forms the view that the User’s conduct or presence on the SitterPoint Platform, including a breach of User Obligations, is detrimental to the operation of the SitterPoint Platform and/or the security, welfare or experience of other users. SitterPoint may monitor communication made on the platform between users for this purpose – see our Privacy and Cookie Policy.` },
    { text_type: "para", text: `6.9 - Our Service constitutes a neutral platform whereby Childcare Providers can arrange to supply Childcare Services to Parents. Any Childcare Contract is between the respective Childcare Provider and Parent and the terms are for the parties to agree. We are not a party to the Childcare Contract itself. You acknowledge that any legal recourse arising from breach of the Childcare Contract is against the other party to the Childcare Contract and not against us.` },
    { text_type: "para", text: `6.10 - If you are a Childcare Provider, you promise us that in connection with supply of the Childcare Services to Parents:` },
    { text_type: "para", text: `6.11 - You have the necessary abilities (including registrations, permissions, qualifications, skills, training, expertise).` },
    { text_type: "para", text: `6.12 - You will act in accordance with the highest standards reasonably to be expected in the relevant industry.` },
    { text_type: "para", text: `6.13 - You will comply with all applicable laws and regulations and will not infringe any third party rights.` },
    { text_type: "para", text: `6.14 - Users agree to deal with other Users in a polite and courteous manner and to respond promptly to communications from other Users.` },
    { text_type: "para", text: `6.15 - All Users promise us that they will only use any personal information of other Users strictly in accordance with applicable data protection laws and regulations.` },
    { text_type: "para", text: `6.16 - If you have any complaint about another User, you must notify us promptly via our contact form at sitterpoint.co.uk/contact. We may in our discretion try to resolve the dispute but we do not promise to get involved.` },
    { text_type: "para", text: `6.17 - All Services included in and offered by the SitterPoint Service are indivisible and cannot be subscribed to individually.` },
    { text_type: "para", text: `We provide additional details about these services below.` },

    { text_type: "section_sub_title", text: `EXCHANGE MANAGEMENT` },
    { text_type: "para", text: `6.18 - SitterPoint provides its Members with an exchange management service via its Platform, allowing the Sitting Member to publish a listing to propose an Exchange of his or her Residence, and allowing the Using Member to review the Listing and book the Residence proposed for Exchange by a Sitting Member. SitterPoint is merely a platform-provider. When Members enter into an Exchange, they are entering into a contract directly with each other. SitterPoint does not become party to any Exchange, nor is SitterPoint a real estate broker or insurer. SitterPoint does not act as an agent in any capacity for any Member during an Exchange, unless otherwise explicitly set forth herein. The exchange management services provided by SitterPoint are automatized. As such, all contact between Members is initiated solely on the Members’ efforts and SitterPoint does not intervene in any manner unless otherwise explicitly described herein.` },
    { text_type: "para", text: `6.19 - Each Exchange is a limited license by a Sitting Member to a Using Member to enter, occupy, and use the Residence for the provided period. To the extent permitted by applicable law, Sitting Members reserve the right to re-enter the Residence subject to the terms of the particular Exchange. Should a Using Member fail to vacate the Residence at the end of the defined Exchange period, Sitting Members may take all permitted legal action to make the Using Member vacate. Using Members agree to pay, for each additional 24-hour period in which the Using Member has failed to vacate the Residence, any damages claimed by the Sitting Member relating to the Using Member’s failure to vacate. Using Members authorize SitterPoint to pay such damages out of Using Members’ Security Deposit.` },
    { text_type: "para", text: `6.20 - Members wishing to execute an Exchange via the Platform do so on their own initiative and under their own responsibility.` },
    { text_type: "para", text: `6.21 - Members agree not to enter into any exchange of a Residence listed on the Platform outside the Platform. A violation of this provision will result in immediate Account closure. Member information (name, telephone number, address) must only be communicated to another Member during execution of the Exchange via the Platform.` },
    { text_type: "para", text: `6.22 - SitterPoint in no way guaranties the legality of any Exchange entered into between Members, nor its proper performance by any Member. Further, SitterPoint makes no guaranties regarding the availability of the Residence, the conditions of the Residence, or any other conditions imposed by one Member on another regarding an Exchange.` },
    { text_type: "para", text: `6.23 - Members alone assume the risks associated with providing a Residence or occupying a Residence in furtherance of an Exchange, in particular, the risk of damage to the property or theft of certain items furnishing it. Sitting Members are free to select the Using Member who will use his or her Residence. Preliminary correspondences between Sitting Members and Using Members should be used to discuss (and avoid) any issues that may arise from an Exchange.` },

    { text_type: "section_sub_title", text: `MESSAGING SERVICE` },
    { text_type: "para", text: `6.24 - SitterPoint provides Members with a free messaging service for the purposes of facilitating an Exchange or to share experiences regarding past Exchanges; however, use of the messaging service alone does not permit Members to enter into an Exchange. The messaging service is automatized and as such, SitterPoint does not intervene in any manner.` },
    { text_type: "para", text: `6.25 - Members are solely responsible for any information communicated via the SitterPoint messaging service. Any information communicated between Members on the messaging service shall in no way bind SitterPoint, nor does SitterPoint in any way guaranty the information’s accuracy or completeness.` },

    { text_type: "section_sub_title", text: `VERIFICATION` },
    { text_type: "para", text: `6.26 - SitterPoint provides a verification service, wherein SitterPoint will request verification of each Member’s name, address and email address when the Member creates an Account. All Members must provide proof of address and a form of identification in order to verify the Member’s address and identity. SitterPoint reserves the right to accept or reject supporting documentation for verification at its sole discretion. When a Member’s address, email address and first and last name have been verified, such Member shall be deemed a “Verified Member” on their profile. Verification will be required before any Member can enter into an Exchange.` },
    { text_type: "para", text: `6.27 - Just because a Member, or a Member’s email address and phone number, have been verified does not guarantee the accuracy of any content provided by that Member, nor guaranty the accuracy of any Listing. SitterPoint provides no endorsements or assurances regarding Verified Members or their Listings.` },
    { text_type: "para", text: `6.28 - Once Verification has been performed for a Member, a Member can enter into Exchanges.` },

    { text_type: "section_sub_title", text: `SECONDARY ADDITIONAL SERVICES OFFERED BY THE SitterPoint SERVICE` },
    { text_type: "para", text: `6.29 - All secondary Services offered by the SitterPoint Service are indivisible and cannot be subscribed to individually.` },

    { text_type: "para", text: `Ratings, Responses and Reviews` },
    { text_type: "para", text: `6.30 - Following an Exchange, the Sitting Member and Using Member may leave reviews of the Residence and Using Member and submit a related star rating, which will then be available on the Member’s Account profile and/or in relation to a Member’s Listing. Sitting Members and Using Members have 30 calendar days from the posting of the review or rating to contact SitterPoint to request that SitterPoint facilitate the posting of a response to the review or rating. Responses to reviews or ratings may be no more than 500 characters long.` },
    { text_type: "para", text: `6.31 - Such reviews, responses or ratings only reflect the views of the individual Members and SitterPoint does not review the reviews, responses or ratings nor guarantee their accuracy. Each Member providing a review, response or rating agrees to only provide information that is accurate and not defamatory or contain offensive language. Reviews, responses and/or ratings may not be made for purposes of extortion only. Members may not instruct other Members to leave fake reviews, responses or ratings.` },
    { text_type: "para", text: `6.32 - SitterPoint may choose to deny the posting of a review, response, or rating at its sole discretion.` },

    { text_type: "para", text: `Sitter Protection upon Parent Cancellation` },
    { text_type: "para", text: `6.33 - If a Parent Member cancels an Exchange, the Sitter Member has the choice whether to return the relevant SittingPoints to the Parent Member. If a Parent Member cancels due to an emergency situation and provides proper relevant documentation, SitterPoint may, at its sole discretion, return the SittingPoints to the Parent Member (while allowing the Sitting Member to keep the relevant SittingPoints).` },

    { text_type: "para", text: `Parent Protection upon Sitter Cancellation` },
    { text_type: "para", text: `6.34 - If a Sitter Member cancels an Exchange, the applicable SittingPoints will be returned to the Parent Member, and any Pay as You Go fees paid to SitterPoint will be refunded to the Parent Member.` },

    { text_type: "para", text: `Member Protection upon SitterPoint Cancellation` },
    { text_type: "para", text: `6.35 - SitterPoint may be required, from time to time, to cancel an Exchange in emergency or other circumstances which, at SitterPoint’s sole and reasonable discretion, are necessary in order to prevent harm to Sitterpoint, any Members, or any third party, or for any other reason set forth in these Terms. In this case, SitterPoint may, at its sole discretion and dependent on the particular circumstances of the reason for cancellation: (i) return SittingPoints to the Parent Member; (ii) allow the Sitter Member to keep any SittingPoints attributable to the Exchange; and/or (iii) make any other reimbursements or payouts it, in its sole discretion, deems necessary.` },


    { text_type: "section_title", text: `7. ACCOUNT` },
    { text_type: "para", text: `7.1 - In order to register with the Platform, you must first create an Account by providing the personal information requested (including without limitation first and last name, date of birth, home address and email address). You may only create one Account for your immediate household, and you may choose to treat adult members of your immediate household as authorized users of your Account.` },
    { text_type: "para", text: `7.2 - Members must keep their Account information up-to-date at all times. SitterPoint shall under no circumstances be held responsible for information communicated by Members that may be erroneous or fraudulent. Upon registration, you must: (i) be 18 years of age at the time you register your account; (ii) have the legal or contractual right to offer the Residence via the Platform and continue to have that right during your use of the Platform; and (iii) have the authority to enter into an Exchange. In addition, a Member Account is for that Member’s sole, personal use, and Accounts may not be created for business or other commercial purposes. SitterPoint reserves the right to refuse (without justification and without recourse or compensation) the registration of any Member Account for any reason, at any time, in its sole and absolute discretion.` },
    { text_type: "para", text: `7.3 - Upon registration, Members will select a username and password, which can only be changed at the Member's request. Each Member is solely responsible for his or her username and password and agrees to keep them confidential and not to disclose them to anyone, in any for, for any reason. Each Member shall be solely responsible for any third-party use of a Member’s username or password and for any actions or statements made through his or her Account by a third party, whether fraudulent or not, and shall indemnify SitterPoint against any claim relating thereto. Moreover, SitterPoint shall not liable for any identity theft of any Member. If a Member has reason to believe that a third party is fraudulently using the Member’s identity or is otherwise using the Member’s account in an unauthorized manner, the Member must immediately inform SitterPoint. In the event of loss or theft of a Member’s username or password, the Member must immediately change his or her username and/or password and the Member shall be solely responsible for any losses relating to such loss or theft.` },
    { text_type: "para", text: `7.4 - You agree to only create and/or use one Account. Any exception to this rule must be granted SitterPoint’s prior written approval. Any Accounts created in violation of the foregoing shall be subject to immediate suspension and/or termination.` },
    { text_type: "para", text: `7.5 - Members are not and should not be construed as an employee, agent, joint venture, or partner of SitterPoint for any reason, and that all Members are acting exclusively on their own behalf and benefit and not those of SitterPoint. SitterPoint does not in any manner control or direct any Member and each Member acknowledges and agrees that he or she has complete discretion on whether to post a Listing or enter into an Exchange. If you register your Account using a third-party social networking service, you can disable the connection between your social networking account and SitterPoint by accessing your Account settings.` },
    { text_type: "para", text: `7.6 - Unless otherwise specifically stated on our Service, your account is for your personal use only and is non-transferable. You must not authorise or permit any other person to use your account. You must take reasonable care to protect and keep confidential your password and other account or identity information. You must notify us immediately of any apparent breach of security such as loss, theft, misuse or unauthorised disclosure or use of a password. You are responsible for third parties who use your account or identity (unless and to the extent that we are at fault).` },

    { text_type: "para", text: `8. LISTINGS` },
    { text_type: "para", text: `8.1 - Sitting Members must keep their Listings up-to-date and accurate at all times, including:` },
    { text_type: "para", text: `(a) All User Content included in the Listing;` },
    { text_type: "para", text: `(b) Description, profile details, location, family details and availability dates;` },
    { text_type: "para", text: `(c) Deficiencies, restrictions (including any house rules applicable to Parent Members and their children); and` },
    { text_type: "para", text: `(d) Any other information requested by SitterPoint.` },
    { text_type: "para", text: `8.2 - A Family can only appear on the Account of a single Member, even if several people hold responsibililty for this Family. Listings may not contain hyperlinks.` },
    { text_type: "para", text: `8.3 - Any particular terms or conditions listed in a Member’s Listing cannot conflict with these Terms.` },
    { text_type: "para", text: `8.4 - SitterPoint reserves the right to request that certain minimum information be included in any Listing and that Listing information be presented in a particular format.` },
    { text_type: "para", text: `8.5 - The placement of Listings in SitterPoint search results may vary and depend on a variety of factors relating to the Listing details, Sitter Member's Profile details and the Parent Member’s particular search. SitterPoint makes no guarantees regarding the particular placement of a Member’s Listing in search results.` },
    { text_type: "para", text: `8.6 - All Members represent and warrant that the posting of a Member’s Listing and any acceptance of an Exchange will not breach any agreements such Member has entered into with any condominium association, homeowners’ association, cooperative agreements, or other such agreements, and (i) complies with any laws, including zoning laws, tax requirements, or license or permit requirements, or any other laws, rules or regulations applicable to the Residence or Listing. All Sitting Members are responsible for their own acts or omissions or those of any persons present at the Residence, besides the Using Member and his or her invitees.` },

    { text_type: "section_title", text: `9. VERIFICATION` },
    { text_type: "para", text: `9.1 - SitterPoint will request verification of each Member’s name, address and email address when the Member creates an Account. All Members must provide proof of address and a form of identification when the Member creates an Account. SitterPoint reserves the right to accept or reject supporting documentation for verification at its sole discretion. When a Member’s address, email address and first and last name have been verified, such Member shall be deemed a “Verified Member” on their profile.` },
    { text_type: "para", text: `9.2 - Just because a Member, or a Member’s email address, have been verified does not guaranty the accuracy of any content provided by that Member, nor guaranty the accuracy of any Listing. SitterPoint provides no endorsements or assurances regarding Verified Members or their Listings.` },

    { text_type: "section_title", text: `10. GUIDANCE` },
    { text_type: "para", text: `10.1 - We do not guarantee that guidance or other general information which we provide on our Service is accurate or up to date and we do not accept legal responsibility for it. Before acting on such information, you must make your own appropriate and careful enquiries including as to its accuracy and suitability for your purposes. The information is not intended as professional or other advice and is not tailored to your personal circumstances. Nor is it intended to be a substitute for possession of an appropriate level of training, qualifications, skill and experience in the matters covered. You rely on such information at your own risk.` },

    { text_type: "section_title", text: `11. ${GUEST_FAMILIES.toUpperCase()}` },
    { text_type: "para", text: `11.1 - As a Parent, you shall provide us with such identification documents or other information as we may require in order to set up your account. You consent to SitterPoint carrying out checks on such documents and information, including by the use of third parties.` },
    { text_type: "para", text: `11.2 - You represent and warrant to SitterPoint that:` },
    { text_type: "para", text: `(a) the details that you provide to us are accurate and up to date (including details obtained from your Facebook account); and` },
    { text_type: "para", text: `(b) neither you nor any member of your household have ever been convicted of any criminal offence.` },
    { text_type: "para", text: `11.3 - You acknowledge that your preferred Sitter, if you have one, may be unavailable from time to time, whether due to illness or holiday or through ceasing to use SitterPoint.` },
    { text_type: "para", text: `11.4 - You agree to treat Sitters courteously and lawfully and to provide a safe and appropriate environment for them in compliance with all applicable laws and regulations. You also agree to provide them with all reasonable information and co-operation required to enable them to provide the services you have requested.` },
    { text_type: "para", text: `11.5 - Once you have been connected to a Sitter through the Platform, you shall not book that Sitter other than through the Platform’s booking functions.` },
    { text_type: "para", text: `11.6 - Both parties have the right to cancel the engagement.` },
    { text_type: "para", text: `11.7 - It is your responsibility to ensure you comply with appropriate regulations in regard to employment and tax.` },

    { text_type: "section_title", text: `12. ${HOST_FAMILIES.toUpperCase()}` },
    { text_type: "para", text: `12.1 - As a Sitter, you shall provide us with such identification documents or other information as we may require in order to set up your account. You consent to SitterPoint carrying out checks on such documents and information, including by the use of third parties.` },
    { text_type: "para", text: `12.2 - You represent and warrant to SitterPoint that:` },
    { text_type: "para", text: `(a) you are at least 18 years of age;` },
    { text_type: "para", text: `(b) that you are legally entitled to reside and babysit children in the UK;` },
    { text_type: "para", text: `(c) the details that you provide to us are accurate and up to date (including details obtained from your Facebook account); and` },
    { text_type: "para", text: `(d) you have never been convicted of any criminal offence.` },
    { text_type: "para", text: `12.3 - Once you have been connected to a Parent through the Platform, you shall not make a booking with that Parent other than through the Platform’s booking functions.` },
    { text_type: "para", text: `12.4 - You are solely responsible for all taxes, national insurance and/or similar contributions payable in respect of the payments made to you.` },

    { text_type: "section_title", text: `13. OTHER USERS` },
    { text_type: "para", text: `13.1 - You accept that we have no obligation to vet or monitor Users or their Content. We do not endorse or recommend any Users. You rely on such information and/or deal with other Users at your own risk. We accept no legal responsibility for the accuracy of, or otherwise in relation to, any such Content or in connection with any dealings between Users. It is your responsibility to carry out careful and detailed investigations before dealing with other Users including use of or reliance on their Content. You should not assume that any Content from another User is accurate and be aware that a person may not be who he or she claims to be.` },
    { text_type: "para", text: `13.2 - If we state on our Service that we hold a document such as a regulatory registration certificate or criminal records check, we do not guarantee that that any such document is accurate or valid or suitable for your purposes. If you are a Parent, it is your responsibility to seek access to the document via the relevant Childcare Provider and satisfy yourself including making additional enquiries where appropriate.` },
    { text_type: "para", text: `13.3 - You acknowledge that in using the Service you may encounter behaviour or Content which you consider inappropriate. If so, please contact us at us at sitterpoint.co.uk/contact (including if you wish to give us notice of defamatory material). Please also use any available blocking mechanisms, seek relevant external help If appropriate (e.g. from law enforcement authorities) and/or stop using the Service.` },
    { text_type: "para", text: `13.4 - If you are a Sitter Member, you acknowledge that we permit Parent Members to post reviews in relation to you and/or your services and that these will be publicly available for viewing and will remain so after this agreement ends. We are not responsible for monitoring or editing reviews. You acknowledge that such reviews may be critical or defamatory of you.` },

    { text_type: "section_title", text: `14. PAYMENTS` },
    { text_type: "para", text: `14.1 - The Parent shall pay SitterPoint a Booking Fee (including VAT) (the “Booking Fee”) for each appointment made with a Sitter through the App, unless the Parent is on a paid subscription plan, ‘Gold Member’, in which case the Booking Fee is waived on every sit during the period of that subscription. Parents who subscribe to ‘Gold Member’ pay for their subscription period up front, and the subscription renews unless cancelled by the parent. The Booking Fee amount shall be collected by SitterPoint upon completion of the appointment from the payment card specified by the Parent when registering for an Account (“Parent Card”). The Booking Fee payable on any sit is made visible to parents before they book any sit inside of their app.` },
    { text_type: "para", text: `14.2 - While parts of our Service are available to Users free of charge, certain features are available only to Users who subscribe or (in the case of our Platform) pay the applicable one-off fee. Subscriptions can be acquired for the periods and by the payment methods specified on our Service. Payment is in advance. We reserve the right at any time to vary or remove any benefits applicable to subscribers.` },
    { text_type: "para", text: `14.3 - The prices shown on our website include any applicable VAT unless we say otherwise.` },
    { text_type: "para", text: `14.4 - If we have mis-priced any part of our Service, we are not obliged to supply the Service provided we notify you. If we do notify you, then you can decide if you want continue with the Service at the correct price but, if you do not, we will provide a full refund of any payments already made.` },
    { text_type: "para", text: `14.5 - Where stated on our Service when you subscribed, your subscription will continue to be auto-renewed for the same subscription period which you signed up to unless you end your subscription before the renewal date by following the instructions on our Service. Ending your subscription does not entitle you to a refund (unless Consumer cooling off rights apply). You authorise us and our payment provider to charge your payment card for the relevant amounts when payments are due in accordance with this agreement.` },
    { text_type: "para", text: `14.6 - We may at any time change our subscription prices. The new rate takes effect if you apply for a new subscription after we post the new prices on our Service. For existing subscribers, we will give you notice by email at least one month before any price change takes effect. If you do not accept the new fee, you should end your subscription by following the instructions on our Service. Otherwise the next renewal of your subscription after the one month’s notice will be at the new price.` },
    { text_type: "para", text: `14.7 - You must contact us immediately with full details if you dispute any payment.` },
    { text_type: "para", text: `14.8 - If any amount due to us is unpaid (including unjustifiable chargeback), without prejudice to any other remedy that may be available to us, we may charge you interest (both before and after judgment) on the amount unpaid at the rate for the time being that would be applicable if the debt were a qualifying debt under the Late Payment of Commercial Debts (Interest) Act 1998.` },
    { text_type: "para", text: `14.9 - The Parent shall also pay the fee for the sit which is the number of minutes duration of the sit as stated in the app at the end of the sit, multiplied by the sitter’s hourly rate as stated at the time the booking is confirmed. The sitter’s hourly rate is inclusive of any Platform Fee that is applied to each appointment made through the App. All fees shall be collected by SitterPoint upon completion of the appointment from the payment card specified by the Parent when registering for an Account (“Parent Card”). The formula applied to calculate the amount due as a Platform Fee is determined at the sole discretion of SitterPoint and may change from time to time. The calculation formula that is being applied at any one time is viewable here.` },
    { text_type: "para", text: `14.10 - Before the start of the agreed sit, the Parent Card shall be pre-authorised to cover the amount of the booking, any booking fee due, plus any potential overrun past the pre-agreed end time. Upon completion of the appointment, SitterPoint shall collect from the Parent Card the amount agreed between the Parent and the Sitter plus any booking fee that was due, as recorded in the App.` },
    { text_type: "para", text: `14.11 - The parent or the sitter may elect to cancel the mutually agreed sit at any point prior to the agreed start time. If the parent cancels the mutually agreed sit, the parent may be charged a cancellation fee. As the agreement for the sit is between the Parent and the Sitter, SitterPoint accepts no liability for cancelled bookings. The current cancellation policy that is applied at any one time is viewable here.` },
    { text_type: "para", text: `14.12 - All charges/fees become due once the booked sit reaches the agreed start time as recorded in the App, or when the agreed sit is cancelled by the parent in line with the terms of the current cancellation policy, whichever comes first. Payment will be facilitated as soon as practicable by SitterPoint using the preferred payment method designated in the Parent Account, after which SitterPoint will send a receipt by email. If the primary Parent Account payment method is determined to be expired, invalid or otherwise not able to be charged, the Parent agrees that SitterPoint may, as the payment collection agent, use a secondary payment method in the Parent Account, if available.` },
    { text_type: "para", text: `14.13 - Prices will be listed in the currency of the Member’s country of residence, or, if such information is not available, in Great British Pounds. Members shall be solely responsible for the payment of all applicable taxes resulting from the Member’s use of the Platform. SitterPoint shall in no way be liable for any Member bank or credit card fees or charges relating to the Member’s payment for the Services via the Platform.` },
    { text_type: "para", text: `14.14 - Any taxes that may apply will be calculated based on SitterPoint’s place of business. Nevertheless, if a conflicting rule in a European Union member state having jurisdiction over the place where services are effectuated applies, the paid Service may be billed by SitterPoint with the VAT of the paying Member’s applicable jurisdiction. This VAT will be collected by SitterPoint and remitted to the relevant local taxing authority.` },


    { text_type: "section_title", text: `15. RATES AND TERMS OF PAYMENT` },
    { text_type: "para", text: `15.1 - Members may register, contact other Members, access the Website, access the Listings, and message other Members, and have SitterPoint verify their email and phone number, free of charge.` },
    { text_type: "para", text: `15.2 - All Exchanges will require either, a Member to subscribe to the membership Gold Member Account, or for a Member to pay a one-off pay-as-you-go fee. Details regarding pricing plans are available at sitterpoint.co.uk/plans.` },
    { text_type: "para", text: `15.3 - Subscription to the SitterPoint Service is available as a monthly subscription granting Members access to an unlimited number of Exchanges (subject to available SittingPoints) over the validity period of the above-referred subscription. Subscriber Members pay a monthly subscription fee for an unlimited number of exchanges without paying booking fees during a one (1) month period of the subscription validity dates. Subscriptions are renewed automatically for successive 1-month periods unless cancelled on or before the last day of the current 1-month term. If the Member terminated its subscription during the current term, no refund, even a partial refund, will be due.` },
    { text_type: "para", text: `15.4 - Before confirmation of any paid subscription-based Services, Members will be informed of the relevant pricing and will be required to accept such pricing before proceeding to payment. Prices offered by SitterPoint are subject to change. Any change in price shall take effect immediately upon notice and shall apply to any future purchases made by a Member after such changes come into effect. Furthermore, SitterPoint may choose to temporarily modify pricing for the Services for promotional events or in relation to new Services. Such changes shall take effect on the date provided in the relevant notice to Members. SitterPoint may offer new Services at any time, with or without notice.` },
    { text_type: "para", text: `15.5 - Please note that no payment made by SitterPoint pursuant to any SitterPoint Service, whether to a Sitting Member or a Using Member, shall release, reduce or waive any liability of a Member for any damage, injury, or other loss caused by that Member or any other Member invitee, or for which a Member is otherwise responsible.` },

    { text_type: "section_title", text: `16. SITTERPOINTS` },
    { text_type: "para", text: `16.1 - Any Member activity on the Platform (registering your Account, completing your profile, getting your Account and Residence address verified, sponsoring new Members, entering into an Exchange, etc.) allows Members to earn SittingPoints. A Member may receive SittingPoints as a welcome gift after creating an Account. If the Member is eligible for a welcome gift, SitterPoint, at its sole discretion, will award one welcome gift per Member.` },
    { text_type: "para", text: `16.2 - The number of SittingPoints earned per Exchange varies according to SitterPoint’s evaluation made of the length of sit, time period (e.g. day, week, month, year), the number of children and location. The Member cannot make modifications to the SittingPoints evaluation proposed by SitterPoint.` },
    { text_type: "para", text: `16.3 - For unilateral Exchanges, only the Sitting Member earns SittingPoints.` },
    { text_type: "para", text: `16.4 - These SittingPoints allow a Parent Member's children to stay at a Sitting Member’s home, during the dates of his or her choice (subject to Sitting Member's availability and the Sitting Member’s acceptance).` },
    { text_type: "para", text: `16.5 - SittingPoints are non-transferable, non-redeemable for cash or other property, and have no inherent monetary value. Points are non-transferable and non-divisible, unless expressly provided for in this agreement, and may not be combined with other Member’s accounts. Additionally, points cannot be transferred by operation of law, such as by inheritance, in bankruptcy or in connection with a divorce. Any attempt to circumvent these rules by any means is prohibited. Additional details regarding SittingPoints are detailed here.` },
    { text_type: "para", text: `16.6 - The credit, debit, and other management of SittingPoints is automatized and does not imply any intervention by SitterPoint.` },

    { text_type: "section_title", text: `17. INSURANCE` },
    { text_type: "para", text: `17.1 - Sitting Members are solely responsible for obtaining and maintaining valid homeowners’, renters’, third-party public liability insurance or other insurance as may be required by relevant law, relating to the Exchange and any amenities relating thereto. Such insurance must remain valid and in force throughout the duration of the Exchange. Any lapse in such insurance may result in the immediate cancellation of a Sitting Member’s Account. In addition, SitterPoint may request that a Sitting Member promptly provide proof of such insurance and any other information relating to such demand. Failure to respond or provide accurate information may result in cancellation of a Member’s Account.` },
    { text_type: "para", text: `17.2 - It is the responsibility of Members to review and understand their own insurance coverage and the coverage provided by any other Member with whom a Member enters into an Exchange, including property, and liability. You shall remain solely liable in the event that your participation in an Exchange violates an agreement or policy with your insurance provider.` },

    { text_type: "section_title", text: `18. PROMOTIONS` },
    { text_type: "para", text: `18.1 - SitterPoint may offer promotional offers from time to time which may include discount codes or account credits, may be subject to expiration dates and may only be applicable to selected users of the App. SitterPoint reserves the right to withhold or remove credit from a user account or end a promotion without notice if at the sole discretion of SitterPoint it believes a user is not acting in good faith in relation to the terms of the promotion.` },
    { text_type: "para", text: `18.2 - We may offer discount codes from time to time. Such codes may only be applied to purchases made through the account in respect of which the discount code was offered and registered and are not transferable or redeemable for cash. Unless otherwise stated: codes (1) are only available for future new orders placed online; (2) cannot be used retrospectively; (3) can only be redeemed once per customer; and (4) expire after 12 months. You cannot use more than one discount code per transaction unless we state otherwise; if we do so, the order in which the codes are to be applied is in our sole discretion.` },
    { text_type: "para", text: `18.3 - We reserve the right to reject any discount code if we consider that it is being used in breach of these terms. Discount codes are subject to any additional specific terms and conditions which are specified at the point of issue. We reserve the right to discontinue or otherwise modify any discount codes at any time without prior notice.` },

    { text_type: "section_title", text: `19. DISPUTES` },
    { text_type: "section_sub_title", text: `DISPUTES BETWEEN ${GUEST_FAMILIES} and ${HOST_FAMILIES}.` },

    { text_type: "para", text: `19.2 - If any disputes or disagreements arise between ${GUEST_FAMILIES} and ${HOST_FAMILIES} relating to the services provided by ${HOST_FAMILIES} or payments made by or due from ${GUEST_FAMILIES}, the ${GUEST_FAMILIES} and the ${HOST_FAMILIES} are responsible for resolving any such disputes directly with each other. SitterPoint shall not be a party to any such dispute and SitterPoint is not obliged to take any action toward resolving the dispute.` },


    { text_type: "section_title", text: `20. COMPLAINTS` },
    { text_type: "para", text: `20.1 - If you have any complaints, please contact us via the contact details shown below.` },
    { text_type: "para", text: `20.2 - Company name: SitterPoint Ltd` },
    { text_type: "para", text: `20.3 - Trading name: “SitterPoint”` },
    { text_type: "para", text: `20.4 - Country of incorporation: England and Wales.` },
    { text_type: "para", text: `20.5 - Registered number: 13822882` },
    { text_type: "para", text: `20.6 - Other contact information: Website: sitterpoint.co.uk/contact` },

    { text_type: "section_title", text: `21. REFUNDS` },
    { text_type: "para", text: `21.1 - Any subscription refunds approved and authorized by SitterPoint will be issued via the payment method used by the Member no later than twenty (20) business days following the date of cancellation. (only if the refund request has been confirmed by SitterPoint under its ToU, we will refund within the next 20 days since the request as long as there are no Exchanges scheduled or booked under the Account).` },
    { text_type: "para", text: `21.2 - In case of annual subscription, the right of withdrawal will apply to each renewal, and must be exercised within fourteen (14) days from the first day of the automatic subscription renewal.` },

    { text_type: "section_title", text: `22. LICENSE` },
    { text_type: "para", text: `22.1 - In return for you agreeing to comply with these terms of use, SitterPoint grants you a licence to use the App on each device (e.g. phone, tablet) you use to download the App. We only grant this licence to you and you cannot transfer or sell it to anyone else. We grant identical and similar licences to all users of our App. The licence we grant is subject to these terms of use, SitterPoint’s Privacy and Cookie Policy and the App store Rules.` },
    { text_type: "para", text: `22.2 - Apart from User Content (as defined in clause 11.1 below), all images, text, icons and other content on the App (“Content”), as well as the arrangement of the Content on the App, is protected by copyright and other intellectual property rights. Except as provided in these terms of use, or otherwise in writing by SitterPoint, you are not granted any licence to use the Content or its arrangement. Nothing in these terms of use grants you a licence to use any SitterPoint trade marks or the trade marks of any third parties on the App.` },
    { text_type: "para", text: `22.3 - Unless you have entered into a separate written agreement with us, you shall not copy, republish, frame, link to, download, transmit, modify, adapt, rent, lease, loan, sell, assign, distribute, license or sublicense the App or any of the Content. No copyright and/or other intellectual property notices or watermarks on any Content shall be deleted or modified.` },
    { text_type: "para", text: `22.4 - You shall not disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the App except to the extent allowed by law.` },
    { text_type: "para", text: `22.5 - If you breach any of the terms in these terms of use, your permission to use the App automatically terminates. In addition, SitterPoint shall have the right to block, restrict, disable, suspend or terminate your access to all or any part of the App or its Content at any time in its discretion without liability to you.` },

    { text_type: "section_title", text: `23. UPTIME` },
    { text_type: "para", text: `23.1 - While SitterPoint endeavours to ensure that the App is normally available 24 hours a day, SitterPoint shall not be liable if for any reason the App is unavailable at any time or for any period.` },
    { text_type: "para", text: `23.2 - Access to the App may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for reasons beyond SitterPoint’s control.` },
    { text_type: "para", text: `23.3 - When you register with us and choose a password to protect your secure account, you are responsible for maintaining the confidentiality of your password. If you become aware of any unauthorised use of your account, you must notify us and you should change your password immediately. You are solely responsible for all activities that occur under your password and/or account. You shall not permit anyone else to use your account.` },
    { text_type: "para", text: `23.4 - We do not guarantee that the Service will be uninterrupted or error-free. We are entitled, without notice and without liability (a) to suspend the Service for repair, maintenance, improvement or other technical reason and (b) to make changes to the Service provided these don’t have a seriously negative affect the Service.` },
    { text_type: "para", text: `23.5 - You acknowledge that technology is not always secure and you accept the risks inherent in use of the Internet or other technology for the purpose of the Service.` },

    { text_type: "section_title", text: `24. PRIVACY` },

    { text_type: "section_sub_title", text: `YOUR PRIVACY` },
    { text_type: "para", text: `24.1 - SitterPoint is committed to respecting your privacy and the privacy of all individuals using the App. More information regarding how SitterPoint may use your personal data can be found in SitterPoint’s Privacy and Cookie Policy which is incorporated into these terms of use by reference.` },
    { text_type: "para", text: `24.2 - You acknowledge and agree that we may process your personal information in accordance with the terms of our privacy and cookies policy which is subject to change from time to time.` },
    { text_type: "para", text: `24.3 - You acknowledge that we do not supply Users with access to any personal or other information provided to us or generated by use of our Service other than as explained on our Service. We do not share any such information with anyone else except insofar as this is necessary for the proper functioning of our Service.` },
    { text_type: "para", text: `24.4 - For additional information about our privacy practices, please read our Privacy Policy, located here.` },

    { text_type: "section_title", text: `25. CLOSING ACCOUNT` },

    { text_type: "section_sub_title", text: `TERMINATION` },
    { text_type: "para", text: `25.1 - We may terminate these terms of use immediately by written notice to you:` },
    { text_type: "para", text: `(a) if you commit a material or persistent breach of these terms of use which you fail to remedy (if remediable) within 14 days after the service of written notice requiring you to do so; and/or` },
    { text_type: "para", text: `25.2 - You may discontinue your use of the App at any time. These terms of use will continue to apply to past use of the App by you.` },
    { text_type: "para", text: `25.3 - On termination for any reason:` },
    { text_type: "para", text: `(a) all rights granted to you under these terms of use shall cease;` },
    { text_type: "para", text: `(b) your profile will no longer be displayed on the App;` },
    { text_type: "para", text: `(c) content posted by you on the App may or may not (at our discretion) remain on the App;` },
    { text_type: "para", text: `(d) you must immediately cease all activities authorised by these terms of use; and` },
    { text_type: "para", text: `(e) you must immediately delete or remove the App from all devices.` },

    { text_type: "section_sub_title", text: `Accounts Closed by SitterPoint` },
    { text_type: "para", text: `25.4 - If a Member acts in violation of these Terms or any other rules or regulations issued by SitterPoint, or exhibits any conduct contrary to our rules of good conduct, values or ethics, or breaks any law, rule, regulation, or any other legal order issued by a governing jurisdiction, SitterPoint reserves the right, without justification and without compensation, to:` },
    { text_type: "para", text: `limit that Member’s access to the Platform;` },
    { text_type: "para", text: `remove any User Content hosted on the Platform; or` },
    { text_type: "para", text: `close an Account.` },
    { text_type: "para", text: `SitterPoint may close the Member's Account without warning or formal notice. By closing an Account for breach of these Terms, SitterPoint does not waive its right to any damages it may otherwise claim related to such breach.` },
    { text_type: "para", text: `Closure of a Member’s Account following a violation of these Terms will result in the termination of the Member’s current subscription, if any, without entitlement to any refund, even partial.` },
    { text_type: "para", text: `SitterPoint reserves the right to sign out, terminate, delete, or purge your Account, any related Listings and any related information from the Platform and our servers if you have not signed in to the Platform for a particular period of time, as determined by us, in our sole discretion.` },
    { text_type: "para", text: `The Member will be informed by email or by notice through the Account of the closure of their Account.` },

    { text_type: "section_sub_title", text: `Closing an Account at a Member’s Request` },
    { text_type: "para", text: `25.5 - Members of the Platform may, at any time and for any reason, terminate their registration on the Platform by requesting the closure of their Account with SitterPoint, at no cost other than those related to the transmission of their request. To cancel an Account, a Member must fill out the form located at sitterpoint.co.uk/contact.` },
    { text_type: "para", text: `25.6 - Members may cancel their Accounts for any reason within fourteen (14) days from the start of their subscription, provided that no Exchanges have been scheduled for such Member. The Member has a period of fourteen (14) calendar days from the first payment in case of a payment in 3 times by credit card.` },
    { text_type: "para", text: `25.7 - This request shall become effective one working day following receipt by SitterPoint of the request to close the Account and all future Exchanges shall be cancelled; however, if an Exchange is in progress on the date of the request, the Account will not be closed until after the Exchange and any related transactions are completed. SitterPoint will confirm cancellation by sending an email to the former Member at his or her email address.` },
    { text_type: "para", text: `25.8 - A Member’s request to close an Account will not result in any refund, even partial, of any amounts paid relating to such Account.` },
    { text_type: "para", text: `25.9 - Upon closure of a Member Account for any reason, all accumulated SittingPoints of that Member will be cancelled.` },
    { text_type: "para", text: `25.10 - Upon closure of a Member Account and/or any other termination of these Terms, the clauses of these Terms that reasonably should survive termination shall remain in effect.` },

    { text_type: "section_sub_title", text: `Ending or suspending this contract` },
    { text_type: "para", text: `25.11 - You may at any time end this contract by closing your account or otherwise following any applicable instructions on our Service. (This doesn’t entitle you to a refund unless you have Consumer “cooling off” rights, explained above.) You may close your account at any time using our self-service online cancellation page at sitterpoint.co.uk/contact. You may at any time end this contract by closing your account or otherwise following any applicable instructions on our Service. (This doesn’t entitle you to a refund unless you have Consumer “cooling off” rights, explained above.)` },
    { text_type: "para", text: `25.12 - We are entitled at any time to end this contract if we terminate our Service as a whole or if in our reasonable opinion it is necessary to do so for security, technical or operational reasons.` },
    { text_type: "para", text: `25.13 - We are entitled to end this contract or suspend part or all of our Service or impose restrictions on our Service if:` },
    { text_type: "para", text: `a)you break this contract;` },
    { text_type: "para", text: `b)you are subject to repeated complaints by other Users and/or negative reviews;` },
    { text_type: "para", text: `c)any fees due to us are unpaid / unjustifiably charged back;` },
    { text_type: "para", text: `d)we think that it is necessary to protect us or others;` },
    { text_type: "para", text: `e)we are required to do so by law or appropriate authority; or` },
    { text_type: "para", text: `f)you or anyone on your behalf acts inappropriately towards our staff.` },

    { text_type: "para", text: `25.14 - If you are a Childcare Provider and we end the contract, we will give you at least 30 days’ notice unless we have a legal or regulatory obligation, or a legal right, to end it earlier, or you have repeatedly broken this contract.` },
    { text_type: "para", text: `25.15 - If we suspend our Service, you remain responsible to pay for our Service during the period of suspension if you were at fault. We are entitled to make resumption of a suspended Service subject to reasonable conditions including payment of a reasonable fee.` },
    { text_type: "para", text: `25.16 - If this contract ends:` },
    { text_type: "para", text: `a)Your right to use our Service and all licences are terminated.` },
    { text_type: "para", text: `b)Existing rights and liabilities are unaffected.` },
    { text_type: "para", text: `c)All clauses in this contract which are stated or intended to continue after termination will continue to apply.` },
    { text_type: "para", text: `d)You must not attempt to re-register for or continue to use our Service if we have given you notice of termination.` },
    { text_type: "para", text: `e)After the end of the contract, we may retain access to information provided or generated by HostFamilies and GuestFamilies in order to deal with disputes, enforce our terms and conditions or manage or improve our Service generally. See our Privacy Policy for more information about our retention of personal information.` },


    { text_type: "section_title", text: `26. SUPPORT` },
    { text_type: "para", text: `26.1 - We do not supply support except to the extent specifically stated on our Service, as may be varied from time to time. You acknowledge that the Appstore has no obligation to supply any maintenance and support services in relation to the App.` },

    { text_type: "section_title", text: `27. COMPATABILITY` },
    { text_type: "para", text: `27.1 - We do not guarantee that the App is or will be compatible with any particular mobile devices or associated operating systems (OS’s). You must check that the App works on your applicable device before you subscribe. You acknowledge that the supplier of the device or OS may issue an update that causes our App to stop working. We may issue App updates through the Appstore; if so, you may not be able to use our App properly or at all until you have downloaded the update, which may be subject to the agreement of new terms and conditions. It is your responsibility to frequently monitor for App updates and to install them as soon as they become available.` },

    { text_type: "section_title", text: `28. IP` },

    { text_type: "para", text: `Intellectual property rights` },
    { text_type: "para", text: `28.1 - The intellectual property rights in all material used on or in connection with our Service are owned by us or by our partners or other Users. For your personal use only, you may view such material on your device. You must not otherwise use such material including copying, selling or adapting it or taking extracts from it without our specific prior written consent. You must not misrepresent the ownership or source of such material, for example by changing or removing any legal notices or author attributions.` },
    { text_type: "para", text: `28.2 - Just to be clear - you must not collect, scrape, harvest, frame or deep-link to any Content on our Service without our specific prior written consent.` },
    { text_type: "para", text: `28.3 - You must not reverse-engineer or decompile any of our software in any way (except to the extent allowed by applicable law). You must not create or use a modified or derivative version of our software or distribute or sublicense our software to third parties. You must take reasonable steps to ensure that our software is not disclosed to any third party.` },
    { text_type: "para", text: `28.4 - If you upload any Content to our Service (including logos, trade marks and brand names), you retain ownership of the intellectual property rights. You allow us at no cost, and forever, to use and adapt all or part of such material however we wish on our Service and on other peoples’ websites (e.g. job search websites such as Google Jobs). You waive your “moral rights” in relation to such Content to the extent legally allowed. You also allow each User to use your Content in accordance with these terms and conditions.` },
    { text_type: "para", text: `28.5 - In the event of any third party claim that the App or your possession and use of the App infringes that third party’s intellectual property rights, we, not the Appstore, will be solely responsible for the investigation, defence, settlement and discharge of any such intellectual property infringement claim.` },

    { text_type: "section_sub_title", text: `USER CONTENT` },
    { text_type: "para", text: `28.6 - The Platform may contain areas in which you or other Members may post or submit information, including text, photographs, images, or other information (“User Content”). We reserve the right to remove or refuse, without any warning or other notification, any User Content that does not comply with the rules set forth herein and/or would undermine the security of the Platform or other Members.` },

    { text_type: "para", text: `Ownership of User Content` },
    { text_type: "para", text: `28.7 - User Content is owned by you or whoever created it. By submitting User Content to the SitterPoint team or posting any User Content on the Platform, you grant us a non-exclusive, transferable, sublicensable, royalty-free, irrevocable, worldwide license to use, copy, perform, reproduce, promote, publish, display and distribute any User Content and all copyright, in and to, each and every photograph, video, image, and all information related to your Listing or other User Content. You grant these rights to us free of charge and without any commitment of an attribution as to authorship, ownership or other similar reference. You represent and warrant that you have the right to grant the rights and license in this paragraph. Please do not post any User Content unless you have permission from the person who took the picture and anyone who is depicted in the picture. We will be entitled to use your User Content without incurring obligations of confidentiality, attribution, or compensation to you.` },

    { text_type: "para", text: `Liability` },
    { text_type: "para", text: `28.8 - You are responsible for determining whether User Content or information is protected by copyright, trademark, trade secret, right of publicity or other proprietary right. Except for that information which is in the public domain or for which you have been given express written permission you agree not to upload, post, or otherwise transmit any User Content or information protected by copyright, trademark, trade secret, right of publicity or other proprietary right without the express permission of the owner of such copyright, trademark, trade secret, right of publicity or other proprietary right. You shall be solely liable for any damage resulting from any infringement of copyrights, trademarks, trade secrets, rights of publicity or other proprietary rights or any other harm resulting from such a submission, and agree to indemnify, defend (at its request) and hold harmless SitterPoint from any claims or losses incurred by it due to your breach of any provision of this section. We take no responsibility for, we do not expressly or implicitly endorse, and we expressly disclaim any liability for the conduct of any Usings or Members or for any views, opinions or statements expressed in any User Content on the Platform. You use the Platform and view the User Content strictly at your own risk.` },
    { text_type: "para", text: `28.9 - We reserve the right to revoke your use or access to the Platform at any time, in our discretion, including, without limitation, if we believe that your conduct violates applicable law or is harmful to the interests of SitterPoint or its subsidiaries and affiliates.` },


    { text_type: "section_sub_title", text: `PLATFORM CONTENT` },
    { text_type: "para", text: `28.10 - The Platform contains materials, text, images, software, scripts, code, designs, graphics, photos, sounds, music, videos, applications, interactive features, and other content (“Platform Content”) that we provide for your educational and general use.` },

    { text_type: "para", text: `Ownership of Platform Content` },
    { text_type: "para", text: `28.11 - Except for any User Content, all of the Platform Content is owned by SitterPoint or others from whom we license the Platform Content, and is protected under the United Kingdom and other countries’ copyright laws.All trademarks, logos, service marks and trade names are owned, registered, and/or licensed by SitterPoint or its parent or affiliate companies.` },
    { text_type: "para", text: `28.12 - You agree not to change or delete any ownership notices from materials downloaded or printed from the Platform.` },
    { text_type: "para", text: `28.13 - You agree not to copy, edit, translate, display, distribute, download, transmit, sell, redistribute, publish, or create derivate works from any content appearing on the Platform, including Platform and User Content, without our prior written consent, unless it is your own User Content that you legally post on the Platform.` },


    { text_type: "para", text: `Application` },
    { text_type: "para", text: `28.14 - Subject to your compliance with these Terms, SitterPoint grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to (i) download and use the Application on your mobile phone, tablet, or other personal devices; and (ii) access and view any Platform Content made available on or through the Platform and accessible to you, solely for your personal and non-commercial use.` },


    { text_type: "section_title", text: `29. MISCELLANEOUS` },

    { text_type: "section_sub_title", text: `GOVERNING LAW` },
    { text_type: "para", text: `29.1 - These Terms and our Privacy Policy shall be governed by and construed in accordance with the laws of the United Kingdom, without respect to its choice of law principles. If a dispute arises between any Member or Using Member regarding the Platform, any legal action will be brought exclusively in the United Kingdom.` },
    { text_type: "para", text: `29.2 - Notwithstanding the foregoing, as a consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident. Nothing in these Terms affects your rights as a consumer to rely on such mandatory provisions of local law.` },


    { text_type: "section_sub_title", text: `SEVERABILITY` },
    { text_type: "para", text: `29.3 - If any part of these Terms is illegal, invalid, unenforceable, or prohibited in any respect under any applicable law or regulation, such provision or part thereof will be deemed to not form part of the contract between us. The legality, validity, or enforceability of the remainder of these Terms will remain in full force and effect.` },

    { text_type: "section_sub_title", text: `NO WAIVER` },
    { text_type: "para", text: `29.4 - No failure or delay by SitterPoint in exercising any right, power or privilege under these Terms will operate as a waiver thereof, nor will any single or partial exercise of any right, power or privilege preclude any other or further exercise thereof or the exercise of any other right, power, or privilege under these Terms.` },
    { text_type: "para", text: `29.7 - If we fail to insist that you perform any of your obligations under these terms of use, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.` },

    { text_type: "section_sub_title", text: `HEADINGS` },
    { text_type: "para", text: `29.5 - The headings in these Terms are for convenience only and have no legal or contractual effect.` },

    { text_type: "section_sub_title", text: `ASSIGNMENT` },
    { text_type: "para", text: `29.6 - SitterPoint may assign its rights to this Agreement without your prior consent and without prior notice to you. You may not assign these Terms without the prior written consent of SitterPoint.` },

    { text_type: "section_sub_title", text: `MODIFICATION` },
    { text_type: "para", text: `29.7 - SitterPoint reserves the right to amend, remove, or add to these Terms at any time with or without notice; however, the date on which these Terms have been last updated shall be reflected at the top of this document. Any modification shall be effective immediately. Please review these Terms of Use whenever accessing or using this site.` },

    { text_type: "section_sub_title", text: `ENTIRE AGREEMENT` },
    { text_type: "para", text: `29.8 - These Terms (including the Privacy Policy) constitute the entire agreement between SitterPoint and you with respect to the subject matter hereof and thereof. These Terms (including the Privacy Policy) supersede all prior or simultaneous representations, discussions, negotiations, letters, proposals, agreements, exchanges, and understandings between you and SitterPoint with respect to the subject matter hereof and thereof, whether written or oral.` },


    { text_type: "section_sub_title", text: `OTHER` },
    { text_type: "para", text: `29.3 - We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these terms of use that is caused by any act or event beyond our reasonable control, including failure of public or private telecommunications networks (“Event Outside Our Control”). If an Event Outside Our Control takes place that affects the performance of our obligations under these terms of use:` },
    { text_type: "para", text: `(a) our obligations under these terms of use shall be suspended for the duration of the Event Outside Our Control; and` },
    { text_type: "para", text: `(b) we will use our reasonable endeavours to find a solution by which our obligations under these terms of use may be performed despite the Event Outside Our Control.` },
    { text_type: "para", text: `29.4 - We may transfer our rights and obligations under these terms of use to another organisation, but this will not affect your rights or our obligations under these terms of use.` },
    { text_type: "para", text: `29.5 - You may only transfer your rights or obligations under these terms of use to another person if we agree in writing.` },
    { text_type: "para", text: `29.6 - A person who is not a party to these terms of use has no right to enforce any term of these terms of use.` },
    { text_type: "para", text: `42.1 - If you use our App,You promise that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties. (This clause is required by Apple, Inc.)` },

    { text_type: "section_sub_title", text: `CONTACT US` },
    { text_type: "para", text: `29.9 - To contact us in writing send your communication to us via our contact form at sitterpoint.co.uk/contact.` },


    { text_type: "section_title", text: `30. PLATFORM AND CONTENT USE` },
    { text_type: "para", text: `30.1 - The Platform is a service offered by SitterPoint, a private organization, and any information that is exclusively available to Members is private and not in the public domain. All content made available exclusively to Members is for the purpose of arranging Exchanges between Members.` },
    { text_type: "para", text: `30.2 - The Platform shall not be used for any solicitations or for any commercial use. Data mining of information from the Platform and/or collecting or storing personal information about Members is strictly prohibited. Any attempt to collect or use personal information from Members or contacting Members or groups of Members other than for the purpose of arranging an Exchange is in violation of these Terms.` },
    { text_type: "para", text: `30.3 - Inquiries and communications may be monitored and filtered by SitterPoint prior to delivery to Members. SitterPoint is not responsible for undelivered inquiries or for missed income as a result of undelivered inquiries.` },
    { text_type: "para", text: `30.4 - Communications sent between you and other Members that are not readily accessible to the general public may be reviewed by us for compliance with these terms, but will be treated by us as private to the extent required by applicable law. We shall have the right to remove any such content that, in our sole discretion, violates, or is alleged to violate, the law or these terms. Notwithstanding this right, you remain solely responsible for the content of the material you post and communications you exchange.` },
    { text_type: "para", text: `30.5 - SitterPoint does not participate in or tolerate the sending of unsolicited commercial email (“SPAM”). SitterPoint reserves the right to cancel (without refund) any Member that mentions SitterPoint or any of its Websites, URLs, or any associated SitterPoint email address in any SPAM message. SitterPoint reserves the right to cancel (without refund) any Member that uses SitterPoint to send any solicitations other than personal Exchange requests or replies. Any violation of this paragraph shall subject the violator to severe penalties and you shall indemnify and hold SitterPoint harmless from and against any and all costs and damages incurred by SitterPoint as a result of such violation.` },

    { text_type: "section_title", text: `31. LINKS TO AND FROM OTHER APPS & WEBSITES` },
    { text_type: "para", text: `31.1 - Links to third party apps and websites on the App are provided solely for your convenience. SitterPoint has not reviewed all of these third party apps and websites and does not control and is not responsible for these apps/websites or their content or availability. SitterPoint does not endorse or make any representations about them, or any material found there, or any results that may be obtained from using them. If you decide to access any of the third party apps/websites linked to the App, you do so entirely at your own risk.` },
    { text_type: "para", text: `31.2 - We may display other peoples’ services, advertising and links to other websites. These may include online training courses, insurance services, payroll services and discount schemes. We do not recommend or endorse, nor are we legally responsible for, those sites or services. You use them at your own risk.` },
    { text_type: "para", text: `31.3 - Apple Inc is a “Third Party Beneficiary”` },
    { text_type: "para", text: `31.4 - Where Apple Inc is the Store: You acknowledge and agree that Apple, and Apple’s subsidiaries, are third party beneficiaries of this agreement, and that Apple will have the right (and will be deemed to have accepted the right) to enforce this agreement against you as a third party beneficiary thereof.` },
    { text_type: "para", text: `31.5 - The Platform may contain links to other sites maintained by third parties, or integrate services provided by third parties (“Third-Party Services”). These Third-Party Services are provided only as a convenience to you. SitterPoint, its subsidiaries and affiliated companies have no control over, and are not responsible for any content, products or services offered by Third-Party Services or found on related websites, or their terms of use or privacy policies. Members are solely responsible for compliance with such Third-Party Services’ terms of use and/or privacy policies. Links to third party sites do not constitute an assumption of liability or sponsorship, endorsement or approval of these sites or the content contained in these sites.` },


    { text_type: "section_title", text: `32. DISCLAIMER` },
    { text_type: "para", text: `32.1 - While SitterPoint endeavours to ensure that the Content is correct, SitterPoint does not warrant the accuracy and completeness of the Content. SitterPoint may make changes to the Content at any time without notice. The Content may be out of date and SitterPoint makes no commitment to update such material.` },
    { text_type: "para", text: `32.2 - The material on the App is provided “as is”, without any conditions, warranties or other terms of any kind. Accordingly, to the maximum extent permitted by law, SitterPoint provides you with the App on the basis that SitterPoint excludes all representations, warranties, conditions and other terms (including, without limitation, the conditions implied by law of satisfactory quality, fitness for purpose and the use of reasonable care and skill) which, but for these terms of use, might have effect in relation to the App.` },
    { text_type: "para", text: `32.3 - Use of the Platform and any information obtained from the Platform is at your sole risk. SitterPoint makes no guarantees or warranties, either express or implied, regarding any Exchange and/or the properties, personal and/or real, included in any Listing or Exchange available through the Platform. SitterPoint makes no warranty of any kind, express or implied, as to the Listings, other Members, or any other information contained on the Platform, including, but not limited to, accuracy and non-infringement. SitterPoint has the right, but not the obligation, to pre-screen, monitor or remove any User Content. SitterPoint uses various tools and procedures designed to screen Members; however, SitterPoint does not perform background checks, require references or its prospects, or accept any obligation to independently verify the information posted by its Members on their Listings. SitterPoint makes no representations or warranties as to the conduct of Members, or the adequacy of a Member's property or family as published on a Listing, including as to whether a relationship between Members complies with federal, state, or local laws, third party agreements, or other rules and regulations.` },
    { text_type: "para", text: `32.4 - ASSUMPTION OF RISK: You assume all responsibility and risk for your use of and reliance on the Platform and any information obtained from or through the Platform, including your interactions with other Members, Exchanges in which you participate, and your use of other Services provided by SitterPoint. Your use of the Platform is voluntary and at your own risk. Some Services may carry inherent risk, and by participating in such Services, you choose to assume those risks voluntarily. The Platform and any feature on the Platform is provided on an “as is,” “as available” basis. The SitterPoint and its subsidiaries and affiliates and their respective officers, directors, employees, agents, and licensees (the “ SitterPoint Parties”) make no representations or warranties of any kind, express or implied, as to the accuracy of any information included on the Platform, the operation of the Platform or the information, content, features, materials, or products included therein.` },

    { text_type: "section_title", text: `33. INDEMNITY` },
    { text_type: "para", text: `33.1 - You shall indemnify SitterPoint against any loss, damages, costs or expenses which are awarded against or incurred by SitterPoint as a result of any claim or threatened claim (including any claim or threatened claim by a third party) arising out of or related to:` },
    { text_type: "para", text: `(a) any breach by you of any of these terms of use or applicable law;` },
    { text_type: "para", text: `(b) your misuse of the App and/or the Services;` },
    { text_type: "para", text: `(c) any messages or other content you post on or transmit via the App;` },
    { text_type: "para", text: `(d) your infringement of intellectual property rights;` },
    { text_type: "para", text: `(e) your failure to provide accurate, up to date information;` },
    { text_type: "para", text: `33.2 - To the fullest extent permitted by applicable law, the SitterPoint Parties disclaim all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose, non-infringement of intellectual property rights or other proprietary rights, and freedom from errors, viruses, bugs, or other harmful components.` },
    { text_type: "para", text: `33.3 - SitterPoint cannot guarantee the continuous and uninterrupted availability or accessibility of the Platform. SitterPoint has the right to restrict or limit the Platform as may be needed to protect its servers, or otherwise carry out maintenance measures.` },
    { text_type: "para", text: `33.4 - Opinions, advice, statements, offers, or other information or content made available through the Platform, but not directly by us, are those of their respective authors, and should not necessarily be relied upon.` },
    { text_type: "para", text: `33.5 - We do not guarantee the accuracy, completeness, or usefulness of any information on the Platform and we neither adopt nor endorse, nor are we responsible for, the accuracy or reliability of any opinion, advice, or statement made by any party other than us. Under no circumstances are we responsible for any loss or damage resulting from any person's reliance on information or other content posted on the Platform or transmitted to Members.` },

    { text_type: "section_title", text: `34. LIABILITY` },

    { text_type: "para", text: `34.1 - Nothing in these terms of use shall exclude or limit either your or SitterPoint’s liability for:` },
    { text_type: "para", text: `(a) death or personal injury resulting from the negligence of the other or their servants, agents or employees;` },
    { text_type: "para", text: `(b) fraud or fraudulent misrepresentation; or` },
    { text_type: "para", text: `(c) breach of any implied condition as to title or quiet enjoyment.` },
    { text_type: "para", text: `34.2 - Save to the extent that these terms of use state otherwise, nothing in these terms of use shall exclude or limit your liability under clause 15 (Indemnity).` },
    { text_type: "para", text: `34.3 - Subject to clauses 16.1 and 16.2, neither you nor SitterPoint shall be liable in contract, tort (including, without limitation, negligence), pre-contract or other representations (other than fraudulent misrepresentation) or otherwise arising out of or in connection with these terms of use for:` },
    { text_type: "para", text: `(a) any economic losses (including, without limitation, loss of revenues, profits, contracts, data, business, anticipated savings or cost of substitute services);` },
    { text_type: "para", text: `(b) any loss of goodwill or reputation; or` },
    { text_type: "para", text: `(c) any special, indirect or consequential losses;` },
    { text_type: "para", text: `in any case, whether or not such losses were within the contemplation of you and SitterPoint at the date of these terms of use, suffered or incurred by you or SitterPoint arising out of or in connection with the provisions of, or any matter under, these terms of use.` },
    { text_type: "para", text: `34.4 - SitterPoint at the date of these terms of use, suffered or incurred by you or SitterPoint arising out of or in connection with the provisions of, or any matter under, these terms of use.` },
    { text_type: "para", text: `34.5 - Subject to clauses 34.1, 34.2 and 34.3, each party’s total liability to the other in relation to all events or series of connected events occurring under these terms of use (and whether the liability arises because of breach of contract, negligence or for any other reason) shall be limited to the greater of:` },
    { text_type: "para", text: `(a) the total amount of the sums you have paid or received (as applicable) through the App in the 12 month period immediately preceding the date of the first event allegedly giving rise to the liability; or` },
    { text_type: "para", text: `(b) £10,000.` },
    { text_type: "para", text: `34.6 - Nothing in these terms of use excludes any statutory rights which may apply to your use of the App which cannot be excluded, restricted or modified by contract.` },

    { text_type: "section_sub_title", text: `Restrictions on our legal responsibility - very important` },
    { text_type: "para", text: `34.7 - Nothing in this agreement in any way limits or excludes our liability for negligence causing death or personal injury or for fraud or fraudulent misrepresentation or for anything which may not legally be excluded or limited. In this section, any reference to us includes our employees and agents, who have the right to enforce this agreement.` },
    { text_type: "para", text: `34.8 - Very important: If you are a Consumer, we shall not be liable for any loss or damage where:` },
    { text_type: "para", text: `a)there is no breach of a legal duty owed to you by us or by any of our employees or agents;` },
    { text_type: "para", text: `b)such loss or damage was not reasonably foreseeable (meaning it was not an obvious consequence of our breach or not contemplated by you and us at the time we entered into this contract);` },
    { text_type: "para", text: `c)(and to the extent that) such loss or damage is caused by you, for example by not complying with this agreement; or` },
    { text_type: "para", text: `d)such loss or damage relates to a business of yours.` },
    { text_type: "para", text: `34.9 - Very important: If you are a Consumer, you will be responsible to us for any reasonably foreseeable loss or damage we suffer (including claims made by other people) arising from your breach of this agreement or misuse of our Service.` },
    { text_type: "para", text: `34.10 - The following clauses apply only if you are not a Consumer:` },
    { text_type: "para", text: `a)If you are or were a subscriber, our liability of any kind (including our own negligence) for any act or omissions or series of connected acts or omissions is limited to the total fees paid by you to us in connection with our Service in the 12 months before the act or omissions complained of.` },
    { text_type: "para", text: `b)In no event (including our own negligence) will we be liable for any:` },
    { text_type: "para", text: `economic losses (including, without limit, loss of revenues, profits, contracts, business or anticipated savings);` },
    { text_type: "para", text: `loss of goodwill or reputation;` },
    { text_type: "para", text: `special, indirect or consequential losses; or` },
    { text_type: "para", text: `damage to or loss of data` },
    { text_type: "para", text: `(even if we have been advised of the possibility of such losses).` },
    { text_type: "para", text: `c)You will indemnify us against all claims and liabilities directly or indirectly related to your use of the Service and/or breach of this agreement.` },
    { text_type: "para", text: `d)To the extent allowed by law, you and we exclude all terms, whether imposed by statute or by law or otherwise, that are not expressly stated in this agreement.` },
    { text_type: "para", text: `e)This agreement constitutes the entire agreement between us with respect to its subject matter and supercedes any previous communications or agreements between us. We both acknowledge that there have been no misrepresentations and that neither of us has relied on any pre-contractual statements. Liability for misrepresentation (excluding fraudulent misrepresentation) relating to the terms of this agreement is excluded.` },
    { text_type: "para", text: `34.11 - If you use our App, the following applies where Apple Inc is the Appstore:` },
    { text_type: "para", text: `a)In the event of any failure of the App to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the App (if applicable). To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be our sole responsibility subject to these terms and conditions.` },
    { text_type: "para", text: `b)We, not Apple, are responsible for addressing your claims or the claims of any third party relating to the App or the end-user’s possession and/or use of that App, including, but not limited to: (i) product liability claims; (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.` },
    { text_type: "para", text: `34.12 - Events outside our control` },
    { text_type: "para", text: `34.13 - We are not liable for failure to perform or delay in performing any obligation under this agreement if the failure or delay is caused by any circumstances beyond our reasonable control including third party telecommunication failures.` },
    { text_type: "para", text: `34.14 - The SitterPoint Parties will not be liable for any damages of any kind arising from any Listings, Exchanges, or the use of the Platform or the accuracy of any information included thereon, including but not limited to direct, indirect, general, special, compensatory, incidental, punitive, or consequential damages or damages resulting from loss of use, data, profits, goodwill, or business interruption, or for any damages for personal or bodily injury, or emotional distress, arising out of or in any way connected with these Terms, the use (or inability thereof) of the Platform, your communication or meeting with other Members, or from your publishing a Listing or entering into an Exchange (whether as a Sitting Member or a Using Member), whether based on warranty, contract, tort, negligence, strict liability or otherwise, even if SitterPoint has been advised of the possibility of such damages and even if the limited remedy set forth herein is found to have failed its essential purpose. Moreover, key exchange is solely and fully the responsibility of the owner, and that SitterPoint is not liable or responsible for the key exchange.` },
    { text_type: "para", text: `34.15 - Except as may otherwise be expressly provided for herein, in no event will SitterPoint’s aggregate liability arising out of or relating to these Terms and Members’ use of the Platform or any Listings or Exchanges exceed the amounts a Member has paid SitterPoint, or one hundred U.S. dollars (US$100) if no payments have been made.` },
    { text_type: "para", text: `34.16 - The limitations of damages set forth above are fundamental elements of the basis of the bargain between SitterPoint and each Member.` },
    { text_type: "para", text: `34.17 - Some jurisdictions do not allow the disclaimer, exclusion or limitation of certain warranties, liabilities and damages, so some of the above disclaimers, exclusions and limitations may not apply to you. In such jurisdictions, the liability of the SitterPoint parties will be limited to the fullest extent permitted by applicable law.` },
    { text_type: "para", text: `34.18 - You agree to indemnify and hold harmless the SitterPoint Parties, from any injury or damage suffered directly or indirectly as a result of the use of your property or use by the Using Member of your property and/or your use of the Sitting Member’s property including but not limited to: (i) any claims for injury or damage to person or property arising from a property you own; (ii) any claims for injury or damage to person or property arising from a property transaction to which you are a party (including claims by Using invitees, and including minor invitees); (iii) a violation of these Terms of Use or Privacy Policy by you or anyone using your computer (or Account, where applicable); (iv) a claim that any use of the Platform by you or someone using your computer (or Account, where applicable) infringes any right of any third party; (v) any misrepresentation by you or misuse of the Platform; (vii) any claims arising out of any interaction you have with another Member or such Member’s invitees, whether within or without the scope of the Platform, including without limitation any breach of any Exchange or any other agreement to be performed by you; or (viii) your breach of any laws, rules or regulations of any applicable jurisdiction. You agree to pay any and all costs, damages and expenses, including, but not limited to, reasonable attorneys’ fees and costs awarded against or otherwise incurred by or in connection with or arising from any such claim, suit, action or proceeding attributable to any such claim.` }

  ]
};


export default terms;
