import { loginUserReset } from './auth/login';
/** Constants */
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

/** Action-creators */
export const showModal = (type, props) => ({
    type: SHOW_MODAL,
    payload: {
      type,
      props
    }
});

export const hideModalAction = () => ({
    type: HIDE_MODAL
});

export function hideModal () {
  return (dispatch) => {
    dispatch(loginUserReset()); // make loginUser happen on LoginModal close.
    dispatch(hideModalAction());
  };
}
