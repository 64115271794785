import React from 'react';
import {
  Button
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { other } from '../../../text';

// opacity: props.opacity || 1

export const StyledButton = styled(Button).attrs(props => ({
  width: props.width || null
}))`

  width: ${props => props.width};
  margin: 0px!important;
  color: ${colours.white}!important;
  background-color: ${colours.orange}!important;
  border-color: ${colours.orange}!important;

  cursor: pointer!important;

`;


export const EditButton = (props) => {
  // console.log('EditButton.props:', props);
  return (
    <StyledButton
      primary
      // icon={props.icon}
      labelPosition={props.icon ? props.labelPosition || 'left' : null}
      fluid={props.fluid}
      compact={props.compact}
      disabled={props.disabled}
      width={props.width}

      // style={{ width: props.width || null, margin: 0, color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}

      onClick={props.onClick}
    >
      { // <Icon name={props.icon || 'edit'} />
      }
      {props.children || other.edit}
    </StyledButton>
  );
};

export default EditButton;
