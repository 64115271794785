import React, { Component } from 'react';
import moment from 'moment';
import { BackButton } from '../../styles/styles';
import { H3, A7 } from '../../styles/text';
import AvailabilityKey from './availability-key';
import CellColumnButton from './cell-column-button';
import colours from '../../styles/colours';
import history from '../Navigation/history';
import { getDateNo, getDay, getYear, getMonth, isBefore, months } from '../../lib/timeHelpers';


class AvailabilityWeek extends Component {

  
  constructor (props) {
    super(props);
    // console.log('constructor this props', this.props);


    this.state = {
      originalDateContext: moment(),
      dateContext: moment(),
      today: moment(),
      dimensions: {
        width: 0,
        height: 0
      },
      cellWidth: 0
    };

    this.container = React.createRef();
    this.checkBoxRef = React.createRef();
    // console.log('this.checkBoxRef:', this.checkBoxRef);

  }


  componentDidMount () {
    const { user_id, home_address } = this.props;
    // console.log('did mount this.container:', this.container);

    const dimensions = {
      width: this.container.offsetWidth,
      height: this.container.offsetHeight
    };

    this.setState({
      dimensions
    });

    const containerWidth = dimensions.width;

    const noOfColumns = 9; // days of the week + 2 for row headers;

    // console.log('renderContent containerWidth:', containerWidth);

    // use if want exactly square cells
    // const cellSize = size = Math.min(containerWidth / columns, containerHeight / rows);

    // use if want full width and min height based on contents - maintain round inner circle
    const cellWidth = containerWidth / noOfColumns;

    this.setState({
      cellWidth
    });

    // console.log('AvailabilityWeek Did Mount home_address: ', home_address);

  }

  componentWillReceiveProps (nextProps) {
    const { home_address } = nextProps;

    // console.log('AvailabilityWeek Will Receive home_address: ', home_address);

  }

  componentWillUnmount () {

    // window.removeEventListener('resize', _.throttle(this.handleWindowSizeChange, 20));
  }


  // need to call this when windowWidth or WindowHeight changes in redux.

  handleWindowSizeChange = () => {

    if (this.container !== null) {
      // console.log('this.container.offsetWidth:', this.container.offsetWidth);
    }

    // update dimensions of calendar container and recalc cellSize
    if (this.container === null) {
      // console.log('do nothing');
    } else if (this.container.offsetWidth === null || this.container.offsetHeight === null) {
        // console.log('do nothing');
    } else {

      const dimensions = {
        width: this.container.offsetWidth,
        height: this.container.offsetHeight
      };

      this.setState({
        dimensions
      });

      const containerWidth = dimensions.width;

      const noOfColumns = 9; // days of the week + 2 for row headers;

      // console.log('renderContent containerWidth:', containerWidth);

      // use if want exactly square cells
      // const cellSize = size = Math.min(containerWidth / columns, containerHeight / rows);

      // use if want full width and min height based on contents - maintain round inner circle
      const cellWidth = containerWidth / noOfColumns;

      this.setState({
        cellWidth
      });

    }


  };

// cal functions

  setMonth = (thismonth) => {
      const monthNo = months.indexOf(thismonth);
      const { dateContext } = this.state;
      let dateObject = Object.assign({}, dateContext);
      dateObject = moment(dateObject).set('month', monthNo);
      this.setState({
        dateContext: dateObject
      });
  }

  nextWeek = () => {
      let dateContext = Object.assign({}, this.state.dateContext);
      dateContext = moment(dateContext).add(1, "week");
      this.setState({
          dateContext: dateContext
      });
      this.props.onNextMonth && this.props.onNextMonth();
  }

  prevWeek = () => {
      let dateContext = Object.assign({}, this.state.dateContext);
      dateContext = moment(dateContext).subtract(1, "week");
      if (!isBefore(dateContext, this.state.today)) {
        this.setState({
            dateContext: dateContext
        });
        this.props.onPrevMonth && this.props.onPrevMonth();
      }

  }


  onSelectChange = (e, data) => {
      this.setMonth(data);
      this.props.onMonthChange && this.props.onMonthChange();

  }

  SelectList = (props) => {
      const popup = props.data.map((data) => {
          return (
            <div key={data}>
              <a
                ref="#"
                onClick={(e)=> {this.onSelectChange(e, data)}}>
                {data}
              </a>
            </div>
          );
      });

      return (
        <div className='month-popup'>
          {popup}
        </div>
      );
  }

  onChangeMonth = (e, month) => {
      // below code is to Select month directly (not used)
      // this.setState({
      //     showMonthPopup: !this.state.showMonthPopup
      // });
  }

  MonthNav = () => {
      return (
        <span className='label-month'
          // onClick={(e)=> {this.onChangeMonth(e, this.month())}}
        >
          {getMonth(this.state.dateContext)}
          {
            // below code is to Select month directly (not used)
            // {this.state.showMonthPopup &&
            //  <this.SelectList data={months} />
            // }
          }
        </span>
      );
  }

  showYearEditor = () => {
      this.setState({
          showYearNav: true
      });
  }

  setYear = (year) => {
      let dateContext = Object.assign({}, this.state.dateContext);
      dateContext = moment(dateContext).set("year", year);
      this.setState({
          dateContext: dateContext
      })
  }

  onYearChange = (e) => {
      this.setYear(e.target.value);
      this.props.onYearChange && this.props.onYearChange(e, e.target.value);
  }

  onKeyUpYear = (e) => {
      if (e.which === 13 || e.which === 27) {
          this.setYear(e.target.value);
          this.setState({
              showYearNav: false
          })
      }
  }

  YearNav = () => {
      return (
        // below code is to Select year directly (not used)
        // this.state.showYearNav ?

          // <input
          //   defaultValue = {year(this.state.dateContext)}
          //   className="editor-year"
          //   ref={(yearInput) => { this.yearInput = yearInput}}
          //   onKeyUp= {(e) => this.onKeyUpYear(e)}
          //   onChange = {(e) => this.onYearChange(e)}
          //   type="number"
          //   placeholder="year"/>
        // :

        <span
          className='label-year'
          // below code is to Select year directly (not used)
          // onDoubleClick={(e)=> { this.showYearEditor()}}
        >
          {getYear(this.state.dateContext)}
        </span>
      );
  }

  onDayClick = (e, day) => {
      this.setState({
          selectedDay: day
      }, () => {
          console.log('SELECTED DAY: ', this.state.selectedDay);
      });

      this.props.onDayClick && this.props.onDayClick(e, day);
  }


  handleClick = (day, time) => {
    const { addAvailability, removeAvailability } = this.props;
    // console.log('this.props', this.props);
    // console.log('this.state', this.state);
    const year = getYear(this.state.dateContext);
    const month = getMonth(this.state.dateContext);
    const availabilityObject = { year, month, day, time };

    // console.log('SELECTED new availabilityObject: ', availabilityObject);

    if (this.isPresent(availabilityObject)) {
      removeAvailability(availabilityObject);
    } else {
      addAvailability(availabilityObject);
    }

    // can we add is recurring pop-up when select?

  }


  isPresent = (availabilityObject) => {

    // console.log('this.props', this.props);
    const { available_dates } = this.props;

    // console.log('availabilityObject:', availabilityObject);
    // console.log('available_dates:', available_dates);

    return available_dates.filter(object => !this.isSameObject(object, availabilityObject)).length !== available_dates.length;
  }

  // isBooked = (availabilityObject) => {
  //
  //   // console.log('this.props', this.props);
  //   const { booked_dates } = this.props;
  //
  //   // console.log('availabilityObject:', availabilityObject);
  //   // console.log('booked_dates:', booked_dates);
  //   return false;
  //   // return booked_dates.filter(object => !this.isSameObject(object, availabilityObject)).length !== booked_dates.length;
  // }


  isSameObject = (first, second) => {
    // console.log('first:', first);
    // console.log('second:', second);
    return (
      first.year === second.year
      && first.month === second.month
      && first.day === second.day
      && first.time === second.time
    );
  }


  previousPage = () => {

      history.goBack();

  }

  componentWillReceiveNewProps (nextProps) {
    if (nextProps.window_width !== this.props.window_width) {
      console.log('width has resized');
      this.handleWindowSizeChange();

    } else if (nextProps.window_height !== this.props.window_height) {
      console.log('height has resized');
      this.handleWindowSizeChange();
    }
  }


  renderContent () {
    // only renders if dimensions of container stored in state - needed to set cellWidth
    const { upcomingAvailability, home_address } = this.props;
    const { dimensions } = this.state;


    // console.log('renderContent dimensions:', dimensions);

    // console.log('AvailabilityWeek renderContent upcomingAvailability:', upcomingAvailability);

    const containerWidth = dimensions.width;

    const noOfColumns = 9; // days of the week + 2 for row headers;

    // console.log('renderContent containerWidth:', containerWidth);

    // use if want exactly square cells
    // const cellSize = size = Math.min(containerWidth / columns, containerHeight / rows);

    // use if want full width and min height based on contents - maintain round inner circle
    const cellWidth = containerWidth / noOfColumns;
    const cellHeaderHeight = cellWidth + 12;


    const cellsInThisWeek = [];

    // const noOfDaysInRange = upcomingAvailability.length / 4;

    const newColumns = [];
    let newColumn = [];


    // for each week push 1 header column + 7 day columns (of 4 cells)

    // 1st column = row headers
    cellsInThisWeek.push(
      <div key='columnA' style={{ display: 'flex', flexDirection: 'column', borderWidth: 0, borderBottomWidth: 1, borderColor: colours.mediumlightgray, borderStyle: 'solid' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: cellHeaderHeight, background: colours.white, borderWidth: 0, borderColor: 'transparent', borderStyle: 'solid' }}>


        </div>

        <CellColumnButton key='h_em'
          // generic formatting
          cellWidth={cellWidth * 2}
          cellHeight={cellWidth}
          overflow='hidden'
          noMargin

          // date specific formatting

          // to do
          availability_score={0}
        >
          {`00am-6am`}
        </CellColumnButton>
        <CellColumnButton key='h_am'
          // generic formatting
          cellWidth={cellWidth * 2}
          cellHeight={cellWidth}
          overflow='hidden'
          noMargin

          // to do
          availability_score={0}
        >
          {`6am-12pm`}
        </CellColumnButton>
        <CellColumnButton key='h_pm'
          // generic formatting
          cellWidth={cellWidth * 2}
          cellHeight={cellWidth}
          overflow='hidden'
          noMargin

          // to do
          availability_score={0}
        >
          {`12pm-6pm`}
        </CellColumnButton>
        <CellColumnButton key='h_ev'
          // generic formatting
          cellWidth={cellWidth * 2}
          cellHeight={cellWidth}
          overflow='hidden'
          noMargin

          // to do
          availability_score={0}
        >
          {`6pm-12am`}
        </CellColumnButton>
      </div>
    );
    //

    upcomingAvailability.map((item, i) => {
      const newColumnLength = newColumn.length;
      // console.log('newColumnLength:', newColumnLength);
      if (newColumnLength === 0) {
        const date = getDateNo(moment(item.start_timestamp, 'YYYY-MM-DD HH:mm:ss'));
        const day = getDay(moment(item.start_timestamp, 'YYYY-MM-DD HH:mm:ss'));
        newColumn.push({ date, day, timestamp: item.start_timestamp });
      }
      if (item.available_hours !== false) {
        // console.log('AvailabilityWeek item.available_hours:', item.available_hours);
      }

      const newItem = { ...item, availability_score: item.available_hours > 5 ? 2 : item.available_hours > 1 ? 1 : 0 };
      newColumn.push(newItem);


      if (newColumnLength === 4) {
        newColumns.push(newColumn);
        newColumn = [];
      }
  
    });

    // console.log('upcomingAvailability newColumns: ', newColumns);

    const weeksAfterOriginalDateContext = this.state.dateContext.diff(this.state.originalDateContext, 'week');
    console.log('weeksAfterOriginalDateContext:', weeksAfterOriginalDateContext);

    const selectedWeekNo = weeksAfterOriginalDateContext + 1;

    const maxWeeks = upcomingAvailability.length / 7 / 4; // 4 slots per day
    console.log('maxWeeks:', maxWeeks);
    const start_day_index = 0 + (7 * (selectedWeekNo - 1));
    const last_day_index = 6 + (7 * (selectedWeekNo - 1));

    const filteredDays = newColumns.filter((column, i) => {
      return i >= start_day_index && i <= last_day_index;
    });

    filteredDays.map((day, i) => {
      console.log('day:', day);
      cellsInThisWeek.push(
        <div key={day[0].timestamp} style={{ display: 'flex', flexDirection: 'column', borderWidth: 0, borderBottomWidth: 1, borderColor: colours.mediumlightgray, borderStyle: 'solid' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: cellHeaderHeight, background: colours.white, borderWidth: 0, borderColor: 'transparent', borderStyle: 'solid' }}>

            <A7>{day[0].day}</A7>
            <A7>{day[0].date}</A7>

          </div>
          <div style={{ borderWidth: 0, borderRightWidth: (i === 6) ? 1 : 0, borderColor: colours.mediumlightgray, borderStyle: 'solid' }}>

            <CellColumnButton
              // generic formatting
              cellWidth={cellWidth}
              cellHeight={cellWidth}

              // date specific formatting
              // isInPast={isDateInPast}
              // isToday={isToday}
              // to do
              availability_score={day[1].availability_score}


              // needed?
              // borderColor={borderColor}
              // backgroundColor='transparent'
              // textColor={textColor}
              // date={date}
            >
              {
                // day[1].available_hours
              }
            </CellColumnButton>
            <CellColumnButton
              // generic formatting
              cellWidth={cellWidth}
              cellHeight={cellWidth}

              // date specific formatting
              // isInPast={isDateInPast}
              // isToday={isToday}
              // to do
              availability_score={day[2].availability_score}


              // needed?
              // borderColor={borderColor}
              // backgroundColor='transparent'
              // textColor={textColor}
              // date={date}
            >
              {
                // day[2].available_hours
              }
            </CellColumnButton>
            <CellColumnButton
              // generic formatting
              cellWidth={cellWidth}
              cellHeight={cellWidth}

              // date specific formatting
              // isInPast={isDateInPast}
              // isToday={isToday}
              // to do
              availability_score={day[3].availability_score}


              // needed?
              // borderColor={borderColor}
              // backgroundColor='transparent'
              // textColor={textColor}
              // date={date}
            >
              {
                // day[3].available_hours
              }
            </CellColumnButton>
            <CellColumnButton
              // generic formatting
              cellWidth={cellWidth}
              cellHeight={cellWidth}

              // date specific formatting
              // isInPast={isDateInPast}
              // isToday={isToday}
              // to do
              availability_score={day[4].availability_score}


              // needed?
              // borderColor={borderColor}
              // backgroundColor='transparent'
              // textColor={textColor}
              // date={date}
            >
              {
                // day[4].available_hours
              }
            </CellColumnButton>
          </div>
        </div>

      );
    });


    const weekDisplay = [...cellsInThisWeek];

    // console.log('weekDisplay:', weekDisplay);


    console.log('week this.state.dateContext', this.state.dateContext);

    // const isTodaysContext = (getMonth(this.state.dateContext) === todaysMonth(this.state.today)) && (getYear(this.state.dateContext) === todaysYear(this.state.today));


    return (

      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        { // calendar main
        }
        <div style={{ display: 'flex', width: '100%', flexDirection: 'row', borderWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderTopWidth: 1, borderBottomWidth: 1, borderColor: colours.lightgray, borderStyle: 'solid' }}>
          { // month nav
          }
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-start', borderWidth: 0, borderColor: 'red', borderStyle: 'solid' }}>
            {
              selectedWeekNo > 1 &&
              <BackButton
                // noIcon
                paddingLeft='14px'
                style={{ padding: 0, margin: 0 }}
                onClick={(e)=> { this.prevWeek(); }}
              />
            }
          </div>
          <div style={{ display: 'flex', flex: 5, alignItems: 'center', justifyContent: 'center', borderWidth: 0, borderColor: 'green', borderStyle: 'solid' }}>
            <H3
              marginTop='8px'
              marginBottom='8px'
            >
              <this.MonthNav />
              {' '}
              <this.YearNav />
            </H3>
          </div>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', borderWidth: 0, borderColor: 'red', borderStyle: 'solid' }}>
            {
              selectedWeekNo < maxWeeks &&
              <BackButton
                noIcon
                rightIcon='chevron right'
                paddingRight='14px'
                style={{ padding: 0, margin: 0 }}
                onClick={(e)=> { this.nextWeek(); }}
              />
            }
          </div>
          { // rightSpacer
            // only needed if add WeekButton
            // <div style={{ borderStyle: 'solid', borderWidth: 0, borderLeftWidth: 1, borderColor: colours.mediumlightgray, width: (cellWidth / 1.5) - 3 }} />
          }
        </div>

        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', borderWidth: 0, borderColor: 'red', borderStyle: 'solid' }}>

          { // cells - days
          }
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', borderWidth: 0, borderRightWidth: 0, borderBottomWidth: 0, borderColor: colours.mediumlightgray, borderStyle: 'solid' }}>
            {
              weekDisplay
            }
          </div>
        </div>

      </div>

    );
  }

  render () {

    const { dimensions } = this.state;
    const { upcomingAvailability, window_width } = this.props;

    if (!upcomingAvailability) return null;

    return (
      <div className='calendar' style={{ display: 'flex', width: '100%', borderWidth: 0, flexDirection: window_width > 600 ? 'row' : 'column', justifyContent: 'center', alignItems: 'center', borderColor: 'blue', borderStyle: 'solid' }}>
        { // calendar main
        }
        <div
          className='calendar-container'
          ref={(el) => { this.container = el; }}
          style={{
            width: '100%',
            maxWidth: 375,
            borderColor: 'blue',
            borderWidth: 0,
            borderStyle: 'solid',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingRight: 0,
            paddingLeft: 0
        }}>
          {dimensions && this.renderContent()}
        </div>

        <div style={{ display: 'flex', padding: 24, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderWidth: 0, borderColor: 'red', borderStyle: 'solid' }}>
          { // key
          }
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', borderWidth: 0, borderColor: colours.mediumlightgray, borderStyle: 'solid' }}>

            <AvailabilityKey
              cellWidth={this.state.cellWidth}
              cellHeight={this.state.cellWidth}
            />

          </div>
        </div>
      </div>

    );


  }


}


export default AvailabilityWeek;
