import React from 'react';
import styled from 'styled-components';
import colours from '../../colours';

// opacity: props.opacity || 1

export const StyledLink = styled.a`


  color: ${props => props.color ? props.color : colours.lightpurple}!important;
  text-decoration: ${props => props.underline ? 'underline' : 'none'}!important;

  background: transparent;



  cursor: pointer;

  &:hover {

    color: ${props => props.hoverColor ? props.hoverColor : colours.mediumlightpurple}!important;
  }

`;


const ExternalLink = (props) => {

  return (
    <StyledLink
      target='_blank' // ensures opens in new window
      to={props.to}
      href={props.href}
      onClick={props.onClick}
      color={props.color}
      hoverColor={props.hoverColor}
      underline={props.underline}
    >

      {props.children}

    </StyledLink>
  );
};

export default ExternalLink;
