import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Modal,
    Button,
    Header
} from "semantic-ui-react";
import { hideModal } from '../../actions/modal';
import Paragraph from '../../components/general/paragraph';
import ModalHeader from '../../components/general/modal-header';
// import Modal from '../../components/modals/modal';
import { H1, A5, H5, H3, ConfirmButtonText } from '../../styles/text';
import { ConfirmButton, Row } from '../../styles/styles';
import colours from '../../styles/colours';
import { plan, modal } from '../../text';
import Spinner from '../../components/general/spinner';

const actions = {hideModal};

const SpinnerModal = ({

  afterClose, hideModal, title, message, payment_made, payment_type, cancel_at, cancel_at_period_end }) => {

  const modalTitle = title || modal.spinner.title;
  const modalMessage = message || modal.spinner.message;

  // const onClose = () => {
  //   hideModal();
  //
  //   if (afterClose) {
  //     afterClose();
  //   }
  // };

  const formattedCancelAt = moment.unix(cancel_at).format("DD/MM/YYYY");

  return (

    <Modal
      // closeIcon="close"
      open={true}
      // onClose={onClose}
      // dimmer='blurring'
      size='tiny'
      style={{ maxWidth: 500 }}
    >
      {
        // <Modal.Header>{modalTitle}</Modal.Header>
      }
      <Modal.Content>

        <ModalHeader>
          {
            payment_type === 'downgrade'
            && cancel_at_period_end && <H1 style={{ textAlign: 'center' }}>{plan.modal.downgrade.success.header}</H1>
          }
          {
            payment_type === 'cancel_downgrade'
            && !cancel_at_period_end && <H1 style={{ textAlign: 'center' }}>{plan.modal.cancel_downgrade.success.header}</H1>
          }
          {
            // payment_type === 'downgrade'  // figure out error conditions
            // && !cancel_at_period_end && <H1 style={{ textAlign: 'center' }}>{plan.modal.failure.header}</H1>
          }
        </ModalHeader>

        <div style={{ paddingBottom: 8, display: 'flex', background: 'transparent', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

          <Spinner />

        </div>

        <div style={{ flexDirection: 'column', paddingBottom: 16, display: 'flex', background: 'transparent', justifyContent: 'center', alignItems: 'center' }}>

          <div style={{ marginBottom: 24, maxWidth: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <p>
              {
               <A5>{modalMessage}</A5>
              }
            </p>

          </div>

        </div>

      </Modal.Content>

    </Modal>

  );
};

SpinnerModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default connect(null, { hideModal })(SpinnerModal);
