import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    Modal,
    Button,
    Header,
    Icon
} from "semantic-ui-react";
import colours from '../../styles/colours';
import { A5 } from '../../styles/text';
import { showModal, hideModal } from '../../actions/modal';
import { MODAL_TYPE_EDIT_DAY } from '../modals/modalTypes';
import { calendar } from '../../text';
import { DateText } from './calendar-styles';
import { confirmEditAllAvailability, confirmDeleteAllAvailability } from '../../actions/calendar';
import * as BOOKING_TYPES from '../../constants/booking-types';
import { CONFIRMED, UNCONFIRMED } from '../../constants/booking-status';

const { start, end } = calendar.edit_event;

const defaultCellBorderColor = colours.mediumlightgray;
const clashingCellBorderColor = colours.red;
const clashingTextColor = colours.red;


export const BookingItem = ({ window_width, recurring_count, start_timestamp, end_timestamp, booking_type, booking_status }) => {

  const start_time = moment(start_timestamp, "YYYY-MM-DD HH:mm:ss").format('HH:mm');
  const end_time = moment(end_timestamp, "YYYY-MM-DD HH:mm:ss").format('HH:mm');

  // console.log('start_timestamp:', start_timestamp);
  // console.log('end_timestamp:', end_timestamp);
  // console.log('start_time:', start_time);
  // console.log('end_time:', end_time);


  const cellBorderColor = defaultCellBorderColor;

  const reqBookedColor = colours.reqBooked;
  const sitBookedColor = colours.sitBooked;
  const availableColor = colours.available;

  const typeMarkerColor = (
    booking_type === BOOKING_TYPES.SITTING && booking_status === CONFIRMED ? sitBookedColor
    : booking_type === BOOKING_TYPES.SITTING && booking_status === UNCONFIRMED ? sitBookedColor
    : booking_type === BOOKING_TYPES.USING && booking_status === CONFIRMED ? reqBookedColor
    : booking_type === BOOKING_TYPES.USING && booking_status === UNCONFIRMED ? reqBookedColor
    : availableColor);

  const typeMarkerOpacity = (
    booking_type === BOOKING_TYPES.SITTING && booking_status === CONFIRMED ? 1
    : booking_type === BOOKING_TYPES.SITTING && booking_status === UNCONFIRMED ? 0.5
    : booking_type === BOOKING_TYPES.USING && booking_status === CONFIRMED ? 1
    : booking_type === BOOKING_TYPES.USING && booking_status === UNCONFIRMED ? 0.5
    : 1);

  const typeText = (
    booking_type === BOOKING_TYPES.SITTING && booking_status === CONFIRMED ? calendar.edit_event.sit_booked
    : booking_type === BOOKING_TYPES.SITTING && booking_status === UNCONFIRMED ? calendar.edit_event.sit_pending
    : booking_type === BOOKING_TYPES.USING && booking_status === CONFIRMED ? calendar.edit_event.req_booked
    : booking_type === BOOKING_TYPES.USING && booking_status === UNCONFIRMED ? calendar.edit_event.req_pending
    : calendar.edit_event.available);

  return (
    <div style={{ width: '100%', overflow: 'hidden', display: 'flex', marginTop: 4, marginBottom: 4, flexDirection: 'row', borderColor: cellBorderColor, borderWidth: 1, borderStyle: 'solid', borderRadius: 8 }}>
      <div style={{ background: typeMarkerColor, width: 8, opacity: typeMarkerOpacity }} />
      <div style={{ display: 'flex', flexDirection: 'column', padding: 4, flex: 1 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <A5 style={{ color: typeMarkerColor, opacity: typeMarkerOpacity }}>{typeText}</A5>

          { false
            && <div style={{ display: 'flex', paddingTop: 2 }}>
              <Icon size='small' color='grey' name='repeat' />
            </div>
          }
        </div>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingBottom: 0 }}>

          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', paddingLeft: window_width < 340 ? 0 : 8, paddingRight: window_width < 340 ? 0 : 8 }}>
            <div style={{ display: 'flex', maxWidth: 100, justifyContent: 'center', alignItems: 'center', flex: 1, borderWidth: 1, borderStyle: 'solid', borderColor: cellBorderColor, opacity: typeMarkerOpacity }}>
              <A5 style={{ opacity: typeMarkerOpacity }}>{`${start}:`}&nbsp;</A5><A5 style={{ opacity: typeMarkerOpacity }}>{start_time}</A5>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                icon
                style={{ display: 'flex', padding: 0, flexDirection: 'row', margin: 0, width: '100%', justifyContent: 'center', alignItems: 'center', background: 'transparent', color: colours.mediumlightgray, opacity: typeMarkerOpacity }}
              >
                <Icon name='long arrow alternate right' />
              </Button>
            </div>
            <div style={{ display: 'flex', maxWidth: 100, justifyContent: 'center', alignItems: 'center', flex: 1, borderWidth: 1, borderStyle: 'solid', borderColor: cellBorderColor, opacity: typeMarkerOpacity }}>
              <A5 style={{ opacity: typeMarkerOpacity }}>{`${end}:`}&nbsp;</A5><A5 style={{ opacity: typeMarkerOpacity }}>{end_time}</A5>
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};


export default BookingItem;
