import history from '../../components/Navigation/history';
import { store } from '../../init-store';
import { showModal, hideModal } from '../modal';
import { actionSuccess } from '../verify';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS, MODAL_TYPE_CONFIRM } from '../../components/modals/modalTypes';
import * as ROUTES from '../../constants/routes';
import { edit_details, children } from '../../text/register';
import { CheckError, ReportError } from '../../utils/check-error';
import { SUCCESS_UPDATE_KIDS, CONFIRM_DELETE, SUCCESS_DELETE_KIDS } from '../../components/modals/modalNames';
import { PROFILE } from '../../components/modals/modalCategory';
import { selectChild, setNoOfChildren, setChildToAdd } from './aboutChildren';
import { SUBMIT_REGISTRATION_REQUEST, SUBMIT_REGISTRATION_SUCCESS, SUBMIT_REGISTRATION_FAILURE } from './registration';

export const submitUpdateKidsRequest = () => ({
  type: SUBMIT_REGISTRATION_REQUEST
});

export const submitUpdateKidsSuccess = data => ({
  type: SUBMIT_REGISTRATION_SUCCESS,
  data
});

export const submitUpdateKidsFailure = error => ({
  type: SUBMIT_REGISTRATION_FAILURE,
  error
});

export const onOKClick = (editMode) => {

  return (dispatch) => {
    dispatch(hideModal());

    if (editMode) {
      // go back to AboutChildren
      history.push({
        pathname: ROUTES.ABOUT_CHILDREN,
        state: { editMode: true }
      });
    } else {
      // add another child

      // addChild first
      // dispatch(createChild());
      const noOfChildren = store.getState().register.noOfChildren;
      dispatch(setNoOfChildren(noOfChildren + 1));

      // then selectChild
      dispatch(selectChild(noOfChildren)); // check what this does? does it duplicate initialise form in Edit Child

      dispatch(setChildToAdd(true)); // ensures newKid added to Array when EditChild form re-initialises

      history.push({
        pathname: ROUTES.EDIT_CHILD,
        state: { editMode: false }
      });
    }

  };


};

export const pushNextRoute = () => {

  return (dispatch) => {
    dispatch(hideModal());

    // is not editMode, by default or there'd be no button
      history.push({
        pathname: ROUTES.WHERE_YOU_LIVE,
        state: { editMode: false }
      });
    // }


  };
};

export const okDeleteChild = (token, user_id, fields, child_index, noOfChildren, editMode) => {

  // const kids = store.getState().user.kids;


  return (dispatch) => {

    dispatch(showModal(MODAL_TYPE_SPINNER, {

    }));

    console.log('child_index', child_index);
    fields.remove(child_index);

    dispatch(setNoOfChildren(noOfChildren - 1));
    // change number of children to -1

    // get new Kids from form values
    console.log('store.getState().form:', store.getState().form);
    const kids = store.getState().form.kids.values.kids;
    console.log('kids:', kids);


    dispatch(submitUpdateKidsRequest());
    // console.log('submitted Action');

    fetch(`${process.env.REACT_APP_API_URI}/users/registration/${user_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ user: { kids } })
    })
    .then(CheckError)
    .then((data) => {

      console.log('registration response data:', data);
      dispatch(submitUpdateKidsSuccess(data));

      dispatch(hideModal());

      const modalText = children.modal.delete_kids.success;

      dispatch(showModal(
        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: PROFILE,
          modalName: SUCCESS_DELETE_KIDS,
          modalText,
          okAction: () => dispatch(hideModal()),
          backAction: null

        }
        // need to add confirm modal to ask if they want to add another child

      ));
      dispatch(actionSuccess()); // triggers success icon

      // console.log('nextRouteToPush:', nextRouteToPush);

      history.push({
        pathname: ROUTES.ABOUT_CHILDREN,
        state: { editMode }
      });

      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.

    })
    .catch((err) => {
      dispatch(submitUpdateKidsFailure(err.message));
      dispatch(hideModal());

      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: children.modal.update_kids.failure.title,
          header: children.modal.update_kids.failure.header,
          message: children.modal.update_kids.failure.para,
          message2: children.modal.update_kids.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };

};


export function submitDeleteChild (token, user_id, fields, child_index, noOfChildren, editMode) {

  return (dispatch) => {


    // we want an are you sure prompt, so need to move this to actions
    const okAction = () => {
      console.log('confirmDeleteChild okAction:');
      dispatch(okDeleteChild(token, user_id, fields, child_index, noOfChildren, editMode));
    };

    // dispatch(showModal(MODAL_TYPE_CONFIRM_PREAPPROVE, {
    //   okAction: () => okAction(),
    //   backAction: null,
    //   cancelAction: () => dispatch(hideModal()),
    //   thread_stage: 'pre_approve'
    // }));


    dispatch(showModal(MODAL_TYPE_CONFIRM, {
      modalCategory: PROFILE,
      modalName: CONFIRM_DELETE,
      modalText: children.modal.delete_kids.confirm,
      okAction: () => okAction(),
      backAction: null,
      cancelAction: () => dispatch(hideModal())
    }));

  };
}


export function submitUpdateKids (token, user_id, kids, nextRoute, editMode) {

  console.log('submit reg');
  console.log('nextRoute:', nextRoute);
  const nextRouteToPush = !nextRoute ? ROUTES.ABOUT_CHILDREN : nextRoute;
  console.log('nextRouteToPush:', nextRouteToPush);

  if (!kids) {
    history.push({
      pathname: nextRouteToPush,
      state: { editMode }
    });
  }


  return (dispatch) => {
    dispatch(showModal(MODAL_TYPE_SPINNER, {

    }));

    dispatch(submitUpdateKidsRequest());
    // console.log('submitted Action');

    fetch(`${process.env.REACT_APP_API_URI}/users/registration/${user_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ user: { kids } })
    })
    .then(CheckError)
    .then((data) => {

      console.log('registration response data:', data);
      dispatch(submitUpdateKidsSuccess(data));

      dispatch(hideModal());

      const modalText = editMode ? children.modal.update_kids.success : children.modal.update_kids.success_add_another;

      dispatch(showModal(
        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: PROFILE,
          modalName: SUCCESS_UPDATE_KIDS,
          modalText,
          okAction: () => dispatch(onOKClick(editMode)), // add another child and navigate to editChild
          cancelAction: editMode ? null : () => dispatch(pushNextRoute()), // hideModal and return to nextRoute
          backAction: null

        }
        // need to add confirm modal to ask if they want to add another child

      ));
      dispatch(actionSuccess()); // triggers success icon

      console.log('nextRouteToPush:', nextRouteToPush);

      // history.push({
      //   pathname: nextRouteToPush,
      //   state: { editMode }
      // });

      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.

    })
    .catch((err) => {
      dispatch(submitUpdateKidsFailure(err.message));
      dispatch(hideModal());

      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: children.modal.update_kids.failure.title,
          header: children.modal.update_kids.failure.header,
          message: children.modal.update_kids.failure.para,
          message2: children.modal.update_kids.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });
  };
}
