import * as nearbyUsers from '../actions/nearbyUsers';
import * as dashboardActions from '../actions/dashboard';

export const initialState = {
  // isFetchingNearbyUsers: false,
  isFetchingUser: false,
  viewed_family: null,
  error: undefined
};

export default function dashboard (state = initialState, action) {
  switch (action.type) {

    case nearbyUsers.GET_NEARBY_USERS_REQUEST:
      return {
        ...state,
        isFetchingNearbyUsers: true,
        error: undefined
      };
    case nearbyUsers.GET_NEARBY_USERS_SUCCESS:
      return {
        ...state,
        isFetchingNearbyUsers: false,
        nearby_users: action.data
      };
    case nearbyUsers.GET_NEARBY_USERS_FAILURE:
      return {
        ...state,
        isFetchingNearbyUsers: false,
        error: action.error
      };
    case dashboardActions.SET_VIEWED_FAMILY:
      return {
        ...state,
        viewed_family: action.data
      };
      case dashboardActions.GET_VIEWED_FAMILY_REQUEST:
        return {
          ...state,
          isFetchingUser: true,
          error: undefined
        };
      case dashboardActions.GET_VIEWED_FAMILY_SUCCESS:
        return {
          ...state,
          isFetchingUser: false,
          viewed_family: action.data
        };
      case dashboardActions.GET_VIEWED_FAMILY_FAILURE:
        return {
          ...state,
          isFetchingUser: false,
          error: action.error
        };


    default:
      return state;
  }
}
