import React from 'react';
import {
  Button, Icon
} from 'semantic-ui-react';
import styled from 'styled-components';
import Image from '../../../components/general/image';
import colours from '../../colours';
import DeleteIcon from '../../../images/bin_icon_grey.min.svg'; // thin fill style

export const StyledButton = styled(Button)`

  display: flex;
  flex: ${props => props.flex ? props.flex : null}!important;
  align-items: center!important;
  width: 40px!important;
  height: 40px!important;
  margin: 0px!important;
  padding-top: 0px!important;
  padding-bottom: 0px!important;
  padding-left: 0px!important;
  padding-right: 0px!important;
  color: ${colours.red}!important;
  background-color: ${'transparent'}!important;
  border-color: ${colours.red}!important;
  border-width: 0px!important;
  border-style: solid!important;
  border-radius: 4px!important;
  overflow: hidden!important;

  cursor: pointer!important;

`;


const DeleteButton = (props) => {
  return (
    <StyledButton
      primary
      icon={props.icon}
      // labelPosition={props.icon ? props.labelPosition || 'left' : null}
      // fluid={props.fluid}
      // compact={props.compact}
      disabled={props.disabled}

      // flex={props.flex}
      // style={{ margin: 0, color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}

      onClick={props.onClick}
    >
      {
        props.icon
        && <Icon size='large' name={props.icon} />
      }
      {
        !props.icon
        && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <Image noDelay width={'100%'} height={'100%'} src={DeleteIcon} />
        </div>

      }

    </StyledButton>
  );
};

export default DeleteButton;
