import { connect } from 'react-redux';
import CalendarButton from '../../components/calendar/calendar-button';
import { getUserById } from '../../actions/auth/profile';
import { openDropdownNav, closeDropdownNav, setThreadBack } from '../../actions/navbar';
import { getAllThreadsById } from '../../actions/messages';

const mapStateToProps = ({ user, inbox, nav }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  user_id: user.user_id,
  burgerIsOpen: ownProps.burgerIsOpen,
  dropdownOpen: ownProps.dropdownOpen,
  toggleDropdown: ownProps.toggleDropdown,
  all_threads: inbox.all_threads,
  isFetchingAllThreads: inbox.isFetchingAllThreads,
  dropdown_nav_is_open: nav.dropdown_nav_is_open

});

const mapDispatchToProps = (dispatch, props) => ({


  handleGetUser: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getUserById(token, user_id));

    }

  },
  handleGetAllThreads: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getAllThreadsById(token, user_id));

    }

  },
  handleDropdownNavClick: (dropdown_nav_is_open) => {
    if (dropdown_nav_is_open) {

      console.log('dropdown-nav-container closeDropdownNav when going to Calendar');
      dispatch(closeDropdownNav());
    } else {
      console.log('dropdown-nav-container openDropdownNav');
      dispatch(openDropdownNav());
    }
  },

  handleSelect: () => {

    // const token = localStorage.sitterpoint_token;
    //
    // if (token) {
    //   dispatch(getUserById(token, user_id));
    //
    // }

  },


  setThreadBack: (route) => {
    dispatch(setThreadBack(route));
  },

  handleDropdownClick: () => {
    const { handleDropdownClick } = props;
    handleDropdownClick();
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarButton);
