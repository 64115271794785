import React, { Component } from 'react';
import { ReportGA } from '../../utils/ga';
import { ConfirmButton } from '../../styles/styles';
import Card from '../general/card';
import CardHeader from '../general/card-header';
import OpenSearchButtonContainer from '../../containers/search/open-search';
import Paragraph from '../general/paragraph';
import Image from '../general/image';
import history from '../Navigation/history';
import { dashboard, other } from '../../text';
// import landingBackgroundPic from '../../images/landingBackgroundPic.jpg';
import youHave450sp from '../../images/you_have_450_sp.min.svg';
import boyJump from '../../images/boy_jump.min.jpg';
import * as ROUTES from '../../constants/routes';
import * as VERIFIED_STATUS from '../../constants/verified-status';
import { DASHBOARD } from '../../constants/ga-category';

// import { connectAlert } from '../Alert';

// const { Answers } = Fabric;

class ProfileSP extends Component {
    // console.log('f item user:', user);


    handleClickCal = () => {

        history.push({
          pathname: ROUTES.AVAILABILITY
        });

        ReportGA({
          category: DASHBOARD,
          action: `User pressed Go to Calendar button on ProfileSP card on Dashboard page`
        });

    };

    handleClickBookASit = () => {

        const { searchBannerIsOpen, showSearchBanner, hideSearchBanner, clearSearchQuery, isSearchSubmitted } = this.props;

        if (isSearchSubmitted) {
          clearSearchQuery(); // resets search bar and clears search results
          // ONLY do here if don't want to edit search, and do when search is over (navigate away from results? to dashboard etc?)
          // add option of New Search to Search banner.
        }

        if (!searchBannerIsOpen) {
          console.log('OpenSearch banner not open');
          // is a New Search, so clear previous search

          showSearchBanner();
        } else {
          console.log('OpenSearch banner open');
          hideSearchBanner();


          showSearchBanner();
        }

        ReportGA({
          category: DASHBOARD,
          action: `User pressed Book a Sit button on ProfileSP card on Dashboard page`
        });

    };

    render () {

      const { handleClickGotIt, window_width, profileProgress, verified_status } = this.props;


      return (

        <Card>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>

            <div style={{ flex: 1, zIndex: 1, paddingTop: 0, paddingBottom: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

              <Image
                alt='main'
                style={{ width: '120%', maxWidth: 120, objectFit: 'cover' }}
                src={ boyJump }
              />

            </div>

            <div style={{ flex: 2, zIndex: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0, paddingBottom: 8, paddingLeft: 8, paddingRight: 8 }}>

              <div style={{ paddingTop: 0, paddingBottom: 0, width: '100%', maxWidth: 260, display: 'flex', flexDirection: 'column' }}>

                <Image
                  alt='main'
                  style={{ width: '100%', objectFit: 'cover' }}
                  src={ youHave450sp }
                />

              </div>

              <CardHeader
                paddingBottom='0px'
                alignTitle='center'
                title={<div>{dashboard.profile_sp_title1}</div>}
                // image={welcomeBuntingPic}
                // paragraph={(window_width >= 490) &&
                //   <Paragraph noBottomPadding>
                //     <br />
                //     {dashboard.welcome_para1}
                //     <br />
                //     {(profileProgress !== 100) ? `${dashboard.welcome_para2_incomplete}` : dashboard.welcome_para2}
                //   </Paragraph>
                // }
              />

              <Paragraph textAlign='center'>
                {dashboard.profile_sp_para1}
              </Paragraph>

              {
                // <div style={{ flex: 1, width: '100%', display: 'flex', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', justifyContent: 'flex-end', alignItems: 'flex-end', paddingTop: 4, paddingBottom: 4 }}>
                //   <ConfirmButton
                //     onClick={ () => handleClickGotIt() }
                //     // style={{ alignItems: 'center', alignSelf: 'flex-end', borderColor: 'green', borderWidth: 0, borderStyle: 'solid', minHeight: 30, marginRight: 0 }}
                //   >
                //     {other.got_it}
                //   </ConfirmButton>
                // </div>
              }

              <div style={{ flex: 1, width: '100%', display: 'flex', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', justifyContent: window_width > 400 ? 'center' : 'center', alignItems: 'flex-start', paddingTop: 4, paddingBottom: 4 }}>

                <ConfirmButton
                  compact={window_width <= 400}
                  onClick={ () => this.handleClickCal() }
                >
                  {dashboard.profile_sp_cal_button}
                </ConfirmButton>
                <div style={{ width: 16 }} />
                <ConfirmButton
                  compact={window_width <= 400}
                  onClick={() => this.handleClickBookASit()}
                >
                  {dashboard.profile_sp_book_a_sit_button}
                </ConfirmButton>

              </div>

            </div>

          </div>

        </Card>

      );
    }


}

export default ProfileSP;
