import React from 'react';
import {
  Button
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';

export const StyledLink = styled(Button)`

  margin: 0px!important;
  padding: 0px!important;
  color: ${(props) => props.color ? props.color : colours.lightpurple}!important;
  text-decoration: ${(props) => props.underline ? 'underline' : 'none'}!important;
  background: ${(props) => props.background ? props.background : 'transparent'}!important;
  cursor: pointer;
  border-radius: 2px;
  font-size: 16px!important;

  &:hover {
    color: ${(props) => props.hovercolor ? props.hovercolor : colours.mediumlightpurple}!important;
    background: ${(props) => props.hoverBackground ? props.hoverBackground : 'transparent'}!important;
  }

`;

const LinkButton = (props) => {

  return (
    <StyledLink
      target={props.target}
      onClick={props.onClick}
      color={props.color}
      background={props.background}
      hovercolor={props.hoverColor}
      hoverBackground={props.hoverBackground}
      underline={props.underline}
    >

      {props.children}

    </StyledLink>
  );
};

export default LinkButton;
