import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import { versionNo } from './constants/env';


if (Bugsnag) {
  Bugsnag.start({
    apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
    plugins: [new BugsnagPluginReact()],
    appVersion: versionNo
    // otherOptions: value,
  });
}


const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export default ErrorBoundary;
