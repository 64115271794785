import { connect } from 'react-redux';
import EditSearch from '../../components/messages/edit-search';
import { persistor, store } from '../../init-store';
import { logout } from '../../actions/auth/signup';
import { showModal, hideModal } from '../../actions/modal';
import { sendEditedDetails } from '../../actions/messages';
import { openBurger, closeBurger } from '../../actions/navbar';
import { saveEditSelectedKids, saveEditStartDate, saveEditEndDate, saveEditStartHour, saveEditEndHour, saveEditStartMinute, saveEditEndMinute, setAllSelectedKids } from '../../actions/calendar';
import { clearEditSearchQuery } from '../../actions/search';
import * as ROUTES from '../../constants/routes';
import history from '../../components/Navigation/history';
import { MODAL_TYPE_SIGNIN, MODAL_TYPE_SIGNUP } from '../../components/modals/modalTypes';

const mapStateToProps = ({ user, nav, search, editSearch, layout, tariff, messageThread }, ownProps) => {
  // console.log('this.state:', this.state);
  // console.log('localStorage: ', localStorage);
  console.log('editSearch.saved_selected_kids', editSearch.saved_selected_kids);
  return {
    isLoggedIn: user.isLoggedIn,
    isLoggingIn: user.isLoggingIn,
    user_id: user.user_id,
    firstname: user.firstname,
    sp: user.sp,
    profile_progress: user.profile_progress,
    burgerIsOpen: nav.burgerIsOpen,
    saved_start_date: editSearch.saved_start_date,
    saved_end_date: editSearch.saved_end_date,
    saved_start_hr: editSearch.saved_start_hr,
    saved_end_hr: editSearch.saved_end_hr,
    saved_start_min: editSearch.saved_start_min,
    saved_end_min: editSearch.saved_end_min,
    saved_selected_kids: editSearch.saved_selected_kids,
    tariff_details: tariff.tariff_details,
    thread_details: messageThread.thread_details,
    saved_search_details: search,
    edited_thread_details: editSearch,
    editMode: messageThread.editMode,
    finaliseMode: ownProps.finaliseMode,
    window_width: layout.window_width,
    noTitle: ownProps.noTitle
  };

};

const mapDispatchToProps = dispatch => ({
  handleLogOut: () => {
    // console.log('localStorage: ', localStorage);
    localStorage.removeItem('sitterpoint_token');

    localStorage.removeItem('sitterpoint_user_id');

    history.push(ROUTES.LANDING);

    // const rootNavigator = nav.getNavigator('root');
    // rootNavigator.replace('auth');
    // clean up persisted state
    persistor.purge();
    // store.getState().network.socket.disconnect();
    dispatch(logout());
    // redirect

  },
  sendEditedDetails: (thread_id, thread_details, user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      const messageParams = null;

      dispatch(sendEditedDetails(token, messageParams, thread_id, thread_details, user_id));

    }

  },
  // setSelectedValue: (value, inputKey, dataType) => {
  //   console.log('value: ', value);
  //   console.log('inputKey: ', inputKey);
  //   console.log('dataType: ', dataType);
  //
  //   dispatch(setValue(value, inputKey, dataType));
  //
  // },
  handleBurgerClick: (burgerIsOpen) => {
    if (burgerIsOpen) {
      dispatch(closeBurger());
    } else {
      dispatch(openBurger());
    }
  },
  handleCloseBurger: () => {
    dispatch(closeBurger());
  },

  hideModal: () => {
    dispatch(hideModal());
  },

  saveEditStartDate: (dateObject) => {

      dispatch(saveEditStartDate(dateObject));

  },
  saveEditEndDate: (dateObject) => {

      dispatch(saveEditEndDate(dateObject));

  },
  saveEditStartHour: (dateObject) => {

      dispatch(saveEditStartHour(dateObject));

  },
  saveEditEndHour: (dateObject) => {

      dispatch(saveEditEndHour(dateObject));

  },
  saveEditStartMinute: (dateObject) => {

      dispatch(saveEditStartMinute(dateObject));

  },
  saveEditEndMinute: (dateObject) => {

      dispatch(saveEditEndMinute(dateObject));

  },
  saveEditSelectedKids: (kidObject) => {

      dispatch(saveEditSelectedKids(kidObject));

  },
  setAllSelectedKids: (selected_kids) => {

      dispatch(setAllSelectedKids(selected_kids));

  }

});

const EditSearchContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditSearch);

export default EditSearchContainer;
