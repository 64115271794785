import { connect } from 'react-redux';
import Signup from '../../components/auth/signup';
import { showModal, hideModal } from '../../actions/modal';
import { signupUser } from '../../actions/auth/signup';
import { MODAL_TYPE_SIGNIN } from '../../components/modals/modalTypes';


const mapStateToProps = ({ user, network }) => ({
  isFetching: user.isFetching,
  isConnected: network.isConnected,
  active_referral_code: user.active_referral_code,
  serverError: user.error
});

const mapDispatchToProps = (dispatch) => {

  return {

    handleSubmitForm: ({ firstname, surname, email, password }, active_referral_code) => {
      dispatch(signupUser(firstname, surname, email.toLowerCase(), password, active_referral_code));

    },
    showSignInModal: () => {
      dispatch(showModal(MODAL_TYPE_SIGNIN, {

      }));
    },
    hideModal: () => {
      dispatch(hideModal());
    }


  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
