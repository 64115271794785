import { showModal, hideModal } from '../modal';


export const TOGGLE_DROPDOWN_HOUR_FROM = 'TOGGLE_DROPDOWN_FROM';
export const TOGGLE_DROPDOWN_HOUR_TO = 'TOGGLE_DROPDOWN_TO';
export const TOGGLE_DROPDOWN_KIDS = 'TOGGLE_DROPDOWN_KIDS';

export function toggleDropdownHourFrom (isOpen) {
  return {
    type: TOGGLE_DROPDOWN_HOUR_FROM,
    data: isOpen
  };
}

export function toggleDropdownHourTo (isOpen) {
  return {
    type: TOGGLE_DROPDOWN_HOUR_TO,
    data: isOpen
  };
}

export function toggleDropdownKids (isOpen) {
  return {
    type: TOGGLE_DROPDOWN_KIDS,
    data: isOpen
  };
}
