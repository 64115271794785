import * as verify from '../actions/verify';
import update from 'immutability-helper';

export const initialState = {
  selectedAdult: undefined,
  selectedDocFile: undefined,
  selectedSelfieImage: undefined

};

export default function register (state = initialState, action) {
  switch (action.type) {

    case verify.SET_SELECTED_ADULT:

      return {
        ...state,
        selectedAdult: action.data
      };

    case verify.RESET_SELECTED_ADULT:

      return {
        ...state,
        selectedAdult: undefined
      };

    case verify.SET_SELECTED_DOC_FILE:

      return {
        ...state,
        selectedDocFile: action.data
      };

    case verify.SET_SELFIE_IMAGE:

      return {
        ...state,
        selectedSelfieImage: action.data
      };


    default:
      return state;
  }
}
