/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { SetGA, ReportGA } from '../../../utils/ga';
import {
    Icon,
    Dropdown,
    Menu
} from "semantic-ui-react";
import { Transition } from 'react-transition-group';
// import '@trendmicro/react-buttons/dist/react-buttons.css';
// import '@trendmicro/react-dropdown/dist/react-dropdown.css';
// import 'react-dropdown/style.css';
// import 'react-select/dist/react-select.css';
import '../../../styles/transitions.css';
import history from '../../Navigation/history';
import { DropDownButton } from '../../../styles/styles';
import { A5, B5 } from '../../../styles/text';
import { DropdownItem } from '../../../styles/buttons';
import colours from '../../../styles/colours';
import Image from '../../general/image';
import { POINTS } from '../../../constants/ga-category';

const defaultStyle = (burger, width) => {
  return ({
    opacity: 1,
    position: burger ? 'fixed' : 'absolute',
    top: burger ? 118 : 60, // same as your nav height
    right: burger ? null : 0,
    // left: burger ? '10%' : null,
    left: burger ? 0 : null,
    margin: 0,
    // marginLeft: burger ? (width) * -0.6 : 0,
    marginLeft: burger ? 0 : 0,
    background: 'transparent',
    padding: 0,
    paddingLeft: burger ? 10 : 0,
    paddingRight: burger ? 10 : 0,
    textAlign: 'center',
    // transition: 'all 1000 ease',
    zIndex: 2000,
    width: burger ? (width * 1) - 0 : 350
  });

};


// const slideDownStyles = {
//
//   entering: { transform: 'translateY(-100%)', opacity: 0, height: 0, transition: 'all .9s cubic-bezier(0, 1, 0.5, 1)' }, // transition: .3s cubic-bezier(0, 1, 0.5, 1);
//   entered: { transform: 'translateY(0%)', opacity: 1, height: 200, transition: 'all .9s cubic-bezier(0, 1, 0.5, 1)' },
//   exiting: { transform: 'translateY(0%)', transition: '.9s ease-in-out' },
//   exited: { transform: 'translateY(-100%)' }
// };

const fadeInStyles = {

  entering: { opacity: 0 },
  entered: { transition: 'opacity 200ms linear', opacity: 1 },
  exiting: { transition: 'opacity 100ms linear', opacity: 0 },
  exited: { opacity: 0 }
};

export default class DropdownPoints extends Component {

  state = {
    open: false
  }


  handleClick = () => {

      // if click is inside, do something
      const { open } = this.state;
      this.setState({ open: !open });

      ReportGA({
        category: POINTS,
        action: `Points dropdown button clicked on navbar`
      });

  }

  handleOutsideClick = () => {

      // if click is inside, do something
      this.setState({ open: false });

  }

  handleSelect = (route) => {

    const { onBurgerClick, burgerIsOpen } = this.props;

    ReportGA({
      category: POINTS,
      action: `User clicked ${route} in Points dropdown`
    });

    this.setState({ open: false }); // closes dropdown menu
    history.push({
      pathname: route,
      state: { editMode: true }

    });
    // console.log('setEditMode true');
    // console.log('aboutToClick:', this.props);
    if (burgerIsOpen) {
      onBurgerClick(burgerIsOpen); // closes burgermenu
    }

  }


  render () {


    const { open } = this.state;


    const { value, options, width, burger, image, icon } = this.props;
    // console.log('options', options);
    // console.log('burger:', burger);
    // console.log('width:', width);
    const spBelow = width < 500;

    const dropdownValue = value;
    // console.log('options: ', options);

    // const defaultOption = options[this.props.value - 1];

    const menuItems = options.map((option, i) => {

        const currentValue = option.value;

        // console.log('option', option);

        return (


          <DropdownItem

            key={`myDropdownKey ${option.text}`}
            eventkey={currentValue}
            index={i}
            onClick={() => this.handleSelect(option.route)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.handleSelect(option.route);
              }
            }}
            // style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            // description='2 new'
            // text='Important'
          >

            <div style={{ width: '70%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              {
                option.text
                && <A5 textAlign='left'>{option.text}</A5>
              }

              {
                option.up_to
                && <A5 textAlign='right'>{'up to'}</A5>
              }
            </div>
            <div style={{ width: '30%', display: 'flex', flexDirection: 'row' }}>
              {
                option.points
                && <A5 color={colours.red}>{option.points}</A5>
              }
              {
                option.unit
                && <A5>{option.unit}</A5>
              }
              {
                option.progress
                && <A5>&nbsp;{option.progress}</A5>
              }
            </div>
          </DropdownItem>


        );
    });

    return (

      <div
        style={{
          flex: 1,
          display: 'flex',
          position: 'relative',
          cursor: 'pointer',
          borderWidth: 0,
          borderColor: colours.lightgray,
          borderStyle: 'solid',
          borderRadius: 0,
          height: '100%',
          minWidth: width >= 640 ? 86 : null
        }}
      >

        <DropDownButton

          type='button'
          onClick={this.handleClick}
          onKeyDown={this.handleClick}

        >

          <div style={{ position: 'relative', display: 'table' }}>

            {
              image
              ? <Image
                alt='welcome'
                style={{ width: 36,
                // maxWidth: 200,
                objectFit: 'contain' }}
                src={ image }
                />
              : <div />

            }
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: `translate(-50%, -50%)`,
              borderColor: 'red', borderWidth: 0, borderStyle: 'solid', flex: 1, height: '100%', display: 'flex', flexDirection: spBelow ? 'column' : 'row', justifyContent: 'center', alignItems: 'center' }}>
              <B5 color={colours.mediumlightpurple}>{dropdownValue}</B5><A5>SP</A5>
            </div>
          </div>

          <div style={{ borderColor: 'red', borderWidth: 0, borderStyle: 'solid', position: 'absolute', display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Icon name='caret down' size='small' />
          </div>


        </DropDownButton>
        <Transition
          in={open}
          appear={true}
          exit={true}
          timeout={{
            appear: 50,
            enter: 50,
            exit: 200
          }}

          unmountOnExit
          // classNames='fade'
        >
          { state => (

            <div

              style={{
                ...defaultStyle(burger, width),
                ...fadeInStyles[state]
              }}

            >

              <div
                role='presentation'
                onClick={this.handleOutsideClick}
                onKeyDown={this.handleOutsideClick}
                style={{
                  position: 'fixed',
                  opacity: 0.2,
                  background: colours.darkgray,
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: -1,
                  borderWidth: 1, borderStyle: 'solid', borderColor: 'blue'
              }} />


              <Menu
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: `1px 1px 1px ${colours.shadow}`
                }}
                vertical
              >

                {menuItems}
              </Menu>


            </div>
          )}

        </Transition>


      </div>

    );
  }
}


// <MenuButton
//   type='button'
//   key={`myDropdownKey ${option.text}`}
//   eventkey={currentValue}
//   index={i}
//   onClick={() => this.handleSelect(option.route)}
//   onKeyPress={(e) => {
//     if (e.key === 'Enter') {
//       this.handleSelect(option.route);
//     }
//   }}
//
// >
//
//   <Row style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 4, borderBottomWidth: 1, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: colours.lightgray, flex: 1, height: '100%', alignItems: 'flex-end', justifyContent: 'center' }}>
//     <div style={{ width: '75%', display: 'flex', flexDirection: 'row' }}>
//       {
//         option.text
//         && <A5 style={{ paddingLeft: 4, textAlign: 'left' }}>{option.text}</A5>
//       }
//     </div>
//     <div style={{ width: '25%', display: 'flex', flexDirection: 'row' }}>
//       {
//         option.points
//         && <A5 style={{ color: colours.red }}>{option.points}</A5>
//       }
//       {
//         option.unit
//         && <A5>&nbsp;{option.unit}</A5>
//       }
//       {
//         option.progress
//         && <A5>&nbsp;{option.progress}</A5>
//       }
//     </div>
//   </Row>
//
//
// </MenuButton>




// <Dropdown.Item
//   text={
//
//           option.text
//           && option.text
//
//   }
//
//   description={
//     `${option.points
//       && option.points}
//     ${
//       option.unit
//       && <A5>&nbsp;{option.unit}</A5>
//     }
//     ${
//       option.progress
//       && <A5>&nbsp;{option.progress}</A5>
//     }`
//   } />
//
//
// );
// });
//
// return (
//
// <Dropdown text={`${dropdownValue} SP`}
// pointing
// style={{
//                   position: 'relative'
// }}
// >
// <Dropdown.Menu
// style={{
//                     position: 'fixed',
//                     top: 0,
//                     left: 0,
//                     width: '100%'
// }}>
//   {menuItems}
//   <Dropdown.Divider />
//   <Dropdown.Item text='Download As...' />
//   <Dropdown.Item text='Publish To Web' />
//   <Dropdown.Item text='E-mail Collaborators' />
// </Dropdown.Menu>
// </Dropdown>
