import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import colours from '../../../styles/colours';

const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;

// export const Wrapper = styled.div`
//   display: flex;
//   flex-flow: column;
//   align-items: center;
//   /* width: 100%; */
//
//   border-color: red;
//   border-width: 0px;
//   border-style: solid;
// `;

export const Container = styled.div`
  position: relative;
  overflow: hidden;

`;

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;


`;


export const Video = styled.video`
  /* have to style this so innerRef works */

  /* height: 100%;
  width: 100%; */


  /* below this flips the image */
  -webkit-transform: ${({ mirrored }) => mirrored && `scaleX(-1)`};
  transform: ${({ mirrored }) => mirrored && `scaleX(-1)`};
  /*
  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  } */



`;

const faceShape = `50% 50% 50% 50% / 40% 40% 60% 60%`;
/* upside down egg shape */
const rectangleShape = `5px`;


export const FaceOverlay = ({ height, width }) => {

  const topMargin = width > 400 ? 80 : '10%';


  return (

    <div
      style={{
        width,
        height,
        borderColor: colours.white,
        borderWidth: 3,
        borderStyle: 'solid',
        boxShadow: '0px 0px 20px 500px rgba(0, 0, 0, 0.6)',
        marginTop: topMargin,
        marginBottom: 'auto',
        borderRadius: faceShape

    }} />

  );
};


export const SquareOverlay = ({ height, width }) => {

  return (

    <div
      style={{
        width,
        height,
        borderColor: colours.white,
        borderWidth: 3,
        borderStyle: 'solid',
        boxShadow: '0px 0px 20px 500px rgba(0, 0, 0, 0.6)',
        margin: '5%',
        marginBottom: 'auto',
        borderRadius: rectangleShape,

        // top: 0,
        // right: 0,
        // bottom: 0,
        // left: 0


    }} />

  );
};


export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #ffffff;

  ${({ flash }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
  }}
`;
