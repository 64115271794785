// import { store } from '../../init-store';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';

export const GET_TARIFF_REQUEST = 'GET_TARIFF_REQUEST';
export const GET_TARIFF_SUCCESS = 'GET_TARIFF_SUCCESS';
export const GET_TARIFF_FAILURE = 'GET_TARIFF_FAILURE';


export const getTariffByIdRequest = () => ({
  type: GET_TARIFF_REQUEST
});

export const getTariffByIdSuccess = data => ({
  type: GET_TARIFF_SUCCESS,
  data
});

export const getTariffByIdFailure = error => ({
  type: GET_TARIFF_FAILURE,
  error
});

// could combine into getUser response from SQL database and return tariff details too?

export function getTariffById (token, tariff_id) {
  return (dispatch) => {

    dispatch(getTariffByIdRequest());
    console.log('getTariffById action for id: ', tariff_id);

    fetch(`${process.env.REACT_APP_API_URI}/tariff/${tariff_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    })
    .then(CheckError)
    .then((data) => {
      console.log('getTariff response data:', data);

      dispatch(getTariffByIdSuccess(data));

    })
    .catch((err) => {
      console.log('caught getTariff error: ', err);
      dispatch(getTariffByIdFailure(err.message));
    });

  };
}
