import React, { useState } from 'react';
import {
  Button, Icon
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { H5, H2, A6, A5 } from '../../text';
import Image from '../../../components/general/image';
import UploadedIcon from '../../../images/status_good_tick.min.svg'; // thin fill style
import PendingIcon from '../../../images/status_waiting_clock.min.svg'; // thick fill style clock
import AttentionIcon from '../../../images/status_need_action_exclam.min.svg'; // thick fill style

export const StyledButton = styled(Button)`

  flex-direction: row!important;
  display: flex!important;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  flex: ${props => props.flex ? props.flex : null}!important;
  margin: 0px!important;
  color: ${props => props.color ? props.color : colours.white}!important;
  background: ${props => props.background ? props.background : colours.white}!important;
  border-color: ${colours.borderColour}!important;
  border-width: 1px!important;
  border-style: solid!important;

  cursor: pointer!important;

`;


const RowButton = (props) => {

  const [hover, setHover] = useState(false);
  const [clicked, setClicked] = useState(false);

  const { onClick } = props;

  const toggleHover = (newHover) => {
      if (hover === undefined) {
        setHover(!hover);
      } else {
        setHover(newHover);
      }
  };

  const handleClick = () => {

      if (onClick) {
        onClick();
      }

      toggleHover(false); // removes any hover effects on click

      // trigger touch feedback
      setClicked(true);
      setTimeout(() => {
        setClicked(false);
      }, 20);

  };

  // console.log('checkStatus: ', props.checkStatus);

  const isHovered = hover;

  const color = props.color || colours.white;
  const background = clicked ? colours.darkpurple : props.color || colours.white;
  const hoveredBackground = colours.verylightpurple;

  return (
    <StyledButton
      primary
      icon={props.icon}
      labelPosition={props.icon ? props.labelPosition || 'left' : null}
      fluid={props.fluid}
      compact={props.compact}
      disabled={props.disabled}
      color={isHovered ? background : color}
      background={isHovered ? hoveredBackground : background}

      flex={props.flex}
      // style={{ margin: 0, color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}

      // onClick={props.onClick}
      onClick={() => handleClick()}

      // ishovered={isHovered}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}

    >

      {
        props.icon
        &&
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <Icon name={props.icon} />
        </div>

      }
      {
        props.checkStatus === undefined
        ? null
        : <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          { !props.checkStatus
            ? <Image noDelay width={25} height={25} src={AttentionIcon} />
            :
              props.checkStatus === 'pending'
              ? <Image noDelay width={25} height={25} src={PendingIcon} />
              : <Image noDelay width={25} height={25} src={UploadedIcon} />

          }
        </div>

      }
      {
        (props.header || props.description)
        &&
        <div style={{ flex: 1, paddingLeft: 16, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {
              props.header
              && <H5 style={{ borderColor: 'blue', borderWidth: 0, borderStyle: 'solid', textAlign: 'left' }}>{props.header}</H5>
            }
            {
              props.description
              && <A5 style={{ borderColor: 'blue', borderWidth: 0, borderStyle: 'solid', textAlign: 'left' }}><i>{props.description}</i></A5>
            }
          </div>
        </div>
      }

      {
        props.children
        &&
        props.children
      }


      {
        props.button
        &&
        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 8 }}>
          {props.button}
        </div>
      }
      {
        !props.button &&
        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 8 }}>
          <H2>{'>'}</H2>
        </div>
      }


    </StyledButton>
  );
};


export default RowButton;
