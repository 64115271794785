// import update from 'immutability-helper';
import * as trans from '../actions/transactions';

export const initialState = {
  isFetchingAllTransactions: false,
  all_transactions: [],
  error: undefined
};

export default function transactions (state = initialState, action) {
  switch (action.type) {

    case trans.GET_ALL_TRANSACTIONS_REQUEST:
      return {
        ...state,
        isFetchingAllTransactions: true
      };
    case trans.GET_ALL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isFetchingAllTransactions: false,
        all_transactions: action.data.transactions
      };
    case trans.GET_ALL_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isFetchingAllTransactions: false,
        error: action.error
      };

    default:
      return state;
  }
}
