import _ from 'lodash';
import getTimeAndPoints from './getTimeAndPoints';
import isDateTimeInPast from './timeHelpers';
import { sit_details } from '../text';

export const buildThreadDetails = (editMode, newMessageThreadParams, thread_details, tariff_details, edited_thread_details) => {

  // console.log('buildThreadDetails');
  // if editMode use new search props
  console.log('editMode:', editMode);

  if (!editMode) {
    // console.log('newMessageThreadParams', newMessageThreadParams);
    if (_.isEmpty(thread_details) && !_.isEmpty(newMessageThreadParams)) {
      // is a brand new sit request
      console.log('buildThreadDetails - brand new request');

      const { saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, selected_kids } = newMessageThreadParams.search_details;
      const endDate = !saved_end_date ? saved_start_date : saved_end_date;


      // console.log('saved_start_date:', saved_start_date);
      // console.log('saved_start_hr:', saved_start_hr);
      // console.log('saved_start_min:', saved_start_min);
      // console.log('tariff_details', tariff_details);

      const spQuote = getTimeAndPoints(tariff_details, saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, selected_kids);

      console.log('spQuote', spQuote);

      // allow for change of thread_details in Sit Details and then
      // calc mins and points calc to this or on thread load. and update redux state for thread.
      // then allow for editing of SitDetailsBelow

      const newMessageThreadDetails = {
        thread_id: '',
        requester_id: newMessageThreadParams.sender_id,
        sitter_id: newMessageThreadParams.receiver_id,
        start_date: saved_start_date,
        end_date: endDate,
        start_time: `${saved_start_hr}:${saved_start_min}`,
        end_time: `${saved_end_hr}:${saved_end_min}`,
        kids: selected_kids,
        requester_photo_url: newMessageThreadParams.sender_photo_url,
        sitter_name: newMessageThreadParams.receiver_name,
        requester_name: newMessageThreadParams.sender_name,
        sitter_photo_url: newMessageThreadParams.receiver_photo_url,
        mins_quoted: spQuote.timeQuote,
        sp_quoted: spQuote.spQuote,
        status: 'new',
        actions: []
        // actions_history = [];
      };
      // console.log('newMessageThreadDetails', newMessageThreadDetails);
      return newMessageThreadDetails;
    }
  }

  // console.log('edited_thread_details:', edited_thread_details);
  // console.log('thread_details:', thread_details);

  let searchDetails = {};
  // set searchDetails dependent on editMode (latest search state) or thread_details.
  if (editMode) {
    if (!_.isEmpty(edited_thread_details)) {
      // details have been edited
      console.log('buildThreadDetails - details edited');

      console.log('buildThreadDetails thread_details:', thread_details);
      // console.log('buildThreadDetails edited_thread_details:', edited_thread_details);
      console.log('buildThreadDetails edited_thread_details:', edited_thread_details);
      console.log('!_.isEmpty(edited_thread_details', !_.isEmpty(edited_thread_details));
      searchDetails = {
        saved_start_date: edited_thread_details.saved_start_date !== null ? edited_thread_details.saved_start_date : thread_details.start_date,
        saved_end_date: edited_thread_details.saved_end_date !== null ? edited_thread_details.saved_end_date : thread_details.end_date,
        saved_start_hr: edited_thread_details.saved_start_hr !== null ? edited_thread_details.saved_start_hr : thread_details.start_time.slice(0, 2),
        saved_end_hr: edited_thread_details.saved_end_hr !== null ? edited_thread_details.saved_end_hr : thread_details.end_time.slice(0, 2),
        saved_start_min: edited_thread_details.saved_start_min !== null ? edited_thread_details.saved_start_min : thread_details.start_time.slice(-2),
        saved_end_min: edited_thread_details.saved_end_min !== null ? edited_thread_details.saved_end_min : thread_details.end_time.slice(-2),
        selected_kids: edited_thread_details.saved_selected_kids !== null ? edited_thread_details.saved_selected_kids : thread_details.kids

      };

      console.log('searchDetails1 : ', searchDetails);
    }
  } else if (!_.isEmpty(thread_details)) {
    // else has not been edited and is not a new request
    console.log('buildThreadDetails - details not edited, existing request');

      // console.log('!_.isEmpty(thread_details', thread_details);
      // console.log('thread_details:', thread_details);
      const { start_date, end_date, start_time, end_time, kids } = thread_details;
      const saved_start_date = start_date;
      const saved_end_date = end_date;
      const saved_start_hr = thread_details.start_time === null ? '' : start_time.slice(0, 2);
      const saved_start_min = thread_details.start_time === null ? '' : start_time.slice(-2);
      const saved_end_hr = thread_details.end_time === null ? '' : end_time.slice(0, 2);
      const saved_end_min = thread_details.end_time === null ? '' : end_time.slice(-2);
      const selected_kids = kids;
      // const receiver_photo_url = requester_photo_url;


      searchDetails = { saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, selected_kids };

  }

  console.log('searchDetails2 : ', searchDetails);

  if (!_.isEmpty(searchDetails)) {
    // searchDetails are populated and is not a brand new request
    console.log('buildThreadDetails - not a brand new request');

    const { saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, selected_kids } = searchDetails;

    // console.log('searchDetails:', searchDetails);
    const isInPast = isDateTimeInPast(saved_end_date, `${saved_end_hr}:${saved_end_min}`);
    const spQuote = getTimeAndPoints(tariff_details, saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, selected_kids);
    // console.log('spQuote:', spQuote);
    const actualQuote = (isInPast) ? spQuote.spQuote : 0;
    const updatedSpQuote = (!isInPast) ? spQuote.spQuote : thread_details.sp_quoted;
    const actualTimeQuote = (isInPast) ? spQuote.timeQuote : '';
    const updatedTimeQuote = (!isInPast) ? spQuote.timeQuote : thread_details.mins_quoted;

    const threadStartTime = `${saved_start_hr}:${saved_start_min}`;
    const threadEndTime = `${saved_end_hr}:${saved_end_min}`;
    const threadStartDate = saved_start_date;
    const threadEndDate = !saved_end_date ? saved_start_date : saved_end_date;
    const threadKids = selected_kids;

    // const endDate = !saved_end_date ? saved_start_date : saved_end_date;

    const threadDetails = {
      ...thread_details,
      start_date: threadStartDate,
      end_date: threadEndDate,
      start_time: threadStartTime,
      end_time: threadEndTime,
      kids: threadKids,
      mins_quoted: updatedTimeQuote,
      mins_actual: actualTimeQuote,
      sp_quoted: updatedSpQuote,
      sp_actual: actualQuote,
      thread_id: !thread_details.thread_id ? '' : thread_details.thread_id,
      status: !thread_details.status ? 'new' : thread_details.status,
      requester_id: !thread_details.requester_id ? newMessageThreadParams.sender_id : thread_details.requester_id,
      sitter_id: !thread_details.sitter_id ? newMessageThreadParams.receiver_id : thread_details.sitter_id,
      requester_photo_url: !thread_details.requester_photo_url ? newMessageThreadParams.sender_photo_url : thread_details.requester_photo_url,
      sitter_name: !thread_details.sitter_name ? newMessageThreadParams.receiver_name : thread_details.sitter_name,
      requester_name: !thread_details.requester_name ? newMessageThreadParams.sender_name : thread_details.requester_name,
      sitter_photo_url: !thread_details.sitter_photo_url ? newMessageThreadParams.receiver_photo_url : thread_details.sitter_photo_url
    };
    console.log('threadDetails:', threadDetails);

    return threadDetails;
  }


};

export const getCancelButtonText = (editMode, setEditMode, showReviewModal, subscription_status, messagesLength, user_id, sitter_id, requester_id, isInPast, isStarted, status, thread_details, sp_actual, requester_rating, sitter_rating, edited_thread_details) => {


    // if messages.length = 0 then cant send request

    // ---------sitter view


    // this.renderCancelRequestButton()

    if ((user_id === sitter_id) && !isInPast && !isStarted && (status === 'request')) {
      return sit_details.cancel_decline;
    }

    if ((user_id === sitter_id) && !isInPast && !isStarted && (status === 'pre_approved')) {
      return sit_details.cancel_pre_approve;
    }
    // this.renderCancelPreApproveButton()

    if ((user_id === sitter_id) && !isInPast && !isStarted && (status === 'approved')) {
      return sit_details.cancel_approval;
    }
    // this.renderCancelApproveButton()  // need to put back to request or cancelled status, rather than pre-approved

    if ((user_id === sitter_id) && isInPast && (status === 'approved')) {
      if (editMode) {
        return null; // to be reviewed
      }
      return sit_details.cancel_approved_not_happened;
      // do we want the sitter to be able to cancel if sit didn't happen?  Or leave it for the requester to do it? Do we need both? Or a hide button from each feed?
      // If not we can remove this button.  Is there then a risk that events that never happen stay in inbox forever?

    }

    if ((user_id === sitter_id) && isInPast && (status !== 'approved' && status !== 'pre_authorised' && status !== 'finalised')) {
      if (editMode) {
        return null; // to be reviewed
      }
      return null;
      // return sit_details.cancel_expired_not_approved;
      // do we want the sitter to be able to cancel if sit wasn't approved? and didn't happen?  Or leave it for the requester to do it?
      // If not we can remove this button.  Is there then a risk that events that never happen stay in inbox forever?

    }

    if ((user_id === sitter_id) && isInPast && (status === 'pre_authorised')) {
      if (editMode) {
        // exit editMode
        return sit_details.cancel_exit_edit;

      }

      return sit_details.cancel_expired_pre_authorised;
      // do we want the sitter to be able to cancel if sit they have entered final details, then realised it was the wrong sit and didn't actually happen?  Or leave it for the requester to do it?
      // If not we can remove this button.  Is there then a risk that events that never happen stay in inbox forever?

    }


    // ----------requester view

    if ((messagesLength !== 0) && (user_id !== sitter_id) && !isInPast && !isStarted && (status !== 'cancelled')) {
      // reset send button and message field to null/false
      return sit_details.cancel_request;
    }

    if ((user_id === requester_id) && !isInPast && !isStarted && (status === 'approved')) {
      return sit_details.cancel_pre_approve;
    }
    // this.renderCancelApproveButton()

};

export const getApproveButtonDetails = (editMode, setEditMode, showReviewModal, subscription_status, messagesLength, user_id, sitter_id, requester_id, isInPast, isStarted, status, thread_details, sp_actual, requester_rating, sitter_rating, edited_thread_details) => {

  // console.log('editMode: ', editMode);

  console.log('SET_NEW_MESSAGE action getApproveButtonDetails calc props:', { editMode, setEditMode, showReviewModal, subscription_status, messagesLength, user_id, sitter_id, requester_id, isInPast, isStarted, status, thread_details, sp_actual, requester_rating, sitter_rating, edited_thread_details });

  // if messages.length = 0 then cant send request

  // --------sitter view


  if ((user_id !== sitter_id) && !isInPast && !isStarted && (!status)) {
    return { action: 'message', text: sit_details.pre_approve_sitting };
  }

  if ((user_id !== sitter_id) && !isInPast && !isStarted && (status === 'new')) {
    return { action: 'edit_new', text: null };
  }

  if ((user_id === sitter_id) && !isInPast && !isStarted && (status === 'request')) {
    return { action: 'pre_approve', text: sit_details.pre_approve_sitting };
  }

  if ((user_id === sitter_id) && isInPast && status === 'approved' && status !== 'finalised') {
    // edit final details or pre-authorise
    return { action: 'pre_authorise', text: sit_details.pre_authorise_final_details };
  }

  if ((user_id === sitter_id) && isInPast && (status === 'pre_authorised')) {
    if (editMode) {
      // only return if details have been amended.

      const { saved_start_date: edit_start_date, saved_end_date: edit_end_date, saved_start_hr: edit_start_hr, saved_start_min: edit_start_min, saved_end_hr: edit_end_hr, saved_end_min: edit_end_min, saved_selected_kids: edit_kids } = edited_thread_details;
      const useEditEndDate = !edit_end_date ? edit_start_date : edit_end_date;
      const start_time = `${edit_start_hr}:${edit_start_min}`;
      const end_time = `${edit_end_hr}:${edit_end_min}`;
      const editParameters = (!edit_start_date || !useEditEndDate || !edit_start_hr || !edit_start_min || !edit_end_hr || !edit_end_min || !edit_kids) ? null : { start_date: edit_start_date, end_date: useEditEndDate, start_time, end_time, kids: edit_kids, sp_actual };

      const threadParameters = (!thread_details.start_date || !thread_details.end_date || !thread_details.start_time || !thread_details.end_time || !thread_details.kids) ? null : { start_date: thread_details.start_date, end_date: thread_details.end_date, start_time: thread_details.start_time, end_time: thread_details.end_time, kids: thread_details.kids, sp_actual: thread_details.sp_actual };
      const areEditParamsSame = JSON.stringify(threadParameters) === JSON.stringify(editParameters);
      // console.log('threadParameters:', JSON.stringify(threadParameters));
      // console.log('editParameters:', JSON.stringify(editParameters));
      // console.log('areEditParamsSame:', areEditParamsSame);
      if (areEditParamsSame) {
        return null;
      }
        // else
        return { action: 'updated_pre_authorised', text: sit_details.pre_authorise_final_details };
    }
    // else
      return null;
      // no need to return approve button, only edit button


    // {`Waiting for ${requester_name} to finalise the sit`}
  }
  // this.renderUpdatePreAuthoriseButton()


  if ((user_id === requester_id) && isInPast && (status === 'finalised') && (sitter_rating === -1)) {
    return { action: 'review', text: sit_details.review };
  }

  // console.log('thread_details:', thread_details);

  if ((user_id === requester_id) && (status === 'cancelled') && (sitter_rating === -1)
    && thread_details.actions.some(e => e.action === 'approved')
    // and thread actions array includes 'approved'
  ) {
    return { action: 'review', text: sit_details.review };
  }
  // this.renderReviewButton()


    // --------requester view

  if (editMode && (user_id === requester_id) && !isInPast && !isStarted && (status === 'request' || status === 'pre_approved' || status === 'approved')) {
    // if editDetails, then allow to click to Update
    // only return if details have been amended.

    const { saved_start_date: edit_start_date, saved_end_date: edit_end_date, saved_start_hr: edit_start_hr, saved_start_min: edit_start_min, saved_end_hr: edit_end_hr, saved_end_min: edit_end_min, saved_selected_kids: edit_kids } = edited_thread_details;
    const useEditEndDate = !edit_end_date ? edit_start_date : edit_end_date;
    const start_time = `${edit_start_hr}:${edit_start_min}`;
    const end_time = `${edit_end_hr}:${edit_end_min}`;
    const editParameters = (!edit_start_date || !useEditEndDate || !edit_start_hr || !edit_start_min || !edit_end_hr || !edit_end_min || !edit_kids) ? null : { start_date: edit_start_date, end_date: useEditEndDate, start_time, end_time, kids: edit_kids, sp_actual };

    const threadParameters = (!thread_details.start_date || !thread_details.end_date || !thread_details.start_time || !thread_details.end_time || !thread_details.kids) ? null : { start_date: thread_details.start_date, end_date: thread_details.end_date, start_time: thread_details.start_time, end_time: thread_details.end_time, kids: thread_details.kids, sp_actual: thread_details.sp_actual };
    const areEditParamsSame = JSON.stringify(threadParameters) === JSON.stringify(editParameters);
    // console.log('threadParameters:', JSON.stringify(threadParameters));
    // console.log('editParameters:', JSON.stringify(editParameters));
    // console.log('areEditParamsSame:', areEditParamsSame);
    if (areEditParamsSame) {
      return null;
    }
      // else
      return { action: 'edit', text: sit_details.edit_request_send };
  }

  if ((user_id === requester_id) && !isInPast && !isStarted && (status === 'pre_approved')) {

    if ((subscription_status !== 'active' && subscription_status !== 'trialing' && subscription_status !== 'past_due') && thread_details.payment_status !== 'paid') {
      // if not member and not paid then take payment (membership or payg)

        // history.push({
        //   pathname: ROUTES.SUBSCRIBE_TO_CONTACT
        // });

      // what if not member but already paid?  e.g. cancelled approval, back to pre-approval.
      // refund policy?
      return { action: 'approve', text: sit_details.approve_sitting };

    }
      // else
      // if member
      // then approve sit

      // need to add sp_quoted and user_currency;
      return { action: 'approve', text: sit_details.approve_sitting };

    // approve_sitting: sitDetails.buttons.approve
  }
  // this.renderApproveButton()


  if ((user_id === requester_id) && isInPast && (status === 'pre_authorised')) {

    return { action: 'finalise', text: sit_details.approve_final_details };
  }
  // this.renderFinaliseButton()


  if ((user_id === sitter_id) && isInPast && (status === 'finalised') && (requester_rating === -1)) {
    return { action: 'review', text: sit_details.review };
  }

  if ((user_id === sitter_id) && (status === 'cancelled') && (requester_rating === -1)
    && thread_details.actions.some(e => e.action === 'approved')
    // and thread actions array includes 'approved'
  ) {
    return { action: 'review', text: sit_details.review };
  }
    // this.renderReviewButton()


  // else
  return null;
  // no need to return approve button, only edit button


    // (user_id === requester_id) && isInPast && (status === 'finalised')
    // && <div style={{ width: '100%', padding: 4, borderColor: 'green', borderWidth: 0, borderStyle: 'solid', alignItems: 'center' }}>
    //   <H5 style={{ color: colours.red, textAlign: 'center' }}>This sit has been finalised</H5>
    // </div>


};

export const getEditButtonDetails = (editMode, setEditMode, showReviewModal, subscription_status, messagesLength, user_id, sitter_id, requester_id, isInPast, isStarted, status, thread_details, sp_actual, requester_rating, sitter_rating, edited_thread_details) => {
  // console.log('editMode: ', editMode);
  // console.log('status: ', status);
  // console.log('isInPast: ', isInPast);

  if (editMode) {
    return null;
  }
  // if messages.length = 0 then cant send request

  // --------sitter view

  if (user_id === sitter_id) {

      if (!isInPast) {
        return null;
      }
      // else if is in past
      if (status === 'finalised') {
        return null;
      }
      // else if pre-authorised
      if (status === 'pre_authorised') {
      // amend final details
      return { action: 'edit', text: sit_details.amend_final_details };
      }
      if (status === 'approved') {
      // amend final details
      return { action: 'edit', text: sit_details.amend_final_details };
      }


  }


  // --------requester view

  if (user_id === requester_id) {

    if (!isInPast && !isStarted) {
      // if is not in past
      if (status === 'new' || status === 'request' || status === 'pre_approved') {
        // if not yet confirmed/approved
        return { action: 'edit', text: sit_details.edit_request_details };
      }

    }

  }

  // else if none of above
  return null;


};


export default buildThreadDetails;
