// import update from 'immutability-helper';
import * as messages from '../actions/messages';

export const initialState = {
  isFetchingAllThreads: false,
  all_threads: [],
  error: undefined,
  active_thread_id: undefined,
  numberOfUnreadMessages: 0
};

export default function inbox (state = initialState, action) {
  switch (action.type) {

    case messages.GET_ALL_THREADS_REQUEST:
      return {
        ...state,
        isFetchingAllThreads: true
      };
    case messages.GET_ALL_THREADS_SUCCESS:
      return {
        ...state,
        isFetchingAllThreads: false,
        all_threads: action.data

      };
    case messages.GET_ALL_THREADS_FAILURE:
      return {
        ...state,
        isFetchingAllThreads: false,
        error: action.error
      };

    case messages.SET_ACTIVE_THREAD_ID:
      return {
        ...state,
        active_thread_id: action.data
      };

    case messages.SET_NO_OF_UNREAD_MESSAGES:
      return {
        ...state,
        numberOfUnreadMessages: action.data
      };

    default:
      return state;
  }
}
