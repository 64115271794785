import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";
import { StyledP as B6 } from '../b/b6';


export const StyledP = styled(B6)`
  padding-left: 0px;
  padding-right: 4px;

  font-style: ${props => props.fontStyle ? props.fontStyle : null}!important;
  color: ${props => props.color ? props.color : `${colours.gray}!important`};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'}!important;
  font-size: ${props => props.screensize = 'small' ? '1em' : '4em'}!important;
`;

const mapStateToProps = (layout, ownProps) => {

    return {
      screensize: layout.screensize,
      color: ownProps.color,
      textAlign: ownProps.textAlign,
      ...ownProps
    };

};


class FormLabelSmallText extends Component {

  render () {

    const { screensize, color, textAlign, fontStyle } = this.props;
    // console.log('B5 props:', this.props);

    return (
      <StyledP

        screensize={screensize}
        fontStyle={fontStyle}
        color={color || null}
        textAlign={textAlign || null}

      >
        {this.props.children}
      </StyledP>
    );

  }
}

export default connect(
    mapStateToProps
)(FormLabelSmallText);
