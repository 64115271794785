import history from '../../components/Navigation/history';
import { showModal, hideModal } from '../../actions/modal';
import { getMessageThread } from '../messages';
import { actionSuccess } from '../verify';
import { resetActionBar } from '../layout';
import { setShowSubscribePayment } from './index';
import { plan as planText, sit_details } from '../../text';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_SUCCESS, MODAL_TYPE_ERROR } from '../../components/modals/modalTypes';
import { SUCCESS_APPROVE } from '../../components/modals/modalNames';
import { PLAN, BOOKING } from '../../components/modals/modalCategory';
import * as ROUTES from '../../constants/routes';


export const SUBMIT_PAYMENT_REQUEST = 'SUBMIT_PAYMENT_REQUEST';
export const SUBMIT_PAYMENT_SUCCESS = 'SUBMIT_PAYMENT_SUCCESS';
export const SUBMIT_PAYMENT_FAILURE = 'SUBMIT_PAYMENT_FAILURE';

export const SUBMIT_SUBSCRIPTION_REQUEST = 'SUBMIT_SUBSCRIPTION_REQUEST';
export const SUBMIT_SUBSCRIPTION_SUCCESS = 'SUBMIT_SUBSCRIPTION_SUCCESS';
export const SUBMIT_SUBSCRIPTION_FAILURE = 'SUBMIT_SUBSCRIPTION_FAILURE';


export const submitPaymentRequest = () => ({
  type: SUBMIT_PAYMENT_REQUEST
});

export const submitPaymentSuccess = data => ({
  type: SUBMIT_PAYMENT_SUCCESS,
  data
});

export const submitPaymentFailure = error => ({
  type: SUBMIT_PAYMENT_FAILURE,
  error
});

export const submitSubscriptionRequest = () => ({
  type: SUBMIT_SUBSCRIPTION_REQUEST
});

export const submitSubscriptionSuccess = data => ({
  type: SUBMIT_SUBSCRIPTION_SUCCESS,
  data
});

export const submitSubscriptionFailure = error => ({
  type: SUBMIT_SUBSCRIPTION_FAILURE,
  error
});


// need similar for subscription creation (without sit)
export function createCustomer (token, messageParams, user_id, email, payment_method, plan, promo_id) {
  // console.log('payment_method:', payment_method);
  // console.log('plan:', plan);

  console.log('CREATE CUSTOMER');
  return (dispatch) => {
    console.log('CREATE CUSTOMER dispatch');

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: planText.modal.subscribe.fetching
      }
    ));
    dispatch(submitPaymentRequest());

    // determin if subscription or booking with subscription (will have thread_id)

    // approve Sit if came via booking
    console.log('!messageParams:', !messageParams);

    if (!messageParams) {
      // subscription only

      console.log('subscribe Customer /payment/customer/subscribe:');

      fetch(`${process.env.REACT_APP_API_URI}/payment/customer/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: token,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        },
        body: JSON.stringify({
          user_id,
          email,
          payment_method,
          plan,
          promo_id,
          message_params: messageParams
        })

      })
      .then(CheckError)
      .then((data) => {
        console.log('customer created response:', data);
        dispatch(submitSubscriptionSuccess(data));

        console.log('no thread = subscription, so goto myPlan:');
        // success message, get user's updated subscription status and back to plan?

        const datapayments = data.payments;

        const lastpaymentlength = datapayments.length;
        console.log('lastpaymentlength:', lastpaymentlength);

        // const lastpayment = JSON.parse(datapayments[datapayments.length - 1]);
        // const amount = lastpayment.amount;
        // const currency_amount = amount;

        dispatch(hideModal());
        dispatch(resetActionBar()); // resets MessageBar and ActionBar
        // show modal showing Payment success/confirmed booking.
        // dispatch(showModal(MODAL_TYPE_SUCCESS_APPROVE, {
        //   // payment_made: currency_amount !== 0,
        //   payment_type: 'subscription'
        // }));

        const modalText = planText.modal.subscribe.success;


        dispatch(showModal(

          MODAL_TYPE_SUCCESS,
          { // modalProps
            // other_user_name?
            modalCategory: PLAN,
            modalName: SUCCESS_APPROVE,
            modalText: modalText,
            okAction: () => {
              // dispatch(hideModal());
              window.location.reload(true); // ensures myPlan updates and scrolls to top
            },
            backAction: null,

            payment_type: 'subscription'

          }
        ));

        dispatch(actionSuccess()); // triggers success icon

        dispatch(setShowSubscribePayment(false));

        // no messageParams - so previous screen must be either myPlan or searchResults/contact user,
        // so goBack() should take back to relevant page.  Check reload behaviour above.

        console.log('window.location: ', window.location);
        history.goBack();
        // should keep current state

        // history.push({
        //   pathname: ROUTES.PLAN,
        //   state: { editMode: false }
        // });
      })
      .catch((err) => {
        console.log('caught customer created error: ', err);

        dispatch(submitSubscriptionFailure(err.message));
        // centralise bugsnag reporting
        ReportError(!err.code ? null : err.code, err.message, {});
        dispatch(showModal(
          MODAL_TYPE_ERROR,
          { // modalProps
            title: planText.modal.subscribe.failure.title,
            header: planText.modal.subscribe.failure.header,
            message: planText.modal.subscribe.failure.para,
            message2: planText.modal.subscribe.failure.para2,
            error_status: err.code && err.code,
            error_message: err.message
          }
        ));

        dispatch(actionSuccess());

      });

    } else {

      // sit and subscription
      console.log('createCustomer /payment/customer/create:');

      fetch(`${process.env.REACT_APP_API_URI}/payment/customer/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: token,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        },
        body: JSON.stringify({
          user_id,
          email,
          payment_method,
          plan,
          promo_id,
          message_params: messageParams
        })

      })
      .then(CheckError)
      .then((data) => {

        console.log('customer created subscriptionPayment and Approve response data:', data);

        if (data) { // not sure if we need?
          // dispatch(approveSitSuccess(data.thread_id)); // do we need?

          dispatch(submitPaymentSuccess(data)); // adds users' payments to state (but is it used?)
          const { user_data } = data;

          console.log('user_data:', user_data); // is this useful?

          const message_thread_id = data.thread_id;
          dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));

          dispatch(hideModal());
          dispatch(resetActionBar()); // resets MessageBar and ActionBar
          // show modal showing Payment success/confirmed booking.
          // dispatch(showModal(
          //   MODAL_TYPE_SUCCESS_APPROVE,
          //   { // modalProps
          //     // payment_made: currency_amount !== 0,
          //     payment_type: 'sit_subscription'
          //   }
          // ));

          const modalText = sit_details.modal.approve.success.sub;


          dispatch(showModal(

            MODAL_TYPE_SUCCESS,
            { // modalProps
              // other_user_name?
              modalCategory: BOOKING,
              modalName: SUCCESS_APPROVE,
              modalText: modalText,
              okAction: () => dispatch(hideModal()),
              backAction: null,

              payment_type: 'sit_subscription'

            }
          ));

          dispatch(actionSuccess()); // triggers success icon

          console.log('second thread route push');
          history.push({
            pathname: ROUTES.THREAD,
            state: { editMode: false }
          });
        } else {

          // throw error
          throw new CustomException(200, 'Your payment was not authorised by your card issuer or the sit could not be confirmed');

        }
      })
      .catch((err) => {
        console.log('caught customer created error: ', err);

        dispatch(submitSubscriptionFailure(err.message));
        // centralise bugsnag reporting
        ReportError(!err.code ? null : err.code, err.message, {});
        dispatch(showModal(
          MODAL_TYPE_ERROR,
          { // modalProps
            title: planText.modal.subscribe.failure.title,
            header: planText.modal.subscribe.failure.header,
            message: planText.modal.subscribe.failure.para,
            message2: planText.modal.subscribe.failure.para2,
            error_status: err.code && err.code,
            error_message: err.message
          }
        ));

        dispatch(actionSuccess());

      });


    }

  };

}
