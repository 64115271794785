import React from 'react';
import { L4 } from '../styles/text';
import { verification_doc_points, profile_completed_points, total_verification_points } from '../rules/points';
import { GUEST_FAMILY, HOST_FAMILY } from './terms';
import { REFERRALS_SIGNUP_LIMIT, REFERRAL_SIGNUP, REFERRAL_FIRSTSIT } from '../constants/bonuses';

export const offerDetails = {
  page_title: 'Refer Friends Offer',
  intro: `This page sets out the terms for the SitterPoint 'Refer Friends' offer.`,
  end1: 'If you can’t find the answer to your question, please contact us at ',
  end2: 'or by using our request form',
  last_update: `Last Updated: 12 September 2023`,
  items: [

    { text_type: "para", text: `` },

    { text_type: "section_title", text: `1. THE OFFER` },

    { text_type: "para", text: `1.1 - Each friend you invite who successfully signs up to Sitterpoint.co.uk will receive 3 month's free membership (normally £14.99 per month).` },
    { text_type: "para", text: `1.2 - In addition, for each user you refer, you will receive:` },

    { text_type: "bullets", bullets: [
        { text: `a bonus of ${REFERRAL_SIGNUP} SittingPoints (SP) when they signup` },
        { text: `a bonus of ${REFERRAL_FIRSTSIT} SittingPoints (SP) when they complete their first sit (as a ${GUEST_FAMILY})` }
      ]
    },

    { text_type: "para", text: `1.3 - This offer is valid up to a maximum of ${REFERRALS_SIGNUP_LIMIT} friends, meaning you could get up to a maximum of 1,500 SP (that's equivalent 15 hours of free childcare for a child when using the SitterPoint platform).` },

    { text_type: "para", text: `` }

  ],
  button_intro: `Click the button below to return to previous page:`,
  button_text: `Go Back`

};


export default offerDetails;
