export const HOME = '/';
export const CONTACT_BETA = '/contactBeta';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LANDING = '/landing';
export const DASHBOARD = '/dashboard';

export const INVITE = '/invite';
export const OFFER_DETAILS = '/offer-details';

export const EDIT_DETAILS = '/editDetails';
export const WELCOME = '/welcome';
export const ABOUT_CHILDREN = '/aboutChildren';
export const EDIT_CHILD = '/child';
export const WHERE_YOU_LIVE = '/whereYouLive';
export const TELL_US_ABOUT_YOURSELF = '/tellUsAboutYourself';
export const EDIT_ADULT = '/adult';
export const PROFILE_PHOTO = '/profilePhoto';
export const BIO = '/bio';

export const PROFILE_SUMMARY = '/profileSummary';
// export const ACCOUNT_HISTORY = '/accountHistory';
export const POINTS_HISTORY = '/pointsHistory';
export const PAYMENT_HISTORY = '/paymentHistory';
export const PLAN = '/myPlan';
export const PRICING = '/plansAndPricing';
export const ADMIN = '/admin';
export const INBOX = '/inbox';
export const RESULTS = '/results';
export const REQUEST_PROFILE = '/requestProfile';
export const USER_REVIEWS = '/userReviews';
export const THREAD = '/thread';
export const AVAILABILITY = '/availability';
export const CONTACT = '/contact';
export const FAQ = '/faq';
export const VERIFY = '/verify';
export const UPLOAD_VERIFICATION = '/uploadVerification';
export const CHOOSE_ADULT = '/chooseAdult';
export const VERIFY_MAIN_ADULT = '/verifyMainAdult';
export const VERIFY_OTHER_ADULTS = '/verifyOtherAdults';
export const VERIFY_DBS_MAIN_ADULT = '/verifyDBSMainAdult';

export const VERIFY_POA = '/verifyPOA';
export const VERIFY_SELFIE = '/verifySelfie';
export const REFER = '/refer';
export const SUBSCRIBE_TO_CONTACT = '/subscribeToContact';
export const SUBSCRIBE = '/subscribe';
export const SUBSCRIBE_PAY = '/subscribe_payment';
export const ADMIN_DETAILS = '/admin/:id';
export const PASSWORD_FORGET = '/pw-forget';

export const HELP = '/help';
export const HOWITWORKS = '/howItWorks';
export const PHILOSOPHY = '/ourPhilosophy';
export const SITTER_POINTS = '/sittingPoints';
export const ADMIN_VERIFICATION = '/admin/verification';
export const ADMIN_VIEW_VERIFY_DOC = '/admin/view_doc';
export const TYPO = '/typo';

export const TIPS = '/tips';
export const PLANS = '/plans';
export const TERMS = '/terms';
export const PRIVACY = '/privacy';
export const DATA_DELETION = '/data-deletion';
export const COOKIES = '/cookies';
export const VERIFY_EMAIL = '/verify-email';
