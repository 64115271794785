import colours from './colours';


export const fonts = {
  default: 'Roboto, Lato, Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
  default2: 'Open Sans, Arial, Helvetica, sans-serif',
  thin: 300,
  normal: 400,
  semibold: 600,
  bold: 700
};

export const padding = {
  s: [0, 0, 0, 0],
  m: [4, 4, 4, 4],
  l: [8, 8, 8, 8],
  vl: [16, 16, 16, 16]
};


export const default_theme = {
  theme_id: 0,
  theme_name: 'default',
  selected: true,
  theme_details: {
    breakpoints: ["319px", "599px", "900px"],
    otherStyles: {
      bullet: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        tag: 'div',

        lineHeight: ['30px', '30px', '30px'],
        width: ['30px', '30px', '30px'],
        height: ['30px', '30px', '30px'],

        borderWidth: '0px',
        borderStyle: 'solid',
        borderRadius: '50%'

      }
    },
    layoutStyles: {
      main: {
        paddingLeft: ['16px', '16px', '16px'],
        paddingRight: ['16px', '16px', '16px']
      },
      s: {
        paddingLeft: ['0px', '0px', '0px'],
        paddingRight: ['0px', '0px', '0px']
      },
      m: {
        paddingLeft: ['4px', '4px', '4px'],
        paddingRight: ['4px', '4px', '4px']
      },
      l: {
        paddingLeft: ['0px', '0px', '0px'],
        paddingRight: ['0px', '0px', '0px']
      },
      vl: {
        paddingLeft: ['16px', '16px', '16px'],
        paddingRight: ['16px', '16px', '16px']
      }
    },
    textStyles: {
      a1: {
        id: 'a1',
        tag: 'h1',
        fontSize: ['26px', '26px', '32px'],
        fontWeight: [fonts.normal, fonts.normal, fonts.normal],
        // lineHeight: ["56px", "56px", "72px"],
        fontFamily: [fonts.default, fonts.default, fonts.default],
        color: colours.gray,
        textAlign: 'left',
        paddingTop: ['8px', '8px', '8px'],
        paddingBottom: ['8px', '8px', '8px'],
        extended_tags: {
          h1: {
            tag: 'h1',
            fontWeight: [fonts.semibold, fonts.semibold, fonts.semibold],
            color: colours.main
          }
        }
      },
      a2: {
        id: 'a2',
        tag: 'h2',
        fontSize: ['20px', '25px', '25px'],
        fontWeight: [fonts.normal, fonts.normal, fonts.normal],
        // lineHeight: ["32px", "36px", "56px"],
        fontFamily: [fonts.default, fonts.default, fonts.default],
        color: colours.gray,
        textAlign: 'left',
        paddingTop: ['4px', '4px', '4px'],
        paddingBottom: ['8px', '8px', '8px']
      },
      a3: {
        id: 'a3',
        tag: 'h3',
        fontSize: ['19px', '19px', '19px'],
        fontWeight: [fonts.normal, fonts.normal, fonts.normal],
        // lineHeight: ["32px", "36px", "56px"],
        fontFamily: [fonts.default, fonts.default, fonts.default],
        color: colours.gray,
        textAlign: 'left',
        paddingTop: ['4px', '4px', '4px'],
        paddingBottom: ['4px', '4px', '4px'],
        extended_tags: {
          h3: {
            tag: 'h3',
            fontWeight: [fonts.semibold, fonts.semibold, fonts.semibold],
            color: colours.gray
          },
          b3: {
            tag: 'b3',
            fontWeight: [fonts.bold, fonts.bold, fonts.bold],
            color: colours.black
          }
        }
      },
      a4: {
        id: 'a4',
        tag: 'p',
        fontSize: ['16px', '16px', '16px'],
        fontWeight: [fonts.normal, fonts.normal, fonts.normal],
        // lineHeight: ["32px", "36px", "56px"],
        fontFamily: [fonts.default, fonts.default, fonts.default],
        color: colours.gray,
        textAlign: 'left',
        paddingTop: ['4px', '4px', '4px'],
        paddingBottom: ['4px', '4px', '4px']
      },
      a5: {
        id: 'a5',
        tag: 'p',
        fontSize: ['14px', '14px', '14px'],
        fontWeight: [fonts.normal, fonts.normal, fonts.normal],
        // lineHeight: ["32px", "18px", "56px"],
        fontFamily: [fonts.default, fonts.default, fonts.default],
        color: colours.gray,
        textAlign: 'left',
        paddingTop: ['4px', '4px', '4px'],
        paddingBottom: ['4px', '4px', '4px'],
        extended_tags: {
          h5: {
            tag: 'h5',
            fontWeight: [fonts.semibold, fonts.semibold, fonts.semibold],
            color: colours.gray
          },
          b5: {
            tag: 'b5',
            fontWeight: [fonts.bold, fonts.bold, fonts.bold],
            color: colours.black
          }
        }
      },
      a6: {
        id: 'a6',
        tag: 'p',
        fontSize: ['12px', '12px', '12px'],
        fontWeight: [fonts.normal, fonts.normal, fonts.normal],
        // lineHeight: ["32px", "36px", "56px"],
        fontFamily: [fonts.default, fonts.default, fonts.default],
        color: colours.gray,
        textAlign: 'left',
        paddingTop: ['4px', '4px', '4px'],
        paddingBottom: ['4px', '4px', '4px'],
        extended_tags: {
          h6: {
            tag: 'h6',
            fontWeight: [fonts.semibold, fonts.semibold, fonts.semibold],
            color: colours.gray
          },
          b6: {
            tag: 'b6',
            fontWeight: [fonts.bold, fonts.bold, fonts.bold],
            color: colours.black
          }
        }
      },
      l4: {
        id: 'l4',
        tag: 'a',
        fontSize: ['16px', '16px', '16px'],
        fontWeight: [fonts.bold, fonts.bold, fonts.bold],
        // lineHeight: ["32px", "36px", "56px"],
        fontFamily: [fonts.default, fonts.default, fonts.default],
        color: colours.gray,
        textAlign: 'left',
        paddingTop: ['4px', '4px', '4px'],
        paddingBottom: ['4px', '4px', '4px']
      },
      input: {
        id: 'input',
        tag: 'input',
        fontFamily: [fonts.default, fonts.default, fonts.default],
        textAlign: 'left',
        marginLeft: ['0px', '0px', '0px'],
        marginRight: ['1px', '1px', '1px'],
        paddingLeft: ['4px', '4px', '4px'],
        paddingRight: ['4px', '4px', '4px'],
        background: colours.white,
        fontSize: ['14px', '14px', '14px'],
        fontWeight: [fonts.normal, fonts.normal, fonts.normal],
        borderWidth: 0,
        borderColor: colours.orange,
        color: colours.black,
        borderStyle: 'solid',
        borderRadius: 0,
        height: 25,
        display: 'flex',
        flex: 1,
        outline: 'none'
      },
      textarea: {
        id: 'textarea',
        tag: 'textarea',
        fontFamily: [fonts.default, fonts.default, fonts.default],
        textAlign: 'left',
        marginLeft: ['0px', '0px', '0px'],
        marginRight: ['1px', '1px', '1px'],
        paddingLeft: ['4px', '4px', '4px'],
        paddingRight: ['4px', '4px', '4px'],
        background: colours.white,
        fontSize: ['14px', '14px', '14px'],
        fontWeight: [fonts.normal, fonts.normal, fonts.normal],
        borderWidth: 0,
        borderColor: colours.orange,
        color: colours.black,
        borderStyle: 'solid',
        borderRadius: 0,
        height: 25,
        display: 'flex',
        flex: 1,
        outline: 'none'
      },
      bullet4: {
        id: 'bullet4',
        display: 'inline-block',
        tag: 'div',
        fontFamily: [fonts.default, fonts.default, fonts.default],
        fontSize: ['16px', '16px', '16px'],
        fontWeight: [fonts.semibold, fonts.semibold, fonts.semibold],
        lineHeight: ['30px', '30px', '30px'],
        borderRadius: '50%',
        width: 60,
        height: ['30px', '30px', '30px'],
        minWidth: ['30px', '30px', '30px'],
        minHeight: ['30px', '30px', '30px'],
        // lineHeight: ["32px", "36px", "56px"],
        color: colours.gray,
        borderWidth: '1px',
        borderStyle: 'solid'


      },
      bullet5: {
        id: 'bullet5',
        tag: 'p',
        fontSize: ['14px', '14px', '14px'],
        fontWeight: [fonts.normal, fonts.normal, fonts.normal],
        // lineHeight: ["32px", "18px", "56px"],
        fontFamily: [fonts.default, fonts.default, fonts.default],
        color: colours.gray,

        paddingTop: ['4px', '4px', '4px'],
        paddingBottom: ['4px', '4px', '4px'],

        lineHeight: ['30px', '30px', '30px'],
        verticalAlign: 'middle',

        borderRadius: '50%',

        borderWidth: '1px',
        borderStyle: 'solid',
        width: '30px',
        height: '30px',
        marginRight: '10px',
        minWidth: '30px',
        minHeight: '30px',
        textAlign: 'center'
      }

    }

  }

};


export const getScreensizeFromWidth = (width) => {

    let screensize = 'small';
    if (width > default_theme.theme_details.breakpoints[2]) {
      screensize = 'very_large';
    }
    if (width > default_theme.theme_details.breakpoints[1]) {
      screensize = 'medium';
    }
    if (width > default_theme.theme_details.breakpoints[0]) {
      screensize = 'small';
    }

    return screensize;
};

export const getThemeFromScreensize = (screensize) => {
    let theme_layout = {};
    if (screensize === 'small') {
      theme_layout = {
        padding_main: padding.m[0],
        padding_side: padding.l[0],
        padding_title: padding.l[0]
      };
    }
    if (screensize === 'medium') {
      theme_layout = {
        padding_main: padding.m[1],
        padding_side: padding.l[1],
        padding_title: padding.l[0]
      };
    }
    if (screensize === 'large') {
      theme_layout = {
        padding_main: padding.m[2],
        padding_side: padding.l[2],
        padding_title: padding.l[0]
      };
    }
    if (screensize === 'very_large') {
      theme_layout = {
        padding_main: padding.m[3],
        padding_side: padding.l[3],
        padding_title: padding.l[0]
      };
    } else { // assume small
      theme_layout = {
        padding_main: padding.m[0],
        padding_side: padding.l[0],
        padding_title: padding.l[0]
      };
    }

    return theme_layout;
};


console.log('theme:', default_theme);

export default default_theme;

export const inputDropdownSize = default_theme.theme_details.textStyles.input.fontSize;
export const inputDropdownWeight = default_theme.theme_details.textStyles.input.fontSize;

// export const a1 = '20px';
// export const a2 = '18px';
// export const a3 = '14px';
// export const a4 = '12px';
// export const a5 = '10px';
// export const a6 = '9px';

// export const a1 = '35px';
// export const a2 = '18px';
// export const a3 = '15px';
// export const a4 = '14px';
// export const a5 = '12px';
// export const a6 = '11px';
