import React, { Component } from 'react';
import { B5 } from '../../styles/text';
//  default styles - edit in CSS to preserve hover:
import '../../styles/navigation.css';
import * as ROUTES from '../../constants/routes';

class RegisterProgBar extends Component {


  render () {
    const { currentRoute } = this.props;
    console.log('this.props', this.props);
    const showProgress = (currentRoute !== '/welcome') && (currentRoute !== '/availability') && (currentRoute !== '/thread') && (currentRoute !== '/profileSummary');

    const progressStep = !currentRoute ? 0
      : currentRoute === ROUTES.ABOUT_CHILDREN ? 1
      : currentRoute === ROUTES.EDIT_CHILD ? 1
      : currentRoute === ROUTES.WHERE_YOU_LIVE ? 2
      : currentRoute === ROUTES.TELL_US_ABOUT_YOURSELF ? 3
      : currentRoute === ROUTES.EDIT_ADULT ? 3
      : currentRoute === ROUTES.PROFILE_PHOTO ? 4
      : currentRoute === ROUTES.BIO ? 5
      : currentRoute === ROUTES.VERIFY_MAIN_ADULT ? 6
    : 0;


    return (

      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-end' }}>

        <div style={{ flex: 1, marginTop: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>

          <div style={{ marginRight: 16, marginBottom: 8 }}>
            <B5 style={{ borderWidth: 0, borderStyle: 'solid' }}>
              {showProgress && `Step ${progressStep} of 6`}
            </B5>
          </div>
        </div>


      </div>


    );
  }

}


export default RegisterProgBar;
