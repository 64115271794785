
export const contact = {

  page_title: 'Contact us',
  section1_para1: `If you have a question, please visit our FAQ first.  If you can't find the answer you're looking for, please use our contact form below to message us.  We aim to reply to you within 2 working days.`,
  subject_label: 'Subject',
  subject_placeholder: 'Choose from list',
  message_label: 'Message',
  // message_footer: `Maximum 500 characters`
  screenshots_label: 'Screenshots',
  screenshots_message: 'Upload 1 to 3 screenshots for us to have a better understanding of the issue',
  screenshots_button: 'Upload file',
  name_label: 'Name',
  email_label: 'Email',
  confirm_email_label: 'Confirm email',
  mandatory_fields: 'Mandatory fields',
  send_button: 'Send Message',
  sending: 'Sending',
  message_sent: 'Message Sent',

  subject_options: {
    general_enquiry: 'General Enquiry',
    booking: 'Booking',
    verification: 'Verification',
    membership: 'Membership',
    // payg: 'Pay as You Go',
    bug: 'Bug / Error',
    partnership: 'Partnership',
    job: 'Job / Careers',
    other: 'Other'
  },

  modal: {
    success: {
      title: 'Success',
      header: 'Message sent',
      para: 'Your message has been sent.',
      para2: `Thank you.`,
      ok: 'OK'
    }
  }


};

export const contact_beta = {

  page_title: 'Try our platform',
  section1_para1: `Register as a beta tester by entering your details below. We will reply to you with further details on how you can take part.`,
  subject_label: 'Subject',
  subject_placeholder: 'Choose from list',
  message_label: 'Message',
  // message_footer: `Maximum 500 characters`
  screenshots_label: 'Screenshots',
  screenshots_message: 'Upload 1 to 3 screenshots for us to have a better understanding of the issue',
  screenshots_button: 'Upload file',
  name_label: 'Name',
  email_label: 'Email',
  phone_label: 'Phone',
  postcode_label: 'Postcode',
  name_placeholder: 'Joe Bloggs',
  email_placeholder: 'j.bloggs@example.com',
  phone_placeholder: '07123 456 789',
  postcode_placeholder: 'SW1A',
  confirm_email_label: 'Confirm email',
  mandatory_fields: 'Mandatory fields',
  send_button: 'Send Message',
  sending: 'Sending',
  message_sent: 'Message Sent',

  subject_options: {
    beta: 'Become a beta tester'
  },

  modal: {
    success: {
      title: 'Success',
      header: 'Message sent',
      para: 'Your message has been sent.',
      para2: `Thank you.`,
      ok: 'OK'
    }
  }


};


export default contact;
