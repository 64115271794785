import history from '../../components/Navigation/history';
import { showModal, hideModal } from '../modal';
import { actionSuccess } from '../verify';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS } from '../../components/modals/modalTypes';
import * as ROUTES from '../../constants/routes';
import { edit_details } from '../../text/register';
import { CheckError, ReportError } from '../../utils/check-error';
import { verify_email } from '../../text';
import { SUCCESS_RESEND_VERIFY_EMAIL, SUCCESS_EDIT_DETAILS, SUCCESS_EDIT_BIO } from '../../components/modals/modalNames';
import { PROFILE } from '../../components/modals/modalCategory';


export const SUBMIT_REGISTRATION_REQUEST = 'SUBMIT_REGISTRATION_REQUEST';
export const SUBMIT_REGISTRATION_SUCCESS = 'SUBMIT_REGISTRATION_SUCCESS';
export const SUBMIT_REGISTRATION_FAILURE = 'SUBMIT_REGISTRATION_FAILURE';


export const submitRegistrationRequest = () => ({
  type: SUBMIT_REGISTRATION_REQUEST
});

export const submitRegistrationSuccess = data => ({
  type: SUBMIT_REGISTRATION_SUCCESS,
  data
});

export const submitRegistrationFailure = error => ({
  type: SUBMIT_REGISTRATION_FAILURE,
  error
});


export function submitRegistration (token, user_id, kids, adults, home_address, nextRoute, editMode) {

  console.log('submit reg');
  console.log('nextRoute:', nextRoute);
  const nextRouteToPush = !nextRoute ? ROUTES.PROFILE_SUMMARY : nextRoute;
  console.log('nextRouteToPush:', nextRouteToPush);

  if (!kids && !adults && !home_address) {
    history.push({
      pathname: nextRouteToPush,
      state: { editMode }
    });
  }


  return (dispatch) => {
    dispatch(showModal(MODAL_TYPE_SPINNER, {

    }));

    dispatch(submitRegistrationRequest());
    // console.log('submitted Action');

    // console.log('testUserSave', JSON.stringify({ user: { kids, adults, home_address } }));
    fetch(`${process.env.REACT_APP_API_URI}/users/registration/${user_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ user: { kids, adults, home_address } })
    })
    .then(CheckError)
    .then((data) => {

      console.log('registration response data:', data);
      dispatch(submitRegistrationSuccess(data));

      dispatch(hideModal());

      console.log('nextRouteToPush:', nextRouteToPush);

      history.push({
        pathname: nextRouteToPush,
        state: { editMode: editMode }
      });

      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.

    })
    .catch((err) => {
      dispatch(submitRegistrationFailure(err.message));
      dispatch(hideModal());

      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: edit_details.modal.failure.title,
          header: edit_details.modal.failure.header,
          message: edit_details.modal.failure.para,
          message2: edit_details.modal.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });
  };
}
