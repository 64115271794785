import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  List
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";


export const StyledHeader = styled(List.Item)`

  color: ${props => props.color ? props.color : colours.gray}!important;
  text-align: ${props => props.textAlign ? props.textAlign : 'left'}!important;
  font-size: ${props => props.screensize = 'small' ? '1em' : '4em'}!important;

`;

//   font-weight: ${fonts.semibold};
//   color: ${colours.darkpurple};
//   font-size: 18px;

const mapStateToProps = (layout, ownProps) => {

    const { color, textAlign, ...rest } = ownProps;

    return {
      screensize: layout.screensize,
      color: ownProps.color,
      textAlign: ownProps.textAlign,
      ...rest
    };

};


class Li extends Component {

  render () {

    const { screensize, color, textAlign } = this.props;
    // console.log('H1 props:', this.props);

    return (
      <StyledHeader
        as='li'
        content={this.props.children}
        // inverted
        screensize={screensize}

        color={color || null}
        textAlign={textAlign || null}

      />
    );

  }
}

export default connect(
    mapStateToProps
)(Li);
