import React, { Component } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './crop-image';
import colours from '../../../styles/colours';

class ImageCropper extends Component {

  constructor (props) {
    super(props);
    // console.log('constructor this props', this.props);
    this.state = {
      crop: { x: 0, y: 0 },
      zoom: 1.2,
      aspect: 1
    };

  }

  setCrop = (data) => {
     this.setState({ crop: data });
     console.log('setCrop:', data);
  }

  setZoom = (data) => {
     this.setState({ zoom: data });
  }

  /* onCropComplete() will occur each time the user modifies the cropped area,
  which isn't ideal. A better implementation would be getting the blob
  only when the user hits the submit button, but this works for now  */
  onCropComplete = async (_, croppedAreaPixels) => {
    console.log('videoref Aspect:', 640 / 480); // 1.3
    console.log('video Aspect:', 1404 / 885); // 1.6
    console.log('croppedAreaPixels:', croppedAreaPixels); // 1.3
    console.log('video Aspect:', 959 / 738); // 1.3
    console.log('intrinsic final image:', 468 / 265); // 1.76
    console.log('rendered image:', 299 / 189); // 1.6
     const { inputImg, getBlob } = this.props;
     const croppedImage = await getCroppedImg(
         inputImg,
         croppedAreaPixels
     );
     console.log('croppedImage:', croppedImage);
     getBlob(croppedImage);
  }

  render () {

    const { inputImg, cropShape, aspect } = this.props;
    const useAspect = aspect || this.state.aspect;
    console.log('aspect:', aspect);
    console.log('this.state.crop:', this.state.crop);

    return (
       /* need to have a parent with `position: relative`
    to prevent cropper taking up whole page */

      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: 'green'
      }}>


        {
          <Cropper
            image={inputImg}
            crop={this.state.crop}
            zoom={this.state.zoom}
            aspect={useAspect}
            cropShape={cropShape || 'round'}
            showGrid={false}
            style={{
              // containerStyle: {
              //
              //   // background: 'blue'
              // },
              cropAreaStyle: {
                borderColor: colours.white, borderWidth: cropShape === 'none' ? 0 : 3, borderStyle: 'solid'
              },
              mediaStyle: {
                background: 'red'
              }
            }}
            objectFit='horiztonal-cover'

            onCropChange={this.setCrop}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.setZoom}
          />
        }

      </div>

    );
  }


}

export default ImageCropper;
