/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import moment from 'moment';
// import '@trendmicro/react-buttons/dist/react-buttons.css';
// import '@trendmicro/react-dropdown/dist/react-dropdown.css';
// import 'react-dropdown/style.css';
// import 'react-select/dist/react-select.css';
import { Row, Column } from '../../styles/styles';
import { H5, H2, A5, FormLabelSmallText } from '../../styles/text';
import colours from '../../styles/colours';
import DropdownHourFromValueContainer from '../../containers/general/dropdown-hourFromValue';
import DropdownKidsValueContainer from '../../containers/general/dropdown-kidsValue';
import DropdownDateValueContainer from '../../containers/general/dropdown-dateValue';
import Card from '../general/card';
import { other, sit_details, search } from '../../text';
import getTimeAndPoints from '../../lib/getTimeAndPoints';


const MenuTabs = ({ window_width, saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, saved_selected_kids, thread_details }) => {
  console.log('saved_start_date:', saved_start_date);
  console.log('saved_end_date:', saved_end_date);

  const savedStartDate = !saved_start_date ? thread_details.start_date : saved_start_date;
  const savedEndDate = saved_end_date;
  console.log('savedStartDate:', savedStartDate);
  console.log('savedEndDate:', savedEndDate);

  const startDate = (
    !savedStartDate ? null
    : window_width < 400
      ? moment(savedStartDate, 'DD-MM-YYYY').format('DD MMM')
      : moment(savedStartDate, 'DD-MM-YYYY').format('DD MMM')
  );
  const endDate = (
    (!savedEndDate || savedEndDate === savedStartDate) ? ''
    : window_width < 400
      ? ` - ${moment(savedEndDate, 'DD-MM-YYYY').format('DD MMM')}`
      : ` - ${moment(savedEndDate, 'DD-MM-YYYY').format('DD MMM')}`
  );

  console.log('startDate:', startDate);
  console.log('endDate:', endDate);


  console.log('saved_kids:', saved_selected_kids);


  const startHr = saved_start_hr;
  const endHr = saved_end_hr;
  const startMin = saved_start_min;
  const endMin = saved_end_min;
  const savedKids = saved_selected_kids.map((kid) => {
      // console.log('typeof kid', typeof kid);
      let parsedKid = {};
      if (typeof kid !== 'object') {
        parsedKid = JSON.parse(kid);
      } else {
        parsedKid = kid;
      }

      // console.log('parsedKid.Name:', parsedKid.Name);
      return ` ${parsedKid.Name}`;
    });
  // console.log('savedKids:', savedKids);
  // console.log('value1:', (!savedKids || !savedKids.length) ? 'Children' : `${savedKids}`);


  return (
    <Row style={{ display: 'flex', flex: 1, alignItems: 'flex-end' }}>
      <Column style={{ flex: 1, borderWidth: 0, borderStyle: 'solid', borderColor: 'blue' }}>

        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', background: colours.verylightgray, paddingTop: 4, paddingLeft: 4, paddingRight: 4 }}>
          {
            // if window_width is wide, put Row to include Children after Date
            // window_width >= 700
            // && <Row style={{ display: 'flex', alignItems: 'center', paddingTop: 4, paddingBottom: 8, paddingLeft: 2, paddingRight: 2 }}>
            //
            //   { // select date / time - wide screen only
            //   }
            //   <div style={{ display: 'flex', flex: 1, paddingLeft: 2, paddingRight: 2, flexDirection: 'column' }}>
            //     <FormLabelSmallText>{search.date_time_edit}</FormLabelSmallText>
            //     <DropdownDateValue
            //       window_width={window_width}
            //       value={saved_start_date ? `${startDate}${endDate}` : search.date_time_placeholder}
            //       dataType='menu'
            //       options={[{ value: 1 }]}
            //     />
            //   </div>
            //
            //   { // select kids - wide screen only
            //   }
            //   <div style={{ display: 'flex', flex: 1, paddingLeft: 2, paddingRight: 2, flexDirection: 'column' }}>
            //     <FormLabelSmallText>{search.for}</FormLabelSmallText>
            //     <Row style={{ alignItems: 'center' }}>
            //       <DropdownKidsValueContainer
            //         editMode
            //         value={(!savedKids || !savedKids.length) ? search.kids_placeholder : `${savedKids}`}
            //         dataType='menu'
            //       />
            //     </Row>
            //   </div>
            //
            // </Row>
          }

          { // select date / time - narrow screen only
            // window_width < 700
            // &&
          }

          <Row style={{ flex: null, display: 'flex', alignItems: 'flex-start', paddingBottom: 8, paddingLeft: 2, paddingRight: 2 }}>

            { // select date / time - wide screen only
            }
            <div style={{ display: 'flex', flex: 1, paddingLeft: 2, paddingRight: 4, flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0, paddingBottom: 4 }}>
                <FormLabelSmallText>{search.date_time_edit}</FormLabelSmallText>
              </div>
              <DropdownDateValueContainer
                window_width={window_width}
                value={saved_start_date ? `${startDate}${endDate}` : search.date_time_placeholder}
                dataType='menu'
                options={[{ value: 1 }]}
              />

            </div>

            {
              // select kids - narrow screen only
            }
            <div style={{ display: 'flex', flex: 1, paddingLeft: 4, paddingRight: 2, flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0, paddingBottom: 4 }}>
                <FormLabelSmallText>{search.for_children_short}</FormLabelSmallText>
              </div>
              <Row style={{ alignItems: 'center' }}>
                <DropdownKidsValueContainer
                  editMode
                  value={(!savedKids || !savedKids.length) ? search.for_children_placeholder : `${savedKids}`}
                  dataType='menu'
                />
              </Row>

            </div>
          </Row>


          { // dropoff pickup times
          }
          <Row style={{ flex: null, display: 'flex', alignItems: 'center', paddingBottom: 8, paddingLeft: 2, paddingRight: 2 }}>

            <div style={{ display: 'flex', flex: 1, paddingLeft: 2, paddingRight: 4, flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0, paddingBottom: 4 }}>
                <FormLabelSmallText>{search.dropoff}</FormLabelSmallText>
              </div>
              <Row style={{ alignItems: 'center' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', paddingRight: 2 }}>
                  <DropdownHourFromValueContainer
                    editMode
                    value={saved_start_hr ? `${startHr}` : 'Hr'}
                    dataType='start_hr'
                  />
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', paddingLeft: 2 }}>
                  <DropdownHourFromValueContainer
                    editMode
                    value={saved_start_min ? `${startMin}` : 'Min'}
                    dataType='start_min'
                  />
                </div>
              </Row>
            </div>
            <div style={{ display: 'flex', flex: 1, paddingLeft: 4, paddingRight: 2, flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0, paddingBottom: 4 }}>
                <FormLabelSmallText>{search.pickup}</FormLabelSmallText>
              </div>
              <Row style={{ alignItems: 'center' }}>
                <div style={{ flex: 1.1, display: 'flex', justifyContent: 'center', paddingRight: 2 }}>
                  <DropdownHourFromValueContainer
                    editMode
                    value={saved_end_hr ? `${endHr}` : 'Hr'}
                    dataType='end_hr'
                  />
                </div>
                <div style={{ flex: 1.1, display: 'flex', justifyContent: 'center', paddingLeft: 2 }}>
                  <DropdownHourFromValueContainer
                    editMode
                    value={saved_end_min ? `${endMin}` : 'Min'}
                    dataType='end_min'
                  />
                </div>
              </Row>
            </div>

          </Row>

          { // select kids - narrow screen only
            // window_width < 700
            // && <Row style={{ display: 'flex', alignItems: 'center', paddingBottom: 8, paddingLeft: 2, paddingRight: 2 }}>
            //   <div style={{ display: 'flex', flex: 1, paddingLeft: 2, paddingRight: 2, flexDirection: 'column' }}>
            //     <FormLabelSmallText>{search.for}</FormLabelSmallText>
            //     <Row style={{ alignItems: 'center' }}>
            //       <DropdownKidsValueContainer
            //         editMode
            //         value={(!savedKids || !savedKids.length) ? search.kids_placeholder : `${savedKids}`}
            //         dataType='menu'
            //       />
            //     </Row>
            //   </div>
            // </Row>
          }

        </div>

      </Column>

    </Row>
  );

};

export default class EditSearch extends Component {


  componentDidMount () {
    const { saved_start_date, setAllSelectedKids, saveEditSelectedKids, saveEditStartDate, saveEditEndDate, saveEditStartHour, saveEditEndHour, saveEditStartMinute, saveEditEndMinute, thread_details, preliminaryThreadDetails, saved_search_details } = this.props;

    console.log('EditSearch Will Mount props:', this.props);


    const startDate = !thread_details.start_time ? preliminaryThreadDetails.start_date : thread_details.start_date;
    const endDate = !thread_details.start_time ? preliminaryThreadDetails.end_date : thread_details.end_date;
    const startHr = !thread_details.start_time ? preliminaryThreadDetails.start_time.slice(0, 2) : thread_details.start_time.slice(0, 2);
    const endHr = !thread_details.start_time ? preliminaryThreadDetails.end_time.slice(0, 2) : thread_details.end_time.slice(0, 2);
    const startMin = !thread_details.start_time ? preliminaryThreadDetails.start_time.slice(-2) : thread_details.start_time.slice(-2);
    const endMin = !thread_details.start_time ? preliminaryThreadDetails.end_time.slice(-2) : thread_details.end_time.slice(-2);
    const selectedKids = !thread_details.start_time ? Object.values(preliminaryThreadDetails.kids) : Object.values(thread_details.kids); // ensures its an array

    // need to add kids to dropdown of requester, not user (for editMode).

    // is Edit State Null?
    const editIsNull = saved_start_date === null;


    if (editIsNull) {
      saveEditStartDate(startDate);
      saveEditEndDate(endDate);
      saveEditStartHour(startHr);
      saveEditEndHour(endHr);
      saveEditStartMinute(startMin);
      saveEditEndMinute(endMin);
      saveEditSelectedKids(selectedKids);
      setAllSelectedKids(selectedKids);
    }


  }


  render () {


    const { user_id, noTitle, finaliseMode, editMode, tariff_details, window_width, saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, saved_selected_kids, thread_details } = this.props;
    // console.log('options', options);
    console.log('saved_selected_kids:', saved_selected_kids);
    // const { search_parameters } = this.props;
    // const { saved_selected_kids } = search_parameters;
    const searchParameters = (!saved_start_date || !saved_start_hr || !saved_start_min || !saved_end_hr || !saved_end_min) ? null : { saved_start_date, saved_end_date, saved_start_hr, saved_start_min, saved_end_hr, saved_end_min };
    const searchDates = (!saved_start_date) ? null : { saved_start_date, saved_end_date };
    const searchTimes = (!saved_start_hr || !saved_start_min || !saved_end_hr || !saved_end_min) ? null : { saved_start_hr, saved_start_min, saved_end_hr, saved_end_min };

    const showQuote = (!searchParameters || !tariff_details || (!saved_selected_kids || (saved_selected_kids.length === 0))) ? false : true;

    const spQuote = getTimeAndPoints(tariff_details, saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, saved_selected_kids);


    const selectDatesMessage = 'Choose date(s)';
    const selectTimesMessage = 'Choose dropoff and pickup times';
    const selectKidsMessage = 'Please select kid(s)';
    const clickSearchMessage = 'Click search to find nearby sitters';
    const changeDetailsMessage = 'Amend details above if desired';
    const clickOKMessage = 'Click Update below to change the booking';
    const clickUpdateMessage = 'Enter the actual time(s) sat and Click Confirm';
    const clickFinaliseMessage = 'Enter the actual time(s) and click to finalise';

    console.log('finaliseMode:', finaliseMode);
    console.log('editMode:', editMode);

    const getHelpMessage = () => {

      if (finaliseMode && editMode) {
        return clickUpdateMessage;
      }
      if (finaliseMode) {
        return clickFinaliseMessage;
      }
      if (!searchDates) {
        return selectDatesMessage;
      }
      if (!searchTimes) {
        return selectTimesMessage;
      }
      if (!saved_selected_kids || (saved_selected_kids.length === 0)) {
        return selectKidsMessage;
      }
      if (editMode) {
        // if pristine
        const useEditEndDate = !saved_end_date ? saved_start_date : saved_end_date;
        const start_time = `${saved_start_hr}:${saved_start_min}`;
        const end_time = `${saved_end_hr}:${saved_end_min}`;
        const editParameters = (!saved_start_date || !useEditEndDate || !saved_start_hr || !saved_start_min || !saved_end_hr || !saved_end_min || !saved_selected_kids) ? null : { start_date: saved_start_date, end_date: useEditEndDate, start_time, end_time, kids: saved_selected_kids };

        const threadParameters = (!thread_details.start_date || !thread_details.end_date || !thread_details.start_time || !thread_details.end_time || !thread_details.kids) ? null : { start_date: thread_details.start_date, end_date: thread_details.end_date, start_time: thread_details.start_time, end_time: thread_details.end_time, kids: thread_details.kids };

        const areEditParamsSame = JSON.stringify(threadParameters) === JSON.stringify(editParameters);
        if (areEditParamsSame) {
          return changeDetailsMessage;
        }
        // else
        //

        return clickOKMessage;
      }
      return clickSearchMessage;
    };

    const sitTimeLabel =
      (finaliseMode && editMode)
        ? (user_id === thread_details.sitter_id)
          ? 'You hosted for'
          : 'You used a sitter for'
        :
          (finaliseMode)
            ? 'unknown'
            : 'You are requesting a sitter for';

    const pointsUsedLabel =
      (finaliseMode && editMode)
        ? (user_id === thread_details.sitter_id)
          ? 'This will earn you '
          : 'This used '
        : (finaliseMode)
          ? 'unknown'
          : 'This will use ';

    const helpMessage = getHelpMessage();

    return (


      <div
        style={{
          width: '100%',

          display: 'flex',
          borderColor: colours.darkpurple, borderStyle: 'solid', borderWidth: 0,
          backgroundColor: colours.verylightgray,
          // borderBottomWidth: 0,
          // borderBottomColor: colours.lightgray,
          justifyContent: 'center'

        }}
      >

        <Card
          borderColor={colours.borderColour}
          borderWidth={1}
          shadow
          noBottomMargin
          // closeButton={
          //   <CloseButton
          //     // onClick={hideSearchBanner}
          //   />
          // }
        >
          {
            !noTitle
            && <div style={{
              minHeight: 30,
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%',
              paddingTop: 4,
              paddingBottom: 4
            }}>
              <H2
                color={colours.darkpurple}
                textAlign='center'
              >
                {finaliseMode && editMode ? sit_details.section_amend_title : sit_details.section_edit_title}
              </H2>
            </div>
          }

          <div
            style={{ marginRight: 'auto', marginLeft: 'auto', width: '100%', // maxWidth: 400, // limit search banner width to 650
              display: 'flex', flexDirection: 'column',
              borderWidth: 0, backgroundColor: colours.white, borderColor: colours.red, borderStyle: 'solid'
            }}
          >
            <MenuTabs
              window_width={window_width}
              // hideSearchBanner={hideSearchBanner}
              thread_details={thread_details}
              saved_start_date={saved_start_date}
              saved_end_date={saved_end_date}
              saved_start_hr={saved_start_hr}
              saved_end_hr={saved_end_hr}
              saved_start_min={saved_start_min}
              saved_end_min={saved_end_min}
              saved_selected_kids={saved_selected_kids}
            />

            <div style={{ background: colours.white, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderWidth: 0, borderTopWidth: 1, borderStyle: 'solid', borderColor: colours.lightgray }}>
              {
                showQuote &&
                <><A5>{sitTimeLabel}&nbsp;</A5><A5 color={colours.darkpurple }>{`${spQuote.timeQuote}`}&nbsp;</A5></>
              }
            </div>

            <div style={{ background: colours.white, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderWidth: 0, borderTopWidth: 0, borderStyle: 'solid', borderColor: colours.lightgray }}>
              {
                showQuote && <><A5>{pointsUsedLabel}</A5><A5 color={colours.darkpurple }>{`${spQuote.spQuote}`}&nbsp;{other.sitter_points}</A5></>
              }
            </div>


            <div style={{ background: colours.white, padding: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', borderWidth: 0, borderTopWidth: 1, borderStyle: 'solid', borderColor: colours.lightgray }}>

              <H5 color={colours.red}><i>{`${helpMessage}`}</i></H5>

            </div>

          </div>

        </Card>


      </div>


    );
  }
}
