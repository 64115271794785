import React from 'react';
import { PROFILE_COMPLETE, MAX_SIGNUP_BONUS, REFERRAL_SIGNUP, REFERRAL_FIRSTSIT, REFERRALS_SIGNUP_LIMIT } from '../constants/bonuses';

export const points = {
  page_title: 'What are SittingPoints?',
  page_sub_title_line1: '',
  page_sub_title_line2: '',

  section1: {
    para1: <div>SittingPoints were created to <b>reward hosts for their time</b>, while avoiding the need for reciprocal exchanges or costly fees.</div>,
    para2: <div>You will earn SittingPoints when hosting a playdate and <b>use those points as a guest when you need a sitter</b> for your children.</div>,

    para3: `Points are earned depending on the number of children and the duration of the sit:`,

    graphic_heading: `per Hour`,
    graphic_footer: `(sits can be booked in slots of 15 minutes)`

    // para4: `To make it easy, we valued 100 SittingPoints (SP) to be equivalent to 1 hour per child and, for each additional sibling on the same sit, an additional 60 points per hour.`,
    // para5: `For example, if the sit lasts for 2 hours for 2 children, then 320 SittingPoints would be earned, calculated as (2 hours @ 100 SP) + (2 hours @ 60 SP) = 320 SP.`
    // para6: `If you don’t have enough points for a sit, you can purchase some points (up to 50% of the points you already have for £1 per extra 10 SittingPoints.`,
    // para7: `If we take the example above, the sit last 1h45 and you have 2 children, and only have 240 SP, you will need to purchase an extra 40 SP, equivalent to £4.`
  },

  section2: {
    header: `Free points to get you started!`,
    intro: <div>You can <b>start using SitterPoint without having to sit first</b></div>,
    sub_bullet1: `earn up to ${PROFILE_COMPLETE} SP`,
    para1: <div>when you <b>complete your profile and get verified</b>.</div>,
    sub_bullet2: `earn up to ${MAX_SIGNUP_BONUS} SP`,
    para2: <div>when you <b>refer your friends</b>{` (${REFERRAL_SIGNUP} SP per friend who signs up, plus another ${REFERRAL_FIRSTSIT} SP when they complete their first sit, up to a maximum of ${REFERRALS_SIGNUP_LIMIT} friends).`}</div>
    // sub_bullet2: `up to X SP`,
    // para2: `when you have been a subscriber for X months`
    // sub_bullet3: `up to X SP`,
    // para3: `when you add X hours of availability to your calendar (X SP per friend, up to a max of 15)`

  },

  bonuses: {
    completed_profile: 'Completed profile bonus',
    user_verified: 'Verification bonus',
    user_dbs_part: `DBS bonus - 1 Adult`,
    user_dbs_full: `DBS bonus - Full`,
    user_dbs_upgrade: `DBS bonus - Full`,
    referral_signup: `Referral - Sign up bonus`

  }

};

export default points;
