import React from 'react';
import { L4 } from '../styles/text';
import { verification_doc_points, profile_completed_points, total_verification_points } from '../rules/points';

const dbs_info_link = 'https://dbscheckonline.org.uk/individuals';
const dbs_fees_link = 'https://www.gov.uk/government/news/fee-changes-for-dbs-checks';


export const data_deletion = {
  page_title: 'Deleting your data',
  // intro: 'Here are the most frequently asked questions and useful information you should know about SitterPoint:',
  end1: 'If you can’t find the answer to your question, please contact us at ',
  end2: 'or by using our request form',
  items: [


{ link_url: ``, link_text: ``, text_type: "section_title", text: `How can I delete my data from Sitterpoint?` },
{ link_url: `www.sitterpoint.co.uk/contact`, link_text: `www.sitterpoint.co.uk/contact`, text_type: "para", text: `If you would like us to delete your user data from Sitterpoint, please contact us using this link:` }

  ]
};


export default data_deletion;
