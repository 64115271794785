import { connect } from 'react-redux';
import UpcomingSummary from '../../components/calendar/upcoming-summary';
import { getMessageThread } from '../../actions/messages';
import * as ROUTES from '../../constants/routes';


const mapStateToProps = ({ user, nav, calendar, inbox }, ownProps) => {


  return {
    // serverError: user.error,
    // isConnected: network.isConnected,
    user_id: user.user_id,
    widget_type: ownProps.type,
    dropdown_nav_is_open: nav.dropdown_nav_is_open,
    available_dates: calendar.available_dates,
    booked_dates: calendar.booked_dates,
    allThreads: Array.isArray(inbox.all_threads) ? inbox.all_threads : []

  };

};

const mapDispatchToProps = dispatch => ({

  goToThread: (thread_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      // load message thread into redux state
      dispatch(getMessageThread(token, thread_id, ROUTES.THREAD));
      // push thread route

    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingSummary);
