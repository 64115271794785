import React from 'react';
import PropTypes from 'prop-types';
import { SetGA, ReportGA } from '../../utils/ga';
import { connect } from 'react-redux';
import {
    Modal,
    Button
} from "semantic-ui-react";
import { hideModal } from '../../actions/modal';
import Paragraph from '../../components/general/paragraph';
import ModalHeader from '../../components/general/modal-header';
import ModalSuccessIndicator from '../general/modal-success-indicator';
// import Modal from '../../components/modals/modal';
import { A5 } from '../../styles/text';
import colours from '../../styles/colours';

const SuccessModal = ({
  // dispatch,
  // title
  window_width,
  showClose,
  afterClose,
  hideModal, // connected with dispatchToProps
  // payment_made,
  // payment_type,
  // other_user_name,
  thread_stage,
  payment_type,
  user_type,
  // points_adjustment,
  modalCategory,
  modalName,
  modalText,
  cancelAction,
  backAction,
  okAction,
  hideIndicator,
  // sit_date
}) => {

  // console.log('payment_type:', payment_type);

  // const modalText =
  //   // thread_stage === 'request' ? sit_details.modal.request.success :
  //   thread_stage === 'pre_approve' ? sit_details.modal.pre_approve.success :
  //   thread_stage === 'pre_authorise' ? sit_details.modal.pre_authorise.success :
  //   thread_stage === 'finalise' ? sit_details.modal.finalise.success :
  //   thread_stage === 'review' ? reviews.modal.success :
  //   thread_stage === 'edited_details' ? sit_details.modal.send_edited.success :
  //   '';

  const { title, header, para, para2, cancel, ok } = modalText;


  const onClose = () => {
    console.log('onClose');
    hideModal();

    if (afterClose) {
      afterClose();
    }
  };
  console.log('para2: ', para2);

  const onOK = () => {
    okAction();
    hideModal();

  };

  const onCancel = () => {
    cancelAction();
    hideModal();

  };

  ReportGA({
    category: modalCategory,
    action: `Modal shown to user ${modalName}, thread_stage: ${thread_stage}`
    // meta: { window_width, afterClose, hideModal, cancelAction, backAction, okAction, modalText, modalCategory, modalName, clashing_bookings, cameraAction, docStatus, adult_id, adult_name, doc_name, thread_stage, payment_type, user_type }
  });


  return (

    <Modal
      closeIcon={showClose && "close"}
      open={true}
      onClose={showClose && onClose}
      style={{ maxWidth: 500 }}
    >
      { // <Modal.Header>{title}</Modal.Header>
      }
      <Modal.Content>

        <ModalHeader>{header}</ModalHeader>

        {
          !hideIndicator &&
          <div style={{ paddingBottom: 8, display: 'flex', background: 'transparent', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

            <ModalSuccessIndicator

            />

          </div>
        }


        <div style={{ flexDirection: 'column', paddingBottom: 16, display: 'flex', background: 'transparent', justifyContent: 'center', alignItems: 'center' }}>

          <div style={{ marginBottom: 24, maxWidth: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <Paragraph>

              <A5 textAlign='center'>{`${para}`}</A5>

            </Paragraph>

            {(para2) &&
              <Paragraph>

                <A5 textAlign='center'>{para2}</A5>

              </Paragraph>


            }


          </div>

          {
            (cancelAction || okAction) &&
            <div style={{ display: 'flex', maxWidth: 200, width: '100%', flexDirection: 'row', justifyContent: 'space-around' }}>
              {
                cancelAction &&
                <Button
                  // secondary

                  // style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
                  onClick={() => onCancel()}
                >
                  {cancel}
                </Button>
              }
              {
                okAction &&

                  <Button
                    primary

                    style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
                    onClick={() => onOK()}
                  >

                    {ok}

                  </Button>
              }
            </div>
          }
        </div>

      </Modal.Content>

    </Modal>

  );
};

SuccessModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default connect(
  (state, ownProps) => ({
    afterClose: ownProps.afterClose,
    payment_made: ownProps.payment_made,
    payment_type: ownProps.payment_type
  }),
  { hideModal }
)(SuccessModal);
