import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Modal
} from "semantic-ui-react";
import { hideModal } from '../../actions/modal';
import ModalHeader from '../../components/general/modal-header';
import { login } from '../../text';
import LoginContainer from '../auth/login';


const LoginModal = ({
  // title,
  afterClose,
  hideModal
  }) => {

  const title = login.title;

  const onClose = () => {

    hideModal();

    if (afterClose) {
      afterClose();
    }
  };

  return (


    <Modal
      closeIcon='close'
      open={true}
      onClose={onClose}
      style={{ maxWidth: 500 }}
      // dimmer='inverted'
      // size='tiny'
      centered={false} // puts at top of page
    >

      <Modal.Content>

        <ModalHeader>{title}</ModalHeader>

        <LoginContainer />

      </Modal.Content>

    </Modal>
  );
};

LoginModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default connect(null, { hideModal })(LoginModal);
