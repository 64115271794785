// import initSocket from '../socket-router';
import { getScreensizeFromWidth, getThemeFromScreensize } from '../styles/default-theme';


export const SET_CHECKBOX_WIDTH = 'SET_CHECKBOX_WIDTH';
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';
export const SET_WINDOW_HEIGHT = 'SET_WINDOW_HEIGHT';
export const SET_SHOW_NAVBAR = 'SET_SHOW_NAVBAR';
export const SET_CAMERA_ASPECT_RATIO = 'SET_CAMERA_ASPECT_RATIO';

export const RESET_ACTION_BAR = 'RESET_ACTION_BAR';
export const SHOW_CANCEL_ACTION_BAR = 'SHOW_CANCEL_ACTION_BAR';
export const SHOW_MESSAGE_ACTION_BAR = 'SHOW_MESSAGE_ACTION_BAR';
export const SHOW_APPROVE_ACTION_BAR = 'SHOW_APPROVE_ACTION_BAR';

export const resetActionBar = () => ({
  type: RESET_ACTION_BAR
});

export const showCancelActionBar = () => ({
  type: SHOW_CANCEL_ACTION_BAR
});

export const showMessageActionBar = () => ({
  type: SHOW_MESSAGE_ACTION_BAR
});

export const showApproveActionBar = () => ({
  type: SHOW_APPROVE_ACTION_BAR
});


export function setCameraAspectRatio (data) {
  return {
    type: SET_CAMERA_ASPECT_RATIO,
    data
  };
}


export const setCheckboxWidth = data => ({
  type: SET_CHECKBOX_WIDTH,
  data
});

export function setWindowWidth (width) {

  const screensize = getScreensizeFromWidth(width);
  const layout_theme = getThemeFromScreensize(screensize);
  const data = { width, screensize, layout_theme };
  return {
    type: SET_WINDOW_WIDTH,
    data
  };
}

export function setWindowHeight (height) {

  const data = { height };
  return {
    type: SET_WINDOW_HEIGHT,
    data
  };
}

export function setShowNavbar (data) {

  return {
    type: SET_SHOW_NAVBAR,
    data
  };
}
