import { connect } from 'react-redux';
import DropdownHourFromValue from '../../components/layout/dropdowns/hourFromValue';
import { toggleDropdownHourFrom, toggleDropdownHourTo } from '../../actions/general/dropdown-hourValue';
// import { setDateValueOpen } from '../../actions/search';
import { setStartHour, setEndHour, saveStartHour, saveEndHour, saveStartMinute, saveEndMinute, saveEditStartHour, saveEditEndHour, saveEditStartMinute, saveEditEndMinute } from '../../actions/calendar';
import { getUserById } from '../../actions/auth/profile';

const mapStateToProps = ({ user, search }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  user_id: user.user_id,
  // open: dropdownHourValue.isHourFromOpen,
  searchBannerIsOpen: search.banner_is_open,
  // date_value_is_open: search.date_value_is_open,
  saved_start_hr: search.saved_start_hr,
  saved_end_hr: search.saved_end_hr,
  saved_start_min: search.saved_start_min,
  saved_end_min: search.saved_end_min,
  narrow: ownProps.narrow

});

const mapDispatchToProps = (dispatch, ownProps) => ({


  handleGetUser: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getUserById(token, user_id));

    }

  },
  toggleDropdownHourFrom: (open) => {
    console.log('toggleDropdown');
    dispatch(toggleDropdownHourFrom(open));


  },
  toggleDropdownHourTo: (open) => {
    console.log('toggleDropdown');
    dispatch(toggleDropdownHourTo(open));


  },
  handleSelect: () => {

    // const token = localStorage.sitterpoint_token;
    //
    // if (token) {
    //   dispatch(getUserById(token, user_id));
    //
    // }

  },
  setStartHour: (dateObject) => {
    console.log('setting Start Hour:', dateObject);

      dispatch(setStartHour(dateObject));

  },
  setEndHour: (dateObject) => {

      dispatch(setEndHour(dateObject));

  },
  saveStartHour: (dateObject) => {
      const { editMode } = ownProps;
      if (editMode) {
        dispatch(saveEditStartHour(dateObject));
      } else {
        dispatch(saveStartHour(dateObject));
      }


  },
  saveEndHour: (dateObject) => {

    const { editMode } = ownProps;
    if (editMode) {
      dispatch(saveEditEndHour(dateObject));
    } else {
      dispatch(saveEndHour(dateObject));
    }


  },
  saveStartMinute: (dateObject) => {

    const { editMode } = ownProps;
    if (editMode) {
      dispatch(saveEditStartMinute(dateObject));
    } else {
      dispatch(saveStartMinute(dateObject));
    }


  },
  saveEndMinute: (dateObject) => {

    const { editMode } = ownProps;
    if (editMode) {
      dispatch(saveEditEndMinute(dateObject));
    } else {
      dispatch(saveEndMinute(dateObject));
    }


  }

});

export default connect(mapStateToProps, mapDispatchToProps)(DropdownHourFromValue);
