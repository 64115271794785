import React, { Component } from 'react';
import moment from 'moment';
import { persistor } from '../../init-store';

import { ConfirmButton, MenuButton, MoreButton } from '../../styles/styles';
import { A5, B3, H2, H5, H6, H1 } from '../../styles/text';
import history from '../Navigation/history';
import colours from '../../styles/colours';
import imgSitting from '../../images/DB_CB_you_are_sitting2.svg';
import imgHaveSitter from '../../images/DB_CB_you_have_a_sitter2.svg';
import imgAreyouSitting from '../../images/DB_NC_are_you_sitting2.svg';
import imgHaveYouSitter from '../../images/DB_NC_have_you_a_sitter2.svg';
import { calendar, other, dashboard } from '../../text';
import * as ROUTES from '../../constants/routes';


const WidgetImage = ({ src }) => {

    return (

      <div style={{ padding: 4, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', backgroundColor: colours.backgroundGray }}>

        <img alt='icon' style={{ width: '100%', objectFit: 'contain' }} src={ src } />

      </div>
    );


};


class UpcomingWidget extends Component {

  constructor (props) {
    super(props);

    this.state = {

      available: [],
      am_booked: [],
      pending_am_booked: [],
      booked_sitter: [],
      pending_booked_sitter: [],
      need_sitter: [],
      am_offered: [],
      awaiting_finalise: [],
      need_finalise: [],
      show_more: false,
      show_items: false

    };

  }


  toggleExpand = (i, status, route) => {

    if (route === '') { // no route defined, expand/collapse

      const { menu_items } = this.state;

      const newState = menu_items.map((item, j) => {
        if (i === j) {
          return { ...item, is_open: !status };
        }
        // else
        return item;
      });

      this.setState({ menu_items: newState });

    } else {
      this.handleSelect(route);
    }
     // else if route then push route


  }

  // showMore = () => {
  //   const { show_more } = this.state;
  //   this.setState({ show_more: !show_more });
  // }

  showItems = () => {
    const { show_items } = this.state;
    this.setState({ show_items: !show_items });
  }

  handleInbox = () => {
    history.push({
      pathname: ROUTES.INBOX,
      state: { editMode: false }

    });
  }

  handleGoTo (widget_type) {
    const { handleSearch } = this.props;

    if (widget_type === 'booked_sitter') {
      handleSearch();
    }
    if (widget_type === 'pending_booked_sitter') {
      handleSearch();
    }
    if (widget_type === 'am_booked') {
      this.handleInbox();
    }
    if (widget_type === 'pending_am_booked') {
      this.handleInbox();
    }

  }

  renderButton = (widget_type) => {
    // search or go to inbox

      // search
      return (
        <div style={{ width: 178, display: 'flex', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', justifyContent: 'center', alignItems: 'center', paddingTop: 0, paddingBottom: 0 }}>
          <ConfirmButton
            flex={1}
            onClick={ () => this.handleGoTo(widget_type) }
          >

            {
              widget_type === 'booked_sitter' ? dashboard.search :
              widget_type === 'pending_booked_sitter' ? dashboard.search :
              widget_type === 'am_booked' ? dashboard.view_messages :
              widget_type === 'pending_am_booked' ? dashboard.view_messages :
              ''
            }

          </ConfirmButton>
        </div>
      );

  }


  render () {
    const { widget_type, data, goToThread, window_width } = this.props;

    // console.log('this.state: ', this.state);

    let widgetIcon;
    let noItemsText = '';
    let widgetText = '';
    let subtitleColor = colours.black;
    let widgetItems = '';
    let widgetArray = [];

    if (widget_type === 'booked_sitter') {
      widgetIcon = imgHaveSitter;
      noItemsText = calendar.noItems_booked_sitter;
      widgetText = calendar.subtitle_booked_sitter;
      subtitleColor = colours.using;
      widgetArray = this.state.booked_sitter;
    }
    if (widget_type === 'pending_booked_sitter') {
      widgetIcon = imgHaveYouSitter;
      noItemsText = calendar.noItems_pending_booked_sitter;
      widgetText = calendar.subtitle_pending_booked_sitter;
      subtitleColor = colours.pending_booked_sitter;
      widgetArray = this.state.pending_booked_sitter;
    }
    if (widget_type === 'need_finalise') {
      widgetIcon = imgHaveYouSitter;
      noItemsText = calendar.noItems_need_finalise;
      widgetText = calendar.subtitle_need_finalise;
      subtitleColor = colours.needFinalise;
      widgetArray = this.state.need_finalise;
    }
    if (widget_type === 'am_booked') {
      widgetIcon = imgSitting;
      noItemsText = calendar.noItems_am_booked;
      widgetText = calendar.subtitle_am_booked;
      subtitleColor = colours.sitting;
      widgetArray = this.state.am_booked;
    }
    if (widget_type === 'pending_am_booked') {
      widgetIcon = imgAreyouSitting;
      noItemsText = calendar.noItems_pending_am_booked;
      widgetText = calendar.subtitle_pending_am_booked;
      subtitleColor = colours.pending_am_booked;
      widgetArray = this.state.pending_am_booked;
    }
    if (widget_type === 'awaiting_finalise') {
      widgetIcon = imgAreyouSitting;
      noItemsText = calendar.noItems_awaiting_finalise;
      widgetText = calendar.subtitle_awaiting_finalise;
      subtitleColor = colours.awaitingFinalise;
      widgetArray = this.state.awaiting_finalise;
    }
    if (widget_type === 'am_offered') {
      widgetIcon = imgAreyouSitting;
      noItemsText = calendar.noItems_am_offered;
      widgetText = calendar.subtitle_am_offered;
      subtitleColor = colours.offered;
      widgetArray = this.state.am_offered;
    }
    if (widget_type === 'need_sitter') {

      noItemsText = calendar.noItems_need_sitter;
      widgetText = calendar.subtitle_need_sitter;
      subtitleColor = colours.requested;
      widgetArray = this.state.need_sitter;
    }
    if (widget_type === 'available') {

      noItemsText = calendar.noItems_available;
      widgetText = calendar.subtitle_available;
      subtitleColor = colours.available;
      widgetArray = this.state.available;
      console.log('widgetArray:', widgetArray);
    }

    // console.log('widgetArray', widgetArray);

    // override subtitleColor for new theme to be black for all
    subtitleColor = colours.black;

    const boxSize = 48;
    const showItems = this.state.show_items;
    const showMore = this.state.show_more;
    const itemsToShow = showMore ? 40 : 5;

    // console.log('data', data);

    widgetItems = data.map((item, i) => {

        // console.log('start_date', item.start_date);
        // console.log('start_time', item.start_time);
        // console.log('end_time', item.end_time);
        const displayStartDate = moment(item.start_date, 'DD-MM-YYYY').format('dddd DD MMMM');
        const displayEndDate = moment(item.start_date, 'DD-MM-YYYY').format('dddd DD MMMM');
        const displayDate = `${displayStartDate}${displayStartDate !== displayEndDate ? ` - ${displayEndDate}` : ''}`;
        const displayStartTime = moment(item.start_time, 'hh:mm').format('h:mma');
        const displayEndTime = moment(item.end_time, 'hh:mm').format('h:mma');
        const displayTime = `${displayStartTime} - ${displayEndTime}`;
        const displayName = `(${item.name})`; // am_booked 'for Amy'

        return (
          <div key={`${displayDate}${item.time}`} style={{ width: '100%', display: 'flex', flexDirection: 'column', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', paddingTop: 1, paddingBottom: 1 }}>
            <H5
              textAlign='left'
              color={colours.black}
            >
              {`${displayDate}:`}
            </H5>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>
              <A5
                textAlign='left'
              >
                {`${displayTime} ${displayName}`}
              </A5>
              <MoreButton
                onClick={() => goToThread(item.thread_id)}
              >
                {other.view_details}
              </MoreButton>
            </div>
            <div style={{ height: 1, width: '100%', background: colours.borderColour, marginTop: 8, marginBottom: 8 }} />
          </div>

        );
    });


    // console.log('widgetItems:', widgetItems);
    if (widgetItems.length === 0 && noItemsText === '') {
      return <div />;
    }

    if (widgetItems.length === 0 && noItemsText !== '') {
      return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', paddingTop: 4, paddingBottom: 4 }}>
          <div style={{ background: colours.white, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

            <div style={{ width: boxSize, height: boxSize, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: subtitleColor, borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0 }}>
              {widgetIcon && <WidgetImage src={widgetIcon} />}
            </div>
            <div style={{ width: boxSize, height: boxSize, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: subtitleColor, borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0 }}>
              <B3 color={subtitleColor}>{widgetItems.length}</B3>
            </div>
            <div style={{ flex: 1, height: boxSize, padding: 4, paddingLeft: 12, paddingRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderColor: subtitleColor, borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0 }}>

              <H6
                color={colours.gray} // {subtitleColor}
                textAlign='left'
              >
                {noItemsText}
              </H6>
            </div>

            {
              window_width >= 600
              && <div style={{ height: boxSize, flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: subtitleColor, borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0 }}>
                { this.renderButton(widget_type) }
              </div>
            }

            <div style={{ width: boxSize, height: boxSize, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: subtitleColor, borderStyle: 'solid', borderWidth: 1 }} />

          </div>

        </div>
      );
    }

    return (

      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', paddingTop: 4, paddingBottom: 4 }}>
        <MenuButton
          style={{ background: colours.white, borderWidth: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          type='button'
          onClick={() => this.showItems()}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.showItems();
            }
          }}

        >
          <div style={{ width: boxSize, height: boxSize, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: subtitleColor, borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0 }}>
            {widgetIcon && <WidgetImage src={widgetIcon} />}
          </div>
          <div style={{ width: boxSize, height: boxSize, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: subtitleColor, borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0 }}>
            <H2

            >{widgetItems.length}</H2>
          </div>
          <div style={{ flex: 1, height: boxSize, padding: 4, paddingLeft: 12, paddingRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderColor: subtitleColor, borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0 }}>
            <H6
              textAlign='left'
              color={colours.gray} // {subtitleColor}
            >{widgetText}</H6>
          </div>

          {
            window_width >= 600
            && <div style={{ height: boxSize, flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: subtitleColor, borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0 }}>
              { this.renderButton(widget_type) }
            </div>
          }

          <div
            style={{ width: boxSize, height: boxSize, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: subtitleColor, borderStyle: 'solid', borderWidth: 1 }}
          >
            <H1 color={subtitleColor}>{showItems ? '-' : '+'}</H1>
          </div>
        </MenuButton>

        {
          showItems && <div style={{ background: colours.white, marginLeft: 1, marginRight: 1, padding: 12, display: 'flex', alignItems: 'flex-start', flexDirection: 'column', borderColor: subtitleColor, borderStyle: 'solid', borderWidth: 1 }}>

            { widgetItems }

            {
              // <MoreButton onClick={() => this.showMore()}>{this.state.show_more ? other.view_less : other.view_more}</MoreButton>
            }

          </div>
        }
      </div>


    );
  }
}


export default UpcomingWidget;
