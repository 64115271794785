
export const calendar = {
  page_title: 'My Calendar',
  right_title: 'Coming up:',
  subtitle_available: 'I am available to sit:',
  subtitle_am_booked: 'Booked: You are sitting',
  subtitle_pending_am_booked: 'Pending : You are sitting',
  subtitle_am_offered: 'I have offered to sit:',
  subtitle_booked_sitter: 'Booked: Sitting for you',
  subtitle_pending_booked_sitter: 'Pending : Sitting for you',
  subtitle_need_sitter: 'I need a sitter on:',
  subtitle_need_finalise: 'I need to finalise for:',
  subtitle_awaiting_finalise: 'I am waiting for sitter to finalise:',
  noItems_available: 'you have not entered any availability to sit',
  noItems_am_booked: 'Booked: You are sitting',
  noItems_pending_am_booked: 'Pending : You are sitting',
  noItems_awaiting_finalise: 'pending finalisation from sitter',
  noItems_am_offered: 'you have not offered to sit',
  noItems_booked_sitter: 'Booked: Sitting for you',
  noItems_pending_booked_sitter: 'Pending : Sitting for you',
  noItems_need_finalise: 'pending finalisation from you',
  noItems_need_sitter: '',
  clashing: 'Clash - please review',
  available: 'Available',
  sitting: 'Sitting',
  requested: 'Requested',


  intro1: 'Here is your calendar. Click on a square to toggle your availability.', // you will find all your upcoming bookings and availability.',
  // intro2: 'You can click on a square to toggle your availability.',
  key: {
    not_available: `You haven't saved any availability for that day`,
    not_available_search: 'Not available',
    clashing: 'You have overlapping sits/availability on that day',
    available: 'You have saved availability on that day',

    // need: 'I need a sitter',
    // booked_sitter: 'I have booked a sitter',
    // am_booked: 'I am booked to sit',

    booked_sitter: 'You booked a sitter',
    pending_sitter: 'Pending confirmation : You booked a sitter',
    booked_to_sit: 'You are hosting',
    pending_to_sit: 'Pending confirmation : You are hosting'

  },
  tods: {
    am: '06:00',
    pm: '12:00',
    eve: '18:00',
    on: '00:00',
    morning: 'Morning',
    afternoon: 'Afternoon',
    evening: 'Evening',
    overnight: 'Overnight'
  },


  edit_event: {
    title: 'Saturday 17 December ', // will be date
    header: '',
    para: 'I am available to sit that day from:',
    start: 'Start',
    end: 'End',
    every: 'Every',
    for_the_next: 'for the next',
    mandatory_fields: 'All fields are mandatory',
    save: 'Add and Save',
    your_events: 'Your availability and bookings',
    full_details: 'Full details',

    recurrance_options: {
      one_off: 'One off',
      weeks: 'weeks',
      months: 'months'
    },
    other_recurrances: 'Other recurrances',

    sit_booked: 'Sit booked',
    sit_pending: 'Sit pending',
    req_booked: 'Req booked',
    req_pending: 'Req pending',
    available: 'Available to sit'

  },

  modal: {
    add_availability: {
      fetching: 'saving',
      confirm: {
        title: 'Warning',
        header: 'You have clashes',
        para: 'Your selections clash with existing bookings',
        para2: 'Are you sure you wish to continue?',
        ok: 'OK',
        cancel: 'Cancel',
        clashing_bookings: 'Clashing bookings',
        note: 'Note: You can review clashes and edit existing availability later in the calendar'
      },
      success: {
        title: 'Success',
        header: 'Saved',
        para: 'Your availability has been saved',
        para2: '',
        ok: 'OK'
      },
      failure: {
        title: 'Error',
        header: 'Oops',
        para: 'Your availability could not be saved.  This could be due to a network error, please try again.',
        para2: 'Please contact us if this problem persists.'
      }

    },

    edit_all_availability: {
      confirm: {
        title: 'Edit availability',
        header: 'Please choose',
        para: 'Do you wish to edit all recurrances?',
        para2: 'Or just this one?',
        ok: 'All',
        cancel: 'Just this one'
      }

    },

    edit_availability: {
      fetching: 'saving',
      confirm: {
        title: 'Warning',
        header: 'You have clashes',
        para: 'Your selections clash with existing bookings',
        para2: 'Are you sure you wish to continue?',
        ok: 'OK',
        cancel: 'Cancel',
        clashing_bookings: 'Clashing bookings',
          note: 'Note: You can review clashes and edit existing availability later in the calendar'
      },
      success: {
        title: 'Success',
        header: 'Saved',
        para: 'Your availability has been saved',
        para2: '',
        ok: 'OK'
      },
      failure: {
        title: 'Error',
        header: 'Oops',
        para: 'Your availability could not be saved.  This could be due to a network error, please try again.',
        para2: 'Please contact us if this problem persists.'
      }

    },

    delete_all_availability: {
      confirm: {
        title: 'Delete availability',
        header: 'Please choose',
        para: 'Do you wish to delete all recurrances?',
        para2: 'Or just this one?',
        ok: 'All',
        cancel: 'Just this one'
      }

    },

    delete_availability: {
      fetching: 'deleting',
      success: {
        title: 'Success',
        header: 'Deleted',
        para: 'Your availability has been deleted',
        para2: '',
        ok: 'OK'
      },
      failure: {
        title: 'Error',
        header: 'Oops',
        para: 'Your availability could not be deleted.  This could be due to a network error, please try again.',
        para2: 'Please contact us if this problem persists.'
      }

    }
  }


};


export default calendar;
