// import { store } from '../../init-store';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';

export const GET_PLANS_REQUEST = 'GET_PLANS_REQUEST';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLANS_FAILURE = 'GET_PLANS_FAILURE';


export const getPlansRequest = () => ({
  type: GET_PLANS_REQUEST
});

export const getPlansSuccess = data => ({
  type: GET_PLANS_SUCCESS,
  data
});

export const getPlansFailure = error => ({
  type: GET_PLANS_FAILURE,
  error
});

export function getPlans (token) {
  return (dispatch) => {

    dispatch(getPlansRequest());
    console.log('getPlans action: ');

    fetch(`${process.env.REACT_APP_API_URI}/plans`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    })
    .then(CheckError)
    .then((data) => {
      console.log('getPlans response data:', data);

      dispatch(getPlansSuccess(data));

    })
    .catch((err) => {
      console.log('caught getPlans error: ', err);
      dispatch(getPlansFailure(err.message));
    });

  };
}
