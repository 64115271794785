import React from 'react';
// import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// import bugsnag from '@bugsnag/js';
// import bugsnagReact from '@bugsnag/plugin-react';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
// import App from './containers/app-container';
import Loading from './components/general/loading';
import ErrorScreen from './components/general/error-screen';
import ErrorBoundary from './Error-boundary';
import { store, persistor } from './init-store';
// import * as serviceWorker from './serviceWorker';
// import {  } from '../../styles/styles';

// require('dotenv').config();

// const bugsnagClient = bugsnag(`${process.env.REACT_APP_BUGSNAG_API_KEY}`);

// bugsnagClient.use(bugsnagReact, React);


const beforeSend = (report) => {
  // You can also provide a beforeSend callback to run just on errors caught by
  // the error boundary. Maybe you want to attach some of the current state from
  // whatever model/store you're using (e.g redux)
  console.log('about to send this report', { report });
};

const onBeforeLift = () => {
  // take some action before the gate lifts - PersistGate delays Render until store has rehydrated
};


export default (props) => {
  return (
    <Provider store={store}>

      <PersistGate
        loading={<Loading />}
        onBeforeLift={onBeforeLift}
        persistor={persistor}
      >

        <ErrorBoundary
          FallbackComponent={ErrorScreen}
          beforeSend={beforeSend}
        >

          {props.children}

        </ErrorBoundary>

      </PersistGate>

    </Provider>
  );
};
