import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReportGA } from '../../utils/ga';
import { connect } from 'react-redux';
import {
    Modal,
    Button,
    Icon
} from "semantic-ui-react";
import ModalHeader from '../../components/general/modal-header';
import ImageCropper from '../../components/general/imageUpload/image-cropper';
import { hideModal } from '../../actions/modal';
import { uploadDoc, submitIDDoc } from '../../actions/verify/index';
import { upload_photo } from '../../text/register';
import VerifyFileUploadButton from '../verify/verify-file-upload-button';
import { H5 } from '../../styles/text';
import colours from '../../styles/colours';
import { verify as verifyText } from '../../text';
import { setUploadProgress, setCurrentFile, setSelectedFiles, setBlob } from '../../actions/general/upload';
import { UPLOAD_FILE } from '../../components/modals/modalNames';
import { UPLOAD as UPLO } from '../../constants/ga-category';

// const actions = { hideModal };


class UploadFileModal extends Component {

  componentDidMount () {

    ReportGA({
      category: UPLO,
      action: `Modal shown to user ${UPLOAD_FILE}`
      // meta: { window_width, afterClose, hideModal, cancelAction, backAction, okAction, modalText, modalCategory, modalName, clashing_bookings, cameraAction, docStatus, adult_id, adult_name, doc_name, thread_stage, payment_type, user_type }
    });
  }

  handleSubmitDoc = () => {

    const { adult_id, adult_name, doc_name, setProgress, currentFile, setCurrentFile, setSelectedFiles } = this.props;

    // console.log('adult_id:', adult_id);
    // console.log('adult_name:', adult_name);
    // console.log('doc_name:', doc_name);
    // console.log('selectedFiles:', selectedFiles);

    const fileObject = currentFile; // blob; // ???

    setProgress(0); // sets Progress Indicator to zero for upload
    setCurrentFile(fileObject); // sets currentFile in State

    console.log('upload fileObject:', fileObject);

    if (doc_name === 'id') {
      // do not upload, but move onto selfie (will upload together)
      this.props.handleDocUpload(fileObject, (event) => {
        // don't set progess as no indicator required, or could leave in?
        setProgress(Math.round((100 * event.loaded) / event.total));
      }, adult_id, adult_name, doc_name);


      // do we need to set fileID selected somewhere before selfie gets loaded into SelectedFiles
      setSelectedFiles(undefined);
    } else {
      this.props.handleDocUpload(fileObject, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      }, adult_id, adult_name, doc_name);
      console.log('setSelectedFiles upload-file');
      setSelectedFiles(undefined);
    }


  };

  render () {
    const {
      // title
    isFetchingUpload, hideModal, afterClose, docStatus, adult_id, adult_name, doc_name, selectedFiles, currentFile } = this.props;


    // const title =
    // doc_name === 'photo' ? upload_photo.selfie.title
    // : doc_name === 'id_selfie' ? verifyText.modal.upload_selfie_id_selfie.title
    // : doc_name === 'id' ? verifyText.modal.upload_selfie_id.title
    // : doc_name === 'poa' ? verifyText.modal.upload_selfie_poa.title
    // : verifyText.modal.upload_selfie_dbs.title;

    const header =
    doc_name === 'photo' ? upload_photo.selfie.header
    : doc_name === 'id_selfie' ? verifyText.modal.upload_selfie_id_selfie.header
    : doc_name === 'id' ? verifyText.modal.upload_selfie_id.header
    : doc_name === 'poa' ? verifyText.modal.upload_selfie_poa.header
    : verifyText.modal.upload_selfie_dbs.header;

    // const para =
    // doc_name === 'photo' ? upload_photo.selfie.para
    // : doc_name === 'id_selfie' ? verifyText.modal.upload_selfie_id_selfie.para
    // : doc_name === 'id' ? verifyText.modal.upload_selfie_id.para
    // : doc_name === 'poa' ? verifyText.modal.upload_selfie_poa.para
    // : verifyText.modal.upload_selfie_dbs.para;

    const submit =
    doc_name === 'photo' ? upload_photo.selfie.submit
    : doc_name === 'id_selfie' ? verifyText.modal.upload_selfie_id_selfie.submit
    : doc_name === 'id' ? verifyText.modal.upload_selfie_id.submit
    : doc_name === 'poa' ? verifyText.modal.upload_selfie_poa.submit
    : verifyText.modal.upload_selfie_dbs.submit;

    // const take_another =
    // doc_name === 'photo' ? upload_photo.selfie.take_another
    // : doc_name === 'id_selfie' ? verifyText.modal.upload_selfie_id_selfie.take_another
    // : doc_name === 'id' ? verifyText.modal.upload_selfie_id.take_another
    // : doc_name === 'poa' ? verifyText.modal.upload_selfie_poa.take_another
    // : verifyText.modal.upload_selfie_dbs.take_another;


    const onClose = () => {
      hideModal();
      setSelectedFiles(undefined);

      console.log('onClose Clicked');

      if (afterClose) {
        afterClose();
      }
    };

    // const { window_width, window_height, isUploadingDoc, isFetchingSuccess, location, user_id, isSubmittingForm, selectedAdult, photoUrl, firstname, surname, email, family_description, kids, adults, homeAddress, memberSince, dropdown_nav_is_open, profile_progress, all_reviews, all_threads, setSelfieImage, selectedSelfieImage, handleUploadSelfie, handleTakeAnother } = this.props;

    console.log('currentFile:', currentFile);

    return (

      <Modal
        centered={false} // top align
        closeIcon="close"
        open={true}
        onClose={onClose}
        style={{ maxWidth: 500 }}

      >

        <Modal.Content>

          <ModalHeader>{header}</ModalHeader>


          <div style={{ flexDirection: 'column', paddingBottom: 16, display: 'flex', background: 'transparent', justifyContent: 'center', alignItems: 'center' }}>

            <div style={{ marginBottom: 24, maxWidth: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>


              {
                selectedFiles && currentFile && !isFetchingUpload
                && <H5>{currentFile.name}</H5>
              }
              {
                selectedFiles && !isFetchingUpload && (
                  <div style={{ display: 'flex', justifyContent: 'center', borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>
                    <div style={{
                      display: doc_name !== 'photo' ? 'none' : null, width: 250,
                      // height: 250,
                      position: 'relative', borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>
                      <ImageCropper
                        cropShape={(doc_name === 'photo' || doc_name === 'id_selfie') ? 'round' : 'square'}
                        aspect={(doc_name === 'photo' || doc_name === 'id_selfie') ? 1 : 1.3}
                        getBlob={setBlob}
                        inputImg={selectedFiles}
                      />
                    </div>
                  </div>
                )
              }

            </div>

            <div style={{ display: 'flex', maxWidth: 200, width: '100%', flexDirection: 'row', justifyContent: 'space-around' }}>


              {
                selectedFiles
                && <>

                  <VerifyFileUploadButton
                    // primary
                    isVerify={doc_name !== 'photo'}
                    style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
                    // onClick={fileAction}

                    docStatus={docStatus}
                    adult_id={adult_id}
                    adult_name={adult_name}
                    doc_name={doc_name}
                  >
                    {upload_photo.file.upload_another}
                  </VerifyFileUploadButton>


                  <Button
                    primary
                    icon
                    labelPosition='left'

                    style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}

                    onClick={() => this.handleSubmitDoc()}
                  >
                    <Icon name='upload cloud' />
                    {submit}
                  </Button>

                </>
              }


            </div>

          </div>


        </Modal.Content>
        {
          // selectedFiles
          // && <Modal.Actions>
          //
          //   {
          //     selectedFiles
          //     && <VerifyFileUploadButton
          //       // primary
          //       isVerify={doc_name !== 'photo'}
          //       style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
          //       // onClick={fileAction}
          //
          //       docStatus={docStatus}
          //       adult_id={adult_id}
          //       adult_name={adult_name}
          //       doc_name={doc_name}
          //     >
          //       {upload_photo.file.upload_another}
          //     </VerifyFileUploadButton>
          //   }
          //   {
          //     // selectedFiles
          //     // && <Button
          //     //   // secondary
          //     //   icon
          //     //   labelPosition='left'
          //     //
          //     //   onClick={() => handleTakeAnother()}
          //     // >
          //     //   <Icon name='camera' />
          //     //   {upload_photo.selfie.take_another}
          //     // </Button>
          //
          //   }
          //
          //   {
          //     selectedFiles
          //     && <Button
          //       primary
          //       icon
          //       labelPosition='left'
          //
          //       style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
          //
          //       onClick={() => this.handleSubmitDoc()}
          //     >
          //       <Icon name='upload cloud' />
          //       {submit}
          //     </Button>
          //   }
          //
          //   {
          //     // <Button
          //     //   // secondary
          //     //
          //     //   // style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
          //     //   onClick={cancelAction}
          //     // >
          //     //   {verifyText.modal.upload_selfie.cancel}
          //     // </Button>
          //   }
          //
          //
          // </Modal.Actions>
        }
      </Modal>

    );
  }
}


UploadFileModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

const mapStateToProps = ({ user, upload, layout }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  isFetchingUpload: user.isFetchingUpload,
  isFetchingSuccess: user.isFetchingSuccess,
  successText: ownProps.successText,
  fetchingText: ownProps.fetchingText,
  afterClose: ownProps.afterClose,
  hideModal: ownProps.hideModal,
  title: ownProps.title,
  message: ownProps.message,

  isUploadingDoc: user.isFetchingUpload,
  progress: upload.progress,
  currentFile: upload.currentFile,
  selectedFiles: upload.selectedFiles,
  blob: upload.blob,
  docStatus: ownProps.docStatus,
  doc_name: ownProps.doc_name,
  window_height: layout.window_height

});

const mapDispatchToProps = (dispatch, props) => ({
  // handleSubmitForm: ({ firstname, surname, email, password }, props) => {
  //
  //   dispatch(submitAboutChildren(firstname, surname, email.toLowerCase(), password, props.navigation));
  // },

  handleTakeAnother: () => {
    dispatch(setSelectedFiles(undefined));
  },

  hideModal: () => {
    dispatch(hideModal());
  },

  setSelectedFiles: (value) => {
    dispatch(setSelectedFiles(value));
    // if verify, don't hideModal, but show preview imageCropper
    if (!props.isVerify) {
      // dispatch(hideModal()); only hide Modal if profile photo upload from page
    }

  },
  setCurrentFile: (value) => {
    dispatch(setCurrentFile(value));
  },
  setProgress: (value) => {
    dispatch(setUploadProgress(value));
  },
  setBlob: (value) => {
    dispatch(setBlob(value));
  },


  handleDocUpload: (file, onUploadProgress, adult_id, adult_name, doc_name) => {

    const token = localStorage.sitterpoint_token;
      // is nextRoute used/in correct place?

      if (token) {
          console.log('upload-file handleDocUpload doc_name:', doc_name);
          if (doc_name === 'id') {
            // submit (save) doc to state, to upload later
            dispatch(submitIDDoc(token, file, onUploadProgress, adult_id, adult_name, doc_name));
          } else {
            // upload verify docs
            dispatch(uploadDoc(token, file, onUploadProgress, adult_id, adult_name, doc_name));
          }

      }

  }


});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileModal);
