import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";


export const StyledSendInput = styled.textarea`
  font-family: ${fonts.default};
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background: ${colours.white};
  font-size: ${props => props.screensize = 'small' ? '1em' : '4em'}!important;
  font-weight: ${props => props.fontWeight ? props.fontWeight : fonts.normal}!important;
  color: ${props => props.color ? props.color : colours.gray}!important;
  border-width: 0px;
  border-color: ${colours.orange};
  border-style: solid;
  border-radius: 0px;
  minHeight: 40px;
  width: 100%;
  resize: none;
  outline: none;

  ::selection {
    background: ${colours.black};
    color: ${colours.orange};
  }

`;


const mapStateToProps = (layout, ownProps) => {

    return {
      screensize: layout.screensize,
      color: ownProps.color,
      textAlign: ownProps.textAlign,
      maxLines: ownProps.maxLines,
      ...ownProps
    };

};


class SendInput extends Component {

  render () {

    const { screensize, color, textAlign, fontWeight, textOverflow, whiteSpace, overflow, maxLines } = this.props;
    console.log('SendInput props:', this.props);

    return (
      <StyledSendInput
        {...this.props}
        // screensize={screensize}
        // fontWeight={fontWeight || null}
        // textOverflow={textOverflow || null}
        // whiteSpace={whiteSpace || null}
        // overflow={overflow || null}
        // maxLines={maxLines || null}

        // color={color || null}
        // textAlign={textAlign || null}

      >
        {this.props.children}
      </StyledSendInput>
    );

  }
}

export default connect(
    mapStateToProps
)(SendInput);
