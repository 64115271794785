import update from 'immutability-helper';
import * as calendar from '../actions/calendar';
import * as search from '../actions/search';


export const initialState = {
  saved_start_date: null,
  saved_end_date: null,
  saved_start_hr: null,
  saved_end_hr: null,
  saved_start_min: null,
  saved_end_min: null,
  saved_selected_kids: []
};


export default function editSearchReducer (state = initialState, action) {
  switch (action.type) {

    case calendar.SAVE_EDIT_START_DATE:

      return {
        ...state,
        saved_start_date: action.dateObject
      };

    case calendar.SAVE_EDIT_END_DATE:

      return {
        ...state,
        saved_end_date: action.dateObject
      };


    case calendar.SAVE_EDIT_START_HOUR:

      return {
        ...state,
        saved_start_hr: action.dateObject
      };

    case calendar.SAVE_EDIT_END_HOUR:

      return {
        ...state,
        saved_end_hr: action.dateObject
      };


    case calendar.SAVE_EDIT_START_MINUTE:

      return {
        ...state,
        saved_start_min: action.dateObject
      };

    case calendar.SAVE_EDIT_END_MINUTE:

      return {
        ...state,
        saved_end_min: action.dateObject
      };


    case calendar.SAVE_EDIT_SELECTED_KIDS:

      return {
        ...state,
        saved_selected_kids: action.selected_kids
      };


    case search.CLEAR_EDIT_SEARCH_QUERY:

      return initialState;


    default:
      return state;
  }
}
