import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DynamicTextAreaComponent } from '../../dynamic-component';
import history from '../../../components/Navigation/history';

const mapStateToProps = ({ style }, ownProps) => {
    // console.log('style:', style);

    const getTagPropsToUse = (nav) => {
      let result = {};
      if (nav === '/typo') {
        result = style.editing_theme.theme_details.textStyles.textarea;
      } else if (!style.available_themes) {
        result = {};
      } else {
        result = style.available_themes.reduce((acc, item) => {
          if (item.selected) {
            return item.theme_details.textStyles.textarea;
          }
          return acc;
        }, []);
      }
      return result;

    };
    const nav = history.location.pathname;
    const tagPropsToUse = getTagPropsToUse(nav);

    return {
      tagProps: tagPropsToUse,
      ownProps
    };

};


class TextAreaTyp extends Component {

  render () {

    const { tagProps, ownProps } = this.props;

    return (
      <DynamicTextAreaComponent {...tagProps} {...ownProps}>
        {this.props.children}
      </DynamicTextAreaComponent>
    );

  }
}

export default connect(
    mapStateToProps
)(TextAreaTyp);
