import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";


export const StyledP = styled.p`

  font-weight: ${fonts.bold}!important;
  color: ${props => props.color ? props.color : `${colours.purple}!important`};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'}!important;
  font-size: ${props => props.screensize = 'small' ? '1em' : '4em'}!important;
  margin: ${'0px'}!important;
`;

const mapStateToProps = (layout, ownProps) => {

    return {
      screensize: layout.screensize,
      color: ownProps.color,
      textAlign: ownProps.textAlign,
      ...ownProps
    };

};


class B5 extends Component {

  render () {

    const { screensize, color, textAlign } = this.props;
    // console.log('B5 props:', this.props);

    return (
      <StyledP

        screensize={screensize}

        color={color || null}
        textAlign={textAlign || null}

      >
        {this.props.children}
      </StyledP>
    );

  }
}

export default connect(
    mapStateToProps
)(B5);
