import { connect } from 'react-redux';
import DropdownDateValue from '../../components/layout/dropdowns/dateValue';
import { setDateValueOpen } from '../../actions/search';
// import { toggleDropdown } from '../../actions/general/dropdown-dateValue';
import { getUserById } from '../../actions/auth/profile';

const mapStateToProps = ({ user, search }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  user_id: user.user_id,
  // open: dropdownDateValue.isOpen,
  window_width: ownProps.window_width,
  searchBannerIsOpen: search.banner_is_open,
  date_value_is_open: search.date_value_is_open,
  value: ownProps.value,
  dataType: ownProps.dataType,
  options: ownProps.options

});

const mapDispatchToProps = dispatch => ({


  handleGetUser: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      console.log('dropdown DateValue get User');
      dispatch(getUserById(token, user_id));

    }

  },
  setDateValueOpen: (value) => {
    console.log('setDateValueOpen:', value);
    dispatch(setDateValueOpen(value));
  },
  // toggleDropdown: (open) => {
  //   console.log('toggleDropdown');
  //   dispatch(toggleDropdown(open));
  //
  //
  // },
  handleSelect: () => {

    // const token = localStorage.sitterpoint_token;
    //
    // if (token) {
    //   dispatch(getUserById(token, user_id));
    //
    // }

  }

});

export default connect(mapStateToProps, mapDispatchToProps)(DropdownDateValue);
