import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Modal,
    Button,
    Header
} from "semantic-ui-react";
import { ReportGA } from '../../utils/ga';
import { hideModal } from '../../actions/modal';
import Paragraph from '../../components/general/paragraph';
import ModalHeader from '../../components/general/modal-header';
import UploadProgressIndicator from '../general/upload-progress-indicator';
import UploadSuccessIndicator from '../general/upload-success-indicator';
import ModalSuccessIndicator from '../general/modal-success-indicator';
// import Modal from '../../components/modals/modal';
import { H1, A5, H5, H3, ConfirmButtonText } from '../../styles/text';
import { ConfirmButton, Row } from '../../styles/styles';
import colours from '../../styles/colours';
import { plan, modal } from '../../text';
import Spinner from '../../components/general/spinner';
import { UPLOAD_PROGRESS } from '../../components/modals/modalNames';
import { UPLOAD } from '../../components/modals/modalCategory';
import { UPLOAD as UPLO } from '../../constants/ga-category';


const actions = {hideModal};

const UploadProgressModal = ({

  afterClose, showClose, hideModal, title, message, successText, fetchingText, isFetchingSuccess }) => {

  const modalTitle = title || modal.spinner.title;
  const modalMessage = message || modal.spinner.message;

  const onClose = () => {
    hideModal();

    if (afterClose) {
      afterClose();
    }
  };

  ReportGA({
    category: UPLO,
    action: `Modal shown to user ${UPLOAD_PROGRESS}`
    // meta: { window_width, afterClose, hideModal, cancelAction, backAction, okAction, modalText, modalCategory, modalName, clashing_bookings, cameraAction, docStatus, adult_id, adult_name, doc_name, thread_stage, payment_type, user_type }
  });


  return (

    <Modal
      closeIcon={showClose && 'close'}
      open={true}
      onClose={showClose && onClose}
      style={{ maxWidth: 500 }}
    >

      <Modal.Content>

        <ModalHeader>{modalTitle}</ModalHeader>

        <div style={{ display: 'flex', background: 'transparent', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

          <UploadProgressIndicator
            successText={successText}
            fetchingText={fetchingText}
          />

        </div>

        {
          isFetchingSuccess && <div style={{ display: 'flex', background: 'transparent', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

            {
              // <UploadSuccessIndicator
              //
              // />
            }
            <ModalSuccessIndicator />

          </div>
        }

        {
          // !isFetchingSuccess && <div style={{ display: 'flex', background: 'transparent', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          //   <p>
          //     {
          //      <A5>{modalMessage}</A5>
          //     }
          //   </p>
          // </div>
        }


      </Modal.Content>

    </Modal>

  );
};

UploadProgressModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

const mapStateToProps = ({ user, upload }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  isFetchingUpload: user.isFetchingUpload,
  isFetchingSuccess: user.isFetchingSuccess,
  successText: ownProps.successText,
  fetchingText: ownProps.fetchingText,
  afterClose: ownProps.afterClose,
  showClose: ownProps.showClose,
  hideModal: ownProps.hideModal,
  title: ownProps.title,
  message: ownProps.message

});

export default connect(mapStateToProps, { hideModal })(UploadProgressModal);
