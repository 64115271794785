import {
  Header
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from './colours';
import { fonts } from "./theme";

// import {
//   // S1,
//   S2,
//   S3,
//   S4,
//   S5,
//   S6,
//   Link4
// } from "./typography";

import {

  H1,
  H1A,
  H2,
  H3,
  H4,
  H5,
  H6,
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7,
  B1,
  B2,
  B3,
  B4,
  B5,
  B6,
  P,
  FormLabelMedText,
  FormLabelSmallText,
  FormValueText,
  BulletText,
  Li,
  Link4
} from './typo';

const { width } = window.screen.width;

// Main Layout


// Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350;
// const guidelineBaseHeight = 680;

const scale = size => Number((width / guidelineBaseWidth) * size);
// const horizontalScale2 = (size, factor = 0.7) => Number(((width / guidelineBaseWidth) * size) * factor);
// const verticalScale = size => Number((height / guidelineBaseHeight) * size);
// const verticalScale2 = (size, factor = 0.8) => Number(((height / guidelineBaseHeight) * size) * factor);
const moderateScale = (size, factor = 0.3) => Number(size + ((scale(size) - size) * factor));

export const ms10 = moderateScale(10);
export const ms12 = moderateScale(12);
export const ms14 = moderateScale(14);
export const ms16 = moderateScale(16);
export const ms18 = moderateScale(18);
export const ms30 = moderateScale(30);

// new styles


// bold


// links
export const L4 = styled(Link4)`
  font-weight: ${fonts.bold};
  color: ${colours.black};
  text-align: left;
`;

export { H1, H1A, H2, H3, H4, H5, H6, B1, B2, B3, B4, B5, B6, A1, A2, A3, A4, A5, A6, A7, P, FormLabelMedText, FormLabelSmallText, FormValueText, BulletText, Li };


// export const Li = styled.li`
//   font-weight: ${fonts.semibold};
//   color: ${colours.darkpurple};
//   font-size: 18px;
// `;


export const DayH = styled(H6)`
  color: ${colours.black};
  position: absolute;
  /* prevents headings being different size dependent on text */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  textAlign: center;
  margin: 0;
  padding: 0;
  borderWidth: 0;
  borderRadius: 2;
  borderColor: red;
  borderStyle: solid;
  lineHeight: 200%;

`; // Mon


export const TodH = styled(H6)`


`; // Morning

export const V5 = styled(A5)`

`; // used for input values


// landing
export const QuoteText = styled(A5)`
  color: ${colours.gray};
  font-weight: ${fonts.semibold};
  text-align: justify;
`;

// bullets

export const BulletNumberHText = styled(H4)`
  color: ${colours.white};
  vertical-align: middle;

  text-align: center;
`;

// export const BulletNumberText = styled(H5)`
//   color: ${colours.white}!important;
//
//   vertical-align: middle!important;
//
//
//   text-align: center!important;
// `;

export const EditButtonText = styled(H5)`

    color: ${colours.offWhite}!important;
    text-align: center;
    margin: 8px;

    &:hover {

    }


`;


export const ConfirmButtonText = styled(H5)`
  color: ${colours.offWhite};
  text-align: center;
  margin: 8px;

  &:hover {

  }
`;

// export const BulletText = styled(B5)`
//   text-align: left;
//   overflow-wrap: normal;
// `;

// inputs

export const InputText = styled(H5)`
  color: ${colours.gray};
`;


export const ItemLabelSmallText = styled(A5)`

`; // used on search items (families)


// other
// export const ActionText = styled(H6)`
//   font-style: italic;
//
// `;

export const AvatarText = styled(A4)`
  color: ${colours.darkgray};
  font-weight: ${fonts.bold};
  text-align: left;
`;


// end of new styles

// export const T2 = styled(A5)`
//   color: ${colours.gray};
//   font-weight: ${fonts.semibold};
//
//   text-align: left;
// `;

export const T3 = styled(A3)`
  color: ${colours.gray};
  font-weight: ${fonts.bold};
  text-align: left;
`;

export const T4 = styled(A4)`
  color: ${colours.gray};
  font-weight: ${fonts.normal};
  opacity: ${props => props.opacity};
  text-align: left;
`;


export const FormLabelText = styled(A4)`

  color: ${colours.main};
  fontWeight: ${fonts.semibold};
  opacity: ${props => props.opacity};
  text-align: left;

`;

// export const FormLabelMedText = styled(B5)`
//
//   opacity: ${props => props.opacity};
//   text-align: left;
//
// `;


export const AlertText = styled(H4)`

  color: ${colours.red};

`;

export const InputErrorText = styled(H4)`

  color: ${colours.error}!important;

`;

export const RedButtonText = styled(H4)`

  color: ${colours.red};

`;

export const AlertSmallText = styled(A6)`

  color: ${colours.red};

`;


export const FooterText = styled(A4)`
  font-family: ${fonts.default};
  text-align: center;
  color: ${colours.blue};
  line-height: 22px;
  font-weight: ${fonts.thin};
  text-decoration: none;


`;
