import React from 'react';
import { ReportGA } from '../../utils/ga';
import { A6 } from '../../styles/text';
import { VideoWrapper, VideoResponsive } from '../../styles/styles';
import colours from '../../styles/colours';
import Card from '../general/card';
import CardHeader from '../general/card-header';
import Paragraph from '../general/paragraph';
import ProgressBar from '../general/progress-bar';
import history from '../Navigation/history';
import { dashboard, other } from '../../text';
import welcomeBuntingPic from '../../images/welcome_bunting.min.svg';
import * as ROUTES from '../../constants/routes';
import { DASHBOARD } from '../../constants/ga-category';
import youHave450spPic from '../../images/you_have_450_sp.min.svg';
import youHave500spPic from '../../images/you_have_500_sp.min.svg';

const HowDoesItWork = (props) => {
    // console.log('f item user:', user);
    const { window_width, handleClickGotIt, profileProgress } = props;

    // const narrowScreenOrientationDirection = narrowScreenOrientation === 'row' ? 'column' : 'row';

    const handleClickProfile = () => {

      history.push({
        pathname: ROUTES.PROFILE_SUMMARY,
        state: { editMode: true }
      });

      ReportGA({
        category: DASHBOARD,
        action: `User pressed Go to Profile button on Dashboard Welcome card`
      });

    };
    console.log('Welcome profile progress:', profileProgress);


    return (


      <Card>
        <CardHeader
          paddingBottom='0px'
          alignTitle='center'
          title={<div>{dashboard.how_does_it_work_title}</div>}
          // image={welcomeBuntingPic}
          // paragraph={(window_width >= 490) &&
          //   <Paragraph noBottomPadding>
          //     <br />
          //     {dashboard.welcome_para1}
          //     <br />
          //     {(profileProgress !== 100) ? `${dashboard.welcome_para2_incomplete}` : dashboard.welcome_para2}
          //   </Paragraph>
          // }
        />


        <div style={{ flex: 1, width: '100%', display: 'flex', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', justifyContent: 'center', alignItems: 'flex-end', paddingTop: 4, paddingBottom: 16 }}>

          <div style={{ borderWidth: 0, borderColor: 'pink', borderStyle: 'solid', width: '100%', paddingTop: 8, paddingBottom: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ borderWidth: 0, borderColor: 'red', borderStyle: 'solid', width: '94%', display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>

              <VideoWrapper>
                <VideoResponsive
                  style={{ borderWidth: 0, borderColor: 'blue', borderStyle: 'solid' }}
                  title='sitterpoint_video'
                  src='https://player.vimeo.com/video/670781112?h=389b342fde'
                  // src='https://player.vimeo.com/video/670781112?h=389b342fde&autoplay=1'
                  width='640'
                  height='300'
                  frameBorder='0'
                  autoPlay
                  allow='fullscreen'
                  // color={colours.red}
                  // allow='autoplay; fullscreen'
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowFullscreen
                  loop
                />
              </VideoWrapper>
            </div>
          </div>

        </div>

      </Card>

    );


};

export default HowDoesItWork;
