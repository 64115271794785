/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import colours from '../../styles/colours';
import { setWindowWidth } from '../../actions/layout';
import default_theme from '../../styles/default-theme';

const { layoutStyles } = default_theme;
console.log('layoutStyles: ', layoutStyles);
// standard navbar padding?
// height set

export const StyledDiv = styled.div`

  display: flex;
  flex: 1;
  flex-direction: column;
  alignItems: flex-start;

  background: ${colours.white};

  /* width: 100%; removed for welcome to allow padding to be applied from Theme */

  padding-bottom: 0px;

  margin-left: auto;
  margin-right: auto;

  /* overflow: scroll; removed to avoid border and margin showing in non-debug mode */
  overflow-x: auto;
  border-width: 0px;
  border-color: blue;
  border-style: solid;

  transition: 0.6s margin-top ease-in-out;

`;

// display: 'flex'?

const mapStateToProps = ({ user, layout, nav, search }) => {

  return {
    // serverError: user.error,
    // isConnected: network.isConnected,
    user_id: user.user_id,
    isLoggedIn: user.isLoggedIn,
    window_width: layout.window_width,
    dropdown_nav_is_open: nav.dropdown_nav_is_open,
    screensize: layout.screensize,
    layout_theme: layout.layout_theme,
    searchBannerIsOpen: search.banner_is_open
  };

};

const mapDispatchToProps = dispatch => ({

  setWindowWidth: (width) => {
      dispatch(setWindowWidth(width)); // can remove as not used outside app.js
  }

});


class ScrollColDiv extends Component {


  render () {

    const { isLoggedIn, searchBannerIsOpen, padding, window_width, children, dropdown_nav_is_open, layout_theme, editMode, style } = this.props;
    console.log('searchBannerIsOpen:', searchBannerIsOpen);
    console.log('dropdown_nav_is_open:', dropdown_nav_is_open);
    console.log('editMode:', editMode);
    const { padding_main, padding_side, padding_title } = layout_theme;
    let paddingTop = 0;

    if (window_width <= 730) {
      // if (!editMode) {

        if (searchBannerIsOpen) {
          paddingTop += 0;
        }
        if (dropdown_nav_is_open) {
          paddingTop += 60;
        }
        if (!isLoggedIn) {
          paddingTop = 0;
        }
      // }
    }
    console.log('paddingTop:', paddingTop);


    return (

      <StyledDiv
        width='100%'
        dropdown_nav_is_open={dropdown_nav_is_open}
        style={{
          ...style,
          paddingLeft: padding ? padding_side : 0,
          paddingRight: padding ? padding_side : 0,
          marginTop: paddingTop
        }}
      >
        {children}
      </StyledDiv>


    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrollColDiv);
