import React, { useState } from 'react';
import {
  Icon
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";

// opacity: props.opacity || 1

export const StyledButton = styled.button`

  font-family: ${fonts.default};
  justify-content: center;
  align-items: center;
  background-color: ${props => props.isHovered ? props.verylightgray : props.background ? props.background : colours.confirm};
  color: ${props => props.isHovered ? props.background : props.color};

  border-radius: 30px;
  width: 50px;
  height: 50px;
  border-color: ${props => props.background ? props.background : colours.confirm};
  border-width: 0px;
  border-style: solid;
  margin-left: 8px;
  margin-right: 8px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px ${colours.shadow}; // width, height, radius, colour
  z-index: 1000;

  /* &:disabled {
    background: transparent;
    color: transparent;
  } */


`;


const ActionButton = (props) => {


  const [hover, setHover] = useState(false);

  const { children, screensize, backgroundColor, color, textAlign, onClick, noIcon, rightIcon, paddingLeft, paddingRight } = props;

  const toggleHover = (newHover) => {
      if (hover === undefined) {
        setHover(!hover);
      } else {
        setHover(newHover);
      }
  };

  const handleClick = () => {

      onClick();

      toggleHover(false); // removes any hover effects on click

  };


  const isHovered = hover;

  return (
    <StyledButton
      primary={props.primary}
      icon={props.icon}
      labelPosition={props.icon ? props.labelPosition || 'left' : null}
      fluid={props.fluid}
      compact={props.compact}
      disabled={props.disabled}

      color={props.color}
      background={props.background}

      isHovered={isHovered}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}

      // onClick={props.onClick}
      onClick={() => handleClick()}

    >
      {
        props.icon
        && <Icon name={props.icon} />
      }
      {props.children}
    </StyledButton>
  );
};

export default ActionButton;
