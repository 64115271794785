import moment from 'moment';
import history from '../../components/Navigation/history';
import { showModal, hideModal } from '../modal';
import { dateRangeOverlaps } from '../../lib/timeHelpers';
import { getClashingBookings } from '../../lib/bookingsHelpers';
import { actionSuccess } from '../verify';
import { calendar } from '../../text';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR, MODAL_TYPE_EDIT_BOOKING, MODAL_TYPE_CONFIRM_ADD_AVAILABILITY, MODAL_TYPE_SUCCESS_ADD_AVAILABILITY, MODAL_TYPE_CONFIRM_EDIT_ALL_AVAILABILITY, MODAL_TYPE_CONFIRM_EDIT_AVAILABILITY, MODAL_TYPE_SUCCESS_EDIT_AVAILABILITY, MODAL_TYPE_CONFIRM_DELETE_ALL_AVAILABILITY, MODAL_TYPE_SUCCESS_DELETE_AVAILABILITY, MODAL_TYPE_CONFIRM, MODAL_TYPE_SUCCESS } from '../../components/modals/modalTypes';
import { CONFIRM_ADD_AVAILABILITY, CONFIRM_EDIT_ALL_AVAILABILITY, CONFIRM_DELETE_ALL_AVAILABILITY, CONFIRM_EDIT_AVAILABILITY, SUCCESS_EDIT_AVAILABILITY, SUCCESS_DELETE_AVAILABILITY, SUCCESS_ADD_AVAILABILITY } from '../../components/modals/modalNames';
import { AVAILABILITY } from '../../components/modals/modalCategory';
import { FINALISED, PRE_AUTHORISED, APPROVED } from '../../constants/thread-stages';
import { CONFIRMED, UNCONFIRMED } from '../../constants/booking-status';

export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SAVE_START_DATE = 'SAVE_START_DATE';
export const SAVE_END_DATE = 'SAVE_END_DATE';
export const SET_START_HOUR = 'SET_START_HOUR';
export const SET_END_HOUR = 'SET_END_HOUR';
export const SAVE_START_HOUR = 'SAVE_START_HOUR';
export const SAVE_END_HOUR = 'SAVE_END_HOUR';
export const SET_START_MINUTE = 'SET_START_MINUTE';
export const SET_END_MINUTE = 'SET_END_MINUTE';
export const SAVE_START_MINUTE = 'SAVE_START_MINUTE';
export const SAVE_END_MINUTE = 'SAVE_END_MINUTE';
export const SAVE_SELECTED_RECURRANCE = 'SAVE_SELECTED_RECURRANCE';
export const SET_SELECTED_KIDS = 'SET_SELECTED_KIDS';
export const REMOVE_SELECTED_KIDS = 'REMOVE_SELECTED_KIDS';
export const SET_ALL_SELECTED_KIDS = 'SET_ALL_SELECTED_KIDS';
export const SAVE_SELECTED_KIDS = 'SAVE_SELECTED_KIDS';
export const SAVE_EDIT_START_DATE = 'SAVE_EDIT_START_DATE';
export const SAVE_EDIT_END_DATE = 'SAVE_EDIT_END_DATE';
export const SAVE_EDIT_START_HOUR = 'SAVE_EDIT_START_HOUR';
export const SAVE_EDIT_END_HOUR = 'SAVE_EDIT_END_HOUR';
export const SAVE_EDIT_START_MINUTE = 'SAVE_EDIT_START_MINUTE';
export const SAVE_EDIT_END_MINUTE = 'SAVE_EDIT_END_MINUTE';
export const SAVE_EDIT_SELECTED_KIDS = 'SAVE_EDIT_SELECTED_KIDS';
export const GET_DATE_TIME_AVAILABILITY_REQUEST = 'GET_DATE_TIME_AVAILABILITY_REQUEST';
export const GET_DATE_TIME_AVAILABILITY_SUCCESS = 'GET_DATE_TIME_AVAILABILITY_SUCCESS';
export const GET_DATE_TIME_AVAILABILITY_FAILURE = 'GET_DATE_TIME_AVAILABILITY_FAILURE';
export const SUBMIT_DATE_TIME_AVAILABILITY_REQUEST = 'SUBMIT_DATE_TIME_AVAILABILITY_REQUEST';
export const SUBMIT_DATE_TIME_AVAILABILITY_SUCCESS = 'SUBMIT_DATE_TIME_AVAILABILITY_SUCCESS';
export const SUBMIT_DATE_TIME_AVAILABILITY_FAILURE = 'SUBMIT_DATE_TIME_AVAILABILITY_FAILURE';
export const GET_CALENDAR_REQUEST = 'GET_CALENDAR_REQUEST';
export const GET_CALENDAR_SUCCESS = 'GET_CALENDAR_SUCCESS';
export const GET_CALENDAR_FAILURE = 'GET_CALENDAR_FAILURE';
export const SET_USER_FLEXIBLE_REQUEST = 'SET_USER_FLEXIBLE_REQUEST';
export const SET_USER_FLEXIBLE_SUCCESS = 'SET_USER_FLEXIBLE_SUCCESS';
export const SET_USER_FLEXIBLE_FAILURE = 'SET_USER_FLEXIBLE_FAILURE';
export const SET_BOOKED_DATES = 'SET_BOOKED_DATES';
export const ADD_AVAILABILITY_REQUEST = 'ADD_AVAILABILITY_REQUEST';
export const ADD_AVAILABILITY_SUCCESS = 'ADD_AVAILABILITY_SUCCESS';
export const ADD_AVAILABILITY_FAILURE = 'ADD_AVAILABILITY_FAILURE';
export const EDIT_AVAILABILITY_REQUEST = 'EDIT_AVAILABILITY_REQUEST';
export const EDIT_AVAILABILITY_SUCCESS = 'EDIT_AVAILABILITY_SUCCESS';
export const EDIT_AVAILABILITY_FAILURE = 'EDIT_AVAILABILITY_FAILURE';
export const DELETE_AVAILABILITY_REQUEST = 'DELETE_AVAILABILITY_REQUEST';
export const DELETE_AVAILABILITY_SUCCESS = 'DELETE_AVAILABILITY_SUCCESS';
export const DELETE_AVAILABILITY_FAILURE = 'DELETE_AVAILABILITY_FAILURE';
export const GET_UPCOMING_AVAILABILITY_REQUEST = 'GET_UPCOMING_AVAILABILITY_REQUEST';
export const GET_UPCOMING_AVAILABILITY_SUCCESS = 'GET_UPCOMING_AVAILABILITY_SUCCESS';
export const GET_UPCOMING_AVAILABILITY_FAILURE = 'GET_UPCOMING_AVAILABILITY_FAILURE';

export const getCalendarRequest = () => ({
  type: GET_CALENDAR_REQUEST
});

export const getCalendarSuccess = data => ({
  type: GET_CALENDAR_SUCCESS,
  data
});

export const getCalendarFailure = error => ({
  type: GET_CALENDAR_FAILURE,
  error
});

export const setUserFlexibleRequest = () => ({
  type: SET_USER_FLEXIBLE_REQUEST
});

export const setUserFlexibleSuccess = data => ({
  type: SET_USER_FLEXIBLE_SUCCESS,
  data
});

export const setUserFlexibleFailure = error => ({
  type: SET_USER_FLEXIBLE_FAILURE,
  error
});

export function createBookedDatesFromThreads (user_id, allThreads) {
  return (dispatch) => {

    // create booked_dates (sitting) array from AllThreads
    let bookedDatesArray = [];

    // console.log('this.props:', this.props);
    if (allThreads && allThreads !== undefined) {
      allThreads.map((thread) => {

        const { thread_id, sitter_id, status } = thread;
        // populate bookingObjects from each thread:
        //    - availabilityObjects
        //    - sitting / using
        //    - status (confirmed/unconfirmed)
        //    - thread_id

        const booking_type = (user_id === sitter_id) ? 'sitting' : 'using';
        const booking_status = (status === APPROVED || status === PRE_AUTHORISED || status === FINALISED) ? CONFIRMED : UNCONFIRMED;
        // CHECK input date formats
        const start_timestamp = `${moment(thread.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD')} ${thread.start_time}`;
        const end_timestamp = `${moment(thread.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD')} ${thread.end_time}`;

        const booking = {
          booking_type,
          booking_status,
          thread_id,
          start: start_timestamp,
          end: end_timestamp
        };

        if (thread.status !== 'cancelled') {
          // exclude cancelled sits
          bookedDatesArray = bookedDatesArray.concat(booking);
        }

        // console.log('XbookedDatesArray:', bookedDatesArray);

        return bookedDatesArray;

      });
    }
    console.log('final bookedDatesArray:', bookedDatesArray);
    if (bookedDatesArray.length > 0) {

      dispatch(setBookedDates(bookedDatesArray));

    }
  };
}

export function setBookedDates (bookedDates) {
  return {
    type: SET_BOOKED_DATES,
    bookedDates
  };
}

export const addAvailabilityRequest = () => ({
  type: ADD_AVAILABILITY_REQUEST
});

export const addAvailabilitySuccess = data => ({
  type: ADD_AVAILABILITY_SUCCESS,
  data
});

export const addAvailabilityFailure = error => ({
  type: ADD_AVAILABILITY_FAILURE,
  error
});

export const editAvailabilityRequest = () => ({
  type: EDIT_AVAILABILITY_REQUEST
});

export const editAvailabilitySuccess = data => ({
  type: EDIT_AVAILABILITY_SUCCESS,
  data
});

export const editAvailabilityFailure = error => ({
  type: EDIT_AVAILABILITY_FAILURE,
  error
});

export const deleteAvailabilityRequest = () => ({
  type: DELETE_AVAILABILITY_REQUEST
});

export const deleteAvailabilitySuccess = data => ({
  type: DELETE_AVAILABILITY_SUCCESS,
  data
});

export const deleteAvailabilityFailure = error => ({
  type: DELETE_AVAILABILITY_FAILURE,
  error
});

export function setStartDate (dateObject) {
  return {
    type: SET_START_DATE,
    dateObject
  };
}

export function setEndDate (dateObject) {
  return {
    type: SET_END_DATE,
    dateObject
  };
}

export function saveStartDate (dateObject) {
  return {
    type: SAVE_START_DATE,
    dateObject
  };
}

export function saveEndDate (dateObject) {
  return {
    type: SAVE_END_DATE,
    dateObject
  };
}

export function setStartHour (dateObject) {
  return {
    type: SET_START_HOUR,
    dateObject
  };
}

export function setEndHour (dateObject) {
  return {
    type: SET_END_HOUR,
    dateObject
  };
}

export function saveStartHour (dateObject) {
  return {
    type: SAVE_START_HOUR,
    dateObject
  };
}

export function saveEndHour (dateObject) {
  return {
    type: SAVE_END_HOUR,
    dateObject
  };
}

export function setStartMinute (dateObject) {
  return {
    type: SET_START_MINUTE,
    dateObject
  };
}

export function setEndMinute (dateObject) {
  return {
    type: SET_END_MINUTE,
    dateObject
  };
}

export function saveStartMinute (dateObject) {
  return {
    type: SAVE_START_MINUTE,
    dateObject
  };
}

export function saveEndMinute (dateObject) {
  return {
    type: SAVE_END_MINUTE,
    dateObject
  };
}

export function saveSelectedRecurrance (data) {
  return {
    type: SAVE_SELECTED_RECURRANCE,
    data
  };
}

export function setSelectedKids (kidObject) {
  return {
    type: SET_SELECTED_KIDS,
    kidObject
  };
}

export function setAllSelectedKids (selected_kids) {
  return {
    type: SET_ALL_SELECTED_KIDS,
    selected_kids
  };
}

export function removeSelectedKids (kidObject) {
  return {
    type: REMOVE_SELECTED_KIDS,
    kidObject
  };
}

export function saveSelectedKids (selected_kids) {
  return {
    type: SAVE_SELECTED_KIDS,
    selected_kids
  };
}

export function saveEditStartDate (dateObject) {
  return {
    type: SAVE_EDIT_START_DATE,
    dateObject
  };
}

export function saveEditEndDate (dateObject) {
  return {
    type: SAVE_EDIT_END_DATE,
    dateObject
  };
}

export function saveEditStartHour (dateObject) {
  return {
    type: SAVE_EDIT_START_HOUR,
    dateObject
  };
}

export function saveEditEndHour (dateObject) {
  return {
    type: SAVE_EDIT_END_HOUR,
    dateObject
  };
}

export function saveEditStartMinute (dateObject) {
  return {
    type: SAVE_EDIT_START_MINUTE,
    dateObject
  };
}

export function saveEditEndMinute (dateObject) {
  return {
    type: SAVE_EDIT_END_MINUTE,
    dateObject
  };
}

export function saveEditSelectedKids (selected_kids) {
  return {
    type: SAVE_EDIT_SELECTED_KIDS,
    selected_kids
  };
}

// export function toggleAvailability (availabilityObject) {
//
// }

export const getUpcomingAvailabilityRequest = () => ({
  type: GET_UPCOMING_AVAILABILITY_REQUEST
});

export const getUpcomingAvailabilitySuccess = data => ({
  type: GET_UPCOMING_AVAILABILITY_SUCCESS,
  data
});

export const getUpcomingAvailabilityFailure = error => ({
  type: GET_UPCOMING_AVAILABILITY_FAILURE,
  error
});

export function setUserFlexible (token, user_id, flexible) {

  console.log('setUserFlexible action:');

  return (dispatch) => {

    dispatch(showModal(
      // MODAL_TYPE_SPINNER,
      // { // modalProps
      //   // message: calendar.modal.add_availability.fetching
      // }
    ));

    dispatch(setUserFlexibleRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/calendar/flexible`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        user_id,
        flexible // boolean
      })
    })
    .then(CheckError)
    .then((data) => {

      console.log('data:', data);

      dispatch(setUserFlexibleSuccess(data.flexible)); // returns flexible boolean to user reducer

      dispatch(hideModal());
      // show modal showing success.

      // const modalText = calendar.modal.add_availability.success;

      dispatch(showModal(

        // MODAL_TYPE_SUCCESS,
        // { // modalProps
        //   // other_user_name?
        //   modalCategory: AVAILABILITY,
        //   modalName: SUCCESS_ADD_AVAILABILITY,
        //   modalText: modalText,
        //   okAction: () => dispatch(hideModal()),
        //   backAction: null,
        //
        // }
      ));
      dispatch(actionSuccess()); // triggers success icon

    })
    .catch((err) => {
      dispatch(setUserFlexibleFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: calendar.modal.add_availability.failure.title,
          header: calendar.modal.add_availability.failure.header,
          message: calendar.modal.add_availability.failure.para,
          message2: calendar.modal.add_availability.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}

export function getUpcomingAvailability (token, user_id, lat, lng) {
  return (dispatch) => {

    dispatch(getUpcomingAvailabilityRequest());
    console.log('getUpcomingAvailability action for user_id: ', user_id);

    // /calendar/upcoming-availability/:user_id/:start_timestamp/:end_timestamp
    fetch(`${process.env.REACT_APP_API_URI}/calendar/upcoming-availability/${user_id}/${lat}/${lng}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    })
    .then((res) => {
      console.log('getUpcomingAvailability res:', res);
      res.json()
      .then((data) => {
        console.log('getUpcomingAvailability response data:', data);

        dispatch(getUpcomingAvailabilitySuccess(data));

      })
      .catch((err) => {
        console.log('caught getCalendar error: ', err);
        dispatch(getUpcomingAvailabilityFailure(err.message));
      });
    })
    .catch((err) => {
      console.log('caught getCalendar error: ', err);
      dispatch(getUpcomingAvailabilityFailure(err.message));
    });
  };
}

// TO REDO ALL BELOW - NEW
// launches confirm modal
export function confirmAddAvailability (token, combinedBookingsArray, booking_id, user_id, date, start_time, end_time, recurrance) {

    return (dispatch) => {

      // check if dates overlap any
                // confirmed sits?
                // pre-approved sits?
                // if true then confirm modal

      const all_clashing_bookings = getClashingBookings(booking_id, date, recurrance, start_time, end_time, combinedBookingsArray);

      console.log('all_clashing_bookings:', all_clashing_bookings);
      // check if this availability overlaps any booked_dates.
      if (all_clashing_bookings.length > 0) {

        // if true then return modal

        const okAction = () => {
            console.log('confirmAddAvailability okAction:');
          dispatch(okAddAvailability(token, user_id, date, start_time, end_time, recurrance));
        };

        // dispatch(showModal(MODAL_TYPE_CONFIRM_ADD_AVAILABILITY, {
        //   okAction: () => okAction(),
        //   backAction: null,
        //   cancelAction: () => dispatch(hideModal()),
        //   clashing_bookings: all_clashing_bookings
        //   // thread_stage: 'pre_approve'
        // }));

        dispatch(showModal(MODAL_TYPE_CONFIRM, {
          modalCategory: AVAILABILITY,
          modalName: CONFIRM_ADD_AVAILABILITY,
          modalText: calendar.modal.add_availability.confirm,
          okAction: () => okAction(),
          backAction: null,
          cancelAction: () => dispatch(hideModal()),
          clashing_bookings: all_clashing_bookings
        }));

      } else {
        // else just addAvailability

        dispatch(okAddAvailability(token, user_id, date, start_time, end_time, recurrance));

      }

    };

}

export function okAddAvailability (token, user_id, date, start_time, end_time, recurrance) {

  console.log('okAddAvailability action:');
  // console.log(`thread_id: ${thread_id}`);

  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: calendar.modal.add_availability.fetching
      }
    ));

    dispatch(addAvailabilityRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/users/availability/add`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        user_id,
        date, // 'YYYY-MM-DD'
        start_time,
        end_time,
        recurrance
      })
    })
    .then(CheckError)
    .then((data) => {

      console.log('data:', data);
      dispatch(addAvailabilitySuccess(data)); // returns ALL available dates to write new available_dates array to calendar reducer

      dispatch(hideModal());
      // show modal showing success.

      const modalText = calendar.modal.add_availability.success;

      dispatch(showModal(

        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: AVAILABILITY,
          modalName: SUCCESS_ADD_AVAILABILITY,
          modalText: modalText,
          okAction: () => dispatch(hideModal()),
          backAction: null,

        }
      ));
      dispatch(actionSuccess()); // triggers success icon

    })
    .catch((err) => {
      dispatch(addAvailabilityFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: calendar.modal.add_availability.failure.title,
          header: calendar.modal.add_availability.failure.header,
          message: calendar.modal.add_availability.failure.para,
          message2: calendar.modal.add_availability.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}

// TO REDO ALL BELOW - NEW
// launches confirm modal - do you want to edit all or just the 1?
export function confirmEditAllAvailability (token, booked_dates, booking_id, recurrance_id, user_id, date, start_time, end_time, recurrances) {

    console.log('viewed_date:', date);
    console.log('recurrances:', recurrances);

    return (dispatch) => {

      if (recurrances.length === 1) {
        //  1 recurrance, so launch Edit Times modal
        const editing_all = false;
        dispatch(launchEditAvailability(token, booked_dates, booking_id, recurrance_id, user_id, date, start_time, end_time, editing_all, recurrances));

      } else {

        //  > 1 recurrance
        // check if user wants to delete all or just 1?

        const editAllAction = () => {
            console.log('confirmAddAvailability okAction:');
            const editing_all = true;
          dispatch(launchEditAvailability(token, booked_dates, booking_id, recurrance_id, user_id, date, start_time, end_time, editing_all, recurrances));

        };

        const justOneAction = () => {
            console.log('confirmAddAvailability okAction:');
            const editing_all = false;

          dispatch(launchEditAvailability(token, booked_dates, booking_id, recurrance_id, user_id, date, start_time, end_time, editing_all, recurrances));
        };

        dispatch(showModal(MODAL_TYPE_CONFIRM, {
          modalCategory: AVAILABILITY,
          modalName: CONFIRM_EDIT_ALL_AVAILABILITY,
          modalText: calendar.modal.edit_all_availability.confirm,
          okAction: () => editAllAction(),
          backAction: null,
          cancelAction: () => justOneAction()
        }));

      }

    };

}

// launches editDayModal modal in edit_booking_mode
export function launchEditAvailability (token, booked_dates, booking_id, recurrance_id, user_id, date, start_time, end_time, editing_all, recurrances) {

  return (dispatch) => {
    // want to show edit day modal, but in edit_booking_mode
    dispatch(showModal(MODAL_TYPE_EDIT_BOOKING, {

      editing_all,
      booking_id,
      recurrance_id,
      date,
      start_time,
      end_time,
      recurrances

    }));
  };

}

// launches confirm modal - is it already booked?
export function confirmEditAvailability (token, combinedBookingsArray, booking_id, recurrance_id, user_id, date, start_time, end_time, recurrance, editing_all) {

    return (dispatch) => {

      // check if dates overlap any
                // existing availability
                // confirmed sits?
                // pre-approved sits?
                // if true then confirm modal

      console.log('combinedBookingsArray:', combinedBookingsArray);

      const all_clashing_bookings = getClashingBookings(booking_id, date, recurrance, start_time, end_time, combinedBookingsArray);

      console.log('all_clashing_bookings:', all_clashing_bookings);
      // check if this availability overlaps any booked_dates.
      if (all_clashing_bookings.length > 0) {

        // if true then return modal

        const okAction = () => {
            console.log('confirmAddAvailability okAction:');
          dispatch(okEditAvailability(token, user_id, booking_id, recurrance_id, date, start_time, end_time, recurrance, editing_all));
        };

        // dispatch(showModal(MODAL_TYPE_CONFIRM_EDIT_AVAILABILITY, {
        //   okAction: () => okAction(),
        //   backAction: null,
        //   cancelAction: () => dispatch(hideModal()),
        //   clashing_bookings: all_clashing_bookings
        // }));

        dispatch(showModal(MODAL_TYPE_CONFIRM, {
          modalCategory: AVAILABILITY,
          modalName: CONFIRM_EDIT_AVAILABILITY,
          modalText: calendar.modal.edit_availability.confirm,
          okAction: () => okAction(),
          backAction: null,
          cancelAction: () => dispatch(hideModal()),
          clashing_bookings: all_clashing_bookings
        }));

      } else {
        // else just editAvailability

        dispatch(okEditAvailability(token, user_id, booking_id, recurrance_id, date, start_time, end_time, recurrance, editing_all));

      }

    };

}

export function okEditAvailability (token, user_id, booking_id, recurrance_id, date, start_time, end_time, recurrance, editing_all) {

  console.log('okEditAvailability action:');
  // console.log(`thread_id: ${thread_id}`);

  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: calendar.modal.edit_availability.fetching
      }
    ));

    dispatch(editAvailabilityRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/users/availability/edit`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        user_id,
        booking_id,
        recurrance_id,
        date, // 'YYYY-MM-DD'
        start_time,
        end_time,
        recurrance,
        editing_all
      })
    })
    .then(CheckError)
    .then((data) => {
      console.log('data:', data);
      dispatch(editAvailabilitySuccess(data)); // returns ALL available dates to write new available_dates array to calendar reducer

      dispatch(hideModal());
      // show modal showing success.
      // dispatch(showModal(
      //   MODAL_TYPE_SUCCESS_EDIT_AVAILABILITY,
      //   { // modalProps
      //     // other_user_name?
      //     // thread_stage: 'pre_approve',
      //     // user_type,
      //     // other_user_name: messageParams.thread_details.requester_name
      //   }
      // ));

      const modalText = calendar.modal.edit_availability.success;

      dispatch(showModal(

        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: AVAILABILITY,
          modalName: SUCCESS_EDIT_AVAILABILITY,
          modalText: modalText,
          okAction: () => dispatch(hideModal()),
          backAction: null,

        }
      ));

      dispatch(actionSuccess()); // triggers success icon

    })
    .catch((err) => {
      dispatch(editAvailabilityFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: calendar.modal.edit_availability.failure.title,
          header: calendar.modal.edit_availability.failure.header,
          message: calendar.modal.edit_availability.failure.para,
          message2: calendar.modal.edit_availability.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}

// TO REDO ALL BELOW - NEW
// launches confirm modal - do you want to edit all or just the 1?
export function confirmDeleteAllAvailability (token, booking_id, recurrance_id, user_id, recurrances) {

    return (dispatch) => {

      if (recurrances.length === 1) {
        //  1 recurrance, so launch Delete action
        const editing_all = false;
        dispatch(okDeleteAvailability(token, booking_id, recurrance_id, user_id, editing_all));

      } else {
      // > 1 recurrance
      // check if user wants to delete all or just 1?

        const deleteAllAction = () => {
            console.log('confirmDeleteAvailability okAction:');
            const editing_all = true;
          dispatch(okDeleteAvailability(token, booking_id, recurrance_id, user_id, editing_all));
        };

        const justOneAction = () => {
            console.log('confirmDeleteAvailability okAction:');
            const editing_all = false;
          dispatch(okDeleteAvailability(token, booking_id, recurrance_id, user_id, editing_all));
        };

        // dispatch(showModal(MODAL_TYPE_CONFIRM_DELETE_ALL_AVAILABILITY, {
        //   okAction: () => deleteAllAction(),
        //   backAction: null,
        //   cancelAction: () => justOneAction()
        // }));

        dispatch(showModal(MODAL_TYPE_CONFIRM, {
          showClose: true,
          modalCategory: AVAILABILITY,
          modalName: CONFIRM_DELETE_ALL_AVAILABILITY,
          modalText: calendar.modal.delete_all_availability.confirm,
          okAction: () => deleteAllAction(),
          backAction: null,
          cancelAction: () => justOneAction()
        }));

      }

    };

}

export function okDeleteAvailability (token, booking_id, recurrance_id, user_id, editing_all) {

  console.log('okDeleteAvailability action:');
  // console.log(`thread_id: ${thread_id}`);

  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: calendar.modal.delete_availability.fetching
      }
    ));

    dispatch(deleteAvailabilityRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/users/availability/delete`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        user_id,
        booking_id,
        recurrance_id,
        editing_all
      })
    })
    .then(CheckError)
    .then((data) => {

      console.log('data:', data);
      dispatch(deleteAvailabilitySuccess(data)); // returns ALL available dates to write new available_dates array to calendar reducer

      dispatch(hideModal());
      // show modal showing success.

      // dispatch(showModal(
      //   MODAL_TYPE_SUCCESS_DELETE_AVAILABILITY,
      //   { // modalProps
      //     // other_user_name?
      //     // thread_stage: 'pre_approve',
      //     // user_type,
      //     // other_user_name: messageParams.thread_details.requester_name
      //   }
      // ));

      const modalText = calendar.modal.delete_availability.success;

      dispatch(showModal(

        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: AVAILABILITY,
          modalName: SUCCESS_DELETE_AVAILABILITY,
          modalText: modalText,
          okAction: () => dispatch(hideModal()),
          backAction: null,

        }
      ));

      dispatch(actionSuccess()); // triggers success icon

    })
    .catch((err) => {
      dispatch(deleteAvailabilityFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: calendar.modal.delete_availability.failure.title,
          header: calendar.modal.delete_availability.failure.header,
          message: calendar.modal.delete_availability.failure.para,
          message2: calendar.modal.delete_availability.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}
