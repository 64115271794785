import { connect } from 'react-redux';
import SearchButton from '../../components/search/search-button';
import { toggleDropdownKids } from '../../actions/general/dropdown-hourValue';
import { submitSearch } from '../../actions/search';
import { sendEditedDetails } from '../../actions/messages';
import { getUserById } from '../../actions/auth/profile';
import { openBurger, closeBurger } from '../../actions/navbar';

const mapStateToProps = ({ user, search, editSearch, messageThread, nav }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  user_id: user.user_id,
  home_address: user.home_address,
  search_parameters: search,
  burgerIsOpen: nav.burgerIsOpen,
  dropdownOpen: ownProps.dropdownOpen,
  toggleDropdown: ownProps.toggleDropdown,
  editMode: messageThread.editMode,
  thread_id: messageThread.thread_details.thread_id,
  thread_details: messageThread.thread_details,
  edited_thread_details: editSearch,
  finaliseMode: ownProps.finaliseMode,
  spQuoted: ownProps.spQuoted,
  helpMessage: ownProps.helpMessage,
  userHasEnoughPoints: ownProps.userHasEnoughPoints

});

const mapDispatchToProps = (dispatch, props) => ({


  handleGetUser: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getUserById(token, user_id));

    }

  },
  handleBurgerClick: (burgerIsOpen) => {
    if (burgerIsOpen) {
      dispatch(closeBurger());
    } else {
      dispatch(openBurger());
    }
  },
  toggleDropdownKids: (open) => {
    console.log('toggleDropdown');
    dispatch(toggleDropdownKids(open));


  },
  handleSelect: () => {

    // const token = localStorage.sitterpoint_token;
    //
    // if (token) {
    //   dispatch(getUserById(token, user_id));
    //
    // }

  },
  saveSearchQuery: (user_id, home_address, search_parameters) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(submitSearch(token, user_id, home_address, search_parameters));
    }


  },
  sendEditedDetails: (messageParams, thread_id, thread_details, user_id) => {

    console.log('thread_id:', thread_id);
    console.log('thread_details:', thread_details);

    const status = 'request';


    // const status = (thread_details.status === 'pre_approved') ? 'request' : (thread_details.status === 'approved') ? 'edited-approved' : 'edited-unhandled';
    const editedThreadDetails = { ...thread_details, status };

    console.log('finalThreadDetails:', editedThreadDetails);


    const token = localStorage.sitterpoint_token;

    if (token) {

      dispatch(sendEditedDetails(token, messageParams, thread_id, editedThreadDetails, user_id));

    }

  },
  // preAuthoriseDetails: (thread_id, thread_details) => {
  //
  //   // console.log('thread_id:', thread_id);
  //   // console.log('thread_details:', thread_details);
  //
  //   const status = 'pre_authorised'; // not needed but don't stuff up request.
  //   const editedThreadDetails = { ...thread_details, status };
  //
  //   // console.log('finalThreadDetails:', editedThreadDetails);
  //
  //
  //   const token = localStorage.sitterpoint_token;
  //
  //   if (token) {
  //
  //     dispatch(preAuthoriseDetails(token, thread_id, editedThreadDetails));
  //
  //   }
  //
  // },
  // updatePreAuthorisedDetails: (thread_id, thread_details, user_id) => {
  //
  //   // console.log('thread_id:', thread_id);
  //   // console.log('thread_details:', thread_details);
  //
  //   const status = 'pre_authorised'; // not needed but don't stuff up request.
  //   const editedThreadDetails = { ...thread_details, status };
  //
  //   // console.log('finalThreadDetails:', editedThreadDetails);
  //
  //
  //   const token = localStorage.sitterpoint_token;
  //
  //   if (token) {
  //
  //     dispatch(updatePreAuthorisedDetails(token, thread_id, editedThreadDetails, user_id));
  //
  //   }
  //
  // },
  handleDropdownClick: () => {
    const { handleDropdownClick } = props;
    handleDropdownClick();
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(SearchButton);
