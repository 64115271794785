import moment from 'moment';

const getTimeAndPoints = (tariff_details, saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, saved_kids) => {

  // console.log('getTimeAndPoints saved_start_date:', saved_start_date);
  // console.log('getTimeAndPoints saved_start_hr:', saved_start_hr);
  // console.log('getTimeAndPoints saved_start_min:', saved_start_min);
  // console.log('getTimeAndPoints tariff_details', tariff_details);
  // console.log('getTimeAndPoints saved_kids', saved_kids);

  const useEndDate = !saved_end_date ? saved_start_date : saved_end_date;
  if (tariff_details && saved_kids && saved_start_date && saved_start_hr && saved_start_min && saved_end_hr && saved_end_min) {
    // calc QuoteSP
    // console.log('saved_start_date:', saved_start_date);
    // console.log('saved_start_hr:', saved_start_hr);
    // console.log('saved_start_min:', saved_start_min);
    // console.log('tariff_details', tariff_details);
    const startString = `${saved_start_date} ${saved_start_hr}:${saved_start_min}:00`;
    const endString = `${useEndDate} ${saved_end_hr}:${saved_end_min}:00`;

    const startMoment = moment(startString, 'DD/MM/YYYY HH:mm:ss');
    const endMoment = moment(endString, 'DD/MM/YYYY HH:mm:ss');

    const durationMins = endMoment.diff(startMoment, 'minutes');
    // console.log('durationMins:', durationMins);
    const tariffPerMin = (tariff_details.points / tariff_details.mins); // may not be a round number, so round final points.

    const additionalKidFactor = (tariff_details.additional_kid_points / tariff_details.points);
    // console.log('saved_kids:', saved_kids);

    const kidFactor = saved_kids.reduce((accumulator, value, index) => {
        // console.log('accumulator: ', accumulator);
        // console.log('value:', value);
        // console.log('index:', index);
        if (index === 0) {
          return 1;
        }
        return accumulator + additionalKidFactor;

    }, 0);

    // console.log('kidFactor:', kidFactor);
    // console.log('durationMins:', durationMins);
    // console.log('tariffPerMin:', tariffPerMin);
    // console.log('kidFactor:', kidFactor);

    const spQuote = Math.round(durationMins * tariffPerMin * kidFactor); // must round to integer as tariffPerMin may not be a round number
    console.log('spQuote:', spQuote);
    // console.log('tariff_details', tariff_details);
    // console.log('spQuote:', spQuote);


    // calc timeQuote String

    const minutesDuration = moment.duration(durationMins, 'minutes');
    // console.log('minutesDuration:', minutesDuration);
    const asHours = minutesDuration.asHours();
    // console.log('asHours:', asHours);
    const hours = asHours > 23 ? Math.floor(minutesDuration.asHours()) : minutesDuration.hours();
    const minutes = minutesDuration.minutes();

    console.log('hours:', hours);
    console.log('minutes:', minutes);
    const timeQuote = `${hours < 1 ? '' : `${hours} ${hours === 1 ? 'hour' : 'hours'}`}${((minutes > 0) && (hours > 0)) ? ' and ' : ''}${minutes > 0 ? `${minutes} minutes` : ''}`;
    // const minutesQuote = spQuote.durationMins;


    console.log('spQuote:', spQuote);
    console.log('timeQuote:', timeQuote);
    // return object
    return { spQuote, timeQuote };
  }


};

export default getTimeAndPoints;
