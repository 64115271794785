import React, { Component } from 'react';
import { CameraSelfie } from '../general/camera';
import { verify as verifyText } from '../../text';


class VerifySelfieUploadButton extends Component {


  onCaptureImage = (blob) => {
    // convert image file to base64 string

    // convert image file to base64 string

    const file = blob;
    const reader = new FileReader();


    reader.addEventListener('load', () => {
      // const fileObject = { image: reader.result, name: `photo.${fileName}`, type: file.type };
      this.props.setSelectedFiles(reader.result); // setInputImg(reader.result)

    }, false);

    if (file) {
        reader.readAsDataURL(file);

    }


  }


  render () {
    const { doc_name, setSelectedFiles, selectedFiles, setCameraAspectRatio } = this.props;

    const introText =
      doc_name === 'id_selfie' ? verifyText.modal.take_selfie_intro :
      doc_name === 'id' ? verifyText.modal.take_id_intro :
      doc_name === 'poa' ? verifyText.modal.take_poa_intro :
      doc_name === 'dbs' ? verifyText.modal.take_dbs_intro :
      verifyText.modal.take_photo_intro;

    return (


      <div style={{ flex: 1, height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center' }}>


        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          textAlign: 'center',
          // overflowX: 'hidden',
          // overflowY: 'auto',
          maxWidth: 600,

          borderColor: 'green',
          borderWidth: 0,
          borderStyle: 'solid'


        }}>

          {
            // camera
            !selectedFiles && (
              <CameraSelfie

                camera={doc_name === 'id_selfie' || doc_name === 'photo' ? 'front' : 'rear'}
                outline={doc_name === 'id_selfie' || doc_name === 'photo' ? 'face' : 'rectangle'}
                onCapture={blob => this.onCaptureImage(blob)}
                // onCapture={blob => setSelectedFiles(blob)}
                onClear={() => setSelectedFiles(undefined)}
                introText={introText}
                setCameraAspectRatio={setCameraAspectRatio}
              />
            )
          }

          {
            // preview
            // selectedFiles && (
            //   <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            //     <FormLabelSmallText style={{ marginLeft: 0, marginRight: 0 }}>{verifyText.modal.upload_selfie_id_selfie.preview}</FormLabelSmallText>
            //     <AvatarWrapper style={{ borderRadius: '5%', width: 140, height: 140 }}>
            //       <AvatarPhotoFlipped
            //
            //         alt='user_selfie'
            //         src={selectedFiles && URL.createObjectURL(selectedFiles)}
            //
            //       />
            //     </AvatarWrapper>
            //   </div>
            //
            // )
          }

        </div>

      </div>
    );
  }
}

export default VerifySelfieUploadButton;
// export default connectAlert(hoistNonReactStatic(decoratedComponent, Signup));
