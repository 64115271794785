import colours from '../styles/colours';
import { thread_chat } from '../text';
import imgSitting from '../images/DB_CB_you_are_sitting2.svg';
import imgHaveSitter from '../images/DB_CB_you_have_a_sitter2.svg';
import imgAreyouSitting from '../images/DB_NC_are_you_sitting2.svg';
import imgHaveYouSitter from '../images/DB_NC_have_you_a_sitter2.svg';
import alertIcon from '../images/exclam_circle.svg';
import { ACTION_REQUIRED } from '../constants/thread-status';

// for messages and thread
export const getStatusText = (status, user_id, sitter_id, sitter_name, requester_name, isInPast) => {
  // console.log('status', status);
  // console.log('user_id', user_id);
  // console.log('sitter_id', sitter_id);
  // console.log('sitter_name', sitter_name);
  // console.log('requester_name', requester_name);


  // { (user_id === sitter_id) && isInPast && ((status === 'pre_approved') || (status === 'request'))
  //   && <div style={{ width: '100%', padding: 4, borderColor: 'green', borderWidth: 0, borderStyle: 'solid', alignItems: 'center' }}>
  //     <H5 style={{ color: colours.red, textAlign: 'center' }}>Sit Expired - this sit wasn't approved</H5>
  //   </div>
  // }
  //
  // { (user_id === requester_id) && isInPast && ((status === 'pre_approved') || (status === 'request'))
  //   && <div style={{ width: '100%', padding: 4, borderColor: 'green', borderWidth: 0, borderStyle: 'solid', alignItems: 'center' }}>
  //     <H5 style={{ color: colours.red, textAlign: 'center' }}>Sit Expired - this sit wasn't approved</H5>
  //   </div>
  // }

  // { (user_id === requester_id) && isInPast && (status === 'approved')
  //   && <div style={{ width: '100%', padding: 4, borderColor: 'green', borderWidth: 0, borderStyle: 'solid', alignItems: 'center' }}>
  //     <H5 style={{ color: colours.red, textAlign: 'center' }}>Sit in past - waiting for sitter to finalise times</H5>
  //   </div>
  // }

  // { (user_id === requester_id) && isInPast && (status === 'finalised')
  //   && <div style={{ width: '100%', padding: 4, borderColor: 'green', borderWidth: 0, borderStyle: 'solid', alignItems: 'center' }}>
  //     <H5 style={{ color: colours.red, textAlign: 'center' }}>This sit has been finalised</H5>
  //   </div>
  // }


  if (isInPast) {

    if ((status === 'pre_approved') || (status === 'request')) {
      if (user_id === sitter_id) {
        return `${thread_chat.status_text.sit_expired}`; // add BorderColor and textColor colours.red to object
      }
      return `${thread_chat.status_text.sit_expired}`; // textColor colours.red
    }

    if (status === 'approved') {
      if (user_id === sitter_id) {
        return `${thread_chat.status_text.waiting_for} ${thread_chat.status_text.you} ${thread_chat.status_text.to_finalise}`; // add BorderColor and textColor colours.redto object
      }
      return `${thread_chat.status_text.waiting_for} ${sitter_name} (${thread_chat.status_text.sitter}) ${thread_chat.status_text.to_finalise}`; // textColor colours.red
    }

  }

  if (status === 'new') {
    if (user_id === sitter_id) {
      return `${thread_chat.status_text.error}`;
    }
    return `${thread_chat.status_text.You} ${thread_chat.status_text.new_request} ${sitter_name}`;
  }
  if (status === 'request') {
    if (user_id === sitter_id) {
      return `${thread_chat.status_text.needs_pre_approval} ${thread_chat.status_text.you}`;
    }
    return `This sit needs to be pre-approved by ${sitter_name}`;
  }
  if (status === 'pre_approved') {
    if (user_id !== sitter_id) {
      return 'This sit needs to be confirmed by You';
    }
    return `This sit needs to be confirmed by ${requester_name}`;
  }
  if (status === 'approved') {
    return 'This sit is confirmed';
  }
  if (status === 'edited') {
    return `This sit has been changed, waiting for ${sitter_name} to pre-approve`;
  }
  if (status === 'cancelled') {
    return 'This has been cancelled';
  }
  if (status === 'pre_authorised') {
    if (user_id === sitter_id) {
      return `Waiting for ${requester_name} to approve final details`;
    }
    return `You need to approve final details submitted by ${sitter_name}`;
  }
  if (status === 'finalised') {
    if (user_id === sitter_id) {
      return `This sitting happended. Final details were approved by ${requester_name}`;
    }
    return 'This sitting happended. Final details were approved by You';
  }
  // else
  return 'unhandled statusText';

};


export const getStatusIcon = (status, user_id, sitter_id, sitter_name, requester_name, isInPast) => {


  if (isInPast) {

    if ((status === 'pre_approved') || (status === 'request')) {
      if (user_id === sitter_id) {
        return null; // `${thread_chat.status_text.sit_expired}`; // add BorderColor and textColor colours.red to object
      }
      return null; // `${thread_chat.status_text.sit_expired}`; // textColor colours.red
    }

    if (status === 'approved') {
      if (user_id === sitter_id) {
        return alertIcon; // `${thread_chat.status_text.waiting_for} ${thread_chat.status_text.you} ${thread_chat.status_text.to_finalise}`; // add BorderColor and textColor colours.redto object
      }
      return null; // `${thread_chat.status_text.waiting_for} ${sitter_name} (${thread_chat.status_text.sitter}) ${thread_chat.status_text.to_finalise}`; // textColor colours.red
    }

  }

  if (status === 'new') {
    if (user_id === sitter_id) {
      return null; // `${thread_chat.status_text.error}`;
    }
    return null; // `${thread_chat.status_text.You} ${thread_chat.status_text.new_request} ${sitter_name}`;
  }
  if (status === 'request') {
    if (user_id === sitter_id) {
      return alertIcon; // `${thread_chat.status_text.needs_pre_approval} ${thread_chat.status_text.you}`;
    }
    return null; // `This sit needs to be pre-approved by ${sitter_name}`;
  }
  if (status === 'pre_approved') {
    if (user_id !== sitter_id) {
      return alertIcon; // 'This sit needs to be confirmed by You';
    }
    return null; // `This sit needs to be confirmed by ${requester_name}`;
  }
  if (status === 'approved') {
    return null; // 'This sit is confirmed';
  }
  if (status === 'edited') {
    return alertIcon; // `This sit has been changed, waiting for ${sitter_name} to pre-approve`;
  }
  if (status === 'cancelled') {
    return null; // 'This has been cancelled';
  }
  if (status === 'pre_authorised') {
    if (user_id === sitter_id) {
      return null; // `Waiting for ${requester_name} to approve final details`;
    }
    return alertIcon; // `You need to approve final details submitted by ${sitter_name}`;
  }
  if (status === 'finalised') {
    if (user_id === sitter_id) {
      return null; // `This sitting happended. Final details were approved by ${requester_name}`;
    }
    return null; // 'This sitting happended. Final details were approved by You';
  }
  // else
  return 'unhandled statusText';

};

export const getAlertStatus = (status, user_id, sitter_id, isInPast) => {


  if (isInPast) {

    if ((status === 'pre_approved') || (status === 'request')) {
      if (user_id === sitter_id) {
        return null; // `${thread_chat.status_text.sit_expired}`; // add BorderColor and textColor colours.red to object
      }
      return null; // `${thread_chat.status_text.sit_expired}`; // textColor colours.red
    }

    if (status === 'approved') {
      if (user_id === sitter_id) {
        return ACTION_REQUIRED; // `${thread_chat.status_text.waiting_for} ${thread_chat.status_text.you} ${thread_chat.status_text.to_finalise}`; // add BorderColor and textColor colours.redto object
      }
      return null; // `${thread_chat.status_text.waiting_for} ${sitter_name} (${thread_chat.status_text.sitter}) ${thread_chat.status_text.to_finalise}`; // textColor colours.red
    }

  }

  if (status === 'new') {
    if (user_id === sitter_id) {
      return null; // `${thread_chat.status_text.error}`;
    }
    return null; // `${thread_chat.status_text.You} ${thread_chat.status_text.new_request} ${sitter_name}`;
  }
  if (status === 'request') {
    if (user_id === sitter_id) {
      return ACTION_REQUIRED; // `${thread_chat.status_text.needs_pre_approval} ${thread_chat.status_text.you}`;
    }
    return null; // `This sit needs to be pre-approved by ${sitter_name}`;
  }
  if (status === 'pre_approved') {
    if (user_id !== sitter_id) {
      return ACTION_REQUIRED; // 'This sit needs to be confirmed by You';
    }
    return null; // `This sit needs to be confirmed by ${requester_name}`;
  }
  if (status === 'approved') {
    return null; // 'This sit is confirmed';
  }
  if (status === 'edited') {
    return ACTION_REQUIRED; // `This sit has been changed, waiting for ${sitter_name} to pre-approve`;
  }
  if (status === 'cancelled') {
    return null; // 'This has been cancelled';
  }
  if (status === 'pre_authorised') {
    if (user_id === sitter_id) {
      return null; // `Waiting for ${requester_name} to approve final details`;
    }
    return ACTION_REQUIRED; // `You need to approve final details submitted by ${sitter_name}`;
  }
  if (status === 'finalised') {
    if (user_id === sitter_id) {
      return null; // `This sitting happended. Final details were approved by ${requester_name}`;
    }
    return null; // 'This sitting happended. Final details were approved by You';
  }
  // else
  return 'unhandled statusText';

};



export const getStatusColor = (status, user_id, sitter_id, sitter_name, requester_name, isInPast) => {
  // console.log('status', status);
  // console.log('user_id', user_id);
  // console.log('sitter_id', sitter_id);
  // console.log('sitter_name', sitter_name);
  // console.log('requester_name', requester_name);
  const sitter_color = colours.darkpurple;
  const requester_color = colours.green;
  const expired_color = colours.red;
  const error_color = colours.red;
  const cancelled_color = colours.red;
  const past_color = colours.gray;
  const pending_color = colours.orange;
  const confirmed_color = colours.green;
  const neutral_color = colours.gray;


  // { (user_id === sitter_id) && isInPast && ((status === 'pre_approved') || (status === 'request'))
  //   && <div style={{ width: '100%', padding: 4, borderColor: 'green', borderWidth: 0, borderStyle: 'solid', alignItems: 'center' }}>
  //     <H5 style={{ color: colours.red, textAlign: 'center' }}>Sit Expired - this sit wasn't approved</H5>
  //   </div>
  // }
  //
  // { (user_id === requester_id) && isInPast && ((status === 'pre_approved') || (status === 'request'))
  //   && <div style={{ width: '100%', padding: 4, borderColor: 'green', borderWidth: 0, borderStyle: 'solid', alignItems: 'center' }}>
  //     <H5 style={{ color: colours.red, textAlign: 'center' }}>Sit Expired - this sit wasn't approved</H5>
  //   </div>
  // }

  // { (user_id === requester_id) && isInPast && (status === 'approved')
  //   && <div style={{ width: '100%', padding: 4, borderColor: 'green', borderWidth: 0, borderStyle: 'solid', alignItems: 'center' }}>
  //     <H5 style={{ color: colours.red, textAlign: 'center' }}>Sit in past - waiting for sitter to finalise times</H5>
  //   </div>
  // }
  if (isInPast) {

    if ((status === 'pre_approved') || (status === 'request')) {
      if (user_id === sitter_id) {
        return expired_color; // add BorderColor and textColor colours.red to object
      }
      return expired_color; // textColor colours.red
    }

    if (status === 'approved') {
      if (user_id === sitter_id) {
        return pending_color; // 'Waiting for you to finalise times'
      }
      return pending_color; // 'Waiting for sitter to finalise times'
    }

  }

  if (status === 'new') {
    if (user_id === sitter_id) {
      return error_color; // 'An error has occurred'
    }
    return neutral_color; // `You are making a new request to ${sitter_name}`
  }
  if (status === 'request') {
    if (user_id === sitter_id) {
      return pending_color; // 'This sit needs to be pre-approved by You';
    }
    return pending_color; // `This sit needs to be pre-approved by ${sitter_name}`;
  }
  if (status === 'pre_approved') {
    if (user_id !== sitter_id) {
      return pending_color; // 'This sit needs to be confirmed by You';
    }
    return pending_color; // `This sit needs to be confirmed by ${requester_name}`;
  }
  if (status === 'approved') {
    if (user_id === sitter_id) {
      return confirmed_color; // 'This sit is confirmed';
    }
    return confirmed_color; // 'This sit is confirmed';
  }
  if (status === 'edited') {
    if (user_id === sitter_id) {
      return pending_color; // `This sit has been changed, waiting for You to pre-approve`;
    }
    return pending_color; // `This sit has been changed, waiting for ${sitter_name} to pre-approve`;

  }
  if (status === 'cancelled') {
    return cancelled_color;
  }
  if (status === 'pre_authorised') {
    if (user_id === sitter_id) {
      return pending_color; // `Waiting for ${requester_name} to approve final details`;
    }
    return pending_color; // `You need to approve final details submitted by ${sitter_name}`;
  }
  if (status === 'finalised') {
    if (user_id === sitter_id) {
      return past_color; // `This sitting happended. Final details were approved by ${requester_name}`;
    }
    return past_color; // 'This sitting happended. Final details were approved by You';
  }
  // else
  return error_color; // 'unhandled statusText';

};

export const getOLDUseCaseText = (status, user_id, sitter_id, sitter_name, requester_name, isInPast) => {
  // console.log('status', status);
  // console.log('user_id', user_id);
  // console.log('sitter_id', sitter_id);
  // console.log('sitter_name', sitter_name);
  // console.log('requester_name', requester_name);


  if (isInPast) {

    if ((status === 'new') || (status === 'request') || (status === 'pre_approved')) {
      if (user_id !== sitter_id) {
        return `was available to sit`;
      }
      return `needed a sitter`;
    }

    if ((status === 'approved') || (status === 'cancelled')) {
      if (user_id !== sitter_id) {
        return 'was sitting';
      }
      return 'needed a sitter';
    }

    if (status === 'pre_authorised') {
      if (user_id !== sitter_id) {
        return 'was sitting';
      }
      return 'used you as a sitter';
    }

    if (status === 'finalised') {
      if (user_id !== sitter_id) {
        return `was sitting`;
      }
      return 'used you as a sitter';
    }


  }

  // else if in future

    if ((status === 'new') || (status === 'request') || status === 'edited') {
      if (user_id !== sitter_id) {
        return `is available to sit`;
      }
      return `needs a sitter`;
    }

    if (status === 'pre_approved') {
      if (user_id !== sitter_id) {
        return 'is sitting';
      }
      return 'needs a sitter';
    }

    if (status === 'approved') {
      if (user_id !== sitter_id) {
        return 'is sitting';
      }
      return 'is using you';
    }


    if (status === 'cancelled') {
      if (user_id !== sitter_id) {
        return 'was sitting';
      }
      return 'needed a sitter';
    }


  // else
  return 'unhandled statusText';

};


export const getUseCaseText = (status, user_id, sitter_id, sitter_name, requester_name, isInPast) => {
  console.log('status', status);
  console.log('user_id', user_id);
  console.log('sitter_id', sitter_id);
  console.log('sitter_name', sitter_name);
  console.log('requester_name', requester_name);
  console.log('isInPast', isInPast);


  if (isInPast) {

    if ((status === 'new') || (status === 'request') || (status === 'pre_approved')) {
      if (user_id !== sitter_id) {
        return `You asked ${sitter_name} to host`;
      }
      return `You were asked to host`;
    }

    if ((status === 'approved')) {
      if (user_id !== sitter_id) {
        return `${sitter_name} was hosting`;
      }
      return 'You were hosting';
    }

    if ((status === 'cancelled')) {
      if (user_id !== sitter_id) {
        return 'Cancelled';
      }
      return 'Cancelled';
    }

    if (status === 'pre_authorised') {
      if (user_id !== sitter_id) {
        return `${sitter_name} hosted`;
      }
      return 'You hosted';
    }

    if (status === 'finalised') {
      if (user_id !== sitter_id) {
        return `${sitter_name} hosted`;
      }
      return 'You hosted';
    }


  }

  // else if in future

    if ((status === 'new') || (status === 'request') || status === 'edited') {
      if (user_id !== sitter_id) {
        return `You need a host`;
      }
      return `${requester_name} needs a host`;
    }

    if (status === 'pre_approved') {
      if (user_id !== sitter_id) {
        return `${sitter_name} can host`;
      }
      return 'You can host';
    }

    if (status === 'approved') {
      if (user_id !== sitter_id) {
        return `${sitter_name} is hosting`;
      }
      return 'You are hosting';
    }


    if (status === 'cancelled') {
      if (user_id !== sitter_id) {
        return 'Cancelled';
      }
      return 'Cancelled';
    }

console.log('unhandled');
  // else
  return 'unhandled statusText';

};


export const getStatusUseCaseIcon = (status, user_id, sitter_id, sitter_name, requester_name, isInPast) => {
  // console.log('status', status);
  // console.log('user_id', user_id);
  // console.log('sitter_id', sitter_id);
  // console.log('sitter_name', sitter_name);
  // console.log('requester_name', requester_name);


  if (isInPast) {

    if ((status === 'new') || (status === 'request') || (status === 'pre_approved')) {
      if (user_id !== sitter_id) {
        return imgHaveYouSitter; // `was available to sit`;
      }
      return imgAreyouSitting; // `needed a sitter`;
    }

    if ((status === 'approved') || (status === 'cancelled')) {
      if (user_id !== sitter_id) {
        return imgHaveYouSitter; // 'was sitting';
      }
      return imgAreyouSitting; // 'needed a sitter';
    }

    if (status === 'pre_authorised') {
      if (user_id !== sitter_id) {
        return imgHaveSitter; // 'was sitting';
      }
      return imgSitting; // 'used you as a sitter';
    }

    if (status === 'finalised') {
      if (user_id !== sitter_id) {
        return imgHaveSitter; // `was sitting`;
      }
      return imgSitting; // 'used you as a sitter';
    }


  }

  // else if in future

    if ((status === 'new') || (status === 'request') || status === 'edited') {
      if (user_id !== sitter_id) {
        return imgHaveYouSitter; // `is available to sit`;
      }
      return imgAreyouSitting; // `needs a sitter`;
    }

    if (status === 'pre_approved') {
      if (user_id !== sitter_id) {
        return imgHaveYouSitter; // 'is sitting';
      }
      return imgAreyouSitting; // 'needs a sitter';
    }

    if (status === 'approved') {
      if (user_id !== sitter_id) {
        return imgHaveSitter; // 'is sitting';
      }
      return imgSitting; // 'is using you';
    }


    if (status === 'cancelled') {
      if (user_id !== sitter_id) {
        return imgHaveYouSitter; // 'was sitting';
      }
      return imgAreyouSitting; // 'needed a sitter';
    }


  // else
  return 'unhandled statusText';

};


export default getStatusText;
