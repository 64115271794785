import React, { Component } from 'react';
import { ReportGA } from '../../utils/ga';
import Section from '../general/section';
import Collapse from '../general/collapse';
import SearchBannerContainer from '../../containers/search/search-banner';
import { NAV } from '../../constants/ga-category';



class SearchbarTop extends Component {


  componentDidUpdate () {

    const { scrolledToSearchBanner, scroll_to_banner, searchBannerRef } = this.props;

    if (scroll_to_banner) {
      this.scrollToTop(searchBannerRef);
      scrolledToSearchBanner(); // put in redux to ensure correct scroll behaviour when clicking Book a Sit button.
    }

  }

  componentWillUnmount () {
    // const { hideSearchBanner } = this.props;
    // hideSearchBanner(); // ?? required for hiding searchbanner when changing screen?
    // do not do here as will hide when completing a search.
  }

  scrollToTop = (searchBannerRef) => {
    console.log('this.searchBannerRef:', searchBannerRef);
    if (searchBannerRef.current !== null) {
      searchBannerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleSearch = () => {
    const { showSearchBanner } = this.props;
    // show search banner then
    console.log('searchBar handleSearch:');
    // console.log('location history.location.pathname:', history.location.pathname);
    this.scrollToTop();
    showSearchBanner();

    ReportGA({
      category: NAV,
      action: `User clicked Search button on Nav Menu`
    });
  }


  render () {
    const { searchBannerIsOpen } = this.props;
    console.log('collapse RRR searchbar render searchBannerIsOpen: CHANGE', searchBannerIsOpen);


    return (

      <Collapse
        parentComp='searchbarTop'
        zIndex={3000} // set from 3 to 3000 to ensure dateValue picker is above Dashboard cards
        isOpen={searchBannerIsOpen}
      >
        <Section noBottomPadding fullWidth>

          <SearchBannerContainer />

        </Section>
      </Collapse>


    );


  }
}

export default SearchbarTop;
