import React, { useState } from 'react';
import { Placeholder } from 'semantic-ui-react';

const NavbarImage = (props) => {

  const [isImageLoaded, setImageIsLoaded] = useState(false);


  return (
    <div style={{ position: 'relative' }}>
      {!isImageLoaded &&
        <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0
          // borderWidth: 1, borderColor: 'red', borderStyle: 'solid'
        }}>
          <Placeholder fluid
            style={{ height: '100%', width: '100%'
            // borderWidth: 1, borderColor: 'blue', borderStyle: 'solid'
          }}>
            <Placeholder.Image />
          </Placeholder>
        </div>

      }
      <img
        {...props}
        onLoad={() => setTimeout(() => setImageIsLoaded(true), 10)}
        alt={props.alt || 'image'}
        style={{ ...props.style, visibility: isImageLoaded ? 'visible' : 'hidden' }}


      />
    </div>

  );

};

export default NavbarImage;
