import React from 'react';
import { REFERRAL_SIGNUP, REFERRAL_FIRSTSIT, REFERRALS_SIGNUP_LIMIT, REFERRALS_FIRSTSIT_LIMIT } from '../constants/bonuses';

const single_sign_up = REFERRAL_SIGNUP;
// const profile = 50;
const sit = REFERRAL_FIRSTSIT;
const x_families = REFERRALS_SIGNUP_LIMIT;
const sp_families = (REFERRAL_SIGNUP * REFERRALS_SIGNUP_LIMIT) + (REFERRAL_FIRSTSIT * REFERRALS_FIRSTSIT_LIMIT); // 1000


export const refer = {
  page_title: `Refer Friends!`,
  page_intro: <>Give your friends <strong>3 months free</strong> membership!</>,

  // para1: `*after they complete their first sit `,

  // para2a: `Offer ends 31st May 2023`,
  para2b: `Full details `,
  read_more_link_text: `here.`,


  link: {
    // subtitle: 'Share this link',
    // para: 'unique link',
    send: '>> Get Invite Link <<',
    copied: 'Copied!'
  },

  social: {
    intro: `Share on social media`,
    intro2: `You'll see a preview before you post`,
    facebook: 'Facebook',
    messenger: 'Messenger',
    twitter: 'Twitter',
    email: 'Email'
  },

  skip_for_now: `Skip for now`,

  full_details: {
    intro: {
        subtitle: 'By refering a family, you could earn',
        bullets: [
          `${single_sign_up} SP when they sign up.`,
          `${sit} SP when completing their first sit.`,
          `Up to ${x_families} families (${sp_families} SP).`
        ]
    },
  }

};


export default refer;
