import update from 'immutability-helper';
import * as account from '../actions/auth/account';


export const initialState = {
  tariff_details: {},
  isFetchingTariff: false
};


export default function tariffReducer (state = initialState, action) {
  switch (action.type) {


    case account.GET_TARIFF_REQUEST:
      return {
        ...state,
        isFetchingTariff: true
      };
    case account.GET_TARIFF_SUCCESS:
      return {
        ...state,
        tariff_details: action.data,
        isFetchingTariff: false
      };
    case account.GET_TARIFF_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetchingTariff: false
      };

    default:
      return state;
  }
}
