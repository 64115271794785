import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Modal,
    Button,
    Header
} from "semantic-ui-react";
import { hideModal } from '../../actions/modal';
// import Modal from '../../components/modals/modal';
import { H1, A5, H5, H3, ConfirmButtonText } from '../../styles/text';
import { ConfirmButton, Row } from '../../styles/styles';
import colours from '../../styles/colours';
import { plan, modal, other } from '../../text';
import Spinner from '../../components/general/spinner';
import ModalHeader from '../../components/general/modal-header';
import ModalFailureIndicator from '../general/modal-failure-indicator';
import Paragraph from '../../components/general/paragraph';

const ErrorModal = ({

  afterClose, hideModal, title, error_status, error_message, header, message, message2 }) => {

  // const modalTitle = title || modal.error.title;
  // const modalHeader = header || modal.error.header;
  const modalMessage = message || modal.error.message;
  const modalMessage2 = message2;

  console.log('error_message:', error_message);

  const onClose = () => {
    hideModal();

    if (afterClose) {
      afterClose();
    }
  };

  return (

    <Modal
      // closeIcon='close'
      open
      // onClose={onClose}
      style={{ maxWidth: 500 }}
    >
      <Modal.Content>
        {
          // <Modal.Header>{modalTitle}</Modal.Header>

          //   <Modal.Description>
        }
        {
          // <Header>
          //
          //   <H1 style={{ textAlign: 'center' }}>{modalHeader}</H1>
          //
          // </Header>
        }
        <ModalHeader>{header}</ModalHeader>

        <div style={{ paddingBottom: 8, display: 'flex', background: 'transparent', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

          <ModalFailureIndicator

          />

        </div>

        <div style={{ flexDirection: 'column', paddingBottom: 16, display: 'flex', background: 'transparent', justifyContent: 'center', alignItems: 'center' }}>

          <div style={{ marginBottom: 24, maxWidth: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {
              error_message !== 'Failed to fetch'
              && <Paragraph>

                <A5 textAlign='center'>{modalMessage}</A5>

              </Paragraph>
            }

            { modalMessage2
              && <Paragraph>

                <A5 textAlign='center'>{modalMessage2}</A5>

              </Paragraph>
            }

            { error_message === 'Failed to fetch'
              && <Paragraph>

                <A5 textAlign='center'>{modal.error.error_connection}</A5>

              </Paragraph>
            }

            { error_message && error_message !== 'Failed to fetch'
              && <Paragraph>

                <A5 textAlign='center'>{error_message}</A5>

              </Paragraph>
            }

          </div>

          <Button
            primary

            style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
            onClick={onClose}
          >
            {modal.error.ok}
          </Button>

        </div>


      </Modal.Content>

    </Modal>

  );
};

ErrorModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default connect(null, { hideModal })(ErrorModal);
