import React from 'react';
import colours from '../../styles/colours';

const CardSection = ({ borderTop, fullWidth, noTopPadding, noBottomPadding, noSidePadding, window_width, children, backgroundColor }) => {

    return (
      <div style={{ marginLeft: 0, marginRight: 0, paddingLeft: fullWidth ? 12 : 0, paddingRight: fullWidth ? 12 : 0, overflow: 'wrap', width: '100%', flexDirection: 'column', paddingTop: noTopPadding ? 0 : 16, paddingBottom: noBottomPadding ? 0 : 16, background: backgroundColor, borderColor: colours.borderColour, borderWidth: 0, borderTopWidth: borderTop ? 1 : 0, borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        { children }
      </div>
    );
};

export default CardSection;
