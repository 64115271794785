import moment from 'moment';
// import 'moment/locale/fr';
import 'moment/locale/en-gb';

moment.locale('en-gb');
const hmm = moment(1316116057189).fromNow();
console.log('hmm:', hmm);
const hmmWd = moment.weekdaysShort(true);
console.log('hmmWd:', hmmWd);

export const dateRangeOverlaps = (a_start, a_end, b_start, b_end) => {

    // console.log('a_start:', a_start);
    // console.log('a_end:', a_end);
    // console.log('b_start:', b_start);
    // console.log('b_end:', b_end);

    if (a_start === b_end) return false; // not a clash if one starts at the same time the other one ends
    if (b_start === a_end) return false; // not a clash if one starts at the same time the other one ends

    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
    if (b_start < a_start && a_end < b_end) return true; // a in b

    return false;
};

export const isDateTimeInPast = (date, time) => {

  // console.log('date:', date);
  // console.log('time:', time);

    // if 2 args, consolidate, else just add seconds
    const dateTimeString = time ? `${date} ${time}:00` : `${date}:00`;

    // if 2 args, amend format
    const comparisonMoment = moment(dateTimeString, time ? 'DD/MM/YYYY HH:mm:ss' : 'YYYY-MM-DD HH:mm:ss');
    const now = moment();

    // console.log('comparisonMoment:', comparisonMoment);
    // console.log('now:', now);

    const isBefore = moment(comparisonMoment).isBefore(now);

    // console.log('isBefore:', isBefore);
    // return object
    return isBefore;

};

export const getTimestamp = (date, hr, min) => {
  // console.log('date:', date); // DD-MM-YYYY
  // console.log('hr:', hr); // HH
  // console.log('min:', min); // mm
  const dateTimeString = `${date}T${hr}:${min}`;
  // console.log('dateTimeString:', dateTimeString);
  const timestamp = moment(dateTimeString, 'DD-MM-YYYYTHH:mm').format('YYYY-MM-DD HH:mm:ss');
  // console.log('timestamp:', timestamp);
  return timestamp;
};

// export const makeTodFromHrDate = (hr, date) => {
//
//   // console.log('hr: ', hr);
//   // console.log('given_date: ', date);
//   const formattedDate = moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY');
//   // console.log('formattedDate: ', formattedDate);
//   // console.log('hr is less than 6', hr < 6);
//   // console.log('hr is less than 12', hr < 12);
//   // console.log('hr is less than 18', hr < 18);
//   // console.log('hr is less than 24', hr < 24);
//
//   // may need to factor in minutes.  e.g. if 12noon, will show as AM, but it should be less than 11.59 and below
//
//   switch (true) {
//     case (hr < 6):
//       return { date: formattedDate, tod: { todIndex: 0, tod: 'on' } };
//     case (hr < 12):
//       return { date: formattedDate, tod: { todIndex: 1, tod: 'am' } };
//     case (hr < 18):
//       return { date: formattedDate, tod: { todIndex: 2, tod: 'pm' } };
//     case (hr < 24):
//       return { date: formattedDate, tod: { todIndex: 3, tod: 'ev' } };
//     default:
//       return {};
//   }
// };

// export const getTodFromHr = (hr) => {
//
//   // console.log('hr is less than 6', hr < 6);
//   // console.log('hr is less than 12', hr < 12);
//   // console.log('hr is less than 18', hr < 18);
//   // console.log('hr is less than 24', hr < 24);
//
//   // may need to factor in minutes.  e.g. if 12noon, will show as AM, but it should be less than 11.59 and below
//
//   switch (true) {
//     case (hr < 6):
//       return 'on';
//     case (hr < 12):
//       return 'am';
//     case (hr < 18):
//       return 'pm';
//     case (hr < 24):
//       return 'ev';
//     default:
//       return {};
//   }
// };

// export const getAllAvailabilityObjects = (start_date, start_time, end_date, end_time, bookingParams) => {
//
//     // returns all availabilityObjects between start date/time and end date/time
//
//
//     const startDateTimeString = `${start_date} ${start_time}:00`;
//     const startMoment = moment(startDateTimeString, 'DD/MM/YYYY HH:mm:ss');
//
//     const endDateTimeString = `${end_date} ${end_time}:00`;
//     const endMoment = moment(endDateTimeString, 'DD/MM/YYYY HH:mm:ss');
//
//     // console.log('XstartMoment:', startMoment);
//     // console.log('endMoment:', endMoment);
//
//
//     const currentMoment = startMoment;
//     const objectArray = [];
//
//     while (moment(endMoment).isAfter(currentMoment)) {
//       // console.log('currentMoment', currentMoment);
//       // console.log('endMoment', endMoment);
//       // console.log('currentMoment isNotAfter endMoment:', moment(endMoment).isAfter(currentMoment));
//       // console.log('XcurrentMoment', currentMoment);
//       const currentMomentYear = currentMoment.year();
//       const currentMomentYearString = currentMomentYear.toString();
//       const formattedCurrentMonth = moment(currentMoment).format('MMMM');
//       const currentMomentDate = currentMoment.date();
//       // console.log('XcurrentMomentDate', currentMomentDate);
//       const currentMomentHr = currentMoment.hour();
//
//       const currentTod = getTodFromHr(currentMomentHr);
//
//       const newObject = { year: currentMomentYearString, month: formattedCurrentMonth, day: currentMomentDate, time: currentTod, booking_params: bookingParams };
//       objectArray.push(newObject);
//
//
//       currentMoment.add(6, 'hours');
//     }
//
//     return objectArray;
//
// };

export const getSitDuration = (start_date, end_date, start_time, end_time) => {

  const useEndDate = !end_date ? start_date : end_date;

  const startString = `${start_date} ${start_time}:00`;
  const endString = `${useEndDate} ${end_time}:00`;

  const startMoment = moment(startString, 'DD/MM/YYYY HH:mm:ss');
  const endMoment = moment(endString, 'DD/MM/YYYY HH:mm:ss');

  const durationMins = endMoment.diff(startMoment, 'minutes');

  const minutesDuration = moment.duration(durationMins, 'minutes');

  const asHours = minutesDuration.asHours();

  const hours = asHours > 23 ? Math.floor(minutesDuration.asHours()) : minutesDuration.hours();
  const minutes = minutesDuration.minutes();

  const timeQuote = `${hours < 1 ? '' : `${hours} ${hours === 1 ? 'hour' : 'hours'}`}${((minutes > 0) && (hours > 0)) ? ' and ' : ''}${minutes > 0 ? `${minutes} minutes` : ''}`;

  return timeQuote;

};

export const normalizeDob = (val, prevVal) => {
    // Prevent non-digit characters being entered
    if (isNaN(parseInt(val[val.length - 1], 10))) {
        return val.slice(0, -1);
    }

    // When user is deleting, this prevents immediate re-addition of '/' when it's deleted
    if (prevVal && (prevVal.length >= val.length)) {
        return val;
    }

    // Add / at appropriate sections of the input
    if (val.length === 2 || val.length === 5) {
        val += '/';
    }

    // Prevent characters being entered after Dob is full
    if (val.length >= 10) {
        return val.slice(0, 10);
    }

    return val;
};

// cal functions

export const getDateNo = (dateContext) => {
    return dateContext.format('DD');
};

export const getDay = (dateContext) => {
    return dateContext.format('ddd');
};

export const getYear = (dateContext) => {
    return dateContext.format('Y');
};

export const getMonth = (dateContext) => {
    return dateContext.format('MMMM');
};

export const getMonthNo = (dateContext) => {
    return dateContext.format('MM');
};

export const getDate = (dateContext) => {
    return dateContext.format('YYYY-MM-DD');
};

export const daysInMonth = (dateContext) => {
    return dateContext.daysInMonth();
};

export const currentDate = (dateContext) => {
    // console.log('currentDate: ', this.state.dateContext.get('date'));
    return dateContext.get('date');
};

export const currentDay = (dateContext) => {
    return dateContext.format('D');
};

export const todaysDate = (today) => {
    // console.log('todaysDate: ', this.state.today.get('date'));
    return today.get('date');
};

export const todaysMonth = (today) => {
    // console.log('todaysMonth: ', this.state.today.format('MMMM'));
    return today.format('MMMM');
};

export const todaysYear = (today) => {
    // console.log('todaysYear: ', this.state.today.format('Y'));
    return today.format('Y');
};

export const todaysDay = (today) => {
    return today.format('D');
};

export const isBefore = (date, comparisonDate) => {
  return moment(date).isBefore(comparisonDate);
};

export const isInPast = (date, today) => {
  return moment(date).isBefore(today);
};

export const firstDayOfMonth = (dateContext) => {
    const firstDay = moment(dateContext).startOf('month').format('d'); // Day of week 0...1..5...6
    return firstDay;
};

export const lastDayOfMonth = (dateContext) => {
    const lastDay = moment(dateContext).endOf('month').format('d'); // Day of week 0...1..5...6
    return lastDay;
};

export const firstDateOfMonth = (dateContext) => {
    const firstDate = moment(dateContext).startOf('month').format('DD/MM/YYYY');
    return firstDate;
};

export const lastDateOfMonth = (dateContext) => {
    const lastDate = moment(dateContext).endOf('month').format('DD/MM/YYYY');
    return lastDate;
};

export const weekdays = moment.weekdays(true); // add true to make Monday first (or whatever start day is in that locale) ["Sunday", "Monday", "Tuesday", "Wednessday", "Thursday", "Friday", "Saturday"]

export const weekdaysShort = moment.weekdaysShort(true); // // add true to make Monday first (or whatever start day is in that locale) ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
console.log('weekdaysShort:', weekdaysShort);
export const months = moment.months();

export const validateDates = (parameters) => {
  console.log('parameters:', parameters);
  const { saved_start_date, saved_start_hr, saved_start_min, saved_end_date, saved_end_hr, saved_end_min } = parameters;
  const dateNow = moment();
  console.log('dateNow:', dateNow);
  const hrNow = moment().format('HH');
  const minNow = moment().format('mm');
  // console.log('dateNow:', dateNow);
  // console.log('hrNow:', hrNow);
  // console.log('minNow:', minNow);
  const dayDateNow = moment(dateNow).format('DD-MM-YYYY');
  const dayDateNowMoment = moment(dayDateNow, 'DD-MM-YYYY');

  if (!saved_end_date) {
    // no end date, then must be same day

    if (parseInt(`${saved_end_hr}${saved_end_min}`, 10) <= parseInt(`${saved_start_hr}${saved_start_min}`, 10)) {
      // if not after start time, return false.
      console.log('1');
      return false;
    }

  }
  if (moment(saved_end_date, 'DD-MM-YYYY').isBefore(moment(saved_start_date, 'DD-MM-YYYY'))) {
    console.log('2');
    return false;
  }
  if (!moment(saved_end_date, 'DD-MM-YYYY').isAfter(moment(saved_start_date, 'DD-MM-YYYY'))) {
    // not after so must be same day
    if (parseInt(`${saved_end_hr}${saved_end_min}`, 10) <= parseInt(`${saved_start_hr}${saved_start_min}`, 10)) {
      // if not after start time, return false.
      console.log('3');
      return false;
    }
  }

  // what about in editMode/finalisation???
  // dates in Past
  if (moment(saved_start_date, 'DD-MM-YYYY').isBefore(dayDateNowMoment)) { // start date prior day

    console.log('4');
    return false;
  }
  if (moment(saved_start_date, 'DD-MM-YYYY').isSameOrBefore(moment(dayDateNowMoment))) { // start date is today
    console.log('same day');
    if (parseInt(`${saved_start_hr}${saved_start_min}`, 10) <= parseInt(`${hrNow}${minNow}`, 10)) { // start time is past
      console.log('5');
      return false;
    }
  }


  return true;
};

export default isDateTimeInPast;
