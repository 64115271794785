import React from 'react';
import { ReportGA } from '../../utils/ga';
import { LinkButton } from '../../styles/buttons';
import { store } from '../../init-store';
import colours from '../../styles/colours';
import history from '../Navigation/history';
import { filterRelevantActions, calcNextAction } from '../../lib/nextActionHelpers';
import Card from '../general/card';
import CardHeader from '../general/card-header';
import ParaBullet from '../general/para-bullet';
import Image from '../general/image';
import Paragraph from '../general/paragraph';
import CTAImage from '../../images/CTA_Important_info.min.svg';
import { dashboard } from '../../text';
import { CTA } from '../../constants/ga-category';
import * as ROUTES from '../../constants/routes';
import { getMessageThread } from '../../actions/messages';

const CallToActionBanner = (props) => {
  const { user_id, allThreads, profileIncomplete, showVerifyEmail, resendVerifyEmail, showCompleteAvailability } = props;

  // filter Threads to only those not yet finalised, and not already cancelled
  const nonFinalThreads = filterRelevantActions(allThreads, user_id);
  console.log('CTABanner nonFinalThreads:', nonFinalThreads);

  // filter only booked sitters
  const threadsRequiringThisUserAction = nonFinalThreads.map((thread) => {

    const nextActionObject = calcNextAction(thread, user_id);

    return nextActionObject;
  });
  console.log(`threadsRequiringThisUserAction: `, threadsRequiringThisUserAction);

  const relevant_thread_actions = threadsRequiringThisUserAction;


  const handleClickResendEmail = () => {

      ReportGA({
        category: CTA,
        action: `User pressed Resend Verify Email button on CTA Card`
      });

      resendVerifyEmail();

  };

  const handleClickCompleteProfile = () => {
      ReportGA({
        category: CTA,
        action: `User pressed Complete Profile button on CTA Card`
      });
      history.push({
        pathname: ROUTES.PROFILE_SUMMARY,
        state: { editMode: false }
      });
  };

  const handleClickAddAvailability = () => {
    // does not fire if use "to" rather than onClick - use button instead of Link to record to analytics that routed from CTA rather than somewhere else 
      ReportGA({
        category: CTA,
        action: `User pressed Add Availability button on CTA Card`
      });
      
      history.push({
        pathname: ROUTES.AVAILABILITY
      });
  };


  const handleGetMessageThread = (message_thread_id) => {

    ReportGA({
      category: CTA,
      action: `User pressed on thread action button on CTA Card`
    });

    const token = localStorage.sitterpoint_token;

    if (token) {
      store.dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));

    }
  };

  const core_actions = [];

  if (showVerifyEmail) {
    core_actions.push({
      id: `resend_email`,
      onClick: handleClickResendEmail,
      text: `Confirm your email (go to your email and click the link we sent you) or`,
      secondary_link_text: dashboard.cta.click_here_to_resend
    });
  }

  if (profileIncomplete) {
    core_actions.push({
      id: `complete_profile`,
      onClick: handleClickCompleteProfile,
      link_text: `Complete`,
      text: `your profile`
    });
  }

  if (showCompleteAvailability) {
    core_actions.push({
      id: `add_availability`,
      onClick: handleClickAddAvailability,
      link_text: `Add some availability`, // or reply to?
      text: `or mark yourself as`,
      secondary_link_text: `flexible`
    });
  }

  const relevant_actions = [
    ...core_actions,
    ...relevant_thread_actions
  ];


  const handleClick = (item) => {

      console.log('CTA handleClick item:', item);

      if (!item.thread_id) {
        if (!item.route) {
          // if no thread & no route
          ReportGA({
            category: CTA,
            action: `User pressed CTA banner on Dashboard, on item: ${item.id}`
          });
          item.onClick();

        } else {
          // if no thread_id, but is route
          ReportGA({
            category: CTA,
            action: `User pressed CTA banner on Dashboard, on item: ${item.id}`
          });
          history.push(item.route);
        }
      } else {
        // if is thread_id, push thread
        // getMessageThread
        handleGetMessageThread(item.thread_id);
      }

  };

  const renderRelevantActions = relevant_actions.map((item) => {
    return (
      <ParaBullet key={item.id} color={colours.white}>
        <Paragraph color={colours.white}>
          {
            item.pre_link_text &&
            item.pre_link_text
          }
          {
            item.link_text &&
            <>
              <LinkButton
                // if no route, use LinkButton (button) instead of Link
                color={colours.white}
                hoverColor={colours.offWhite}
                underline
                onClick={() => handleClick(item)}
              >
                <strong>{item.link_text}</strong>
              </LinkButton>
              &nbsp;
            </>
          }
          {item.text}
          {
            item.secondary_link_text &&
            <>
              &nbsp;
              <LinkButton
                // if no route, use LinkButton (button) instead of Link
                color={colours.white}
                hoverColor={colours.offWhite}
                underline
                onClick={() => handleClick(item)}
              >
                <strong>{item.secondary_link_text}</strong>
              </LinkButton>
            </>
          }
        </Paragraph>
      </ParaBullet>
    );
  });

  // console.log('CTA renderRelevantActions:', renderRelevantActions);
  // console.log('CTA profileCompleteExceptForPendingDocs:', profileCompleteExceptForPendingDocs);
  if (renderRelevantActions.length === 0) {
    return null;
  }

  return (

    <Card backgroundColor={colours.cta} borderWidth={2} borderColor={colours.cta}>

      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'center', alignItems: 'center' }}>

        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
          <div style={{ paddingTop: 0, paddingBottom: 0, width: 80, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Image
              noDelay
              alt='main'
              style={{ height: '100%', objectFit: 'cover' }}
              src={ CTAImage }
            />
          </div>
        </div>

        <div style={{ }}>

          <CardHeader paddingBottom='0px' backgroundColor={colours.cta} color={colours.white} alignTitle='center' title={dashboard.cta.important} />

          <Paragraph color={colours.white} textAlign='center'>
            {dashboard.cta.you_need_to}
          </Paragraph>

        </div>

        <div style={{ flex: 1 }} />

      </div>


      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ul style={{ textAlign: 'center', maxWidth: 400, marginTop: 0, marginBottom: 0, paddingLeft: 30, paddingRight: 20 }}>
          {
            renderRelevantActions
          }
        </ul>
      </div>

    </Card>

  );

};

export default CallToActionBanner;
