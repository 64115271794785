import * as index from '../actions/register/index';
import update from 'immutability-helper';
import * as reviewActions from '../actions/review';

export const initialState = {
  rating: undefined,
  review: '',
  isSubmittingRating: false

};

export default function review (state = initialState, action) {
  switch (action.type) {

    case reviewActions.SET_RATING:

      return {
        ...state,
        rating: action.data
      };

    case reviewActions.SET_REVIEW:

      return {
        ...state,
        review: action.data
      };

    case reviewActions.SUBMIT_REVIEW_REQUEST:
      return {
        ...state,
        isSubmittingRating: true
      };

    case reviewActions.SUBMIT_REVIEW_SUCCESS:
      return {
        ...state,
        isSubmittingRating: false,
        review: '',
        rating: undefined
      };
    case reviewActions.SUBMIT_REVIEW_FAILURE:
      return {
        ...state,
        isSubmittingRating: false,
        error: action.error
      };


    default:
      return state;
  }
}
