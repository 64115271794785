import Bugsnag from '@bugsnag/js';
import { persistor } from '../../init-store';
import { storeToken, storeUserId } from '../../lib/credentials';
import history from '../../components/Navigation/history';
// import * as ROUTES from '../../constants/routes';
// import initSocket from '../socket-router';
import { actionSuccess } from '../../actions/verify';
import { showModal, hideModal } from '../../actions/modal';
// import { getAPIURI } from '../../constants/env';
import { login as loginText } from '../../text';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR } from '../../components/modals/modalTypes';


// require('dotenv').config(); // no longer needed in new version of node // can cause bugs

// const API_ENDPOINT = getAPIURI();
// console.log(`${API_ENDPOINT}/login`);

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_RESET = 'LOGIN_USER_RESET';
export const LOGOUT = 'LOGOUT';


export const loginUserRequest = () => ({
  type: LOGIN_USER_REQUEST
});

export const loginUserSuccess = data => ({
  type: LOGIN_USER_SUCCESS,
  data
});

export const loginUserFailure = error => ({
  type: LOGIN_USER_FAILURE,
  error
});

export const loginUserReset = () => ({
  type: LOGIN_USER_RESET
});

export function loginUser (email, password) {

  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: loginText.modal.fetching
      }
    ));

    dispatch(loginUserRequest());
    console.info(`${process.env.REACT_APP_API_URI}/login`);
    fetch(`${process.env.REACT_APP_API_URI}/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    })
    .then(CheckError)
    .then((data) => {
      console.log('data:', data);
      if (data.token && data.user_id) {

        storeUserId(data.user_id);
        storeToken(data.token);
        console.log('token stored: ', data.token); // may need to make synchronous and wait for result before going to dashboard

        dispatch(loginUserSuccess({
          firstname: data.firstname,
          surname: data.surname,
          email: data.email,
          email_verified: data.email_verified,
          user_id: data.user_id,
          is_admin: data.is_admin,
          photo_url: data.photo_url,
          home_address: data.home_address,
          kids: data.kids,
          adults: data.adults,
          got_it: data.got_it,
          sp: data.sp,
          profile_progress: data.profile_progress,
          profile_complete_count: data.profile_complete_count,
          member_since: data.member_since,
          sp_balance: data.sp_balance,
          sp_pending: data.sp_pending,
          payments: data.payments,
          family_description: data.family_description,
          subscription_status: data.subscription_status,
          subscription_id: data.subscription_id,
          plan: data.plan,
          customer_id: data.customer_id,
          cancel_at_period_end: data.cancel_at_period_end,
          cancel_at: data.cancel_at,
          cancelled_at: data.cancelled_at,
          current_period_end: data.current_period_end,
          verified_status: data.verified_status,
          referral_code: data.referral_code,
          referred_by: data.referred_by,
          referrals: data.referrals,
          sit_approved_req_count: data.sit_approved_req_count,
          sit_approved_sit_count: data.sit_approved_sit_count
          // revs_array: data.revs_array  does not exist in user table - so won't be brought with login - fetch seperately on pages needing reviews
          // available_dates: data.available_dates, replaced in calendar reducer

        }));
        

        Bugsnag.setUser(data.user_id, data.email, `${data.firstname} ${data.surname}`);

        // initSocket();
        console.log('history: ', history);
        history.push('dashboard');
        dispatch(hideModal());

        persistor.persist(); // moved to end to after state is updated


      } else {
        // throw error
        throw new CustomException(null, data.error);
      }
    })

    .catch((err) => {

      // console.log('caught loginUser error: ', err.code);
      // console.log('caught loginUser error: ', err.message);

      dispatch(loginUserFailure(err.message));

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: loginText.modal.failure.title,
          header: loginText.modal.failure.header,
          message: loginText.modal.failure.para,
          // message2: loginText.modal.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });
  };
}


export const logout = () => ({
  type: LOGOUT
});
