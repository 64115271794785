import React from 'react';
import colours from '../../styles/colours';

const Avatar = ({ size, src, alt }) => {

    // calc('100%' - 24)???
    const avatar_size = size || 100;

    return (

        <div style={{ width: avatar_size, position: 'relative', display: 'block', paddingBottom: '100%' }}>
          <div style={{

            // width: 100,
            // height: 100,
            // position: relative;
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            position: 'absolute',
            objectFit: 'cover',
            borderRadius: '50%',
            borderWidth: 2,
            borderColor: colours.mediumlightpurple,
            borderStyle: 'solid',

            width: '100%',
            height: '100%'
          }}>
            <img

              alt='user_photo'
              src={src}
              style={{
                display: 'inline',
                width: 'auto',
                height: '100%',
                margin: '0 auto',
                objectFit: 'cover'
              }}
            />


          </div>
        </div>


    );
};

export default Avatar;
// <div
//   style={{
//         borderRadius: '50%',
//         marginTop: 0,
//         // border: '1px solid black',
//         // marginBottom: 20,
//         height: avatar_size,
//         width: 60,
//         overflow: 'hidden'
//     }}>
//
//     <img
//       alt={alt}
//       src={src}
//       style={{
//       objectFit: 'cover',
//       width: '100%',
//       height: '100%',
//       display: 'block',
//     }} />
//   </div>
