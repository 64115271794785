import * as dropdownhour from '../actions/general/dropdown-hourValue';

export const initialState = {
  isHourFromOpen: false,
  isHourToOpen: false,
  isKidsOpen: false
};

export default function dropdownHourValue (state = initialState, action) {
  switch (action.type) {


    case dropdownhour.TOGGLE_DROPDOWN_HOUR_FROM:
      return {
        ...state,
        isHourFromOpen: action.data
      };

    case dropdownhour.TOGGLE_DROPDOWN_HOUR_TO:
      return {
        ...state,
        isHourToOpen: action.data
      };

    case dropdownhour.TOGGLE_DROPDOWN_KIDS:
      return {
        ...state,
        isKidsOpen: action.data
      };


    default:
      return state;
  }
}
