import React, { Component } from 'react';
import {
    Button
} from "semantic-ui-react";
import { persistor, store } from '../../init-store';
import { hideModal, showModal } from '../../actions/modal';
import { actionSuccess, uploadDocFailure } from '../../actions/verify';
import colours from '../../styles/colours';
import Spinner from '../general/spinner';
import { verify } from '../../text';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';
import { MODAL_TYPE_ERROR } from '../modals/modalTypes';

// import { connectAlert } from '../Alert';

// const { Answers } = Fabric;
const FileUploadButton = ({ onClick, primary, icon, children }) => {
  return (

    <Button
      // animated='vertical'
      icon={icon}
      labelPosition='left'
      primary={primary}

      style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
      onClick={onClick}
      content={children}
    />

  );
};

// const CameraUploadButton = ({ onClick, primary, icon, children }) => {
//   return (
//     <Button
//       htmlFor='file'
//       // animated='vertical'
//       icon
//       labelPosition='left'
//       primary
//
//       style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
//       onClick={onClick}
//     >
//       {children}
//       <Icon name='camera' />
//
//     </Button>
//
//   );
// };


class VerifyFileUploadButton extends Component {


  fileInputRef = React.createRef();


  componentDidMount () {

    console.log(' docUpload didMount this.props', this.props);

    persistor.pause();

  }

  componentWillReceiveProps (nextProps) {
    console.log('this.props:', this.props);
    console.log('nextProps:', nextProps);
    // const { previewFileURL } = this.state;


    // console.log('previewFileURL:', previewFileURL);
    // TO AUTOMATICALLY LOAD AFTER INPUT
    // if (nextProps.selectedFiles && nextProps.selectedFiles !== undefined) { // if not undefined
    //   if (nextProps.selectedFiles !== this.props.selectedFiles) {
    //     this.handleUploadFile(nextProps.selectedFiles);
    //   }
    // }

  }


  // fileChangedHandler = (event) => {
  //   this.setState({ previewFileURL: URL.createObjectURL(event.target.files[0]) });
  //   this.props.setSelectedDocFile(event.target.files[0]);
  //
  // };

//   onInputChange = (e) => {
//     // convert image file to base64 string
//
//     const file = e.target.files[0];
//
//     this.props.setSelectedFiles(file);
//
//     // const reader = new FileReader();
//     //
//     // reader.addEventListener('load', () => {
//     //   // const fileObject = { image: reader.result, name: `photo.${fileName}`, type: file.type };
//     //   this.props.setSelectedFiles(reader.result); // setInputImg(reader.result)
//     // }, false);
//     //
//     // if (file) {
//     //     reader.readAsDataURL(file);
//     // }
//
// }

  onInputChange = (e) => {


      // convert image file to base64 string

      // load spinner until completed
      const { setFileFetching, doc_name } = this.props;
      setFileFetching();

      const file = e.target.files[0];

      console.log('verify input file:', file);
      // file validation

      // check file can be read (so not from Google Drive or is invalid)
      file.slice(0, 1) // only first byte
        .arrayBuffer() // try to read
        .then(() => {
          // success, we should be able to send that file
          console.log('should be able to send file');

          // perform other checks

          // file type
          const t = file.type.split('/').pop().toLowerCase();

          if (doc_name === 'photo') {
            if (t !== "jpeg" && t !== "jpg" && t !== "png" && t !== "bmp" && t !== "gif") {

              store.dispatch(hideModal());
              const err = 'Please choose a an image file of format png, jpg, jpeg, bmp, or gif.';
              // alert(err);
              store.dispatch(uploadDocFailure(err));
              // centralise bugsnag reporting
              ReportError(err);
              store.dispatch(showModal(
                MODAL_TYPE_ERROR,
                { // modalProps
                  title: verify.modal.upload_invalid_file.title,
                  header: verify.modal.upload_invalid_file.header,
                  message: err
                }
              ));

              store.dispatch(actionSuccess());


              return;
            }
          }
          // else
          if (t !== "pdf" && t !== "jpeg" && t !== "jpg" && t !== "png" && t !== "bmp" && t !== "gif") {
            store.dispatch(hideModal());
            const err = 'Please choose a pdf or image file of format pdf, png, jpg, jpeg, bmp, or gif.';
            // alert(err);
            store.dispatch(uploadDocFailure(err));
            // centralise bugsnag reporting
            ReportError(err);
            store.dispatch(showModal(
              MODAL_TYPE_ERROR,
              { // modalProps
                title: verify.modal.upload_invalid_file.title,
                header: verify.modal.upload_invalid_file.header,
                message: err
              }
            ));

            store.dispatch(actionSuccess());

            return;
          }


          // file size
          const fsize = (file.size / 1024 / 1024).toFixed(2);
          if (fsize > 5) {

            store.dispatch(hideModal());
            const err = 'Please choose a file smaller than 5 MB.';
            // alert(err);
            store.dispatch(uploadDocFailure(err));
            // centralise bugsnag reporting
            ReportError(err);
            store.dispatch(showModal(
              MODAL_TYPE_ERROR,
              { // modalProps
                title: verify.modal.upload_invalid_file.title,
                header: verify.modal.upload_invalid_file.header,
                message: err
              }
            ));

            store.dispatch(actionSuccess());

            return;
          }

          // file name
          const fname = (file.name);
          if (!fname) {

            store.dispatch(hideModal());
            const err = 'Please ensure file has a filename.';
            // alert(err);
            store.dispatch(uploadDocFailure(err));
            // centralise bugsnag reporting
            ReportError(err);
            store.dispatch(showModal(
              MODAL_TYPE_ERROR,
              { // modalProps
                title: verify.modal.upload_invalid_file.title,
                header: verify.modal.upload_invalid_file.header,
                message: err
              }
            ));

            store.dispatch(actionSuccess());

            return;
          }

          const reader = new FileReader();

          reader.addEventListener('load', () => {
            // const fileObject = { image: reader.result, name: `photo.${fileName}`, type: file.type };
            this.props.setSelectedFiles(reader.result); // setInputImg(reader.result)

          }, false);

          if (file) {
              reader.readAsDataURL(file);
              this.props.setCurrentFile(file); // to ensure filename displayed prior to upload
          }

        })
        .catch((error) => {
          console.log('failed to read file:', error);
          // e.target.value = null; // remove invalid files

          store.dispatch(hideModal());
          const err = 'File is invalid, please choose another file.  Note: files from cloud storage are not supported, please download to device first, or use your camera.';
          // alert(err);
          store.dispatch(uploadDocFailure(err));
          // centralise bugsnag reporting
          ReportError(err);
          store.dispatch(showModal(
            MODAL_TYPE_ERROR,
            { // modalProps
              title: verify.modal.upload_invalid_file.title,
              header: verify.modal.upload_invalid_file.header,
              message: err
            }
          ));

          store.dispatch(actionSuccess());

        });


  }


  render () {
    const { isFetching, isUploadingDoc, isFetchingSuccess, children } = this.props;


    if (isFetching) {
      return <Spinner />; // if is loading file, then show spinner.
    }


    return (

      <React.Fragment>
        {
          !isUploadingDoc
          && <input
            type='file'
            name='file'
            accept='image/*, application/pdf' // check other options
            hidden

            ref={this.fileInputRef}
            onChange={this.onInputChange}
          />


        }
        {
          // isUploadingDoc && !isFetchingSuccess
          // && <FileUploadLabelVerify htmlFor='file' style={{ background: 'transparent', marginLeft: 0, paddingLeft: 8, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', borderColor: 'green', borderWidth: 0, borderStyle: 'solid' }}>
          //   <ConfirmButtonText style={{ color: colours.main }}>{other.uploading}</ConfirmButtonText>
          //   <SmallSpinner color={colours.darkpurple} />
          // </FileUploadLabelVerify>

        }
        {
          // isUploadingDoc && isFetchingSuccess
          // && <FileUploadLabelVerify htmlFor='file' style={{ background: 'transparent', marginLeft: 0, paddingLeft: 8, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', borderColor: 'green', borderWidth: 0, borderStyle: 'solid' }}>
          //   <ConfirmButtonText style={{ color: colours.main }}>{other.success}</ConfirmButtonText>
          //   <FontAwesomeIcon
          //     icon={faCheckCircle}
          //     size='lg'
          //     style={{ paddingRight: 4, color: colours.green }} />
          // </FileUploadLabelVerify>

        }

        {
          <FileUploadButton
            loading={isUploadingDoc && !isFetchingSuccess}
            icon={isUploadingDoc && isFetchingSuccess ? 'check' : 'file image'}
            onClick={() => this.fileInputRef.current.click()}
          >
            {children}
          </FileUploadButton>

        }

      </React.Fragment>

    );
  }
}

export default VerifyFileUploadButton;
// export default connectAlert(hoistNonReactStatic(decoratedComponent, Signup));
