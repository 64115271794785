import { store } from '../../init-store';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_UPLOAD_PROGRESS, MODAL_TYPE_ERROR, MODAL_TYPE_CONFIRM_UPLOAD_TYPE, MODAL_TYPE_UPLOAD_SELFIE } from '../../components/modals/modalTypes';
import { CONFIRM_APPROVE, CONFIRM_CANCEL, CONFIRM_PREAPPROVE, CONFIRM_CHANGEPLAN, CONFIRM_UPLOAD_TYPE } from '../../components/modals/modalNames';
import { UPLOAD } from '../../components/modals/modalCategory';
import { showModal, hideModal } from '../modal';
import { upload_photo } from '../../text/register';
import { verify } from '../../text';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';


export const EDIT_NAME_REQUEST = 'EDIT_NAME_REQUEST';
export const EDIT_NAME_SUCCESS = 'EDIT_NAME_SUCCESS';
export const EDIT_NAME_FAILURE = 'EDIT_NAME_FAILURE';
export const CHANGE_NAME = 'CHANGE_NAME';
export const UPLOAD_PHOTO_REQUEST = 'UPLOAD_PHOTO_REQUEST';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAILURE = 'UPLOAD_PHOTO_FAILURE';
export const LOGOUT = 'LOGOUT';
export const GOT_IT_WHATS_NEW_REQUEST = 'GOT_IT_WHATS_NEW_REQUEST';
export const GOT_IT_WHATS_NEW_SUCCESS = 'GOT_IT_WHATS_NEW_SUCCESS';
export const GOT_IT_WHATS_NEW_FAILURE = 'GOT_IT_WHATS_NEW_FAILURE';
export const UPDATE_OPEN_NO_REQUEST = 'UPDATE_OPEN_NO_REQUEST';
export const UPDATE_OPEN_NO_SUCCESS = 'UPDATE_OPEN_NO_SUCCESS';
export const UPDATE_OPEN_NO_FAILURE = 'UPDATE_OPEN_NO_FAILURE';
export const GET_USERNOS_REQUEST = 'GET_USERNOS_REQUEST';
export const GET_USERNOS_SUCCESS = 'GET_USERNOS_SUCCESS';
export const GET_USERNOS_FAILURE = 'GET_USERNOS_FAILURE';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const SET_WELCOME_FALSE = 'SET_WELCOME_FALSE';
export const SET_GOT_IT_REQUEST = 'SET_GOT_IT_REQUEST';
export const SET_GOT_IT_SUCCESS = 'SET_GOT_IT_SUCCESS';
export const SET_GOT_IT_FAILURE = 'SET_GOT_IT_FAILURE';

export const changeName = (value, category) => ({
  type: CHANGE_NAME,
  value,
  category
});

export const setWelcomeFalse = () => ({
  type: SET_WELCOME_FALSE
});

export const setGotItRequest = () => ({
  type: SET_GOT_IT_REQUEST
});

export const setGotItSuccess = data => ({
  type: SET_GOT_IT_SUCCESS,
  data
});

export const setGotItFailure = error => ({
  type: SET_GOT_IT_FAILURE,
  error
});

export const editNameRequest = () => ({
  type: EDIT_NAME_REQUEST
});

export const editNameSuccess = data => ({
  type: EDIT_NAME_SUCCESS,
  data
});

export const editNameFailure = error => ({
  type: EDIT_NAME_FAILURE,
  error
});

export const uploadPhotoRequest = () => ({
  type: UPLOAD_PHOTO_REQUEST
});

export const uploadPhotoSuccess = data => ({
  type: UPLOAD_PHOTO_SUCCESS,
  data
});

export const uploadPhotoFailure = error => ({
  type: UPLOAD_PHOTO_FAILURE,
  error
});

export const logout = () => ({
  type: LOGOUT
});

export const gotItWhatsNewRequest = data => ({
  type: GOT_IT_WHATS_NEW_REQUEST,
  data
});

export const gotItWhatsNewSuccess = () => ({
  type: GOT_IT_WHATS_NEW_SUCCESS
});

export const gotItWhatsNewFailure = error => ({
  type: GOT_IT_WHATS_NEW_FAILURE,
  error
});

export const UpdateOpenNoRequest = data => ({
  type: UPDATE_OPEN_NO_REQUEST,
  data
});

export const UpdateOpenNoSuccess = () => ({
  type: UPDATE_OPEN_NO_SUCCESS
});

export const UpdateOpenNoFailure = error => ({
  type: UPDATE_OPEN_NO_FAILURE,
  error
});

export const getUserNoByIdRequest = () => ({
  type: GET_USERNOS_REQUEST
});

export const getUserNoByIdSuccess = data => ({
  type: GET_USERNOS_SUCCESS,
  data
});

export const getUserNoByIdFailure = error => ({
  type: GET_USERNOS_FAILURE,
  error
});

export const getUserByIdRequest = () => ({
  type: GET_USER_REQUEST
});

export const getUserByIdSuccess = data => ({
  type: GET_USER_SUCCESS,
  data
});

export const getUserByIdFailure = error => ({
  type: GET_USER_FAILURE,
  error
});

export function getUserById (token, user_id) {
  return (dispatch) => {


    dispatch(getUserByIdRequest());
    console.log('getUserById action for id: ', user_id);

    fetch(`${process.env.REACT_APP_API_URI}/users/${user_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    })
    .then((res) => {
      console.log('getUser res:', res);
      res.json()
      .then((data) => {
        console.log('getUser response data:', data);

        dispatch(getUserByIdSuccess(data)); // returns user record for user reducer and user's available dates for calendar reducer

      })
      .catch((err) => {
        console.log('caught getUser error: ', err);
        dispatch(getUserByIdFailure(err.message));
      });
    })
    .catch((err) => {
      console.log('caught getUser error: ', err);
      dispatch(getUserByIdFailure(err.message));
    });
  };
}


export function getUserNoById (token, user_id) {
  return (dispatch) => {

    dispatch(getUserNoByIdRequest());
    console.log('getUserNoById action for id: ', user_id);

    fetch(`${process.env.REACT_APP_API_URI}/userNos/${user_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    })
    .then((res) => {
      console.log('profile res:', res);
      res.json()
      .then((data) => {
        console.log('getUserNos response data:', data);
        const latestUpdateNo = (store.getState().user.user_update_no > data.update_no) ? store.getState().user.user_update_no : data.update_no;
        const latestOpenNo = (store.getState().user.user_open_no > data.open_no) ? store.getState().user.user_open_no : data.open_no;
        const userNosData = {
          update_no: latestUpdateNo,
          open_no: latestOpenNo
        };
        dispatch(getUserNoByIdSuccess(userNosData));

      })
      .catch((err) => {
        console.log('caught getUser error: ', err);
        dispatch(getUserNoByIdFailure(err.message));
      });
    })
    .catch((err) => {
      console.log('caught getUser error: ', err);
      dispatch(getUserNoByIdFailure(err.message));
    });
  };
}


export function setGotIt (token, user_id, type) {

  // adds got it type (e.g. 'first_200_sp') to user's got_it array
  return (dispatch) => {
    dispatch(setGotItRequest());
    fetch(`${process.env.REACT_APP_API_URI}/gotIt`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ user_id, type })
    })
    .then((response) => {
      console.log('setGotIt response', response);
      response.json()
      .then((data) => {
        if (data.error) {
          dispatch(setGotItFailure(data.error));
        } else {
          console.log('successfully setGotIt to server');
          dispatch(setGotItSuccess(data));
        }
      });
    })
    .catch((error) => {
      dispatch(setGotItFailure(error.message));
    });
  };
}

export function updateOpenNo (token, user_id, open_no) {
  return (dispatch) => {
    dispatch(UpdateOpenNoRequest(open_no));
    fetch(`${process.env.REACT_APP_API_URI}/saveOpenNo/${user_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ user: { open_no } })
    })
    .then((response) => {
      console.log('updateOpenNo response', response);
      response.json()
      .then((data) => {
        console.log('updateOpenNo data', data);
        if (data.error) {
          console.log('updateOpenNo data.error');
          dispatch(UpdateOpenNoFailure(data.error));
        } else {
          console.log('successfully saved open_no to server');
          dispatch(UpdateOpenNoSuccess());
        }
      });
    })
    .catch((error) => {
      console.log('updateOpenNo error');
      dispatch(UpdateOpenNoFailure(error.message));
    });
  };
}

export function editName (token, user_id, firstname, surname) {
  return (dispatch) => {
    dispatch(editNameRequest());
    fetch(`${process.env.REACT_APP_API_URI}/users/${user_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ user: { firstname, surname } })
    })
    .then((res) => {
      console.log('editName res:', res);
      res.json()
      .then((data) => {
        dispatch(editNameSuccess(data));
      })
      .catch(err => dispatch(editNameFailure(err.message)));
    })
    .catch((err) => {
      dispatch(editNameFailure(err.message));
    });
  };
}

// export function uploadPhoto (token, formData) {
//   console.log('Photo formData: ', formData);
//   console.log('UploadPhotoURL: ', `${process.env.URI}/upload`);
//   console.log('token: ', token);
//   return (dispatch) => {
//     dispatch(uploadPhotoRequest());
//     fetch(`${process.env.REACT_APP_API_URI}/upload`, {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'multipart/form-data',
//         authorization: token
//       },
//       body: formData
//     })
//     .then((res) => {
//       console.log('uploadPhoto res', res);
//       res.json()
//       .then((data) => {
//         console.log('Photo response data: ', data);
//         dispatch(uploadPhotoSuccess(data));
//         // add tick?
//       })
//       // .catch((err) => {
//       //   console.log('Photo response error: ', err);
//       //   dispatch(uploadPhotoFailure('Something went wrong'));
//       //   // add modal?
//       // });
//     })
//     .catch((err) => {
//       console.log('Photo response error: ', JSON.stringify(err));
//       dispatch(uploadPhotoFailure('Something went wrong'));
//
//       ReportError(!err.code ? null : err.code, err.message, {});
//       dispatch(showModal(
//         MODAL_TYPE_ERROR,
//         { // modalProps
//           title: upload_photo.modal.failure.title,
//           header: upload_photo.modal.failure.header,
//           message: upload_photo.modal.failurepara,
//           message2: upload_photo.modal.failure.para2,
//           error_status: err.code && err.code,
//           error_message: err.message
//         }
//       ));
//
//     });
//   };
// }

// handleUploadProfilePhoto

export function handleUploadPhoto (docStatus, adult_id, adult_name, doc_name) {
  // console.log('handleUploadVerifyClick', `${docStatus}, ${adult_id}, ${adult_name}, ${doc_name}`);
  const cameraAction = (dispatch) => {

      dispatch(showModal(MODAL_TYPE_UPLOAD_SELFIE, {
        showClose: true,
        cancelAction: () => dispatch(hideModal()),
        adult_id,
        adult_name,
        doc_name
      }));

  };

  return (dispatch) => {

    // dispatch(showModal(MODAL_TYPE_CONFIRM_UPLOAD_TYPE, {
    //   cameraAction: () => cameraAction(dispatch),
    //   // fileAction,
    //   cancelAction: () => dispatch(hideModal()),
    //   docStatus,
    //   adult_id,
    //   adult_name,
    //   doc_name
    // }));

    const modalText = verify.modal.confirm_upload_type;

    const header =
    doc_name === 'photo' ?
      modalText.header.photo :
    doc_name === 'id' ?
      modalText.header.id :
    doc_name === 'poa' ?
      modalText.header.poa :
    doc_name === 'dbs' ?
      modalText.header.dbs :
    'undefined';


    const titleSuffix =
    doc_name === 'photo' ?
      modalText.photo :
    doc_name === 'id' ?
      modalText.id :
    doc_name === 'poa' ?
      modalText.poa :
    doc_name === 'dbs' ?
      modalText.dbs :
    'undefined';

    const para =
    doc_name === 'photo' ?
      modalText.para_photo :
    doc_name === 'id' ?
      modalText.para_id :
    doc_name === 'poa' ?
      modalText.para_poa :
    doc_name === 'dbs' ?
      modalText.para_dbs :
    'undefined';

    const title = `${modalText.title} ${titleSuffix}`;

    const newModalText = { ...modalText, para, title, header };

    dispatch(showModal(MODAL_TYPE_CONFIRM_UPLOAD_TYPE, {
      showClose: true,
      modalCategory: UPLOAD,
      modalName: CONFIRM_UPLOAD_TYPE,
      modalText: newModalText,
      // okAction: () => dispatch(okDowngradeSubscription(token, user_id, cancel, next_payment_date)),
      backAction: null,

      cameraAction: () => cameraAction(dispatch),
      // fileAction,
      cancelAction: () => dispatch(hideModal()),
      docStatus,
      adult_id,
      adult_name,
      doc_name

    }));

  };
}
