import { showModal } from '../modal';
import { confirmPAYGPayment, createCustomer, attachPaymentMethod, submitPaymentRequest, submitPaymentFailure } from './index';
import { plan as planText } from '../../text';
import { actionSuccess } from '../../actions/verify';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR } from '../../components/modals/modalTypes';


export function handleCheckout (token, stripe, cardElement, messageParams, user_id, email, currentPlan, validatedCoupon, payment_type, thread_id, customer_id, subscription_id) {
  console.log('HANDLE CHECKOUT: ', { token, stripe, cardElement, messageParams, user_id, email, currentPlan, validatedCoupon, payment_type, thread_id, customer_id, subscription_id });
  // card verified - now to collect payment and confirm booking
  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: planText.modal.payg_payment.fetching
      }
    ));
    dispatch(submitPaymentRequest());
    console.log('payment_type:', payment_type);

    if (payment_type === 'subscribe' || payment_type === 'new_card') {

      // api.createCustomer(email);


      stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          email
          // name,
          // address: {
          //   postal_code: postal,
          // }
        }
      })
      .then((result) => {
        console.log('result:', result);
        // Handle result.error or result.paymentMethod
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          console.log(result.error.message);
          throw new CustomException(200, `Your payment was not authorised by your card issuer or the sit could not be confirmed: ${result.error.message}`);


        } else {

          // if new customer, create customer and subscription
          if (!customer_id) {
            dispatch(createCustomer(token, messageParams, user_id, email, result.paymentMethod, currentPlan, validatedCoupon.id));
          }

          // if existing customer & no subscription (or 'canceled?'), create new subscription for existing customer
          if (customer_id && !subscription_id) {
            // TODO
            // createCustomer checks for existence of customer_id and only creates a new one, if not already existing for that user.
            // QUESTION - should we create new subscription (think so), alternative to un-pause old subscription (if paused).
            dispatch(createCustomer(token, messageParams, user_id, email, result.paymentMethod, currentPlan, validatedCoupon.id));
          }

          // if existing customer & subscription, just add new payment method, do not re-subscribe - can only do this from MyPlan, not from booking sit.
          if (customer_id && subscription_id) {
            dispatch(attachPaymentMethod(token, messageParams, user_id, email, result.paymentMethod, currentPlan, validatedCoupon.id));
          }


        }
      }).catch((err) => {
        // this.setState({ error: err.message }); // add to redux / actions

        console.log('caught submitCheckoutPaymentSub error: ', err);
        // alert('Payment Error');
        dispatch(submitPaymentFailure(err.message));

        // dispatch(submitSubscriptionFailure(err.message));
        // centralise bugsnag reporting
        ReportError(!err.code ? null : err.code, err.message, {});
        dispatch(showModal(
          MODAL_TYPE_ERROR,
          { // modalProps
            title: planText.modal.subscribe.failure.title,
            header: planText.modal.subscribe.failure.header,
            message: planText.modal.subscribe.failure.para,
            message2: planText.modal.subscribe.failure.para2,
            error_status: err.code && err.code,
            error_message: err.message
          }
        ));

        dispatch(actionSuccess());

      });

    } else if (payment_type === 'payg') {
      // to add code


      stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          email
        }
      }).then((result) => {
        console.log('result:', result);

        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          console.log(result.error.message);
          throw new CustomException(200, `Your payment was not authorised by your card issuer or the sit could not be confirmed: ${result.error.message}`);


        } else {

          console.log('result.paymentMethod:', result.paymentMethod);
          const payment_id = result.paymentMethod.id;
          // update state and db

          dispatch(confirmPAYGPayment(token, messageParams, user_id, thread_id, payment_id, validatedCoupon.code)); //  validate promo_code rather than promo_id - check if should do this for subscriptions.
          // handleCreateCustomer(messageParams, user_id, email, result.paymentMethod, currentPlan, validatedCoupon.id).then((res) => {
          //   console.log('res:', res);
          //   // do something with result, success/confirmation screen?
          //   // subscribe success modal
          //   // store customer_id in db?  to check if subscribed or not?


        }
      }).catch((err) => {
        // this.setState({ error: err.message }); // add to redux / actions

        console.log('caught submitCheckoutPaymentSub error: ', err);
        // alert('Payment Error');
        dispatch(submitPaymentFailure(err.message));

        // centralise bugsnag reporting
        ReportError(!err.code ? null : err.code, err.message, {});
        dispatch(showModal(
          MODAL_TYPE_ERROR,
          { // modalProps
            title: planText.modal.payg_payment.failure.title,
            header: planText.modal.payg_payment.failure.header,
            message: planText.modal.payg_payment.failure.para,
            message2: planText.modal.payg_payment.failure.para2,
            error_status: err.code && err.code,
            error_message: err.message
          }
        ));

        dispatch(actionSuccess());

      });

    } else {
      const errorText = `Invalid payment_type: ${payment_type}`;
      dispatch(submitPaymentFailure(errorText));

      // centralise bugsnag reporting
      ReportError(null, errorText, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: planText.modal.payg_payment.failure.title,
          header: planText.modal.payg_payment.failure.header,
          message: planText.modal.payg_payment.failure.para,
          message2: planText.modal.payg_payment.failure.para2,
          error_status: null,
          error_message: errorText
        }
      ));

      dispatch(actionSuccess());

    }

  };

}

export default handleCheckout;
