import React from 'react';
import PageWrapper from './page-wrapper';
import Spinner from './spinner';
import LogoMain from './logo-main';


const Loading = () => {

    return (
      <PageWrapper>

        <LogoMain />
        <Spinner />

      </PageWrapper>
    );

};

export default Loading;
