
import { showModal, hideModal } from '../modal';

// import initSocket from '../socket-router';
// require('dotenv').config();


export const SET_FONT_PROPS = 'SET_FONT_PROPS';
export const ADD_NEW_THEME_REQUEST = 'ADD_NEW_THEME_REQUEST';
export const ADD_NEW_THEME_SUCCESS = 'ADD_NEW_THEME_SUCCESS';
export const ADD_NEW_THEME_FAILURE = 'ADD_NEW_THEME_FAILURE';
export const SAVE_THEME_REQUEST = 'SAVE_THEME_REQUEST';
export const SAVE_THEME_SUCCESS = 'SAVE_THEME_SUCCESS';
export const SAVE_THEME_FAILURE = 'SAVE_THEME_FAILURE';
export const SET_USE_THEME_REQUEST = 'SET_USE_THEME_REQUEST';
export const SET_USE_THEME_SUCCESS = 'SET_USE_THEME_SUCCESS';
export const SET_USE_THEME_FAILURE = 'SET_USE_THEME_FAILURE';
export const SET_EDITING_THEME = 'SET_EDITING_THEME';
export const SET_EDITING_TAG = 'SET_EDITING_TAG';
export const SAVE_CHANGE_TO_THEME = 'SAVE_CHANGE_TO_THEME';
export const UPDATE_EDIT_THEME = 'UPDATE_EDIT_THEME';
export const SET_NEW_THEME_NAME = 'SET_NEW_THEME_NAME';
export const GET_ALL_THEMES_REQUEST = 'GET_ALL_THEMES_REQUEST';
export const GET_ALL_THEMES_SUCCESS = 'GET_ALL_THEMES_SUCCESS';
export const GET_ALL_THEMES_FAILURE = 'GET_ALL_THEMES_FAILURE';


// export function setFontProps (tag, property, value) {
//   return {
//     type: SET_FONT_PROPS,
//     data: { tag, property, value }
//   };
// }

export const addNewThemeRequest = () => ({
  type: ADD_NEW_THEME_REQUEST
});

export const addNewThemeSuccess = data => ({
  type: ADD_NEW_THEME_SUCCESS,
  data
});

export const addNewThemeFailure = error => ({
  type: ADD_NEW_THEME_FAILURE,
  error
});

export const saveThemeRequest = () => ({
  type: SAVE_THEME_REQUEST
});

export const saveThemeSuccess = data => ({
  type: SAVE_THEME_SUCCESS,
  data
});

export const saveThemeFailure = error => ({
  type: SAVE_THEME_FAILURE,
  error
});

export const setUseThemeRequest = () => ({
  type: SET_USE_THEME_REQUEST
});

export const setUseThemeSuccess = data => ({
  type: SET_USE_THEME_SUCCESS,
  data
});

export const setUseThemeFailure = error => ({
  type: SET_USE_THEME_FAILURE,
  error
});


export const getAllThemesRequest = () => ({
  type: GET_ALL_THEMES_REQUEST
});

export const getAllThemesSuccess = data => ({
  type: GET_ALL_THEMES_SUCCESS,
  data
});

export const getAllThemesFailure = error => ({
  type: GET_ALL_THEMES_FAILURE,
  error
});

export function getAllThemes (token) {
  return (dispatch) => {

    dispatch(getAllThemesRequest());

    fetch(`${process.env.REACT_APP_API_URI}/getallthemes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    })
    .then((res) => {
      // console.log('getAllThemes res:', res);
      res.json()
      .then((data) => {
        // console.log('getAllThemes response data:', data);
        const new_data_array = data.map((item) => {
          // console.log('item.theme_details:', item.theme_details);
          const parsed_theme_details = JSON.parse(item.theme_details);
          // console.log('parsed_theme_details:', parsed_theme_details);

          const newItem = { ...item, theme_details: parsed_theme_details };
          // console.log('newItem:', newItem);
          return newItem;
        });

        // console.log('new_data_array', new_data_array);

        dispatch(getAllThemesSuccess(new_data_array));

      })
      .catch((err) => {
        // console.log('caught getAllThemes error: ', err);
        dispatch(getAllThemesFailure(err));
      });
    })
    .catch((err) => {
      // console.log('caught getAllThemes error: ', err);
      dispatch(getAllThemesFailure(err));
    });
  };
}


export function addNewTheme (token, themeName, themeDetails) {

  return (dispatch) => {

    dispatch(addNewThemeRequest());
    // console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/addtheme`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        theme: {
          theme_name: themeName,
          theme_details: themeDetails
        }
      })
    })
    .then((response) => {
      // console.log('addNewTheme response:', response);
      response.json()
      .then((data) => {
        // console.log('addNewTheme data:', data);
        dispatch(addNewThemeSuccess(data)); // available_themes
        dispatch(getAllThemes(token));
        dispatch(hideModal());
      })
      .catch((err) => {
        dispatch(addNewThemeFailure(err.message));
        dispatch(hideModal());
      });
    })
    .catch((error) => {
      dispatch(addNewThemeFailure(error.message));
      dispatch(hideModal());
    });
  };
}

export function saveEditedTheme (token, theme_id, theme_details) {

  return (dispatch) => {

    dispatch(saveThemeRequest());
    // console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/savetheme`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        theme: {
          theme_id,
          theme_details
        }
      })
    })
    .then((response) => {
      // console.log('saveTheme response:', response);
      response.json()
      .then((data) => {
        dispatch(saveThemeSuccess(data)); // available_themes
        dispatch(getAllThemes(token));
        dispatch(hideModal());
      })
      .catch((err) => {
        dispatch(saveThemeFailure(err.message));
        dispatch(hideModal());
      });
    })
    .catch((error) => {
      dispatch(saveThemeFailure(error.message));
      dispatch(hideModal());
    });
  };
}

export function setUseTheme (token, theme_id) {

  return (dispatch) => {

    dispatch(setUseThemeRequest());
    // console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/setusetheme`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      // replace with new params
      body: JSON.stringify({
        theme: {
          theme_id
        }
      })
    })
    .then((response) => {
      // console.log('setUseTheme response:', response);
      response.json()
      .then((data) => {
        dispatch(setUseThemeSuccess(data)); // return available_themes
        dispatch(getAllThemes(token));
        dispatch(hideModal());
      })
      .catch((err) => {
        dispatch(setUseThemeFailure(err.message));
        dispatch(hideModal());
      });
    })
    .catch((error) => {
      dispatch(setUseThemeFailure(error.message));
      dispatch(hideModal());
    });
  };
}

export function setEditingTheme (editing_theme) {

  return {
    type: SET_EDITING_THEME,
    data: { editing_theme }
  };
}

export function setEditingTag (tag) {

  return {
    type: SET_EDITING_TAG,
    data: tag
  };
}

export function updateEditingTheme (style_section, tag, property, screensize_index, value) {

  return {
    type: UPDATE_EDIT_THEME,
    data: { style_section, tag, property, screensize_index, value }
  };
}

export function saveChangeToTheme (style_section, tag, property, screensize_index, value) {

  return {
    type: SAVE_CHANGE_TO_THEME,
    data: { style_section, tag, property, screensize_index, value }
  };
}


export function setNewThemeName (new_theme_name) {

  return {
    type: SET_NEW_THEME_NAME,
    data: { new_theme_name }
  };
}
