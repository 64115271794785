/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { SetGA, ReportGA } from '../../utils/ga';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
// // import '@trendmicro/react-buttons/dist/react-buttons.css';
// // import '@trendmicro/react-dropdown/dist/react-dropdown.css';
// // import 'react-dropdown/style.css';
// // import 'react-select/dist/react-select.css';
import Collapse from '../general/collapse';
import { DropDownButton } from '../../styles/styles';
import colours from '../../styles/colours';
import NavMenu from './nav-menu';
import NavMenuLoggedOut from './nav-menu-logged-out';
import { navBarHeight, navBarLoggedOutHeight } from '../../styles/layout';
import { NAV } from '../../constants/ga-category';

export default class DropdownNav extends Component {


  componentWillUnmount () {
    const { handleDropdownNavClick } = this.props;
    handleDropdownNavClick(true);
  }

  handleClick = () => {

      // if click is inside, do something
      const { handleDropdownNavClick, dropdown_nav_is_open } = this.props;
      // console.log('dropdown-nav handleClick');
      handleDropdownNavClick(dropdown_nav_is_open);

      ReportGA({
        category: NAV,
        action: `User clicked Dropdown Nav button`
      });


  }

  handleOutsideClick = () => {

      // if click is inside, do something
      // console.log('dropdown-nav handleOutsideClick');
      const { handleDropdownNavClick } = this.props;
      handleDropdownNavClick(true);

  }


  render () {


    const {
      dropdown_nav_is_open,
      user_id,
      user_is_admin,
      all_transactions,
      sp_balance,
      sp_pending,
      photo_url,
      profile_progress,
      profile_complete_count,
      verified_count,
      email_verified,
      window_width,
      // burgerIsOpen,
      // onBurgerClick,
      handleDropdownNavClick,
      handleLogOut,
      isLoggedIn,
      showSignUpModal,
      showSignInModal,
      currentRoute

    } = this.props;


    return (

      <div
        style={{
          flex: 1,
          display: 'flex',
          position: 'relative',
          cursor: 'pointer',
          borderWidth: 0,
          borderColor: colours.lightgray,
          borderStyle: 'solid',
          borderRadius: 0,
          height: '100%',
          flexDirection: 'column'
        }}
      >
        <DropDownButton
          type='button'
          title='Navigation Menu'
          onClick={this.handleClick}
          onKeyDown={this.handleClick}
          style={{ background: colours.logopurple, display: 'flex', flexDirection: 'row', width: '100%', flex: 1, borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}

        >

          <div style={{ display: 'flex', height: 48, flex: 1, borderColor: 'red', borderWidth: 0, borderStyle: 'solid', alignItems: 'center', justifyContent: 'center' }}>
            <FontAwesomeIcon
              icon={faBars}
              size='2x'
              style={{ color: colours.white }} />
          </div>


        </DropDownButton>

        <div
          style={{
            opacity: 1,
            position: 'absolute',
            top: isLoggedIn ? navBarHeight : navBarLoggedOutHeight, // adjust to 48 when logged out
            // top: 64, // same as your nav height
            right: 0,
            background: colours.lightgray,
            borderColor: colours.verylightgray, borderStyle: 'solid',
            borderWidth: 0,
            borderTopWidth: 0,
            boxShadow: `0px 2px 4px 0px ${colours.shadow}`,
            padding: 0,
            margin: 0,
            textAlign: 'center',
            transition: 'all 1000 ease',
            zIndex: 999,
            width: isLoggedIn ? window_width : null,
            minWidth: isLoggedIn ? null : 200
          }}
        >
          <Collapse parentComp='dropdownNav' isOpen={dropdown_nav_is_open}>
            <div
              role='presentation'
              // onClick={this.handleOutsideClick}
              // onKeyDown={this.handleOutsideClick}
              style={{
                position: 'fixed',
                opacity: 0.2,
                background: colours.lightgray,
                top: 0,
                left: 0,
                width: isLoggedIn ? '100%' : null,
                height: 0,
                zIndex: -1
            }} />
            <div style={{
              borderWidth: 1,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderColor: colours.borderColour,
              borderStyle: 'solid',
              boxShadow: `0px 1px 0px ${colours.shadow}` }}>

              {
                isLoggedIn
                ? <NavMenu
                  width={window_width}
                  orientation='burger'
                  user_id={user_id}
                  user_is_admin={user_is_admin}
                  transactions={all_transactions}
                  sp_balance={sp_balance}
                  sp_pending={sp_pending}
                  photo_url={photo_url}
                  profile_progress={profile_progress}
                  profile_complete_count={profile_complete_count}
                  verified_count={verified_count}
                  email_verified={email_verified}
                  dropdown_nav_is_open={dropdown_nav_is_open}
                  outsideDropdownNavClick={() => this.handleOutsideClick()}
                  handleDropdownNavClick={handleDropdownNavClick}
                  handleLogOut={handleLogOut}
                />
                : <NavMenuLoggedOut
                  width={window_width}
                  orientation='burger'
                  user_id={user_id}
                  user_is_admin={user_is_admin}
                  transactions={all_transactions}
                  photo_url={photo_url}
                  profile_progress={profile_progress}
                  dropdown_nav_is_open={dropdown_nav_is_open}
                  outsideDropdownNavClick={() => this.handleOutsideClick()}
                  handleDropdownNavClick={handleDropdownNavClick}
                  handleLogOut={handleLogOut}
                  showSignUpModal={showSignUpModal}
                  showSignInModal={showSignInModal}
                  currentRoute={currentRoute}
                />
              }
            </div>
          </Collapse>
        </div>


      </div>

    );
  }
}
