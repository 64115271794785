import React, { useState } from 'react';
import { connect } from 'react-redux';
import colours from '../../styles/colours';
import { showModal, hideModal } from '../../actions/modal';
import { MODAL_TYPE_EDIT_DAY } from '../modals/modalTypes';
import { calendar } from '../../text';
import { DateText } from './calendar-styles';
import imgBookedSitter from '../../images/SoloBooked_you_have_sitter2.svg';
import imgPendingSitter from '../../images/SoloPending_you_have_sitter2.svg';
import imgBookedSitting from '../../images/SoloBooked_you_are_sitting2.svg';
import imgPendingSitting from '../../images/SoloPending_you_are_sitting2.svg';


const WidgetImage = ({ src }) => {

    return (

      <div style={{ padding: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', backgroundColor: colours.backgroundGray }}>

        <img alt='icon' style={{ width: '100%', objectFit: 'contain' }} src={ src } />

      </div>
    );


};


export const CellButton = ({ children, date, onClick, handleCellClick, isInSelectedRange, isToday, isInPast, isPrevMonth, isNextMonth, isAvailable, isSitBooked, isSitPending, isReqBooked, isReqPending, hasClashes, cellWidth, style, textColor, backgroundColor }) => {

  const [hover, setHover] = useState(false);

  // console.log('isInPast: ', isInPast);

  // isfirst row
  // islast row
  // is first cell
  // is last cell

  const toggleHover = (newHover) => {
    if (!isInPast && !isNextMonth && !isPrevMonth) {
      if (hover === undefined) {
        setHover(!hover);
      } else {
        setHover(newHover);
      }
    }
  };

  const handleClick = () => {


    // console.log('date: ', date);
    if (!isInPast && !isNextMonth && !isPrevMonth) {

      if (onClick) {
        onClick();
      } else {
        handleCellClick(date);
      }

    }
    toggleHover(false);
  };

  const available = !isInPast && isAvailable;
  const sitBooked = !isInPast && isSitBooked;
  const sitPending = !isInPast && isSitPending;
  const reqBooked = !isInPast && isReqBooked;
  const reqPending = !isInPast && isReqPending;
  const isHovered = hover;

  // console.log('available:', available);

  const pastTestColor = isInPast ? colours.white : 'transparent';
  const divColor = isToday ? pastTestColor : pastTestColor;
  const reqBookedColor = colours.reqBooked;
  const sitBookedColor = colours.sitBooked;
  const availableColor = colours.available;

  const availableTextColor = colours.white;

  const usedTextColor = available ? availableTextColor : textColor;

  const hoveredColor = colours.lightpurple;
  const outlineWidth = isHovered ? 3 : 0;
  const outlineColor = isHovered ? hoveredColor : 'transparent';

  const borderColor = isHovered ? hoveredColor : hasClashes && !isInPast ? colours.red : colours.mediumlightgray;

  const borderWidth = 1;

  const mainbackgroundColor = isInSelectedRange ? colours.blue : hasClashes && !isInPast ? colours.verylightred : backgroundColor || divColor;

  const showInnerCircle = available;

  const dotsMargin = cellWidth < 60 ? 1 : 2;
  const dotSize = 8;
  const dotBorderWidth = 1;

  const innerCircleMargin = 4;

  const innerCircleSize = cellWidth - ((dotSize + dotsMargin + innerCircleMargin) * 2); // multiply by two ensure account for spacing both sides;

  const innerCircleBackgroundColor = isInSelectedRange ? colours.blue : showInnerCircle ? availableColor : colours.white;

  return (
    <button
      type='button'
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
      onClick={() => handleClick(children)}
      style={{ ...style, zIndex: isHovered ? 999 : 1, position: 'relative', outlineWidth, outlineColor, outlineStyle: 'solid', display: 'flex', width: cellWidth, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderWidth: 0, borderLeftWidth: borderWidth, borderRightWidth: 0, borderTopWidth: borderWidth, borderBottomWidth: 0, borderColor, borderStyle: 'solid', paddingLeft: 0, paddingRight: 0, borderRadius: 0, background: mainbackgroundColor }}>


      <div style={{ position: 'absolute', right: dotsMargin, top: dotsMargin, display: 'flex', flexDirection: 'column' }}>


        {
          sitBooked && <div style={{ background: sitBookedColor, borderRadius: '50%', width: dotSize, height: dotSize, marginBottom: dotsMargin }} />
        }
        {
          sitPending && <div style={{ background: colours.white, borderRadius: '50%', width: dotSize, height: dotSize, borderColor: sitBookedColor, borderStyle: 'solid', borderWidth: dotBorderWidth, marginBottom: dotsMargin }} />
        }
        {
          reqBooked && <div style={{ background: reqBookedColor, borderRadius: '50%', width: dotSize, height: dotSize, marginBottom: dotsMargin }} />
        }

        {
          reqPending && <div style={{ background: colours.white, borderRadius: '50%', width: dotSize, height: dotSize, borderColor: reqBookedColor, borderStyle: 'solid', borderWidth: dotBorderWidth, marginBottom: dotsMargin }} />
        }

        {
          // sitBooked && <div style={{ width: dotSize, height: dotSize, marginBottom: dotsMargin }}><WidgetImage src={imgBookedSitter} /></div>
        }

        {
          // sitPending && <div style={{ width: dotSize, height: dotSize, marginBottom: dotsMargin }}><WidgetImage src={imgPendingSitter} /></div>
        }

        {
          // reqBooked && <div style={{ width: dotSize, height: dotSize, marginBottom: dotsMargin }}><WidgetImage src={imgBookedSitting} /></div>
        }

        {
          // reqPending && <div style={{ width: dotSize, height: dotSize, marginBottom: dotsMargin }}><WidgetImage src={imgPendingSitting} /></div>
        }


      </div>

      <div style={{ width: innerCircleSize, height: innerCircleSize, borderRadius: '50%', background: innerCircleBackgroundColor, margin: innerCircleMargin, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

        <DateText textColor={usedTextColor}>{children}</DateText>

      </div>

    </button>
  );
};


const mapDispatchToProps = (dispatch, ownProps) => ({

  handleCellClick: (date) => {

    dispatch(showModal(MODAL_TYPE_EDIT_DAY, {

      date

    }));

  },
  onClick: ownProps.onClick


  // handleGetUser: (user_id) => {
  //
  //   const token = localStorage.sitterpoint_token;
  //
  //   if (token) {
  //     console.log('availability get User');
  //     dispatch(getUserById(token, user_id));
  //
  //   }
  //
  // },
  //
  // addAvailability: (availabilityObject) => {
  //
  //     dispatch(addAvailability(availabilityObject));
  //
  // },
  // removeAvailability: (availabilityObject) => {
  //
  //     dispatch(removeAvailability(availabilityObject));
  //
  // },
  // handleSubmitAvailability: (user_id, available_dates) => {
  //
  //     console.log('submitAvailability:', available_dates);
  //
  //     const token = localStorage.sitterpoint_token;
  //
  //     if (token) {
  //       dispatch(submitAvailability(token, user_id, available_dates));
  //     }
  //
  // },
  // handleGetAllThreads: (user_id) => {
  //
  //   const token = localStorage.sitterpoint_token;
  //
  //   if (token) {
  //     dispatch(getAllThreadsById(token, user_id));
  //
  //   }
  //
  // },
  // setBookedDates: (usersBookedDates) => {
  //   dispatch(setBookedDates(usersBookedDates));
  // }


});

export default connect(null, mapDispatchToProps)(CellButton);
