/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { SetGA, ReportGA } from '../../../utils/ga';
import {
    Icon,
    Dropdown,
    Menu
} from "semantic-ui-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import '@trendmicro/react-buttons/dist/react-buttons.css';
// import '@trendmicro/react-dropdown/dist/react-dropdown.css';
// import 'react-dropdown/style.css';
// import 'react-select/dist/react-select.css';
import { Transition } from 'react-transition-group';
import history from '../../Navigation/history';
import { DropDownButton, Row, MoreButton } from '../../../styles/styles';
import { T4, A5 } from '../../../styles/text';
import { DropdownItem } from '../../../styles/buttons';
import NavbarImage from '../../general/navbar-image';
import colours from '../../../styles/colours';
import { help } from '../../../text';
import * as ROUTES from '../../../constants/routes';
import { HELP } from '../../../constants/ga-category';

const defaultStyle = (burger, width) => {
  return ({
    opacity: 1,
    position: burger ? 'fixed' : 'absolute',
    top: burger ? 118 : 60, // same as your nav height
    right: burger ? null : 0,
    // left: burger ? '10%' : null,
    left: burger ? 0 : null,
    margin: 0,
    // marginLeft: burger ? (width) * -0.6 : 0,
    marginLeft: burger ? 0 : 0,
    background: 'transparent',
    padding: 0,
    paddingLeft: burger ? 10 : 0,
    paddingRight: burger ? 10 : 0,
    textAlign: 'center',
    // transition: 'all 1000 ease',
    zIndex: 2000,
    width: burger ? (width * 1) - 0 : 350
  });

};

const fadeInStyles = {

  entering: { opacity: 0 },
  entered: { transition: 'opacity 200ms linear', opacity: 1 },
  exiting: { transition: 'opacity 100ms linear', opacity: 0 },
  exited: { opacity: 0 }
};

export default class DropdownHelp extends Component {

  state = {
    open: false,
    menu_items: [
      { is_open: false, title: help.title1, text: help.text1, read_more: false, is_collapsable: false, route: ROUTES.HOWITWORKS },
      { is_open: false, title: help.title2, text: help.text2, read_more: false, is_collapsable: false, route: ROUTES.SITTER_POINTS },
      { is_open: false, title: help.title3, text: help.text3, read_more: false, is_collapsable: false, route: ROUTES.PHILOSOPHY },
      { is_open: false, title: help.title4, text: help.text4, read_more: false, is_collapsable: false, route: ROUTES.FAQ },
      { is_open: false, title: help.title5, text: help.text5, read_more: false, is_collapsable: false, route: ROUTES.TIPS },
      { is_open: false, title: help.title6, text: help.text6, read_more: false, is_collapsable: false, route: ROUTES.CONTACT }
    ]
  }


  handleClick = () => {

      // if click is inside, do something
      const { open } = this.state;
      this.setState({ open: !open });

      ReportGA({
        category: HELP,
        action: `Help dropdown button clicked on navbar`
      });

  }

  handleOutsideClick = () => {

      // if click is inside, do something
      this.setState({ open: false });

  }

  handleSelect = (route) => {
    console.log('route:', route);
    const { onBurgerClick, burgerIsOpen, handleDropdownNavClick, dropdown_nav_is_open } = this.props;

    ReportGA({
      category: HELP,
      action: `User clicked ${route} in Help dropdown`
    });
    // console.log('this.props:', this.props);
    // console.log('handleSelect Messages Item dropdown_nav_is_open:', dropdown_nav_is_open);

    this.setState({ open: false }); // closes help dropdown menu

    if (dropdown_nav_is_open) {
      // console.log('closing dropdown nav because open Dropdown Help');
      handleDropdownNavClick(dropdown_nav_is_open); // closes dropdown nav if open
    }

    history.push({
      pathname: route,
      state: { editMode: false }

    });
    // console.log('aboutToClick:', this.props);
    if (burgerIsOpen) {
      onBurgerClick(burgerIsOpen); // closes burgermenu
    }

  }


  toggleExpand = (i, status, route, read_more) => {

    if (read_more) { // no route defined, expand/collapse

      const { menu_items } = this.state;

      const newState = menu_items.map((item, j) => {
        if (i === j) {
          return { ...item, is_open: !status };
        }
        // else
        return item;
      });

      this.setState({ menu_items: newState });

    } else {
      this.handleSelect(route);
    }
     // else if route then push route


  }


  render () {

    const { open, menu_items } = this.state;


    const { image, icon, iconSize, width, burger } = this.props;

    // const dropdownValue = value;

    const collapsingMenuItems = menu_items.map((item, i) => {

        return (

          <DropdownItem
            onClick={() => this.toggleExpand(i, item.is_open, item.route, item.read_more)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.toggleExpand(i, item.is_open, item.route, item.read_more);
              }
            }}

          >

            <div style={{ flex: 1, display: 'flex', height: '100%', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'flex-end', justifyContent: 'center' }}>

              <div style={{ width: '90%', justifyContent: 'flex-start', display: 'flex' }}>

                <A5>{item.title}</A5>

              </div>
              <div style={{ width: '10%', display: 'flex', flexDirection: 'row' }}>
                {
                  item.is_collapsable
                && <A5>{item.is_open ? '-' : '+'}</A5>
                }
              </div>
            </div>


          </DropdownItem>


        );
    });


    return (

      <div
        style={{
          flex: 1,
          display: 'flex',
          position: 'relative',
          cursor: 'pointer',
          borderWidth: 0,
          borderColor: colours.lightgray,
          borderStyle: 'solid',
          borderRadius: 0,
          height: '100%',
          minWidth: 64
        }}
      >
        <DropDownButton

          type='button'
          onClick={this.handleClick}
          onKeyDown={this.handleClick}

        >


          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', borderStyle: 'solid', borderWidth: 0, borderColor: 'red' }}>

            <div style={{ borderStyle: 'solid', width: 36, height: 36, borderWidth: 0, borderColor: 'red' }}>

              {
                image
                ? <NavbarImage
                    alt='welcome'
                    style={{ width: 36,
                    // maxWidth: 200,
                    objectFit: 'contain' }}
                    src={ image }
                  />
                : <FontAwesomeIcon
                    icon={icon}
                    size={iconSize}
                    style={{ margin: 8, color: colours.mediumlightpurple }} />
              }

            </div>

          </div>


          <div style={{ position: 'absolute', display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Icon name='caret down' size='small' />

          </div>


        </DropDownButton>
        <Transition
          in={open}
          appear={true}
          exit={true}
          timeout={{
            appear: 50,
            enter: 50,
            exit: 200
          }}

          unmountOnExit
          // classNames='fade'
        >
          { state => (

            <div

              style={{
                ...defaultStyle(burger, width),
                ...fadeInStyles[state]
              }}

            >

              <div
                role='presentation'
                onClick={this.handleOutsideClick}
                onKeyDown={this.handleOutsideClick}
                style={{
                  position: 'fixed',
                  opacity: 0.2,
                  background: colours.darkgray,
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: -1
              }} />
              <Menu
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: `1px 1px 1px ${colours.shadow}`
                }}
                vertical
              >
                {collapsingMenuItems}
              </Menu>


            </div>
          )}

        </Transition>

      </div>

    );
  }
}
