import React from 'react';
import { connect } from 'react-redux';

import SpinnerModal from './modals/spinner';
import ErrorModal from './modals/error';
import UploadProgressModal from './modals/upload-progress';
import ConfirmCancelModal from './modals/confirm-cancel';
import UploadSelfieModal from './modals/upload-selfie';
import UploadFileModal from './modals/upload-file';
import EditDayModal from './modals/edit-day';
import EditBookingModal from './modals/edit-booking';
import EditSitDetailsModal from './modals/edit-sit-details';
import ConfirmModal from './modals/confirm-modal';
import ConfirmUploadTypeModal from './modals/confirm-upload-type-modal';
import SuccessModal from './modals/success-modal';
import LoginModalContainer from './modals/login';
import SignupModal from './modals/signup';
import ForgotPasswordModal from './modals/forgot-password';
import ReviewModal from './modals/review';

import { MODAL_TYPE_SPINNER, MODAL_TYPE_UPLOAD_PROGRESS, MODAL_TYPE_ERROR, MODAL_TYPE_UPLOAD_SELFIE, MODAL_TYPE_UPLOAD_FILE,
  MODAL_TYPE_EDIT_DAY, MODAL_TYPE_EDIT_BOOKING, MODAL_TYPE_SIGNIN, MODAL_TYPE_SIGNUP, MODAL_TYPE_FORGOTPASSWORD, MODAL_TYPE_CONFIRM_CANCEL,
  MODAL_TYPE_REVIEW, MODAL_TYPE_CONFIRM, MODAL_TYPE_CONFIRM_UPLOAD_TYPE, MODAL_TYPE_SUCCESS, MODAL_TYPE_EDIT_SIT_DETAILS } from '../components/modals/modalTypes';

const MODAL_COMPONENTS = {

  [MODAL_TYPE_FORGOTPASSWORD]: ForgotPasswordModal,

  [MODAL_TYPE_SIGNIN]: LoginModalContainer,
  [MODAL_TYPE_SIGNUP]: SignupModal,
  // [MODAL_TYPE_SEARCH]: SearchModal,


  // [MODAL_TYPE_SUCCESS_RESEND_VERIFY_EMAIL]: SuccessResendVerifyEmailModal,


  // [MODAL_TYPE_CONFIRM_CHANGEPLAN]: ConfirmPlanChangeModal,
  // [MODAL_TYPE_SUCCESS_CHANGEPLAN]: SuccessPlanChangeModal,
  // [MODAL_TYPE_SUCCESS_COUPON]: SuccessCouponModal,


  // [MODAL_TYPE_SUCCESS_CANCEL]: SuccessCancelModal,
  // [MODAL_TYPE_CONFIRM_PREAPPROVE]: ConfirmPreApproveModal,
  // [MODAL_TYPE_SUCCESS_PREAPPROVE]: SuccessPreApproveModal,
  // [MODAL_TYPE_CONFIRM_APPROVE]: ConfirmApproveModal,
  // [MODAL_TYPE_SUCCESS_APPROVE]: SuccessApproveModal,


  // [MODAL_TYPE_SUCCESS_SENT]: SuccessSentModal,

  // [MODAL_TYPE_CONFIRM_UPLOAD_TYPE]: ConfirmUploadTypeModal,

  // [MODAL_TYPE_CONFIRM_ADD_AVAILABILITY]: ConfirmAddAvailabilityModal,
  // [MODAL_TYPE_SUCCESS_ADD_AVAILABILITY]: SuccessAddAvailabilityModal,
  // [MODAL_TYPE_CONFIRM_EDIT_ALL_AVAILABILITY]: ConfirmEditAllAvailabilityModal,
  // [MODAL_TYPE_CONFIRM_EDIT_AVAILABILITY]: ConfirmEditAvailabilityModal,
  // [MODAL_TYPE_SUCCESS_EDIT_AVAILABILITY]: SuccessEditAvailabilityModal,
  // [MODAL_TYPE_CONFIRM_DELETE_ALL_AVAILABILITY]: ConfirmDeleteAllAvailabilityModal,
  // [MODAL_TYPE_SUCCESS_DELETE_AVAILABILITY]: SuccessDeleteAvailabilityModal,
  [MODAL_TYPE_SPINNER]: SpinnerModal,
  [MODAL_TYPE_ERROR]: ErrorModal,
  [MODAL_TYPE_CONFIRM]: ConfirmModal,
  [MODAL_TYPE_CONFIRM_UPLOAD_TYPE]: ConfirmUploadTypeModal,
  [MODAL_TYPE_CONFIRM_CANCEL]: ConfirmCancelModal,
  [MODAL_TYPE_SUCCESS]: SuccessModal,

  [MODAL_TYPE_REVIEW]: ReviewModal,

  [MODAL_TYPE_EDIT_DAY]: EditDayModal,
  [MODAL_TYPE_EDIT_BOOKING]: EditBookingModal,

  [MODAL_TYPE_EDIT_SIT_DETAILS]: EditSitDetailsModal,

  [MODAL_TYPE_UPLOAD_SELFIE]: UploadSelfieModal,
  [MODAL_TYPE_UPLOAD_FILE]: UploadFileModal,
  [MODAL_TYPE_UPLOAD_PROGRESS]: UploadProgressModal

};

const ModalRoot = ({ type, props }) => {
  if (!type) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[type];
  return <ModalComponent {...props} />;
};

export default connect(state => state.modal)(ModalRoot);
