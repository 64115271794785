import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";
import { StyledP as A5 } from '../a/a5';

export const StyledP = styled(A5)`

  white-space: ${props => props.whiteSpace ? props.whiteSpace : 'pre-wrap'}!important;
  
  margin-right: ${props => props.marginRight ? props.marginRight : '10px'}!important;
  color: ${props => props.color ? props.color : colours.gray}!important;
  text-align: ${props => props.textAlign ? props.textAlign : 'left'}!important;
  font-size: ${props => props.screensize = 'small' ? '1em' : '4em'}!important;
  font-style: ${props => props.fontStyle ? props.fontStyle : null}!important;
  text-overflow: ${props => props.textOverflow ? props.textOverflow : 'ellipsis'}!important;
  overflow: ${props => props.overflow ? props.overflow : 'hidden'}!important;
`;

const mapStateToProps = (layout, ownProps) => {

    return {
      screensize: layout.screensize,
      color: ownProps.color,
      textAlign: ownProps.textAlign,
      ...ownProps
    };

};


class FormValueText extends Component {

  render () {

    const { screensize, color, textAlign, marginRight, fontStyle, whiteSpace, overflow, textOverflow } = this.props;
    // console.log('B5 props:', this.props);

    return (
      <StyledP

        screensize={screensize}

        color={color || null}
        textAlign={textAlign || null}
        marginRight={marginRight}
        fontStyle={fontStyle}
        whiteSpace={whiteSpace}
        textOverflow={textOverflow}
        overflow={overflow}

      >
        {this.props.children}
      </StyledP>
    );

  }
}

export default connect(
    mapStateToProps
)(FormValueText);
