import history from '../../components/Navigation/history';
import { showModal, hideModal } from '../modal';
import { actionSuccess } from '../verify';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS } from '../../components/modals/modalTypes';
import * as ROUTES from '../../constants/routes';
import { bio } from '../../text/register';
import { CheckError, ReportError } from '../../utils/check-error';
import { SUCCESS_EDIT_BIO } from '../../components/modals/modalNames';
import { PROFILE } from '../../components/modals/modalCategory';

export const SUBMIT_EDIT_BIO_REQUEST = 'SUBMIT_EDIT_BIO_REQUEST';
export const SUBMIT_EDIT_BIO_SUCCESS = 'SUBMIT_EDIT_BIO_SUCCESS';
export const SUBMIT_EDIT_BIO_FAILURE = 'SUBMIT_EDIT_BIO_FAILURE';


export const submitEditBioRequest = () => ({
  type: SUBMIT_EDIT_BIO_REQUEST
});

export const submitEditBioSuccess = data => ({
  type: SUBMIT_EDIT_BIO_SUCCESS,
  data
});

export const submitEditBioFailure = error => ({
  type: SUBMIT_EDIT_BIO_FAILURE,
  error
});


export function submitEditBio (token, user_id, family_description, nextRoute, editMode) {


  const nextRouteToPush = !nextRoute ? ROUTES.PROFILE_SUMMARY : nextRoute;
  console.log('nextRouteToPush:', nextRouteToPush);

  return (dispatch) => {
    dispatch(showModal(MODAL_TYPE_SPINNER, {

    }));

    dispatch(submitEditBioRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/users/registration/${user_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ user: { family_description } })
    })
    .then(CheckError)
    .then((data) => {
      dispatch(submitEditBioSuccess(data));

      const modalText = bio.modal.success;

      dispatch(showModal(

        MODAL_TYPE_SUCCESS,
        { // modalProps
          // other_user_name?
          modalCategory: PROFILE,
          modalName: SUCCESS_EDIT_BIO,
          modalText: modalText,
          okAction: () => dispatch(hideModal()),
          backAction: null,

        }
      ));


      dispatch(actionSuccess()); // triggers success icon


      // dispatch(hideModal());

      history.push({
        pathname: nextRouteToPush,
        state: { editMode: editMode }
      });


      // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
    })
    .catch((err) => {
      dispatch(submitEditBioFailure(err.message));
      dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: bio.modal.failure.title,
          header: bio.modal.failure.header,
          message: bio.modal.failure.para,
          message2: bio.modal.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());

    });

  };
}
