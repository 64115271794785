import isDateTimeInPast from './timeHelpers';
import { thread_chat } from '../text';

// note - next CTA banner will route clicks based on first of: 1. thread_id, 2. route, 3. onClick.


export const filterRelevantActions = (allThreads, user_id) => {
  const filteredThreads = allThreads.filter((thread) => {

    const isInPast = isDateTimeInPast(thread.end_date, thread.end_time);

    if (isInPast) {
      if (user_id === thread.sitter_id) {
        return thread.status === 'approved' || (thread.status === 'finalised' && thread.requester_rating === -1); // finalised/yet to leave a review(not expired 7 days)
      } // else if requester_id
      return thread.status === 'pre_authorised' || (thread.status === 'finalised' && thread.sitter_rating === -1); // check have sitter/req right way round

    } // else in future
    if (user_id === thread.sitter_id) {
      return thread.status === 'request' || thread.status === 'edited';
    } // else if requester_id
    return thread.status === 'pre_approved';

  });
  return filteredThreads;
};


export const calcNextAction = (thread, user_id) => {

  const isInPast = isDateTimeInPast(thread.end_date, thread.end_time);

  let object = {};

  if (isInPast) {
    if (user_id === thread.sitter_id) {
      if (thread.status === 'approved') {
        // you need to confirm final times
        object = {
          id: `${thread.thread_id}:${thread.sitter_id}:${thread.status}`,
          thread_id: thread.thread_id,
          // no route as using thread_id
          link_text: `Confirm`,
          text: `the times you sat for ${thread.requester_name}`
        };
      }

      if (thread.status === 'finalised' && thread.requester_rating === -1) {
        // you need to leave a review for requester
        object = {
          id: `${thread.thread_id}:${thread.sitter_id}:${thread.status}`,
          thread_id: thread.thread_id,
          // no route as using thread_id
          // pre_link_text: `${thread.sitter_name} sat for you.`,
          // link_text: `Leave a review`,
          text: `You sat for ${thread.requester_name}.`,
          secondary_link_text: `Leave a review`
        };
      }
    } // else if requester_id
    if (thread.status === 'pre_authorised') {
      // you need to approve final times
      object = {
        id: `${thread.thread_id}:${thread.requester_id}:${thread.status}`,
        thread_id: thread.thread_id,
        // no route as using thread_id
        link_text: `Confirm`,
        text: `the times ${thread.sitter_name} you sat for you`
      };
    }
    if (thread.status === 'finalised' && thread.sitter_rating === -1) {
      // you need to leave a review for sitter
      object = {
        id: `${thread.thread_id}:${thread.requester_id}:${thread.status}`,
        thread_id: thread.thread_id,
        // no route as using thread_id
        // pre_link_text: `${thread.sitter_name} sat for you.`,
        // link_text: `Leave a review`,
        text: `${thread.sitter_name} sat for you.`,
        secondary_link_text: `Leave a review`
      };
    }

  } // else in future
  if (user_id === thread.sitter_id) {
    if (thread.status === 'request') {
      // you need to pre-approve
      object = {
        id: `${thread.thread_id}:${thread.sitter_id}:${thread.status}`,
        thread_id: thread.thread_id,
        // no route as using thread_id
        link_text: `Confirm`,
        text: `you can sit for ${thread.requester_name}`
      };
    }

    if (thread.status === 'edited') {
      // X has changed sit details, you need to pre-approve
      object = {
        id: `${thread.thread_id}:${thread.sitter_id}:${thread.status}`,
        thread_id: thread.thread_id,
        // no route as using thread_id
        pre_link_text: `Sit details have changed.`,
        link_text: `Confirm`,
        text: `you can sit for ${thread.requester_name}`
      };
    }

  } // else if requester_id
  if (thread.status === 'pre_approved') {
    // you need to confirm the sit with
    object = {
      id: `${thread.thread_id}:${thread.requester_id}:${thread.status}`,
      thread_id: thread.thread_id,
      // no route as using thread_id
      link_text: `Confirm`,
      text: `that ${thread.sitter_name} will sit for you`
    };
  }

  return object;

};

export default calcNextAction;
