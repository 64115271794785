import axios from 'axios';

// using axios for image uploads because fetch can't show progress indicator.

export default axios.create({
  baseURL: `${process.env.REACT_APP_API_URI}`,
  headers: {
    "Content-type": "application/json"
  }
});
