import { connect } from 'react-redux';
import VerifyFileUploadButton from '../../components/verify/verify-file-upload-button';
import { hideModal, showModal } from '../../actions/modal';
import { setUploadProgress, setCurrentFile, setSelectedFiles, setBlob, setFileFetching } from '../../actions/general/upload';
import { MODAL_TYPE_UPLOAD_FILE } from '../../components/modals/modalTypes';


// import { MODAL_TYPE_SIGNIN, MODAL_TYPE_SIGNUP, MODAL_TYPE_FORGOTPASSWORD } from '../../components/modals/modalTypes';


const mapStateToProps = ({ user, network, register, verify, upload }, ownProps) => ({
  isSubmittingForm: user.isSubmittingForm,
  isConnected: network.isConnected,
  serverError: user.error,
  progressStep: register.ProgressStep,
  noOfChildren: register.noOfChildren,
  child_details: register.child_details,
  userNoOfChildren: user.kids && user.kids.length,
  adults: user.adults,
  kids: user.kids,
  homeAddress: user.home_address,
  photo_url: user.photo_url,
  selectedDocFile: verify.selectedDocFile,
  isFetching: user.isFetching,
  isUploadingDoc: user.isFetchingUpload,
  isFetchingSuccess: user.isFetchingSuccess,
  docStatus: ownProps.docStatus,
  doc_name: ownProps.doc_name,
  adult_id: ownProps.adult_id,
  adult_name: ownProps.adult_name,
  isFetchingUpload: user.isFetchingUpload,
  progress: upload.progress,
  currentFile: upload.currentFile,
  selectedFiles: upload.selectedFiles,
  blob: upload.blob,
  isVerify: ownProps.isVerify
});

const mapDispatchToProps = (dispatch, props) => ({
  // handleSubmitForm: ({ firstname, surname, email, password }, props) => {
  //
  //   dispatch(submitAboutChildren(firstname, surname, email.toLowerCase(), password, props.navigation));
  // },


  // setSelectedDocFile: (file) => {
  //   console.log('setSelectedDocFile file: ', file);
  //
  //   dispatch(setSelectedDocFile(file));
  //
  // },

  setFileFetching: () => {
    dispatch(setFileFetching());
  },

  setSelectedFiles: (value) => {

    const { adult_id, adult_name, doc_name } = props;
    dispatch(setSelectedFiles(value));
    // if verify, don't hideModal, show preview on modal instead.
    console.log('isVerify:', props.isVerify);
    if (!props.isVerify) {
      dispatch(hideModal()); // for profile photo only - preview is on page
    } else {
      // if Verify show FileUploadModal - for preview
      // dispatch(hideModal());
      dispatch(showModal(MODAL_TYPE_UPLOAD_FILE, {

        cancelAction: () => dispatch(hideModal()),
        adult_id,
        adult_name,
        doc_name
      }));
    }

  },
  setCurrentFile: (value) => {
    console.log('setCurrentFileValue:', value);
    dispatch(setCurrentFile(value));
  },
  setProgress: (value) => {
    dispatch(setUploadProgress(value));
  },
  setBlob: (value) => {
    dispatch(setBlob(value));
  }


});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyFileUploadButton);
