import React from 'react';
import {
  Button, Icon
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';


// opacity: props.opacity || 1

export const StyledButton = styled(Button)`

  height: 38px!important;
  width: 38px!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  flex: ${props => props.flex ? props.flex : null}!important;
  padding: 8px!important;
  margin: 0px!important;
  color: ${colours.gray}!important;
  background-color: ${colours.white}!important;
  border-color: ${colours.lightgray}!important;

  cursor: pointer!important;

`;


const HelpButton = (props) => {
  return (
    <StyledButton
      primary
      icon={props.icon}
      labelPosition={props.icon ? props.labelPosition || 'center' : null}
      fluid={props.fluid}
      compact={props.compact}
      disabled={props.disabled}
      // basic
      // animated

      flex={props.flex}
      // style={{ margin: 0, color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
    >

      <Button.Content style={{ margin: 0 }}>
        <Icon circular name='help' />
      </Button.Content>
    </StyledButton>
  );
};


export default HelpButton;
