export const AVAILABILITY = 'AVAILABILITY';
export const BOOKING = 'BOOKING';
export const PLAN = 'PLAN';
export const COUPON = 'COUPON';
export const UPLOAD = 'UPLOAD';
export const REVIEW = 'REVIEW';
export const CONTACT = 'CONTACT';
export const VERIFY = 'VERIFY';
export const PROFILE = 'PROFILE';
export const SIGNUP = 'SIGNUP';
export const SIGNIN = 'SIGNIN';

export const MODAL_TYPE_EDIT_BOOKING = 'MODAL_TYPE_EDIT_BOOKING';
