import React, { useState, useEffect, useRef } from "react";
import Measure from "react-measure";
import { useUserMedia } from "./hooks/use-user-media";
import { useCardRatio } from "./hooks/use-card-ratio";
import { useOffsets } from "./hooks/use-offsets";
import colours from '../../../styles/colours';
import Spinner from '../spinner';
// import Paragraph from '../paragraph';
import { ConfirmButton } from '../../../styles/styles';
import { B5, H5 } from '../../../styles/text';
import { camera as cameraText } from '../../../text';
import {
  Video,
  Canvas,
  Container,
  Flash,
  SquareOverlay,
  FaceOverlay
} from "./styles";

// const CAPTURE_OPTIONS = {
//   audio: false,
//   video: { facingMode: 'user', width: '200px', height: '300px' } // or 'environment'
// };

export const FRONT_CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: 'user' } // or 'environment'
};

export const BACK_CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: 'environment' } // or 'user'
};


export function CameraSelfie ({ camera, outline, onCapture, onClear, introText, setCameraAspectRatio }) {

  // loads and displays camera, and captures original image as img file

  const canvasRef = useRef();
  const videoRef = useRef();

  // const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
  const [videoAspectRatio, setVideoAspectRatio] = useState(null);
  const [previewDimensions, setPreviewDimensions] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isFlashing, setIsFlashing] = useState(false);
  const [isResized, setIsResized] = useState(false);
  console.info('preferred camera: ', camera); // selfie is front, otherwise rear
  const captureOptions = camera === 'front' ? FRONT_CAPTURE_OPTIONS : BACK_CAPTURE_OPTIONS;
  console.info('captureOptions: ', captureOptions);

  // below sets the width of container to 100%, then can measure and store in state.
  const initialContainerHeight = '100%';

  const mediaStream = useUserMedia(captureOptions);

  // const defaultVideoAspectRatio = null; // we need a aspectRatio to start to ensure there is a height and therefore
  // let videoAspectRatio = defaultVideoAspectRatio;


  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {

    console.info('CameraSelfie camera LOADED!');

    videoRef.current.srcObject = mediaStream;
    // console.info('CameraSelfie videoRef.current:', videoRef.current);
    console.info('CameraSelfie videoRef.current.videoWidth:', videoRef.current && videoRef.current.videoWidth);
    console.info('CameraSelfie videoRef.current.videoHeight:', videoRef.current && videoRef.current.videoHeight);

    const newVideoAspectRatio = videoRef.current.videoWidth / videoRef.current.videoHeight;
    setVideoAspectRatio(newVideoAspectRatio);
    setCameraAspectRatio(newVideoAspectRatio); // for redux


  }

  console.info('CameraSelfie videoAspectRatio:', videoAspectRatio);
  // const [aspectRatio, calculateRatio] = useCardRatio(videoAspectRatio); // THIS?

  // console.info('CameraSelfie aspectRatio:', aspectRatio);

  const containerHeight = container.height === 0 ? initialContainerHeight : container.height; // if camera is portrait (phone), width 100% of screen.
  const containerWidth = container.height !== 0 ? container.height * videoAspectRatio : '100%'; // if no width (height not set yet, waiting for rerender to calculate size, if camera is portrait height is width * aspect;

  // initialise container
  if (container.height === 0) {
    setContainer({
      width: containerWidth, // maintain camera aspect ratio
      height: containerHeight
    });

    const containerAspectRatio = containerWidth / containerHeight;

    const previewHeight = containerHeight * 0.6;
    const previewWidth = previewHeight * containerAspectRatio;

    console.info('CameraSelfie previewHeight:', previewHeight);
    console.info('CameraSelfie previewWidth:', previewWidth);
    console.info('CameraSelfie containerAspectRatio:', containerAspectRatio);

    setPreviewDimensions({
      width: previewWidth,
      height: previewHeight // maintain camera aspect ratio
    });
  }

  console.info('CameraSelfie containerWidth PRE-RESIZE:', containerWidth);
  console.info('CameraSelfie containerHeight PRE-RESIZE:', containerHeight);

  function handleResize (contentRect) {
    console.info('CameraSelfie handle container RESIZE!');
    const newContainerWidth = contentRect.bounds.width;
    const newContainerHeight = contentRect.bounds.height; // why isn't this setting to '100%'? what should this be? '100%' of ?
    console.info('CameraSelfie handleResize contentRect.bounds.width:', contentRect.bounds.width);
    console.info('CameraSelfie handleResize contentRect.bounds.height:', contentRect.bounds.height);
    console.info('CameraSelfie handleResize newContainerWidth:', newContainerWidth);
    console.info('CameraSelfie handleResize newContainerHeight:', newContainerHeight);

    const screenAspectRatio = contentRect.bounds.width / contentRect.bounds.height;


    console.log('CameraSelfie handleResize aspectRatio:', videoAspectRatio);
    // if landscape, we want video to fill 100% of available height
    if (!videoAspectRatio) {

      // setDefaultVideoDimensions
      setVideoDimensions({
        width: newContainerWidth, // 374
        height: newContainerWidth / 1.3 // 374 * 1.3 = 486 // use default aspect ratio to start with until camera aspectRatio loaded.
      });

      setContainer({
        width: newContainerWidth, // maintain camera aspect ratio
        height: newContainerHeight
      });

      const previewHeight = newContainerHeight * 0.6;
      const previewWidth = previewHeight * 1.3; // use default aspect ratio to start with until camera aspectRatio loaded.
      console.log('CameraSelfie handleResize null videoAspectRatio:', videoAspectRatio);
      console.log('CameraSelfie handleResize null previewHeight:', previewHeight);
      console.log('CameraSelfie handleResize null previewWidth:', previewWidth);

      setPreviewDimensions({
        width: previewWidth,
        height: previewHeight // maintain camera aspect ratio
      });

      console.log('CameraSelfie handleResize !aspect container:', container);

    } else if (videoAspectRatio) {
      // camera dimensions and aspect ratio have loaded

      if (screenAspectRatio > 1) {
        console.log('CameraSelfie handleResize landscape');
        setContainer({
          width: newContainerHeight * videoAspectRatio, // maintain camera aspect ratio
          height: newContainerHeight
        });

        setVideoDimensions({
          width: newContainerHeight * videoAspectRatio, // maintain camera aspect ratio,
          height: newContainerHeight
        });

        const previewHeight = newContainerHeight * 0.6;
        const previewWidth = previewHeight * videoAspectRatio;
        console.log('CameraSelfie handleResize landscape aspectRatio:', videoAspectRatio);
        console.log('CameraSelfie handleResize landscape previewHeight:', previewHeight);
        console.log('CameraSelfie handleResize landscape previewWidth:', previewWidth);
        setPreviewDimensions({
          width: previewWidth,
          height: previewHeight // maintain camera aspect ratio
        });

        console.log('CameraSelfie handleResize landscape container:', container);

      } else {
      // if screen is portrait, we want video to fill 100% of available width
        console.log('CameraSelfie handleResize portrait');
        setContainer({
          width: '100%', // newContainerWidth, // don't set width as already 100%
          height: newContainerWidth / videoAspectRatio // maintain camera aspect ratio
        });

        setVideoDimensions({
          width: newContainerHeight * videoAspectRatio,
          height: newContainerHeight // maintain camera aspect ratio
        });

        const previewHeight = newContainerHeight * 1; // this needs to be available height on modal after buttons, headings, etc.
        const previewWidth = previewHeight * videoAspectRatio;
        console.log('CameraSelfie handleResize portrait aspectRatio:', videoAspectRatio);
        console.log('CameraSelfie handleResize portrait previewHeight:', previewHeight);
        console.log('CameraSelfie handleResize portrait previewWidth:', previewWidth);

        setPreviewDimensions({
          width: previewWidth,
          height: previewHeight // maintain camera aspect ratio
        });

        console.log('CameraSelfie handleResize portrait container:', container);
        console.log('CameraSelfie handleResize portrait videoDimensions:', videoDimensions);

        // height: videoDimensions.height, // 746,
        // width: videoDimensions.width, // 1200,

      }

    }

    // need to maintain aspectRatio on these


    // setWindowDimensions({
    //   width: window.innerWidth,
    //   height: window.innerHeight
    // });

  }

  function handleCanPlay () {

    console.info('CameraSelfie camera handle CANPLAY!');
    const newVideoAspectRatio = videoRef.current.videoWidth / videoRef.current.videoHeight;
    setVideoAspectRatio(newVideoAspectRatio);
    setCameraAspectRatio(newVideoAspectRatio); // for redux
    // calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
    videoRef.current.play();

  }

  function handleCapture () {
    const context = canvasRef.current.getContext("2d");

    setContainer({
      width: previewDimensions.width,
      height: previewDimensions.height
    }); // now that photo is taken, make container same size as preview.

    // dimensions of device camera
    console.info('CameraSelfie handleCapture videoRef.current.videoWidth: ', videoRef.current && videoRef.current.videoWidth);
    console.info('CameraSelfie handleCapture videoRef.current.videoHeight: ', videoRef.current && videoRef.current.videoHeight);


    // dimensions of canvas to draw preview on
    console.info('CameraSelfie handleCapture canvasRef.current.width: ', canvasRef.current && canvasRef.current.width);
    console.info('CameraSelfie handleCapture canvasRef.current.height: ', canvasRef.current && canvasRef.current.height);
    // need to set this to correct aspectRatio now have video dimensions.

    // const cameraAspectRatio = videoRef.current.videoWidth ? (videoRef.current.videoWidth / videoRef.current.videoHeight) : null;
    const cameraAspectRatio = videoAspectRatio;
    console.info('CameraSelfie handleCapture cameraAspectRatio: ', cameraAspectRatio); // 1.333
    // preview container AspectRatio
    // const previewAspectRatio =
    // dimensions should match overlay?

    const stretchToWidth = previewDimensions.width;
    const stretchToHeight = previewDimensions.height;

    console.log('CameraSelfie handleCapture stretchToWidth:', stretchToWidth);
    console.log('CameraSelfie handleCapture stretchToHeight:', stretchToHeight);


    // if selfie, then flip the canvas first to take mirrored image
    if (outline === 'face') {
      context.save();
      context.translate(stretchToWidth, 0); // mirrors the canvas
      context.scale(-1, 1); // mirrors the canvas
    }

    // draw image
    context.drawImage(
      videoRef.current,
      0, // offsets.x, // The x coordinate where to start clipping
      0, // offsets.y, // The y coordinate where to start clipping
      videoRef.current.videoWidth, // width of clipped image
      videoRef.current.videoHeight, // height of clipped image
      0, // The x coordinate where to place the image on the canvas
      0, // The y coordinate where to place the image on the canvas
      stretchToWidth, // The width of the image to use (stretch or reduce the image)
      stretchToHeight // The height of the image to use (stretch or reduce the image)
    );

    console.log('canvasRef.current:', canvasRef.current);
    canvasRef.current.toBlob(blob => onCapture(blob), "image/jpeg", 1);
    setIsCanvasEmpty(false);
    setIsFlashing(true);

  } // handleCapture end

  function handleClear () {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true);
    onClear();
  }

  useEffect(() => {

    // similar to componentDidMount, etc
    console.log('CameraSelfie USEEffect');
    console.log('CameraSelfie isResized:', isResized);
    console.info('CameraSelfie USEEffect container:', container);
    // if has not yet resized, then trigger resize
    // if needed, use container dimensions in place of contentRect
    // this is needed to ensure fills page once dimensions have been obtained from camera after first render.

    if (videoRef.current) {

      const newVideoAspectRatio = videoRef.current.videoWidth / videoRef.current.videoHeight;
      setVideoAspectRatio(newVideoAspectRatio);
      setCameraAspectRatio(newVideoAspectRatio); // for redux
      console.log('CameraSelfie set new VideoAspectRatio: ', newVideoAspectRatio);
      // calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
      console.log('CameraSelfie videoAspectRatio: ', videoAspectRatio);
    }

    if (!isResized && videoAspectRatio) {
      const contentRectPreResize = { bounds: { width: container.width, height: container.height } };
      handleResize(contentRectPreResize);
      setIsResized(true);
    }

  });

  // console.info('Camera isVideoPlaying:', isVideoPlaying);
  // console.info('Camera isCanvasEmpty:', isCanvasEmpty);

  if ((isVideoPlaying && !isCanvasEmpty)) {
    return (

      <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Spinner />
      </div>

    );
  }

  // set Timeout or delay or spinner
  setTimeout(() => {
    if (!mediaStream) {
      return (
        <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <B5 color={colours.red}>{'Error: Cannot access camera'}</B5>
        </div>
      );
    }
  }, 1000);


  // face
  // console.info('CameraSelfie container.height:', container.height);
  const overlayHeight = videoDimensions.height * 0.65; // videoDimensions.height * 0.7;
  const overlayWidth = outline === 'face' ? overlayHeight * 0.7 : container.width > 750 ? overlayHeight * 1.6 : overlayHeight * 0.7;
  // console.info('CameraSelfie overlayHeight:', overlayHeight);
  // console.info('CameraSelfie overlayWidth:', overlayWidth);

  const mirrorCameraImage = camera === 'front';


  console.log('CameraSelfie RENDER');
  // console.log('CameraSelfie containerWidth:', containerWidth);
  // console.log('CameraSelfie aspectRatio:', aspectRatio);
  // console.log('CameraSelfie containerHeight:', containerHeight);

  console.info('CameraSelfie container:', container);


  console.log('CameraSelfie isResized:', isResized);
  console.log('CameraSelfie videoDimensions:', videoDimensions);
  console.log('CameraSelfie previewDimensions:', previewDimensions);


  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <div style={{
          display: 'flex',
          // flex-flow: column;
          justifyContent: 'center',
          width: '100%',
          flex: 1,
          // height: '100%', // parent has to have a height, or make this flex: 1?
          overflowX: 'hidden', // this is causing the gap on right - can we remove?
          //
          borderColor: 'orange',
          borderWidth: 0,
          borderStyle: 'solid'
        }}
        >
          <Container
            innerRef={measureRef}
            // maxHeight={videoRef.current && videoDimensions.height}
            // maxWidth={videoRef.current && videoRef.current.videoWidth}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderColor: 'pink',
              borderStyle: 'solid',
              borderWidth: 0,
              overflowX: 'hidden',
              width: container.width, // 100% to ensure grows/shrinks on resize
              height: '100%' // container.height // 746, // `${container.height}px`


            }}
          >
            <Video
              innerRef={videoRef}
              hidden={!isVideoPlaying}
              onCanPlay={handleCanPlay}
              autoPlay
              playsInline
              muted
              mirrored={mirrorCameraImage}
              style={{
                position: 'absolute',
                height: videoDimensions.height, // 746,
                width: videoDimensions.width, // 1200,
                margin: '0 0 0 0',
                borderColor: 'red',
                borderStyle: 'solid',
                borderWidth: 0
              }}

            />
            { !isVideoPlaying &&
              <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner />
              </div>
            }

            {
              isVideoPlaying &&
              <Canvas
                innerRef={canvasRef}
                width={previewDimensions.width} // container.width
                height={previewDimensions.height} // container.height

              />
            }

            <Flash
              flash={isFlashing}
              onAnimationEnd={() => setIsFlashing(false)}
            />

            <div style={{ position: 'absolute', top: 0, bottom: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>

              <div style={{ zIndex: 999, padding: 8, position: 'relative', height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>

                {isVideoPlaying && (


                  <H5 textAlign='center' color={colours.white}>{`${introText}`}</H5>


                )}
              </div>


              <div style={{ position: 'relative', height: '70%', display: 'flex', width: '100%', justifyContent: 'center' }}>

                {
                  isVideoPlaying && outline !== 'face'
                  && <SquareOverlay

                    height={overlayHeight}
                    width={overlayWidth}

                    // width={container.width * 0.9}
                    hidden={!isVideoPlaying}
                    // maxWidth={container.width}
                  />
                }

                {
                  isVideoPlaying && outline === 'face'
                  && <FaceOverlay

                    height={overlayHeight}
                    width={overlayWidth}
                    hidden={!isVideoPlaying}
                  />
                }
              </div>

              <div style={{ position: 'relative', height: '20%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>

                {isVideoPlaying && (
                  <ConfirmButton
                    icon='camera'


                    onClick={isCanvasEmpty ? handleCapture : handleClear}
                  >
                    {isCanvasEmpty ? cameraText.take_photo : cameraText.take_another}
                  </ConfirmButton>
                )}
              </div>

            </div>
          </Container>


        </div>
      )}
    </Measure>
  );

}

export default CameraSelfie;
