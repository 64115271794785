import React from 'react';
import colours from '../styles/colours';

export const welcome = {
  section1_title: 'First of all...',
  section1_para1: 'Thank you for taking the first step and joining our community of parents that share babysitting!',
  // section1_para2: 'We believe you share',
  // intro_more: 'our philosophy',

  section2_title: 'What comes next?',

  section2_para1: 'Complete your profile to get started!',

  // section2_para1: {
  //   a: 'In order to make the full use of our service and for you to be able to contact like-minded families able to sit your children and vice-versa , you will be asked to complete your',
  //   h1: 'family profile',
  //   b: ', which includes:'
  // },

  // section2_bullets: [
  //   'information on the number of children you have, their age, and any allergies, dietary requirements or SEN.',
  //
  //   'your full address (to find the closest families to you).',
  //
  //   'the number of adults (over 16s) residing at your address and their relation to your children.',
  //
  //   'family description (e.g: your occupations, hobbies you do with your children, etc).',
  //
  //   'profile photo.'
  // ],
  section2_para2: 'You can come back to your profile and upload documents to verify you at anytime.',
  learn_more_link: 'Learn more about SittingPoints',

  complete_profile_now_button: `Let's go`,
  later_button: 'Skip for now',

  verify_email_modal: {
    title: '',
    header: 'We sent you an email!',
    para: `Thanks for signing up! Go to your email box to verify your email address. (Note: You may need to check spam folders)`,
    // para2: ``,
    cancel: 'Cancel',
    ok: 'OK'
  }

};

export const children = {

  section1_title: 'Your children',
  section1_para1: 'Please enter details about all children who may require a sitter.',
  lets_go_button: `Add a child`,
  saved_children: `Saved children`,
  add_child: 'Add a child +',
  remove_child: '- Remove this child',
  child: 'Child',
  enter_child: `Enter child`,
  age: `Age`,
  name: <>Name<font color={colours.red}> *</font></>,
  name_eg: 'e.g. William',
  gender: <>Gender<font color={colours.red}> *</font></>,
  boy: 'boy',
  girl: 'girl',
  neutral: 'neutral',
  dob: <>Date of birth<font color={colours.red}> *</font></>,
  special_req: <>Special requirements<br />(SEN, allergies...)<font color={colours.red}> *</font></>,
  yes: 'yes',
  no: 'no',
  sen: {
    title: 'SEN',
    header: 'Special education needs',
    aut: 'Autism',
    asperger: 'Asperger Syndrome',
    ebd: 'EBD',
    adhd: 'ADHD/ADD',
    down: 'Down Syndrome',

    // besd: 'BESD',
    // mld: 'MLD',
    // cp: 'CP',
    // pda: 'PDA',
    // hyperlexia: 'Hyperlexia',

    other: 'Other (please specify)'
  },
  allergies: {
    title: 'Allergies',
    header: 'Food allergies and allergies',
    milk: 'Milk',
    egg: 'Eggs',
    nuts: 'Peanuts/tree nuts',
    shellfish: 'Shellfish',
    wheat: 'Wheat',
    soy: 'Soy',
    fish: 'Fish',

    // gluten: 'Gluten',
    // seafood: 'Seafood',
    // celery: 'Celery',

    other: 'Other (please specify)'

  },

  anything_else: 'Is there anything else you would like to tell us about your child/children that might be important?',
  this_info: `This will be added to your profile under "other important information"`,

  check_details: 'Some fields have errors, please check all fields',
  please_complete_all: 'Please complete all fields',
  required_field: <><font color={colours.red}> * required</font></>,

  modal: {
    update_kids: {
      success_add_another: {
          title: 'Success',
          header: 'Child saved',
          para: 'Would you like to add another child?',
          // para2: ``,
          ok: 'Yes',
          cancel: `No`
      },
      success: {
          title: 'Success',
          header: 'Child saved',
          para: 'Your profile has been updated.',
          // para2: ``,
          ok: 'OK'
      },
      failure: {
        title: 'Update Failed',
        header: 'Oops',
        para: 'Child could not be updated.',
        para2: 'Please contact us if this problem persists.',
        ok: 'OK'
      }
    },
    delete_kids: {
      confirm: {
          title: 'Confirm',
          header: 'Delete child',
          para: `Are you sure you want to remove this child?`,
          // para2: `Your plan will end on`,
          cancel: 'Cancel',
          ok: 'Delete'
      },
      success: {
          title: 'Success',
          header: 'Child removed',
          para: 'Your profile has been updated.',
          // para2: ``,
          ok: 'OK'
      },
      failure: {
        title: 'Update Failed',
        header: 'Oops',
        para: 'Your profile could not be updated.',
        para2: 'Please contact us if this problem persists.',
        ok: 'OK'
      }
    }

  }

};

export const where_live = {
  section1_title: 'Your address',
  section1_para1: 'Enter your postcode and choose your address.',
  add_child: 'Add a child +',
  remove_child: '- Remove this child',
  child: 'Child',
  name: 'Name',
  name_eg: 'e.g. William',
  gender: 'Gender',
  boy: 'boy',
  girl: 'girl',
  neutral: 'neutral',
  dob: 'Date of birth',
  yes: '',
  no: '',
  sen: {
    header: 'Special education needs',
    aut: 'Autistic',
    besd: 'BESD',
    mld: 'MLD',
    cp: 'CP',
    adhd: 'ADHD',
    pda: 'PDA',
    hyperlexia: 'Hyperlexia',
    other: 'Other (please specify)'
  },

  anything_else: '',
  this_info: ''


};

export const adults = {
  section1_title: 'Adults in your family',
  section1_para1: 'Please provide details of all adults or older children living in your household.',
  section2_title: 'Please enter their details:',
  lets_go_button: `Add an adult`,
  saved_children: `Saved adults`,
  add_adult: 'Add an adult +',
  remove_adult: '- Remove this adult',
  adult: 'Adult',
  enter_adult: `Enter adult`,
  name: <>Name<font color={colours.red}> *</font></>,
  name_eg: 'e.g. Sarah',
  surname: <>Surname<font color={colours.red}> *</font></>,
  surname_eg: 'e.g. Jones',

  boy: 'boy',
  girl: 'girl',
  neutral: 'neutral',
  dob: <>Date of birth<font color={colours.red}> *</font></>,
  yes: '',
  no: '',
  relation: {
    header: <>Relation to children<font color={colours.red}> *</font></>,
    short_header: 'Relation',
    parent: 'Parent',
    sibling: 'Sibling',
    grandparent: 'Grandparent',
    uncle_aunt: 'Uncle/Aunt',
    other: 'Other'
  },
  please_specify: 'Please specify',

  anything_else: '',
  this_info: '',

  check_details: 'check details',
  please_complete_all: 'Please complete all fields',
  required_field: <><font color={colours.red}> * required</font></>,

  modal: {
    update_adults: {
      success_add_another: {
          title: 'Success',
          header: 'Adult saved',
          para: 'Would you like to add another adult?',
          // para2: ``,
          ok: 'Yes',
          cancel: `No`
      },
      success: {
          title: 'Success',
          header: 'Adult saved',
          para: 'Your profile has been updated.',
          // para2: ``,
          ok: 'OK'
      },
      failure: {
        title: 'Update Failed',
        header: 'Oops',
        para: 'Adult could not be updated.',
        para2: 'Please contact us if this problem persists.',
        ok: 'OK'
      }
    },
    delete_adults: {
      confirm: {
          title: 'Confirm',
          header: 'Delete adult',
          para: `Are you sure you want to remove this adult?`,
          // para2: `Your plan will end on`,
          cancel: 'Cancel',
          ok: 'Delete'
      },
      success: {
          title: 'Success',
          header: 'Adult removed',
          para: 'Your profile has been updated.',
          // para2: ``,
          ok: 'OK'
      },
      failure: {
        title: 'Update Failed',
        header: 'Oops',
        para: 'Your profile could not be updated.',
        para2: 'Please contact us if this problem persists.',
        ok: 'OK'
      }
    }

  }

};

export const upload_photo = {
  section1_title: 'Profile photo',
  section1_title_edit: 'Profile photo',
  section1_para1: 'Upload a photo of your family',
  section1_para_crop: 'Drag or zoom to crop your picture as you want',
  choose: 'Choose Photo',
  choose_another: 'Choose Another',

  selfie: {
    title: 'Your Selfie',
    header: `Take a photo`,
    para: 'Take a photo of yourself for your profile picture.',
    cancel: 'Cancel',
    or: 'or',
    take_another: 'Take another',
    take_photo: 'Take photo',
    submit: 'Save photo'
  },

  file: {
    upload_another: 'Upload another'
  },

  submit: 'Save photo',
  success: 'Photo uploaded successfully',
  uploading: 'Uploading photo',

  modal: {

    failure: {
      title: 'Upload Failed',
      header: 'Oops',
      para: 'Your file could not be uploaded.  This could be due to a network error, please try again.',
      para2: 'Please contact us if this problem persists.',
      ok: 'OK'
    }

  }

};

export const edit_details = {
  section1_title: 'Your family bio',
  section1_edit_title: 'Account Details',
  section1_para1: `This will help other local families to know a bit more about you and your children. For example, your occupation, hobbies, what you like to do with your children, what they like or anything else you'd like to share.`,
  section1_edit_para1: 'Edit your profile or change account details',
  input_label1: 'Your family description :',
  section_email_title: 'Registered Email',
  section_email_label: 'Email Address',
  section_email_changed: `You will need to check your inbox and confirm your new email.`,
  section_about_you_title: 'About you',
  section_about_you_label1: 'First name',
  section_about_you_label2: 'Surname',

  modal: {
    success: {
        title: 'Success',
        header: 'Update Successful',
        para: 'Your details have been updated ',
        // para2: ``,
        ok: 'OK'
    },
    failure: {
      title: 'Update Failed',
      header: 'Oops',
      para: 'Your details could not be updated.',
      para2: 'Please contact us if this problem persists.',
      ok: 'OK'
    }

  }
};


export const bio = {
  section1_title: 'Your family bio',
  section1_edit_title: 'Your family bio',
  section1_para1: `Help other families to learn a bit more about your family (occupation, hobbies, what you like to do together, and anything else you'd like to share).`,
  section1_edit_para1: `Help other families to learn a bit more about your family (occupation, hobbies, what you like to do together, and anything else you'd like to share).`,
  input_label1: 'Your family description :',
  section_email_title: 'Registered Email',
  section_email_label: 'Email Address',
  section_email_changed: `You will need to check your inbox and confirm your new email.`,
  section_about_you_title: 'About you',
  section_about_you_label1: 'First name',
  section_about_you_label2: 'Surname',


  modal: {
    success: {
        title: 'Success',
        header: 'Update Successful',
        para: 'Your profile has been updated ',
        // para2: ``,
        ok: 'OK'
    },
    failure: {
      title: 'Update Failed',
      header: 'Oops',
      para: 'Your profile could not be updated.',
      para2: 'Please contact us if this problem persists.',
      ok: 'OK'
    }

  }
};


export default welcome;
