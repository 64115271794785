import * as navbar from '../actions/navbar';
import * as search from '../actions/search';
import * as ROUTES from '../constants/routes';
import { ALL } from '../constants/inbox-filters';

// export const OPEN_BURGER = 'OPEN_BURGER';
// export const CLOSE_BURGER = 'CLOSE_BURGER';
// export const OPEN_DROPDOWN_NAV = 'OPEN_DROPDOWN_NAV';
// export const CLOSE_DROPDOWN_NAV = 'CLOSE_DROPDOWN_NAV';
// export const SET_INBOX_FILTER = 'SET_INBOX_FILTER';


export const initialState = {
  burgerIsOpen: false,
  dropdown_nav_is_open: false,
  selectedInboxFilter: ALL,
  thread_back: 'inbox'
};

export default function nav (state = initialState, action) {
  switch (action.type) {

    case navbar.OPEN_BURGER:
      return {
        ...state,
        burgerIsOpen: true
      };

    case navbar.CLOSE_BURGER:
      return {
        ...state,
        burgerIsOpen: false
      };

    case navbar.OPEN_DROPDOWN_NAV:
      return {
        ...state,
        dropdown_nav_is_open: true
      };

    case navbar.CLOSE_DROPDOWN_NAV:
      return {
        ...state,
        dropdown_nav_is_open: false
      };

    case navbar.SET_INBOX_FILTER:
      return {
        ...state,
        selectedInboxFilter: action.data
      };

    case search.SUBMIT_SEARCH_SUCCESS: // fired when nearestusers is returned from search success
      return {
        ...state,
        thread_back: ROUTES.RESULTS
      };

    case navbar.SET_THREAD_BACK:
      return {
        ...state,
        thread_back: action.data
      };

    default:
      return state;
  }
}
