import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { CompatRouter, CompatRoute } from "react-router-dom-v5-compat";
import { ReportGA } from '../utils/ga';
import { store } from '../init-store';
import { getMessageThread } from '../actions/messages';
import * as ROUTES from '../constants/routes';
import { EMAIL } from '../constants/ga-category';
// import { withAuthentication } from './components/Session';
import history from './Navigation/history';

const CompatPrivateRoute = ({ userIsLoggedIn, condition, component: Component, ...rest }) => {

  const handleGetMessageThread = (message_thread_id) => {

    ReportGA({
      category: EMAIL,
      action: `User pressed on button in email with thread Param in url`
    });

    const token = localStorage.sitterpoint_token;

    if (token) {
      console.log('PrivateRoute getMessageThread:', message_thread_id);
      store.dispatch(getMessageThread(token, message_thread_id, ROUTES.THREAD));
    }
  };

  return (
    <CompatRoute {...rest}

      render={(props) => {
        console.log('userIsLoggedIn: ', userIsLoggedIn);
        console.log('props:', props);
        console.log('history:', history);
        console.log('PrivateRoute history.location:', history.location);
        console.log('PrivateRoute props.location:', props.location);

        const searchParams = new URLSearchParams(history.location.search);
        const referralCode = searchParams.get('ref');
        const threadId = Number(searchParams.get('thread'));

        console.log('PrivateRoute threadId:', threadId);
        // if ThreadIdParam, navigate to that thread straight away
        if (threadId) {
          console.log('PrivateRoute threadId');
          // handleGetMessageThread(threadId);
        }

        if (userIsLoggedIn === true) {
          console.log('PrivateRoute userIsLoggedIn:', userIsLoggedIn);
          if (condition === 'editMode set') {
            console.log('PrivateRoute editMode set:');
            // const editMode = props.location.state props.location.state.editMode || props.location.state;
            if (props.location.state === undefined || props.location.state.editMode === undefined) {
              console.log('PrivateRoute location state === undefined');
              return (
                <Redirect to={{
                  pathname: ROUTES.PROFILE_SUMMARY,
                  state: {
                    referral_code: referralCode,
                    thread_id: threadId
                  }
                }} />
              );
            } // else editMode is defined
            console.log('PrivateRoute editMode is defined');
            return (
              <Component {...props} />
            );

          } // else no condition set
          console.log('PrivateRoute no condition set');
          return (
            <Component {...props} />
          );

        }

        console.log('PrivateRoute to landing');

        return (
          <Redirect to={{
            pathname: ROUTES.LANDING,
            state: {
              referral_code: referralCode,
              thread_id: threadId
            }
          }} />
        );

      }}
    />
  );
};


export default CompatPrivateRoute;
