import React, { useState } from 'react';
import {
  Button, Icon
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { other } from '../../../text';

// opacity: props.opacity || 1

export const StyledButton = styled(Button).attrs(props => ({
  width: props.width || null
}))`

  zIndex: ${props => props.isHovered ? 999 : null}!important;

  flex: 1!important;
  margin: 0px!important;
  text-align: center!important;


  color: ${props => props.active ? colours.logopurple : props.isHovered ? colours.verylightgray : colours.logopurple}!important;

  background: ${props => props.active ? colours.white : props.isHovered ? colours.orange : colours.white}!important;


  border-width: 0px!important;

  border-radius: ${props => props.rounded ? '30px' : '0px'}!important;
  border-width: ${props => props.active ? props.rounded ? '1px' : '0px' : '0px'}!important;
  border-bottom-width: ${props => props.active ? props.rounded ? '1px' : '2px' : '0px'}!important;
  border-style: solid!important;
  border-color: ${props => props.light ? colours.darkpurple : colours.logopurple}!important;

  padding: 8px!important;
  padding-top: 16px!important;
  padding-left: ${props => props.active ? props.rounded ? '7px' : '16px' : '16px'}!important;
  padding-right: ${props => props.active ? props.rounded ? '7px' : '16px' : '16px'}!important;
  padding-bottom: ${props => props.active ? props.rounded ? '7px' : '6px' : '8px'}!important;
  min-width: 60px!important;
  min-height: 50px!important;
  margin-bottom: 8px!important;
  margin-left: 8px!important;
  margin-right: 8px!important;

  cursor: pointer!important;



`;


export const TabButton = (props) => {
  // console.log('EditButton.props:', props);

  const [hover, setHover] = useState(false);

  const toggleHover = (newHover) => {
      if (hover === undefined) {
        setHover(!hover);
      } else {
        setHover(newHover);
      }
  };

  const handleClick = () => {
    if (!props.active) {
      props.onClick();

      toggleHover(false); // removes any hover effects on click
    }
  };


  const isHovered = hover;

  return (
    <StyledButton
      primary
      // icon={props.icon}
      labelPosition={props.icon ? props.labelPosition || 'left' : null}
      fluid={props.fluid}
      compact={props.compact}
      disabled={props.disabled}
      width={props.width}

      active={props.active}

      isHovered={isHovered}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}

      // onClick={props.onClick}
      onClick={() => handleClick()}

    >
      { // <Icon name={props.icon || 'edit'} />
      }
      {props.children || other.edit}
    </StyledButton>
  );
};

export default TabButton;
