import React from 'react';
import { FooterLink, FooterText, FooterWrapper, NavButton } from '../../styles/styles';
import Section from './section';
import SectionHeader from './section-header';
import CardHeader from './card-header';
import { landing, footer } from '../../text';
import history from '../Navigation/history';
import * as ROUTES from '../../constants/routes';
// import * as ROLES from '../../constants/roles';
import { H1, D4, H3, T4, H5, B3, B4, B5, H2, A6 } from '../../styles/text';
import colours from '../../styles/colours';
import { versionNo } from '../../constants/env';

console.log('process.env.REACT_APP_APP_NODE_ENV:', process.env.REACT_APP_APP_NODE_ENV);
const debugMode = process.env.REACT_APP_APP_NODE_ENV !== 'production';

const Footer = ({ cookiesAccepted, user_id, firstname, small, background, window_width }) => {


  const breakpoint = 700;


  return (
    <Section fullWidth noSidePadding noBottomPadding backgroundColor={background || colours.logopurple}>
      {small &&
        <CardHeader light background={colours.logopurple} title={landing.ourPromise.title} alignTitle='center' alignSubtitle='center' subtitle={landing.ourPromise.subtitle} highlightedSubtitle={landing.ourPromise.highlightedSubtitle} />
      }
      {!small &&
        <Section fullWidth backgroundColor={colours.logopurple}>
          <SectionHeader light background={colours.logopurple} title={landing.ourPromise.title} subtitle={landing.ourPromise.subtitle} highlightedSubtitle={landing.ourPromise.highlightedSubtitle} />
        </Section>
      }

      <div style={{ background: colours.logopurple, width: '100%', paddingLeft: 0, paddingRight: 0, paddingTop: 12, paddingBottom: 18, display: 'flex', flexDirection: window_width < breakpoint ? 'column' : 'row', alignItems: window_width < breakpoint ? 'center' : 'center', justifyContent: 'center', flex: 1, borderWidth: 0, borderStyle: 'none', borderColor: colours.gray }}>

        <NavButton
          onClick={() => history.push(ROUTES.HOWITWORKS)}
        >
          {footer.how}
        </NavButton>

        <div style={{ height: window_width < breakpoint ? 1 : 20, width: window_width < breakpoint ? '10%' : 1, marginTop: window_width < breakpoint ? 8 : 0, marginBottom: window_width < breakpoint ? 8 : 0, marginLeft: 4, marginRight: 4, background: colours.borderColour }} />

        <NavButton
          onClick={() => history.push(ROUTES.SITTER_POINTS)}
        >
          {footer.points}
        </NavButton>

        <div style={{ height: window_width < breakpoint ? 1 : 20, width: window_width < breakpoint ? '10%' : 1, marginTop: window_width < breakpoint ? 8 : 0, marginBottom: window_width < breakpoint ? 8 : 0, marginLeft: 4, marginRight: 4, background: colours.borderColour }} />

        <NavButton
          onClick={() => history.push(ROUTES.PHILOSOPHY)}
        >
          {footer.philosophy}
        </NavButton>
        <div style={{ height: window_width < breakpoint ? 1 : 20, width: window_width < breakpoint ? '10%' : 1, marginTop: window_width < breakpoint ? 8 : 0, marginBottom: window_width < breakpoint ? 8 : 0, marginLeft: 4, marginRight: 4, background: colours.borderColour }} />

        <NavButton
          onClick={() => history.push(ROUTES.FAQ)}
        >
          {footer.faq}
        </NavButton>
        <div style={{ height: window_width < breakpoint ? 1 : 20, width: window_width < breakpoint ? '10%' : 1, marginTop: window_width < breakpoint ? 8 : 0, marginBottom: window_width < breakpoint ? 8 : 0, marginLeft: 4, marginRight: 4, background: colours.borderColour }} />

        <NavButton
          onClick={() => history.push(ROUTES.PRICING)}
        >
          {footer.plans}
        </NavButton>
        <div style={{ height: window_width < breakpoint ? 1 : 20, width: window_width < breakpoint ? '10%' : 1, marginTop: window_width < breakpoint ? 8 : 0, marginBottom: window_width < breakpoint ? 8 : 0, marginLeft: 4, marginRight: 4, background: colours.borderColour }} />
        <NavButton
          onClick={() => history.push(ROUTES.CONTACT)}
        >
          {footer.contact}
        </NavButton>

      </div>

      <div style={{ background: colours.logopurple, width: '100%', paddingLeft: 0, paddingRight: 0, paddingTop: 12, paddingBottom: 18, display: 'flex', flexDirection: 'column', alignItems: window_width < breakpoint ? 'center' : 'center', justifyContent: 'center', flex: 1, borderWidth: 0, borderStyle: 'none', borderColor: colours.logopurple }}>
        <FooterText>{`© ${new Date().getFullYear()} ${footer.legal1}`}</FooterText>
        <FooterText>{footer.read_our} <FooterLink to={ROUTES.TERMS}>{footer.tc}</FooterLink> {footer.and} <FooterLink to={ROUTES.PRIVACY}>{footer.pp}</FooterLink>.</FooterText>
        <FooterText>{`v${versionNo}`}</FooterText>
        {
          debugMode && user_id
          && <>
            <FooterText>Logged in as</FooterText>
            <FooterText color={colours.red}>&nbsp;{`${user_id}`}</FooterText>
            <FooterText color={colours.red}>&nbsp;{firstname}&nbsp;</FooterText>
          </>
        }
      </div>

      <div
        style={{
        background: colours.logopurple, width: '100%',
        height: cookiesAccepted || cookiesAccepted === undefined ? 16 : 216 // if landing page cookiesAccepted will be false or true (if accepted). Other pages will be undefined.
      }} />

    </Section>
  );

};

export default Footer;
