import React from 'react';
// import { Checkmark } from 'react-checkmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Exclmark } from './animated-exclamation/index';
import moment from 'moment';
import { HeaderbarWrapper } from '../../styles/styles';
import { D4, A6 } from '../../styles/text';
import NavBarContainer from '../../containers/Navigation/navbar-container';
import RegisterProgBarContainer from '../../containers/Navigation/registerProgBar-container';
import history from '../Navigation/history';
import colours from '../../styles/colours';

const AnimatedExclamationLarge = ({ }) => {


    return (


      <div style={{ paddingLeft: 0, paddingRight: 0, flexDirection: 'row', alignItems: 'center', paddingBottom: 2, display: 'flex', background: 'transparent' }}>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <Exclmark size='80px' />

        </div>

      </div>


    );
};

export default AnimatedExclamationLarge;
