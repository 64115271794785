import { connect } from 'react-redux';
import ConfirmEmail from '../../components/auth/confirm-email';
import { confirmEmail, confirmEmailReset } from '../../actions/auth/confirm-email';
import { hideModal } from '../../actions/modal';

const mapStateToProps = ({ forgotPassword }) => ({
  successMessage: forgotPassword.success_message,
  isFetching: forgotPassword.isFetching,
  errorMessage: forgotPassword.error

});

const mapDispatchToProps = dispatch => ({
  hideModal: () => {
    dispatch(confirmEmailReset());
    dispatch(hideModal());
  },
  handleSubmitForm: ({ email }, dispatch, props) => { //eslint-disable-line
    dispatch(confirmEmail(email.toLowerCase()));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
