export const NAV = 'NAV';
export const INVITE = 'INVITE';
export const WELCOME = 'WELCOME';
export const PLAN = 'PLAN';
export const BETA = 'BETA';
export const CONTACT = 'CONTACT';
export const LANDING = 'LANDING';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_IN = 'SIGN_IN';
export const SEARCH = 'SEARCH';
export const HELP = 'HELP';
export const ACCOUNT = 'ACCOUNT';
export const PROFILE = 'PROFILE';
export const VERIFY = 'VERIFY';
export const UPLOAD = 'UPLOAD';
export const AVAILABILITY = 'AVAILABILITY';
export const CALENDAR = 'CALENDAR';
export const PAYMENTS = 'PAYMENTS';
export const MESSAGES = 'MESSAGES';
export const POINTS = 'POINTS';
export const DASHBOARD = 'DASHBOARD';
export const CTA = 'CTA';
export const EMAIL = 'EMAIL';
