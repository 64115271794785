import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DynamicComponent } from '../../dynamic-component';
import history from '../../../components/Navigation/history';
import { default_theme } from '../../default-theme';

const {
  bullet
} = default_theme.theme_details.otherStyles;

const mapStateToProps = ({ style }, ownProps) => {
    // console.log('style:', style);

    const getTagPropsToUse = (nav) => {
      let result = {};
      if (nav === '/typo') {
        result = style.editing_theme.theme_details.otherStyles.bullet;
      } else if (!style.available_themes) {
        result = bullet;
      } else {
        result = style.available_themes.reduce((acc, item) => {
          if (item.selected) {
            return item.theme_details.otherStyles.bullet;
          }
          return acc;
        }, []);
      }
      return result;

    };
    const nav = history.location.pathname;
    const tagPropsToUse = getTagPropsToUse(nav);

    return {
      tagProps: tagPropsToUse,
      ownProps
    };

};


class Bullet extends Component {

  render () {

    const { tagProps, ownProps } = this.props;

    return (
      <DynamicComponent {...tagProps} {...ownProps}>
        {this.props.children}
      </DynamicComponent>
    );

  }
}

export default connect(
    mapStateToProps
)(Bullet);
