import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { persistor } from '../../init-store';
import Spinner from '../general/spinner';
import { ConfirmButton, BackButton } from '../../styles/styles';
import CellButton from './cell-button';
import { H3 } from '../../styles/text';
import WeekdaysHeader from './weekdays-header';
import { getYear, getMonth, getMonthNo, daysInMonth, currentDate, currentDay, todaysDate, todaysMonth, todaysYear, todaysDay, isBefore, isInPast, firstDayOfMonth, lastDayOfMonth, firstDateOfMonth, lastDateOfMonth, weekdays, weekdaysShort, months } from '../../lib/timeHelpers';
import { getDaysBookings, isOverlappingOtherBookings } from '../../lib/bookingsHelpers';
import colours from '../../styles/colours';
import { other } from '../../text';
import * as BOOKING_TYPES from '../../constants/booking-types';
import * as BOOKING_STATUS from '../../constants/booking-status';


const inActiveTextColor = colours.mediumlightgray;
const activeTextColor = colours.orange;
const inActiveBorderColor = colours.mediumlightgray;

class SearchDatePicker extends Component {

 
  constructor (props) {
    super(props);
    // console.log('constructor this props', this.props);


    this.state = {

      dateContext: moment(),
      today: moment(),
      dimensions: {
        width: 0,
        height: 0
      }
    };

    this.container = React.createRef();
    this.onDayClick = this.onDayClick.bind(this);


  }


  componentDidMount () {
    // console.log('did mount this.container:', this.container);
    console.group('searchDate did mount');
    console.log('searchDate did mount this.container.offsetWidth:', this.container.offsetWidth);
    console.log('searchDate did mount this.container.offsetHeight:', this.container.offsetHeight);
    console.groupEnd();

    this.setState({
      dimensions: {
        width: this.container.offsetWidth,
        height: this.container.offsetHeight
      }
    });

    // need to duplicate this listener as well as in app to ensure dateboxes scale correctly
    window.addEventListener('resize', this.handleWindowSizeChange); // does this duplicate theevent listener in App?

  }

  componentWillUnmount () {

    window.removeEventListener('resize', _.throttle(this.handleWindowSizeChange, 20));
  }


  handleWindowSizeChange = () => {

    if (this.container !== null) {
      // console.log('this.container.offsetWidth:', this.container.offsetWidth);
    }

    // update dimensions of calendar container and recalc cellSize
    if (this.container === null) {
      // console.log('do nothing');
    } else if (this.container.offsetWidth === null || this.container.offsetHeight === null) {
        // console.log('do nothing');
    } else {

      console.group('searchDate did handleWindowSizeChange');
      console.log('searchDate handleWindowSizeChange offsetWidth', this.container.offsetWidth);
      console.log('searchDate handleWindowSizeChange offsetHeight', this.container.offsetHeight);
      console.groupEnd();

      this.setState({
        dimensions: {
          width: this.container.offsetWidth,
          height: this.container.offsetHeight
        }
      });

    }


  };


  // handleClickProfile = () => {
  //
  //   history.push({
  //     pathname: ROUTES.PROFILE_SUMMARY
  //   });
  //
  // }

  // cal functions

  year = () => {
      return this.state.dateContext.format('Y');
  }

  month = () => {
      return this.state.dateContext.format('MMMM');
  }

  daysInMonth = () => {
      return this.state.dateContext.daysInMonth();
  }

  currentDate = () => {
      // console.log('currentDate: ', this.state.dateContext.get('date'));
      return this.state.dateContext.get('date');
  }

  currentDay = () => {
      return this.state.dateContext.format('D');
  }

  todaysDate = () => {
      console.log('isDateInPast todaysDate: ', this.state.today.get('date'));
      return this.state.today.get('date');
  }

  todaysMonth = () => {
      // console.log('todaysMonth: ', this.state.today.format('MMMM'));
      return this.state.today.format('MMMM');
  }

  todaysYear = () => {
      // console.log('todaysYear: ', this.state.today.format('Y'));
      return this.state.today.format('Y');
  }

  todaysDay = () => {
      return this.state.today.format('D');
  }

  isBefore = (date, comparisonDate) => {
    // console.log('date: ', date);
    // console.log('comparisonDate:', comparisonDate);
    return moment(date).isBefore(comparisonDate, 'day');
  };

  isInPast = (date) => {

    const isDayInPast = moment(date).isBefore(this.state.today, 'day');
    console.log('isDateInPast isDayInPast:', isDayInPast);
    return moment(date).isBefore(this.state.today, 'day');
  }

  setMonth = (month) => {
      const monthNo = this.months.indexOf(month);
      const { dateContext } = this.state;
      let dateObject = Object.assign({}, dateContext);
      dateObject = moment(dateObject).set('month', monthNo);
      this.setState({
        dateContext: dateObject
      });
  }

  nextMonth = () => {
      let dateContext = Object.assign({}, this.state.dateContext);
      dateContext = moment(dateContext).add(1, "month");
      this.setState({
          dateContext: dateContext
      });
      this.props.onNextMonth && this.props.onNextMonth();
  }

  prevMonth = () => {
      let dateContext = Object.assign({}, this.state.dateContext);
      dateContext = moment(dateContext).subtract(1, "month");
      console.log('Month prevMonthDateContext:', dateContext);
      console.log('month this.state.today:', this.state.today);
      const firstDayOfTodaysMonth = moment(this.state.today).startOf('month');
      console.log('month firstDayOfTodaysMonth:', firstDayOfTodaysMonth);

      if (!this.isBefore(dateContext, firstDayOfTodaysMonth)) {
        console.log('Month dateContext not before today:', this.state.today);
        this.setState({
            dateContext: dateContext
        });
        this.props.onPrevMonth && this.props.onPrevMonth();
      }


  }

  firstDayOfMonth = () => {
      let dateContext = this.state.dateContext;
      let firstDay = moment(dateContext).startOf('month').format('d'); // Day of week 0...1..5...6
      return firstDay;
  }

  onSelectChange = (e, data) => {
      this.setMonth(data);
      this.props.onMonthChange && this.props.onMonthChange();

  }

  SelectList = (props) => {
      let popup = props.data.map((data) => {
          return (
              <div key={data}>
                  <a href="#" onClick={(e)=> {this.onSelectChange(e, data)}}>
                      {data}
                  </a>
              </div>
          );
      });

      return (
          <div className="month-popup">
              {popup}
          </div>
      );
  }

  onChangeMonth = (e, month) => {
      // below code is to Select month directly (not used)
      // this.setState({
      //     showMonthPopup: !this.state.showMonthPopup
      // });
  }

  MonthNav = () => {
      return (
        <span className='label-month'
          // onClick={(e)=> {this.onChangeMonth(e, this.month())}}
        >
          {this.month()}
          {
            // below code is to Select month directly (not used)
            // {this.state.showMonthPopup &&
            //  <this.SelectList data={this.months} />
            // }
          }
        </span>
      );
  }

  showYearEditor = () => {
      this.setState({
          showYearNav: true
      });
  }

  setYear = (year) => {

      let dateContext = Object.assign({}, this.state.dateContext);
      dateContext = moment(dateContext).set("year", year);

      this.setState({
          dateContext: dateContext
      })
  }

  onYearChange = (e) => {
      this.setYear(e.target.value);
      this.props.onYearChange && this.props.onYearChange(e, e.target.value);
  }

  onKeyUpYear = (e) => {
      if (e.which === 13 || e.which === 27) {
          this.setYear(e.target.value);
          this.setState({
              showYearNav: false
          })
      }
  }

  YearNav = () => {
      return (
        // below code is to Select year directly (not used)
        // this.state.showYearNav ?

          // <input
          //   defaultValue = {this.year()}
          //   className="editor-year"
          //   ref={(yearInput) => { this.yearInput = yearInput}}
          //   onKeyUp= {(e) => this.onKeyUpYear(e)}
          //   onChange = {(e) => this.onYearChange(e)}
          //   type="number"
          //   placeholder="year"/>
        // :

        <span
          className='label-year'
          // below code is to Select year directly (not used)
          // onDoubleClick={(e)=> { this.showYearEditor()}}
        >
          {this.year()}
        </span>
      );
  }

  onDayClick = (e, day, earliestDate, latestDate, isDateInPast) => {
      console.log('DayClickProps:', this.props);
      // console.log('isDateInPast:', isDateInPast);
      const { thread_status } = this.props
      if (isDateInPast && (thread_status !== 'pre_authorised' && thread_status !== 'approved')) {
        return;
      }
      console.log('onDayClick this.props', this.props);
      const { setStartDate, setEndDate } = this.props;

      const dateMoment = this.getMoment(day);
      console.log('SELECTED new dateMOment: ', dateMoment);

      console.log('earliestDate: ', earliestDate);
      console.log('latestDate: ', latestDate);

      // refactor to use start_date and end_dates throughout.
      if (earliestDate && (latestDate === null)) {

        if (moment(dateMoment, 'DD-MM-YYYY').isBefore(moment(earliestDate, 'DD-MM-YYYY'), 'day')) {
          // if clicked on an earlier date
          console.log('isbeforeOnly date');
          setStartDate(dateMoment);
          setEndDate(null);
        } else if (moment(dateMoment, 'DD-MM-YYYY').isSame(moment(earliestDate, 'DD-MM-YYYY'), 'day')) {
            // if clicked on only selected date (start_date)
            console.log('isOnly date');
            setStartDate(null);
            setEndDate(null);
        } else if (moment(dateMoment, 'DD-MM-YYYY').isAfter(moment(earliestDate, 'DD-MM-YYYY'), 'day')) {
            // if clicked date after only selected date (start_date)
            console.log('isAfterOnly date');
            setEndDate(dateMoment);
        } else {
          // or clicked on another date, set end of range
          console.log('other - null enddate');
          setEndDate(null);
        }

      // if there is an end date
      } else if (moment(dateMoment, 'DD-MM-YYYY').isSame(moment(earliestDate, 'DD-MM-YYYY'), 'day')) {
        console.log('isSameAsFirst');
        // if clicked on first day, remove selection
        setStartDate(null);
        setEndDate(null);
      } else if (moment(dateMoment, 'DD-MM-YYYY').isSame(moment(latestDate, 'DD-MM-YYYY'), 'day')) {
        console.log('isSameAsLast');
        // if clicked on last day
        setEndDate(null);
      } else if (moment(dateMoment, 'DD-MM-YYYY').isBefore(moment(earliestDate, 'DD-MM-YYYY'), 'day')) {
        console.log('isBefore');
        // if clicked before firstDay, create new range
        setStartDate(dateMoment);
        setEndDate(null);
      } else if (moment(dateMoment, 'DD-MM-YYYY').isAfter(moment(earliestDate, 'DD-MM-YYYY'), 'day') && moment(dateMoment).isBefore(moment(latestDate, 'DD-MM-YYYY'), 'day')) {
        console.log('isMiddle');
        // if in middle of range, create new range
        setStartDate(dateMoment);
        setEndDate(null);
      } else if (moment(dateMoment, 'DD-MM-YYYY').isAfter(moment(latestDate, 'DD-MM-YYYY'), 'day')) {
        console.log('isAfter');
        setStartDate(dateMoment);
        setEndDate(null);
      } else {
        console.log('other');
        setStartDate(dateMoment);
        setEndDate(null);
      }

  }


  isPresent = (dateObject, selected_dates) => {

    // console.log('this.props', this.props);
    // const { selected_dates } = this.props;

    // console.log('dateObject:', dateObject);
    // console.log('selected_dates:', selected_dates);

    return selected_dates.filter(object => !this.isSameObject(object, dateObject)).length !== selected_dates.length;
  }

  getEarliestDate = (array) => {
    const earliestDate = moment.min(array);
    return earliestDate;
  }

  getLatestDate = (array) => {
    const latestDate = moment.max(array);
    return latestDate;
  }

  getMoment = (d) => {
    // console.log('d:', d);

    const createdMoment = moment().year(this.year()).month(this.month()).date(d);
    // console.log('getMoment createdMoment:', createdMoment);
    return moment(createdMoment).format('DD-MM-YYYY');
  };

  isInSelectedRange = (dateObject, start_date, end_date) => {

    const startMoment = moment(start_date, 'DD-MM-YYYY');
    const endMoment = moment(end_date, 'DD-MM-YYYY');


      if (moment(dateObject, 'DD-MM-YYYY').isBefore(startMoment)) {
        return false;
      }

      if (moment(dateObject, 'DD-MM-YYYY').isSameOrAfter(startMoment, 'day')) {
        if ((end_date === null) && moment(dateObject, 'DD-MM-YYYY').isSame(startMoment, 'day')) {
          return true;
        }
        if (end_date && moment(dateObject, 'DD-MM-YYYY').isSameOrBefore(endMoment, 'day')) {
          return true;
        }
        if (end_date && moment(dateObject, 'DD-MM-YYYY').isAfter(endMoment, 'day')) {
          return false;
        }
      }

      return false;

  }


  isSameObject = (first, second) => {
    // console.log('first:', first);
    // console.log('second:', second);
    return (
      first.year === second.year
      && first.month === second.month
      && first.day === second.day
    );
  }

  isBooked = (availabilityObject) => {

    // console.log('this.props', this.props);
    const { booked_dates } = this.props;

    // console.log('booked_dates:', booked_dates);
    //
    // console.log('XavailabilityObject:', availabilityObject);

    const isBookedArray = booked_dates.filter((bookedDateObject) => {
      const isBookedObjectSame = this.isSameObject(bookedDateObject, availabilityObject);
      // console.log('XisSame:', isBookedObjectSame);
      return isBookedObjectSame;
    });
    // console.log('XisBookedArray:', isBookedArray);
    const isBookedResult = (!isBookedArray || isBookedArray.length === 0) ? null : isBookedArray[0];

    // console.log('XisBookedResult:', isBookedResult);
    return isBookedResult;
  }


  previousPage = () => {

      // if search datepicker close window,

      const { handleClickBackButton } = this.props;
      // if click is inside, do something
      handleClickBackButton();

      // else go Back
      // history.goBack();


  }

  renderButton = () => {


    const { editMode, isSavingAvailability, handleSubmit, start_date, end_date } = this.props;
    // console.log('render OK button: start_date:', start_date);
    // console.log('render OK button: end_date:', end_date);

    if (isSavingAvailability) {
      return <div style={{ justifyContent: 'center', alignItems: 'center' }}><Spinner size={20} /></div>;
    }

    return (

      <ConfirmButton

        testID='SearchDatePicker OK'

        disabled={isSavingAvailability}
        onClick={() => handleSubmit(editMode, start_date, end_date)}
      >
        {other.ok}
      </ConfirmButton>


    );


  };

  renderContent () {

    // only renders if dimensions of container stored in state - needed to set cellWidth

    const { start_date, end_date, window_width, combinedBookingsArray } = this.props;

    const { dimensions } = this.state;

    console.log('renderContent dimensions:', dimensions);

    const containerWidth = dimensions.width;

    const columns = 7; // days of the week;

    // console.log('renderContent containerWidth:', containerWidth);

    // use if want exactly square cells
    // const cellSize = size = Math.min(containerWidth / columns, containerHeight / rows);

    // use if want full width and min height based on contents - maintain round inner circle
    const cellWidth = containerWidth / columns;


    const blanks = []; // blanks is days of preceding month falling into this month view.
    const thefirstDayOfMonth = firstDayOfMonth(this.state.dateContext); // weekday number that first day falls on

    for (let i = 1; i < thefirstDayOfMonth; i++) {

      // remove first day of Month - i for each extra blank day
      // need to remove i days to first day in month moment()
      const thefirstDateOfMonth = firstDateOfMonth();


      // const extraDateValueStart = moment(firstDateInMonth).subtract(daysToSubtract, 'days').format('D');

      // subtract i days
      // const extraDateValueStart = moment(firstDateInMonth).subtract(daysToSubtract, 'days').format('D');

      const extraDateValueStart = moment(thefirstDateOfMonth, 'DD/MM/YYYY').subtract(thefirstDayOfMonth - i, 'd').format('D');

      // date cell
      blanks.push(
        <CellButton key={`blank${i}`}
          cellWidth={cellWidth}
          isPrevMonth
          borderColor={inActiveBorderColor}
          backgroundColor='transparent'
          textColor={inActiveTextColor}
        >
          {extraDateValueStart}
        </CellButton>
      );
    }


    const daysInThisMonth = [];

    for (let d = 1; d <= daysInMonth(this.state.dateContext); d++) {

      const isTodaysContext = (getMonth(this.state.dateContext) === todaysMonth(this.state.today)) && (getYear(this.state.dateContext) === todaysYear(this.state.today));
      const isToday = isTodaysContext && (todaysDate(this.state.today) === d);

      let dContext = Object.assign({}, this.state.dateContext);
      dContext = moment(dContext).set('date', d);
      // console.log('isDateInPast dContext', dContext);
      // console.log('isDateInPast this.state.today', this.state.today);
      const isDateInPast = isInPast(dContext, this.state.today);
      // console.log('d:', d);
      // console.log('dContext:', dContext);
      // console.log('this.state.dateContext:', this.state.dateContext);

      const date = {
        year: getYear(this.state.dateContext),
        month: getMonth(this.state.dateContext),
        day: d
      };

      const dateObjectMoment = this.getMoment(d);

      // console.log('dateObjectMoment: ', dateObjectMoment);
      // console.log('start_date: ', start_date);
      // console.log('end_date: ', end_date);
      // const selectedEndDate
      const isInSelectedRange = this.isInSelectedRange(dateObjectMoment, start_date, end_date);
      // console.log('isInSelectedRange', isInSelectedRange);


      // isbooked/available logic
      // may need to move out of render

      const dString = d.toString().padStart(2, 0); // converts 1 to 01 for d in datestring.

      const dateString = `${getYear(this.state.dateContext)}-${getMonthNo(this.state.dateContext)}-${dString}`;

      const daysBookingsArray = getDaysBookings(dateString, combinedBookingsArray);

      // console.log(`${dateString} daysBookingsArray:`, daysBookingsArray);
      // need following:
      const available = [];
      const sitbooked = [];
      const sitpending = [];
      const reqBooked = [];
      const reqPending = [];
      const clashes = [];

      daysBookingsArray.map((item) => {
        // if item is overlapping with another
        let start_timestamp;
        let end_timestamp;

        if (!item.available_dates) {
          start_timestamp = item.start;
          end_timestamp = item.end;
        } else {
          // parse available_dates
          // ["2021-08-20 02:30:00","2021-08-20 05:30:00"]
          const parsed_dateRange = JSON.parse(item.available_dates);
          const dateRangeArray = [...parsed_dateRange];
          // console.log('parsed_dateRange:', parsed_dateRange);
          start_timestamp = dateRangeArray[0];
          end_timestamp = dateRangeArray[1];
        }
        const isClashing = isOverlappingOtherBookings(start_timestamp, end_timestamp, daysBookingsArray); // should be all or just the day's?
        // console.log('isClashing:', isClashing);
        if (isClashing) {
          clashes.push(item);
        }
        if (item.booking_type === BOOKING_TYPES.AVAILABLE) {
          available.push(item);
        }
        if (item.booking_type === BOOKING_TYPES.SITTING) {
          if (item.status === BOOKING_STATUS.UNCONFIRMED) {
            sitpending.push(item);
          } else {
            sitbooked.push(item);
          }
        }
        if (item.booking_type === BOOKING_TYPES.USING) {
          if (item.status === BOOKING_STATUS.UNCONFIRMED) {
            reqPending.push(item);
          } else {
            reqBooked.push(item);
          }
        }

      });

      const isAvailable = available.length > 0;
      const isSitBooked = sitbooked.length > 0;
      const isSitPending = sitpending.length > 0;
      const isReqBooked = reqBooked.length > 0;
      const isReqPending = reqPending.length > 0;
      const hasClashes = clashes.length > 0;

      // is booked/available logic end

      // RESTRICT PAST DATES FROM CLICKS?


      let borderColor = colours.darkpurple;
      let textColor = colours.orange; // future or today

      const { thread_status } = this.props;
      // console.log('isInSelectedRange:', isInSelectedRange);

      // console.log(`isDateInPast ${d}:`, isDateInPast);
      if (isDateInPast) {
        // final or not finalising
        if ((thread_status !== 'pre_authorised') && (thread_status !== 'approved')) {
          // backgroundColor = colours.past;
          borderColor = 'transparent';
          textColor = inActiveTextColor;
        }
        // finalising and selected
        // if (isInSelectedRange) {
        //   backgroundColor = colours.blue;
        //   textColor = colours.white;
        // }


      } else { // not in past

        if (isToday) {
          // today
            borderColor = colours.orange;
            textColor = activeTextColor;
        }

        if (!isToday) {
          // not today
            textColor = activeTextColor;
            borderColor = 'transparent';
        }

        // if requested
        // if (partBookedType === 'using' && partBookedStatus === 'unconfirmed') {
        //   backgroundColor = colours.requested;
        // }
        // // if requested and confirmed
        // if (partBookedType === 'using' && partBookedStatus === 'confirmed') {
        //   backgroundColor = colours.using;
        // }
        // // if sitting and confirmed
        // if (partBookedType === 'sitting' && partBookedStatus === 'confirmed') {
        //   backgroundColor = colours.sitting;
        // }

      }

      // date cell
      daysInThisMonth.push(
        <CellButton key={d}
          // generic formatting
          cellWidth={cellWidth}

          // date specific formatting
          isInPast={isDateInPast}
          isToday={isToday}
          // to do
          isAvailable={isAvailable}
          hasClashes={hasClashes}
          isSitBooked={isSitBooked}
          isSitPending={isSitPending}
          isReqBooked={isReqBooked}
          isReqPending={isReqPending}


          // needed?
          borderColor={borderColor}
          backgroundColor='transparent'
          textColor={textColor}
          date={date}

          isInSelectedRange={isInSelectedRange}

          onClick={ (e) => { this.onDayClick(e, d, start_date, end_date, isDateInPast); }}
        >
          {d}
        </CellButton>

      );
    }

    // console.log('days: ', daysInThisMonth);
    const provisionalSlots = [...blanks, ...daysInThisMonth];

    const provisionalSlotsLength = provisionalSlots.length;
    // console.log('provisionalSlotsLength:', provisionalSlotsLength);
    const divBySeven = provisionalSlots.length / 7;
    // console.log('divBySeven:', divBySeven);
    const roundedUpLength = Math.ceil(divBySeven);
    // console.log('roundedUpLength:', roundedUpLength); // gives number of weeks
    const daysToShow = roundedUpLength * 7;
    // console.log('daysToShow:', daysToShow); // gives number of days

    // is multiple of 7, if not push extra to make multiple of 7.
    const extraDays = daysToShow - provisionalSlotsLength;
    const thelastDateInMonth = lastDateOfMonth();

    const blanksEnd = []; // blanks is days of next month falling into this month view.
    for (let i = 0; i < extraDays; i++) {


      // add last day of Month + i for each extra blank day
      // need to add i days to last day in month moment()

      // console.log('thelastDateInMonth:', thelastDateInMonth);

      // add i days
      const extraDateValueEnd = moment(thelastDateInMonth, 'DD/MM/YYYY').add(i + 1, 'd').format('D');


      // date cell
      blanksEnd.push(
        <CellButton key={`blank${i}`}
          cellWidth={cellWidth}
          isNextMonth
          borderColor={inActiveBorderColor}
          backgroundColor='transparent'
          textColor={inActiveTextColor}
        >
          {extraDateValueEnd}
        </CellButton>
      );
    }

    // calculate total number of cells to render
    const totalSlots = [...blanks, ...daysInThisMonth, ...blanksEnd];
    const rows = [];
    let cells = [];

    // console.log('totalSlots:', totalSlots);

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row); // if index not equal 7 that means not go to next week
      } else {
        rows.push(cells); // when reach next week we contain all td in last week to rows
        // console.log('cells:', cells);
        // console.log('rows:', rows);
        cells = []; // empty container
        cells.push(row); // in current loop we still push current row to new container
      }
      if (i === totalSlots.length - 1) { // when end loop we add remain date

        // console.log('cells:', cells);

        rows.push(cells);
      }
    });

    const clickableCells = rows.map((row, i) => {

      if (i === 0) {
        return;
      }
      const keyText = `${i}r`;


      return (

        <div key={keyText} style={{ borderColor: 'green', borderWidth: 0, borderStyle: 'solid', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {row}
          {
            // add weekbutton eventually to copy/paste full weeks
            // <WeekButton cellWidth={cellWidth} />
          }
        </div>

      );
    });

    // const calendar_width = (
    //   window_width < 340 ? window_width - 40
    //   : window_width < 400 ? window_width - 40
    //   : window_width < 460 ? 380
    //   : window_width < 600 ? 380
    //   : window_width < 700 ? 380
    //   : window_width < 740 ? 320
    //   : 340 // once screen beyond 700 only half screen
    // );

    // console.log('month this.state.dateContext', this.state.dateContext);

    const isTodaysContext = (getMonth(this.state.dateContext) === todaysMonth(this.state.today)) && (getYear(this.state.dateContext) === todaysYear(this.state.today));


    return (


      <div style={{ borderWidth: 0, width: '100%', borderColor: 'pink', borderStyle: 'solid', flexDirection: 'column', display: 'flex', alignItems: 'center' }}>

        {
          // key popup on narrow screen
        }
        {
          // (window_width <= 450) &&
          // <div style={{ width: '100%', paddingTop: 4, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}><CalendarKeyPopUp orientation='column' /></div>
        }


        {
          // calendar section
        }

        <div className='calendar' style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', borderWidth: 0, borderColor: 'blue', borderStyle: 'solid' }}>

          {
            // Month Nav
          }
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row', borderWidth: 0, borderColor: 'red', borderStyle: 'solid', justifyContent: 'center' }}>

            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', borderWidth: 0, borderColor: 'red', borderStyle: 'solid' }}>
              {
                !isTodaysContext
                && <BackButton
                  paddingLeft='14px'
                  style={{ padding: 0, margin: 0 }}
                  onClick={() => { this.prevMonth(); }}
                />
              }

            </div>
            <div style={{ display: 'flex', flex: 5, justifyContent: 'center', borderWidth: 0, borderColor: 'green', borderStyle: 'solid' }}>
              <H3
                marginTop='8px'
                marginBottom='8px'
              >
                <this.MonthNav />
                {' '}
                <this.YearNav />
              </H3>
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', borderWidth: 0, borderColor: 'red', borderStyle: 'solid' }}>
              <BackButton
                noIcon
                rightIcon='chevron right'
                paddingRight='14px'
                style={{ padding: 0, margin: 0 }}
                onClick={() => { this.nextMonth(); }}
              />
            </div>

          </div>

          <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', borderWidth: 0, borderColor: 'red', borderStyle: 'solid' }}>

            { // headers
            }
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', borderWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderColor: colours.lightgray, borderStyle: 'solid' }}>
              <WeekdaysHeader />
              { // rightSpacer
                // only needed if add WeekButton
                // <div style={{ borderStyle: 'solid', borderWidth: 0, borderLeftWidth: 1, borderColor: colours.mediumlightgray, width: (cellWidth / 1.5) - 3 }} />
              }
            </div>

            { // cells - days
            }
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', borderWidth: 0, borderRightWidth: 1, borderColor: colours.mediumlightgray, borderStyle: 'solid' }}>
              {clickableCells}
            </div>


          </div>
        </div>
        {
          // key on wide screen
        }
        {
          // (window_width >= 450) &&
          // <CalendarKey search orientation='row' />
        }

        {
          // action section
        }


        <div style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', display: 'flex', paddingTop: 8, paddingBottom: 8, borderColor: colours.mediumlightgray, borderWidth: 0, borderTopWidth: 1, borderStyle: 'solid' }}>
          <div style={{ paddingLeft: 10, paddingRight: 10, borderColor: 'blue', borderWidth: 0, borderStyle: 'solid' }}>
            <BackButton onClick={ () => this.previousPage() }>{'Back'}</BackButton>
          </div>
          <div style={{ borderColor: 'green', display: 'flex', borderWidth: 0, borderStyle: 'solid' }}>
            { this.renderButton() }
          </div>

        </div>


      </div>


    );


  }


  render () {

    const { dimensions } = this.state;
    const { window_width } = this.props;
    // console.log('window_width:', window_width);
    // console.log('dimensions:', dimensions);

    const setContainerWidth =
      window_width > 758 ? (758 / 2) - 4
      : window_width > 700 ? (window_width / 2) - 4
      : window_width - 26;

    console.log('setContainerWidth:', setContainerWidth);

    return (


      <div className='calendar-container' ref={(el) => { this.container = el; }} style={{ width: setContainerWidth, maxWidth: 375, background: colours.white, borderColor: 'blue', borderWidth: 0, borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 4, paddingLeft: 4 }}>

        {dimensions && this.renderContent()}

      </div>

    );


  }


}


export default SearchDatePicker;
