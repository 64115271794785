import React from 'react';
// import { Checkmark } from 'react-checkmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Checkmark } from './animated-tick/index';
import moment from 'moment';
import { HeaderbarWrapper } from '../../styles/styles';
import { D4, A6 } from '../../styles/text';
import NavBarContainer from '../../containers/Navigation/navbar-container';
import RegisterProgBarContainer from '../../containers/Navigation/registerProgBar-container';
import history from '../Navigation/history';
import colours from '../../styles/colours';

const AnimatedTickLarge = ({ fillColor, tickColor, borderColor }) => {

    const useFillColor = !fillColor ? '#7ac142' : fillColor;
    const useTickColor = !tickColor ? colours.white : tickColor;
    const useBorderColor = !borderColor ? '#7ac142' : borderColor;

    return (


      <div style={{ paddingLeft: 0, paddingRight: 0, flexDirection: 'row', alignItems: 'center', paddingBottom: 2, display: 'flex', background: 'transparent' }}>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <Checkmark size='80px' fillColor={useFillColor} tickColor={useTickColor} borderColor={useBorderColor} />
          {
            // <FontAwesomeIcon
            //   icon={faCheckCircle}
            //   size='sm'
            //   style={{ height: 30, width: 30, color: useColor }} />

          }
        </div>

      </div>


    );
};

export default AnimatedTickLarge;
