import { submitKids, SUBMIT_KIDS_REQUEST, SUBMIT_KIDS_SUCCESS, SUBMIT_KIDS_FAILURE } from './submit-kids';
import { submitAdults, SUBMIT_ADULTS_REQUEST, SUBMIT_ADULTS_SUCCESS, SUBMIT_ADULTS_FAILURE } from './submit-adults';
import { submitHomeAddress, SUBMIT_HOME_ADDRESS_REQUEST, SUBMIT_HOME_ADDRESS_SUCCESS, SUBMIT_HOME_ADDRESS_FAILURE } from './submit-home-address';
import { submitEditBio, SUBMIT_EDIT_BIO_REQUEST, SUBMIT_EDIT_BIO_SUCCESS, SUBMIT_EDIT_BIO_FAILURE } from './bio';
import { submitEditDetails, SUBMIT_EDIT_DETAILS_REQUEST, SUBMIT_EDIT_DETAILS_SUCCESS, SUBMIT_EDIT_DETAILS_FAILURE } from './edit-details';

import { submitRegistration, SUBMIT_REGISTRATION_REQUEST, SUBMIT_REGISTRATION_SUCCESS, SUBMIT_REGISTRATION_FAILURE } from './registration';

import { uploadPhoto, SET_SELECTED_PHOTO_FILE, UPLOAD_PHOTO_REQUEST, UPLOAD_PHOTO_SUCCESS, UPLOAD_PHOTO_FAILURE } from './upload-photo';
import { submitUpdateKids, submitDeleteChild } from './update-kids';
import { submitUpdateAdults, submitDeleteAdult } from './update-adults';

export {
  submitKids, SUBMIT_KIDS_REQUEST, SUBMIT_KIDS_SUCCESS, SUBMIT_KIDS_FAILURE,
  submitAdults, SUBMIT_ADULTS_REQUEST, SUBMIT_ADULTS_SUCCESS, SUBMIT_ADULTS_FAILURE,
  submitHomeAddress, SUBMIT_HOME_ADDRESS_REQUEST, SUBMIT_HOME_ADDRESS_SUCCESS, SUBMIT_HOME_ADDRESS_FAILURE,
  submitEditBio, SUBMIT_EDIT_BIO_REQUEST, SUBMIT_EDIT_BIO_SUCCESS, SUBMIT_EDIT_BIO_FAILURE,
  submitEditDetails, SUBMIT_EDIT_DETAILS_REQUEST, SUBMIT_EDIT_DETAILS_SUCCESS, SUBMIT_EDIT_DETAILS_FAILURE,
  submitRegistration, SUBMIT_REGISTRATION_REQUEST, SUBMIT_REGISTRATION_SUCCESS, SUBMIT_REGISTRATION_FAILURE,

  uploadPhoto, SET_SELECTED_PHOTO_FILE, UPLOAD_PHOTO_REQUEST, UPLOAD_PHOTO_SUCCESS, UPLOAD_PHOTO_FAILURE,
  submitUpdateKids, submitDeleteChild,
  submitUpdateAdults, submitDeleteAdult
};

export const SET_PROGRESS_STEP = 'SET_PROGRESS_STEP';
export const INCREMENT_PROGRESS_STEP = 'INCREMENT_PROGRESS_STEP';
export const DECREMENT_PROGRESS_STEP = 'DECREMENT_PROGRESS_STEP';


export const setProgressStep = data => ({
  type: SET_PROGRESS_STEP,
  data
});

export const incrementProgressStep = () => ({
  type: INCREMENT_PROGRESS_STEP
});

export const decrementProgressStep = () => ({
  type: DECREMENT_PROGRESS_STEP
});
