// import React from 'react';
import styled from 'styled-components';

import {

  space,
  lineHeight,
  fontSize,
  fontStyle,
  size,
  color,
  colorStyle,
  textStyle,
  fontFamily,
  fontWeight,
  letterSpacing,
  borderRadius,
  width,
  height,
  alignItems,
  display,
  justifyContent

} from 'styled-system';


export const DynamicComponent = styled.div`
  ${space}
  ${fontSize}
  ${fontStyle}
  ${color}
  ${size}
  ${colorStyle}
  ${textStyle}
  ${lineHeight}
  ${letterSpacing}
  ${fontFamily}
  ${fontWeight}
  ${borderRadius}
  ${width}
  ${height}
  ${alignItems}
  ${justifyContent}
  ${display}


`;

export const DynamicAComponent = styled.a`
  ${space}
  ${fontSize}
  ${fontStyle}
  ${color}
  ${size}
  ${colorStyle}
  ${textStyle}
  ${lineHeight}
  ${letterSpacing}
  ${fontFamily}
  ${fontWeight}
  ${borderRadius}
  ${width}
  ${height}
  ${alignItems}
  ${justifyContent}
  ${display}

`;

export const DynamicInputComponent = styled.input`
  ${space}
  ${fontSize}
  ${fontStyle}
  ${color}
  ${size}
  ${colorStyle}
  ${textStyle}
  ${lineHeight}
  ${letterSpacing}
  ${fontFamily}
  ${fontWeight}
  ${borderRadius}
  ${width}
  ${height}
  ${alignItems}
  ${justifyContent}
  ${display}

`;

export const DynamicTextAreaComponent = styled.textarea`
  ${space}
  ${fontSize}
  ${fontStyle}
  ${color}
  ${size}
  ${colorStyle}
  ${textStyle}
  ${lineHeight}
  ${letterSpacing}
  ${fontFamily}
  ${fontWeight}
  ${borderRadius}
  ${width}
  ${height}
  ${alignItems}
  ${justifyContent}
  ${display}

`;


export default DynamicComponent;

//
// const DynamicComponent = ({ tag = "div", children, ...props }) => {
//   const WithComponent = StyledDynamicComponent.withComponent(tag);
//   return <WithComponent {...props}>{children}</WithComponent>;
// };
//
// DynamicComponent.defaultProps = {
//   tag: "div"
// };
//
// export default DynamicComponent;
