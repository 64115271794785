
// create the image with a src of the base64 string
const createImage = url => new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });

export const getCroppedImg = async (imageSrc, crop) => {
    // console.log('getUncroppedImg imageSrc:', imageSrc);
    const image = await createImage(imageSrc);
    // console.log('getUncroppedImg image:', image);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');


    /* setting canvas width & height allows us to
    resize from the original image resolution */

    canvas.width = 250;
    canvas.height = 250;

    console.log('crop:', crop);

    ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
    );

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            // console.log('getUncroppedImg blob:', blob);
            resolve(blob);
        }, 'image/jpeg');
    });
};


export const getUncroppedImg = async (imageSrc, crop) => {

  // console.log('getUncroppedImg imageSrc:', imageSrc);
  const image = await createImage(imageSrc);
  // console.log('getUncroppedImg image:', image);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');


  /* setting canvas width & height allows us to
  resize from the original image resolution */

  // set to width of image as not cropping
  canvas.width = crop.width;
  canvas.height = crop.height;

  const cropAspectRatio = crop.width / crop.height;
  console.log('getUncroppedImg crop:', crop);
  console.log('crop aspectRatio:', cropAspectRatio);
  // crop.width and crop.height are original image dimensions

  // if aspectRatio is less than 1 - image is portrait

  // if image is not being cropped, then use original image dimensions?

  ctx.drawImage(
      image,
      0, // 0
      0, // 0
      crop.width, // 754
      crop.height, // 566
      0,
      0,
      crop.width, // canvas.width,
      crop.height // canvas.height


  );

  console.log('ctx:', ctx);

  return new Promise((resolve) => {
      canvas.toBlob((blob) => {
          // console.log('getUncroppedImg blob:', blob);
          resolve(blob);
      }, 'image/jpeg');
  });


};

export default createImage;
// export default connectAlert(hoistNonReactStatic(decoratedComponent, Signup));
