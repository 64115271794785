import React, { Component } from 'react';
import Bugsnag from '@bugsnag/js';
import './App.css';
import { ThemeProvider } from "styled-components";
import default_theme from "./styles/default-theme";
import { SetGA } from './utils/ga';
// import bugsnagClient from './lib/bugsnag';
// import GlobalStyle from './styles/global';
import RoutesContainer from './containers/routes-container';
import { versionNo } from './constants/env';
import moment from 'moment';
import 'moment/locale/en-gb';

moment.locale('en-gb'); // ensures Monday is first day of week, etc


// import { withAuthentication } from './components/Session';


class App extends Component {

  constructor (props) {
    super(props);

    const { setWindowWidth, setWindowHeight } = this.props;
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }


  componentDidMount () {


    window.addEventListener('resize', this.handleWindowSizeChange);

    const { setEditMode, user_id, firstname, surname, email, handleGetPlans } = this.props;
    console.log('App DidMount user_id:', user_id);
    console.log('App DidMount props:', this.props);

    if (Bugsnag) {
      // Bugsnag.notify(new Error('App Test error')); // sends test error to Bugsnag to check integration
      console.log('Bug reporting available');
      // set release version
      // versionNo
    } else {
      console.log('Bug reporting blocked or not available');
    }

    // clearSearchQuery(); // resets search bar and clears search results - DONT do it here because means can't refresh or go back to results page

    if (user_id) {
      if (Bugsnag) {
          Bugsnag.setUser(user_id, email, `${firstname} ${surname}`);
      }


      // bugsnagClient.user = { id: user_id, name: `${firstname} ${surname}`, email, versionNo };

      // set Google Analytics user_id if exists
      SetGA({
        userId: user_id
        // any data that is relevant to the user session
        // that you would like to track with google analytics
      });

      setEditMode(false);

      handleGetPlans();

    }

  }

  componentDidUpdate () {

    const { user_id, firstname, surname, email } = this.props;
    console.log('App DidUpdate user_id:', user_id);

    if (user_id) {
      if (Bugsnag) {
        Bugsnag.setUser(user_id, email, `${firstname} ${surname}`);
      }


      // bugsnagClient.user = { id: user_id, name: `${firstname} ${surname}`, email, versionNo };

      // set Google Analytics user_id if exists
      SetGA({
        userId: user_id
        // any data that is relevant to the user session
        // that you would like to track with google analytics
      });


    }

  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowSizeChange);

    const { clearSearchQuery } = this.props;

    // clears Search params when leaving the app
    clearSearchQuery(); // resets search bar

  }

  handleWindowSizeChange = () => {
    const { setWindowWidth, setWindowHeight } = this.props;
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };


  render () {

    // const vhHeight = document.documentElement.clientHeight;
    console.log('App render versionNo:', versionNo);
    const { window_height } = this.props;

    // console.log('App render props:', this.props);

    return (
      <div style={{ width: '100%', maxWidth: 758, marginLeft: 'auto', marginRight: 'auto', height: window_height, overflow: 'hidden', borderWidth: 0, borderColor: 'green', borderStyle: 'solid' }}>
        <ThemeProvider theme={default_theme}>
          <RoutesContainer />
        </ThemeProvider>

      </div>
    );
  }
}

export default App;
