import { connect } from 'react-redux';
import DropdownRecurValue from '../../components/layout/dropdowns/recurValue';
import { saveSelectedRecurrance } from '../../actions/calendar';

const mapStateToProps = ({ user, dropdownHourValue, search }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  user_id: user.user_id,
  open: dropdownHourValue.isHourFromOpen,
  saved_start_hr: search.saved_start_hr,
  saved_end_hr: search.saved_end_hr,
  saved_start_min: search.saved_start_min,
  saved_end_min: search.saved_end_min,
  value: ownProps.value,
  selected_recurrance: search.selected_recurrance

});

const mapDispatchToProps = dispatch => ({


  saveSelectedRecurrance: (data) => {

    dispatch(saveSelectedRecurrance(data));

  }

});

export default connect(mapStateToProps, mapDispatchToProps)(DropdownRecurValue);
