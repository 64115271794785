
export const sit_details = {

  review_details: 'Details',
  authorise_details: 'Sit Details',
  confirm_details: 'Confirm Details',
  sit_details: 'Sit Details',

  page_title: '',
  section_edit_title: 'Edited Sit Details',
  section_amend_title: 'Amend Final Details',
  cancel: 'Cancel',
  cancel_request: 'Cancel Sit Request',
  cancel_decline: `Sorry, I can't make it`,
  cancel_pre_approve: 'Cancel Pre-Approval',
  cancel_approval: 'Cancel Approval',
  cancel_exit_edit: 'Go Back',
  cancel_expired_not_approved: `Sit Didn't Happen`,
  cancel_approved_not_happened: `Sit Didn't Happen`,
  cancel_expired_pre_authorised: '',
  pre_approve: 'Pre-Approve Sit',
  pre_approve_sitting: 'Pre-approve Sitting',
  approve_sitting: 'Confirm Sitting',
  approve: 'approve',
  edit_request_details: 'Edit Sit Details',
  amend_final_details: 'Amend Final Details',
  edit_request_send: 'Update',
  pre_authorise_final_details: 'Confirm Final Details',
  approve_final_details: 'Approve Final Details',
  review: 'Review Sitting',
  message: 'Message',
  quoted_points: 'Total Points for this sit',
  total_points: 'Total Points for this sit',
  actual_points: 'Actual Points',

  sen_header: 'SEN',
  allergies_header: 'Allergies',
  children_header: 'Children',
  children_details_header: 'Children Details',


  buttons: {
    review: 'Review Sitting'
  },


  modal: {
    fetching_thread: {
      fetching: `retrieving...`,
      failure: {
          title: 'Send Failed',
          header: 'Oops',
          para: 'Your message could not be sent.  This could be due to a network error, please try again.',
          para2: 'Please contact us if this problem persists.',
          ok: 'OK'
      }
    },

    approve: {
      fetching: 'processing...',
      confirm: {
        title: 'Confirm',
        header: 'Are you sure you want to confirm the sit?',
        para: `By confirming you are committing to the sit and points will be debited from your SP balance`,
        para2: `Points will not be refunded if the sit is subsequently cancelled by you.`,
        cancel: 'Cancel',
        ok: 'OK'
      },
      success: {
        member: {
          title: 'Success',
          header: 'Booking Successful',
          para: 'Your sit has been confirmed.',
          // para2: ``,
          ok: 'OK'
        },
        sub: {
          title: 'Success',
          header: 'Payment Successful',
          para: 'Your sit has been confirmed.',
          para2: `You are now a monthly member and can book an unlimited number of sits without paying fees.`,
          ok: 'OK'
        }

      },
      failure: {
        payg: {
          title: 'Booking Failed',
          header: 'Oops',
          para: 'Your payment was approved but your sit could not be confirmed.  This could be due to a network error, please try again.',
          para2: 'Please contact us if this problem persists.',
          ok: 'OK'
        },
        sub: {
          title: 'Booking Failed',
          header: 'Oops',
          para: 'Your payment was approved but your sit could not be confirmed.  This could be due to a network error, please try again.',
          para2: 'Please contact us if this problem persists.',
          ok: 'OK'
        },
        member: {
          title: 'Booking Failed',
          header: 'Oops',
          para: 'Your sit could not be confirmed.  This could be due to a network error, please try again.',
          para2: 'Please contact us if this problem persists.',
          ok: 'OK'
        }

      }
    },
    message: {
      fetching: 'sending...',
      // confirm: {
      //   title: 'Confirm',
      //   header: 'Are you sure you want to pre-approve the sit?',
      //   para: `By confirming you are committing to the sit and the sitter will rely on you to be available at this time`,
      //   para2: `Points will credited to your account after the sit has taken place and you have confirmed the actual drop-off/pick-up times.`,
      //   cancel: 'Cancel',
      //   ok: 'OK'
      // },
      success: {
          title: 'Success',
          header: 'Message sent',
          para: 'Your message was sent',
          para2: `to`, // receiver name??
          ok: 'OK'
      },
      failure: {
          title: 'Send Failed',
          header: 'Oops',
          para: 'Your message could not be sent.  This could be due to a network error, please try again.',
          para2: 'Please contact us if this problem persists.',
          ok: 'OK'
      }
    },
    pre_approve: {
      fetching: 'sending...',
      confirm: {
        title: 'Confirm',
        header: 'Are you sure you want to pre-approve the sit?',
        para: `By confirming you are committing to the sit and the sitter will rely on you to be available at this time`,
        para2: `Points will credited to your account after the sit has taken place and you have confirmed the actual drop-off/pick-up times.`,
        cancel: 'Cancel',
        ok: 'OK'
      },
      success: {
          title: 'Success',
          header: 'Pre-approval Successful',
          para: 'Confirmation has been sent to ', // requester name
          // para2: ``,
          ok: 'OK'
      },
      failure: {
          title: 'Pre-approval Failed',
          header: 'Oops',
          para: 'Your pre-approval could not be confirmed.  This could be due to a network error, please try again.',
          para2: 'Please contact us if this problem persists.',
          ok: 'OK'
      }
    },
    pre_authorise: {
      fetching: 'sending...',
      confirm: {
        title: 'Confirm',
        header: 'Are you sure you want to confirm these details?',
        para: `If `, // requester name
        para1a: ` confirms these details, points will credited to your account and the sit will be finalised.`,
        // para2: ``,
        cancel: 'Cancel',
        ok: 'OK'
      },
      success: {
          title: 'Success',
          header: 'Details confirmed',
          para: 'These details have been sent to ', // requester name
          // para2: ``,
          ok: 'OK'
      },
      failure: {
          title: 'Confirmation Failed',
          header: 'Oops',
          para: 'Your details could not be confirmed.  This could be due to a network error, please try again.',
          para2: 'Please contact us if this problem persists.',
          ok: 'OK'
      }
    },
    finalise: {
      fetching: 'sending...',
      confirm: {
        title: 'Confirm',
        header: 'Are you sure you want to finalise the sit?',
        para: `By confirming any points adjustment will be debited to your account and the sit details can no longer be amended.`,
        // para2: ``,
        cancel: 'Cancel',
        ok: 'OK'
      },
      success: {
          title: 'Success',
          header: 'Sit Finalised',
          para: `The sit is now finalised, Thank you.`,
          an_additional: `An additional `, // ${points_adjustment}
          para2: ` points were transferred.`,
          ok: 'OK'
      },
      failure: {
          title: 'Request Failed',
          header: 'Oops',
          para: 'Your sit could not be finalised.  This could be due to a network error, please try again.',
          para2: 'Please contact us if this problem persists.',
          ok: 'OK'
      }
    },
    edit_sit_details: {
      fetching: 'sending...',
      edit: {
        title: 'Edit Sit Details',
        header: '',
        para: `You can make changes to your booking below to re-request to host.`,
        para2: `If your sit has already been confirmed, this will put the sit back to pre-approved status.`,
        cancel: 'Cancel',
        ok: 'OK',
        update: `Update`
      }
      // success: {
      //     title: 'Success',
      //     header: 'Update Successful',
      //     para: 'New details have been sent to ', // requester name
      //     // para2: ``,
      //     ok: 'OK'
      // },
      // failure: {
      //     title: 'Update Failed',
      //     header: 'Oops',
      //     para: 'Your sit details good not be updated.  This could be due to a network error, please try again.',
      //     para2: 'Please contact us if this problem persists.',
      //     ok: 'OK'
      // }
    },
    send_edited: {
      fetching: 'sending...',
      // confirm: {
      //   title: 'Confirm',
      //   header: 'Are you sure you want to pre-approve the sit?',
      //   para: `By confirming you are committing to the sit and the sitter will rely on you to be available at this time`,
      //   para2: `Points will credited to your account after the sit has taken place and you have confirmed the actual drop-off/pick-up times.`,
      //   cancel: 'Cancel',
      //   ok: 'OK'
      // },
      success: {
          title: 'Success',
          header: 'Update Successful',
          para: 'New details have been sent to ', // requester name
          // para2: ``,
          ok: 'OK'
      },
      failure: {
          title: 'Update Failed',
          header: 'Oops',
          para: 'Your sit details good not be updated.  This could be due to a network error, please try again.',
          para2: 'Please contact us if this problem persists.',
          ok: 'OK'
      }
    },
    cancel: {
      fetching: 'cancelling...',
      request: {
        requester: {
          confirm: {
            title: 'Confirm',
            header: 'Are you sure you want to cancel the sit?',
            para: `By cancelling`, // sitter_name
            para2: `will be informed you no longer need a sitter at this time.`,
            cancel: 'Back',
            ok: 'Cancel Sit'
          },
          success: {
              title: 'Success',
              header: 'Sit request cancelled',
              para: 'Confirmation has been sent to ', // sitter name
              // para2: ``,
              ok: 'OK'
          },
          failure: {
              title: 'Cancellation Failed',
              header: 'Oops',
              para: 'Your sit request could not be cancelled.  This could be due to a network error, please try again.',
              para2: 'Please contact us if this problem persists.',
              ok: 'OK'
          }
        },
        sitter: {
          confirm: {
            title: 'Confirm',
            header: 'Are you sure you want to decline the sit?',
            para: `By declining`, // requester_name
            para2: `will be informed you are unable to sit at this time.`,
            cancel: 'Back',
            ok: 'Decline Sit'
          },
          success: {
              title: 'Success',
              header: 'Sit declined',
              para: 'Confirmation has been sent to ', // requester name
              // para2: ``,
              ok: 'OK'
          },
          failure: {
              title: 'Cancellation Failed',
              header: 'Oops',
              para: 'The sit could not be declined.  This could be due to a network error, please try again.',
              para2: 'Please contact us if this problem persists.',
              ok: 'OK'
          }
        }
      },

      pre_approved: {
        requester: {
          confirm: {
            title: 'Confirm',
            header: 'Are you sure you want to cancel the sit?',
            para: `By cancelling`, // requester_name
            para2: `will be informed you no longer need a sitter at this time.`,
            cancel: 'Back',
            ok: 'Cancel Sit'
          },
          success: {
              title: 'Success',
              header: 'Sit request cancelled',
              para: 'Confirmation has been sent to ', // requester name
              // para2: ``,
              ok: 'OK'
          },
          failure: {
              title: 'Cancellation Failed',
              header: 'Oops',
              para: 'Your request could not be cancelled.  This could be due to a network error, please try again.',
              para2: 'Please contact us if this problem persists.',
              ok: 'OK'
          }
        },
        sitter: {
          confirm: {
            title: 'Confirm',
            header: 'Are you sure?',
            para: `By cancelling`, // requester_name
            para2: `will be informed you can no longer sit at this time.`,
            cancel: 'Back',
            ok: 'Cancel Sit'
          },
          success: {
              title: 'Success',
              header: 'Sit pre-approval cancelled',
              para: 'Confirmation has been sent to ', // requester name
              // para2: ``,
              ok: 'OK'
          },
          failure: {
              title: 'Cancellation Failed',
              header: 'Oops',
              para: 'Your pre-approval could not be cancelled.  This could be due to a network error, please try again.',
              para2: 'Please contact us if this problem persists.',
              ok: 'OK'
          }
        }

      },
      approved: {
        requester: {
          confirm: {
            title: 'Confirm',
            header: 'Are you sure you want to cancel the sit?',
            para: `By cancelling`, // requester_name
            para2: `will be informed you no longer need them to sit at this time. As the sit had already been confirmed you will not be refunded points for the first hour of this sit.`,
            cancel: 'Back',
            ok: 'Cancel Sit'
          },
          success: {
              title: 'Success',
              header: 'Sit cancelled',
              para: 'Confirmation has been sent to ', // requester name
              // para2: ``,
              ok: 'OK'
          },
          failure: {
              title: 'Cancellation Failed',
              header: 'Oops',
              para: 'Your sit could not be cancelled.  This could be due to a network error, please try again.',
              para2: 'Please contact us if this problem persists.',
              ok: 'OK'
          }
        },
        sitter: {
          confirm: {
            title: 'Confirm',
            header: 'Are you sure you want to cancel the sit?',
            para: `By cancelling`, // requester_name
            para2: `will be informed you can no longer sit at this time.`,
            cancel: 'Back',
            ok: 'Cancel Sit'
          },
          success: {
              title: 'Success',
              header: 'Sit pre-approval cancelled',
              para: 'Confirmation has been sent to ', // requester name
              // para2: ``,
              ok: 'OK'
          },
          failure: {
              title: 'Cancellation Failed',
              header: 'Oops',
              para: 'Your pre-approval could not be cancelled.  This could be due to a network error, please try again.',
              para2: 'Please contact us if this problem persists.',
              ok: 'OK'
          }
        }
      }
    }

  }

};


export default sit_details;
