import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import hoistNonReactStatic from 'hoist-non-react-statics';
import {
    Modal,
    Button
} from "semantic-ui-react";
import { ReportGA } from '../../utils/ga';
import { persistor } from '../../init-store';
import { FormTextInput, FormPasswordInput, FormCheckInput } from './form-components';
import { signupValidator as validate } from './form-validation';
import Spinner from '../general/spinner';
import history from '../Navigation/history';
import { Link } from '../../styles/buttons';
import { Row } from '../../styles/styles';
import { H4, A5, InputErrorText } from '../../styles/text';
import { signup } from '../../text';
import colours from '../../styles/colours';
import { SIGN_UP, SIGN_IN } from '../../constants/ga-category';
// import { getAPPURI } from '../../constants/env';
import * as ROUTES from '../../constants/routes';

// const url = getAPPURI();
// import { connectAlert } from '../Alert';

// const { Answers } = Fabric;

class Signup extends Component {

  constructor (props) {
    super(props);
    // console.log('constructor this props', this.props);
    this.showSignInModal = this.showSignInModal.bind(this);


  }


  componentDidMount () {

    persistor.pause();

    ReportGA({
      category: SIGN_UP,
      action: "Signup modal shown to user"
    });


    // Answers.logCustom('Signup.js Mounted', { additionalData: 'nothing' });
  }


  showSignInModal () {
    const { showSignInModal } = this.props;

    ReportGA({
      category: SIGN_IN,
      action: "User pressed Sign in button on sign up modal"
    });

    showSignInModal();

  }

  handleTermsClick () {
    const { hideModal } = this.props;

    ReportGA({
      category: SIGN_UP,
      action: "User clicked on show Terms button on sign up modal"
    });

    console.log('track pushing terms1');
    history.push(ROUTES.TERMS);
    console.log('track pushing terms2');
    hideModal();
  }

  // dumbSubmit (values) {
  //   const { handleSubmitForm, active_referral_code } = this.props;
  //   handleSubmitForm(values, active_referral_code);
  // }


  renderButton = () => {
    const { handleSubmit, handleSubmitForm, isFetching, active_referral_code } = this.props;
    if (isFetching) {
      return <div style={{ justifyContent: 'center', alignItems: 'center' }}><Spinner size={20} /></div>;
    }

    return (
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: 8 }}>


        <Button
          primary
          disabled={isFetching}
          style={{ width: '100%', color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
          onClick={handleSubmit((values) => { handleSubmitForm(values, active_referral_code); })}
        >
          {signup.signup}
        </Button>


      </div>

    );
  };

  render () {

    const { serverError } = this.props;

    return (
      <React.Fragment>

        <Modal.Content image>
          <Modal.Description>

            {serverError
              && <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 4, paddingBottom: 4 }}>
                <InputErrorText>{serverError}</InputErrorText>
              </div>
            }

            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 4, paddingBottom: 4 }}>


              <div style={{ flex: 1 }}>
                <Field
                  name='firstname'
                  isName
                  labelText={`${signup.firstname_label}*`}
                  component={ FormTextInput }
                  placeholder='* Firstname'
                  focussedColor={ colours.main }
                  unfocussedColor={ colours.lightgray }
                />
              </div>


            </div>

            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 4, paddingBottom: 4 }}>


              <div style={{ flex: 1 }}>
                <Field
                  name='surname'
                  isName
                  labelText={`${signup.surname_label}*`}
                  component={ FormTextInput }
                  placeholder='* Surname'
                  focussedColor={ colours.main }
                  unfocussedColor={ colours.lightgray }
                />
              </div>


            </div>

            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 4, paddingBottom: 4 }}>


              <div style={{ flex: 1 }}>
                <Field
                  name='email'
                  labelText={`${signup.email_label}*`}
                  component={ FormTextInput }
                  placeholder='* Email'
                  focussedColor={ colours.main }
                  unfocussedColor={ colours.lightgray }
                  isEmail
                />
              </div>


            </div>

            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 4, paddingBottom: 4 }}>


              <div style={{ flex: 1 }}>
                <Field
                  name='password'
                  labelText={`${signup.password_label}*`}
                  component={ FormPasswordInput }
                  placeholder='* Password'
                  focussedColor={ colours.main }
                  unfocussedColor={ colours.lightgray }
                />
              </div>


            </div>

            <div style={{ display: 'flex', alignItems: 'flex-start', paddingTop: 4, paddingBottom: 4 }}>


              <div style={{ flex: 1 }}>
                <Field
                  name='confirmPassword'
                  labelText={`${signup.confirm_password_label}*`}
                  component={ FormPasswordInput }
                  placeholder='* Confirm password'
                  focussedColor={ colours.main }
                  unfocussedColor={ colours.lightgray }
                />
              </div>


            </div>


            <div style={{ display: 'flex', paddingTop: 0, paddingBottom: 4, justifyContent: 'flex-end' }}>
              <A5>* Mandatory fields</A5>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 4, paddingBottom: 4 }}>
              <Field
                name='terms_checked'
                component={ FormCheckInput }
                value='terms_checked'
                
                labelComponent={<H4 color={colours.gray}>By creating an account, I accept the&nbsp;
                  <Link
                    color={colours.red}
                    hoverColor={colours.mediumlightpurple}
                    underline
                    // to={`${ROUTES.TERMS}`}
                    onClick={() => this.handleTermsClick()}
                  >
                    {'Terms and conditions'}
                  </Link>
                </H4>} />
            </div>

            { this.renderButton() }

            <div style={{ width: '100%', height: 1, backgroundColor: colours.borderColour, marginTop: 16 }} />


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingTop: 4, paddingBottom: 4 }}>
              <button
                type='button'
                style={{ backgroundColor: 'transparent', borderWidth: 0 }}

                onClick={ this.showSignInModal }
              >
                <Row>
                  <H4 color={colours.gray}>Already have an account?&nbsp;
                    <span style={{ color: colours.red }}>Sign in</span>
                  </H4>
                </Row>
              </button>
            </div>


          </Modal.Description>


        </Modal.Content>


      </React.Fragment>


    );
  }
}

const decoratedComponent = reduxForm({ form: 'signup', validate })(Signup);
export default hoistNonReactStatic(decoratedComponent, Signup);
// export default connectAlert(hoistNonReactStatic(decoratedComponent, Signup));
