import React from 'react';
import DynamicComponent, { DynamicAComponent, DynamicInputComponent, DynamicTextAreaComponent } from "./dynamic-component";
import default_theme from "./default-theme";


const {
  main
} = default_theme.theme_details.layoutStyles;

export const loggedOutNavHeight = 60;
export const infoBarHeight = 0; // 27; now put in footer
export const navBarHeight = 60;
export const navBarLoggedOutHeight = 60;
export const dropdownNavHeight = 60;
export const profileBarHeight = 78;
export const backBarHeight = 36;
export const tabBarHeight = 56;
export const sendMessageHeight = 60;
export const navBarBottomHeight = 54; // 47 + 7 padding on root - to investigate??
export const actionBarBottomHeight = 134; // 47 + 7 padding on root - to investigate??

// fontSizes


export const DivWithPadding = props => (
  <DynamicComponent {...main} {...props}>
    {props.children}
  </DynamicComponent>
);

export default DivWithPadding;
