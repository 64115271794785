
// import initSocket from '../socket-router';
// require('dotenv').config();

export const SET_NO_OF_CHILDREN = 'SET_NO_OF_CHILDREN';
export const CREATE_CHILD = 'CREATE_CHILD';
export const SELECT_CHILD = 'SELECT_CHILD';
export const SET_CHILD_TO_ADD = 'SET_CHILD_TO_ADD';

export const LOGOUT = 'LOGOUT';

export function setNoOfChildren (data) {
  return {
    type: SET_NO_OF_CHILDREN,
    data
  };
}

export function createChild (data) {
  return {
    type: CREATE_CHILD,
    data
  };
}

export function selectChild (data) {
  return {
    type: SELECT_CHILD,
    data
  };
}

export function setChildToAdd (data) {
  return {
    type: SET_CHILD_TO_ADD,
    data
  };
}

export const logout = () => ({
  type: LOGOUT
});
