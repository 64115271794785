import React from 'react';
import { ReportGA } from '../../utils/ga';
import { ConfirmButton
  // MoreButton
} from '../../styles/styles';
import Card from '../general/card';
import CardHeader from '../general/card-header';
import Paragraph from '../general/paragraph';
// import HighlightedText from '../general/highlighted-text';
import history from '../Navigation/history';
import { dashboard } from '../../text';
import * as ROUTES from '../../constants/routes';
import { DASHBOARD } from '../../constants/ga-category';


const ClashingBookings = () => {
    // console.log('f item user:', user);
    // const { handleMore, narrowScreenOrientation, window_width, profileProgress } = props;


    const handleClickCal = () => {

        history.push({
          pathname: ROUTES.AVAILABILITY
        });

        ReportGA({
          category: DASHBOARD,
          action: `User pressed Go to Calendar button on DocsPending card on Dashboard page`
        });

      };


    return (


      <Card>
        <CardHeader alignTitle='left' title={dashboard.clashing_bookings_title} />

        <Paragraph>
          {dashboard.clashing_bookings_para1.a}
        </Paragraph>
        <Paragraph>

          {dashboard.clashing_bookings_para1.b}

          {
            // <HighlightedText>{dashboard.clashing_bookings_para1.h1}&nbsp;</HighlightedText>
            // {dashboard.clashing_bookings_para1.c}
          }

        </Paragraph>
        {
          // <Paragraph>
          //   {dashboard.clashing_bookings_para1.d}
          // </Paragraph>
        }

        {
          // <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 8, paddingBottom: 8 }}>
          //
          //   <MoreButton onClick={() => handleClickUploadMore()}>
          //     {dashboard.clashing_bookings_upload_more_button}
          //   </MoreButton>
          //
          // </div>
        }


        <div style={{ flex: 1, width: '100%', display: 'flex', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', justifyContent: 'flex-end', alignItems: 'flex-end', paddingTop: 4, paddingBottom: 4 }}>
          <ConfirmButton
            onClick={ () => handleClickCal() }
          >
            {dashboard.clashing_bookings_cal_button}
          </ConfirmButton>
        </div>


      </Card>

    );
};

export default ClashingBookings;
