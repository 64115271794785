
export const profile = {


  section1_title: 'My profile',
  section1_para1: `Here’s your family's profile summary.`,
  section1_para2: `You can edit it if needed.`,

  completion: 'Completion',
  must_complete_profile: 'You must complete your profile before you can arrange a sit.',
  must_complete_verification: 'You must verify your account before you can arrange a sit.',
  must_complete_email_verification: 'You must verify your email before you can arrange a sit.',
  profile_status_label: 'Profile Status',
  dbs_all: 'Full DBS',
  dbs_some: 'Partial DBS',
  verified: 'Verified',
  not_verified: 'Not verified',

  family: 'family',
  member_since: 'Member since',
  distance: 'Distance',
  miles_away: 'miles away from you',

  reviews_header: 'Reviews',
  reviews: 'reviews',
  see_all_reviews: 'See all reviews',
  usage1: 'Has sat',
  usage2: 'has used sitters',
  response_rate: 'Response rate',

  has_sat: 'Has sat',
  times: 'times',
  has_used: 'Has used sitters',

  mandatory_title: 'Required (mandatory)',
  your_details: 'About You',
  your_details_description: 'Your account details',
  about_you: 'Your Family Bio',
  about_you_description: 'Help other families to learn about you',
  verify_email: 'Verify Email',
  email_verified: 'Email Verified',
  your_photo: `Profile Photo`,
  your_address: 'Your Address',
  your_children: 'Your Children',
  adults: 'Adults in your family',
  your_verification: 'Your Verification',

  optional_title: 'Optional',

  your_dbs: 'Your DBS',
  your_dbs_description: `Uploading a DBS will improve your appeal to families`,
  verify_other_adults: `Verify Other Adults`,
  verify_other_adults_description: `Verify all adults in your household to improve your profile's appeal`,


  about: 'About',
  family_description: 'Family description',
  to_be_completed: 'To be completed',
  null_description: `hasn't entered a description yet`,

  input_label1: 'Your family description :',
  section_email_title: 'Registered Email',
  section_email_label: 'Email Address',
  section_about_you_title: 'About you',
  section_about_you_label1: 'First name',
  section_about_you_label2: 'Surname',

  photo: 'Profile Photo',
  edit_photo: 'Edit photo',

  edit_info: 'Edit info',

  name: 'Name',
  email: 'Email',

  address: 'Address',
  approx_location: 'Approximate Location',
  address_info: 'Full address will be shown after the sit is confirmed',
  live: 'lives',

  children_description: 'Children description',
  gender: 'Gender',
  boy: 'Boy',
  girl: 'Girl',
  neutral: 'Neutral',
  age: 'Age',
  sen: 'SEN',
  allergies: 'Allergies',
  other_important_info: 'Other Important Info',
  na: 'N/A',
  no: 'No',


  verified_docs: 'Family/Household',
  verifications: 'Verifications',
  add_verification: 'Add Verification',
  get_verified: 'Get Verified',

  parents_skills: 'Parents skills',

  verification_pending: `documents under review`


};


export default profile;
