import { connect } from 'react-redux';
import MessageBar from '../../components/messages/message-bar';
import { setNewPlaceholder, setNewMessage, sendNewMessage, cancelRequestSit, confirmPreApproveSit, confirmApproveSit, confirmCancelAction, setEditMode, sendEditedDetails, confirmPreAuthorise, confirmFinalise } from '../../actions/messages';
import { clearEditSearchQuery } from '../../actions/search';
import { getUserById } from '../../actions/auth/profile';
import { setShowNavbar, resetActionBar } from '../../actions/layout';
import { setThreadBack } from '../../actions/navbar';
import { getViewedFamily } from '../../actions/dashboard';
import { hideModal } from '../../actions/modal';
// import { MODAL_TYPE_REVIEW } from '../../components/modals/modalTypes';
import * as ROUTES from '../../constants/routes';
import { MESSAGES } from '../../constants/ga-category';
import { ReportGA } from '../../utils/ga';


const mapStateToProps = ({ user, messageThread, tariff, editSearch, payments }, ownProps) => ({

  user_id: user.user_id,
  payments: payments.payments,

  subscription_status: user.subscription_status,

  newMessageThreadParams: messageThread.newMessageThreadParams,
  newMessageContent: messageThread.newMessageContent,
  newMessagePlaceholder: messageThread.newMessagePlaceholder,
  thread_details: (messageThread.thread_messages.length === 0) ? {} : messageThread.thread_details,
  sender_id: user.user_id,
  receiver_id: (messageThread.thread_messages.length === 0) ? ((messageThread.isFetchingThread) ? '' : messageThread.newMessageThreadParams.receiver_id) : ((user.user_id === messageThread.thread_details.requester_id) ? messageThread.thread_details.sitter_id : messageThread.thread_details.requester_id),
  receiver_name: (messageThread.thread_messages.length === 0) ? ((messageThread.isFetchingThread) ? '' : messageThread.newMessageThreadParams.receiver_name) : ((user.user_id === messageThread.thread_details.requester_id) ? messageThread.thread_details.sitter_name : messageThread.thread_details.requester_name),

  editMode: messageThread.editMode,
  messagesLength: ownProps.messagesLength,
  sendMessageAndApprove: ownProps.sendMessageAndApprove,
  handleMessageInput: ownProps.handleMessageInput,

  isInPast: ownProps.isInPast,
  isStarted: ownProps.isStarted,
  sitter_id: ownProps.sitter_id,
  requester_id: ownProps.requester_id,
  status: ownProps.status,
  sp_quoted: ownProps.sp_quoted,
  sp_actual: ownProps.sp_actual,

  isFetchingThread: messageThread.isFetchingThread,
  isSendingMessage: messageThread.isSendingMessage,
  isPreApproving: messageThread.isPreApproving,
  action: ownProps.action,
  edited_thread_details: editSearch,
  tariff_details: tariff.tariff_details


});

const mapDispatchToProps = dispatch => ({


  // handleShowReviewModal: (thread_id, user_id) => {
  //
  //   dispatch(showModal(MODAL_TYPE_REVIEW, {
  //     thread_id, user_id
  //   }));
  //
  // },

  // reviewSitting: () => {
  //   dispatch(reviewSitting());
  // },

  resetActionBar: () => {
    // resets Action Bar if clicking cancel on message bar - think only need to use in edit details modal, as passed down as props in other action bar - test
    ReportGA({
      category: MESSAGES,
      action: `User clicked Close from MessageBar`
    });

    dispatch(resetActionBar());
    dispatch(setEditMode(false));
    dispatch(clearEditSearchQuery());
    dispatch(hideModal()); // check this doesn't stuff up any other actions

  },

  handleHideModal: () => {

    dispatch(hideModal());

  },
  handleGetUser: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getUserById(token, user_id));

    }

  },
  setEditMode: (editMode) => {

      dispatch(setEditMode(editMode));

  },
  setShowNavbar: (data) => {

      dispatch(setShowNavbar(data));

  },
  initializeNewMessage: (text) => {
    // console.log('typed messageText', text);
    dispatch(setNewMessage(text));
  },
  initializePlaceholder: (text) => {
    dispatch(setNewPlaceholder(text));
  },
  handleChange: (text) => {
    // console.log('typed messageText', text);
    dispatch(setNewMessage(text));
  },

  sendNewMessage: (messageParams, thread_id, user_id, nextRoute) => {


    const token = localStorage.sitterpoint_token;

    if (token) {

      // console.log(`sendNewMessageArgs: ${token}, ${sender_id}, ${receiver_id}, ${thread_id}, ${thread_details}, ${messageText}, ${timestamp}`);

      dispatch(sendNewMessage(token, messageParams, thread_id, user_id, nextRoute));

    }

  },

  sendEditedDetails: (messageParams, thread_id, thread_details, user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {

      dispatch(sendEditedDetails(token, messageParams, thread_id, thread_details, user_id));

    }

  },
  cancelRequestSit: (thread_id, user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {

      dispatch(cancelRequestSit(token, thread_id, user_id));

    }

  },
  // are these same?
  preAuthorise: (messageParams, thread_id, user_id, thread_details) => {
    const token = localStorage.sitterpoint_token;
    if (token) {
      dispatch(confirmPreAuthorise(token, messageParams, thread_id, user_id, thread_details));
    }
  },
  // finaliseSit: (thread_id, user_id, sp_actual) => {
  //
  //   const token = localStorage.sitterpoint_token;
  //
  //   if (token) {
  //
  //     dispatch(finaliseSit(token, thread_id, user_id, sp_actual));
  //
  //   }
  //
  // },
  handleGetProfileClick: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getViewedFamily(token, user_id));
    }

  },

  cancelSubmit: (messageParams, editMode, showReviewModal, subscription_status, messagesLength, user_id, isInPast, thread_details, sp_actual, requester_rating, sitter_rating) => {
    // used for PreApprove + Approve + Decline
    const token = localStorage.sitterpoint_token;

    const { sitter_id, requester_id, status } = thread_details;

    // cancelApproveSit(token, thread_id, user_id));
    // console.log(' messageParams', messageParams);
    // console.log('user_id:', user_id);
    // console.log('sitter_id:', sitter_id);
    // console.log('thread_details.thread_id:', thread_details.thread_id);
    // console.log('thread_details:', thread_details);
    // console.log('isInPast:', isInPast);
    console.log('status:', status);

    if (token) {

      // --------sitter view

      if ((user_id === sitter_id) && !isInPast && (status === 'request')) {
        dispatch(confirmCancelAction(token, messageParams, thread_details.thread_id, user_id, 'request', 'sitter'));
      }

      if ((user_id === sitter_id) && !isInPast && (status === 'pre_approved')) {
        dispatch(confirmCancelAction(token, messageParams, thread_details.thread_id, user_id, 'pre_approved', 'sitter'));
      }

      if ((user_id === sitter_id) && !isInPast && (status === 'approved')) {

        // puts status of sit back to request.  use then has option to Decline or Pre-Approve again at request stage.

        // refund?? need to issue refund of points (and £) to requester
        // NEW to check
        dispatch(confirmCancelAction(token, messageParams, thread_details.thread_id, user_id, 'approved', 'sitter'));
      }

      if ((user_id === sitter_id) && isInPast && (status === 'approved')) {

        // do we need this or can we just say sit didn't happen?
        // refunds?
        // edit final details or pre-authorise
        // do we want to cancel or just put back to request?
        // puts status of sit back to request.  use then has option to Decline or Pre-Approve again at request stage.

        // refund?? need to issue refund of points (and £) to requester

        // dispatch(confirmCancelAction(token, messageParams, thread_details.thread_id, user_id, 'approve', 'sitter'));
      }

      if ((user_id === sitter_id) && isInPast && (status === 'pre_authorised')) {
        // dispatch(setEditMode(!editMode));
      }

      if ((user_id === sitter_id) && isInPast && (status === 'finalised') && (sitter_rating === -1)) {
        // dispatch(showReviewModal(thread_details.thread_id, user_id));

      }

      // --------requester view

      if ((user_id === requester_id) && !isInPast && (status === 'pre_approved' || status === 'request')) {
        dispatch(confirmCancelAction(token, messageParams, thread_details.thread_id, user_id, 'request', 'requester'));
      }

      if ((user_id === requester_id) && !isInPast && (status === 'approved')) {
        dispatch(confirmCancelAction(token, messageParams, thread_details.thread_id, user_id, 'approved', 'requester'));
      }

      if ((user_id === requester_id) && isInPast && (status === 'pre_authorised')) {
        // dispatch(finaliseSit(token, thread_details.thread_id, user_id, sp_actual));
      }

      if ((user_id === requester_id) && isInPast && (status === 'finalised') && (requester_rating === -1)) {
        // dispatch(showReviewModal(thread_details.thread_id, user_id));
      }

    }
  },

  approveSubmit: (messageParams, editMode, showReviewModal, subscription_status, messagesLength, user_id, sitter_id, requester_id, isInPast, status, thread_details, sp_actual, requester_rating, sitter_rating) => {
    // used for PreApprove + Approve
    const token = localStorage.sitterpoint_token;

    if (token) {

      // if messages.length = 0 then cant send request

      // --------sitter view

      if ((user_id === sitter_id) && !isInPast && (status === 'request')) {
        dispatch(confirmPreApproveSit(token, messageParams, thread_details.thread_id, user_id));
        // pre_approve_sitting: 'Pre-approve Sitting</ConfirmButtonText'

      }
      // this.renderPreApproveButton()

      // (user_id === sitter_id) && isInPast && ((status === 'pre_approved') || (status === 'request'))
      // && <div style={{ width: '100%', padding: 4, borderColor: 'green', borderWidth: 0, borderStyle: 'solid', alignItems: 'center' }}>
      //   <H5 style={{ color: colours.red, textAlign: 'center' }}>Sit Expired - this sit wasn't approved</H5>
      // </div>


      if ((user_id === sitter_id) && isInPast && (status === 'approved')) {
        // edit final details or pre-authorise

      }
      // <div style={{ width: '100%', padding: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', borderColor: 'green', borderWidth: 0, borderStyle: 'solid' }}>
      //   <PreAuthoriseButtonContainer />
      //   <H5 style={{ textAlign: 'center' }}>or</H5>
      //   <ApproveButton
      //     borderColor={colours.orange}
      //     testID='Edit_Final_Details'
      //
      //     disabled={editMode}
      //     onClick={() => setEditMode(!editMode)}
      //   >
      //     <ConfirmButtonText style={{ color: colours.orange }}>
      //       <FontAwesomeIcon
      //         icon={faEdit}
      //         size='lg'
      //         style={{ paddingRight: 4, color: colours.confirm }} />
      //       {`${editButtonText}`}
      //     </ConfirmButtonText>
      //   </ApproveButton>
      //
      //
      // </div>

      if ((user_id === sitter_id) && isInPast && (status === 'pre_authorised')) {

        dispatch(setEditMode(!editMode));
        // amend_final_details: 'Amend Final Details'
        // {`Waiting for ${requester_name} to finalise the sit`}
      }
      // this.renderUpdatePreAuthoriseButton()


      if ((user_id === sitter_id) && isInPast && (status === 'finalised') && (sitter_rating === -1)) {
        dispatch(showReviewModal(thread_details.thread_id, user_id));
        // review_sitting: {sitDetails.buttons.review}
      }
      // this.renderReviewButton()


        // --------requester view

      if ((user_id === requester_id) && !isInPast && (status === 'pre_approved')) {

        if ((subscription_status !== 'active' && subscription_status !== 'trialing' && subscription_status !== 'past_due') && thread_details.payment_status !== 'paid') {
          // if not member and not paid then take payment (membership or payg)
            const payment_type = 'payg_payment';
            const paid = false;
            dispatch(confirmApproveSit(token, messageParams, thread_details.thread_id, user_id, payment_type, paid));

            dispatch(setThreadBack(ROUTES.INBOX)); // ensures once paid, can't go back to payment page


          // what if not member but already paid?  e.g. cancelled approval, back to pre-approval.
          // refund policy?


        } else {
          // else
          // if member (active or past_due)
          // then approve sit

          // const currency_amount = 0;
          // const user_currency = 'gbp';
          const payment_type = 'sit_existing_member';
          // need to add sp_quoted and user_currency;
          const paid = true;
          dispatch(confirmApproveSit(token, messageParams, thread_details.thread_id, user_id, payment_type, paid));

        }
        // approve_sitting: sitDetails.buttons.approve
      }
      // this.renderApproveButton()


      if ((user_id === requester_id) && isInPast && (status === 'pre_authorised')) {

        dispatch(confirmFinalise(token, messageParams, thread_details.thread_id, user_id, thread_details, sp_actual));

        // approve_final_details: 'Approve Final Details'
      }
      // this.renderFinaliseButton()


      if ((user_id === requester_id) && isInPast && (status === 'finalised') && (requester_rating === -1)) {
        dispatch(showReviewModal(thread_details.thread_id, user_id));
        // review_sitting: {sitDetails.buttons.review}
      }
        // this.renderReviewButton()


        // (user_id === requester_id) && isInPast && (status === 'finalised')
        // && <div style={{ width: '100%', padding: 4, borderColor: 'green', borderWidth: 0, borderStyle: 'solid', alignItems: 'center' }}>
        //   <H5 style={{ color: colours.red, textAlign: 'center' }}>This sit has been finalised</H5>
        // </div>


    }
  }

  // discardNewMessage: () => {
  //   dispatch(clearNewMessage());
  // }

});

export default connect(mapStateToProps, mapDispatchToProps)(MessageBar);
