import React, { useState, useEffect } from 'react';
import { SetGA, ReportGA } from '../../utils/ga';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Modal,
    Button
} from "semantic-ui-react";
import { hideModal } from '../../actions/modal';
import Paragraph from '../../components/general/paragraph';
import ModalHeader from '../../components/general/modal-header';
// import Modal from '../../components/modals/modal';
import { A5, H5 } from '../../styles/text';
import colours from '../../styles/colours';
// import { calendar } from '../../text';
import BookingItemSmall from '../../components/calendar/booking-item-small';
import VerifyFileUploadButton from '../verify/verify-file-upload-button';
import VerifyCameraUploadButton from '../verify/verify-camera-upload-button';
import { useUserMedia } from '../../components/general/camera/hooks/use-user-media';
import { BACK_CAPTURE_OPTIONS, FRONT_CAPTURE_OPTIONS } from '../../components/general/camera';
import { CONFIRM_UPLOAD_TYPE } from '../../components/modals/modalNames';
import Spinner from '../../components/general/spinner';
import SpinnerModal from './spinner';

const debugMode = process.env.REACT_APP_APP_NODE_ENV !== 'production' && process.env.REACT_APP_APP_ENV === 'local';
console.log('ConfirmModal local debugMode: ', debugMode);


const ConfirmModal = ({
  // title
  window_width, showClose, afterClose, hideModal, cancelAction, backAction, okAction, modalText, modalCategory, modalName, clashing_bookings, cameraAction, docStatus, adult_id, adult_name, doc_name }) => {

  const { title, header, para, para2, cancel, ok } = modalText;

  
  const onClose = () => {
    hideModal();

    if (afterClose) {
      afterClose();
    }
  };

  const onOK = () => {
    okAction();
    hideModal();

  };

  ReportGA({
    category: modalCategory,
    action: `Modal shown to user ${modalName}`
    // meta: { window_width, afterClose, hideModal, cancelAction, backAction, okAction, modalText, modalCategory, modalName, clashing_bookings, cameraAction, docStatus, adult_id, adult_name, doc_name }
  });


  const renderClashingBookings = clashing_bookings && clashing_bookings.map((item) => {

    console.log('item:', item);
    let start;
    let end;

    if (!item.available_dates) {
      start = item.start;
      end = item.end;
    } else {
      // parse available_dates
      // ["2021-08-20 02:30:00","2021-08-20 05:30:00"]
      const parsed_dateRange = JSON.parse(item.available_dates);
      const dateRangeArray = [...parsed_dateRange];
      console.log('parsed_dateRange:', parsed_dateRange);
      start = dateRangeArray[0];
      end = dateRangeArray[1];
    }


    return (
      <BookingItemSmall key={item.booking_id} window_width={window_width} booking_id={item.booking_id} recurrance_id={item.recurrance_id} thread_id={1} start_timestamp={start} end_timestamp={end} booking_type={item.booking_type} booking_status={item.booking_status} />
    );
  });

  console.log('usesCamera modalName:', modalName);
  const usesCamera = modalName === CONFIRM_UPLOAD_TYPE;
  console.log('usesCamera:', usesCamera);


  console.log('doc_name:', doc_name);


  const showNormal = true;


  return (


    <Modal
      closeIcon={showClose && "close"}
      open={true}
      onClose={showClose && onClose}
      style={{ maxWidth: 500 }}
    >
      { // <Modal.Header>{title}</Modal.Header>
      }
      <Modal.Content>

        {
          (showNormal) &&
          <ModalHeader>{header}</ModalHeader>
        }


        <div style={{ flexDirection: 'column', paddingBottom: 16, display: 'flex', background: 'transparent', justifyContent: 'center', alignItems: 'center' }}>

          {
            showNormal
            &&
            <div style={{ marginBottom: 24, maxWidth: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

              <Paragraph>

                <A5 textAlign='center'>{`${para}`}</A5>

              </Paragraph>
              {(para2) &&
                <Paragraph>

                  <A5 textAlign='center'>{para2}</A5>

                </Paragraph>
              }
              {(clashing_bookings && clashing_bookings.length > 0) &&
                <div style={{ width: '100%', marginTop: 16, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <H5>{`${modalText.clashing_bookings}`}</H5>
                  {renderClashingBookings}
                  <A5 textAlign='center'>{`${modalText.note}`}</A5>
                </div>
              }

            </div>
          }


          <div style={{ display: 'flex', maxWidth: 200, width: '100%', flexDirection: 'row', justifyContent: 'space-around' }}>
            { !usesCamera &&
              <>
                <Button
                  // secondary

                  // style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
                  onClick={cancelAction}
                >
                  {cancel}
                </Button>
                <Button
                  primary

                  style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
                  onClick={onOK}
                >
                  {ok}
                </Button>
              </>
            }


          </div>

        </div>

      </Modal.Content>

    </Modal>

  );

};


ConfirmModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

const mapStateToProps = ({ layout }, ownProps) => ({

  window_width: layout.window_width,
  afterClose: ownProps.afterClose,
  hideModal: ownProps.hideModal,
  cancelAction: ownProps.cancelAction,
  backAction: ownProps.backAction,
  okAction: ownProps.okAction,
  clashing_bookings: ownProps.clashing_bookings

});

export default connect(mapStateToProps, { hideModal })(ConfirmModal);
