import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, Icon
} from 'semantic-ui-react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import colours from '../../colours';
// import { fonts } from "../../theme";
// import { B4 } from '../../text';

// opacity: props.opacity || 1

export const StyledLink = styled(RouterLink)`


  color: ${props => props.color ? props.color : colours.lightpurple}!important;
  text-decoration: ${props => props.underline ? 'underline' : 'none'}!important;
  background: ${props => props.background ? props.background : 'transparent'}!important;
  cursor: pointer;
  border-radius: 2px;

  &:hover {
    color: ${props => props.hovercolor ? props.hovercolor : colours.mediumlightpurple}!important;
    background: ${props => props.hoverBackground ? props.hoverBackground : 'transparent'}!important;
  }

`;

// add conditional so if onClick, make element a button, if to, then make an anchor


const Link = (props) => {

  return (
    <StyledLink
      target={props.target}
      to={props.to}
      href={props.href}
      onClick={props.onClick}
      color={props.color}
      background={props.background}
      hovercolor={props.hoverColor}
      hoverBackground={props.hoverBackground}
      underline={props.underline}
    >

      {props.children}

    </StyledLink>
  );
};

export default Link;
