export const popups = {

  aboutChildren: {

    // number_of_children: {
    //   title: 'Number of children you have',
    //   text: 'Please tell us how many of your children might need a sitter.'
    // },
    name: { // is this needed?
      title: 'Name',
      text: 'Please enter the first name of the child.'
    },
    gender: { // is this needed?
      title: 'Gender',
      text: 'Please enter the gender of the child.'
    },
    dob: {
      title: 'Date of birth',
      text: `Note - this information will never be shown to another user, only the child's age will be visible.`
    },
    sen: {
      title: 'Special education needs',
      text: 'Please select any SEN of the child that you are aware of (if not on the list, please select “other” and write it down in the text field below).'
    },
    allergies: {
      title: 'Food and other allergies',
      text: 'Please select any allergies of the child that you are aware of (if not on the list, please select “other” and write it down in the text field below).'
    },
    other_info: {
      title: 'Anything else for parents to be aware of',
      text: 'e.g. “scared of dogs” or “trouble coping with loud noises”.'
    }

  },


  adults: {

    name: { // is this needed?
      title: 'Name',
      text: 'Please enter the first name of the person.'
    },
    surname: {
      title: 'Surname',
      text: 'Note - the surname will not be shown to other users.'
    },
    dob: {
      title: 'Date of birth',
      text: 'Note - this information will never be shown to other users.'
    },
    relation: { // is this needed?
      title: 'Relation',
      text: 'Please enter the relation to the child/children.'
    }
  },

  home_address: {

    name: {
      title: 'Home address',
      text: 'Enter your full address or postcode so the map locates your home. This will let other users an approximate location, but your exact location and address will never be seen by other users unless you choose to disclose this after confirming a sit.'
    }
  },

  // profile_photo: {
  //
  //   name: {
  //     title: 'Profile photo',
  //     text: 'Upload your family profile picture.'
  //   }
  // },

  // family_description: {
  //
  //     title: 'Family description',
  //     text: `This will help other local families to know a bit more about you and your children. Add a few sentences about your family to describe yourself to others. For example, what you do and like to do with your children, what your kids like or anything else you'd like to share.`
  // },

  // house_rules: {
  //
  //   name: {
  //     title: 'House rules',
  //     text: `Please enter any information about your house rules: e.g. No shoes in the house/flat, quiet time when baby is napping between 12.30pm and 2pm, no ball games indoors.`
  //   }
  // },

  sit_details: {

    requester: {
      sp_quoted: {
        title: 'Quoted SP',
        text: `These points are deducted from your balance as soon as the sit is confirmed.`
      },
      sp_actual: {
        title: 'Actual SP',
        text: `The final points based on the final agreed sit details.`
      }
    },

    sitter: {
      sp_quoted: {
        title: 'Quoted SP',
        text: `These points will be credited to your balance once the sit has been finalised.`
      },
      sp_actual: {
        title: 'Actual SP',
        text: `The final points based on the final agreed sit details.`
      }
    }


  }


};


export default popups;
