import React, { Component } from 'react';
import Measure from 'react-measure';


export default class Collapse extends Component {

  state = {
    childDimensions: {
        height: 0,
        width: 0
    },
    showOverflow: false
  };


  componentDidMount () {
    // const childHeightRaw = this.content.clientHeight; // initialChildHeight
    // // console.log('childHeightRaw:', childHeightRaw);
    // const childHeight = childHeightRaw;
    // // const childHeight = `${childHeightRaw / 16}rem`;
    // // console.log('childHeight:', childHeightRaw);
    //
    // this.setState({ childHeight });

    const { isOpen, parentComp } = this.props;

    console.log(`collapse DidMount ${parentComp} didmount props:`, this.props);
    // const prevOpen = this.props.isOpen;

    const random = Math.random();

    // set to allow overflow (for date select popups, etc after has expanded)
    if (isOpen) {
      console.log(`collapse ${parentComp} DidMount set showOverflow state to open 0 ms s ${random}: `);
      setTimeout(() => {
        console.log(`collapse ${parentComp} DidMount set showOverflow state to open 0 ms e ${random}: `);
        this.setState({ showOverflow: true });
      }, 0);
    } else {
      console.log(`collapse ${parentComp} NewProps set showOverflow state to closed no timeout s  ${random}: `);
      setTimeout(() => {
        console.log(`collapse ${parentComp} NewProps set showOverflow state to closed no timeout e ${random}: `);
        this.setState({ showOverflow: false });
      }, 0);
    }


    // console.log('collapse did mount Props:', this.props);
  //   console.log(`collapse ${parentComp} DidMount isOpen: `, isOpen);
  //   // set to allow overflow (for date select popups, etc after has expanded)
  //   if (isOpen) {
  //     console.log('collapse ${parentComp} DidMount set showOverflow state to open 600 ms s');
  //     setTimeout(() => {
  //       this.setState({ showOverflow: true });
  //       console.log('collapse ${parentComp} DidMount set showOverflow state to open 600 ms e');
  //     }, 600);
  //   } else {
  //     console.log('collapse ${parentComp} DidMount set showOverflow state to closed no timeout s');
  //     this.setState({ showOverflow: false });
  //     console.log('collapse ${parentComp} DidMount set showOverflow state to closed no timeout e');
  //   }
  }


  componentDidUpdate (prevProps) {


    // console.log('collapse DidUpdate prevProps:', prevProps);
    // console.log('collapse DidUpdate this.props:', this.props);
    // console.log('collapse newProps showOverflow state:', this.state.showOverflow);
    // true
    const { isOpen, parentComp } = this.props;
    const prevOpen = prevProps.isOpen;

    // const random = Math.random();
    // need to capture change to childHeight if child becomes bigger (user selects SEN, etc)
    // console.log('newProps childheight:', this.content.clientHeight);
    // const childHeightNew = this.content.clientHeight; // updatedChildHeight
    // const childHeight = childHeightNew;
    // this.setState({ childHeight });
    // console.log(`collapse ${parentComp} NextProps isOpen ${random}: `, isOpen);
    // console.log(`collapse ${parentComp} NextProps prevOpen ${random}: `, prevOpen);

    // set to allow overflow (for date select popups, etc after has expanded)
    if (isOpen && !prevOpen) {
      // console.log(`collapse ${parentComp} NewProps set showOverflow state to open 600 ms s ${random}: `);
      setTimeout(() => {
        // console.log(`collapse ${parentComp} NewProps set showOverflow state to open 600 ms e ${random}: `);
        this.setState({ showOverflow: true });
      }, 600);
    } else if (isOpen !== prevOpen) {
      // console.log(`collapse ${parentComp} NewProps set showOverflow state to closed no timeout s  ${random}: `);
      setTimeout(() => {
        // console.log(`collapse ${parentComp} NewProps set showOverflow state to closed no timeout e ${random}: `);
        this.setState({ showOverflow: false });
      }, 0);
    }


  }


  render () {
    const { children, isOpen, zIndex, parentComp, width, borderColor, borderWidth, borderLeftWidth, borderRightWidth, borderTopWidth, borderBottomWidth } = this.props;
    const { childDimensions } = this.state;
    const { height } = childDimensions;

    // console.log(`collapse ${parentComp} render props:`, this.props);
    // console.log(`collapse ${parentComp} render showOverflow state:`, this.state.showOverflow);
    const showOverflow = this.state.showOverflow;
    // console.log(`collapse ${parentComp} render showOverflow: `, showOverflow);

    return (
      <div
        // className="collapse"
        style={{
          width: !width ? null : width,
          postion: 'relative',
          zIndex,
          overflow: showOverflow ? 'visible' : 'hidden',
          maxHeight: isOpen ? height : 0,
          transition: '0.6s max-height ease-in-out',
          borderWidth: borderWidth,
          borderLeftWidth: borderLeftWidth,
          borderRightWidth: borderRightWidth,
          borderTopWidth: borderTopWidth,
          borderBottomWidth: borderBottomWidth,
          borderColor: borderColor,
          borderStyle: borderWidth ? 'solid' : null
        }}
      >
        <Measure
          bounds
          onResize={(contentRect) => {
            this.setState({ childDimensions: contentRect.bounds })
          }}
        >
          {({ measureRef }) => (
            <div
              style={{

                // overflow: 'auto'
              }}
              ref={measureRef}
            >
              {children}
            </div>
          )}
        </Measure>
      </div>
    );
  }
}

Collapse.defaultProps = {
  isOpen: false
};
