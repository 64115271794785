import React, { useState } from 'react';
import { Placeholder } from 'semantic-ui-react';
import Spinner from './spinner';

export const Image = (props) => {

  const [isImageLoaded, setImageIsLoaded] = useState(false);

  const { noDelay, spinner, ...rest } = props;
  const delay = noDelay ? 10 : 500;

  return (
    <div style={{ position: 'relative' }}>
      {!isImageLoaded && !spinner &&
        <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0
          // borderWidth: 1, borderColor: 'red', borderStyle: 'solid'
        }}>
          <Placeholder fluid
            style={{ height: '100%', width: '100%'
            // borderWidth: 1, borderColor: 'blue', borderStyle: 'solid'
          }}>
            <Placeholder.Image />
          </Placeholder>
        </div>

      }
      {!isImageLoaded && spinner &&
        <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0
          // borderWidth: 1, borderColor: 'red', borderStyle: 'solid'
        }}>
          <Placeholder fluid
            style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'
            // borderWidth: 1, borderColor: 'blue', borderStyle: 'solid'
          }}>
            <Spinner />
          </Placeholder>
        </div>

      }
      <img
        {...rest}
        onLoad={() => setTimeout(() => setImageIsLoaded(true), delay)}
        alt={props.alt || 'image'}
        style={{ ...props.style, display: 'block', visibility: isImageLoaded ? 'visible' : 'hidden' }}


      />
    </div>

  );

};

export default Image;
