import { connect } from 'react-redux';
import VerifyCameraUploadButton from '../../components/verify/verify-camera-upload-button';


const mapStateToProps = ({ user }, ownProps) => ({

  isFetching: user.isFetching,
  doc_name: ownProps.doc_name,
  cameraAction: ownProps.cameraAction,
  modalText: ownProps.modalText


});

const mapDispatchToProps = (dispatch, props) => ({

  // if camera is loading - set isFetching to true

});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCameraUploadButton);
