/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
// import '@trendmicro/react-buttons/dist/react-buttons.css';
// import '@trendmicro/react-dropdown/dist/react-dropdown.css';
// import 'react-dropdown/style.css';
// import 'react-select/dist/react-select.css';
import { ConfirmButton } from '../../styles/styles';
import { FormValueText, A5 } from '../../styles/text';
import { KidCheckBox } from '../calendar/calendar-styles';
import colours from '../../styles/colours';
import Spinner from './spinner';
import { other } from '../../text';


export default class KidsPicker extends Component {

  state = {
    open: false
  }


  handleClick = () => {

      // if click is inside, do something
      const { open } = this.state;
      this.setState({ open: !open });

  }

  handleOutsideClick = () => {

      // if click is inside, do something
      this.setState({ open: false });

  }

  onKidClick = (e, kid) => {

      console.log('onKidClick this.props:', this.props);
      console.log('onKidClick kid:', kid);

      const { setSelectedKids, removeSelectedKids, selected_kids } = this.props;

      if (this.isPresent(kid, selected_kids)) {
        console.log('onKidClick removeSelectedKids:');
        removeSelectedKids(kid);
      } else {
        setSelectedKids(kid);
      }

  }

  isPresent = (kidObject, selected_kids) => {

    // console.log('this.props', this.props);
    // const { selected_dates } = this.props;

    // console.log('kidObject:', kidObject);
    // console.log('selected_kids:', selected_kids);
    if (!selected_kids) {
      return false;
    }
    return selected_kids.filter((object) => {
      let object1 = object;
      let object2 = kidObject;
      if (typeof object !== 'object') {
        object1 = JSON.parse(object);
      }
      if (typeof kidObject !== 'object') {
        object2 = JSON.parse(kidObject);
      }


      return !this.isSameObject(object1, object2);


    }).length !== selected_kids.length;

  }

  isSameObject = (first, second) => {
    // console.log('first:', first);
    // console.log('second:', second);
    return (
      first.Name === second.Name
    );
  }

  renderButton = () => {


    const { isSavingKids, handleSubmit, selected_kids } = this.props;

    if (isSavingKids) {
      return <div style={{ justifyContent: 'center', alignItems: 'center' }}><Spinner size={20} /></div>;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ConfirmButton

          testID='SearchKids OK'

          disabled={isSavingKids}
          onClick={() => handleSubmit(selected_kids)}
        >
          {other.save_changes}
        </ConfirmButton>

      </div>

    );


  };

  render () {


    // console.log('options', options);
    // console.log('render this.props', this.props);


    const { selected_kids, all_kids } = this.props;
    // console.log('selected_kids:', selected_kids);

    // const selectedKidsNames = selected_kids.map((kid, i) => {
    //     const firstname = kid.Name;
    //     return firstname;
    // });


    const menuItems = all_kids.map((kid, i) => {

        const firstname = kid.Name;
        const isSelected = this.isPresent(kid, selected_kids);


        return (

          <button
            type='button'
            key={`myMenuKey Kid ${firstname}`}
            eventkey={firstname}
            index={i}
            onClick={e => this.onKidClick(e, kid)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.onKidClick(e, kid);
              }
            }}

            style={{
              width: '100%',
              height: 30,
              padding: 2,
              borderWidth: 0,
              // borderLeftWidth: 1,
              // borderRightWidth: 1,
              borderBottomWidth: 1,
              borderColor: colours.lightgray,
              borderStyle: 'solid',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              background: colours.white,
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}

          >

            <div style={{ alignItems: 'center', justifyContent: 'flex-start', borderColor: 'blue', borderStyle: 'solid', borderWidth: 0 }}>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 4, paddingRight: 4 }}>
                <KidCheckBox isChecked={isSelected} />
                <div style={{ paddingLeft: 4, paddingRight: 4 }}>
                  <A5>{firstname}</A5>
                </div>
              </div>


            </div>


          </button>

        );
    });

    return (

      <div style={{ display: 'inline-block', background: colours.white, width: '100%' }}>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
            {menuItems}
          </div>
        </div>

        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', paddingTop: 4, paddingBottom: 4, borderColor: colours.lightgray, borderWidth: 0, borderTopWidth: 0, borderStyle: 'solid' }}>


          { this.renderButton() }


        </div>
      </div>


    );
  }
}
