import React from 'react';
import colours from '../../styles/colours';

const Section = ({ maxWidth, zIndex, paddingTop, paddingBottom, extraPadding, paddingLeft, paddingRight, borderTop, fullWidth, noTopPadding, noBottomPadding, noSidePadding, window_width, children, backgroundColor, borderColor }) => {

    return (
      <div style={{ position: 'relative', zIndex, paddingTop, paddingBottom, marginLeft: !fullWidth ? 12 : 0, marginRight: !fullWidth ? 12 : 0, overflow: 'wrap',
        width: fullWidth ? window_width ? window_width - 24 : '100%' : null,
        maxWidth: maxWidth || null,
        flexDirection: 'row',
        background: backgroundColor, borderColor: borderColor || colours.borderColour, borderWidth: !borderColor ? 0 : 2, borderTopWidth: !borderColor ? borderTop ? 0 : 0 : 2, borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{
          paddingLeft: noSidePadding ? 0 : fullWidth ? paddingLeft || 12 : extraPadding ? 8 : paddingLeft || 0, paddingRight: noSidePadding ? 0 : fullWidth ? paddingRight || 12 : extraPadding ? 8 : paddingRight || 0,
          overflow: 'wrap',
          // width: '100%',
          flex: 1,
          flexDirection: 'column',
          paddingTop: noTopPadding ? 0 : 16, paddingBottom: noBottomPadding ? 0 : 16,
          background: backgroundColor, borderColor: colours.borderColour, borderWidth: 0, borderTopWidth: borderTop ? 1 : 0, borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          { children }
        </div>
      </div>
    );
};

export default Section;
