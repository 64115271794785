import * as upload from '../actions/general/upload';
import update from 'immutability-helper';

export const initialState = {
  selectedFiles: undefined,
  currentFile: undefined,
  progress: 0,
  blob: null,
  fileID: null,
  isFetching: false

};

export default function register (state = initialState, action) {
  switch (action.type) {

    case upload.SET_FILE_FETCHING:

      return {
        ...state,
        isFetching: true
      };

    case upload.SET_UPLOAD_PROGRESS:

      return {
        ...state,
        progress: action.data
      };


    case upload.SET_SELECTED_FILES:

      return {
        ...state,
        selectedFiles: action.data,
        isFetching: false
      };

    case upload.SET_CURRENT_FILE:

      return {
        ...state,
       currentFile: action.data
      };

    case upload.SET_BLOB:

      return {
        ...state,
       blob: action.data
      };

    case upload.SET_FILEID:

      return {
        ...state,
       fileID: action.data
      };


    default:
      return state;
  }
}
