import React, { Component } from 'react';
import {
    Modal,
    Button,
    Header
} from "semantic-ui-react";
import colours from '../../styles/colours';
import TextAreaField from '../general/textAreaField';
import { H5, H1, A5 } from '../../styles/text';
import { reviews } from '../../text';
// import { feedVertPaddingScale, moderateScale } from '../../styles/scaling';
import Spinner from '../general/spinner';
import Paragraph from '../general/paragraph';
import ModalHeader from '../general/modal-header';
import ModalSuccessIndicator from '../../containers/general/modal-success-indicator';
// import ReviewButtonContainer from '../../containers/review/submit-review-button';
// import StarRating from '../general/star-rating';
import StarButtons from '../general/star-buttons';


class Review extends Component {

  handleReviewTextChange (e) {

    const { setReview } = this.props;
    setReview(e.target.value);
  }

  handleSubmit () {
    const { rating, review, handleSubmitRating, thread_id, sitter_id, requester_id, user_id } = this.props;
    console.log('sitter_id:', sitter_id);
    console.log('requester_id:', requester_id);
    handleSubmitRating(rating, review, user_id, thread_id, sitter_id, requester_id);

  }


  render () {

    const { setRating, rating, review } = this.props;
    // timeout maybe?
    // ifSearching...


    // if (isSubmittingRating) {
    //   this.showSpinnerModal();
    // }

    // if (isSubmittingRating) {
    //   return <div style={{ flex: 1, width: '100%', height: '100%' }}><Spinner /></div>
    // }

    const getHelpMessage = () => {
      if (!rating) {
        return reviews.enter_rating;
      }
      if (!review) {
        return reviews.enter_review;
      }
      return '';
    };

    const helpMessage = getHelpMessage();

    const isButtonDisabled = !rating;

    const buttonText = reviews.submit;

    // const { title, header, para, para2, cancel, ok } = reviews;

    const header = reviews.header;


    return (
      <React.Fragment>
        <Modal.Content>

          <ModalHeader>{header}</ModalHeader>


          <div style={{ flexDirection: 'column', paddingBottom: 16, display: 'flex', background: 'transparent', justifyContent: 'center', alignItems: 'center' }}>

            <div style={{ marginBottom: 24, width: '100%', maxWidth: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

              <Paragraph>
                <A5>{reviews.how_did_you_rate}&nbsp;</A5>
              </Paragraph>

              <StarButtons rating={rating} setRating={setRating} />

              <Paragraph>
                <A5>{reviews.type_a_review}&nbsp;</A5>
              </Paragraph>
              <div style={{ width: '100%' }}>
                <TextAreaField
                  placeholder={reviews.review_text_placeholder}
                  autoCorrect={ false }
                  autoCapitalize='sentences'
                  onChangeText={ text => this.handleReviewTextChange(text) }
                  onBlur={ text => this.handleReviewTextChange(text) }
                  value={ review }
                  type='text'

                  // iconName={ iconName }
                  // inputKey= { inputKey }
                  focussedColor={ colours.focussedColor }
                  unfocussedColor={ colours.unfocussedColor }

                />
              </div>


              <div style={{ background: colours.white, padding: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', borderWidth: 0, borderTopWidth: 0, borderStyle: 'solid', borderColor: colours.borderColour }}>
                <H5 color={colours.red}>{`${helpMessage}`}</H5>
              </div>


            </div>

            <div style={{ display: 'flex', maxWidth: 200, width: '100%', flexDirection: 'row', justifyContent: 'space-around' }}>

              <Button
                primary

                style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
                // onClick={() => onOK()}
                disabled={isButtonDisabled}
                onClick={() => this.handleSubmit()}
              >

                {buttonText}

              </Button>


            </div>

          </div>

        </Modal.Content>

      </React.Fragment>
    );
  }

}

export default Review;
