import React, { Component } from 'react';
import moment from 'moment';
import { persistor } from '../../init-store';
import { MoreButton } from '../../styles/styles';
import { A5, H5 } from '../../styles/text';
import colours from '../../styles/colours';
import imgSitting from '../../images/DB_CB_you_are_sitting.svg';
import imgHaveSitter from '../../images/DB_CB_you_have_a_sitter.svg';
import imgAreyouSitting from '../../images/DB_NC_are_you_sitting.svg';
import imgHaveYouSitter from '../../images/DB_NC_have_you_a_sitter.svg';
import { calendar } from '../../text/calendar';
import * as BOOKING_TYPES from '../../constants/booking-types';
import { CONFIRMED } from '../../constants/booking-status';

const WidgetImage = ({ src }) => {

    return (

      <div style={{ width: 40, height: 40, marginRight: 4, display: 'flex', justifyContent: 'center', backgroundColor: colours.backgroundGray }}>

        <img alt='icon' style={{ width: '100%', objectFit: 'contain' }} src={ src } />

      </div>
    );


};


class UpcomingWidget extends Component {

  constructor (props) {
    super(props);

    this.state = {

      available: [],
      am_booked: [],
      booker_sitter: [],
      need_sitter: [],
      am_offered: [],
      awaiting_finalise: [],
      need_finalise: [],
      show_more: false

    };

  }


  // make sure to remove the listener
  // when the component is not mounted anymore

  componentDidMount () {
    persistor.pause();


    const { available_dates, booked_dates } = this.props;
    const amBooked = [];
    const amOffered = [];
    const bookedSitter = [];
    const needSitter = [];
    const awaitingFinalise = [];
    const needFinalise = [];
    const today = moment();

    booked_dates.map((item) => {
      const itemDateString = `${item.day}/${item.month}/${item.year}`;
      const itemMoment = moment(itemDateString, 'DD/MMMM/YYYY');
      const isInPast = moment(itemMoment).isBefore(today);
      if (isInPast) {
        if (item.booking_params.booking_status === 'pre-authorised') {
          if (item.booking_params.booking_type === BOOKING_TYPES.SITTING) {
            needFinalise.push(item);
          } else {
            awaitingFinalise.push(item);
          }
        }
      }
      if (!isInPast) {

        if (item.booking_params.booking_type === BOOKING_TYPES.SITTING) {
          if (item.booking_params.booking_status === CONFIRMED) {
            amBooked.push(item);
          } else {
            amOffered.push(item);
          }
        } else if (item.booking_params.booking_type === BOOKING_TYPES.USING) {
          if (item.booking_params.booking_status === CONFIRMED) {
            bookedSitter.push(item);
          } else {
            needSitter.push(item);
          }
        }

      }
    });

    this.setState({
      available: available_dates,
      booked_sitter: bookedSitter,
      am_booked: amBooked,
      am_offered: amOffered,
      need_sitter: needSitter,
      awaiting_finalise: awaitingFinalise,
      need_finalise: needFinalise
    });

    console.log('this.state:', this.state);
  }

  componentWillReceiveProps (nextProps) {

    const { available_dates, booked_dates } = nextProps;
    const amBooked = [];
    const amOffered = [];
    const bookedSitter = [];
    const needSitter = [];
    const awaitingFinalise = [];
    const needFinalise = [];
    const today = moment();

    booked_dates.map((item) => {
      const itemDateString = `${item.day}/${item.month}/${item.year}`;
      const itemMoment = moment(itemDateString, 'DD/MMMM/YYYY');
      const isInPast = moment(itemMoment).isBefore(today);
      console.log('booked_date item:', item);
      console.log('isInPast:', isInPast);
      if (isInPast) {
        if (item.booking_params.booking_status === 'pre-authorised') {
          if (item.booking_params.booking_type === BOOKING_TYPES.SITTING) {
            needFinalise.push(item);
          } else {
            awaitingFinalise.push(item);
          }
        }
      }
      if (!isInPast) {

        if (item.booking_params.booking_type === BOOKING_TYPES.SITTING) {
          if (item.booking_params.booking_status === CONFIRMED) {
            amBooked.push(item);
          } else {
            amOffered.push(item);
          }
        } else if (item.booking_params.booking_type === BOOKING_TYPES.USING) {
          if (item.booking_params.booking_status === CONFIRMED) {
            bookedSitter.push(item);
          } else {
            needSitter.push(item);
          }
        }

      }
    });

    this.setState({
      available: available_dates,
      booked_sitter: bookedSitter,
      am_booked: amBooked,
      am_offered: amOffered,
      need_sitter: needSitter,
      awaiting_finalise: awaitingFinalise,
      need_finalise: needFinalise
    });

    console.log('this.state:', this.state);
  }


  nextPage = () => {

      // history.push('dashboard');
  }

  previousPage = () => {
    // history.push('dashboard');
  }

  toggleExpand = (i, status, route) => {

    if (route === '') { // no route defined, expand/collapse

      const { menu_items } = this.state;

      const newState = menu_items.map((item, j) => {
        if (i === j) {
          return { ...item, is_open: !status };
        }
        // else
        return item;
      });

      this.setState({ menu_items: newState });

    } else {
      this.handleSelect(route);
    }
     // else if route then push route


  }

  showMore = () => {
    const { show_more } = this.state;
    this.setState({ show_more: !show_more });
  }


  render () {
    const { widget_type } = this.props;

    const vhHeight = document.documentElement.clientHeight;
    console.log('vhHeight', vhHeight);

    console.log('this.state: ', this.state);

    let widgetIcon;
    let noItemsText = '';
    let widgetText = '';
    let subtitleColor = colours.black;
    let widgetItems = '';
    let widgetArray = [];

    if (widget_type === 'booked_sitter') {
      widgetIcon = imgHaveSitter;
      noItemsText = calendar.noItems_booked_sitter;
      widgetText = calendar.subtitle_booked_sitter;
      subtitleColor = colours.using;
      widgetArray = this.state.booked_sitter;
    }
    if (widget_type === 'am_booked') {
      widgetIcon = imgSitting;
      noItemsText = calendar.noItems_am_booked;
      widgetText = calendar.subtitle_am_booked;
      subtitleColor = colours.sitting;
      widgetArray = this.state.am_booked;
    }
    if (widget_type === 'am_offered') {
      widgetIcon = imgAreyouSitting;
      noItemsText = calendar.noItems_am_offered;
      widgetText = calendar.subtitle_am_offered;
      subtitleColor = colours.offered;
      widgetArray = this.state.am_offered;
    }
    if (widget_type === 'need_sitter') {

      noItemsText = calendar.noItems_need_sitter;
      widgetText = calendar.subtitle_need_sitter;
      subtitleColor = colours.requested;
      widgetArray = this.state.need_sitter;
    }
    if (widget_type === 'available') {

      noItemsText = calendar.noItems_available;
      widgetText = calendar.subtitle_available;
      subtitleColor = colours.available;
      widgetArray = this.state.available;
      console.log('widgetArray:', widgetArray);
    }
    if (widget_type === 'awaiting_finalise') {
      widgetIcon = imgHaveYouSitter;
      noItemsText = calendar.noItems_awaiting_finalise;
      widgetText = calendar.subtitle_awaiting_finalise;
      subtitleColor = colours.awaitingFinalise;
      widgetArray = this.state.awaiting_finalise;
    }
    if (widget_type === 'need_finalise') {
      widgetIcon = imgHaveYouSitter;
      noItemsText = calendar.noItems_need_finalise;
      widgetText = calendar.subtitle_need_finalise;
      subtitleColor = colours.needFinalise;
      widgetArray = this.state.need_finalise;
    }
    console.log('widgetArray', widgetArray);
    let notPastIndex = -1;
    const now = moment();


    const showMore = this.state.show_more;
    const itemsToShow = showMore ? 40 : 5;

    widgetItems = widgetArray.map((item, i) => {
      console.log(`available ${i}: `, item);
      if ((i >= (notPastIndex + 1)) && (i < (notPastIndex + itemsToShow))) { // ensures only first 4


        const itemDate = `${item.day}/${item.month}/${item.year}`;
        console.log('step3');
        // filter out passed dates
        if (moment(itemDate).isBefore(now)) {
          notPastIndex += 1;
          return;
        }

        const displayDate = moment(itemDate, 'DD/MMMM/YYYY').format('dddd DD MMMM');
        let tod = '';
        if (item.time === 'am') {
          tod = 'morning';
        }
        if (item.time === 'pm') {
          tod = 'afternoon';
        }
        if (item.time === 'ev') {
          tod = 'evening';
        }
        if (item.time === 'on') {
          tod = 'overnight';
        }
        console.log('step4');


        return (
          <div key={`${displayDate}${item.time}`} style={{ display: 'flex', flexDirection: 'row', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', paddingTop: 1, paddingBottom: 1 }}>
            <H5
              style={{ padding: 0, margin: 0, paddingRight: 4, textAlign: 'left', color: colours.black, borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}
            >
              {`${displayDate}:`}
            </H5>
            <A5
              style={{ padding: 0, margin: 0, textAlign: 'left' }}
            >
              {tod}
            </A5>


          </div>

        );
      }
    });

    console.log('widgetItems:', widgetItems);
    if (widgetItems.length === 0 && noItemsText === '') {
      return <div />;
    }

    if (widgetItems.length === 0 && noItemsText !== '') {
      return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', paddingTop: 4, paddingBottom: 4 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {widgetIcon && <WidgetImage src={widgetIcon} />}
            <H5 style={{ color: subtitleColor }}>{noItemsText}</H5>
          </div>

        </div>
      );
    }

    return (

      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', borderColor: 'red', borderWidth: 0, borderStyle: 'solid', paddingTop: 4, paddingBottom: 4 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {widgetIcon && <WidgetImage src={widgetIcon} />}
          <H5 style={{ color: subtitleColor }}>{widgetText}</H5>
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>

          { widgetItems }

          <MoreButton onClick={() => this.showMore()}>{this.state.show_more ? 'View less' : 'View more'}</MoreButton>

        </div>
      </div>


    );
  }
}


export default UpcomingWidget;
