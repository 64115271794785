import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SetGA, ReportGA } from '../../utils/ga';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    Modal,
    Button,
    Header,
    Icon
} from "semantic-ui-react";
import { hideModal } from '../../actions/modal';
import { H4, H5, A5, A6 } from '../../styles/text';
import DropdownHourFromValueContainer from '../general/dropdown-hourFromValue';
import DropdownRecurValueContainer from '../general/dropdown-recurValue';
import BookingItem from '../../components/calendar/booking-item';
import colours from '../../styles/colours';
import { calendar } from '../../text';
import { dateRangeOverlaps, validateDates } from '../../lib/timeHelpers';
import { isOverlappingOtherBookings } from '../../lib/bookingsHelpers';
import { confirmAddAvailability, confirmEditAllAvailability } from '../../actions/calendar';
import { clearSearchQuery as clearSearchAction } from '../../actions/search';
import { EDIT_DAY } from '../../components/modals/modalNames';
import { AVAILABILITY as AVAI } from '../../constants/ga-category';
// need to add validation before save

// const actions = { hideModal };
const cellBorderColor = colours.mediumlightgray;


class EditDayModal extends Component {

  componentDidMount () {
    // clear Search to reset times
    const { clearSearchQuery } = this.props;
    clearSearchQuery();

    ReportGA({
      category: AVAI,
      action: `Modal shown to user ${EDIT_DAY}`
      // meta: { window_width, afterClose, hideModal, cancelAction, backAction, okAction, modalText, modalCategory, modalName, clashing_bookings, cameraAction, docStatus, adult_id, adult_name, doc_name, thread_stage, payment_type, user_type }
    });

  }


  render () {
    const {
      // title
    window_width,
    afterClose,
    hideModal,
    user_id,
    booking_id,
    // recurrance_id,
    handleAddAvailability,
    // isFetchingSuccess,
    date, selected_start_hr, selected_start_min, selected_end_hr, selected_end_min, selected_recurrance, booked_dates, available_dates, handleEditAvailability } = this.props;


    const { para, start, end, every, for_the_next, mandatory_fields, save, your_events } = calendar.edit_event;

    console.log('date:', date);
    console.log('this.props:', this.props);


    const dateString = `${date.day}-${date.month}-${date.year}`;
    // console.log('dateString:', dateString);

    const dateMoment = moment(dateString, 'DD-MMMM-YYYY');
    const availableDate = moment(dateString, 'DD-MMMM-YYYY').format('YYYY-MM-DD'); // to send to server
    // console.log('dateMoment:', dateMoment);
    const title = dateMoment.format('dddd DD MMMM');
    // console.log('title:', title);
    const day = dateMoment.format('dddd'); // Monday

    // select time and create availabilityObject

    // change below to be ISOString that's stored in available_dates and bookings (problem is how to do start and end time for a moment)

    const selected_start_time = `${selected_start_hr}:${selected_start_min}`;
    const selected_end_time = `${selected_end_hr}:${selected_end_min}`;

    const daysStartTimestamp = `${availableDate} 00:00`;
    const daysEndTimestamp = `${availableDate} 23:59`;

    const reformattedAvailableDates = available_dates.map((item) => {
      const parsed_dateRange = JSON.parse(item.available_dates);
      const dateRangeArray = [...parsed_dateRange];
      // console.log('parsed_dateRange:', parsed_dateRange);
      const start_timestamp = dateRangeArray[0];
      const end_timestamp = dateRangeArray[1];
      return { ...item, start_timestamp, end_timestamp };
    });

    const reformattedBookedDates = booked_dates.map((item) => {
      const start_timestamp = item.start;
      const end_timestamp = item.end;
      return { ...item, start_timestamp, end_timestamp };
    });

    const combinedBookingsArray = [...reformattedAvailableDates, ...reformattedBookedDates];
    // console.log('combinedBookingsArray:', combinedBookingsArray);
    const daysBookingsArray = combinedBookingsArray.filter((item, i) => {
      // console.log(`item ${i}:`, item);
      const { start_timestamp, end_timestamp } = item;

      const isOverlappingThisDay = dateRangeOverlaps(start_timestamp, end_timestamp, daysStartTimestamp, daysEndTimestamp);
      // console.log('isOverlappingThisDay:', isOverlappingThisDay);

      return isOverlappingThisDay;

    });

    const sortedDaysBookingsArray = daysBookingsArray.sort((a, b) => (moment(a.start_timestamp, 'YYYY-MM-DD HH:mm').isSameOrBefore(moment(b.start_timestamp, 'YYYY-MM-DD HH:mm'))) ? -1 : 1);
    console.log('sortedDaysBookingsArray:', sortedDaysBookingsArray);
    const bookingsArray = sortedDaysBookingsArray.map((item, i) => {
      console.log('daysBookingsArray item:', item);
      const { thread_id } = item;
      const { start_timestamp, end_timestamp } = item;

      const recurringCount = combinedBookingsArray.reduce((acc, cur) => cur.recurrance_id === item.recurrance_id ? ++acc : acc, 0);
      const recurrances = combinedBookingsArray.filter(booking => booking.recurrance_id === item.recurrance_id);
      // console.log('recurrances:', recurrances);

      // is this booking overlapping with any others?

      const isClashing = isOverlappingOtherBookings(start_timestamp, end_timestamp, daysBookingsArray); // should be all or just the day's?
      // console.log('isClashing:', isClashing);


      return (
        <BookingItem key={!item.booking_id ? i : item.booking_id} window_width={window_width} viewed_date={availableDate} user_id={user_id} booking_id={item.booking_id} recurrance_id={item.recurrance_id} recurring_count={recurringCount} recurrances={recurrances} thread_id={thread_id} start_timestamp={start_timestamp} end_timestamp={end_timestamp} booking_type={item.booking_type} booking_status={item.booking_status} clashing={isClashing} />
      );


    });
    // console.log('bookingsArray:', bookingsArray);


    const onClose = () => {
      hideModal();

      if (afterClose) {
        afterClose();
      }
    };

    // const { window_width, window_height, isUploadingDoc, isFetchingSuccess, location, user_id, isSubmittingForm, selectedAdult, photoUrl, firstname, surname, email, family_description, kids, adults, homeAddress, memberSince, dropdown_nav_is_open, profile_progress, all_reviews, all_threads, setSelfieImage, selectedSelfieImage, handleUploadSelfie, handleTakeAnother } = this.props;
    const dateFormatted = moment(availableDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
    const validDates = validateDates({ saved_start_date: dateFormatted, saved_start_hr: selected_start_hr, saved_start_min: selected_start_min, saved_end_date: dateFormatted, saved_end_hr: selected_end_hr, saved_end_min: selected_end_min });
    // console.log('selected_recurrance:', selected_recurrance);
    console.log('availableDate:', availableDate);
    console.log('selected_start_hr:', selected_start_hr);
    console.log('selected_start_min:', selected_start_min);
    console.log('selected_end_hr:', selected_end_hr);
    console.log('selected_end_min:', selected_end_min);
    console.log('validDates:', validDates);
    const isButtonDisabled = (
      // !isFetching &&
      (!selected_start_hr || !selected_start_min)) || (!selected_end_hr || !selected_end_min) || (!validDates);
    console.log('isButtonDisabled:', isButtonDisabled);

    return (

      <Modal
        centered={false} // top align
        closeIcon="close"
        open={true}
        onClose={onClose}
        style={{ maxWidth: 500 }}

      >

        <Modal.Content>


          <Header>

            <div style={{ marginTop: window_width > 991 ? 8 : 32, padding: 8, background: colours.mediumlightpurple, display: 'flex', justifyContent: 'center' }}>
              <H4 color={colours.white} textAlign='center'>{title}</H4>
            </div>

          </Header>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <p>
              <A5>{para}</A5>
            </p>
          </div>


          {
            <div>

              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderWidth: 1, borderStyle: 'solid', borderColor: cellBorderColor }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}><A5>{start}</A5><A5 color={colours.red}>*</A5></div>

                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <DropdownHourFromValueContainer
                      value={selected_start_hr ? `${selected_start_hr}` : 'Hr'}
                      dataType='start_hr'
                      narrow
                      // viewOnly={collapsed}
                    />
                    <p style={{ margin: 0, padding: 4, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    :
                    </p>
                    <DropdownHourFromValueContainer
                      value={selected_start_min ? `${selected_start_min}` : 'Min'}
                      dataType='start_min'
                      narrow
                      // viewOnly={collapsed}
                    />
                  </div>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, borderWidth: 0, borderLeftWidth: 1, borderStyle: 'solid', borderColor: cellBorderColor }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}><A5>{end}</A5><A5 color={colours.red}>*</A5></div>

                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <DropdownHourFromValueContainer
                      value={selected_end_hr ? `${selected_end_hr}` : 'Hr'}
                      dataType='end_hr'
                      narrow
                      // viewOnly={collapsed}
                    />
                    <p style={{ margin: 0, padding: 4, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    :
                    </p>
                    <DropdownHourFromValueContainer
                      value={selected_end_min ? `${selected_end_min}` : 'Min'}
                      dataType='end_min'
                      narrow
                      // viewOnly={collapsed}
                    />
                  </div>

                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, borderWidth: 1, borderTopWidth: 0, borderStyle: 'solid', borderColor: cellBorderColor }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}><A5>{`${every} ${day} ${for_the_next}`}</A5><A5 style={{ color: colours.red }}>*</A5></div>

                <DropdownRecurValueContainer
                  value={selected_recurrance}
                />

                <A6 color={colours.red}>{`* ${mandatory_fields}`}</A6>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderWidth: 1, borderTopWidth: 0, borderStyle: 'solid', borderColor: cellBorderColor, padding: 4 }}>
                <Button
                  primary
                  icon
                  labelPosition='left'
                  disabled={isButtonDisabled}

                  style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}

                  onClick={() => handleAddAvailability(combinedBookingsArray, booking_id, user_id, availableDate, selected_start_time, selected_end_time, selected_recurrance)}
                >
                  <Icon name='save' />
                  {save}
                </Button>


              </div>

              <div style={{ width: '100%', marginTop: 16, padding: 4, display: 'flex', justifyContent: 'center', borderWidth: 2, borderStyle: 'solid', borderColor: colours.available }}>
                <H5 color={colours.available}>{your_events}</H5>
              </div>


              <div style={{ width: '100%', marginTop: 16, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {bookingsArray}
              </div>


            </div>
          }


        </Modal.Content>


      </Modal>

    );
  }
}


EditDayModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

const mapStateToProps = ({ user, upload, layout, search, calendar }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  user_id: user.user_id,
  isFetchingUpload: user.isFetchingUpload,
  isFetchingSuccess: user.isFetchingSuccess,
  successText: ownProps.successText,
  fetchingText: ownProps.fetchingText,
  afterClose: ownProps.afterClose,
  hideModal: ownProps.hideModal,
  title: ownProps.title,
  message: ownProps.message,

  isUploadingDoc: user.isFetchingUpload,
  progress: upload.progress,
  currentFile: upload.currentFile,
  selectedFiles: upload.selectedFiles,
  blob: upload.blob,
  docStatus: ownProps.docStatus,
  doc_name: ownProps.doc_name,
  window_width: layout.window_width,
  selected_start_hr: search.saved_start_hr,
  selected_end_hr: search.saved_end_hr,
  selected_start_min: search.saved_start_min,
  selected_end_min: search.saved_end_min,
  selected_recurrance: search.selected_recurrance,
  available_dates: calendar.available_dates,
  booked_dates: calendar.booked_dates,
  editing_all: ownProps.editing_all

});

const mapDispatchToProps = dispatch => ({

  clearSearchQuery: () => {
    dispatch(clearSearchAction());
  },


  handleAddAvailability: (combinedBookingsArray, booking_id, user_id, date, start_time, end_time, recurrance) => {

    const token = localStorage.sitterpoint_token;

        if (token) {

            dispatch(confirmAddAvailability(token, combinedBookingsArray, booking_id, user_id, date, start_time, end_time, recurrance));
            // this action will do a check for overlapping dates and show modal if necessary

        }

  },

  handleEditAvailability: (booked_dates, booking_id, recurrance_id, user_id, date, start_time, end_time, recurrance, editing_all) => {
    const token = localStorage.sitterpoint_token;

        if (token) {

          dispatch(confirmEditAllAvailability(token, booked_dates, booking_id, recurrance_id, user_id, date, start_time, end_time, recurrance, editing_all));
        }
    // 1st modal prompt - do you wish to edit all? or just 1?
    // 2nd modal prompt - is there a clash?

    // what if already available for all / part? what if event when recurring or available?
  },


  hideModal: () => {
    dispatch(hideModal());
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(EditDayModal);
