import React from 'react';
import {
    Modal
} from "semantic-ui-react";
import { SetGA, ReportGA } from '../../utils/ga';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../actions/modal';
import ReviewContainer from '../review/review';

const ReviewModal = ({
  afterClose,
  hideModal,
  modalCategory,
  modalName
  // modalText
}) => {


  // const { header, para, para2, cancel, ok } = modalText;


  const onClose = () => {
    hideModal();

    if (afterClose) {
      afterClose();
    }
  };

  ReportGA({
    category: modalCategory,
    action: `Modal shown to user ${modalName}`
    // meta: { window_width, afterClose, hideModal, cancelAction, backAction, okAction, modalText, modalCategory, modalName, clashing_bookings, cameraAction, docStatus, adult_id, adult_name, doc_name, thread_stage, payment_type, user_type }
  });

  return (
    <Modal
      closeIcon={"close"}
      open={true}
      onClose={onClose}
      style={{ maxWidth: 500 }}
    >

      <ReviewContainer />

    </Modal>
  );
};

ReviewModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default connect(null, { hideModal })(ReviewModal);
