import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../styles/colours';
import { B6 } from '../../styles/text';

const StyledInput = styled(Input)`


  &.ui input {
    border-color: ${props => props.alert ? colours.red : colours.inputborder}!important;
    border-width: ${props => props.alert ? '2px' : '1px'}!important;
    border-style: solid!important;

  }

  &.ui input:focus {
    border-color: ${props => props.alert ? colours.red : colours.mediumlightpurple}!important;
    border-width: ${props => props.alert ? '2px' : '1px'}!important;
    border-style: solid!important;

  }


`;
// input field is set to width flex 1, do to choose specific width, wrap in div
class InputField extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isFocused: false
    };
  }

  handleFocus = () => {
    this.setState({ isFocused: true });
  }

  handleBlur = () => {
    this.setState({ isFocused: false });
  }

  render () {

    const {
      multiline, numberOfLines, labelType, value, alert, placeholder, error,
      onChangeText, testDescription, label, inputKey, optional,
      autoCapitalize, secureTextEntry, type, maxWidth, width, name, maxlength
    } = this.props;
    // const focusedColor = !focussedColor ? colours.main : focussedColor;
    // const unfocusedColor = !unfocussedColor ? colours.lightgray : unfocussedColor;
    // console.log('labelType', labelType);
    // console.log('input this.props', this.props);

    const labelText = label;

    // console.log('Input value:', value);

    return (
      <div
        style={{
          background: 'transparent',
          display: 'flex',
          width: width || '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 0,
          borderColor: 'blue',
          borderWidth: 0,
          borderStyle: 'solid'


      }}>
        <div style={{
          background: 'transparent',
          display: 'flex',
          flex: 1,
          width: width || null,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          borderColor: 'green',
          borderWidth: 0,
          borderStyle: 'solid'
        }}>
          { (labelText !== undefined)
            && <div style={{ alignSelf: 'flex-start', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
              {
                <B6
                  textAlign='left'
                >
                  {labelText}
                </B6>
              }
              { optional
                && <B6 color={colours.gray}>
                  {' - Optional'}
                </B6>
              }
            </div>
          }

          <div style={{ background: colours.white, display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'flex-end' }}>
            <div style={{ display: 'flex', maxWidth, width: '100%', flexDirection: 'row', background: 'transparent', paddingLeft: 0, paddingRight: 0, flex: 1 }}>
              <StyledInput
                style={{ width: '100%' }}
                maxlength={maxlength}
                key={inputKey}
                name={name}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                accessibilityLabel={testDescription}
                underlineColorAndroid='transparent'
                placeholder={ placeholder }
                onChange={onChangeText}
                value={ value }
                error={error}
                onInput={onChangeText}
                autoCapitalize={autoCapitalize}
                type={type}
                multiline={multiline}
                numberOfLines={numberOfLines} // check if does anything on android, else delete
                secureTextEntry={secureTextEntry}
                alert={alert}
              />
            </div>

          </div>
        </div>

      </div>


    );
  }
}

export default InputField;
