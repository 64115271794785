import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Header
} from 'semantic-ui-react';
import styled from 'styled-components';
import colours from '../../colours';
import { fonts } from "../../theme";


export const StyledP = styled.p`
  margin: 0px!important;
  font-weight: ${fonts.normal}!important;
  color: ${props => props.color ? props.color : `${colours.gray}!important`};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'}!important;
  font-size: ${props => props.screensize = 'small' ? '1em' : '4em'}!important;

`;

const mapStateToProps = (layout, ownProps) => {

    return {
      screensize: layout.screensize,
      color: ownProps.color,
      textAlign: ownProps.textAlign,
      ...ownProps
    };

};


class A4 extends Component {

  render () {

    const { screensize, color, textAlign } = this.props;
    // console.log('A4 props:', this.props);

    return (
      <StyledP

        screensize={screensize}

        color={color || null}
        textAlign={textAlign || null}

      >
        {this.props.children}
      </StyledP>
    );

  }
}

export default connect(
    mapStateToProps
)(A4);
