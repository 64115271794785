import React, { Component } from 'react';
import { ReportGA } from '../../utils/ga';
import history from './history';
import * as ROUTES from '../../constants/routes';
// import * as ROLES from '../../constants/roles';
import { NavButton, SignupButton } from '../../styles/styles';
import LogoMain from '../general/logo-main';
import colours from '../../styles/colours';
import DropdownNavContainer from '../../containers/Navigation/dropdown-nav-container';
import '../../styles/navigation.css';
import { nav } from '../../text';
import { navBarLoggedOutHeight } from '../../styles/layout';
import { BETA, SIGN_UP, SIGN_IN, NAV, SEARCH } from '../../constants/ga-category';


class NavBarLoggedOut extends Component {


  constructor () {
    super();

    this.showContactBeta = this.showContactBeta.bind(this);
    this.showSignInModal = this.showSignInModal.bind(this);
    this.showSignUpModal = this.showSignUpModal.bind(this);
  }


  showSignInModal () {
    const { showSignInModal } = this.props;

    console.log('clicked Sign In');

    ReportGA({
      category: SIGN_IN,
      action: 'User pressed Sign in button on navbar/navmenu'
    });

    showSignInModal();

  }

  showSignUpModal () {
    const { showSignUpModal } = this.props;

    console.log('clicked Sign Up');

    ReportGA({
      category: SIGN_UP,
      action: 'User pressed Create Account button on navbar'
    });

    showSignUpModal();
  }

  showContactBeta () {


    // console.log('clicked Contact Beta');

    ReportGA({
      category: BETA,
      action: 'User pressed ContactBeta button on navbar'
    });

    history.push(ROUTES.CONTACT_BETA);
  }

  goToLanding = () => {

    ReportGA({
      category: NAV,
      action: 'User pressed Go To Landing button on logger-out navbar/navmenu'
      // current location?
    });

    history.push({
        pathname: ROUTES.LANDING,
        state: { editMode: true }
    });

  };


  render () {
    const { currentRoute, window_width, showSignInModal, showSignUpModal } = this.props;

    const breakpoint = 700;

    const dividerHeight = navBarLoggedOutHeight / 3;

    const light = false;

    return (
      <div style={{ zIndex: 1000, background: colours.logopurple, minHeight: navBarLoggedOutHeight, width: '100%', display: 'flex', flexDirection: 'row', flex: 1, borderWidth: 0, borderStyle: 'solid', borderColor: colours.gray, boxShadow: window_width > 550 ? `0px 1px 0px ${colours.shadow}` : null }}>

        {
          (window_width < breakpoint)
          && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 48, paddingRight: 0, width: 48, borderWidth: 0, borderStyle: 'solid', borderColor: 'green', cursor: 'pointer' }}>


          </div>

        }

        {
          (window_width >= breakpoint)
          && <div style={{ background: colours.logopurple, width: '100%', paddingLeft: window_width >= breakpoint ? 0 : 16, paddingRight: window_width >= breakpoint ? 0 : 16, paddingTop: 0, paddingBottom: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: window_width > breakpoint ? 'center' : 'flex-start', flex: 1, borderWidth: 0, borderStyle: 'solid', borderColor: colours.gray }}>
            {
              (window_width >= breakpoint)
              && <div style={{ background: colours.logopurple, paddingLeft: window_width > breakpoint ? 0 : 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0, display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: window_width > breakpoint ? 'center' : 'flex-start', borderWidth: 0, borderStyle: 'solid', borderColor: colours.gray }}>
                <div style={{ flexDirection: 'column', flex: 1, display: 'flex' }}>
                  <button
                    type='button'
                    onClick={() => this.goToLanding()}
                    onKeyDown={() => this.goToLanding()}
                    style={{ padding: 0, cursor: 'pointer', display: 'flex', background: light ? 'transparent' : colours.borderColour, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderWidth: 0, borderStyle: 'solid', borderColor: colours.lightgray
                    }}
                  >
                    {
                      <div style={{ background: colours.logopurple }}>
                        <LogoMain center light={light} />
                      </div>
                    }

                  </button>

                </div>
              </div>
            }
            {
              (window_width > breakpoint)
              && <div style={{ background: colours.logopurple }} />
            }
            {
            (window_width > breakpoint)
              && <NavButton
                selected={currentRoute === ROUTES.HOWITWORKS}
                onClick={() => history.push(ROUTES.HOWITWORKS)}
              >
                {nav.how}
              </NavButton>
            }
            {
            (window_width > breakpoint)
              && <div style={{ height: dividerHeight, width: 1, marginLeft: 16, marginRight: 16, background: colours.borderColour }} />
            }
            {
            (window_width > breakpoint)
              && <NavButton
                selected={currentRoute === ROUTES.SITTER_POINTS}
                onClick={() => history.push(ROUTES.SITTER_POINTS)}
              >
                {nav.points}
              </NavButton>
            }
            {
            (window_width > breakpoint)
              && <div style={{ height: dividerHeight, width: 1, marginLeft: 16, marginRight: 16, background: colours.borderColour }} />
            }
            {
            (window_width > breakpoint)
              && <NavButton
                selected={currentRoute === ROUTES.PHILOSOPHY}
                onClick={() => history.push(ROUTES.PHILOSOPHY)}

              >
                {nav.philosophy}
              </NavButton>
            }
            {
            (window_width > breakpoint)
              && <div style={{ height: dividerHeight, width: 1, marginLeft: 16, marginRight: 16, background: colours.borderColour }} />
            }
            {
              window_width > breakpoint
              && <NavButton
                onClick={this.showSignInModal}
              >
                {nav.sign_in}
              </NavButton>
            }

            {
              window_width > breakpoint
              && <div style={{ height: dividerHeight, width: 0, marginLeft: 16, marginRight: 16, background: colours.borderColour }} />
            }
            {
              // <SignupButton
              //   onClick={this.showSignUpModal}
              // >
              //   {nav.sign_up}
              // </SignupButton>
            }
            {
              // currentRoute !== ROUTES.CONTACT_BETA &&
              // <SignupButton
              //   onClick={this.showContactBeta}
              // >
              //   {nav.become_beta}
              // </SignupButton>
            }


          </div>
        }

        {
          (window_width < breakpoint)
          && <div style={{ background: colours.logopurple, paddingLeft: window_width > breakpoint ? 0 : 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0, display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: window_width > breakpoint ? 'center' : 'flex-start', borderWidth: 0, borderStyle: 'solid', borderColor: colours.gray }}>
            <div style={{ flexDirection: 'column', flex: 1, display: 'flex' }}>
              <button
                type='button'
                onClick={() => this.goToLanding()}
                onKeyDown={() => this.goToLanding()}
                style={{ padding: 0, cursor: 'pointer', display: 'flex', background: light ? 'transparent' : colours.logopurple, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderWidth: 0, borderStyle: 'solid', borderColor: colours.lightgray
                }}
              >
                {
                  <div style={{ }}>
                  <LogoMain center light={light} />
                  </div>
                }

              </button>

            </div>
          </div>
        }

        {
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 48, paddingRight: 0, width: 48, borderWidth: 0, borderStyle: 'solid', borderColor: 'green', cursor: 'pointer' }}>
            <DropdownNavContainer
              orientation='burger'
              currentRoute={currentRoute}

            />

          </div>

        }

      </div>


    );
  }
}

export default NavBarLoggedOut;
