import React from 'react';
import PageWrapper from './page-wrapper';
import Section from './section';
import Paragraph from './paragraph';
import { LogoWrapper, LogoPic } from '../../styles/styles';
import colours from '../../styles/colours';
import LogoSitterpoint from '../../images/logo_sitterpoint.min.svg';
import { H1 } from '../../styles/text';
import { ExternalLink } from '../../styles/buttons';
import { getAPPURI } from '../../constants/env';

const homeUrl = getAPPURI();
console.log('homeUrl:', homeUrl);


const ErrorScreen = () => {

  return (

    <PageWrapper>

      <Section fullWidth>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <LogoWrapper
            style={{ display: 'block', overflow: 'hidden', borderWidth: 0, borderStyle: 'solid', borderColor: 'red' }}
          >

            <LogoPic

              alt='sitterpoint_logo'
              src={LogoSitterpoint}
              style={{ padding: 8, objectFit: 'cover', objectPosition: '0% 0%' }}

            />

          </LogoWrapper>

          <H1><span role='img' aria-label='Error'>⚠️ Error ⚠️</span></H1>

          <Paragraph textAlign='center'><strong>Uh oh, there was an error!</strong></Paragraph>
          <Paragraph textAlign='center'>Click <ExternalLink style={{ color: colours.darkpurple }} href={homeUrl}>here</ExternalLink> to return to the dashboard</Paragraph>
          <Paragraph textAlign='center'>If this problem persists, please contact the Sitterpoint team.</Paragraph>

        </div>
      </Section>

    </PageWrapper>
  );
};


export default ErrorScreen;
