import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Modal,
    Button
} from "semantic-ui-react";
import { ReportGA } from '../../utils/ga';
import { hideModal } from '../../actions/modal';
import Paragraph from '../../components/general/paragraph';
import ModalHeader from '../../components/general/modal-header';
// import Modal from '../../components/modals/modal';
import { A5, H5 } from '../../styles/text';
import colours from '../../styles/colours';
// import { calendar } from '../../text';
import BookingItemSmall from '../../components/calendar/booking-item-small';
import VerifyFileUploadButton from '../verify/verify-file-upload-button';
import VerifyCameraUploadButton from '../verify/verify-camera-upload-button';
import { useUserMedia } from '../../components/general/camera/hooks/use-user-media';
import { BACK_CAPTURE_OPTIONS, FRONT_CAPTURE_OPTIONS } from '../../components/general/camera';
import { CONFIRM_UPLOAD_TYPE } from '../../components/modals/modalNames';
// import Spinner from '../../components/general/spinner';
import SpinnerModal from './spinner';
import Image from '../../components/general/image';
import IdSelfieIcon from '../../images/id_selfie.min.svg';
import POAIcon from '../../images/poa.min.svg';
import DBSIcon from '../../images/dbs.min.svg';
// import IdSelfieIcon from '../../images/id_selfie_text.min.svg';

const debugMode = process.env.REACT_APP_APP_NODE_ENV !== 'production' && process.env.REACT_APP_APP_ENV === 'local';
console.log('ConfirmUploadTypeModal local debugMode: ', debugMode);

const checkCamera = (frontMediaStream, backMediaStream) => {

  console.log('call checkCamera:');

  const backCameraAvailable = (!backMediaStream ? false : true);
  const frontCameraAvailable = (!frontMediaStream ? false : true);
  console.info('CameraButton backCameraAvailable:', backCameraAvailable);
  console.info('CameraButton frontCameraAvailable:', frontCameraAvailable);
  const anyCameraAvailable = backCameraAvailable || frontCameraAvailable;
  console.info('CameraButton anyCameraAvailable:', anyCameraAvailable);

  return anyCameraAvailable;

};


const ConfirmUploadTypeModal = ({
  // title
  window_width, showClose, afterClose, hideModal, cancelAction, backAction, okAction, modalText, modalCategory, modalName, clashing_bookings, cameraAction, docStatus, adult_id, adult_name, doc_name }) => {

  const { title, header, para, para2, cancel, ok, icon_caption, allow_camera, must_allow_camera, or_upload_file, we_need_to_verify, contact_us_help } = modalText;

  const [waiting, setWaiting] = useState(true);
  const [renderCount, setRenderCount] = useState(0);


  const onClose = () => {
    hideModal();

    if (afterClose) {
      afterClose();
    }
  };

  const onOK = () => {
    // okAction();
    setRenderCount(2);
    // hideModal();

  };

  ReportGA({
    category: modalCategory,
    action: `Modal shown to user ${modalName}`
    // meta: { window_width, afterClose, hideModal, cancelAction, backAction, okAction, modalText, modalCategory, modalName, clashing_bookings, cameraAction, docStatus, adult_id, adult_name, doc_name }
  });


  const renderClashingBookings = clashing_bookings && clashing_bookings.map((item) => {

    console.log('item:', item);
    let start;
    let end;

    if (!item.available_dates) {
      start = item.start;
      end = item.end;
    } else {
      // parse available_dates
      // ["2021-08-20 02:30:00","2021-08-20 05:30:00"]
      const parsed_dateRange = JSON.parse(item.available_dates);
      const dateRangeArray = [...parsed_dateRange];
      console.log('parsed_dateRange:', parsed_dateRange);
      start = dateRangeArray[0];
      end = dateRangeArray[1];
    }


    return (
      <BookingItemSmall key={item.booking_id} window_width={window_width} booking_id={item.booking_id} recurrance_id={item.recurrance_id} thread_id={1} start_timestamp={start} end_timestamp={end} booking_type={item.booking_type} booking_status={item.booking_status} />
    );
  });

  console.log('MOD usesCamera modalName:', modalName);
  const usesCamera = modalName === CONFIRM_UPLOAD_TYPE;
  console.log('MOD usesCamera:', usesCamera);


  // must be called here in react component or in custom react hook
  // cannot be called conditionally, hence why created bespoke modal.

  console.log('FRONT_CAPTURE_OPTIONS:', FRONT_CAPTURE_OPTIONS);
  console.log('BACK_CAPTURE_OPTIONS:', BACK_CAPTURE_OPTIONS);

  const frontMediaStream = useUserMedia(FRONT_CAPTURE_OPTIONS);
  const backMediaStream = useUserMedia(BACK_CAPTURE_OPTIONS);

  const isCameraAvailable = !usesCamera ? false : checkCamera(frontMediaStream, backMediaStream);
  console.log('isCameraAvailable:', isCameraAvailable);
  console.log('doc_name:', doc_name);


  useEffect(() => {
    console.log('MOD useEffect waiting:', waiting);
    console.log('MOD useEffect renderCount:', renderCount);
    if (!usesCamera) {
      // return instantly
      setWaiting(false);
    } else {
      // make pause to wait for camera to load before returning
      setTimeout(() => {
        setRenderCount(renderCount + 1);
        setWaiting(false);
      }, 1000);
    }
  }, []);


  if (waiting) {
    return (
      <SpinnerModal />
    );

  }

  console.log('doc_name:', doc_name);
  console.log('isCameraAvailable:', isCameraAvailable);

  const showAllowCamera = (
    usesCamera
    &&
      (
        (doc_name === 'id' || doc_name === 'id_selfie' || (doc_name === 'photo' && renderCount === 1))
        && !isCameraAvailable
      )

  );

  const showNormal = !showAllowCamera;

  console.log('MOD renderCount:', renderCount);

  console.log('MOD usesCamera true: ', usesCamera);
  console.log('MOD and');

  console.log('MOD showAllowCamera false: ', showAllowCamera);
  console.log('MOD or');
  console.log('MOD doc_name photo: ', doc_name);
  console.log('MOD renderCount > 1: ', renderCount);

  return (


    <Modal
      closeIcon={showClose && "close"}
      open={true}
      onClose={showClose && onClose}
      style={{ maxWidth: 500 }}
    >
      { // <Modal.Header>{title}</Modal.Header>
      }
      <Modal.Content>

        {
          (showNormal) &&
          <ModalHeader>{header}</ModalHeader>
        }

        {
          (showAllowCamera) &&
          <ModalHeader>{allow_camera}</ModalHeader>
        }


        <div style={{ flexDirection: 'column', paddingBottom: 16, display: 'flex', background: 'transparent', justifyContent: 'center', alignItems: 'center' }}>

          {
            // document icons
          }
          {
            doc_name === 'id'
            && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Image height={64} src={IdSelfieIcon} />
              <Paragraph><A5 color='red' textAlign='center'>{icon_caption.id}</A5></Paragraph>
            </div>
          }
          {
            doc_name === 'poa'
            && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Image height={64} src={POAIcon} />
              <Paragraph><A5 color='red' textAlign='center'>{icon_caption.poa}</A5></Paragraph>
            </div>
          }
          {
            doc_name === 'dbs'
            && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Image height={64} src={DBSIcon} />
              <Paragraph><A5 color='red' textAlign='center'>{icon_caption.dbs}</A5></Paragraph>
            </div>
          }

          {
            // main text
          }
          {
            showNormal
            &&
            <div style={{ marginBottom: 24, maxWidth: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

              <Paragraph>

                <A5 textAlign='center'>{`${para}`}</A5>

              </Paragraph>
              {(para2) &&
                <Paragraph>

                  <A5 textAlign='center'>{para2}</A5>

                </Paragraph>
              }
              {(clashing_bookings && clashing_bookings.length > 0) &&
                <div style={{ width: '100%', marginTop: 16, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <H5>{`${modalText.clashing_bookings}`}</H5>
                  {renderClashingBookings}
                  <A5 textAlign='center'>{`${modalText.note}`}</A5>
                </div>
              }

            </div>
          }

          {
            showAllowCamera &&
            <div style={{ marginBottom: 24, maxWidth: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

              <Paragraph>

                <A5 textAlign='center'>{must_allow_camera}</A5>

              </Paragraph>

              <Paragraph>

                <A5 textAlign='center'>
                  {doc_name === 'photo' ? or_upload_file : we_need_to_verify}
                </A5>

              </Paragraph>

              <Paragraph>

                <A5 textAlign='center'>{contact_us_help}</A5>

              </Paragraph>


            </div>
          }

          <div style={{ display: 'flex', maxWidth: 200, width: '100%', flexDirection: 'row', justifyContent: 'space-around' }}>
            { (!usesCamera || (doc_name === 'photo' && renderCount === 1)) &&
              <>
                {
                  (doc_name !== 'photo' || renderCount !== 1) &&

                  <Button
                    // secondary

                    // style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
                    onClick={cancelAction}
                  >
                    {cancel}
                  </Button>
                }

                {
                  // removed as only want File or Camera Button
                  // <Button
                  //   primary
                  //
                  //   style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
                  //   onClick={onOK}
                  // >
                  //   {ok}
                  // </Button>
                }

              </>
            }

            { usesCamera
              && (!showAllowCamera || (doc_name === 'photo' && renderCount >= 1))
              &&
              // want to hide these two buttons and show spinner until 'allow camera' prompt has cleared and then time for buttons to load
              // but want to hide both buttons - may need to put is camera active allowed in redux and then apply timeout to check before setting visibility on.

              <>
                <VerifyFileUploadButton
                  isVerify={doc_name !== 'photo'}
                  primary

                  style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
                  // onClick={fileAction}

                  docStatus={docStatus}
                  adult_id={adult_id}
                  adult_name={adult_name}
                  doc_name={doc_name}
                >
                  {modalText.file}
                </VerifyFileUploadButton>

                {
                  isCameraAvailable &&
                  <VerifyCameraUploadButton docName={doc_name} cameraAction={cameraAction} modalText={modalText} />

                }
              </>
            }


          </div>

        </div>

      </Modal.Content>

    </Modal>

  );

};


ConfirmUploadTypeModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

const mapStateToProps = ({ layout }, ownProps) => ({

  window_width: layout.window_width,
  afterClose: ownProps.afterClose,
  hideModal: ownProps.hideModal,
  cancelAction: ownProps.cancelAction,
  backAction: ownProps.backAction,
  okAction: ownProps.okAction,
  clashing_bookings: ownProps.clashing_bookings

});

export default connect(mapStateToProps, { hideModal })(ConfirmUploadTypeModal);
