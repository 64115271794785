import React from 'react';
import { Oval } from 'react-loader-spinner';
// import { moderateScale } from '../../../styles/scaling';
import colours from '../../styles/colours';


export default function Spinner ({ color }) {
  // console.log('size', size);
  // const newSize = size || 2;
  // console.log('newSize', newSize);
  // const scaledSize = moderateScale(newSize);
  const useColor = !color ? colours.darkpurple : color;

  return (
    <div style={{ padding: 4, background: 'transparent' }}>
      <Oval
        height='80'
        width='80'
        color={useColor}
        ariaLabel='loading'
      />
    </div>
  );
}
