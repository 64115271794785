import { connect } from 'react-redux';
import { SetGA, ReportGA } from '../../utils/ga';
import DropdownNav from '../../components/Navigation/dropdown-nav';
import { persistor, store } from '../../init-store';
import { logout } from '../../actions/auth/signup';
import { showModal, hideModal } from '../../actions/modal';
import { openDropdownNav, closeDropdownNav } from '../../actions/navbar';
import { getUserById } from '../../actions/auth/profile';
// 
import { getAllThreadsById } from '../../actions/messages';
import { getAllTransactionsById } from '../../actions/transactions';
import * as ROUTES from '../../constants/routes';
import history from '../../components/Navigation/history';
import { MODAL_TYPE_SIGNIN, MODAL_TYPE_SIGNUP } from '../../components/modals/modalTypes';
import { NAV } from '../../constants/ga-category';

const mapStateToProps = ({ user, nav, transactions, inbox, layout }, ownProps) => {
  // console.log('this.state:', this.state);
  // console.log('localStorage: ', localStorage);
  return {
    isLoggedIn: user.isLoggedIn,
    isLoggingIn: user.isLoggingIn,
    window_width: layout.window_width,
    firstname: user.firstname,
    dropdown_nav_is_open: nav.dropdown_nav_is_open,
    user_id: user.user_id,
    all_transactions: transactions.all_transactions,
    sp_balance: user.sp_balance,
    sp_pending: user.sp_pending,
    all_threads: inbox.all_threads,
    currentRoute: ownProps.currentRoute
  };

};

const mapDispatchToProps = dispatch => ({
  handleLogOut: () => {
    // console.log('localStorage: ', localStorage);
    localStorage.removeItem('sitterpoint_token');

    localStorage.removeItem('sitterpoint_user_id');

    history.push(ROUTES.LANDING);

    // const rootNavigator = nav.getNavigator('root');
    // rootNavigator.replace('auth');
    // clean up persisted state
    persistor.purge();
    // store.getState().network.socket.disconnect();
    dispatch(logout());
    // redirect

    ReportGA({
      category: NAV,
      action: `User clicked LogOut button on Nav Menu`,
    });

  },
  handleGetUser: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getUserById(token, user_id));

    }

  },
  handleGetAllThreads: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getAllThreadsById(token, user_id));

    }

  },
  handleGetAllTransactions: (user_id) => {

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(getAllTransactionsById(token, user_id));

    }

  },
  // setSelectedValue: (value, inputKey, dataType) => {
  //   console.log('value: ', value);
  //   console.log('inputKey: ', inputKey);
  //   console.log('dataType: ', dataType);
  //
  //   dispatch(setValue(value, inputKey, dataType));
  //
  // },
  handleDropdownNavClick: (dropdown_nav_is_open) => {
    if (dropdown_nav_is_open) {
      console.log('dropdown-nav-container closeDropdownNav when clicking Dropdown Nav button');
      dispatch(closeDropdownNav());
    } else {
      console.log('dropdown-nav-container openDropdownNav');
      dispatch(openDropdownNav());
    }
  },
  handleCloseDropdownNav: () => {
    dispatch(closeDropdownNav());
  },
  showSignInModal: () => {
    dispatch(showModal(MODAL_TYPE_SIGNIN, {

    }));

    ReportGA({
      category: NAV,
      action: `User clicked SignIn on Dropdown Nav Menu`
    });

  },
  showSignUpModal: () => {
    dispatch(showModal(MODAL_TYPE_SIGNUP, {

    }));

    ReportGA({
      category: NAV,
      action: `User clicked SignUp on Dropdown Nav Menu`
    });
  },
  hideModal: () => {
    dispatch(hideModal());
  }
});

const DropdownNavContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DropdownNav);

export default DropdownNavContainer;
