import { createBrowserHistory } from 'history';
// import { SendGA, SetGA } from '../../utils/ga';

const history = createBrowserHistory();
// Initialize google analytics page view tracking

// history.listen((location) => {
//   SetGA({
//     page: location.pathname
//   });
//   // Update the user's current page
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
//
//   SendGA({
//     hitType: 'pageview',
//     page: location.pathname
//   });
//   // Record a pageview for the given page - TO CHECK
//
// });

export default history;
