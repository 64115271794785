import ReactGA from 'react-ga4';
import { versionNo } from '../constants/env';

const trackingId = `${process.env.REACT_APP_GA_ID}`; // Replace with PROD ID when transfer to live domain

if (ReactGA) {
  ReactGA.initialize(trackingId);
  console.log('Google Analytics available');
} else {
  console.log('Google Analytics blocked or not available');
}


export const SetGA = (data) => {

  // try catch?

  if (ReactGA) {
    // set Google Analytics user_id if exists
    ReactGA.set({
      userId: data.user_id && data.user_id,
      versionNo,
      page: data.page && data.page
      // any data that is relevant to the user session
      // that you would like to track with google analytics
    });
  }


};

export const SendGA = (data) => {


  if (ReactGA) {

    ReactGA.send({
      hitType: data.hitType && data.hitType,
      page: data.page && data.page

    });
  }


};

export const ReportGA = ({ category, action }) => {

  // try catch?

  if (ReactGA) {

    ReactGA.event({
      category,
      action

    });

  }


};


export default ReportGA;
