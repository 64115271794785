import { connect } from 'react-redux';
import Review from '../../components/review/review';
import { showModal, hideModal } from '../../actions/modal';
import { setRating, setReview, submitRating } from '../../actions/review';

const mapStateToProps = ({ user, review, messageThread, nav }) => {

  return {
    // serverError: user.error,
    // isConnected: network.isConnected,
    user_id: user.user_id,
    dropdown_nav_is_open: nav.dropdown_nav_is_open,
    isSubmittingRating: review.isSubmittingRating,
    rating: review.rating,
    review: review.review,
    sitter_id: messageThread.thread_details.sitter_id,
    requester_id: messageThread.thread_details.requester_id,
    thread_id: messageThread.thread_details.thread_id

  };

};

const mapDispatchToProps = dispatch => ({

  handleSubmitRating: (rating, review, user_id, thread_id, sitter_id, requester_id) => { //eslint-disable-line

    const token = localStorage.sitterpoint_token;

    if (token) {
      dispatch(submitRating(token, rating, review, user_id, thread_id, sitter_id, requester_id));
    }

  },
  setRating: (value) => {

    dispatch(setRating(value));

  },
  setReview: (value) => {
    console.log('value:', value);

    dispatch(setReview(value));

  },

  hideModal: () => {
    dispatch(hideModal());
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(Review);
