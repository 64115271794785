
import history from '../../components/Navigation/history';
import { showModal, hideModal } from '../modal';
import { MODAL_TYPE_SPINNER } from '../../components/modals/modalTypes';
// import initSocket from '../socket-router';
// require('dotenv').config();


export const SET_USER_INPUT = 'SET_USER_INPUT';
export const SET_SELECTED_DOC = 'SET_SELECTED_DOC';
export const RESET_SELECTED_DOC = 'RESET_SELECTED_DOC';
export const SUBMIT_ADULTS_REQUEST = 'SUBMIT_ADULTS_REQUEST';
export const SUBMIT_ADULTS_SUCCESS = 'SUBMIT_ADULTS_SUCCESS';
export const SUBMIT_ADULTS_FAILURE = 'SUBMIT_ADULTS_FAILURE';
export const GET_USER_AS_ADMIN_REQUEST = 'GET_USER_AS_ADMIN_REQUEST';
export const GET_USER_AS_ADMIN_SUCCESS = 'GET_USER_AS_ADMIN_SUCCESS';
export const GET_USER_AS_ADMIN_FAILURE = 'GET_USER_AS_ADMIN_FAILURE';
export const GET_USER_RESET = 'GET_USER_RESET';
export const VERIFY_DOCUMENT_REQUEST = 'VERIFY_DOCUMENT_REQUEST';
export const VERIFY_DOCUMENT_SUCCESS = 'VERIFY_DOCUMENT_SUCCESS';
export const VERIFY_DOCUMENT_FAILURE = 'VERIFY_DOCUMENT_FAILURE';
export const GET_USER_DOCUMENT_REQUEST = 'GET_USER_DOCUMENT_REQUEST';
export const GET_USER_DOCUMENT_SUCCESS = 'GET_USER_DOCUMENT_SUCCESS';
export const GET_USER_DOCUMENT_FAILURE = 'GET_USER_DOCUMENT_FAILURE';
export const GET_USER_DOCUMENT_SELFIE_REQUEST = 'GET_USER_DOCUMENT_SELFIE_REQUEST';
export const GET_USER_DOCUMENT_SELFIE_SUCCESS = 'GET_USER_DOCUMENT_SELFIE_SUCCESS';
export const GET_USER_DOCUMENT_SELFIE_FAILURE = 'GET_USER_DOCUMENT_SELFIE_FAILURE';


export function setUserInput (data) {
  return {
    type: SET_USER_INPUT,
    data
  };
}

export function setSelectedDoc (data) {
  return {
    type: SET_SELECTED_DOC,
    data
  };
}

export const resetSelectedDoc = () => ({
  type: RESET_SELECTED_DOC
});

export const submitAdultsRequest = () => ({
  type: SUBMIT_ADULTS_REQUEST
});

export const submitAdultsSuccess = data => ({
  type: SUBMIT_ADULTS_SUCCESS,
  data
});

export const submitAdultsFailure = error => ({
  type: SUBMIT_ADULTS_FAILURE,
  error
});


export function submitAdults (token, user_id, adults, nextRoute) {
  // admin only (for now) function to allow verification

  // console.log('Adults:');
  // console.log('kids:', kids);
  // console.log('adults:', adults);
  // console.log('home_address:', home_address);
  // console.log('REACT_APP_API_URI:', process.env.REACT_APP_API_URI);
  console.log('submit reg');
  console.log('nextRoute:', nextRoute);
  const nextRouteToPush = !nextRoute ? '/profileSummary' : nextRoute;
  console.log('nextRouteToPush:', nextRouteToPush);

  return (dispatch) => {
    dispatch(showModal(MODAL_TYPE_SPINNER, {

    }));

    dispatch(submitAdultsRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/users/registration/${user_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ user: { adults } })
    })
    .then((response) => {
      console.log('Adults response:', response);
      response.json()
      .then((data) => {
        dispatch(submitAdultsSuccess(data));

        dispatch(hideModal());

        history.push({
          pathname: nextRouteToPush,
          state: { editMode: false }
        });


        // history.push('dashboard'); want this to happen from component - maybe hideModal from Component once rendered.
      })
      .catch((err) => {
        dispatch(submitAdultsFailure(err.message));
        dispatch(hideModal());
      });
    })
    .catch((error) => {
      dispatch(submitAdultsFailure(error.message));
      dispatch(hideModal());
    });
  };
}


export const getUserRequest = () => ({
  type: GET_USER_AS_ADMIN_REQUEST
});

export const getUserSuccess = data => ({
  type: GET_USER_AS_ADMIN_SUCCESS,
  data
});

export const getUserFailure = error => ({
  type: GET_USER_AS_ADMIN_FAILURE,
  error
});

export const getUserReset = () => ({
  type: GET_USER_RESET
});

export const getUserDocumentRequest = () => ({
  type: GET_USER_DOCUMENT_REQUEST
});

export const getUserDocumentSuccess = data => ({
  type: GET_USER_DOCUMENT_SUCCESS,
  data
});

export const getUserDocumentFailure = error => ({
  type: GET_USER_DOCUMENT_FAILURE,
  error
});

export const getUserDocumentSelfieRequest = () => ({
  type: GET_USER_DOCUMENT_SELFIE_REQUEST
});

export const getUserDocumentSelfieSuccess = data => ({
  type: GET_USER_DOCUMENT_SELFIE_SUCCESS,
  data
});

export const getUserDocumentSelfieFailure = error => ({
  type: GET_USER_DOCUMENT_SELFIE_FAILURE,
  error
});

// getUserDocument
// fetched user doc from doc db and returns details + image retrieved from S3 secret bucket.
// do we need to specify user so user can retrieve own?  Or if it is in secret bucket.
export function getUserDocument (token, user_id, admin_user_id, doc_id, doc_name) { // or get by doc_id

  // is user id needed?
  // filename needs to be included in document object/array
  return (dispatch) => {

    if (!doc_name || doc_name !== 'id_selfie') {
      dispatch(getUserDocumentRequest());
      console.log('getUserDocument action for id: ', user_id);
    } else {
      dispatch(getUserDocumentSelfieRequest());
      console.log('getUserDocument action for id: ', user_id);
    }

    fetch(`${process.env.REACT_APP_API_URI}/admin/document`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      },
      body: JSON.stringify({ doc_id, admin_user_id })
    })
    .then((res) => {
      console.log('getUserDocument res:', res);
      if (res.ok) {
        res.json()
        .then((data) => {

          console.log('getUserDocument response data:', data);
          if (!doc_name || doc_name !== 'id_selfie') {
            dispatch(getUserDocumentSuccess(data));
          } else {
            dispatch(getUserDocumentSelfieSuccess(data));
          }

        })
        .catch((err) => {
          console.log('caught getUserDocument error1: ', err);
          if (!doc_name || doc_name !== 'id_selfie') {
            dispatch(getUserDocumentFailure(err.message));
          } else {
            dispatch(getUserDocumentSelfieFailure(err.message));
          }
        });
      } else { // res not ok
        res.json()
        .then((data) => {
          console.log('if res not ok data.error: ', data.error);
          if (!doc_name || doc_name !== 'id_selfie') {
            dispatch(getUserDocumentFailure(data.error));
          } else {
            dispatch(getUserDocumentSelfieFailure(data.error));
          }
        });

      }

    })
    .catch((err) => {
      console.log('caught getUserDocument error2: ', err);
      if (!doc_name || doc_name !== 'id_selfie') {
        dispatch(getUserDocumentFailure(err.message));
      } else {
        dispatch(getUserDocumentSelfieFailure(err.message));
      }
    });
  };
}


export function getUserAsAdmin (token, user_id, admin_user_id) {
  return (dispatch) => {

    dispatch(getUserRequest());
    console.log('getUserAsAdmin action for id: ', user_id);

    fetch(`${process.env.REACT_APP_API_URI}/admin/users/${user_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      },
      body: JSON.stringify({ admin_user_id })
    })
    .then((res) => {
      console.log('getUserAsAdmin res:', res);
      if (res.ok) {
        res.json()
        .then((data) => {

            console.log('getUserAsAdmin response data:', data);
            dispatch(getUserSuccess(data));

        })
        .catch((err) => {
          console.log('caught getUserAsAdmin error1: ', err);
          dispatch(getUserFailure(err.message));
        });
      } else { // res not ok
        res.json()
        .then((data) => {
          console.log('if res not ok data.error: ', data.error);
          dispatch(getUserFailure(data.error));
        });

      }

    })
    .catch((err) => {
      console.log('caught getUser error2: ', err);
      dispatch(getUserFailure(err.message));
    });
  };
}

export const verifyDocumentRequest = () => ({
  type: VERIFY_DOCUMENT_REQUEST
});

export const verifyDocumentSuccess = data => ({
  type: VERIFY_DOCUMENT_SUCCESS,
  data
});

export const verifyDocumentFailure = error => ({
  type: VERIFY_DOCUMENT_FAILURE,
  error
});

export function verifyDocument (token, admin_user_id, adult_id, doc_id) {


  return (dispatch) => {
    dispatch(showModal(MODAL_TYPE_SPINNER, {

    }));

    dispatch(verifyDocumentRequest());
    console.log('submitted Action');
    fetch(`${process.env.REACT_APP_API_URI}/admin/verify`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({ user: { doc_id, admin_user_id } })
    })
    .then((response) => {
      console.log('verifyDocument response:', response);
      response.json()
      .then((data) => {
        console.log('verifyDocument data:', data);
        dispatch(verifyDocumentSuccess(data));
        dispatch(getUserAsAdmin(token, data.user_id, admin_user_id));
        // getUserAsAdmin to ensure adults array and verification statuses update

        dispatch(hideModal());

      })
      .catch((err) => {
        dispatch(verifyDocumentFailure(err.message));
        dispatch(hideModal());
      });
    })
    .catch((error) => {
      dispatch(verifyDocumentFailure(error.message));
      dispatch(hideModal());
    });
  };
}

export function unVerifyDocument (token, doc_id, admin_user_id) {

  return (dispatch) => {

  };
}
