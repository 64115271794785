import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Modal,
    Button,
    Icon,
    Dimmer
} from "semantic-ui-react";
import { ReportGA } from '../../utils/ga';
import ImageCropperRaw from '../../components/general/imageUpload/image-cropper-raw';
import ImageCropper from '../../components/general/imageUpload/image-cropper';
import Paragraph from '../../components/general/paragraph';
import ModalHeader from '../../components/general/modal-header';
import { hideModal } from '../../actions/modal';
import { uploadDoc, submitIDDoc, uploadIDDocs } from '../../actions/verify/index';
import { upload_photo } from '../../text/register';
import VerifySelfieUploadButton from '../verify/verify-selfie-upload-button';
import { A5 } from '../../styles/text';
import colours from '../../styles/colours';
import { verify as verifyText } from '../../text';
import { setUploadProgress, setCurrentFile, setSelectedFiles, setBlob } from '../../actions/general/upload';
import { UPLOAD_SELFIE } from '../../components/modals/modalNames';
import { UPLOAD as UPLO } from '../../constants/ga-category';


// const actions = { hideModal };


class UploadSelfieModal extends Component {

  // displays preview of image to be cropped, and buttons to submit if happy - will trigger imageCropper or imageCropperRaw

  componentDidMount () {

    ReportGA({
      category: UPLO,
      action: `Modal shown to user ${UPLOAD_SELFIE}`
      // meta: { window_width, afterClose, hideModal, cancelAction, backAction, okAction, modalText, modalCategory, modalName, clashing_bookings, cameraAction, docStatus, adult_id, adult_name, doc_name, thread_stage, payment_type, user_type }
    });
  }

  handleSubmitDoc = () => {

    const { fileID, adult_id, adult_name, doc_name, blob, handleDocUpload, handleIDDocsUpload, setProgress, setCurrentFile, setSelectedFiles } = this.props;

    // console.log('adult_id:', adult_id);
    // console.log('adult_name:', adult_name);
    // console.log('doc_name:', doc_name);
    // console.log('selectedFiles:', selectedFiles);

    const fileObject = blob; // ???

    setProgress(0);
    setCurrentFile(fileObject);

    console.log('selfie fileObject:', fileObject);

    // refactor to upload id + selfie together at same time

    if (doc_name === 'id_selfie') {

      handleIDDocsUpload(fileID, fileObject, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      }, adult_id, adult_name, doc_name);

    } else {
      handleDocUpload(fileObject, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      }, adult_id, adult_name, doc_name);
    }


    console.log('setSelectedFiles upload-selfie undefined');
    setSelectedFiles(undefined);
  };

  render () {
    const {
      // title
    window_width, window_height, cameraAspectRatio, isFetchingUpload, showClose, afterClose, hideModal, docStatus, adult_id, adult_name, doc_name, selectedFiles, setSelectedFiles, setBlob, handleTakeAnother } = this.props;

    // cameraAspectRatio from redux, set by CameraSelfie
    const cropperHeight = window_width > 650 ? window_height * 0.3 : window_height * 0.3; // need to get proper height
    const cropperWidth = cropperHeight * cameraAspectRatio;
    console.log('ImageCropperRaw upload-selfie.js cropperHeight', cropperHeight);
    console.log('ImageCropperRaw upload-selfie.js cropperWidth', cropperWidth);
    console.log('ImageCropperRaw upload-selfie.js cameraAspectRatio', cameraAspectRatio);
    console.log('ImageCropperRaw upload-selfie.js window_height', window_height);

    // const title =
    // doc_name === 'photo' ? upload_photo.selfie.title
    // : doc_name === 'id_selfie' ? verifyText.modal.upload_selfie_id_selfie.title
    // : doc_name === 'id' ? verifyText.modal.upload_selfie_id.title
    // : doc_name === 'poa' ? verifyText.modal.upload_selfie_poa.title
    // : verifyText.modal.upload_selfie_dbs.title;

    const header =
    doc_name === 'photo' ? upload_photo.selfie.header
    : doc_name === 'id_selfie' ? verifyText.modal.upload_selfie_id_selfie.header
    : doc_name === 'id' ? verifyText.modal.upload_selfie_id.header
    : doc_name === 'poa' ? verifyText.modal.upload_selfie_poa.header
    : verifyText.modal.upload_selfie_dbs.header;

    const para =
    doc_name === 'photo' ? upload_photo.selfie.para
    : doc_name === 'id_selfie' ? verifyText.modal.upload_selfie_id_selfie.para
    : doc_name === 'id' ? verifyText.modal.upload_selfie_id.para
    : doc_name === 'poa' ? verifyText.modal.upload_selfie_poa.para
    : verifyText.modal.upload_selfie_dbs.para;

    const para2 =
    doc_name === 'photo' ? upload_photo.selfie.para2
    : doc_name === 'id_selfie' ? verifyText.modal.upload_selfie_id_selfie.para2
    : doc_name === 'id' ? verifyText.modal.upload_selfie_id.para2
    : doc_name === 'poa' ? verifyText.modal.upload_selfie_poa.para2
    : verifyText.modal.upload_selfie_dbs.para2;

    const submit =
    doc_name === 'photo' ? upload_photo.selfie.submit
    : doc_name === 'id_selfie' ? verifyText.modal.upload_selfie_id_selfie.submit
    : doc_name === 'id' ? verifyText.modal.upload_selfie_id.submit
    : doc_name === 'poa' ? verifyText.modal.upload_selfie_poa.submit
    : verifyText.modal.upload_selfie_dbs.submit;

    // const take_another =
    // doc_name === 'photo' ? upload_photo.selfie.take_another
    // : doc_name === 'id_selfie' ? verifyText.modal.upload_selfie_id_selfie.take_another
    // : doc_name === 'id' ? verifyText.modal.upload_selfie_id.take_another
    // : doc_name === 'poa' ? verifyText.modal.upload_selfie_poa.take_another
    // : verifyText.modal.upload_selfie_dbs.take_another;


    const onClose = () => {
      hideModal();
      setSelectedFiles(undefined);

      if (afterClose) {
        afterClose();
      }
    };

    // const { window_width, window_height, isUploadingDoc, isFetchingSuccess, location, user_id, isSubmittingForm, selectedAdult, photoUrl, firstname, surname, email, family_description, kids, adults, homeAddress, memberSince, dropdown_nav_is_open, profile_progress, all_reviews, all_threads, setSelfieImage, selectedSelfieImage, handleUploadSelfie, handleTakeAnother } = this.props;
    // console.log('selectedFiles', selectedFiles);
    // console.log('isFetchingUpload', isFetchingUpload);
    // console.log('docStatus', docStatus);
    // console.log('adult_id', adult_id);
    // console.log('adult_name', adult_name);
    // console.log('doc_name', doc_name);

    if (!selectedFiles) {
      return (
        <Dimmer active={true} onClickOutside={onClose} page>
          <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, height: '100%', width: '100%', background: colours.black }}>
            {
              !selectedFiles
              && <VerifySelfieUploadButton
                // primary
                isVerify={doc_name !== 'photo'}
                style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
                // onClick={fileAction}

                docStatus={docStatus}
                adult_id={adult_id}
                adult_name={adult_name}
                doc_name={doc_name}
              >
                {submit}
              </VerifySelfieUploadButton>
            }
          </div>
        </Dimmer>
      );
    }

    return (

      <Modal
        closeIcon={showClose && "close"}
        open={true}
        onClose={showClose && onClose}
        style={{ maxWidth: 500 }}
      >
        { // <Modal.Header>{title}</Modal.Header>
        }
        <Modal.Content>

          <ModalHeader>{header}</ModalHeader>


          <div style={{ flexDirection: 'column', paddingBottom: 16, display: 'flex', background: 'transparent', justifyContent: 'center', alignItems: 'center' }}>

            <div style={{ padding: 8, marginBottom: 24, maxWidth: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

              <Paragraph>

                <A5 textAlign='center'>{`${para}`}</A5>

              </Paragraph>
              {(para2) &&
                <Paragraph>

                  <A5 textAlign='center'>{para2}</A5>

                </Paragraph>
              }
              {
                !selectedFiles
                && <VerifySelfieUploadButton
                  // primary
                  isVerify={doc_name !== 'photo'}
                  style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}
                  // onClick={fileAction}

                  docStatus={docStatus}
                  adult_id={adult_id}
                  adult_name={adult_name}
                  doc_name={doc_name}
                >
                  {submit}
                </VerifySelfieUploadButton>
              }

              {
                selectedFiles && !isFetchingUpload && (
                  <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>
                    {
                      (doc_name === 'photo')
                      && <div style={{ width: cropperHeight, height: cropperHeight, position: 'relative', borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>
                        <ImageCropper
                          cropShape={(doc_name === 'photo') ? 'round' : (doc_name === 'id_selfie') ? 'none' : 'square'}
                          aspect={(doc_name === 'photo' || doc_name === 'id_selfie') ? 1 : cameraAspectRatio} // 1 because square for photo avatar, else use camera aspect for id docs
                          getBlob={setBlob}
                          inputImg={selectedFiles}
                        />

                      </div>
                    }
                    {
                      (doc_name !== 'photo')
                      && <div style={{ height: cropperHeight, width: cropperWidth, position: 'relative', borderColor: 'orange', borderWidth: 0, borderStyle: 'solid' }}>

                        <ImageCropperRaw
                          cropShape={(doc_name === 'photo') ? 'round' : (doc_name === 'id_selfie' || doc_name === 'poa' || doc_name === 'dbs' || doc_name === 'id') ? 'none' : 'square'}
                          aspect={(doc_name === 'photo') ? 1 : cameraAspectRatio} // 1 because square for photo avatar, else use camera aspect for id docs
                          getBlob={setBlob}
                          inputImg={selectedFiles}
                        />


                      </div>
                    }
                  </div>
                )
              }

            </div>

            <div style={{ display: 'flex', maxWidth: 200, width: '100%', flexDirection: 'row', justifyContent: 'space-around' }}>

              {
                selectedFiles
                && <Button
                  // secondary
                  icon
                  labelPosition='left'

                  onClick={() => handleTakeAnother()}
                >
                  <Icon name='camera' />
                  {upload_photo.selfie.take_another}
                </Button>

              }

              {
                selectedFiles
                && <Button
                  primary
                  icon
                  labelPosition='left'

                  style={{ color: colours.white, backgroundColor: colours.orange, borderColor: colours.orange }}

                  onClick={() => this.handleSubmitDoc(selectedFiles)}
                >
                  <Icon name='upload cloud' />
                  {submit}
                </Button>
              }


            </div>

          </div>

        </Modal.Content>

      </Modal>


    );
  }
}


UploadSelfieModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

const mapStateToProps = ({ user, upload, layout }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  isFetchingUpload: user.isFetchingUpload,
  isFetchingSuccess: user.isFetchingSuccess,
  successText: ownProps.successText,
  fetchingText: ownProps.fetchingText,
  afterClose: ownProps.afterClose,
  showClose: ownProps.showClose,
  hideModal: ownProps.hideModal,
  title: ownProps.title,
  message: ownProps.message,

  isUploadingDoc: user.isFetchingUpload,
  progress: upload.progress,
  currentFile: upload.currentFile,
  selectedFiles: upload.selectedFiles,
  blob: upload.blob,
  fileID: upload.fileID,
  docStatus: ownProps.docStatus,
  doc_name: ownProps.doc_name,
  window_height: layout.window_height,
  window_width: layout.window_width,

  cameraAspectRatio: layout.cameraAspectRatio
  // cropperHeight: layout.window_width > 650 ? 300 : 220,
  // cropperWidth: cropperHeight * aspectRatio

});

const mapDispatchToProps = (dispatch, props) => ({
  // handleSubmitForm: ({ firstname, surname, email, password }, props) => {
  //
  //   dispatch(submitAboutChildren(firstname, surname, email.toLowerCase(), password, props.navigation));
  // },

  handleTakeAnother: () => {
    dispatch(setSelectedFiles(undefined));
  },

  hideModal: () => {
    dispatch(hideModal());
  },

  setSelectedFiles: (value) => {
    dispatch(setSelectedFiles(value));
    // if verify, don't hideModal, but show preview imageCropper
    if (!props.isVerify) {
      // dispatch(hideModal()); only hide Modal if profile photo upload from page
    }

  },
  setCurrentFile: (value) => {
    dispatch(setCurrentFile(value));
  },
  setProgress: (value) => {
    dispatch(setUploadProgress(value));
  },
  setBlob: (value) => {
    dispatch(setBlob(value));
  },

  // if doc_name is not id_selfie, test if upload or not
  handleDocUpload: (file, onUploadProgress, adult_id, adult_name, doc_name) => {

    const token = localStorage.sitterpoint_token;

      if (token) {

        console.log('upload-file handleDocUpload doc_name:', doc_name);
          if (doc_name === 'id') {
            // submit (save) doc to state, to upload later
            dispatch(submitIDDoc(token, file, onUploadProgress, adult_id, adult_name, doc_name));
          } else {
            // upload verify docs
            dispatch(uploadDoc(token, file, onUploadProgress, adult_id, adult_name, doc_name));
          }
      }

  },

  // if doc_name is id_selfie, upload two files
  handleIDDocsUpload: (fileID, fileSelfie, onUploadProgress, adult_id, adult_name, doc_name) => {

    const token = localStorage.sitterpoint_token;

      if (token) {

          if (doc_name === 'id_selfie') {
            // upload ID + selfie if completed id + selfie
            dispatch(uploadIDDocs(
              token, fileID, fileSelfie, onUploadProgress, adult_id, adult_name, doc_name
            ));
          }

      }

  }


});

export default connect(mapStateToProps, mapDispatchToProps)(UploadSelfieModal);
