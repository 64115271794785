import React, { Component } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { ReportGA } from '../../utils/ga';
import { ActionButton } from '../../styles/buttons';
import colours from '../../styles/colours';
import SendInputField from '../general/send-inputField';
import Spinner from '../general/spinner';
import buildThreadDetails from '../../lib/threadHelpers';
import getDefaultMessage from '../../lib/messageHelpers';
import { MESSAGES } from '../../constants/ga-category';
import { ReportError } from '../../utils/check-error';


class MessageBar extends Component {


  componentDidMount () {


    const { action, messagesLength, receiver_name, receiver_id, sitter_id, initializeNewMessage, initializePlaceholder, isInPast, status } = this.props;

    console.log('MessageBar this.props:', this.props);
    const defaultMessage = getDefaultMessage(action, messagesLength, receiver_name, receiver_id, sitter_id, isInPast, status);
    if (!action || action === undefined) {
      // no action so just a message
      console.log('messagesLength:', messagesLength);
      if (messagesLength !== 0) {
        // not first contact
        initializeNewMessage(''); // return blank new message to force placeholder instead
        initializePlaceholder(defaultMessage);
      } else {
        // const defaultMessage = approvePlaceholder || cancelPlaceholder || 'type new message here';
        initializeNewMessage(defaultMessage);
      }
    } else {
      initializeNewMessage(defaultMessage);
    }


  }

  handleMessageInput = (event) => {
    const { handleChange } = this.props;
    console.log('e. handleChange:', handleChange);
    handleChange(event.target.value);
  }

  handleMessageKeyDown = (event) => {
    const { handleChange } = this.props;
    // console.log('handleChange:', handleChange);
    handleChange(event.target.value);
  }

  sendMessageAndCancel = () => {
    const { isInPast, cancelSubmit, handleShowReviewModal, subscription_status, messagesLength, editMode, user_id, sender_id, receiver_id, newMessageContent, thread_details, sp_actual, requester_rating, sitter_rating } = this.props;
    // reset send button and message field to null/false

    const messageText = newMessageContent;
    // const threadDetails = buildThreadDetails(editMode, newMessageThreadParams, thread_details, tariff_details, edited_thread_details);

    const messageParams = {
      sender_id,
      receiver_id,
      thread_id: thread_details.thread_id,
      thread_details,
      message_text: messageText
    };

    console.log('cancelSubmit');

    cancelSubmit(messageParams, editMode, handleShowReviewModal, subscription_status, messagesLength, user_id, isInPast, thread_details, sp_actual, requester_rating, sitter_rating);

    ReportGA({
      category: MESSAGES,
      action: `User clicked SendMessageAndCancel from MessageBar`
    });

  }

  sendMessageAndApprove = (action) => {

    ReportGA({
      category: MESSAGES,
      action: `User clicked SendMessageAndApprove action: ${action} from MessageBar`
    });

    console.log('sendMessageAndApprove action:', action);

    const { isInPast, approveSubmit, reviewSitting, handleShowReviewModal, status, subscription_status, messagesLength, editMode, user_id, sitter_id, requester_id, sender_id, receiver_id, sendNewMessage, newMessageContent, newMessageThreadParams, thread_details, tariff_details, edited_thread_details, sp_actual, requester_rating, sitter_rating } = this.props;
    // reset send button and message field to null/false

    const messageText = newMessageContent;
    const threadDetails = buildThreadDetails(editMode, newMessageThreadParams, thread_details, tariff_details, edited_thread_details);

    const isFirstMessage = (_.isEmpty(thread_details) && !_.isEmpty(newMessageThreadParams));

    const messageParams = {
      sender_id,
      receiver_id,
      thread_id: threadDetails.thread_id,
      thread_details: threadDetails,
      message_text: messageText
    };
    console.log('action:', action);
    console.log('newMessageThreadParams', newMessageThreadParams);
    console.log('thread_details:', thread_details);
    console.log('edited_thread_details:', edited_thread_details);
    console.log('threadDetails:', threadDetails);
    console.log('messageParams:', messageParams);

    if (!action) {
      // centralise bugsnag reporting
      ReportError(null, `missing param 'action'`, {});
    }

    if (action === 'message') {
      // just a message, send and reload Thread
      sendNewMessage(messageParams, threadDetails.thread_id, user_id, isFirstMessage);
      // reset send button and message field to null/false
    } else if (action === 'edit_new') {
      // just a message, send and reload Thread
      sendNewMessage(messageParams, threadDetails.thread_id, user_id, isFirstMessage);
      // reset send button and message field to null/false
    } else if (action === 'pre_authorise' || action === 'updated_pre_authorised' || action === 'edit') {
      console.log('handlePreAuthorise/edit');
      // pre-authorise, then send message
      this.handlePreAuthoriseOrEdit(messageParams, action);
    } else if (action === 'review') {
      console.log('handleShowReviewModal');
      // pre-authorise, then send message
      reviewSitting(threadDetails.thread_id, user_id);
    } else {
      console.log('approveSubmit');
      // approval action followed by send message
      // changed to wait to send message until after confirming/payment

      approveSubmit(messageParams, editMode, handleShowReviewModal, subscription_status, messagesLength, user_id, sitter_id, requester_id, isInPast, status, thread_details, sp_actual, requester_rating, sitter_rating);

      // sendNewMessage(sender_id, receiver_id, threadDetails.thread_id, threadDetails, messageText, null);

    }

  }

  handlePreAuthoriseOrEdit = (messageParams, action) => {
    const { preAuthorise, sendEditedDetails, editMode, thread_details, status, sp_actual, sp_quoted, edited_thread_details, user_id } = this.props;
    // thread_id

    console.log('HANDLE PRE-AUTH thread_details.thread_id:', thread_details.thread_id);
    // console.log('HANDLE PRE-AUTH edited_thread_details:', edited_thread_details);

    // can we put remaining logic into getActionButtonDetails, to show/hide button and combine below into one action on server, as params are the same.

    const { saved_start_date: edit_start_date, saved_end_date: edit_end_date, saved_start_hr: edit_start_hr, saved_start_min: edit_start_min, saved_end_hr: edit_end_hr, saved_end_min: edit_end_min, saved_selected_kids: edit_kids } = edited_thread_details;
    const useEditEndDate = !edit_end_date ? edit_start_date : edit_end_date;
    const start_time = `${edit_start_hr}:${edit_start_min}`;
    const end_time = `${edit_end_hr}:${edit_end_min}`;

    console.log('sp_quoted:', sp_quoted);
    console.log('PRE AUTH sq_actual:', sp_actual);
    console.log('PRE AUTH editMode:', editMode);
    console.log('PRE AUTH action:', action);
    console.log('PRE AUTH status:', status);
    // if (editMode && action === 'edit') {
    //   const spQuote = getTimeAndPoints(tariff_details, saved_start_date, saved_end_date, saved_start_hr, saved_end_hr, saved_start_min, saved_end_min, selected_kids);
    //   // should this be moved to Thread level and shared (once edited)?
    // }

    const editParameters = (!edit_start_date || !useEditEndDate || !edit_start_hr || !edit_start_min || !edit_end_hr || !edit_end_min || !edit_kids) ? null
      : (editMode && (action === 'edit' || action === 'edit_new'))
      ? { start_date: edit_start_date, end_date: useEditEndDate, start_time, end_time, kids: edit_kids, sp_quoted, status: 'request' } // puts status back to request(sitter will need to re-pre-approve)
      : { start_date: edit_start_date, end_date: useEditEndDate, start_time, end_time, kids: edit_kids, sp_actual };

    const threadParameters = (!thread_details.start_date || !thread_details.end_date || !thread_details.start_time || !thread_details.end_time || !thread_details.kids) ? null : { start_date: thread_details.start_date, end_date: thread_details.end_date, start_time: thread_details.start_time, end_time: thread_details.end_time, kids: thread_details.kids, sp_actual };

    // const areEditParamsSame = JSON.stringify(threadParameters) === JSON.stringify(editParameters);

    console.log('PRE AUTH editParameters:', editParameters);
    console.log('PRE AUTH threadParameters:', threadParameters);

    if (editMode && (action === 'updated_pre_authorised' || action === 'pre_authorise') && status !== 'finalised') {
      // approve after editing
      console.log('approve after editing');
      preAuthorise(messageParams, thread_details.thread_id, user_id, editParameters);

    } else if (editMode && (action === 'edit' || action === 'edit_new')) {

      // confirm edited details
      sendEditedDetails(messageParams, thread_details.thread_id, editParameters, user_id);

    } else {
      // approve without editing
      console.log('approve without editing');
      // preAuthoriseDetails(thread_details.thread_id, threadParameters, user_id);
      preAuthorise(messageParams, thread_details.thread_id, user_id, threadParameters);

    }


  }


  render () {

    const { action, approve, cancel, resetActionBar, newMessageContent, isSendingMessage, newMessagePlaceholder } = this.props;


    return (
      <div style={{ marginTop: 0, width: '100%', overflow: 'hidden', justifyContent: 'space-between', alignItems: 'flex-end', flexDirection: 'row', display: 'flex', paddingTop: 0, paddingBottom: 8, borderColor: colours.borderColour, borderWidth: 0, borderStyle: 'solid' }}>

        <div style={{ position: 'absolute', bottom: 0, width: '100%', height: 110, background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0.9), rgba(255,255,255,1))' }} />
        <ActionButton
          style={{ width: 50, height: 50, marginLeft: 8, marginRight: 8, background: colours.offWhite, boxShadow: `0px 2px 4px 0px ${colours.shadow}` }}
          testID='THREAD Back'

          onClick={ resetActionBar }
          onKeyDown={ resetActionBar }
        >

          <FontAwesomeIcon
            icon={faTimes}
            size='lg'
            style={{ color: colours.darkpurple }} />


        </ActionButton>


        <div style={{ background: colours.white, zIndex: 1000, overflow: 'hidden', minHeight: 50, flex: 1, borderRadius: 6, borderColor: colours.borderColour, borderWidth: 1, borderStyle: 'solid', display: 'flex', flexDirection: 'row', boxShadow: `2px 2px 2px ${colours.shadow}` }}>

          <div style={{ width: 8, height: '100%', background: colours.orange }} />
          <div style={{ padding: 4, display: 'flex', flex: 1, borderColor: colours.borderColour, borderWidth: 0, borderStyle: 'solid', flexDirection: 'row' }}>

            <SendInputField
              accessibilityLabel='New Message'
              // style={{  }}
              onChangeText={this.handleMessageInput}
              value={ newMessageContent }
              type='text'
              placeholder={newMessageContent === '' ? newMessagePlaceholder : undefined}

            />


          </div>
        </div>


        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 0, padding: 0, borderColor: 'red', borderWidth: 0, borderStyle: 'solid' }}>
          {
            isSendingMessage
            && <div style={{ justifyContent: 'center', alignItems: 'center' }}><Spinner size={20} /></div>
          }
          {
            !isSendingMessage && !approve && !cancel
            && <ActionButton
              style={{ width: 50, height: 50, marginLeft: 8, marginRight: 8, background: colours.orange, boxShadow: `0px 2px 4px 0px ${colours.shadow}` }}
              testID='THREAD Send'
              disabled={newMessageContent === ''}
              onClick={ () => this.sendMessageAndApprove('message') }
            >
              <FontAwesomeIcon
                icon={faTelegramPlane}
                size='2x'
                style={{ color: colours.white }} />
            </ActionButton>
          }
          {
            !isSendingMessage && approve
            && <ActionButton
              style={{ width: 50, height: 50, marginLeft: 8, marginRight: 8, background: colours.green, boxShadow: `0px 2px 4px 0px ${colours.shadow}` }}
              testID='THREAD Approve'
              disabled={newMessageContent === ''}
              onClick={ () => this.sendMessageAndApprove(action) }
            >
              <FontAwesomeIcon
                icon={faCheck}
                size='2x'
                style={{ color: colours.white }} />
            </ActionButton>
          }
          {
            !isSendingMessage && cancel
            && <ActionButton
              style={{ width: 50, height: 50, marginLeft: 8, marginRight: 8, background: colours.red, boxShadow: `0px 2px 4px 0px ${colours.shadow}` }}
              testID='THREAD ConfirmCancel'
              disabled={newMessageContent === ''}
              onClick={ () => this.sendMessageAndCancel(action) }
            >
              <FontAwesomeIcon
                icon={faCheck}
                size='2x'
                style={{ color: colours.white }} />
            </ActionButton>
          }
        </div>
      </div>


    );

  }

}

export default MessageBar;
