import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Progress } from 'semantic-ui-react';
import AnimatedCrossLarge from '../../components/general/animated-cross-large';

class ModalFailureIndicator extends Component {

render () {

  const { isFetchingSuccess, spinner, progress, successText, fetchingText } = this.props;
  console.log('render failure indicator isFetchingSuccess: ', isFetchingSuccess);
    if (!isFetchingSuccess) {
      return null;
    }
    if (isFetchingSuccess) {
      return (

        <div style={{ paddingBottom: 10, paddingTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderColor: 'blue', borderWidth: 0, borderStyle: 'solid' }}>

          {
            <AnimatedCrossLarge />
          }

        </div>

      )
    }

  }

}


const mapStateToProps = ({ user, upload }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  isFetchingSuccess: user.isFetchingSuccess,
  successText: ownProps.successText,
  fetchingText: ownProps.fetchingText

});

const mapDispatchToProps = (dispatch, props) => ({



});

export default connect(mapStateToProps, mapDispatchToProps)(ModalFailureIndicator);
