import { connect } from 'react-redux';
import VerifySelfieUploadButton from '../../components/verify/verify-selfie-upload-button';
import { hideModal } from '../../actions/modal';
import { setUploadProgress, setCurrentFile, setSelectedFiles, setBlob } from '../../actions/general/upload';
import { setCameraAspectRatio } from '../../actions/layout';

// import { MODAL_TYPE_SIGNIN, MODAL_TYPE_SIGNUP, MODAL_TYPE_FORGOTPASSWORD } from '../../components/modals/modalTypes';


const mapStateToProps = ({ user, network, register, verify, upload, layout }, ownProps) => ({
  isSubmittingForm: user.isSubmittingForm,
  isConnected: network.isConnected,
  serverError: user.error,
  progressStep: register.ProgressStep,
  noOfChildren: register.noOfChildren,
  child_details: register.child_details,
  userNoOfChildren: user.kids && user.kids.length,
  adults: user.adults,
  kids: user.kids,
  homeAddress: user.home_address,
  photo_url: user.photo_url,
  selectedDocFile: verify.selectedDocFile,
  isUploadingDoc: user.isFetchingUpload,
  isFetchingSuccess: user.isFetchingSuccess,
  docStatus: ownProps.docStatus,
  doc_name: ownProps.doc_name,
  isFetchingUpload: user.isFetchingUpload,
  progress: upload.progress,
  currentFile: upload.currentFile,
  selectedFiles: upload.selectedFiles,
  blob: upload.blob,
  isVerify: ownProps.isVerify,
  cameraAspectRatio: layout.cameraAspectRatio
});

const mapDispatchToProps = (dispatch, props) => ({


  setSelectedFiles: (value) => {
    dispatch(setSelectedFiles(value));
    // if verify, don't hideModal, show preview on modal instead.
    console.log('isVerify:', props.isVerify);
    if (!props.isVerify) {
      dispatch(hideModal()); // for profile photo only - preview is on page
    }

  },
  setCurrentFile: (value) => {

    dispatch(setCurrentFile(value));
  },
  setProgress: (value) => {
    dispatch(setUploadProgress(value));
  },
  setBlob: (value) => {
    dispatch(setBlob(value));
  },
  setCameraAspectRatio: (value) => {

    dispatch(setCameraAspectRatio(value));
  }


  // handleDocUpload: (source, onUploadProgress, nextRoute, adult_id, adult_name, doc_name) => {
  //
  //   const token = localStorage.sitterpoint_token;
  //   // const user_id = localStorage.sitterpoint_user_id;
  //
  //     if (token) {
  //
  //       dispatch(uploadDoc(token, source, onUploadProgress, nextRoute, adult_id, adult_name, doc_name));
  //       // dispatch(uploadPhoto(token, source, onUploadProgress, nextRoute));
  //
  //
  //       // dispatch(uploadDoc(token, formData));
  //     }
  //
  // }

});

export default connect(mapStateToProps, mapDispatchToProps)(VerifySelfieUploadButton);
