import React from 'react';
import {
    Dropdown
} from "semantic-ui-react";
import { faCalendar, faInbox, faInfoCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import * as ROUTES from '../../constants/routes';
// import * as ROLES from '../../constants/roles';
import { Row } from '../../styles/styles';
import { A5 } from '../../styles/text';
import { DropdownItem } from '../../styles/buttons';
import colours from '../../styles/colours';
import DropdownMenu from '../layout/dropdowns/menu-profile';
import CalendarButtonContainer from '../../containers/calendar/calendar-button';
import MessagesInboxButtonContainer from '../../containers/messages/messages-inbox-button';
import DropdownPoints from '../layout/dropdowns/points';
import DropdownHelp from '../layout/dropdowns/help';
//  default styles - edit in CSS to preserve hover:
import '../../styles/navigation.css';
import InfoIcon from '../../images/info_icon.min.svg';
import CalIcon from '../../images/icalendar_icon2.min.svg';
import InboxIcon from '../../images/imessage_icon.min.svg';
import * as BONUSES from '../../constants/bonuses';
import { nav } from '../../text';

const { nav_menus } = nav;


const NavMenu = ({ width, orientation, user_id, user_is_admin, transactions, photo_url, profile_progress, profile_complete_count, verified_count, email_verified, handleLogOut, outsideDropdownNavClick, dropdown_nav_is_open, handleDropdownNavClick, sp_balance, sp_pending }) => {
  console.log('orientation:', orientation);
  const burger = (orientation === 'burger');


  console.log('navmenu user_id:', user_id);
  console.log('navmenu:', transactions);

  console.log('navmenu verified_count:', verified_count);


  // if (Array.isArray(transactions)) {
  //
  // }


  return (
    <div style={{ flex: burger ? null : 1.5, marginLeft: burger ? null : 'auto', height: 60, display: 'flex', flexDirection: 'row', borderWidth: 0, borderStyle: 'solid', borderColor: 'green' }}>


      <div style={{ flex: 1, borderWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: colours.lightgray, justifyContent: 'center', alignItems: 'center' }}>

        <DropdownHelp
          width={width}
          value='Help'
          icon={faInfoCircle}
          image={InfoIcon}
          iconSize='2x'
          dataType='menu'
          outsideDropdownNavClick={() => outsideDropdownNavClick()}
          dropdown_nav_is_open={dropdown_nav_is_open}
          handleDropdownNavClick={handleDropdownNavClick}
          burger={burger}
        />

      </div>

      <div style={{ flex: 1, borderWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: colours.lightgray, justifyContent: 'center', alignItems: 'center' }}>
        {
          // <DropdownMessagesContainer
          //   width={width}
          //   value='Messages'
          //   icon={faComments}
          //   iconSize='2x'
          //   dataType='menu'
          //   burger={burger}
          // />
        }
        {
          <MessagesInboxButtonContainer
            width={width}
            value='Inbox'
            icon={faInbox}
            image={InboxIcon}
            iconSize='2x'
            dataType='menu'
            burger={burger}
          />
        }
      </div>
      <div style={{ flex: 1, borderWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: colours.lightgray, justifyContent: 'center', alignItems: 'center' }}>
        {
          // <DropdownMessagesContainer
          //   width={width}
          //   value='Messages'
          //   icon={faComments}
          //   iconSize='2x'
          //   dataType='menu'
          //   burger={burger}
          // />
        }
        {
          <CalendarButtonContainer
            width={width}
            value='Calendar'
            icon={faCalendar}
            image={CalIcon}
            iconSize='2x'
            dataType='menu'
            burger={burger}
          />
        }
      </div>
      <div style={{ flex: 1, borderWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: colours.lightgray, justifyContent: 'center', alignItems: 'center' }}>
        <DropdownPoints
          width={width}
          value={sp_balance}
          // image={SPIcon}
          dataType='menu'
          options={[
            ...(!profile_complete_count || profile_complete_count === 0) ? [{ text: `Complete your profile`, points: `+ ${BONUSES.PROFILE_COMPLETE}`, unit: 'SP', route: ROUTES.PROFILE_SUMMARY }] : [],
            // ...(!verified_count || verified_count === 0) ? [{ text: 'Become a verified member', points: `+ ${BONUSES.VERIFY_USER}`, unit: 'SP', route: ROUTES.VERIFY }] : [],
            // { text: 'Sit 5 times', points: `+ ${300}`, unit: 'SP' },
            { text: 'Refer a friend', up_to: true, points: `+ ${BONUSES.MAX_SIGNUP_BONUS}`, unit: 'SP', route: ROUTES.REFER },
            // { text: 'Become a gold member', points: `+ ${BONUSES.GOLD_MEMBER_MONTH}`, unit: 'SP', route: ROUTES.PLAN },
            { text: `You have ${sp_balance} SittingPoints ${(sp_pending !== 0) ? ` (${sp_pending} pending)` : ''}`, points: 'View all', route: ROUTES.POINTS_HISTORY }
          ]}
          outsideDropdownNavClick={() => outsideDropdownNavClick()}
          dropdown_nav_is_open={dropdown_nav_is_open}
          burger={burger}
        />
      </div>
      <div style={{ flex: 1, borderWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: colours.lightgray, justifyContent: 'center', alignItems: 'center' }}>
        <DropdownMenu
          width={width}
          value='Profile'
          icon={faUserCircle}
          photo_url={photo_url}
          iconSize='2x'
          dataType='menu'
          dropdown_nav_is_open={dropdown_nav_is_open}
          handleDropdownNavClick={handleDropdownNavClick}
          options={[
            { route: ROUTES.PROFILE_SUMMARY, editMode: true, value: nav_menus.profile.family_profile, blurb: !email_verified ? nav_menus.profile.verify_your_email : (profile_progress !== 100) ? `${nav_menus.profile.complete_your_profile}${profile_complete_count === 0 ? ` ${nav_menus.profile.and_earn} ${BONUSES.PROFILE_COMPLETE} ${nav_menus.profile.SittingPoints}!` : ''}` : '', progress: profile_progress },
            { route: ROUTES.AVAILABILITY, value: 'My calendar' },
            // { route: ROUTES.ACCOUNT_HISTORY, value: 'My account history' },
            { route: ROUTES.PLAN, value: 'My account' },
            ...user_is_admin ? [{ route: ROUTES.ADMIN_VERIFICATION, value: 'Admin' }] : [],

            // { route: ROUTES.DASHBOARD, value: 'My settings' },

            <Dropdown.Divider />,
            <DropdownItem
              onClick={handleLogOut}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleLogOut();
                }
              }}
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <Row>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingRight: 4, alignSelf: 'flex-end', height: '100%', background: 'transparent' }}>
                  <A5 style={{ textAlign: 'left', background: 'transparent' }}>Sign out</A5>
                </div>
              </Row>
            </DropdownItem>

          ]}
          burger={burger}
        />
      </div>
    </div>
  );
};

export default NavMenu;
