import { showModal, hideModal } from '../../actions/modal';
import { actionSuccess } from '../../actions/verify';
import { forgot_password } from '../../text';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS } from '../../components/modals/modalTypes';
import { SUCCESS_RESET_PASSWORD } from '../../components/modals/modalNames';
import { SIGNIN } from '../../components/modals/modalCategory';
import { CheckError, ReportError } from '../../utils/check-error';


export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE';
export const CONFIRM_EMAIL_RESET = 'CONFIRM_EMAIL_RESET';

export const confirmEmailRequest = () => ({
  type: CONFIRM_EMAIL_REQUEST
});

export const confirmEmailReset = () => ({
  type: CONFIRM_EMAIL_RESET
});

export const confirmEmailSuccess = data => ({
  type: CONFIRM_EMAIL_SUCCESS,
  data
});

export const confirmEmailFailure = error => ({
  type: CONFIRM_EMAIL_FAILURE,
  error
});

export function confirmEmail (email) {
  console.log('email', email);
  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: forgot_password.modal.fetching
      }
    ));

    dispatch(confirmEmailRequest());
    fetch(`${process.env.REACT_APP_API_URI}/reset-password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
    .then(CheckError)
    .then((data) => {
      if (data.error) {
        dispatch(confirmEmailFailure(data.error));

        // centralise bugsnag reporting
        ReportError(!data.error.code ? null : data.error.code, data.error.message, {});
        dispatch(showModal(
          MODAL_TYPE_ERROR,
          { // modalProps
            title: forgot_password.modal.failure.title,
            header: forgot_password.modal.failure.header,
            message: forgot_password.modal.failure.para,
            // message2: signupText.modal.failure.para2,
            // error_status: err.code && err.code,
            error_message: data.error.message
          }
        ));

        dispatch(actionSuccess());


      } else {
        console.log('data', data);

        dispatch(confirmEmailSuccess(data));

        const modalText = forgot_password.modal.success;

        const newModalText = { ...modalText, para: `${modalText.para} ${email} ${modalText.para2}`, para2: null };

        dispatch(showModal(

          MODAL_TYPE_SUCCESS,
          { // modalProps
            // other_user_name?
            modalCategory: SIGNIN,
            modalName: SUCCESS_RESET_PASSWORD,
            modalText: newModalText,
            okAction: () => dispatch(hideModal()),
            backAction: null

          }
        ));
        dispatch(actionSuccess()); // triggers success icon


      }
    })
    .catch((err) => {

      console.log('caught reset password err.message: ', err.message);

      dispatch(confirmEmailFailure(err.message));

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: forgot_password.modal.failure.title,
          header: forgot_password.modal.failure.header,
          message: forgot_password.modal.failure.para,
          // message2: signupText.modal.failure.para2,
          error_status: err.code && err.code,
          error_message: err.message
        }
      ));

      dispatch(actionSuccess());


    });


  };
}
