import { connect } from 'react-redux';
import Footer from '../../components/general/footer';


const mapStateToProps = ({ layout, user }, ownProps) => ({
  // serverError: user.error,
  // isConnected: network.isConnected,
  window_width: layout.window_width,
  small: ownProps.small,
  background: ownProps.background,
  user_id: user.user_id,
  firstname: user.firstname

});

const mapDispatchToProps = (dispatch, props) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
