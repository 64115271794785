import Bugsnag from '@bugsnag/js';
import { login as loginText, modal } from '../text';
import { showModal, hideModal } from '../actions/modal';
import { MODAL_TYPE_ERROR } from '../components/modals/modalTypes';


export const ReportError = (code, message, metadata) => {
  console.log('CheckError reporting error: ', `${code}, ${message}, ${metadata}`);

  if (Bugsnag) {
    Bugsnag.notify(
      new Error(`status: ${code}, message: ${message}`),
      {
        metaData: {
          custom_metadata: JSON.stringify(metadata)
        }
      }
    );
  }


};


export function CustomException (code, message, metadata, dispatch) {

  console.log('CheckError CustomException code:', code);
  console.log('CheckError CustomException message:', message);

  ReportError(code, message, metadata);

  const error = new Error(message);

  console.log('CheckError CustomException error:', error);


  if (code) {
    error.code = code;
  }
  if (message === 'Failed to fetch') {
    // offline / no connection
    error.code = null;

    dispatch(showModal(
      MODAL_TYPE_ERROR,
      { // modalProps
        message2: modal.error.offline,
        error_status: code && code,
        error_message: message
      }
    ));

  }

  if (metadata) {
    error.metadata = metadata;
  }

  return error;
}

export const CheckError = (res) => {
  console.log('CheckError res:', res);
  console.log('CheckError res.status:', res.status);

  if (res.ok) {

    return res.json();
  }
  // if axios response, then look for 201 as no ok
  if (res.status === 201) {

    return res;
  }
  if (res.status === 401) {
    throw new CustomException(res.status, loginText.invalid);
  }
  // else
  return res.json().then((data) => {
    console.log('CheckError data: ', data);
    console.log('CheckError res.status: ', res.status);
    console.log('CheckError data.message: ', data.message);
    const status = res.status;

    throw new CustomException(status, data.message);
  });
};

export default CheckError;
