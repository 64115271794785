import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import modal from './modal';
import user from './user';
import nav from './nav';
import network from './network';
import register from './register';
import verify from './verify';
import upload from './upload';
import dashboard from './dashboard';
import messageThread from './messages';
import review from './review';
import inbox from './inbox';
import transactions from './transactions';
import calendar from './calendar';
import search from './search-date';
import layout from './layout';
import editSearch from './edit-search';
import tariff from './tariff';
import payments from './payments';
// import dropdownDateValue from './dropdown-dateValue';
import dropdownHourValue from './dropdown-hourValue';
import admin from './admin';
import style from './style';
import forgotPassword from './forgot-password';
import verificationEmail from './verify-email';


// const messageThreadPersistConfig = {
//   key: 'messageThread',
//   storage,
//   whitelist: ['messageThread']
// };  can nest white/blacklisting if needed, see docs

// nested whitelist on search
// const searchPersistConfig = {
//   key: 'search',
//   storage: storage,
//   whitelist: ['submittedSearch']
// };


export const appReducer = combineReducers({

  modal,
  user,
  network,
  form,
  nav,
  layout,
  upload,
  register,
  verify,
  dashboard,
  inbox,
  transactions,
  messageThread,
  review,
  calendar,
  search, // : persistReducer(searchPersistConfig, search),
  editSearch,
  tariff,
  payments,
  dropdownHourValue,
  admin,
  style,
  forgotPassword,
  verificationEmail

});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {

    // as per https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');
    // storage.removeItem('persist:otherKey')

    return appReducer(undefined, action);

    // state = { // eslint-disable-line no-param-reassign
    //   ...state,
    //   user: undefined,
    //   confirmUserEmail: undefined
    // };
  }

  return appReducer(state, action);
};

export default rootReducer;
