import React from 'react';
import colours from '../../styles/colours';
import { navBarHeight, infoBarHeight, loggedOutNavHeight } from '../../styles/layout';

const PageWrapper = ({ show_navbar, logged_in, window_width, window_height, children, alignItems }) => {


    const pagewrapperHeight = !window_height
      ? '100%'
      : (window_height - (show_navbar
        ? logged_in
          ? (infoBarHeight + navBarHeight)
          : (window_width > 550
            ? 0
            : 0)
        : 0));
    // console.log('verify pagewrapperHeight:', pagewrapperHeight);

    return (
      <div style={{
        display: 'flex',
        flex: 1,
        height: pagewrapperHeight,
        flexDirection: 'column',
        backgroundColor: colours.white,
        borderWidth: 0,
        borderColor: 'orange',
        borderStyle: 'solid',
        justifyContent: 'center',
        alignItems: alignItems || 'center',
        paddingBottom: 0
      }}>
        { children }
      </div>
    );
};

export default PageWrapper;
