
// import initSocket from '../socket-router';
// require('dotenv').config();

export const SET_NO_OF_ADULTS = 'SET_NO_OF_ADULTS';
export const CREATE_ADULT = 'CREATE_ADULT';
export const SELECT_ADULT = 'SELECT_ADULT';
export const SET_ADULT_TO_ADD = 'SET_ADULT_TO_ADD';

export function setNoOfAdults (data) {
  return {
    type: SET_NO_OF_ADULTS,
    data
  };
}

export function createAdult (data) {
  return {
    type: CREATE_ADULT,
    data
  };
}


export function selectAdult (data) {
  return {
    type: SELECT_ADULT,
    data
  };
}


export function setAdultToAdd (data) {
  return {
    type: SET_ADULT_TO_ADD,
    data
  };
}
