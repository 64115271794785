import React, { Component } from 'react';
import { connect } from 'react-redux';
import { default_theme } from './default-theme';


export const fonts = {
  default: 'Lato, Roboto, Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif',
  default2: 'Open Sans, Arial, Helvetica, sans-serif',
  default3: 'DK Bergelmir, Lato, Open Sans, Arial, Helvetica, sans-serif',
  thin: 300,
  normal: 400,
  semibold: 600,
  bold: 700
};

export const padding = {
  s: [0, 0, 0, 0],
  m: [4, 4, 4, 4],
  l: [8, 8, 8, 8],
  vl: [16, 16, 16, 16]
};


const mapStateToProps = ({ style }) => {
    const { used_theme_id, all_themes } = style;

    const used_theme = !all_themes ? {} : all_themes.reduce((acc, item) => {
      if (used_theme_id === item.theme_id) {
        return item;
      }
      return acc;
    }, []);

    return used_theme;

};

// paragraph, labels
class ThemeA extends Component {

  render () {

    console.log('used_theme', this.props.used_theme);
    const { used_theme } = this.props;

    return (
      used_theme
    );

  }
}


export const Theme = connect(
    mapStateToProps
)(ThemeA);

// export const default_theme = {
//   breakpoints: ["319px", "599px", "900px"],
//   otherStyles: {
//     bullet: {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       tag: 'div',
//
//       lineHeight: ['30px', '30px', '30px'],
//       width: ['30px', '30px', '30px'],
//       height: ['30px', '30px', '30px'],
//
//       borderWidth: '0px',
//       borderStyle: 'solid',
//       borderRadius: '50%'
//
//     }
//   },
//   layoutStyles: {
//     main: {
//       paddingLeft: ['16px', '16px', '16px'],
//       paddingRight: ['16px', '16px', '16px']
//     },
//     s: {
//       paddingLeft: ['0px', '0px', '0px'],
//       paddingRight: ['0px', '0px', '0px']
//     },
//     m: {
//       paddingLeft: ['4px', '4px', '4px'],
//       paddingRight: ['4px', '4px', '4px']
//     },
//     l: {
//       paddingLeft: ['0px', '0px', '0px'],
//       paddingRight: ['0px', '0px', '0px']
//     },
//     vl: {
//       paddingLeft: ['16px', '16px', '16px'],
//       paddingRight: ['16px', '16px', '16px']
//     }
//   },
//   textStyles: {
//     a1: {
//       tag: 'h1',
//       fontSize: ['26px', '26px', '32px'],
//       fontWeight: fonts.normal,
//       // lineHeight: ["56px", "56px", "72px"],
//       fontFamily: fonts.default,
//       color: colours.gray,
//       textAlign: 'left',
//       paddingTop: ['8px', '8px', '8px'],
//       paddingBottom: ['8px', '8px', '8px']
//     },
//     a2: {
//       tag: 'h2',
//       fontSize: ['20px', '25px', '25px'],
//       fontWeight: fonts.normal,
//       // lineHeight: ["32px", "36px", "56px"],
//       fontFamily: fonts.default,
//       color: colours.gray,
//       textAlign: 'left',
//       paddingTop: ['4px', '4px', '4px'],
//       paddingBottom: ['8px', '8px', '8px']
//     },
//     a3: {
//       tag: 'h3',
//       fontSize: ['19px', '19px', '19px'],
//       fontWeight: fonts.normal,
//       // lineHeight: ["32px", "36px", "56px"],
//       fontFamily: fonts.default,
//       color: colours.gray,
//       textAlign: 'left',
//       paddingTop: ['4px', '4px', '4px'],
//       paddingBottom: ['4px', '4px', '4px']
//     },
//     a4: {
//       tag: 'p',
//       fontSize: ['16px', '16px', '16px'],
//       fontWeight: fonts.normal,
//       // lineHeight: ["32px", "36px", "56px"],
//       fontFamily: fonts.default,
//       color: colours.gray,
//       textAlign: 'left',
//       paddingTop: ['4px', '4px', '4px'],
//       paddingBottom: ['4px', '4px', '4px']
//     },
//     a5: {
//       tag: 'p',
//       fontSize: ['14px', '14px', '14px'],
//       fontWeight: fonts.normal,
//       // lineHeight: ["32px", "18px", "56px"],
//       fontFamily: fonts.default,
//       color: colours.gray,
//       textAlign: 'left',
//       paddingTop: ['4px', '4px', '4px'],
//       paddingBottom: ['4px', '4px', '4px']
//     },
//     a6: {
//       tag: 'p',
//       fontSize: ['12px', '12px', '12px'],
//       fontWeight: fonts.normal,
//       // lineHeight: ["32px", "36px", "56px"],
//       fontFamily: fonts.default,
//       color: colours.gray,
//       textAlign: 'left',
//       paddingTop: ['4px', '4px', '4px'],
//       paddingBottom: ['4px', '4px', '4px']
//     },
//     l4: {
//       tag: 'a',
//       fontSize: ['16px', '16px', '16px'],
//       fontWeight: fonts.semibold,
//       // lineHeight: ["32px", "36px", "56px"],
//       fontFamily: fonts.default,
//       color: colours.gray,
//       textAlign: 'left',
//       paddingTop: ['4px', '4px', '4px'],
//       paddingBottom: ['4px', '4px', '4px']
//     },
//     input: {
//       tag: 'input',
//       fontFamily: fonts.default,
//       textAlign: 'left',
//       marginLeft: ['0px', '0px', '0px'],
//       marginRight: ['1px', '1px', '1px'],
//       paddingLeft: ['4px', '4px', '4px'],
//       paddingRight: ['4px', '4px', '4px'],
//       background: colours.white,
//       fontSize: ['14px', '14px', '14px'],
//       fontWeight: fonts.normal,
//       borderWidth: 0,
//       borderColor: colours.orange,
//       color: colours.black,
//       borderStyle: 'solid',
//       borderRadius: 0,
//       height: 25,
//       display: 'flex',
//       flex: 1,
//       outline: 'none'
//     },
//     textarea: {
//       tag: 'textarea',
//       fontFamily: fonts.default,
//       textAlign: 'left',
//       marginLeft: ['0px', '0px', '0px'],
//       marginRight: ['1px', '1px', '1px'],
//       paddingLeft: ['4px', '4px', '4px'],
//       paddingRight: ['4px', '4px', '4px'],
//       background: colours.white,
//       fontSize: ['14px', '14px', '14px'],
//       fontWeight: fonts.normal,
//       borderWidth: 0,
//       borderColor: colours.orange,
//       color: colours.black,
//       borderStyle: 'solid',
//       borderRadius: 0,
//       height: 25,
//       display: 'flex',
//       flex: 1,
//       outline: 'none'
//     },
//     bullet4: {
//       display: 'inline-block',
//       tag: 'div',
//       fontFamily: fonts.default,
//       fontSize: ['16px', '16px', '16px'],
//       fontWeight: fonts.semibold,
//       lineHeight: ['30px', '30px', '30px'],
//       borderRadius: '50%',
//       width: 60,
//       height: ['30px', '30px', '30px'],
//       minWidth: ['30px', '30px', '30px'],
//       minHeight: ['30px', '30px', '30px'],
//       // lineHeight: ["32px", "36px", "56px"],
//       color: colours.gray,
//       borderWidth: '1px',
//       borderStyle: 'solid'
//
//
//     },
//     bullet5: {
//       tag: 'p',
//       fontSize: ['14px', '14px', '14px'],
//       fontWeight: fonts.normal,
//       // lineHeight: ["32px", "18px", "56px"],
//       fontFamily: fonts.default,
//       color: colours.gray,
//
//       paddingTop: ['4px', '4px', '4px'],
//       paddingBottom: ['4px', '4px', '4px'],
//
//       lineHeight: ['30px', '30px', '30px'],
//       verticalAlign: 'middle',
//
//       borderRadius: '50%',
//
//       borderWidth: '1px',
//       borderStyle: 'solid',
//       width: '30px',
//       height: '30px',
//       marginRight: '10px',
//       minWidth: '30px',
//       minHeight: '30px',
//       textAlign: 'center'
//     }
//
//   }
//
// };


export default Theme;

// export const a1 = '20px';
// export const a2 = '18px';
// export const a3 = '14px';
// export const a4 = '12px';
// export const a5 = '10px';
// export const a6 = '9px';

// export const a1 = '35px';
// export const a2 = '18px';
// export const a3 = '15px';
// export const a4 = '14px';
// export const a5 = '12px';
// export const a6 = '11px';
