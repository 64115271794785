/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import SimpleBar from 'simplebar-react';
import '../../../styles/scrollbars/simplebar.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
// import '@trendmicro/react-buttons/dist/react-buttons.css';
// import '@trendmicro/react-dropdown/dist/react-dropdown.css';
// import 'react-dropdown/style.css';
// import 'react-select/dist/react-select.css';
import { Column, Row } from '../../../styles/styles';
import { FormValueText } from '../../../styles/text';
import colours from '../../../styles/colours';
import { calendar } from '../../../text';
import { Container } from './common';

const { recurrance_options } = calendar.edit_event;

export default class DropdownRecurValue extends Component {

  state = {
    open: false
  }


  handleClick = () => {

      // if click is inside, do something
      const { open } = this.state;
      this.setState({ open: !open });

  }

  handleOutsideClick = () => {

      // if click is inside, do something
      this.setState({ open: false });

  }

  handleSelect = (currentValue) => {
    // console.log('handleSelect currentValue', currentValue);
    // console.log('handleSelect inputKey', inputKey);
    // console.log('handleSelect dataType', dataType);

    this.setState({ open: false });
     const { saveSelectedRecurrance } = this.props;
      // console.log('handleSelect this.props', this.props);
      saveSelectedRecurrance(currentValue);

  }


  render () {

    const { selected_recurrance, viewOnly } = this.props;

    const menuOptions = () => {
      // recurrance in number of weeks
      return [
        { text: `${recurrance_options.one_off}`, value: 1 },
        { text: `2 ${recurrance_options.weeks}`, value: 2 },
        { text: `4 ${recurrance_options.weeks}`, value: 4 },
        { text: `6 ${recurrance_options.weeks}`, value: 6 }
        // removed other options pending dB solution
        // { text: `12 ${recurrance_options.weeks}`, value: 12 }
        // { text: `2 ${recurrance_options.months}`, value: 8 },
        // { text: `3 ${recurrance_options.months}`, value: 12 },
        // { text: `6 ${recurrance_options.months}`, value: 26 }
      ];

    };

    const options = menuOptions();

    const { open } = this.state;


    // console.log('options', options);
    // console.log('render this.props', this.props);

    const dropdownValue = selected_recurrance;
    // console.log('dropdownValue: ', dropdownValue);
    const dropdownText = options.reduce((acc, item) => {
      // console.log('acc: ', acc);
      // console.log('item: ', item);
      if (item.value === dropdownValue) {
        return item.text;

      }
      return acc;


    }, options[0].text);
    // console.log('dropdownText: ', dropdownText);

    // const defaultOption = options[this.props.value - 1];

    const menuItems = options.map((option, i) => {

        const currentValue = option.value;
        // console.log('option', option);

        return (

          <button
            type='button'
            key={`myMenuKey ${option.value}`}
            eventkey={currentValue}
            index={i}
            onClick={() => this.handleSelect(currentValue)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.handleSelect(currentValue);
              }
            }}
            style={{
              width: '100%',
              height: 30,
              padding: 2,
              borderWidth: 0,
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderBottomWidth: 1,
              borderColor: colours.lightgray,
              borderStyle: 'solid',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              background: colours.white,
              alignItems: 'center',
              justifyContent: 'center'
            }}


          >

            <div style={{ flex: 1, height: '100%', alignItems: 'center', justifyContent: 'center' }}>
              {
                option.value
                && <FormValueText>{option.text}</FormValueText>
              }
              {
                !option.value
                && option
              }
            </div>


          </button>

        );
    });

    return (

      <div
        style={{
          display: 'flex',
          flex: 1,
          padding: 0,
          position: 'relative',
          cursor: 'pointer',
          borderTopWidth: 0.5,
          borderBottomWidth: 0.5,
          borderLeftWidth: 0.5,
          borderRightWidth: 0.5,
          borderColor: colours.darkpurple,
          borderStyle: 'solid',
          borderRadius: 0,
          background: colours.white
        }}
      >
        <button

          type='button'
          onClick={this.handleClick}
          onKeyDown={this.handleClick}
          style={{

            minWidth: 40,
            padding: 0,
            margin: 0,
            border: 'none',
            borderRadius: 0,
            textAlign: 'center',
            position: 'relative',
            cursor: 'pointer',
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            backgroundColor: colours.white,
            alignItems: 'center',
            justifyContent: 'center',
            textDecoration: 'none'
          }}

        >

          <Row style={{ height: 24, flex: 1, alignItems: 'center' }}>

            <div style={{ width: '0%', height: '100%' }} />

            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', paddingLeft: 2, paddingRight: 2 }}>
              <FormValueText>{dropdownText}</FormValueText>
            </div>

            <Column style={{ background: colours.white }}>
              <div style={{ width: 30, height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                {!viewOnly
                  && <FontAwesomeIcon
                    icon={faCaretDown}
                    size='sm'
                    style={{ height: '100%', color: colours.caretColor }} />
                }
              </div>
            </Column>

          </Row>

        </button>
        {(!viewOnly
          && open
          && <Container>
            <div
              role='presentation'
              onClick={this.handleOutsideClick}
              onKeyDown={this.handleOutsideClick}
              style={{
                position: 'fixed',
                opacity: 0.2,
                background: colours.lightgray,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -1,
                overflow: 'hidden'
            }} />

            <SimpleBar
              forceVisible='y'
              autoHide={false}
              style={{
                height: 120,
                maxHeight: 120,
                boxShadow: `1px 1px 1px ${colours.shadow}`,
                borderColor: 'blue', borderWidth: 0, borderStyle: 'solid'
            }}>

              {menuItems}
            </SimpleBar>

          </Container>
        )}

      </div>

    );
  }
}
