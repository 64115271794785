import React from 'react';
import { A5 } from '../../styles/text';
import { Row } from '../../styles/styles';
import colours from '../../styles/colours';



const SearchItem = ({ children }) => {


    return (

      <div
        style={{ display: 'flex', flex: 4, position: 'relative' }}
      >
        <div
          style={{
            minWidth: 40,
            textAlign: 'center',
            position: 'relative',
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            borderColor: colours.orange,
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: 8,
            backgroundColor: colours.white,
            alignItems: 'center',
            justifyContent: 'center',
            textDecoration: 'none'
          }}
        >
          <Row style={{ height: 32,
            minWidth: 0, // needed to ensure ellipsis is applied and text tag doesn't break out of flex container
            flex: 1, alignItems: 'center' }}>
            <div style={{ width: '0%', height: '100%' }} />
            <div style={{ flex: 1,
              minWidth: 0, // needed to ensure ellipsis is applied and text tag doesn't break out of flex container
              display: 'flex', justifyContent: 'center', paddingLeft: 2, paddingRight: 2 }}>
              <A5 whiteSpace='nowrap' textOverflow='ellipsis' textAlign='center'>{children}</A5>
            </div>
          </Row>
        </div>
      </div>

    );


};

export default SearchItem;
