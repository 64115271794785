import React from 'react';
// import colours from '../../styles/colours';
// import { H5 } from '../../styles/text';
import ExpandIcon from '../../images/search_expand_icon_purple2.svg';
import Image from './image';

const ExpandButton = ({ collapsed, onClick }) => {

    return (

      <button
        type='button'
        title='expand search'
        onClick={ onClick }
        onKeyDown={ onClick }
        style={{

          zIndex: 3000,
          textDecoration: 'none',
          outline: 'none',
          borderStyle: 'none',
          borderBottomLeftRadius: 0,
          background: 'transparent',
          right: 0, padding: 0, paddingLeft: 0, paddingRight: 8, paddingTop: 0 }}
      >
        {
          !collapsed &&
            <Image noDelay width={18} height={18} src={ExpandIcon} />

        }
        {
          // <FontAwesomeIcon
          // icon={faTimes}
          // size='lg'
          // style={{ color: colours.white }} />
        }
        {
          // collapsed &&
          // <div style={{
          //
          //
          //   padding: 4
          // }}>
          //   <Image noDelay width={18} height={18} src={SearchIcon} />
          // </div>
        }
        {
          // collapsed &&
          // <div style={{
          //   width: 100,
          //   padding: 4
          // }}>
          //   <H5 color={colours.darkpurple}>Edit Search...</H5>
          // </div>

          // <Image noDelay width={18} height={18} src={CollapseIcon} />

        }


      </button>


    );
};

export default ExpandButton;
