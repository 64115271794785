// import { store } from '../../init-store';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';


export const GET_PAYMENT_METHOD_REQUEST = 'GET_PAYMENT_METHOD_REQUEST';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_FAILURE = 'GET_PAYMENT_METHOD_FAILURE';


export const getPaymentMethodRequest = () => ({
  type: GET_PAYMENT_METHOD_REQUEST
});

export const getPaymentMethodSuccess = data => ({
  type: GET_PAYMENT_METHOD_SUCCESS,
  data
});

export const getPaymentMethodFailure = error => ({
  type: GET_PAYMENT_METHOD_FAILURE,
  error
});


export function getDefaultPaymentMethod (token, customer_id) {
  return (dispatch) => {

    dispatch(getPaymentMethodRequest());
    console.log('getPaymentMethod action customer_id: ', customer_id);

    fetch(`${process.env.REACT_APP_API_URI}/payment_method/${customer_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    })
    .then(CheckError)
    .then((data) => {
      console.log('getPaymentMethod response data:', data);

      dispatch(getPaymentMethodSuccess(data));

    })
    .catch((err) => {
      console.log('caught getPaymentMethod error: ', err);
      dispatch(getPaymentMethodFailure(err.message));
    });

  };
}
