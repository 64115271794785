
// import initSocket from '../socket-router';
import http from '../http-common';
import { setUploadProgress, setCurrentFile } from '../general/upload';
import history from '../../components/Navigation/history';
import { MODAL_TYPE_ERROR } from '../../components/modals/modalTypes';
import { showModal, hideModal } from '../modal';
import { actionSuccess } from '../verify';
import { upload_photo } from '../../text/register';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';


export const SET_SELECTED_PHOTO_FILE = 'SET_SELECTED_PHOTO_FILE';
export const UPLOAD_PHOTO_REQUEST = 'UPLOAD_PHOTO_REQUEST';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAILURE = 'UPLOAD_PHOTO_FAILURE';


export function setSelectedPhotoFile (data) {
  return {
    type: SET_SELECTED_PHOTO_FILE,
    data
  };
}

export const uploadPhotoRequest = () => ({
  type: UPLOAD_PHOTO_REQUEST
});

export const uploadPhotoSuccess = data => ({
  type: UPLOAD_PHOTO_SUCCESS,
  data
});

export const uploadPhotoFailure = error => ({
  type: UPLOAD_PHOTO_FAILURE,
  error
});


export function uploadPhoto (token, file, onUploadProgress, nextRoute, editMode) {


  console.log('uploadPhoto file: ', file);
  const doc_name = 'photo';
  // const uri = file;
  // const name = uri.name;
  // console.log('uri: ', uri);
  // console.log('name: ', name);
  // const fileName = name.substr(name.indexOf('.') + 1); // check iphone name is unique
  // // const fileType = name.substr(name.lastIndexOf('.') + 1);
  // const fileType = uri.type;
  
  console.log('doc_name', `${doc_name}`);
  // console.log('file', file.name); // if blob, won't have name
  // console.log('type', `${file.type}`);


  const formData = new FormData();

  console.log('uploadPhoto formData.append: ', `doc_name ${doc_name}`);
  // console.log('uploadPhoto file.name: ', file.name);

  formData.append('doc_name', `${doc_name}`);
  // formData.append('photo', file); // rename to file on server?
  // formData.append('name', `photo.${fileName}`);
  // formData.append('type', `${fileType}`);
  // const fileName = !file.name || file.name === undefined ? 'blob' : file.name;
  // console.log('file name', fileName);
  formData.append('file', file);
  formData.append('type', `${file.type}`);


  // DEBUG Display the key/value pairs
 // Display the key/value pairs
  for (const pair of formData.entries()) {
    console.log(`uploadPhoto formData ${pair[0]}: ${pair[1]}`);
  }

  
  console.log('uploadPhoto UploadPhotoURL: ', `${process.env.REACT_APP_API_URI}/upload`);
  console.log('uploadPhoto token: ', token);

  return (dispatch) => {
    dispatch(uploadPhotoRequest());

    dispatch(setUploadProgress(0));

    setTimeout(() => {
      // delay slightly so user sees progress indicator.
      // note - using axios for this one as fetch doesn't allow for callback for progress
      http.post(`/upload`, formData, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          authorization: token,
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress // callback to show progress indicator


      })
      .then((res) => {
        console.log('uploadPhoto res', res);
        // res.json()
        // .then((res) => {
          const data = res.data;
          console.log('Photo response data: ', data);
          setTimeout(() => {
            dispatch(uploadPhotoSuccess(data));
            dispatch(setCurrentFile(undefined)); // could this be part of success reducer?

            // dispatch(showModal(
            //   MODAL_TYPE_SUCCESS_PREAPPROVE,
            //   { // modalProps
            //     // other_user_name?
            //     thread_stage: 'edited_details',
            //     // user_type,
            //     other_user_name: messageParams.thread_details.sitter_name // to check when finalising that this is still sitter?
            //   }
            // ));
            dispatch(actionSuccess()); // triggers success icon

            console.log('pushing route editMode:', editMode);

            history.push({
              pathname: nextRoute,
              state: { editMode: editMode || false }
            });


          }, 1500);
        // })
        // .catch((e) => {
        //   console.log('Photo response error: ', e);
        //   dispatch(setUploadProgress(0));
        //   dispatch(setCurrentFile(undefined));
        //   dispatch(uploadPhotoFailure('Something went wrong'));
        // });
      })
      .catch((err) => {
        console.log('Photo response error: ', JSON.stringify(err));
        dispatch(setUploadProgress(0));
        dispatch(setCurrentFile(undefined));
        dispatch(uploadPhotoFailure('Something went wrong'));

        ReportError(!err.code ? null : err.code, err.message, {});
        dispatch(showModal(
          MODAL_TYPE_ERROR,
          { // modalProps
            title: upload_photo.modal.failure.title,
            header: upload_photo.modal.failure.header,
            message: upload_photo.modal.failure.para,
            message2: upload_photo.modal.failure.para2,
            error_status: err.code && err.code,
            error_message: err.message
          }
        ));

        dispatch(actionSuccess());

      });

    }, 1000); // 1 second delay to ensure progress bar shows
  };
}
