import { ReportGA } from '../../utils/ga';
import { showModal, hideModal } from '../modal';
import { actionSuccess } from '../verify';
import { submit_verify_email } from '../../text';
import { CheckError, ReportError, CustomException } from '../../utils/check-error';
import { MODAL_TYPE_SPINNER, MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS, MODAL_TYPE_SIGNIN } from '../../components/modals/modalTypes';
import { SUCCESS_SUBMIT_VERIFY_EMAIL } from '../../components/modals/modalNames';
import { VERIFY } from '../../components/modals/modalCategory';
import * as ROUTES from '../../constants/routes';
import history from '../../components/Navigation/history';
import { WELCOME_PAGE } from '../../components/dashboard/dashboard';
import { VERIFY as VERIFY_EMAIL } from '../../constants/ga-category';


export const SUBMIT_VERIFY_EMAIL_REQUEST = 'SUBMIT_VERIFY_EMAIL_REQUEST';
export const SUBMIT_VERIFY_EMAIL_SUCCESS = 'SUBMIT_VERIFY_EMAIL_SUCCESS';
export const SUBMIT_VERIFY_EMAIL_FAILURE = 'SUBMIT_VERIFY_EMAIL_FAILURE';
export const SUBMIT_VERIFY_EMAIL_RESET = 'SUBMIT_VERIFY_EMAIL_RESET';

export const submitVerifyEmailRequest = () => ({
  type: SUBMIT_VERIFY_EMAIL_REQUEST
});

// export const submitVerifyEmailReset = () => ({
//   type: SUBMIT_VERIFY_EMAIL_RESET
// });

export const submitVerifyEmailSuccess = data => ({
  type: SUBMIT_VERIFY_EMAIL_SUCCESS,
  data
});

export const submitVerifyEmailFailure = error => ({
  type: SUBMIT_VERIFY_EMAIL_FAILURE,
  error
});

export function submitVerifyEmail (verify_email_token, user_id, userIsLoggedIn) {

  console.log('submitVerifyEmail:', user_id);

  return (dispatch) => {

    dispatch(showModal(
      MODAL_TYPE_SPINNER,
      { // modalProps
        message: submit_verify_email.modal.submit_verify_email.fetching
      }
    ));

    dispatch(submitVerifyEmailRequest());

    fetch(`${process.env.REACT_APP_API_URI}/verify-email`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        // authorization: token // remove
      },

      body: JSON.stringify({ verify_email_token, user_id })
    })
    .then(CheckError)
    .then((data) => {
      if (data.error) {
        console.log('data.error', data.error);

        // throw error
        throw new CustomException(null, data.error);

      } else {
        console.log('data', data);
        dispatch(submitVerifyEmailSuccess(data));

        dispatch(hideModal());


        const modalText = submit_verify_email.modal.submit_verify_email.success;

        dispatch(showModal(

          MODAL_TYPE_SUCCESS,
          { // modalProps
            // other_user_name?
            modalCategory: VERIFY,
            modalName: SUCCESS_SUBMIT_VERIFY_EMAIL,
            modalText: modalText,
            okAction: () => {
              console.log('clicking OK');
              // after clicking okay - route user to welcome page (if not already acknowledged) or dashboard.
              const { got_it } = data;

              if (!userIsLoggedIn) {
                // not logged in, go to Sign in

                ReportGA({
                  category: VERIFY_EMAIL,
                  action: `User verified email, was not logged in, going to Landing and Sign in`
                });

                history.push({
                    pathname: ROUTES.LANDING,
                    state: { editMode: false } });

                dispatch(showModal(MODAL_TYPE_SIGNIN, {

                }));

                // or INVITE?
              } else if (!got_it.includes(WELCOME_PAGE)) {
                // not acknowledged Welcome before

                ReportGA({
                  category: VERIFY_EMAIL,
                  action: `User verified email, redirected to Welcome`
                });

                history.push({
                    pathname: ROUTES.WELCOME,
                    state: { editMode: false } });


                // or INVITE?
              } else {
                // already acknowledged Welcome, so show Dashboard

                ReportGA({
                  category: VERIFY_EMAIL,
                  action: `User verified email, redirected to Dashboard`
                });

                history.push({
                    pathname: ROUTES.DASHBOARD,
                    state: { editMode: false } });

                // or INVITE?
              }

              dispatch(hideModal());

            },
            backAction: null

          }
        ));

        dispatch(actionSuccess()); // triggers success icon

      }
    })
    .catch((err) => {

      console.log('err.message', err.message);

      dispatch(submitVerifyEmailFailure(err.message));
      // dispatch(hideModal());

      // centralise bugsnag reporting
      ReportError(!err.code ? null : err.code, err.message, {});
      dispatch(showModal(
        MODAL_TYPE_ERROR,
        { // modalProps
          title: submit_verify_email.modal.submit_verify_email.failure.title,
          header: submit_verify_email.modal.submit_verify_email.failure.header,
          // message: submit_verify_email.modal.submit_verify_email.failure.para,
          message: err.message,
          message2: submit_verify_email.modal.submit_verify_email.failure.para,
          error_status: err.code && err.code,
          // error_message: err.message
          // error_message: submit_verify_email.modal.submit_verify_email.failure.para2,
          afterClose: () => {
            if (!userIsLoggedIn) {
              dispatch(showModal(MODAL_TYPE_SIGNIN, {}));
            }
          }
        }
      ));

      dispatch(actionSuccess());

      history.push({
          pathname: ROUTES.DASHBOARD,
          state: { editMode: false } });


    });

  };
}
