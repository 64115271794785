/**
 * Software distributed under the Apache License is distributed on an "AS IS" basis,
 * WITHOUT WARRANTY OF ANY KIND, either express or implied. See the License for the
 * specific language governing rights and limitations under the License.
 */

import React from 'react';
import PropTypes from 'prop-types';

import './animated-cross.css';

const PREDEFINED_SIZE_MAP = {
  small: '16px',
  medium: '24px',
  large: '52px',
  xLarge: '72px',
  xxLarge: '96px',
};

export function Crossmark ({ size }) {
  const computedSize = PREDEFINED_SIZE_MAP[size] || size;
  const style = { width: computedSize, height: computedSize };

  return (
    <svg
      className='crossmark'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
      viewBox='0 0 52 52'
      stroke-linecap='round'
    >
      <circle className='crossmark__circle' cx='26' cy='26' r='25' fill='none' />

      <line className='crossmark__check' x1="18" y1="18" x2="34" y2="34" />
      <line className='crossmark__check2' x1="34" y1="18" x2="18" y2="34" />
    </svg>
  );
}

Crossmark.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

Crossmark.defaultProps = {
  size: 'large',
};
