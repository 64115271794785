import moment from 'moment';
import { dateRangeOverlaps, isDateTimeInPast } from './timeHelpers';

import * as BOOKING_TYPES from '../constants/booking-types';
import * as BOOKING_STATUS from '../constants/booking-status';

export const getDaysBookings = (dateString, combinedBookingsArray) => {

  const daysStartTimestamp = `${dateString} 00:00`;
  const daysEndTimestamp = `${dateString} 23:59`;

  // console.log('combinedBookingsArray:', combinedBookingsArray);
  const daysBookingsArray = combinedBookingsArray.filter((item, i) => {
    // console.log(`item ${i}:`, item);
    let start_timestamp;
    let end_timestamp;

    if (dateString === "2021-10-06") {
      // console.log(`combinedBookingsArray:`, combinedBookingsArray);
      // console.log('dateString:', dateString);
      // console.log('item.available_dates:', item.available_dates);
    }

    if (!item.available_dates) {
      start_timestamp = item.start;
      end_timestamp = item.end;
    } else {
      // parse available_dates
      // ["2021-08-20 02:30:00","2021-08-20 05:30:00"]
      const parsed_dateRange = JSON.parse(item.available_dates);
      const dateRangeArray = [...parsed_dateRange];
      // console.log('parsed_dateRange:', parsed_dateRange);
      start_timestamp = dateRangeArray[0];
      end_timestamp = dateRangeArray[1];
    }

    const isOverlappingThisDay = dateRangeOverlaps(start_timestamp, end_timestamp, daysStartTimestamp, daysEndTimestamp);
    // console.log('isOverlappingThisDay:', isOverlappingThisDay);

    return isOverlappingThisDay;


  });

  return daysBookingsArray;
};

export const isOverlappingOtherBookings = (booking_start_timestamp, booking_end_timestamp, combinedBookingsArray) => {


  // console.log('combinedBookingsArray:', combinedBookingsArray);
  const clashingBookingsArray = combinedBookingsArray.filter((item, i) => {
    // console.log(`item ${i}:`, item);
    let start_timestamp;
    let end_timestamp;

    if (!item.available_dates) {
      start_timestamp = item.start;
      end_timestamp = item.end;
    } else {
      // parse available_dates
      // ["2021-08-20 02:30:00","2021-08-20 05:30:00"]
      const parsed_dateRange = JSON.parse(item.available_dates);
      const dateRangeArray = [...parsed_dateRange];
      // console.log('parsed_dateRange:', parsed_dateRange);
      start_timestamp = dateRangeArray[0];
      end_timestamp = dateRangeArray[1];
    }

    const isOverlappingThisBooking = dateRangeOverlaps(start_timestamp, end_timestamp, booking_start_timestamp, booking_end_timestamp);
    // console.log('isOverlappingThisBooking:', `${start_timestamp}${end_timestamp}${booking_start_timestamp}${booking_end_timestamp}${isOverlappingThisBooking}`);

    return isOverlappingThisBooking;


  });

  // console.log('clashingBookingsArray:', clashingBookingsArray);

  return clashingBookingsArray.length > 1; // 1 because it will always clash with itself
};


export const getClashingBookings = (booking_id, date, recurrance, start_time, end_time, combinedBookingsArray) => {

  // gets clashing bookings and availability for all recurrances
  console.log('Start');

  const initial_date = date;

  let all_clashing_bookings = [];


  for (let x = 0; x < recurrance; x += 1) {
    // for loop for each availability recurrance
    // add availability for week X
    const weekNo = x;
    const new_date = moment(initial_date, 'YYYY-MM-DD').add(weekNo, 'weeks').format('YYYY-MM-DD'); // + x weeks  // 'YYYY-MM-DD'
    console.log('new_date:', new_date);
    const start_timestamp = `${new_date} ${start_time}`; // '2018-03-25 08:00'
    const end_timestamp = `${new_date} ${end_time}`;
    // console.log('start_timestamp:', start_timestamp);
    // console.log('end_timestamp:', end_timestamp);

    // does new start / end overlap existing booked_dates?
    const clashing_bookings = combinedBookingsArray.filter((item) => {
      // check if is overlapping

      let start;
      let end;

      if (!item.available_dates) {
        start = item.start;
        end = item.end;
      } else {
        // parse available_dates
        // ["2021-08-20 02:30:00","2021-08-20 05:30:00"]
        const parsed_dateRange = JSON.parse(item.available_dates);
        const dateRangeArray = [...parsed_dateRange];
        // console.log('parsed_dateRange:', parsed_dateRange);
        start = dateRangeArray[0];
        end = dateRangeArray[1];
      }
      // console.log('start:', start);
      // console.log('end:', end);
      const isOverlapping = dateRangeOverlaps(start_timestamp, end_timestamp, start, end);
      // console.log('isOverlapping:', isOverlapping);
      // if true add to clashing bookings array
      return (booking_id !== item.booking_id) && isOverlapping;


    });

    all_clashing_bookings = [...all_clashing_bookings, ...clashing_bookings];
    console.log('all_clashing_bookings:', all_clashing_bookings);
    console.log('End');

    return all_clashing_bookings;
  }
};

export default getDaysBookings;


// gets total bookings by category (for all dates)
export const getTotalBookings = (combinedBookingsArray) => {

  const available = [];
  const sitbooked = [];
  const sitpending = [];
  const reqBooked = [];
  const reqPending = [];
  const clashes = [];


  combinedBookingsArray.map((item) => {


  // only for items in future
  // what about finalised?  (probably do seperately as comes from threads in dB)

    // console.log('bookingItem:', item);
    // console.log('BOOKING_TYPES.USING', BOOKING_TYPES.USING);
    // console.log('BOOKING_STATUS.UNCONFIRMED', BOOKING_STATUS.UNCONFIRMED);
    // console.log('item.booking_type', item.booking_type);
    // console.log('item.status', item.status);

    // if item is overlapping with another
    let start_timestamp;
    let end_timestamp;

    if (!item.available_dates) {
      start_timestamp = item.start;
      end_timestamp = item.end;
    } else {
      // parse available_dates
      // ["2021-08-20 02:30:00","2021-08-20 05:30:00"]
      const parsed_dateRange = JSON.parse(item.available_dates);
      const dateRangeArray = [...parsed_dateRange];
      // console.log('parsed_dateRange:', parsed_dateRange);
      start_timestamp = dateRangeArray[0];
      end_timestamp = dateRangeArray[1];
    }
    // console.log('end_timestamp:', end_timestamp);
    const isInPast = isDateTimeInPast(end_timestamp);

    if (isInPast) {
      // not in future, do nothing

    } else {
      // in future, calculate and push to arrays


      const isClashing = isOverlappingOtherBookings(start_timestamp, end_timestamp, combinedBookingsArray); // should be all or just the day's?
      // console.log('isClashing:', isClashing);
      if (isClashing) {
        clashes.push(item);
      }
      if (item.booking_type === BOOKING_TYPES.AVAILABLE) {
        available.push(item);
      }
      if (item.booking_type === BOOKING_TYPES.SITTING) {
        if (item.booking_status === BOOKING_STATUS.UNCONFIRMED) {
          sitpending.push(item);
        } else {
          sitbooked.push(item);
        }
      }
      if (item.booking_type === BOOKING_TYPES.USING) {
        console.log('isUsing');
        if (item.booking_status === BOOKING_STATUS.UNCONFIRMED) {
          console.log('pushingReqPending');
          reqPending.push(item);
        } else {
          reqBooked.push(item);
        }
      }
    }

  });

  console.log('returned total:', {
    available,
    sitbooked,
    sitpending,
    reqBooked,
    reqPending,
    clashes
  });


  return {
    available,
    sitbooked,
    sitpending,
    reqBooked,
    reqPending,
    clashes
  }; // all arrays


};
