import React from 'react';
import colours from '../../styles/colours';

const Card = ({ title, onClick, closeButton, backButton, shadow, borderRadius, borderTop, fullWidth, maxWidth, noTopPadding, borderColor, borderWidth, noBottomMargin, noBottomPadding, noSidePadding, window_width, paddingLeft, paddingRight, children, backgroundColor }) => {

    // calc('100%' - 24)???

    return (
      <div
        role='button'
        tabIndex={0}
        title={title}
        onClick={ onClick }
        onKeyDown={ onClick }
        style={{ background: 'transparent', borderRadius: borderRadius || null, boxShadow: shadow ? `2px 2px 2px ${colours.shadow}` : null, display: 'flex', width: '100%', maxWidth: maxWidth || null, flexDirection: 'row', paddingLeft: paddingLeft || 0, paddingRight: paddingRight || 0 }}>
        <div style={{ position: 'relative', flex: 1, borderRadius: borderRadius || null, borderWidth: borderWidth || 1, backgroundColor: backgroundColor || colours.white, borderColor: borderColor || colours.lightgray, borderStyle: 'solid', paddingBottom: noBottomPadding ? 0 : 4, paddingLeft: noSidePadding ? 0 : 8, paddingRight: noSidePadding ? 0 : 8, marginBottom: noBottomMargin ? 0 : 8 }}>
          <div style={{ position: 'absolute', right: closeButton ? 0 : null, top: 0, marginTop: 4 }}>
            {closeButton && closeButton}
          </div>
          <div style={{ position: 'absolute', left: closeButton ? 0 : null, top: 0, marginTop: 4 }}>
            {backButton && backButton}
          </div>
          { children }
        </div>
      </div>
    );
};

export default Card;
