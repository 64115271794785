import React from 'react';
import {
  WhatsappShareButton,
  // WhatsappIcon,
  // EmailIcon,
  EmailShareButton,
  // FacebookMessengerIcon,
  // FacebookMessengerShareButton,
  FacebookShareButton
  // FacebookIcon
} from 'react-share';
import { ReportGA } from '../../utils/ga';
import { H2 } from '../../styles/text';
import colours from '../../styles/colours';
import Card from '../general/card';
import CardBanner from '../general/card-banner';
import Paragraph from '../general/paragraph';
import Image from '../general/image';
import ReferLink from '../account/refer-link';
import MainImage from '../../images/up_to_1500_sp.min.svg';
// import ReferFriendsImage from '../../images/refer_friends.min.svg';
import FacebookIcon from '../../images/icon_fb.min.svg';
// import FacebookMessengerIcon from '../../images/icon_fb_messenger.min.svg';
import WhatsappIcon from '../../images/icon_whatsapp.min.svg';
import EmailIcon from '../../images/icon_mail.min.svg';
import { Link } from '../../styles/buttons';
import { dashboard, refer } from '../../text';
import * as ROUTES from '../../constants/routes';
import { INVITE } from '../../constants/ga-category';

import { getAPPURI } from '../../constants/env';

const url = getAPPURI();

// const facebookAppID = process.env.REACT_APP_FACEBOOK_APP_ID;

const joinMeMessage = `Hi, join me using SitterPoint to arrange playdates and affordable childcare. Sign up using this link to get your 3 month free trial`;
const joinMeSubject = `Try SitterPoint today!`;
// what about bonus for new user?

const iconSize = 38;

const COPY_LINK = 'copy_link';
const FACEBOOK = 'facebook';
// const FB_MESSENGER = 'fb_messenger';
const WHATSAPP = 'whatsapp';
const LINK = 'link';
const EMAIL = 'email';

export const InviteFriendsContent = ({ window_width, userUniqueLink, prevRoute, clickedShareCallback }) => {
// this is used in Invite/Refer on Signup as well as on Dashboard and Results

  const breakpoint = 580;

  console.log('Invite clickedShareCallback prop:', clickedShareCallback);

  const beforeShareButtonClick = (type) => {

    return new Promise((resolve) => {

      if (clickedShareCallback) {
        clickedShareCallback(); // updates parent component state to indicate a share button has been clicked.
      }
      
      ReportGA({
        category: INVITE,
        action: `ShareButton clicked ${type}`
      });

      resolve();

    });
  };


  const onReferLinkCopy = (type) => {

    return new Promise((resolve) => {

      if (clickedShareCallback) {
        clickedShareCallback(); // updates parent component state to indicate a share button has been clicked.
      }
      
      ReportGA({
        category: INVITE,
        action: `ShareButton clicked ${type}`
      });

      resolve();

    });
  };


  return (

    <Card fullWidth borderColor={colours.mediumlightpurple} borderWidth={1} noSidePadding noBottomMargin>

      <CardBanner altText='Refer Friends'>
        <>
          {dashboard.invite_friends_banner1}
          <br />
          {dashboard.invite_friends_banner2}
        </>
      </CardBanner>

      <div style={{ marginTop: 16, paddingLeft: 8, paddingRight: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <H2 color={colours.gray} textAlign='center' noBottomPadding>
          {dashboard.invite_friends_para2}
        </H2>

        <div style={{ display: 'flex', flexDirection: window_width > breakpoint ? 'row' : 'column', width: '100%' }}>

          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center' }}>
            <div style={{ paddingTop: 0, paddingBottom: 0, width: '100%', maxWidth: window_width > breakpoint ? null : 260, display: 'flex', flexDirection: 'column' }}>

              <Image
                alt='main'
                style={{ height: '100%', objectFit: 'cover' }}
                src={ MainImage }
              />

            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingTop: window_width > breakpoint ? 24 : null }}>

              <ReferLink shareType={COPY_LINK} buttonOnly buttonText={refer.link.send} pageRef='Invite' prevRoute={prevRoute} userUniqueLink={userUniqueLink} onClickCopy={() => onReferLinkCopy(COPY_LINK)} />

            </div>

            <div style={{ marginTop: 16, display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Paragraph textAlign='center' noBottomPadding>
                {dashboard.invite_friends_para5}
                <br />
                {dashboard.invite_friends_para6}
              </Paragraph>
            </div>


            <div style={{ marginTop: 0, display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>


              <div style={{ padding: 8 }}>
                <FacebookShareButton
                  url={userUniqueLink}
                  redirectUri='https://sitterpoint.co.uk/invite'
                  quote={joinMeMessage} // A quote to be shared along with the link.
                  // hashtag='' // A hashtag specified by the developer to be added to the shared content. People will still have the opportunity to remove this hashtag in the dialog. The hashtag should include the hash symbol.
                  beforeOnClick={() => beforeShareButtonClick(FACEBOOK)}
                >
                  <Image width={iconSize} height={iconSize} src={FacebookIcon} />
                  {
                    // <FacebookIcon size={32} round />
                  }
                </FacebookShareButton>
              </div>

              {
                // <div style={{ padding: 8 }}>
                //   <FacebookMessengerShareButton
                //     url={userUniqueLink}
                //     redirectUri='https://sitterpoint.co.uk/invite' // the page to return to after sharing
                //     appId={facebookAppID} // Facebook application id (required)
                //     beforeOnClick={() => beforeShareButtonClick(FB_MESSENGER)}
                //   >
                //     <Image width={iconSize} height={iconSize} src={FacebookMessengerIcon} />
                //     {
                //       // <FacebookMessengerIcon size={32} round />
                //     }
                //   </FacebookMessengerShareButton>
                // </div>
              }

              <div style={{ padding: 8 }}>
                <WhatsappShareButton
                  title={joinMeMessage}
                  url={userUniqueLink}
                  separator=": "
                  beforeOnClick={() => beforeShareButtonClick(WHATSAPP)}
                >
                  <Image width={iconSize} height={iconSize} src={WhatsappIcon} />
                  {
                    // <WhatsappIcon size={32} round />
                  }
                </WhatsappShareButton>
              </div>

              <div style={{ padding: 8 }}>

                <ReferLink shareType={LINK} iconOnly iconSize={iconSize} buttonOnly buttonText={refer.link.send} pageRef='Invite' prevRoute={prevRoute} userUniqueLink={userUniqueLink} onClickCopy={() => onReferLinkCopy(LINK)} />

              </div>

              <div style={{ padding: 8 }}>
                <EmailShareButton
                  subject={joinMeSubject}
                  body={joinMeMessage}
                  url={userUniqueLink}
                  separator=": "
                  beforeOnClick={() => beforeShareButtonClick(EMAIL)}
                >
                  <Image width={iconSize} height={iconSize} src={EmailIcon} />
                  {
                    // <EmailIcon size={32} round />
                  }
                </EmailShareButton>
              </div>

            </div>

            <Paragraph textAlign='center'>
              {refer.para2b}<Link to={ROUTES.OFFER_DETAILS}>{refer.read_more_link_text}</Link>
            </Paragraph>
          </div>

        </div>

      </div>

    </Card>

  );

};

const InviteFriends = (props) => {
    // console.log('f item user:', user);
    const { location, window_width, referral_code } = props;

    const userUniqueLink = `${url}/?ref=${referral_code}`;

    const prevRoute = !location.state ? null : !location.state.prevRoute ? null : location.state.prevRoute;

    return (
      <InviteFriendsContent
        window_width={window_width}
        userUniqueLink={userUniqueLink}
        prevRoute={prevRoute}

      />
    );

};

export default InviteFriends;
