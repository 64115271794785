import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { ReportGA } from '../../utils/ga';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { emailValidator as validate } from './form-validation';
import { FormTextInput } from './form-components';
import { ConfirmButton } from '../../styles/styles';
import { A5, H4 } from '../../styles/text';
import colours from '../../styles/colours';
import Paragraph from '../general/paragraph';
import { forgot_password } from '../../text';
import { ACCOUNT } from '../../constants/ga-category';
// import { connectAlert } from '../Alert';


import Spinner from '../general/spinner';


class ConfirmEmail extends Component {

  componentDidMount () {

    ReportGA({
      category: ACCOUNT,
      action: "Forgot Password page shown to user"
    });

  }


  renderServerError = () => {
    if (this.props.errorMessage) {
      return <H4 color={colours.error}>{ this.props.errorMessage }</H4>;
    }
  }

  renderServerMessage = () => {
    if (this.props.successMessage) {
      return <H4 color={colours.green}>{ this.props.successMessage }</H4>;
    }
  }

  renderButton = () => {
    const { hideModal, submitSucceeded, handleSubmit, handleSubmitForm, isFetching } = this.props;
    if (isFetching) {
      return <Spinner size='large' />;
    }
    if (submitSucceeded) {
      return (
        <ConfirmButton
          onClick={() => hideModal()}
        >
          {forgot_password.close}
        </ConfirmButton>
      );
    }
    return (
      <ConfirmButton
        onClick={handleSubmit(handleSubmitForm)}
      >
        {forgot_password.reset_password}
      </ConfirmButton>
    );
  };


  render () {

    console.log('confirmEmail this.props:', this.props);

    const { submitSucceeded, pristine, isFetching, submitFailed, submitting, error } = this.props;
    return (
      <>


        <Paragraph>

          { !submitSucceeded && <A5>{forgot_password.enter_email}</A5>}
          { !isFetching && submitSucceeded && this.renderServerMessage() }

        </Paragraph>


        <Paragraph>

          {
            !isFetching &&
            <Field name='email' component={ FormTextInput } isEmail placeholder={forgot_password.placeholder_email} />
          }

        </Paragraph>


        { !pristine && !isFetching && this.renderServerError() }

        <div style={{ display: 'flex', marginTop: 20, alignItems: 'center', flexDirection: 'column' }}>

          { this.renderButton() }

        </div>


      </>

    );
  }
}

const decoratedComponent = reduxForm({ form: 'confirmEmail', validate })(ConfirmEmail);
export default hoistNonReactStatic(decoratedComponent, ConfirmEmail);
// export default connectAlert(hoistNonReactStatic(decoratedComponent, ConfirmEmail));
