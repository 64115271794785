import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
// import { withAuthentication } from './components/Session';
import history from './Navigation/history';

const RedirectRoute = ({ userIsLoggedIn, redirect_path, component: Component, ...rest }) => {

  return (
    <Route {...rest}

      render={(props) => {
        console.log('userIsLoggedIn: ', userIsLoggedIn);
        console.log('RedirectRoute history.location:', history.location);

        if (userIsLoggedIn !== true) {
          return (
            <Component {...props} />
          );
        }

        const searchParams = new URLSearchParams(history.location.search);
        const referralCode = searchParams.get('ref');
        const threadId = Number(searchParams.get('thread'));
        console.log('RedirectRoute threadId:', threadId);

        return (
          <Redirect to={{
            pathname: redirect_path || ROUTES.DASHBOARD,
            state: {
              referral_code: referralCode,
              thread_id: threadId
            }
          }} />
        );

      }}
    />
  );
};


export default RedirectRoute;
