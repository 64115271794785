import React from 'react';
import { Li } from '../../styles/text';


const ParaBullet = ({ color, children }) => {

    return (

      <Li color={color}>

        {children}

      </Li>

    );
};

export default ParaBullet;
