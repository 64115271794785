import React from 'react';
import { PROFILE_COMPLETE, REFERRALS_SIGNUP_LIMIT, REFERRAL_SIGNUP, REFERRAL_FIRSTSIT } from '../constants/bonuses';

export const dashboard = {
  section1_title: 'My Dashboard',

  cta: {
    important: `IMPORTANT!`,
    you_need_to: `You need to:`,
    click_here_to_resend: `click here to send again`
  },

  clashing_bookings_title: 'You have clashing bookings!',
  clashing_bookings_para1: {
    a: 'You have pending or confirmed sits which clash with existing sits or availability in your calendar.',
    b: 'Please cancel sits if needed or update your update your availability. '
    // h1: '150 SP ',
    // c: 'In the meantime, why not update your calendar with your upcoming availability for the next month.'
    // d: 'So get verified and book like-minded families available to sit in your area.'
  },
  // clashing_bookings_upload_more_button: 'Upload more documents',
  clashing_bookings_cal_button: 'Go to Calendar',

  docs_pending_title: 'Your verification status',
  docs_pending_para1: {
    a: 'Thank you for uploading your documents for verification. Please allow 7 days for them to be reviewed.',
    b: 'In the meantime, why not update your calendar.'
    // h1: '150 SP ',
    // c: ''
    // d: 'So get verified and book like-minded families available to sit in your area.'
  },
  docs_pending_upload_more_button: 'Upload more',
  docs_pending_cal_button: 'Go to Calendar',

  get_verified_title: 'Get verified and earn up to 150 SP extra!',
  get_verified_para1: {
    a: 'To be able to contact other families in your area, you must be verified.',
    b: 'On top of this, we will offer you up to an extra ',
    h1: '150 SP ',
    c: 'for you to spend on your next babysitter.',
    d: 'So get verified and book like-minded families available to sit in your area.'
  },

  get_verified_button: 'Get verified',

  verify_email_title: 'Verify your email!',
  verify_email_paras: [
    'Please click on the link in the email we sent to validate your email.',
    'This link will expire in 24 hours (check your junk folder if you cannot find the email).'

  ],

  verify_email_button: 'Resend Email',


  incomplete_availability_title: 'Did you know?',
  incomplete_availability_para1: {
    a: 'If you update your calendar for the next 30 days with a minimum of 8 slots of availability, you could earn an extra ',
    h1: '100 SittingPoints.',
    b: 'By doing this every month, you can earn up to 1200 per year, that’s 12 hours worth of sitting for your children.',
    c: 'You can also update your avalability at anytime.'
  },

  incomplete_availability_button: 'Update my availability',

  local_availability_title: `Can’t find a sitter for the date and time you want?`,
  local_availability_para: `See below for when families in your area are available in the next couple of weeks.`,
  local_availability_key: {
    good: 'Good availability',
    some: 'Some availability',
    none: 'No availability'
  },

  upcoming_title: 'Your upcoming bookings and sits',
  // upcoming_para1: {
  //   a: 'Complete 5 sits for another parent and earn a bonus of ',
  //   h1: 'XX points.'
  // },

  welcome_title: 'Welcome to SitterPoint',
  welcome_para1: 'This is your dashboard, here you will find your upcoming sits and news. ',
  welcome_para2: 'You may also find your next sitter by using the search icon above.',
  welcome_para2_incomplete: 'To be able to contact other members, you must complete your profile and get verified.',
  welcome_profile_prog: 'Profile progress:',
  welcome_points_title: `Unlock ${PROFILE_COMPLETE} SP!`,
  welcome_button: 'Complete your profile',
  welcome_only_pending: 'Your profile will be complete once your documents are verified by Sitterpoint.',

  how_does_it_work_title: 'SitterPoint, how does it work?',

  invite_friends_banner1: 'It looks like SitterPoint just started in your area...',
  invite_friends_banner2: 'Invite your friends!',
  // invite_friends_para1: 'Help build the community',
  invite_friends_para2: <>Give your friends <strong>3 months free</strong> membership!</>,
  // invite_friends_para3: '*after they complete their first sit',
  // invite_friends_para4: 'Offer ends 31st May 2023',
  invite_friends_button: '>> Get Invite Link <<',
  invite_friends_para5: 'Share on social media',
  invite_friends_para6: `You'll see a preview before you post`,

  profile_sp_title1: 'Congratulations!',
  // profile_sp_title2: '200 SittingPoints',
  profile_sp_para1: 'You can now book a sit and earn more points by hosting playdates!',
  // profile_sp_para2: 'more points by hosting playdates!',
  profile_sp_cal_button: `Update calendar`,
  profile_sp_book_a_sit_button: `Book a sit`,

  verified_sp_title1: 'You are now verified!',
  // verified_sp_title2: '150 SittingPoints',
  verified_sp_para1: 'As a thank you, we have given you a bonus of 150 SP!',
  verified_sp_para2: 'This means you now have up to 3.5 hours towards your next babysitter!',

  dbs_sp_title1: 'You uploaded a DBS!',
  // dbs_sp_title2: '150 SittingPoints',
  dbs_sp_para1: 'As a thank you, we have given you a bonus of 50 SP!',
  dbs_sp_para2: 'This means you now have 30 minutes extra towards your next babysitter!',


  // to review
  section1_para1: 'Thank you for taking a first step towards our community of parents that share babysitting today!',
  section1_para2: 'We believe you share',
  intro_more: 'our philosophy',

  section2_title: 'What comes next?',
  section2_para1: {
    a: 'In order to make the full use of our service and for you to be able to contact like-minded families able to sit your children and vice-versa , you will be asked to complete your',
    h1: 'family profile',
    b: ', which includes:'
  },

  section2_bullets: [
    'information on the number of children you have, their age, and any allergies, dietary requirements or SEN.',

    'your address (this will only be given to other users if a sit is comfirmed),',

    'the number of adults (over 16s) residing at your address and their relation to your children',

    'a description of yourself for other parents to know a bit more about you. (eg: your accupations, hobbies, etc)',

    'a profile photo (optional)'
  ],
  section2_para2: 'You can come back to your profile and upload documents to verify you at anytime.',
  learn_more_link: 'Learn more about SittingPoints',

  complete_profile_now_button: `Let's go`,
  later_button: 'Skip for now',
  search: 'Search for a sitter',
  view_messages: 'View messages & info'


};


export default dashboard;
