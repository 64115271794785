import { PROFILE_COMPLETE } from '../constants/bonuses';
// should all come to redux state from server db when logging in / loading app

// sits

export const points_per_hour_per_child = 100;


// subscription

export const user_currency = '£';
export const subscription_bonus = 100;
export const sit_price = 3;
export const subscription_name = 'Gold member';
// may want to add insurance cover in future, e.g. Your children are covered everytime in case of any damage they may do. It covers the parent or carer too with a liability insurance.


// profile / verification

export const verification_doc_points = 50;
export const profile_completed_points = PROFILE_COMPLETE;
export const total_verification_points = verification_doc_points * 3;
export const hours_per_verification = total_verification_points / points_per_hour_per_child;


export default points_per_hour_per_child;
