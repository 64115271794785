import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import rootReducer from './reducers/index';


const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'inbox',
    'tariff',
    'messageThread',
    'calendar',
    'dashboard',
    'search',
    'register',
    'form'
  ]
};
// do need to persist MessageThread so that can refresh a thread.  If need anything to clear, put in the layout reducer.
// added register to ensure selected adult and child is persisted so no blank state for child_item/adult_item
// added form to ensure child/adult item can refresh when unsaved new item added via initialize form

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export function initStore (initialState) {

    let middleware = [];
    if (process.env.REACT_APP_APP_NODE_ENV === 'production') {
      middleware = [...middleware, thunk];
    } else {
      middleware = [...middleware, thunk, logger];
    }

    const composeEnhancers = process.env.REACT_APP_APP_NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f || null;

    return createStore(
        persistedReducer,
        initialState,
        compose(
            applyMiddleware(...middleware),
            composeEnhancers
        )
    );
}

export const store = initStore();

export const persistor = persistStore(store);

/*
to clear the old app state in AsyncStorage:
1. uncomment the line below
2. run the app once for each OS - the app state will be purged
3. recomment the line below
 */

// persistor.purge();
